/**
 * Created by piotr.pozniak@thebeaverhead.com on 23/05/2024
 */

import React from "react";
import PropTypes from "prop-types";

const BackButton = ({ show, label, url }) => {
  if (!show) {
    return null;
  }

  return (
    <div className={"dc-calendar-branding--header-backbutton-container"}>
      <a href={url} className="dc-calendar-branding--back-button">
        {label}
      </a>
    </div>
  );
};

BackButton.defaultProps = {};

BackButton.propTypes = {};

export default BackButton;
