/**
 * Created by piotr.pozniak@thebeaverhead.com on 20/10/2022
 */

import React, { useContext } from "react";
import PropTypes from "prop-types";
import { IT } from "./../../../../consts";
import IntegrationContext from "./../../../calendar/strategies/IntegrationContext";
import ExpandableAlert from "./../../component/ExpandableAlert";

const NoEventsAlert = (props) => {
  const integrationStrategy = useContext(IntegrationContext);

  const { calendar } = props;

  const errorBody = (
    <React.Fragment>
      <ul>
        {[IT.ccb, IT.pco].indexOf(calendar.model.integration.type) >= 0 ? (
          <li>
            Perhaps you need to toggle on the{" "}
            <strong>
              Show{" "}
              {calendar.model.integration.type === IT.ccb
                ? "private"
                : "hidden"}
            </strong>{" "}
            events button?
          </li>
        ) : null}
        <li>
          Maybe you chose to <strong>Show events with images only</strong> but
          your events do not have images?
        </li>
        <li>
          What about filters such as{" "}
          {integrationStrategy.availableFilters.join(", ")}? Are they limiting
          your display incorrectly?
        </li>
      </ul>
      <span className={"italic"}>
        This message is only for you. It will not display to other people.
      </span>
    </React.Fragment>
  );

  return (
    <div className={"overlapping-alert-container"}>
      <ExpandableAlert
        icon={null}
        errorLevel={"info"}
        header={
          <span>
            {" "}
            No events to display? Please go to the <strong>
              Setup tab
            </strong>{" "}
            and look at your <strong> Events Filtering</strong>.
            <br />
          </span>
        }
        body={errorBody}
      />
    </div>
  );
};

NoEventsAlert.defaultProps = {};

NoEventsAlert.propTypes = {};

export default NoEventsAlert;
