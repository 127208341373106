/**
 * Created by piotr.pozniak@thebeaverhead.com on 02/09/2022.
 */

import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as subscriptionActions from "../../actions/subscription";
import { terminateSubscriptionAdmin } from "../../actions/subscription";

export function useSubscriptionStore() {
  const dispatch = useDispatch();
  const _subscription = useSelector((store) => store.subscription);

  const initiateSubscription = useCallback(
    async (
      stripeObject,
      billingData,
      planUUID,
      isYearly,
      coupon,
      referralCoupon,
      selectedPlan
    ) =>
      await dispatch(
        subscriptionActions.initiateSubscription(
          stripeObject,
          billingData,
          planUUID,
          isYearly,
          coupon,
          referralCoupon,
          selectedPlan
        )
      ),
    [dispatch]
  );

  const terminateSubscription = useCallback(
    async (
      email,
      password,
      terminate_reason,
      terminate_notes,
      isGoogleAccount
    ) =>
      await dispatch(
        subscriptionActions.terminateSubscription(
          email,
          password,
          terminate_reason,
          terminate_notes,
          isGoogleAccount
        )
      ),
    [dispatch]
  );

  const terminateSubscriptionAdmin = useCallback(
    async (uuid) =>
      await dispatch(subscriptionActions.terminateSubscriptionAdmin(uuid)),
    [dispatch]
  );

  const getCredits = useCallback(
    async () => await dispatch(subscriptionActions.getCredits()),
    [dispatch]
  );

  const updateBillingDetails = useCallback(
    async (stripeObject, billingData) =>
      await dispatch(
        subscriptionActions.updateBillingDetails(stripeObject, billingData)
      ),
    [dispatch]
  );

  return {
    subscription: _subscription,
    initiateSubscription,
    terminateSubscription,
    getCredits,
    updateBillingDetails,
    terminateSubscriptionAdmin,
  };
}
