/**
 * Created by piotr.pozniak@thebeaverhead.com on 28/09/2023.
 */

import React, { useState, useEffect, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import PageTemplate from "./../PageTemplate";

import NewWidgetWizard from "../../component/NewWidgetWizard";
import { store } from "../../store";
import SidebarTab from "../../component/WidgetSettings/SidebarTab";
import SuccessAlert from "../../component/SuccessAlert";
import ApiError from "../../component/ApiError";
import { updateJquery, widgetSettingsValidator } from "../../tools";
import { PLANS } from "../../../../consts";
import { useIntegrationsStore } from "../../../../hooks/redux/integrations";
import { useUserStore } from "../../../../hooks/redux/user";
import { useWidgetStore } from "../../../../hooks/redux/widget";
import MandatoryInformationForm from "./MandatoryInformationForm";
import NewWidgetPreview from "./NewWidgetPreview";
import { ucFirst } from "../../../../helpers/string";
import { useCurrentOrganizationStore } from "../../../../hooks/redux/currentOrganization";
import { hasSubscription } from "../../helpers/organization";
import classNames from "classnames";

const NewWidgetPageTemplate = (props) => {
  let savePointEventsSettings = null;
  const sidebarOverlayRef = useRef(null);
  const chatSidebarOverlayRef = useRef(null);
  
  const { widgetType, integrationDefaultFilters } = props;
  const { widget, updateWidget, setWidget } = useWidgetStore();
  const { integrations } = useIntegrationsStore();
  const { currentOrganization } = useCurrentOrganizationStore();

  const loading = widget.update || widget.fetch || widget.delete;
  const subscription = hasSubscription(currentOrganization);

  const history = useHistory();
  
  const initialState = {
    widgetModel: {
      name: "",
      available: 0,
      integration_uuid: 0,
      template: "",
      widget_type: widgetType,
    },
    errors: {},
  };
  const [state, setState] = useState(JSON.parse(JSON.stringify(initialState)));
  
  /**
   *
   * @param fieldName
   * @param value
   */
  const onUpdateField = (fieldName, value) => {
    const data = {
      ...state,
    };

    data.widgetModel[fieldName] = value;

    data.errors = widgetSettingsValidator(data.widgetModel);

    setState(data);
  };

  useEffect(() => {
    if (widget.fetchSuccess) {
      if (!widget.model.integration) {
        history.push("/dashboard");
      }
    }

    if (widget.fetchSuccess) {
      setTimeout(updateJquery, 200);
    }
  }, [widget.fetchSuccess, widget.fetchSuccess]);

  useEffect(() => {
    window.$(".chat-main-row").animate({ scrollTop: 0 }, 200);

    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [state.widgetModel.template]);

  useEffect(() => {
    return () => setWidget(null);
  }, []);

  /**
   *
   */
  const onSubmit = async () => {
    const defaultUISettings = props.availableWidgets.find(
      (i) => i.slug == state.widgetModel.template
    ).defaultDesignSettings;

    const selectedIntegration = integrations.collection.find(
      (i) => i.uuid === state.widgetModel.integration_uuid
    );

    const data = { ...state.widgetModel };
    data.widget_settings = { design: defaultUISettings };

    // apply filter defaults
    if (
      integrationDefaultFilters &&
      integrationDefaultFilters[selectedIntegration.type]
    ) {
      data.filter_settings = {
        filters: integrationDefaultFilters[selectedIntegration.type],
      };
    }
    await updateWidget(null, data);

    const storeState = store.getState();

    if (storeState.widget.updateSuccess) {
      savePointEventsSettings = storeState.widget.model.widget_settings
        ? Object.assign({}, storeState.widget.model.widget_settings)
        : {};

      await setWidget(null);

      history.push("/signup/" + storeState.widget.model.uuid);
    }
  };

  /**
   *
   * @param widgetType
   * @returns {(function(): void)|*}
   */
  const onSelectedNewWidgetType = (widgetType) => () => {
    setWidget({});
    const data = {
      ...state,
    };
    data.widgetModel.template = widgetType;
    setState(data);
  };

  const settingsTabsOptions = [];

  settingsTabsOptions.push({
    label: "Setup",
    id: "setup_tab",
    additionalClassName: "active show",
  });

  const settingsTabs = settingsTabsOptions.map((item, idx) => {
    return (
      <li key={"settings-tab-" + idx} className="nav-item">
        <a
          className={"nav-link " + item.additionalClassName}
          href={"#" + item.id}
          data-toggle="tab"
        >
          {item.label}
        </a>
      </li>
    );
  });

  const cancelSelection = useCallback(() => {
    setWidget({});
    setState(JSON.parse(JSON.stringify(initialState)));
    if (sidebarOverlayRef?.current) {
      sidebarOverlayRef.current?.classList?.remove('opened');
    }
    if (chatSidebarOverlayRef?.current) {
      chatSidebarOverlayRef.current?.classList?.remove('opened');
    }
  }, [state.widgetModel]);

  const footer = !loading ? (
    <div>
      <SuccessAlert>
        {widget.updateSuccess ? "Your changes have been saved." : null}
      </SuccessAlert>
      <ApiError
        error={widget.updateError}
        defaultErrorMessage={"Could not save your settings. Please try again"}
      />
       <div className="d-flex justify-content-end gap-xs">
        <button
          type="button"
          data-reff="#chat_sidebar"
          onClick={cancelSelection}
          className="btn btn-secondary d-xs-block d-lg-none sidenav_btn  task-chat nav-link "
        >
          Cancel
        </button>
        <button
          disabled={props.disabled}
          type="submit"
          className="btn btn-primary"
          onClick={onSubmit}
        >
          Create
        </button>
      </div>
    </div>
  ) : null;

  const hasWidgetTypeSelected = state.widgetModel.template.length
    ? true
    : false;

  const setupTab = (
    <SidebarTab
      id="setup_tab"
      show={true}
      footer={hasWidgetTypeSelected ? footer : null}
    >
      <NewWidgetWizard
        hasSelectedWidget={state.widgetModel.template}
        hasEnteredWidgetName={state.widgetModel.name.length >= 3}
        hasSelectedIntegration={state.widgetModel.integration_uuid != "0"}
        widgetName={ucFirst(widgetType) + " Widget"}
        widgetType={widgetType}
      />

      {hasWidgetTypeSelected ? (
        <MandatoryInformationForm
          widgetModel={state.widgetModel || {}}
          widgetType={widgetType}
          onUpdateField={onUpdateField}
          disabled={loading}
          formErrors={state.errors}
          integrations={integrations.collection}
          allowedIntegrations={props.allowedIntegrations}
          showSummary={false}
          expanded={true}
        />
      ) : null}
    </SidebarTab>
  );

  const widgetOptions = props.availableWidgets.map((i, idx) => {
    return (
      <NewWidgetPreview
        onSelect={onSelectedNewWidgetType(i.slug)}
        isSelected={state.widgetModel.template === i.slug}
        key={i.slug}
        {...i}
        canSelect={subscription && i.forPlans.includes(subscription.code)}
      />
    );
  });

  return (
    <PageTemplate
      sidebarId={props.sidebarId}
      icon={props.icon}
      title={props.title}
      className={"page-wrapper-with-white-bg"}
    >
      <div className="chat-main-row">
        <div className="chat-main-wrapper">
          <div className="col-xs-12 message-view task-view">
            <div className="chat-window">
              <div
                className={classNames(
                  `chat-contents ${props.widgetType}-background`
                )}
              >
                <div className="chat-content-wrap">
                  <div className="chat-wrap-inner">
                    <div className="chat-box">
                      <div className="chats">
                        <div className="signup-preview-container">
                          <div className="container container-fluid">
                            <div className="row">
                              <div className="col-sm-12">
                                <h4 className="page-title">&nbsp;</h4>
                              </div>
                            </div>
                            <div className="">
                              <h2>
                                New{" "}
                                {props.widgetDisplayName !== ""
                                  ? props.widgetDisplayName
                                  : ucFirst(widgetType)}{" "}
                                Widget Setup
                              </h2>
                              <p>
                                Start by selecting a widget template, adding a
                                name, and selecting an Integration.
                              </p>
                              <div className="row">{widgetOptions}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="col-lg-3 message-view chat-profile-view chat-sidebar"
            id="chat_sidebar"
            ref={chatSidebarOverlayRef}
          >
            <div className="chat-window video-window">
              <div className="fixed-header">
                <ul className="nav nav-tabs nav-tabs-bottom">{settingsTabs}</ul>
              </div>
              <div className="tab-content chat-contents">{setupTab}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="sidebar-overlay" data-reff="#chat_sidebar" ref={sidebarOverlayRef}/>
    </PageTemplate>
  );
};

NewWidgetPageTemplate.defaultProps = {
  widgetDisplayName: "",
};

NewWidgetPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  sidebarId: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  widgetType: PropTypes.string.isRequired,
  widgetDisplayName: PropTypes.string,
  availableWidgets: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string,
      slug: PropTypes.string.isRequired,
      thumbnailUrl: PropTypes.string,
      features: PropTypes.arrayOf(PropTypes.string),
      forPlans: PropTypes.arrayOf(
        PropTypes.oneOf([PLANS.starter, PLANS.medium, PLANS.highest])
      ),
      defaultDesignSettings: PropTypes.object,
      previewUrl: PropTypes.string,
      isNew: PropTypes.bool,
    })
  ).isRequired,
  integrationDefaultFilters: PropTypes.shape({
    [PropTypes.string]: PropTypes.arrayOf(
      PropTypes.shape({
        conjunction: PropTypes.oneOf(["and", "or"]),
        groups: PropTypes.arrayOf(
          PropTypes.shape({
            conjunction: PropTypes.oneOf(["and", "or"]).isRequired,
            rules: PropTypes.arrayOf(
              PropTypes.shape({
                field: PropTypes.shape({
                  value: PropTypes.string.isRequired,
                  label: PropTypes.string.isRequired,
                }).isRequired,
                value: PropTypes.string.isRequired,
                label: PropTypes.string.isRequired,
                operator: PropTypes.shape({
                  value: PropTypes.string.isRequired,
                  label: PropTypes.string.isRequired,
                }).isRequired,
              })
            ),
          })
        ),
      })
    ),
  }),
};
export default NewWidgetPageTemplate;
