/**
 * Created by piotr.pozniak@thebeaverhead.com on 19/02/2024
 */

import React from "react";
import PropTypes from "prop-types";
import HamburgerItem from "./HamburgerItem";

const HamburgerDropdown = ({ disabled, options }) => {
  const items = options.map((i, idx) => <HamburgerItem key={idx} {...i} />);

  return (
    <div className="dropdown dropdown-action">
      <a
        href={!disabled ? "#" : null}
        data-toggle={!disabled ? "dropdown" : ""}
        className="btn action-icon dropdown-toggle p-2"
        aria-expanded="false"
      >
        <i className="material-icons icon-md">more_vert</i>
      </a>
      <div className="dropdown-menu dropdown-menu-right">{items}</div>
    </div>
  );
};

HamburgerDropdown.defaultProps = {};

HamburgerDropdown.propTypes = {
  disabled: PropTypes.bool,
};

export default HamburgerDropdown;
