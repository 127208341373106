/**
 * Created by piotr.pozniak@thebeaverhead.com on 22/08/2022
 */

import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import CrownIcon from "../../component/CrownIcon";
import { PLAN_NAMES, PLANS } from "../../../../consts";
import { useCurrentOrganizationStore } from "../../../../hooks/redux/currentOrganization";
import {
  getOrganizationSubscriptionPlan,
  hasSubscription,
} from "../../helpers/organization";

const Scope = (props) => {
  const [internallyChecked, setInternallyChecked] = useState(props.available);
  const { currentOrganization } = useCurrentOrganizationStore();
  const {
    idPrefix,
    name,
    available,
    additionalComponent,
    isMandatory,
    label,
    description,
    onScopeUpdate,
    allowedPlans,
  } = props;

  const subscription = currentOrganization.subscription;
  const PostfixComponent =
    additionalComponent && available ? additionalComponent : null;

  /**
   *
   * @type {(function(*): void)|*}
   */
  const onToggle = useCallback(
    (e) => {
      setInternallyChecked(e.target.checked ? true : false);
      onScopeUpdate(name, e.target.checked ? true : false);
    },
    [props.integrationModel]
  );

  const allowedPlansNames = useMemo(() => {
    return allowedPlans.map((i) => PLAN_NAMES[i]).join(", ");
  }, []);

  const isAllowed = useMemo(() => allowedPlans.includes(subscription.code), []);

  const premiumIcon = !isAllowed ? (
    <span className={"scope-crown-anchor"}>
      <CrownIcon
        className={""}
        tooltipMessage={`This scope is available in ${allowedPlansNames} plans. Upgrade now!`}
      />
    </span>
  ) : null;

  const additionalInfo = props.additionalInfo || null;
  return (
    <li>
      <input
        id={`${idPrefix}${name}`}
        type={"checkbox"}
        defaultChecked={available && isAllowed ? true : false}
        disabled={isMandatory || !isAllowed || props.isLoading}
        onClick={onToggle}
      />{" "}
      <label htmlFor={`${idPrefix}${name}`} className={"d-inline-flex"}>
        <strong>{label}</strong>
        {premiumIcon}
      </label>
      <p className={"m-l-15"}>{description}</p>
      {additionalInfo}
      {PostfixComponent && internallyChecked ? (
        <PostfixComponent
          integrationModel={props.integrationModel}
          isLoading={props.isLoading}
        />
      ) : null}
    </li>
  );
};

Scope.defaultProps = {
  allowedPlans: [PLANS.free, PLANS.starter, PLANS.medium, PLANS.highest],
};

Scope.propTypes = {
  name: PropTypes.string.isRequired,
  available: PropTypes.bool.isRequired,
  description: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  label: PropTypes.string.isRequired,
  isMandatory: PropTypes.bool,
  isLoading: PropTypes.bool,
  integrationModel: PropTypes.object,
  additionalComponent: PropTypes.func,
  onScopeUpdate: PropTypes.func,
  allowedPlans: PropTypes.array,
};

export default Scope;
