/**
 * Created by piotr.pozniak@thebeaverhead.com on 25/02/2019.
 */

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { Link, useHistory } from "react-router-dom";
import PageTemplate from "./../templates/PageTemplate";
import TableRow from "./LicensesPage/TableRow";
import LoadingIndicator from "../../calendar/components/LoadingIndicator";
import EditModal from "./LicensesPage/EditModal";
import Pagination from "../component/Pagination";
import CreateModal from "./LicensesPage/CreateModal";
import ApiError from "../component/ApiError";
import SuccessAlert from "../component/SuccessAlert";
import { clearErrors } from "../../../actions/main";

import {
  createLicense,
  deleteLicense,
  fetchLicenses,
  updateLicense,
} from "../../../actions/licenses";
import useDidMountEffect from "../../../hooks/useDidMountEffect";

const LicensesPage = (props) => {
  const [state, setState] = useState({
    createModalKey: false,
    editModalKey: false,
    license: { uuid: null },
  });

  const history = useHistory();
  useEffect(() => {
    props.licensesActions.fetchLicenses(props.match.params.page || 1);

    return () => {
      props.mainActions.clearErrors();
      window.$("#edit_license").modal("hide");
      window.$("#create_license").modal("hide");
    };
  }, []);

  useDidMountEffect(() => {
    props.licensesActions.fetchLicenses(props.match.params.page);
  }, [props.match.params.page]);

  useEffect(() => {
    if (state.createModalKey) {
      window.$("#create_license").modal("show");
    } else {
      props.mainActions.clearErrors();
    }
  }, [state.createModalKey]);

  useEffect(() => {
    if (state.editModalKey) {
      window.$("#edit_license").modal("show");
    } else {
      props.mainActions.clearErrors();
    }
  }, [state.editModalKey]);

  useEffect(() => {
    if (props.licenses.updateSuccess) {
      onCloseEditModal();
    }
  }, [props.licenses.updateSuccess]);

  /**
   *
   * @param user
   */
  const onLicenseDelete = (license) => {
    if (
      window.confirm(
        "Are you sure you want to license '" + license.license_key + "'?"
      )
    ) {
      props.licensesActions.deleteLicense(license.uuid);
    }
  };

  /**
   *
   * @param data
   */
  const onCreateLicenses = (data) => {
    props.licensesActions.createLicense(data);
  };

  /**
   *
   * @param user
   */
  const onShowEditLicense = (license) => {
    setState({
      ...state,
      license,
      editModalKey: true,
    });
  };

  /**
   *
   */
  const onEdit = (license) => {
    props.licensesActions.updateLicense(license.uuid, license);
  };

  /**
   *
   */
  const onCloseEditModal = () => {
    window.$("#edit_license").modal("hide");
    setState({
      ...state,
      license: { uuid: null },
      editModalKey: false,
    });
  };

  const licenses = props.licenses;
  const loading = licenses.fetch || licenses.delete;

  const licensesList = licenses.collection.map((item, idx) => {
    return (
      <TableRow
        disabled={loading}
        key={idx}
        license={item}
        onDelete={onLicenseDelete}
        onEdit={onShowEditLicense}
      />
    );
  });

  /**
   *
   */
  const onAddLicenseClick = (e) => {
    e.preventDefault();

    if (!loading) {
      setState({
        ...state,
        createModalKey: true,
      });
    }

    return false;
  };

  const loadingIndicator = loading ? <LoadingIndicator /> : null;

  const licensesTable = licenses.collection.length ? (
    <table className="table table-striped custom-table">
      <thead>
        <tr>
          <th>Created</th>
          <th>Cost</th>
          <th>Assigned Organization</th>
          <th>Key</th>
          <th className="text-right">Action</th>
        </tr>
      </thead>
      <tbody>{licensesList}</tbody>
    </table>
  ) : null;

  /**
   *
   * @param page
   * @returns {string}
   */
  const getPaginationUrl = (page) => "/licenses/" + page;

  /**
   *
   * @param page
   */
  const onPageChange = (page) => {
    history.push("/licenses/" + page);
    window.scrollTo({ top: 0 });
  };

  /**
   *
   */
  const onCreateModalClose = () => {
    window.$("#create_license").modal("hide");
    setState({ ...state, createModalKey: false });
  };

  const createModal = state.createModalKey ? (
    <CreateModal
      onClose={onCreateModalClose}
      onSubmit={onCreateLicenses}
      licenses={licenses}
    />
  ) : null;

  const editModal = state.editModalKey ? (
    <EditModal
      license={state.license}
      licenses={licenses}
      onEdit={onEdit}
      onClose={onCloseEditModal}
    />
  ) : null;

  return (
    <PageTemplate
      sidebarId="licenses"
      title={"All licenses"}
      icon={"key"}
      headerControls={[
        <Link to={"/users-licenses"} className={"btn btn-light"}>
          <small>View licenses per users</small>
        </Link>,
        <a
          href="#"
          className="btn btn-primary btn-rounded pull-right d-flex"
          onClick={onAddLicenseClick}
        >
          <i className="material-icons">add</i> Add Licenses
        </a>,
      ]}
    >
      <div className="row">
        <div className="col-md-12">
          <SuccessAlert>
            {licenses.deleteSuccess
              ? "License has been deleted successfully."
              : null}
          </SuccessAlert>
          <div className="table-responsive">
            {licensesTable}
            <Pagination
              itemsPerPage={licenses.pagination.perPage}
              currentPage={licenses.pagination.page}
              total={licenses.pagination.count || 0}
              getPageUrl={getPaginationUrl}
              onPageChange={onPageChange}
            />
            {loadingIndicator}
          </div>
        </div>
      </div>

      {editModal}
      {createModal}
    </PageTemplate>
  );
};

const mapStateToProps = (store) => ({
  main: store.main,
  licenses: store.licenses,
});

const mapDispatchToProps = (dispatch) => ({
  mainActions: {
    clearErrors: () => dispatch(clearErrors()),
  },
  licensesActions: {
    fetchLicenses: (page, ipp) => {
      dispatch(fetchLicenses(page, ipp));
    },
    updateLicense: (uuid, data) => {
      dispatch(updateLicense(uuid, data));
    },
    createLicense: (data) => {
      dispatch(createLicense(data));
    },
    deleteLicense: (uuid) => {
      dispatch(deleteLicense(uuid));
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LicensesPage);
