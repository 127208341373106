import axios from "axios";
import AxiosConfig from "../AxiosConfig";

/**
 *
 */
export const getReferralCoupon = () => {
  return (window.Rewardful && window.Rewardful.coupon) || null;
};

/**
 *
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export function fetchPlans() {
  return function (dispatch) {
    dispatch({ type: "SUBSCRIPTION_PLAN_FETCH" });

    let url = "/api/subscription_plans.json";

    const referralCoupon = getReferralCoupon();

    if (referralCoupon) {
      url += "?referral_coupon=" + referralCoupon.id;
    }

    return axios
      .get(
        AxiosConfig.getEndpointAddress() + url,
        AxiosConfig.getAuthConfigIfAvailable()
      )
      .then((response) => {
        dispatch({
          type: "SUBSCRIPTION_PLAN_FETCH_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "SUBSCRIPTION_PLAN_FETCH_REJECTED",
          payload: error,
        });
      });
  };
}

/**
 *
 * @param data
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export function updatePlan(uuid, data) {
  return function (dispatch) {
    dispatch({ type: "SUBSCRIPTION_PLAN_UPDATE" });

    const url = "/api/subscription_plans" + (uuid ? "/" + uuid : "") + ".json";
    const action = uuid ? axios.put : axios.post;

    return action(url, data, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "SUBSCRIPTION_PLAN_UPDATE_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "SUBSCRIPTION_PLAN_UPDATE_REJECTED",
          payload: error,
        });
      });
  };
}

/**
 *
 * @param uuid
 * @param data
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export function deletePlan(uuid) {
  return function (dispatch) {
    dispatch({ type: "SUBSCRIPTION_PLAN_DELETE" });

    const url = "/api/subscription_plans/" + uuid + ".json";

    return axios
      .delete(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "SUBSCRIPTION_PLAN_DELETE_FULFILLED",
          payload: { uuid },
        });
      })
      .catch(function (error) {
        dispatch({
          type: "SUBSCRIPTION_PLAN_DELETE_REJECTED",
          payload: error,
        });
      });
  };
}
