/**
 * Created by piotr.pozniak@thebeaverhead.com on 28/06/2022
 */

import React, { useContext, useMemo } from "react";
import PropTypes from "prop-types";
import Filters from "./Filters/Filters";
import IntegrationContext from "../../strategies/IntegrationContext";
import WidgetSettingsContext from "../../../../contexts/WidgetSettingsContext";

const Header = (props) => {
  const integrationStrategy = useContext(IntegrationContext);
  const widgetSettingsContext = useContext(WidgetSettingsContext);

  const hasSelectors = useMemo(
    () =>
      integrationStrategy.availableFilters
        .map((kind) => {
          const showSelector = widgetSettingsContext[`show${kind}Dropdown`];

          if (showSelector) {
            return 1;
          }
          return 0;
        })
        .reduce((a, b) => a + b, 0) > 0,
    [widgetSettingsContext, integrationStrategy.availableFilters]
  );

  const filters = hasSelectors ? (
    <Filters
      onChangeFilter={props.onChangeFilter}
      selectedFilters={props.selectedFilters}
    />
  ) : null;

  return <div className={"dce-groups--header"}>{filters}</div>;
};

Header.defaultProps = {};

Header.propTypes = {
  onChangeFilter: PropTypes.func,
  selectedFilters: PropTypes.object,
};

export default Header;
