/**
 * Created by piotr.pozniak@thebeaverhead.com on 05/09/2022
 */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import { getEmbeddableSource } from "../../../helpers/launcher";
import appConfig from "../../../appConfig";
const sourceUrl = getEmbeddableSource();
const modalDom = document.createElement("div");
modalDom.className =
  "pricing-before-after-modal modal custom-modal fade show d-block";
modalDom.id = "before-after-modal";
modalDom.ariaRoleDescription = "dialog";

const BrandingModal = (props) => {
  useEffect(() => {
    document.body.appendChild(modalDom);

    return () => {
      document.body.removeChild(modalDom);
    };
  }, []);

  return (
    (
      <div className="modal-dialog">
        <div className="modal-content modal-lg bg-white">
          <div className="modal-body">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={props.onCloseModal}
            >
              &times;
            </button>
            <div className={"container-fluid"}>
              <div className={"row"}>
                <div className={"col"}>
                  <br />
                  <br />
                </div>
              </div>
              <div className={"row"}>
                <div className={"col"}>
                  <h4>Why Branding</h4>
                  <p>
                    We are listening to your feedback. To keep it affordable for
                    you and operational for us, our plans come in two versions:
                    branded and unbranded. In the branded version, we add a
                    small logo to your calendar page. There is a small,
                    additional cost to remove the branding.{" "}
                    <a
                      href={`${appConfig.helpdeskAddress}/en/articles/5991210-pricing-branded-or-unbranded`}
                      target={"_blank-why-branding"}
                    >
                      More details here.
                    </a>
                  </p>
                </div>
              </div>
              <img
                src={`${sourceUrl}/img/branding/${appConfig.branding}/branding-before-after.gif`}
              />
            </div>
          </div>
        </div>
      </div>
    ),
    modalDom
  );
};

BrandingModal.defaultProps = {};

BrandingModal.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
};

export default BrandingModal;
