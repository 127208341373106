import React, { useCallback, useContext, useState } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import DesignTooltip from "./DesignTooltip";
import { BuilderInputSetupPropTypes } from "./BuilderOptionPropTypes";
import CssBoxInput from "./CssBoxInput";
import useDidMountEffect from "../../../../../hooks/useDidMountEffect";
import { CSSPositions, CSSUnits } from "../../../../../builder/consts";
import { CSSUnitSelector } from "./CSSUnitSelector";
import WidgetSettingsContext from "../../../../../contexts/WidgetSettingsContext";

const ToggleButton = ({ onClick, isLinked }) => (
  <div className={classnames("button-container", { linked: isLinked })}>
    <button onClick={onClick} className={"d-flex"}>
      <i className="material-icons" aria-hidden="true">
        {isLinked ? "lock" : "lock_open"}
      </i>
    </button>
  </div>
);

const CssBox = (props) => {
  const {
    title,
    name,
    updateDesignProp,
    defaultValue: _defaultValue,
    control,
  } = props;

  const widgetSettingsContext = useContext(WidgetSettingsContext);

  const linkedFieldName = `${name}Linked`;
  const positionValues = widgetSettingsContext[name] || "";
  const matchResult = positionValues.match(/([\d+\.]?\d+)([a-zA-Z%]+)/);
  const defaultUnit =
    matchResult && matchResult.length >= 2 && CSSUnits.includes(matchResult[2])
      ? matchResult[2]
      : CSSUnits[0];

  const defaultValue =
    matchResult && matchResult.length ? matchResult[1] : _defaultValue;

  const [dimension, setDimension] = useState(defaultUnit);
  const [values, setValues] = useState(() => {
    const parsedValues = positionValues
      .split(" ")
      .map((value) => parseFloat(value));

    const baseValue = !isNaN(parsedValues[0]) ? parsedValues[0] : defaultValue;

    return {
      top: !isNaN(parsedValues[0]) ? parsedValues[0] : defaultValue,
      right: !isNaN(parsedValues[1]) ? parsedValues[1] : baseValue,
      bottom: !isNaN(parsedValues[2]) ? parsedValues[2] : baseValue,
      left: !isNaN(parsedValues[3]) ? parsedValues[3] : baseValue,
    };
  });

  const [linked, setLinked] = useState(widgetSettingsContext[linkedFieldName]);

  /**
   *
   * @type {(function(*, *): void)|*}
   */
  const handleInputChange = useCallback(
    (value, position) => {
      if (linked) {
        setValues({
          top: value,
          right: value,
          bottom: value,
          left: value,
        });
      } else {
        setValues((prevValues) => ({ ...prevValues, [position]: value }));
      }
    },
    [linked]
  );

  const handleToggleChange = useCallback(() => {
    setLinked(!linked);
    updateDesignProp([
      { field: linkedFieldName, value: "custom" },
      {
        field: linkedFieldName,
        value: !linked,
      },
    ]);
  }, [setLinked, linked, updateDesignProp]);

  useDidMountEffect(() => {
    updateDesignProp([
      { field: name + "Option", value: "custom" },
      {
        field: name,
        value: !linked
          ? `${values.top}${dimension} ${values.right}${dimension} ${values.bottom}${dimension} ${values.left}${dimension}`
          : `${values.top}${dimension} ${values.top}${dimension} ${values.top}${dimension} ${values.top}${dimension}`,
      },
    ]);
  }, [values, dimension]);

  return (
    <div className={"design-element"}>
      <div className={"d-flex align-items-center justify-content-between"}>
        <label className={classnames("option-label")}>
          <span className={"option-title"}>{title}</span>
          <DesignTooltip help={props.tooltip} />
        </label>
        <CSSUnitSelector value={dimension} setValue={setDimension} />
      </div>
      <div className="cssbox-container">
        {CSSPositions.map((position) => (
          <CssBoxInput
            key={`${name}_${position}`}
            title={`${position.charAt(0).toUpperCase() + position.slice(1)}`}
            value={values[position]}
            placeholder={control.options.placeholder}
            setValue={handleInputChange}
            position={position}
            inputClassName={"text-center"}
          />
        ))}
        <ToggleButton onClick={handleToggleChange} isLinked={linked} />
      </div>
    </div>
  );
};

CssBox.propTypes = {
  ...BuilderInputSetupPropTypes,

  control: PropTypes.shape({
    kind: PropTypes.oneOf(["cssBox"]).isRequired,
    options: PropTypes.shape({
      placeholder: PropTypes.string,
    }),
  }).isRequired,
};

export default CssBox;
