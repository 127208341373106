/**
 * Created by piotr.pozniak@thebeaverhead.com on 20/11/2023.
 */

import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as organizationActions from "../../actions/organizations";
import { deleteOrganization } from "../../actions/organizations";

export function useOrganizationsStore() {
  const dispatch = useDispatch();
  const _organizations = useSelector((store) => store.organizations);

  const fetch = useCallback(
    async (page, ipp, name, email, sort, direction) =>
      await dispatch(
        organizationActions.fetchOrganization(
          page,
          ipp,
          name,
          email,
          sort,
          direction
        )
      ),
    [dispatch]
  );

  const deleteOrganization = useCallback(
    async (uuid) =>
      await dispatch(organizationActions.deleteOrganization(uuid)),
    [dispatch]
  );

  return {
    organizations: _organizations,
    fetch,
    deleteOrganization,
  };
}
