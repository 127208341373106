/**
 * Created by piotr.pozniak@thebeaverhead.com on 05/04/2022.
 */

import React, { useCallback, useContext, useEffect, useState } from "react";

import PropTypes from "prop-types";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import CardView from "./CardView/CardView";
import { GT, PT, ST } from "../../../consts";
import { useAppStore } from "../../../hooks/redux/app";
import classnames from "classnames";
import { WT } from "../../admin/consts/widget";
import useAggressiveResponsiveWidget from "../../../hooks/useAgressiveResponsiveWidget";
import { getAllOptionValues } from "../../../builder";
// import Header from "./Header/Header";
import { filtersToQueryParam } from "../../../helpers/filters";
import GroupModal from "./GroupModal";
import PoweredBy from "../../calendar/components/PoweredBy";
import { useWidgetStore } from "../../../hooks/redux/widget";
import IntegrationContext from "../../../contexts/IntegrationContext";
import WidgetSettingsContext from "../../../contexts/WidgetSettingsContext";

const WidgetComponents = {
  [ST.cardGrid]: CardView,
  //[ST.cardGrid]: C,
};

/**
 *
 * @param sortOptions
 * @returns {{sort: *, order: *}}
 */
const sortOptionsToParam = (sortOptions) => {
  if (!sortOptions) {
    return { sort: "", order: "" };
  }

  const [sort, order] = sortOptions.split(":");

  return {
    sort,
    order,
  };
};

const Signup = (props) => {
  const { widget, widgetItems, fetchItems, fetchItem } = useWidgetStore();
  const { app } = useAppStore();
  const widgetSettings = getAllOptionValues(widget.model);
  const [selectedItem, setSelectedItem] = useState(null);

  const hasValidProps = widget.model.slug ? true : false;
  const hasSingleGroup = props.singleGroupSlug ? true : false;
  const integrationContext = useContext(IntegrationContext);

  const widgetId = `dce_${WT.signups}__${widget.model.uuid}`;
  const hasAggressiveResponsiveness =
    Number.parseInt(widgetSettings["WAggressiveResp"]) === 1;

  const showPopup = Number.parseInt(widgetSettings["gPopupShow"]) === 1;

  const [state, setState] = useState({
    initialized: hasValidProps,
    page: 1,
  });

  const [selectedFilters, setSelectedFilters] = useState(
    integrationContext.availableFilters.reduce(
      (i, j) => ({ ...i, [j]: {} }),
      {}
    )
  );

  useAggressiveResponsiveWidget(
    widgetId,
    600,
    "dce-ar-responsiveness",
    hasAggressiveResponsiveness
  );

  useEffect(() => {
    if (widget.model.slug) {
      setState({
        ...state,
        initialized: true,
      });
      window.__DCE[WT.signups].init = false;
    }
  }, [widget.model.slug]);

  useEffect(() => {
    if (hasSingleGroup) {
      fetchItem(widget.model.uuid, props.singleGroupSlug);
    } else if (hasValidProps) {
      _fetchItems();
    }
  }, []);

  useDidMountEffect(() => {
    if (widget.model.template) {
      _fetchItems();
    }
  }, [
    state.page,
    widget.model.filter_settings,
    selectedFilters,
    widget.model.widget_settings.sorting,
  ]);

  useEffect(() => {
    if (hasSingleGroup && widgetItems.fetchSuccess) {
      setSelectedItem(widgetItems.collection[0]);
    }
  }, [widgetItems.fetchSuccess]);

  /**
   *
   * @param location
   */
  const onChangeFilter = useCallback(
    (filter) => {
      const _selectedFilters = { ...selectedFilters };
      _selectedFilters[filter.kind] = filter;

      setSelectedFilters(_selectedFilters);
    },
    [selectedFilters]
  );

  /**
   * TODO
   */
  const _fetchItems = async () => {
    //const { store } = configureStore();

    if (widgetItems.fetch || hasSingleGroup) {
      return false;
    }

    // const filters = filtersToQueryParam(selectedFilters);
    //
    await fetchItems(
      widgetType,
      widget.model.uuid,
      sortOptionsToParam(widget.model.widget_settings?.sorting?.sort),
      null,
      false
    );
  };

  /**
   *
   * @type {(function(*): void)|*}
   */
  const onSelectGroup = useCallback(
    (widget) => {
      setSelectedItem(widget);
    },
    [selectedItem]
  );

  /**
   *
   * @type {(function(): void)|*}
   */
  const closeGroupModal = useCallback(() => {
    setSelectedItem(null);
  }, [selectedItem]);

  /**
   *
   * @param page
   */
  const onLoadMore = useCallback((page) => {});

  const widgetTemplate = widget.model.template;
  const widgetType = widget.model.widget_type;

  const WidgetComponent = WidgetComponents[widgetTemplate];

  const widgetComponent = (
    <WidgetComponent
      embedded={props.embedded}
      widget={widget}
      items={widgetItems}
      initialized={state.initialized}
      onLoadMore={onLoadMore}
      previewType={app.previewType}
      onSelectedEvent={onSelectGroup}
    />
  );

  const header = null; /*(
    <Header onChangeFilter={onChangeFilter} selectedFilters={selectedFilters} />
  );*/

  const selectedItemModal =
    (hasSingleGroup && selectedItem) || (showPopup && selectedItem) ? (
      <GroupModal
        widgetId={widgetId}
        widget={selectedItem}
        closeModal={closeGroupModal}
        widgetSlug={widget.model.slug}
        isSingleItem={hasSingleGroup}
      />
    ) : null;

  const watermark =
    widget.model && widget.model.is_watermarked ? <PoweredBy /> : null;

  const content = !hasSingleGroup ? (
    app.previewType === PT.mobile ? (
      <div className={"mobile-preview--smartphone"}>
        {header}
        {widgetComponent}
      </div>
    ) : (
      <React.Fragment>
        {header}
        {widgetComponent}
      </React.Fragment>
    )
  ) : null;

  return (
    <WidgetSettingsContext.Provider value={widgetSettings}>
      <div
        className={classnames(widgetId, WT.signups, `dce-${WT.signups}`, {
          "mobile-preview": app.previewType === PT.mobile,
        })}
      >
        {/*<CalendarSettingsContext.Provider value={calendarSettings}>*/}
        {content}
        {selectedItemModal}
        {/*</CalendarSettingsContext.Provider>*/}
        {watermark}
      </div>
    </WidgetSettingsContext.Provider>
  );
};

Signup.defaultProps = {};

Signup.propTypes = {
  embedded: PropTypes.bool,
};

export default Signup;
