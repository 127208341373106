/**
 * Created by piotr.pozniak@thebeaverhead.com on 26/07/2021.
 */

import React, { useState } from "react";
import { BreezeIntegrationAddress as address, IT } from "../../../../consts";
import ApiError from "../../component/ApiError";
import FormError from "../../component/FormError";
import LoadingIndicator from "../../../calendar/components/LoadingIndicator";
import { useIntegrationStore } from "../../../../hooks/redux/integration";
import { useUserStore } from "../../../../hooks/redux/user";
import { store } from "../../store";
import WelcomeCard from "./WelcomeCard";
import OpenAIForm from "./OpenAIForm";
import classname from "classnames";
import appConfig from "../../../../appConfig";

export const BreezeIntegrationHelp = () => {
  return (
    <p>
      To learn more about the BreezeChMS API integration, please click on{" "}
      <a
        href={
          "https://help.display.church/en/articles/5669866-dce-integrations-how-to-set-them-up"
        }
        target="_blank"
      >
        this address
      </a>
      .
    </p>
  );
};

export const BreezeBeforeSyncTooltip = () => {
  return (
    <p
      className={"design-tooltip integration-card--beta-info"}
      data-toggle="tooltip"
      data-placement="top"
      data-trigger={"click"}
      title={`Did you wait 15 minutes? Due to the way Breeze caches updates, it is recommended to wait for 15 minutes (fifteen minutes) BEFORE doing your ${appConfig.shortDisplayName} sync. In that way, all your changes will be available for syncing.`}
    >
      <span className={"alert alert-warning"}>
        <i className="material-icons" aria-hidden={true}>
          info
        </i>
        Did you wait 15 minutes?
      </span>
    </p>
  );
};

const BreezeIntegrationForm = (props) => {
  const { integration, updateIntegration, syncIntegration } =
    useIntegrationStore();

  const integrationModel =
    integration.model && integration.model.uuid ? integration.model : null;

  const [state, setState] = useState({
    //nameError: null,
    apiKeyError: null,
    addressError: null,
  });

  /**
   *
   */
  const onSubmit = async (e) => {
    e.preventDefault();

    const apiKey = document.querySelector("#integration_api_key").value;
    const name = "Breeze Integration"; //document.querySelector("#integration_name").value;
    const addressSubdomain = document.querySelector(
      "#integration_address"
    ).value;

    const available =
      integrationModel && integrationModel.uuid
        ? integrationModel.available
        : 1;

    let apiKeyError = null;
    //let nameError = null;
    let passwordError = null;
    let addressError = null;

    if (!apiKey || !apiKey.length) {
      apiKeyError = "Please enter API Key. ";
    }

    if (!addressSubdomain || !addressSubdomain.length) {
      addressError = "Please enter API Endpoint Address Subdomain. ";
    }

    setState({
      ...state,
      apiKeyError,
      //nameError,
      addressError,
    });

    if (!apiKeyError && !addressError /*&& !nameError*/) {
      const integration_scopes = [
        {
          name: "calendar",
          available: 1,
        },
      ];

      const data = {
        ...integrationModel,
        auth_token: apiKey,
        name,
        address: address.scheme + addressSubdomain + address.host,
        available,
        type: "breeze",
        integration_scopes,
      };

      await updateIntegration(
        integrationModel && integrationModel.uuid
          ? integrationModel.uuid
          : null,
        data
      );
    }

    return false;
  };

  const { update, updateError } = integration;

  const loadingIndicator = update ? <LoadingIndicator /> : null;

  const isLoading = update;
  const isNew = !integrationModel ? true : false;

  if (!isNew) {
    address.subdomain = integrationModel.address.split("://")[1].split(".")[0];
  }

  const apiAccessCheckWarning =
    isNew && update ? (
      <div className={"alert alert-info"}>
        Please be patient. We are checking your Breeze API configuration - it
        can take up to 30 seconds.
      </div>
    ) : null;

  const newSyncAlert = isNew ? (
    <div className={"alert alert-warning"}>
      Sync process may take between 5 to 10 minutes to finish. Once you create a
      new integration, sync process will start automatically.
    </div>
  ) : null;

  const submitButtonLabel = isNew ? "Start Integration" : "Update";
  const openAIForm = !isNew ? <OpenAIForm /> : null;

  return (
    <React.Fragment>
      <div className={"col-xs-12 col-md-12"}>
        <WelcomeCard
          integrationName={"Breeze"}
          imageSrc={"/img/integrations_howto/howto_breeze.png"}
          videoId={"32758233240e4149b8aea643565c9021"}
          helpArticleUrl={
            "https://help.display.church/en/articles/5669866-dce-integrations-how-to-set-them-up"
          }
        >
          <p>
            To synchronize with BreezeChMS, enter your API key in the form
            below. We will use it to pull your calendars and events entries from
            your Breeze account. You will also be able to manually pull the
            Breeze data.
          </p>
        </WelcomeCard>
      </div>
      <div className={classname("col-xs-12", { "col-lg-6": !isNew })}>
        <div className={"card pt-sm-4 pl-sm-4 pr-sm-4"}>
          <form className={"card-body"}>
            <div className={"row"}>
              <div className="form-group col-xs-12 col-md-6">
                <label>
                  API Key <span className="text-danger">*</span>
                </label>

                <input
                  id="integration_api_key"
                  className={
                    "form-control " + (state.apiKeyError ? "is-invalid" : "")
                  }
                  type="text"
                  placeholder="API Key"
                  defaultValue={isNew ? "" : integrationModel.auth_token}
                  disabled={isLoading}
                />
              </div>

              <div className="form-group col-xs-12 col-md-10">
                <label>
                  Breeze Account Address <span className="text-danger">*</span>
                </label>

                <div className="integration-address-container">
                  <span className="integration-address-static">
                    {address.scheme}
                  </span>
                  <input
                    className={
                      "form-control integration-address-input " +
                      (state.addressError ? "is-invalid" : "")
                    }
                    id="integration_address"
                    type="text"
                    placeholder="Subdomain"
                    defaultValue={isNew ? "" : address.subdomain}
                    disabled={!isNew || isLoading}
                  />
                  <span className="integration-address-static">
                    {address.host}
                  </span>
                </div>
              </div>

              <div className={"col-xs-12 col-md-12"}>
                <div className="m-t-20 text-center">
                  <div className="form-group">
                    {newSyncAlert}
                    <button
                      className="btn btn-primary btn-lg"
                      onClick={onSubmit}
                      disabled={isLoading}
                    >
                      {submitButtonLabel}
                    </button>
                  </div>
                </div>
              </div>
              <div className={"col-xs-12"}>
                {apiAccessCheckWarning}

                <ApiError
                  error={updateError}
                  defaultErrorMessage={
                    "Could not add Api User. Please try again"
                  }
                />
                {loadingIndicator}

                <FormError
                  errors={[
                    /*state.nameError,*/
                    state.apiKeyError,
                    state.addressError,
                  ]}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      {openAIForm}
    </React.Fragment>
  );
};

export default BreezeIntegrationForm;
