/**
 * Created by piotr.pozniak@thebeaverhead.com on 23/07/2021.
 */
import PageTemplate from "../templates/PageTemplate";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import classnames from "classnames";
import PropTypes from "prop-types";
import appConfig from "../../../appConfig";

/**
 * For race condition if jQuery didn't load yet.
 * @param criteria
 * @param callback
 * @param timeout
 */

const ModalTemplate = (props) => {
  const history = useHistory();

  useEffect(() => {
    const handleModal = () => {
      window.$("#" + props.id).modal("show");

      return () => {
        window.$("#" + props.id).modal("hide");
        setTimeout(() => {
          window.$(".modal-backdrop.fade.show").remove();
          document.body.className = document.body.className.replace(
            "modal-open",
            ""
          );
        }, 300);
      };
    };

    return handleModal();
  }, []);

  /**
   *
   * @param e
   * @returns {boolean|*}
   */
  const onClose = (e) => {
    // console.log("onClose");
    if (e && e.preventDefault) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (props.onClose) {
      return props.onClose();
    }

    history.goBack();

    return false;
  };

  return (
    <PageTemplate sidebarId={props.sidebarId} title={props.title}>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div
            id={props.id}
            className={classnames("modal custom-modal fade")}
            role="dialog"
          >
            <div className="modal-dialog">
              <div
                className={classnames("modal-content", "modal-" + props.size)}
              >
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={onClose}
                >
                  ×
                </button>
                <div className="modal-header">
                  <h4 className="modal-title">
                    <img
                      src={`/img/branding/${appConfig.branding}/icon/ms-icon-310x310.png`}
                      width={24}
                      height={24}
                      className={"m-r-5 d-xs-block d-lg-none"}
                    />
                    {props.header}
                  </h4>
                </div>
                <div className="modal-body">{props.children}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageTemplate>
  );
};

ModalTemplate.defaultProps = {
  size: "md",
};

ModalTemplate.propTypes = {
  size: PropTypes.string,
  onClose: PropTypes.func,
  header: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  sidebarId: PropTypes.string,
};

export default ModalTemplate;
