/**
 * Created by piotr.pozniak@thebeaverhead.com on 02/08/2024
 */

import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useAffiliatesStore } from "../../../../hooks/redux/affiliates";
import classNames from "classnames";
import LinkTableRow from "./LinkTableRow";

const LinksTable = (props) => {
  const { affiliates } = useAffiliatesStore();

  const rows = affiliates.model?.links?.map((i, idx) => {
    return <LinkTableRow key={i.id} link={i} />;
  });

  const stats = useMemo(
    () => ({
      visitors: affiliates?.model?.links.reduce(
        (acc, i) => acc + i.visitors,
        0
      ),
      leads: affiliates?.model?.links.reduce((acc, i) => acc + i.leads, 0),
      conversions: affiliates?.model?.links.reduce(
        (acc, i) => acc + i.conversions,
        0
      ),
    }),
    []
  );

  return (
    <div>
      <h4>Links</h4>
      <table className={"table"}>
        <thead>
          <tr>
            <th>Link</th>
            <th>Token</th>
            <th>Visitors</th>
            <th>Leads</th>
            <th>Conversions</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
        <tfoot>
          <tr>
            <th colSpan={2}></th>
            <th>{stats.visitors}</th>
            <th>{stats.leads}</th>
            <th>{stats.conversions}</th>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

LinksTable.defaultProps = {};

LinksTable.propTypes = {};

export default LinksTable;
