/**
 * Created by piotr.pozniak@thebeaverhead.com on 18/06/2021.
 */

import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as importsActions from "../../actions/imports";

export function useImportsStore() {
  const dispatch = useDispatch();
  const _imports = useSelector((store) => store.imports);

  const fetchImports = useCallback(
    async (page, limit) =>
      await dispatch(importsActions.fetchImports(page, limit)),
    [dispatch]
  );

  return {
    imports: _imports,
    fetchImports,
  };
}
