/**
 * Created by piotr.pozniak@thebeaverhead.com on 14/05/2024
 */

import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import appConfig from "../../../../appConfig";
import DesignTooltip from "../../component/Builder/Input/DesignTooltip";

const FileUpload = ({
  type,
  src,
  width,
  maxFileSizeKb,
  tooltipMessage,
  title,
  onChange,
}) => {
  const [selectedFile, setSelectedFile] = useState(src);
  const [fileTooBigAlert, setFileTooBigAlert] = useState(null);

  const inputFileRef = React.createRef();

  /**
   *
   */
  const onSelectFileClick = useCallback(() => {
    inputFileRef.current.click();
  }, [inputFileRef, selectedFile]);

  /**
   *
   * @type {(function(): void)|*}
   */
  const onRemoveFile = useCallback(() => {
    setSelectedFile(null);
    onChange(null);
  }, [selectedFile, inputFileRef, fileTooBigAlert, onChange]);

  /**
   *
   * @type {(function(*): void)|*}
   */
  const onSelectFile = useCallback(
    (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        if (file.size > maxFileSizeKb * 1024) {
          setFileTooBigAlert(true);
        } else {
          setSelectedFile(e.target.result);
          onChange(file);
        }
      };
      reader.readAsDataURL(file);
    },
    [selectedFile, inputFileRef, fileTooBigAlert, onChange]
  );

  const preview = selectedFile ? (
    <div className={"branding--upload-preview"}>
      <div className={"branding--upload-remove"} onClick={onRemoveFile}>
        <i className={"material-icons"}>close</i>{" "}
      </div>
      <div className={"branding--upload-preview-img"}>
        <img width={width} className={"img-responsive"} src={selectedFile} />
      </div>
    </div>
  ) : null;

  const uploadBtn = !selectedFile ? (
    <div className={"branding--upload-btn"} onClick={onSelectFileClick}>
      <i className={"material-icons"}>add</i>{" "}
    </div>
  ) : null;

  const alert = fileTooBigAlert ? (
    <div className={"text-danger"}>
      <i className={"material-icons icon-xs"}>error</i> Please upload an image
      that’s less than {maxFileSizeKb}KB.
    </div>
  ) : null;

  return (
    <div className={"form-group row"}>
      <div className={"col-3 col-form-label d-flex"}>
        {title}{" "}
        <DesignTooltip iconClassName={"icon-sm"} help={tooltipMessage} />
      </div>
      <div className={"col-9 d-flex justify-content-end"}>
        <div className={"branding--upload-container"}>
          <div>
            {preview}
            {uploadBtn}
            <input
              type={"file"}
              name={"file"}
              id={`file-${type}`}
              ref={inputFileRef}
              onChange={onSelectFile}
              accept="image/png, image/jpeg"
            />
          </div>
        </div>
      </div>
      <div className={"col-12"}>{alert}</div>
    </div>
  );
};

FileUpload.defaultProps = {
  width: 48,
  maxFileSizeKb: 16,
};

FileUpload.propTypes = {
  type: PropTypes.string.isRequired,
  src: PropTypes.string,
  width: PropTypes.number,
  maxFileSizeKb: PropTypes.number,
  tooltipMessage: PropTypes.object,
  title: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default FileUpload;
