/**
 * Created by piotr.pozniak@thebeaverhead.com on 05/04/2022.
 */

import React, { useEffect } from "react";

import { Link, useHistory } from "react-router-dom";
import PageTemplate from "./../templates/PageTemplate";
import { signupFeaturesCheck } from "../tools";
import WidgetsIndexTable from "../component/WidgetPages/WidgetsIndexTable";
import { WT } from "../consts/widget";
import SignupTypeName from "./SIgnupsPage/SignupTypeName";
import { getShareableUrl, newSignupUrl } from "../../signups/tools";

const SignupsPage = (props) => {
  /**
   *
   * @param domain
   */

  return (
    <PageTemplate
      sidebarId={WT.signups}
      title={"Sign-up Forms"}
      icon={"edit_note"}
      headerControls={[
        <Link
          key={newSignupUrl}
          to={newSignupUrl}
          className="btn btn-primary btn-rounded pull-right d-flex"
        >
          <i className="material-icons">add</i> Create new
        </Link>,
      ]}
    >
      <WidgetsIndexTable
        widgetType={WT.signups}
        featuresCheck={signupFeaturesCheck}
        nameComponent={SignupTypeName}
        shareableUrl={getShareableUrl}
        newWidgetUrl={newSignupUrl}
      />
    </PageTemplate>
  );
};

export default SignupsPage;
