/**
 * Created by piotr.pozniak@thebeaverhead.com on 14/12/2023
 */

import React from "react";
import PropTypes from "prop-types";

const Hr = (props) => {
  const title = props.title ? (
    <span className="chat-date gap-xs">{props.title}</span>
  ) : null;

  return <div className="chat-line">{title}</div>;
};

Hr.defaultProps = {};

Hr.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default Hr;
