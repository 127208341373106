/**
 *
 * @param error
 * @param defaultMessage
 * @returns {*}
 */
import { ucFirst } from "./string";

export function formatError(error, defaultMessage = "Error has occurred") {
  const errors = [];

  if (error && error.response && error.response.data) {
    const data = error.response.data;

    if (data.error && Array.isArray(data.error) && data.error.length) {
      let error = "";

      data.error.map((i) => {
        if (i.messages && i.messages.length) {
          const fieldName = ucFirst(i.field.replace("_", " "));

          i.messages.map((i) => {
            const multilines = i.split("\n");

            if (multilines.length > 1) {
              multilines.map((j) => {
                errors.push(j);
              });
            } else {
              errors.push(fieldName + " - " + i);
            }
          });
        }
      });

      return errors;
    }

    if (error.response.data.message) {
      return [error.response.data.message];
    }
  } else if (error && error.message) {
    return [error.message];
  }

  return [defaultMessage];
}
