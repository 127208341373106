import React, { useEffect, useState } from "react";
import { Route, Switch, withRouter, matchPath } from "react-router-dom";
import { history } from "./store";

import useDidMountEffect from "../../hooks/useDidMountEffect";
import NewIntegrationModal from "./modals/NewIntegrationModal";
import EditIntegrationModal from "./modals/EditIntegrationModal";
import DomainModal from "./modals/DomainModal";
import AdminOrgEditModal from "./modals/AdminOrgEditModal";
import CancelSubscriptionModal from "./modals/CancelSubscriptionModal";
import SubscribeModal from "./modals/SubscribeModal";
import UpdateBillingDetailsModal from "./modals/UpdateBillingDetailsModal";
import appConfig from "../../appConfig";
import ManageMembersModal from "./modals/ManageMembersModal";

const ModalRoutesComponent = React.memo(
  (props) => {
    const [modalClosePath, setModalClosePath] = useState(null);

    useDidMountEffect(() => {
      updateModalClosePath();
    });

    useEffect(() => {
      setModalClosePath(null);
    }, [props.location.pathname]);

    /**
     *
     */
    const updateModalClosePath = () => {
      const modalLinkIndex = props.prevModalLocation.findIndex((modalLink) => {
        const match = matchPath(props.location.pathname, {
          path: modalLink,
          exact: true,
          strict: false,
        });

        return match !== null;
      });

      if (modalLinkIndex >= 0) {
        setModalClosePath(
          Object.values(props.prevModalLocation[modalLinkIndex])[0]
        );
      }
    };

    /**
     *
     */
    const onCloseAction = () => {
      if (modalClosePath) {
        history.push(modalClosePath);
      } else {
        history.goBack();
      }
    };
    const isAdmin = props.user.model && props.user.model.role == "admins";

    return (
      <Switch>
        <Route exact path="/integration/new/" component={NewIntegrationModal} />
        <Route path="/integration/new/:type" component={NewIntegrationModal} />
        <Route path="/integration/:id" component={EditIntegrationModal} />
        <Route path="/domains/:uuid" component={DomainModal} />
        <Route
          path={`/${appConfig.links.organization}/edit/:uuid`}
          component={AdminOrgEditModal}
        />
        <Route
          path="/billing/cancel-subscription"
          component={CancelSubscriptionModal}
        />
        <Route
          path={"/billing/update-billing-details"}
          component={UpdateBillingDetailsModal}
        />
        <Route
          path={"/subscribe/:planCode/:period/:isBranded"}
          component={SubscribeModal}
        />
        <Route path={"/manage-members"} component={ManageMembersModal} />
      </Switch>
    );
  },
  (oldProps, newProps) => {
    if (oldProps.location.pathname !== newProps.location.pathname) {
      return false;
    } else {
      // don't update, locations are the same
      return true;
    }
  }
);

export default withRouter(ModalRoutesComponent);
