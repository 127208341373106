/**
 * Created by piotr.pozniak@thebeaverhead.com on 22/06/2022.
 */

import React, { useContext, useEffect } from "react";
import PropTypes, { func } from "prop-types";
import IntegrationContext from "../../../../groups/strategies/IntegrationContext";
import { useFiltersStore } from "../../../../../hooks/redux/filters";
import ExpandableAlert from "../../../component/ExpandableAlert";
import Filters from "../../../component/Filters/Filters";
import useFiltersHook from "../../../hooks/useFiltersHook";
import Accordion from "../../../component/WidgetSettings/Acordion";
import { IT } from "../../../../../consts";

const getSummary = (filters) => {
  let filtersSummary = "";
  if (filters && filters.groups.length) {
    const rule = filters.groups[0].rules[0];

    filtersSummary =
      " groups filtered by " +
      rule.field.label +
      " " +
      rule.operator.label +
      " " +
      rule.label;

    if (filters.groups.length > 1 || filters.groups[0].rules.length > 1) {
      let filtersCount = -1;
      for (const gIdx in filters.groups) {
        const group = filters.groups[gIdx];
        filtersCount += group.rules.length;
      }
      filtersSummary += " and " + filtersCount + " more.";
    } else {
      filtersSummary += ".";
    }
  } else {
    filtersSummary = " all groups.";
  }

  return (
    <span className={"d-flex"}>
      <i className={"material-icons text-info p-0 m-0"}>info</i>
      <span className={"m-1"}>{"Shows " + filtersSummary}</span>
    </span>
  );
};

const EventsFiltering = (props) => {
  const integrationStrategy = useContext(IntegrationContext);

  const { fetchIntegrationFilters, clearFilters } = useFiltersStore();
  const { availableFilters } = useFiltersHook({
    widget: props.widget,
    integrationStrategy,
  });

  useEffect(() => {
    const filtersToFetch = integrationStrategy.availableFilters
      .map((kind) => {
        const settings = integrationStrategy._filters.find(
          (i) => i.field.value === kind
        );
        return settings.fetch !== undefined && settings.fetch === false
          ? null
          : kind;
      })
      .filter((i) => i);

    fetchIntegrationFilters(
      props.widget.model.integration.uuid,
      filtersToFetch
    );

    return () => {
      clearFilters();
    };
  }, [props.integrationModel]);

  const { widgetModel } = props;

  const { widget } = props;

  const showInfoAlert = integrationStrategy.filterLabelCTA ? (
    <ExpandableAlert
      errorLevel={"info"}
      header={"How to use filters below?"}
      body={integrationStrategy.filterLabelCTA}
    />
  ) : null;

  const widgetFilters = widgetModel.filter_settings
    ? widgetModel.filter_settings.filters
    : null;

  const subtitle = getSummary(
    widgetModel.filter_settings ? widgetModel.filter_settings.filters : null
  );

  return (
    <Accordion title={"Filtering"} subtitle={subtitle} defaultExpanded={true}>
      {/*
      <form action="#">

        <RadioInput
          fieldName={"show_events_with_images"}
          label={"Show events with images only"}
          onChange={onFilteringCheckboxChange}
          checked={widgetModel.show_events_with_images * 1}
          tooltip={{
            url: "https://help.display.church/en/articles/4901525-setup-events-show-events-with-images-only",
            message: "Show events with images only",
          }}
        />

      </form>*/}

      {/*<SubscriptionAccess
        allowedPlans={allowedPlans}
        CTAMessage={
          "You can limit events by selecting specific filters. Upgrade your plan to unlock this functionality. "
        }
      >*/}
      {showInfoAlert}
      <form action="#">
        <Filters
          availableFilters={availableFilters}
          onChange={props.onUpdateFilters}
          filters={widgetFilters}
        />
      </form>
      {/*</SubscriptionAccess>*/}
    </Accordion>
  );
};

EventsFiltering.propTypes = {
  widget: PropTypes.object.isRequired,
  widgetModel: PropTypes.object.isRequired,
  integrationModel: PropTypes.object.isRequired,
  onUpdateFilters: PropTypes.func.isRequired,
};

export default EventsFiltering;
