/**
 * Created by piotr.pozniak@thebeaverhead.com on 12/07/2022.
 */

import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import RowModal from "../EventsList/RowModal";
import InfiniteScrollWrapper from "../InfiniteScrollWrapper";
import LoadingIndicator from "../LoadingIndicator";
import { CT, PT } from "../../../../consts";
import PoweredBy from "../PoweredBy";
import CalendarHeader from "./CalendarHeader";
import NoEvents from "../NoEvents";
import EventRow from "./EventRow";
import Divider from "./Divider";
import { openEventPage } from "../../../../helpers/calendar";
import SearchInput from "../AiSearch/SearchInput";
import useAiSearch from "../../../../hooks/useAiSearch";
import WidgetSettingsContext from "../../../../contexts/WidgetSettingsContext";

const DetailedList = (props) => {
  const [state, setState] = useState({
    selectedEvent: null,
    onModalOpenSelector: null,
  });

  const { calendar, events } = props;

  const calendarSettingsContext = useContext(WidgetSettingsContext);

  const { hasAIEnabled } = useAiSearch(calendar.model.integration);

  const loading =
    calendar.rsvp ||
    calendar.fetch ||
    calendar.delete ||
    calendar.update ||
    events.fetch;

  const showDetailsAsModal =
    Number.parseInt(calendarSettingsContext["generalOpenDetailsModal"]) === 1;

  const hasInfiniteScroll =
    Number.parseInt(calendarSettingsContext["infiniteScroll"]) === 1;

  const showDescription =
    Number.parseInt(calendarSettingsContext["dLShowDescription"]) === 1;

  const showRSVP =
    Number.parseInt(calendarSettingsContext["rsvpShowForOpenForAll"]) === 1;

  const showLocation =
    Number.parseInt(calendarSettingsContext["dLShowLocation"]) === 1;

  const showTimezone =
    Number.parseInt(calendarSettingsContext["generalShowTz"]) === 1;

  const showSummary =
    Number.parseInt(calendarSettingsContext["dLShowSummary"]) === 1;

  const showEventDate =
    Number.parseInt(calendarSettingsContext["dLShowDate"]) === 1;
  const showRepeatIcon =
    Number.parseInt(calendarSettingsContext["dLShowRepeatIcon"]) === 1;

  const openChurchCenter =
    Number.parseInt(calendarSettingsContext["generalGoToChurchCenter"]) === 1;

  const openLinkInNewTab =
    Number.parseInt(calendarSettingsContext["openLinksNewTab"]) === 1;

  const showCTA =
    Number.parseInt(calendarSettingsContext["cDetailedShowCta1st"]) === 1;

  const primaryCtaAction = calendarSettingsContext["cDetailedCTAAction1st"];
  const isPrimaryCTADefaultLabel =
    Number.parseInt(calendarSettingsContext["cDetailed1stDefaultCTALabel"]) ===
    1;
  const primaryCTACustomLabel = calendarSettingsContext["cDetailed1stCTALabel"];

  /*
  const inpicDatePlacement = getOptionValue("cwDateLocation");
  const showDate = Number.parseInt(getOptionValue("ccShowDate")) === 1;
  const showLocation = Number.parseInt(getOptionValue("ccShowLocation")) === 1;

  const hoverEffect = getOptionValue("cwHoverEffect");
  const showWeekDayInList =
    Number.parseInt(getOptionValue("eventDetailsShowDayOfWeek")) === 1;
  const weekDayFormatter = getOptionValue("eventsShowDayWeekOption");

  const useEventAccent =
    Number.parseInt(getOptionValue("generalUseEventAccent")) === 1;
  const showImage =
    Number.parseInt(getOptionValue("eventDetailsShowImage")) == 1;



  const recurringShowBadge =
    Number.parseInt(getOptionValue("recurringShowBadge")) === 1;
  const featuredShowBadge =
    Number.parseInt(getOptionValue("featuredShowBadge")) === 1;

  const badgesLocation = getOptionValue("recurringBadgeLocation");
*/
  const watermark =
    calendar.model && calendar.model.is_watermarked ? (
      <PoweredBy align={hasInfiniteScroll ? "right" : "left"} />
    ) : null;

  /**
   *
   * @param event
   */
  const onSelectedEvent = (event, onModalOpenSelector = null) => {
    if (openChurchCenter) {
      openEventPage(event, openLinkInNewTab);
      return;
    }

    if (showDetailsAsModal) {
      setState({ ...state, selectedEvent: event, onModalOpenSelector });
    }
  };

  /**
   *
   * @param rowId
   */
  const onCloseOpenedEventsDetails = (rowId) => {
    setState({ ...state, selectedEvent: null });

    if (props.onClosedEventRowModal) {
      props.onClosedEventRowModal();
    }
  };

  const calendarID = "dce_calendar__" + calendar.model.uuid;

  let previousDay = new Date(0);
  //const tzOffset = new Date().getTimezoneOffset() * 60 * 1000;

  const eventsList = events.collection.map((i, idx) => {
    const eventStartTime = new Date(i.start_time * 1000 /* + tzOffset*/);
    const isOtherDay = eventStartTime.getMonth() != previousDay.getMonth();
    const divider = isOtherDay ? <Divider date={eventStartTime} /> : null;

    previousDay = isOtherDay ? eventStartTime : previousDay;

    const primaryCtaLink = i.ctas[primaryCtaAction] || null;
    const label = primaryCtaLink ? primaryCtaLink.label : "";
    const ctaLabel = isPrimaryCTADefaultLabel ? label : primaryCTACustomLabel;

    return (
      <React.Fragment>
        {divider}
        <EventRow
          key={i.slug}
          event={i}
          showDescription={showDescription}
          onSelectedEvent={onSelectedEvent}
          showDetailsAsModal={showDetailsAsModal}
          showRSVP={showRSVP}
          showLocation={showLocation}
          showTimezone={showTimezone}
          showSummary={showSummary}
          showEventDate={showEventDate}
          showRepeatIcon={showRepeatIcon}
          showCTA={showCTA}
          ctaLabel={ctaLabel}
          ctaURL={primaryCtaLink ? primaryCtaLink.url : ""}
        />
      </React.Fragment>
    );
  });

  /*const cardEvents = events.collection.map((i, idx) => {
    return (
      <CardEvent
        key={i.slug}
        event={i}
        onSelectedEvent={onSelectedEvent}
        inpicDatePlacement={inpicDatePlacement}
        showDate={showDate}
        showLocation={showLocation}
        showDescription={showDescription}
        showSummary={showSummary}
        hoverEffect={hoverEffect}
        embedded={props.embedded}
        showWeekDay={showWeekDayInList}
        weekDayFormatter={weekDayFormatter}
        useEventAccent={useEventAccent}
        showImage={showImage}
        showRecurringBadge={recurringShowBadge}
        showFeaturedBadge={featuredShowBadge}
        badgesLocation={badgesLocation}
      />
    );
  });

*/

  const modalEvent = state.selectedEvent ? (
    <RowModal
      event={state.selectedEvent}
      showDetails={true}
      calendarSlug={calendar.model.slug}
      embedded={props.embedded}
      ccbAddress={calendar.model.integration.address}
      onRSVPEvent={props.onRSVPEvent}
      loading={props.loading}
      error={props.error}
      closeOpenedEventsDetails={onCloseOpenedEventsDetails}
      expandedRowSlug={state.selectedEvent.slug}
      expandAsAccordion={false}
      expandAsModal={true}
      calendarID={calendarID}
      onOpenScrollToSelector={state.onModalOpenSelector}
      showWatermark={calendar.model && calendar.model.is_watermarked}
    />
  ) : null;
  const loadingIndicator = loading ? <LoadingIndicator /> : null;

  const noEvents = !loading && !events.collection.length ? <NoEvents /> : null;

  const search =
    hasAIEnabled && calendarSettingsContext["aiEnabled"] ? (
      <SearchInput
        searchEvents={props.searchEvents}
        fetchEvents={props.fetchEvents}
      />
    ) : null;

  return (
    <div className={classnames("dce-detailed-list", `dce--${CT.detailedList}`)}>
      <CalendarHeader
        disabled={events.fetch}
        calendar={calendar}
        currentDate={props.selectedDate}
        onChangeDate={props.onSelectedDateChange}
        events={events.collection}
        locations={events.locations}
        onUpcomingNavigationChange={props.onUpcomingNavigationChange}
        selectedFilters={props.selectedFilters}
        onChangeFilter={props.onChangeFilter}
      />
      {search}

      {noEvents}
      <InfiniteScrollWrapper
        className={classnames("dce-detailed-list")}
        onLoadMore={props.onLoadMore}
        hasMore={events.collection.length != events.totalRows}
        loading={loading}
        embedded={props.embedded}
      >
        {eventsList}
      </InfiniteScrollWrapper>
      {watermark}

      {loadingIndicator}

      {modalEvent}
    </div>
  );
};

DetailedList.propTypes = {
  calendar: PropTypes.object,
  events: PropTypes.object,
  onSelectedDateChange: PropTypes.func.isRequired,
  onUpcomingNavigationChange: PropTypes.func.isRequired,
  selectedDate: PropTypes.object,
  initialized: PropTypes.bool,
  embedded: PropTypes.bool,
  previewType: PropTypes.oneOf(Object.values(PT)),
  onLoadMore: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  selectedFilters: PropTypes.object.isRequired,
};

export default DetailedList;
