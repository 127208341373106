/**
 * Created by piotr.pozniak@thebeaverhead.com on 25/10/2018.
 */

import axios from "axios";
import AxiosConfig from "../AxiosConfig";

const AUTH_TYPES = {
  basic: "basic",
  oauth: "oauth",
  token: "token",
};

/**
 *
 * @param uuid
 * @param data
 * @returns {function(*): Promise<void>}
 */
export const updateIntegration = (uuid = null, data) => {
  return async (dispatch) => {
    dispatch({ type: "INTEGRATION_UPDATE", payload: { uuid } });

    const uuidParam = uuid ? `/${uuid}` : "";
    const url = `/api/integrations${uuidParam}.json`;

    const action = uuid ? axios.put : axios.post;

    return await action(url, data, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "INTEGRATION_UPDATE_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "INTEGRATION_UPDATE_REJECTED",
          payload: error,
          integrationUuid: uuid,
        });
      });
  };
};

/**
 *
 * @param email
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export function deleteIntegration(uuid) {
  return function (dispatch) {
    dispatch({ type: "INTEGRATION_DELETE" });

    const url = "/api/integrations/" + uuid + ".json";

    return axios
      .delete(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "INTEGRATION_DELETE_FULFILLED",
          payload: { uuid },
        });
      })
      .catch(function (error) {
        dispatch({
          type: "INTEGRATION_DELETE_REJECTED",
          payload: error,
        });
      });
  };
}

/**
 *
 * @param email
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export function syncIntegration(uuid, removeAll = false) {
  return function (dispatch) {
    dispatch({ type: "INTEGRATION_SYNC", payload: { uuid } });

    const url = "/api/integrations/sync/" + uuid + ".json";

    return axios
      .post(url, { removeAll }, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "INTEGRATION_SYNC_FULFILLED",
          uuid: uuid,
          payload: response.data,
        });
      })
      .catch(function (error) {
        if (error.response && [503, 504].includes(error.response.status)) {
          return;
        }
        dispatch({
          type: "INTEGRATION_SYNC_REJECTED",
          uuid: uuid,
          payload: error,
        });
      });
  };
}

/**
 *
 * @param uuid
 * @param removeAll
 * @returns {function(*): Promise<AxiosResponse<any>>}
 */
export const fetchIntegration = (uuid) => {
  return async (dispatch) => {
    dispatch({ type: "INTEGRATION_FETCH", payload: { uuid } });

    const url = "/api/integrations/" + uuid + ".json";

    return await axios
      .get(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "INTEGRATION_FETCH_FULFILLED",
          uuid: uuid,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "INTEGRATION_FETCH_REJECTED",
          payload: error,
        });
      });
  };
};

/**
 *
 * @param uuid
 * @param removeAll
 * @returns {function(*): Promise<AxiosResponse<any>>}
 */
export const setIntegrationModel = (model) => {
  return async (dispatch) => {
    dispatch({ type: "INTEGRATION_SET", payload: model });
  };
};

/**
 *
 * @param integrationUUID
 * @param kind
 * @param q
 * @returns {function(*): Promise<AxiosResponse<any>>}
 */
export const fetchMetadata = (integrationUUID, kind, q) => {
  return async (dispatch) => {
    dispatch({
      type: "INTEGRATION_METADATA_FETCH",
      payload: { uuid: integrationUUID, kind },
    });

    const queryParams = {
      integration_uuid: integrationUUID,
      kind,
      q,
    };
    const url =
      `/api/events_metadata/search.json?` +
      AxiosConfig.objectToURLQuery(queryParams);

    return await axios
      .get(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "INTEGRATION_METADATA_FETCH_FULFILLED",
          uuid: integrationUUID,
          payload: response.data,
          kind,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "INTEGRATION_METADATA_FETCH_REJECTED",
          payload: error,
        });
      });
  };
};

/**
 *
 * @param integrationUUID
 * @returns {function(*): Promise<AxiosResponse<any>>}
 */
export const checkAccess = (integrationUUID) => {
  return async (dispatch) => {
    dispatch({
      type: "INTEGRATION_ACCESS_CHECK",
      payload: { uuid: integrationUUID },
    });

    const url = `/api/integrations/check_access/${integrationUUID}.json`;

    return await axios
      .get(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "INTEGRATION_ACCESS_CHECK_FULFILLED",
          uuid: integrationUUID,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "INTEGRATION_ACCESS_CHECK_REJECTED",
          payload: error,
        });
      });
  };
};
