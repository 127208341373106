/**
 * Created by piotr.pozniak@thebeaverhead.com on 05/10/2023
 */

import React from "react";
import PropTypes from "prop-types";
import ClipboardCopyInput from "../../../component/ClipboardCopyInput";
import { getWixEmbeddedScript, wixHeadScript } from "./helpers";
import NoDomainsWarning from "./NoDomainsWarning";

const WixEmbed = ({ widgetTypeName, widgetType, uuid }) => {
  return (
    <form action="#">
      <NoDomainsWarning widgetTypeName={widgetTypeName} />
      <ol>
        <li>
          <div className="col-xs-12">
            <p>
              <strong className={"text-danger"}>EXPERIMENTAL</strong>
              <br />
              <em>
                Add the snippet below to &lt;head&gt; of your wix page. Add
                &gt;&gt; Embed &gt;&gt; Marketing Tools &gt;&gt; Custom Code. If
                you use our {widgetTypeName} in many places, add it to all
                sites, if it's only available on one page, add it to that page
                only. Mark it <strong>Essential</strong>.
              </em>
            </p>

            <ClipboardCopyInput
              id={"wix-head-script"}
              value={wixHeadScript}
              placeholder={"Wix head script"}
            />
          </div>
        </li>
        <li>
          <div className="col-xs-12">
            <p>
              <em>
                On a desired page, where you'd like to display the{" "}
                {widgetTypeName}, add an embedded code by copying the following:
              </em>
            </p>

            <ClipboardCopyInput
              id={"wix-embed-script"}
              value={getWixEmbeddedScript(widgetType, uuid)}
              placeholder={"Wix head script"}
              tag="textarea"
            />
          </div>
        </li>
      </ol>
    </form>
  );
};

WixEmbed.defaultProps = {
  widgetTypeName: "widget",
};

WixEmbed.propTypes = {
  widgetTypeName: PropTypes.string,
  widgetType: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired,
};

export default WixEmbed;
