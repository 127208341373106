/**
 * Created by piotr.pozniak@thebeaverhead.com on 24/05/2019.
 */

import React from "react";
import PropTypes from "prop-types";
import LoadingIndicator from "../../../calendar/components/LoadingIndicator";
import ApiError from "../../component/ApiError";
import BillingDetailsForm from "../../component/BillingDetailsForm";
import moment from "moment";
import { updateJquery } from "../../tools";
import { getPlanSubscriptionName } from "../../../../helpers/subscription";

class EditModal extends React.Component {
  constructor(props) {
    super(props);

    const user = this.props.user;

    this.state = {
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      is_account_verified: user.is_account_verified,
      role: user.role,
    };
  }

  /**
   *
   */
  componentDidMount() {
    updateJquery();
  }

  /**
   *
   */
  onSaveChanges = (e) => {
    e.preventDefault();

    this.props.onEdit(this.props.user.uuid, this.state);
    return false;
  };

  /**
   *
   */
  render() {
    const user = this.props.user;

    const isNew = user.uuid ? false : true;

    if (!isNew) {
      //address.subdomain = domain.domain.split("://")[1].split(".")[0];
    }

    const { update, updateError } = this.props.users;

    const loadingIndicator = update ? <LoadingIndicator /> : null;

    let accountVerifiedLabel = "Email verified";

    if (user.is_account_verified * 1 !== 1) {
      if (this.props.users.activate) {
        accountVerifiedLabel = "Activating....";
      } else {
        const orgUUID = user.organizations ? user.organizations[0].uuid : "";
        const activationUrl = `${
          window.location.origin
        }/users/verify_account/${encodeURIComponent(user.email)}/${
          user.activation_token
        }/${orgUUID}`;

        accountVerifiedLabel = `Email not verified. Activation url: ${activationUrl}`;
      }
    }
    const activateButton = (
      <label>
        <input
          type={"checkbox"}
          className={"form-check-input"}
          checked={user.is_account_verified * 1}
          disabled={true}
        />
        {accountVerifiedLabel}
      </label>
    );

    return (
      <div
        id="user_edit_modal"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div className="modal-dialog">
          <button type="button" className="close" data-dismiss="modal">
            &times;
          </button>

          <div className="modal-content modal-lg">
            <div className="modal-header">
              <h4 className="modal-title">Edit user</h4>
            </div>

            <div className="modal-body">
              <form>
                <div className="card-box">
                  <h3 className="page-title">Basic Information</h3>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="profile-img-wrap">
                        <img
                          className="inline-block"
                          src="/img/user.jpg"
                          alt="user"
                        />
                      </div>
                      <div className="profile-basic">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group form-focus">
                              <label className="focus-label">First Name</label>
                              <input
                                className="form-control floating"
                                type="text"
                                value={this.state.first_name}
                                onChange={(e) =>
                                  this.setState({ first_name: e.target.value })
                                }
                                disabled={update}
                                maxLength={64}
                                minLength={2}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group form-focus">
                              <label className="focus-label">Last Name</label>
                              <input
                                className="form-control floating"
                                type="text"
                                value={this.state.last_name}
                                onChange={(e) =>
                                  this.setState({ last_name: e.target.value })
                                }
                                disabled={update}
                                maxLength={64}
                                minLength={2}
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group">
                              <select
                                className="form-control form-control-lg"
                                type="text"
                                value={this.state.role}
                                onChange={(e) => {
                                  this.setState({ role: e.target.value });
                                }}
                                disabled={update}
                              >
                                <option value="users">User</option>
                                <option value="admins">Admin</option>
                              </select>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="form-group form-focus">
                              <label className="focus-label">Email</label>
                              <input
                                className="form-control floating"
                                type="text"
                                value={this.state.email}
                                onChange={(e) =>
                                  this.setState({ email: e.target.value })
                                }
                                disabled={update}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <div className={"form-check form-check-inline"}>
                                {activateButton}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-center m-t-20">
                    <button
                      className="btn btn-primary btn-lg"
                      type="submit"
                      onClick={this.onSaveChanges}
                      disabled={update}
                    >
                      Save Changes
                    </button>
                    {loadingIndicator}
                    <ApiError
                      error={this.props.users.updateError}
                      defaultErrorMessage={"Could not update user data"}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EditModal.propTypes = {
  onEdit: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

export default EditModal;
