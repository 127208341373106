/**
 * Created by piotr.pozniak@thebeaverhead.com on 15/02/2024
 */

import React from "react";
import PropTypes from "prop-types";
import Row from "./Row";

const Leader = (props) => {
  const { leader } = props;

  return (
    <Row icon={"account_circle"} title={"Contact email"}>
      {1 ? (
        <a
          href={"mailto:" + leader.email}
          className={"dce-groups-modal-details-anchor anchor "}
        >
          <i className={"material-icons"}>email</i>
          {leader.name}
        </a>
      ) : (
        leader.name
      )}
    </Row>
  );
};

Leader.defaultProps = {};

Leader.propTypes = {
  leader: PropTypes.shape({ email: PropTypes.string, name: PropTypes.string }),
};

export default Leader;
