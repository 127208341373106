import React from "react";

const FilterLabelCTA = () => {
  return (
    <span>
      You may filter sign-ups/registrations on this widget using one of the
      available filters.{" "}
      <a
        href={
          "http://help.display.church/en/articles/8579729-planning-center-pco-sign-up-forms#h_3c43827669"
        }
        target={"_blank_pco_signups_filters"}
      >
        Follow this link to learn more
      </a>
      .
    </span>
  );
};

export default FilterLabelCTA;
