/**
 * Created by piotr.pozniak@thebeaverhead.com on 17/07/2023
 */

import React, { useCallback, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { useIntegrationStore } from "../../../../hooks/redux/integration";
import classnames from "classnames";
import { useUserStore } from "../../../../hooks/redux/user";

const OpenAIForm = (props) => {
  const { integration, updateIntegration } = useIntegrationStore();
  const { user } = useUserStore();

  const userRole = useMemo(() => {
    return user.model ? user.model.role : "";
  }, [user]);

  const hasEmbeddingsOn = useMemo(() => {
    return (
      (integration.model &&
        integration.model.settings &&
        integration.model.settings.create_embeddings) ||
      false
    );
  }, [integration.model]);

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      let _hasEmbeddingsOn = hasEmbeddingsOn;

      if (hasEmbeddingsOn) {
        const answer = window.prompt(
          "Disabling OpenAI integration will remove all AI-related data and will turn off AI features in your widgets. Type 'DISABLE' to confirm"
        );

        if (answer.toLowerCase() !== "disable") {
          return;
        }
        _hasEmbeddingsOn = false;
      } else {
        _hasEmbeddingsOn = true;
      }

      updateIntegration(integration.model.uuid, {
        ...integration.model,
        settings: {
          ...integration.model.settings,
          create_embeddings: _hasEmbeddingsOn,
        },
      });
    },
    [integration.model]
  );

  const description = useMemo(() => {
    return hasEmbeddingsOn
      ? "AI is currently turned on"
      : "Harness the power of AI to support your users. Enable calendar analysis using the form below. The third-party app, OpenAI will analyze your data. This will enable special features in your widgets.";
  }, [hasEmbeddingsOn]);

  const isAiALlowed =
    integration.model.settings && integration.model.settings.is_ai_allowed;

  if (userRole !== "admins" && !isAiALlowed) {
    return null;
  }

  return (
    <div className={"col-xs-12 col-lg-6"}>
      <div className={"card  pt-sm-4 pl-sm-4 pr-sm-4"}>
        <form className={"card-body"}>
          <div className={"row"}>
            <div className={"col-12 text-center"}>
              <h4>AI Settings</h4>
              <img
                className={"py-4"}
                src={"/img/integrations_howto/ai_settings.svg"}
                width={"60%"}
                alt={"How to integrate with OpenAI"}
                title={"How to integrate with OpenAI"}
              />
            </div>
            <div className={"col-xs-12"}>
              <p className={"pt-2"}>{description}</p>
            </div>
            <div className={"col-12"}>
              <div className="m-t-20 text-center">
                <div className="form-group">
                  <button
                    className={classnames("btn btn-lg", {
                      "btn-primary": !hasEmbeddingsOn,
                      "btn-danger": hasEmbeddingsOn,
                    })}
                    onClick={onSubmit}
                    disabled={integration.update}
                    type={"button"}
                  >
                    {hasEmbeddingsOn ? "Disable" : "Enable"} AI
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

OpenAIForm.defaultProps = {};

OpenAIForm.propTypes = {
  integration: PropTypes.object.isRequired,
  updateIntegration: PropTypes.func.isRequired,
};

export default OpenAIForm;
