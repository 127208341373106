/**
 * Created by piotr.pozniak@thebeaverhead.com on 24/11/2023
 */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DiscountHeader from "../../../pricing/DiscountHeader";
import { PLANS } from "../../../../consts";
import moment from "moment/moment";
import Pricing from "../../component/Pricing";
import { useCurrentOrganizationStore } from "../../../../hooks/redux/currentOrganization";
import { useSubscriptionPlansStore } from "../../../../hooks/redux/subscriptionPlans";
import { useHistory } from "react-router-dom";
import {
  getOrganizationSubscriptionPlan,
  hasSubscription,
  hasTrialFinished as _hasTrialFinished,
} from "../../helpers/organization";
import appConfig from "../../../../appConfig";

const getPlansSelectorTitle = (
  organizationSubscription,
  isBeforeApplyingFreePlan,
  hasTrialFinished
) => {
  const plan = organizationSubscription.subscription_plan;
  let title = "";

  if (plan.code === PLANS.free) {
    title =
      "You're currently on the Free plan with limited access. Click here to learn more or Upgrade now!";
  } else if (isBeforeApplyingFreePlan) {
    title = "Your trial has expired. Upgrade now!";
  } else if (hasTrialFinished) {
    title =
      `You're currently on a ${plan.name} plan. It will expire on ` +
      moment(organizationSubscription.expiry * 1000).format("MMM DD, YYYY") +
      ".";
  } else {
    title =
      `You're currently on a Trial ${plan.name} plan. It will expire on ` +
      moment(organizationSubscription.expiry * 1000).format("MMM DD, YYYY") +
      ". Upgrade now!";
  }

  return title;
};

const PlansSelector = (props) => {
  const [showAnnual, setShowAnnual] = useState(false);

  // for revisual, it should only show not branded plans
  const [brandedPlans, setBrandedPlans] = useState([]);

  const history = useHistory();
  const { subscriptionPlans } = useSubscriptionPlansStore();
  const { currentOrganization } = useCurrentOrganizationStore();

  const subscription = hasSubscription(currentOrganization);
  const organizationSubscription =
    getOrganizationSubscriptionPlan(currentOrganization);
  const isTrial = subscription && subscription.is_trial * 1 ? true : false;
  const hasTrialFinished = _hasTrialFinished(currentOrganization);

  const canChangePeriod = true;

  // user's trial may have expired, but they may not have a free plan yet
  const isBeforeApplyingFreePlan =
    hasTrialFinished &&
    !subscription &&
    organizationSubscription &&
    organizationSubscription.subscription_plan.code !== PLANS.free
      ? true
      : false;

  // todo 6.0.0
  const pendingSubscriptionPlan = null;
  /*currentOrganization.model &&
    currentOrganization.model.pending_payment * 1 &&
    state.subscription
      ? state.subscription
      : null;
*/

  useEffect(() => {
    if (appConfig.branding !== "rev") {
      const updatedBrandedPlans = [];
      subscriptionPlans.collection.forEach((plan) => {
        if (!(plan.is_branded * 1)) {
          updatedBrandedPlans.push(plan.code);
        }
      });
      setBrandedPlans(updatedBrandedPlans);
    }
  }, [subscriptionPlans.collection]);

  /**
   *
   */
  const onSwitchPlanBranded = (e) => {
    const updatedBrandedPlans = [...brandedPlans];

    const planIndex = updatedBrandedPlans.findIndex((code) => e.code === code);

    if (planIndex >= 0) {
      updatedBrandedPlans.splice(planIndex, 1);
    } else {
      updatedBrandedPlans.push(e.code);
    }

    setBrandedPlans(updatedBrandedPlans);
  };

  /**
   *
   * @param plan
   */
  const onShowEditPlan = (plan) => {
    history.push(
      `/subscribe/${plan.code}/${showAnnual ? "yearly" : "monthly"}/${
        plan.is_branded
      }`
    );
  };

  /**
   *
   */
  const onChangeAnnualSelector = (e) => {
    setShowAnnual(e.target.checked);
  };

  const periodSelector = canChangePeriod ? (
    <div className="plan-toggle-container">
      Monthly Plans
      <div className="material-switch ">
        <input
          id="calendar_available"
          type="checkbox"
          value={1}
          checked={showAnnual}
          onChange={onChangeAnnualSelector}
        />
        <label htmlFor="calendar_available" className="badge-primary" />
      </div>
      Annual Plans
    </div>
  ) : null;

  let planSelectionTitle = organizationSubscription
    ? getPlansSelectorTitle(
        organizationSubscription,
        isBeforeApplyingFreePlan,
        hasTrialFinished
      )
    : "";

  const plans =
    (!subscriptionPlans.fetch && !subscription) ||
    hasTrialFinished ||
    subscription.is_trial * 1
      ? subscriptionPlans.collection
          .filter((i) => Number.parseInt(i.available))
          .filter(
            (i) =>
              (!Number.parseInt(i.is_branded) &&
                brandedPlans.indexOf(i.code) >= 0) ||
              (Number.parseInt(i.is_branded) &&
                brandedPlans.indexOf(i.code) < 0)
          )
          .sort((first, second) =>
            Number.parseFloat(first.price) > Number.parseFloat(second.price)
              ? 0
              : -1
          )
          .map((i, idx) => {
            let label = "SELECT";

            if (
              organizationSubscription?.subscription_plan?.code !==
                PLANS.free &&
              subscription &&
              subscription.uuid != i.uuid &&
              !isTrial
            ) {
              label = "CHANGE";
            }

            // check if the subscription plan match- uuid is the same and
            // plan is the same period (either annual or month)
            const planMatch =
              subscription &&
              organizationSubscription.subscription_plan.uuid === i.uuid &&
              ((showAnnual &&
                organizationSubscription.subscription_plan.is_annual * 1) ||
                (!showAnnual &&
                  !(organizationSubscription.subscription_plan.is_annual * 1)))
                ? true
                : false;

            return (
              <Pricing
                key={"pl-" + idx}
                onSelect={onShowEditPlan}
                plan={i}
                showAnnual={showAnnual}
                submitLabel={label}
                isCurrentPlan={
                  !isTrial &&
                  organizationSubscription &&
                  planMatch &&
                  !organizationSubscription.is_terminated
                }
                isPendingPayment={
                  pendingSubscriptionPlan &&
                  pendingSubscriptionPlan.uuid == i.uuid
                }
                disabled={
                  currentOrganization.model.pending_payment * 1 ? true : false
                }
                onSwitchPlanBranded={onSwitchPlanBranded}
                coupon={subscriptionPlans.coupon}
              />
            );
          })
      : null;

  return (
    <div>
      <div className="plan-header">
        <h5>{planSelectionTitle}</h5>
        {periodSelector}
      </div>
      <DiscountHeader coupon={subscriptionPlans.coupon} />
      <div className="row">{plans}</div>
    </div>
  );
};

PlansSelector.defaultProps = {};

PlansSelector.propTypes = {};

export default PlansSelector;
