/**
 * Created by piotr.pozniak@thebeaverhead.com on 31/03/2019.
 */


import axios from "axios";
import AxiosConfig from "../AxiosConfig";


/**
 *
 * @param uuid
 * @param data
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export function updateDomain(uuid = null, data) {

  return function(dispatch) {

    dispatch({type: "DOMAIN_UPDATE"});

    const url = '/api/domains' + (uuid ? '/' + uuid : '') + '.json';

    const action = uuid ? axios.put : axios.post;

    return action(
      url,
      data,
      AxiosConfig.getAuthConfig()
    )
      .then((response) => {

        dispatch({
          type: "DOMAIN_UPDATE_FULFILLED",
          payload: response.data
        });

      })
      .catch(function(error) {

        dispatch({
          type: "DOMAIN_UPDATE_REJECTED",
          payload: error,
        })
      });
  }
}


/**
 *
 * @param uuid
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export function deleteDomain(uuid) {

  return function(dispatch) {

    dispatch({type: "DOMAIN_DELETE"});

    const url = '/api/domains/' + uuid + '.json';

    return axios.delete(
      url,
      AxiosConfig.getAuthConfig()
    )
      .then((response) => {

        dispatch({
          type: "DOMAIN_DELETE_FULFILLED",
          payload: {uuid}
        });

      })
      .catch(function(error) {

        dispatch({
          type: "DOMAIN_DELETE_REJECTED",
          payload: error,
        })
      });
  }
}

