/**
 * Created by piotr.pozniak@thebeaverhead.com on 28/09/2023.
 */

import React from "react";
import { IT, PLANS, ST } from "../../../consts";
import { WT } from "../consts/widget";
import NewWidgetPageTemplate from "../templates/NewWidgetPageTemplate";
import appConfig from "../../../appConfig";
const IntegrationDefaultFilters = {
  [IT.pco]: null,
  [IT.ccb]: {
    conjunction: "and",
    groups: [
      {
        conjunction: "and",
        rules: [
          {
            field: {
              value: "public",
              label: "Show public only",
            },
            value: "true",
            label: "Yes",
            operator: {
              value: "is",
              label: "is",
            },
          },
        ],
      },
    ],
  },
};

const NewSignupPage = (props) => {
  const availableWidgets = [
    {
      title: "Grid",
      description:
        "Cards (Grid) flexible features make it easy for your people to find their needed registration(s).",
      slug: ST.cardGrid,
      thumbnailUrl: "/img/signup_type/cards_icon.png",
      features: [
        "A modern template modeled on social media type cards. ",
        "Works well on mobile and desktop.",
      ],
      forPlans: [PLANS.starter, PLANS.medium, PLANS.highest],
      defaultDesignSettings: {},
      previewUrl: `${appConfig.homepage}/products/signups/#signups-grid`,
    },
    /*{
      title: "List",
      description: "LOREM IPSUM...",
      slug: ST.cardList,
      thumbnailUrl: "/img/signup_type/list_icon.png",
      features: [
        "Looks intuitive and inviting",
        "Emphasis on visual image for a more personal connection",
      ],
      forPlans: [PLANS.starter, PLANS.medium, PLANS.highest],
      defaultDesignSettings: {},
      previewUrl: "https://display.church/products/signups/#signups-grid",
      isNew: true,
    },*/
  ];

  return (
    <NewWidgetPageTemplate
      sidebarId={WT.signups}
      icon={"edit_note"}
      title={"Sign-up Forms"}
      widgetType={WT.signups}
      widgetDisplayName={"Sign-up Forms"}
      availableWidgets={availableWidgets}
      allowedIntegrations={[IT.pco, IT.ccb]}
      integrationDefaultFilters={IntegrationDefaultFilters}
    ></NewWidgetPageTemplate>
  );
};

export default NewSignupPage;
