/**
 * Created by piotr.pozniak@thebeaverhead.com on 02/02/2020.
 */

import React from "react";
import PropTypes from "prop-types";
import { PAYOUTS_EXPENSES_KINDS, PAYOUTS_VENDORS } from "../../../../consts";

const CurrentMonthExpenseRow = (props) => {
  const { inputDisabled, removeBtnDisabled, id, expense, className } = props;

  const automaticExpense =
    Number.parseInt(expense.is_added_along_with_payout) === 0;

  const hasUUID = expense.uuid ? true : false;

  return (
    <tr className={className}>
      <td>
        <div className="row">
          <div className="col-4">
            <div className="d-flex align-items-center">
              <button
                type="button"
                className={"btn btn-sm btn-primary mr-3"}
                disabled={removeBtnDisabled || automaticExpense}
                onClick={props.removeExpense(id)}
              >
                <span aria-hidden="true" className={"mx-1 fs-"}>
                  &times;
                </span>
              </button>
              <input
                type="text"
                className={"form-control"}
                value={expense.description}
                disabled={inputDisabled || hasUUID}
                onChange={props.onChangePayoutExpense(id, "description")}
              />
            </div>
          </div>
          <div className="col-3">
            <select
              className="form-control h-100"
              value={expense.kind}
              disabled={inputDisabled || hasUUID}
              onChange={props.onChangePayoutExpense(id, "kind")}
            >
              {PAYOUTS_EXPENSES_KINDS.map((i) => (
                <option key={i.value} value={i.value}>
                  {i.label}
                </option>
              ))}
            </select>
          </div>
          <div className="col-3">
            <select
              className="form-control h-100"
              value={expense.vendor}
              disabled={inputDisabled || hasUUID}
              onChange={props.onChangePayoutExpense(id, "vendor")}
            >
              {PAYOUTS_VENDORS.map((i) => (
                <option key={i.value} value={i.value}>
                  {i.label}
                </option>
              ))}
            </select>
          </div>
          <div className="col-2">
            <input
              type="number"
              className={"form-control pr-0"}
              disabled={inputDisabled || automaticExpense}
              value={expense.amount}
              onChange={props.onChangePayoutExpense(id, "amount")}
            />
          </div>
        </div>
      </td>
    </tr>
  );
};

CurrentMonthExpenseRow.propTypes = {
  id: PropTypes.number.isRequired,
  inputDisabled: PropTypes.bool,
  removeBtnDisabled: PropTypes.bool,
  onChangePayoutExpense: PropTypes.func.isRequired,
  removeExpense: PropTypes.func.isRequired,
  expense: PropTypes.shape({
    description: PropTypes.string,
    kind: PropTypes.string,
    vendor: PropTypes.string,
    amount: PropTypes.string,
  }),
  className: PropTypes.string,
};

export default CurrentMonthExpenseRow;
