/**
 * Created by piotr.pozniak@thebeaverhead.com on 19/07/2023
 */

import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import SuggestionPill from "./SuggestionPill";

const Suggestions = (props) => {
  /**
   *
   * @type {(function(*): void)|*}
   */
  const onPillClick = useCallback((value) => {
    props.onSuggestionClick(value);
  }, []);

  const suggestions = useMemo(
    () =>
      props.suggestions.map((suggestion, index) => (
        <SuggestionPill
          key={index}
          content={suggestion}
          onClick={onPillClick}
        />
      )),
    [props.suggestions]
  );

  return (
    <div className={"dc-ai-suggestions"}>
      <div className={"dc-ai-suggestions__label"}>Suggested prompts:</div>
      {suggestions}
    </div>
  );
};

Suggestions.defaultProps = {
  suggestions: [],
};

Suggestions.propTypes = {
  suggestions: PropTypes.arrayOf(PropTypes.string),
  onSuggestionClick: PropTypes.func.isRequired,
};

export default Suggestions;
