/**
 * Created by piotr.pozniak@thebeaverhead.com on 18/03/2024
 */

import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useAppStore } from "../../../../../hooks/redux/app";
import { dismissAlert } from "../../../../../actions/main";

const DismissableMessage = ({ message, id }) => {
  const { dismissedAlerts, dismissAlert } = useAppStore();

  const onClose = useCallback(() => {
    dismissAlert(id);
  }, [dismissedAlerts.collection]);

  const isDismissed = useMemo(
    () => dismissedAlerts.collection.includes(id),
    [dismissedAlerts.collection]
  );

  if (isDismissed) {
    return null;
  }

  return (
    <div
      className="alert alert-info alert-dismissible fade show p-2 px-3"
      role="alert"
    >
      {message}
      <button
        type="button"
        className="close  m-r-5 p-1"
        data-dismiss="alert"
        aria-label="Close"
        onClick={onClose}
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>
  );
};

DismissableMessage.defaultProps = {};

DismissableMessage.propTypes = {};

export default DismissableMessage;
