/**
 * Created by piotr.pozniak@thebeaverhead.com on 12/03/2024
 */

import React from "react";
import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";
import { useUserStore } from "../../../../../hooks/redux/user";
import { useAppStore } from "../../../../../hooks/redux/app";

const DropdownFooter = (props) => {
  const { logout } = useUserStore();
  const { shutdownIntercom } = useAppStore();
  const history = useHistory();

  /**
   *
   */
  const onLogoutClick = (e) => {
    e.preventDefault();

    shutdownIntercom();
    setTimeout(() => {
      history.push("/login");
      logout();
    }, 200);
  };

  return (
    <div className={"nav-user-menu--dropdown-footer"}>
      <Link
        to={"/logout"}
        className={"btn btn-white btn-sm d-flex align-items-center gap-xxs"}
        onClick={onLogoutClick}
      >
        <i className={"material-icons icon-sm"}>logout</i>
        Logout
      </Link>
    </div>
  );
};

DropdownFooter.defaultProps = {};

DropdownFooter.propTypes = {};

export default DropdownFooter;
