import { useContext, useEffect } from "react";
import { FiltersContext } from "./contexts";

export const useFilterField = (groupId, filterId, filter) => {
  const { filters, updateGroup } = useContext(FiltersContext);

  const existingRule =
    filters.groups[groupId].rules && filters.groups[groupId]?.rules?.[filterId];

  const rule = existingRule?.value
    ? { ...existingRule }
    : {
        ...existingRule,
        value: filter?.value,
        label: filter?.label,
        uuid: filter?.uuid,
      };

  const updateFilter = (updatedFilter) => {
    const group = { ...filters.groups[groupId] };

    group.rules = group.rules || [];
    group.rules[filterId] = {
      ...rule,
      ...updatedFilter,
    };
    updateGroup(groupId, group);
  };

  // Setting default value passed in group rules
  useEffect(() => {
    if (filter && filter.value) {
      updateFilter({});
    }
  }, [filter]);

  return { rule, updateFilter };
};
