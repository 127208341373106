/**
 * Created by piotr.pozniak@thebeaverhead.com on 05/08/2024
 */

import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { syncDurationInSeconds } from "../../helpers/sync";
import { durationToString } from "../../tools";

const QueueJobProgress = ({ job }) => {
  const isSyncing = job.fetched && !job.failed && !job.completed;
  const [lastUpdated, setLastUpdated] = useState(new Date());

  const durationInSeconds = useMemo(() => {
    return syncDurationInSeconds(job);
  }, [job, lastUpdated]);

  // calculate progress in percent
  const progress = Math.round(
    (durationInSeconds * 100) / (job.data.last_duration + 30)
  );

  useEffect(() => {
    if (isSyncing && progress <= 100) {
      const interval = setInterval(() => {
        setLastUpdated(new Date());
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [isSyncing, durationInSeconds, progress]);

  if (!isSyncing) {
    return null;
  }
  if (!durationInSeconds) {
    return null;
  }

  if (progress > 100) {
    return (
      "It takes longer than usual:" + durationToString(job.data.last_duration)
    );
  }

  // bootstrap 4 progress with label inside
  return (
    <div className="progress">
      <div
        className="progress-bar"
        role="progressbar"
        style={{ width: `${progress}%` }}
        aria-valuenow={progress}
        aria-valuemin="0"
        aria-valuemax="100"
      >
        {progress}%
      </div>
    </div>
  );
};
QueueJobProgress.defaultProps = {};

QueueJobProgress.propTypes = {
  onETA: PropTypes.func,
  job: PropTypes.object,
};

export default QueueJobProgress;
