/**
 * Created by enes.kahrovic@thebeaverhead.com on 22/10/2018.
 */

import axios from "axios";
import AxiosConfig from "../AxiosConfig";
import { WT } from "../apps/admin/consts/widget";

/**
 *
 * @param integrationUUID
 * @param kind
 * @returns {function(*): Promise<AxiosResponse<any>>}
 */
export const fetchIntegrationFilters = (integrationUUID, kind = null) => {
  return (dispatch) => {
    dispatch({ type: "INTEGRATION_FILTERS_FETCH" });

    let url = `/api/filters/integration_filters/${integrationUUID}.json`;

    if (kind) {
      url += `?kind=` + kind.join(",");
    }

    return axios
      .get(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "INTEGRATION_FILTERS_FETCH_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "INTEGRATION_FILTERS_FETCH_REJECTED",
          payload: error,
        });
      });
  };
};
/**
 *
 * @param calendarUUID
 * @param type
 * @param kind
 * @param q
 * @returns {function(*): Promise<AxiosResponse<any>>}
 */
export const fetchWidgetFilters = (
  widgetUUID,
  widgetType,
  filtersType,
  actionType,
  kind = null,
  q = null
) => {
  return (dispatch) => {
    dispatch({ type: `${actionType}_FETCH` });

    const query = {
      type: filtersType,
      kind,
      q,
    };

    const queryString = AxiosConfig.objectToURLQuery(query);

    const url =
      AxiosConfig.getEndpointAddress() +
      `/api/filters/${widgetType}_filters/${widgetUUID}.json?${queryString}`;

    const authConfig =
      AxiosConfig.getAuthToken() === null
        ? AxiosConfig.getConfig()
        : AxiosConfig.getAuthConfig();

    return axios
      .get(url, authConfig)
      .then((response) => {
        dispatch({
          type: `${actionType}_FETCH_FULFILLED`,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: `${actionType}_FETCH_REJECTED`,
          payload: error,
        });
      });
  };
};

/**
 *
 * @param calendarUUID
 * @param type
 * @param kind
 * @param q
 * @returns {function(*): Promise<AxiosResponse<any>>}
 */
export const fetchCalendarFilters = (
  calendarUUID,
  type = "widget_filters", // this is redundant todo
  kind = null,
  q = null
) => {
  return fetchWidgetFilters(
    calendarUUID,
    WT.calendar,
    type,
    type === "calendar_filters" ? "CALENDAR_FILTERS" : "WIDGET_FILTERS",
    kind,
    q
  );
};

/**
 *
 * @param widgetUUID
 * @param type
 * @param kind
 * @param q
 * @returns {function(*): Promise<AxiosResponse<*>>}
 */
export const fetchGroupFilters = (
  widgetUUID,
  type = "widget_filters", // todo this is redundant
  kind = null,
  q = null
) => {
  return fetchWidgetFilters(
    widgetUUID,
    WT.groups,
    type,
    type === "group_filters" ? "GROUP_FILTERS" : "WIDGET_FILTERS",
    kind,
    q
  );
};

/**
 *
 * @param calendarUUID
 * @returns {function(*): Promise<AxiosResponse<any>>}
 */
export const clearFilters = () => {
  return (dispatch) => {
    dispatch({ type: "FILTERS_CLEAR" });
  };
};
