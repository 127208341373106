/**
 * Created by piotr.pozniak@thebeaverhead.com on 21/08/2018.
 */

import React from "react";

import { Link, useHistory } from "react-router-dom";
import { useUserStore } from "../../../../hooks/redux/user";
import { useAutologinStore } from "../../../../hooks/redux/autologin";
import appConfig from "../../../../appConfig";
import UserMenu from "./UserMenu";

const PageHeader = (props) => {
  const history = useHistory();

  const { user } = useUserStore();
  const { autologin, autologout } = useAutologinStore();

  /**
   *
   */
  const onTerminateAutoLogin = async (e) => {
    e.preventDefault();

    await autologout(autologin);
    history.push("/users");
    return false;
  };

  const userModel = user.model;

  let autologinContent = null;

  if (autologin.model || autologin.login) {
    if (autologin.model) {
      const autologinUser = user.model;
      autologinContent = (
        <h3>
          Auto login as: {autologinUser.name} (
          <a href="#" onClick={onTerminateAutoLogin}>
            logout
          </a>
          )
        </h3>
      );
    } else if (autologin.login) {
      autologinContent = <h3>Auto login in progres...</h3>;
    }
  }

  const userMenu = userModel ? (
    <UserMenu />
  ) : (
    <ul className="nav user-menu pull-right">
      <li className="nav-item dropdown d-none d-sm-block">
        <Link to="/register" className="dropdown-toggle nav-link">
          Sign up
        </Link>{" "}
      </li>
      <li className="nav-item dropdown d-none d-sm-block">
        <Link to="/login" className="dropdown-toggle nav-link">
          Login
        </Link>
      </li>
    </ul>
  );

  return (
    <div>
      <div className="header">
        <div className="header-left">
          <Link to="/" className="logo">
            <img
              src={`/img/branding/${appConfig.branding}/full_length_logo_light.png`}
              className={"desktop"}
              height="40"
              alt=""
            />
            <img
              src={`/img/branding/${appConfig.branding}/logo_icon_light.png`}
              className={"responsive"}
              height="30"
              alt=""
            />
          </Link>
        </div>
        <div className="page-title-box pull-left">{autologinContent}</div>

        <a id="mobile_btn" className="btn mobile_btn pull-left" href="#sidebar">
          <i className="material-icons" aria-hidden="true">
            menu
          </i>
        </a>

        {userMenu}

        {/*<div className="dropdown mobile-user-menu pull-right">
          <a
            href="#"
            className="btn nav-link dropdown-toggle p-2"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons icon-lg" aria-hidden="true">
              more_vert
            </i>
          </a>
          <div className="dropdown-menu dropdown-menu-right">
            A {dropdownMenuItems}
          </div>
        </div>*/}
      </div>
    </div>
  );
};

PageHeader.propTypes = {};

export default PageHeader;
