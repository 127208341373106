import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import classnames from "classnames";
import { getPlanSubscriptionName } from "../../../../helpers/subscription";
import { getCurrentPlan } from "../../tools";
import { hasSubscription } from "../../helpers/organization";

const TableRow = (props) => {
  /**
   *
   */
  const onUpdate = (callback) => (e) => {
    e.preventDefault();

    callback(props.license);

    return false;
  };

  const { email, name, license, domains } = props.organization;

  const disabled = props.disabled;
  const hasLicense = license ? true : false;
  const licenseKey = hasLicense ? license.license_key : null;

  const currentPlan = getCurrentPlan(props.organization.organizations_plans);

  return (
    <tr>
      <td>
        {name}
        <br />
        <small>{email}</small>
      </td>
      <td>{licenseKey || "NO LICENSE"}</td>
      <td>{getPlanSubscriptionName(currentPlan)}</td>
      <td>{moment(currentPlan.expiry * 1000).format("YYYY-MM-DD")}</td>
    </tr>
  );
};

TableRow.propTypes = {
  organization: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    created: PropTypes.number.isRequired,
    assigned_user: PropTypes.object,
    license_key: PropTypes.string,
    cost: PropTypes.number,
  }).isRequired,
};

export default TableRow;
