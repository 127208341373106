/**
 * Created by piotr.pozniak@thebeaverhead.com on 22/11/2023.
 */
import React, { useEffect } from "react";
import { useAppStore } from "../../../hooks/redux/app";
import LoadingIndicator from "../../calendar/components/LoadingIndicator";
import ModalTemplate from "./ModalTemplate";
import { useRouteMatch } from "react-router-dom";
import { useOrganizationStore } from "../../../hooks/redux/organization";
import Form from "./AdminOrgEdit/Form";
import ApiError from "../component/ApiError";
import SubscriptionHistory from "./AdminOrgEdit/SubscriptionHistory";
import appConfig from "../../../appConfig";
import { useTranslation } from "react-i18next";

const AdminOrgEditModal = (props) => {
  const matchParams = useRouteMatch();
  const {
    organization,
    fetchOrganization,
    editOrganization,
    editOrganizationSubscription,
  } = useOrganizationStore();
  const { clearErrors } = useAppStore();
  const { t } = useTranslation();

  const header = `Edit ${t("Organization")} details`;

  const { edit, editError, updateSuccess } = organization;

  useEffect(() => {
    fetchOrganization(matchParams.params.uuid);
  }, []);

  useEffect(() => {
    /*if (updateSuccess) {
      onClose();
    }*/
  }, [updateSuccess]);

  useEffect(() => {
    /*setDomain(
      domains.collection.find(
        (domainItem) => domainItem.uuid === matchParams.uuid
      )
    );*/
  }, [organization]);

  const onClose = async () => {
    await clearErrors();
    props.history.push(`/${appConfig.links.organizations}/`);
  };

  /**
   * Handles add/update domain action.
   *
   * @param domain
   */
  const onSubmit = async (data) => {
    await editOrganization(organization.model.uuid, data, true);
    return false;
  };

  /**
   *
   * @param uuid
   * @param expiry
   */
  const onEditExpiryDate = (expiry) => {
    editOrganizationSubscription(organization.model.uuid, expiry);
  };

  const form = organization.model ? (
    <Form
      organization={organization.model}
      onSubmit={onSubmit}
      disabled={edit}
      organizationSettings={organization.model?.settings}
    />
  ) : null;

  const subscriptionHistory = organization.model ? (
    <SubscriptionHistory onEditExpiryDate={onEditExpiryDate} />
  ) : null;

  const loadingIndicator =
    organization.fetch || organization.edit ? <LoadingIndicator /> : null;

  return (
    <ModalTemplate
      id={"organization-edit-modal"}
      header={header}
      size={"lg"}
      onClose={onClose}
    >
      <div className={""}>
        <div className="modal-dialog">
          <div className="modal-content modal-lg">
            <div className="modal-header"></div>
            <div className="modal-body">
              {form}
              {loadingIndicator}
              <ApiError error={organization.fetchError} />
              <ApiError error={organization.editError} />
              {subscriptionHistory}
            </div>
          </div>
        </div>
      </div>
    </ModalTemplate>
  );
};

AdminOrgEditModal.propTypes = {};

AdminOrgEditModal.defaultProps = {};

export default AdminOrgEditModal;
