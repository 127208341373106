/**
 * Created by piotr.pozniak@thebeaverhead.com on 07/02/2023.
 */

import React, { useState, useEffect, useMemo, useCallback } from "react";

import PageTemplate from "./../templates/PageTemplate";
import LoadingIndicator from "../../calendar/components/LoadingIndicator";
import { useAppStore } from "../../../hooks/redux/app";
import QueueTabs from "../component/QueueTabs";
import { useQueueJobsStore } from "../../../hooks/redux/queueJobs";
import Pagination from "../component/Pagination";
import { useHistory } from "react-router-dom";
import { useRouteMatch } from "react-router";

const QueueJobsPageTemplate = (props) => {
  const { queueJobs, fetchQueueJobs, clearQueueCollection } =
    useQueueJobsStore();
  const { clearErrors } = useAppStore();
  const history = useHistory();
  const match = useRouteMatch();

  useEffect(() => {
    clearErrors();
    return () => {
      clearQueueCollection();
      clearErrors();
    };
  }, []);

  useEffect(() => {
    fetchQueueJobs(match.params.page || 1, props.jobType);
  }, [match.params.page]);

  const loading = queueJobs.fetch;

  /**
   *
   * @type {(function(): Promise<void>)|*}
   */
  const onRefresh = useCallback(
    async (e) => {
      e.preventDefault();
      await fetchQueueJobs(match.params.page || 1, props.jobType);
    },
    [match.params.page]
  );

  /**
   *
   * @type {(function(*): void)|*}
   */
  const onPaginationPageChange = useCallback((page) => {
    let url = props.getCurrentPageUrl(page);
    history.push(url);
    window.scrollTo({ top: 0 });
  }, []);

  const TableRow = props.tableRowComponent;
  const list = useMemo(
    () =>
      queueJobs.collection.map((item, idx) => {
        return <TableRow disabled={loading} key={item.id} job={item} />;
      }),
    [queueJobs.collection, loading]
  );

  const loadingIndicator = loading ? <LoadingIndicator /> : null;

  const refreshButton = !loading ? (
    <a
      key={"refreshWorkersList"}
      href="#"
      className="btn btn-secondary btn-rounded pull-right m-r-5 d-flex"
      onClick={onRefresh}
    >
      <i className="material-icons">refresh</i> Refresh
    </a>
  ) : null;

  const table = queueJobs.collection.length ? (
    <table className="table table-striped custom-table">
      {props.tableHeader}
      <tbody>{list}</tbody>
    </table>
  ) : null;

  const pagination = queueJobs.pagination ? (
    <Pagination
      itemsPerPage={queueJobs.pagination.perPage}
      currentPage={queueJobs.pagination.page}
      total={queueJobs.pagination.count}
      getPageUrl={props.getCurrentPageUrl}
      onPageChange={onPaginationPageChange}
    />
  ) : null;
  return (
    <PageTemplate
      sidebarId="queueWorkers"
      title={props.title}
      icon={"engineering"}
      headerControls={[refreshButton]}
    >
      <QueueTabs />
      <div className="row">
        <div className="col-md-12">
          <div className="table-responsive">
            {table}
            {pagination}
            {loadingIndicator}
          </div>
        </div>
      </div>
    </PageTemplate>
  );
};

export default QueueJobsPageTemplate;
