/**
 * Created by piotr.pozniak@thebeaverhead.com on 21/03/2024.
 */

import React, { useCallback, useEffect, useState } from "react";
import LoadingIndicator from "../../calendar/components/LoadingIndicator";
import ApiError from "../component/ApiError";

import { useUserStore } from "./../../../hooks/redux/user";
import { useAppStore } from "./../../../hooks/redux/app";
import SettingsPageTemplate from "../templates/SettingsPageTemplate";
import BasicDetailsForm from "../modals/AdminOrgEdit/BasicDetailsForm";
import Form from "../modals/AdminOrgEdit/Form";
import { useCurrentOrganizationStore } from "../../../hooks/redux/currentOrganization";

const OrganizationSettingsPage = (props) => {
  const { clearErrors } = useAppStore();
  const { currentOrganization, editOrganization } =
    useCurrentOrganizationStore();
  const orgModel = currentOrganization.model || {};

  const [state, setState] = useState({
    ...orgModel,
  });

  useEffect(() => {
    return clearErrors;
  }, []);
  /**
   *
   */
  const onSaveChangesClick = useCallback(
    async (data) => {
      await editOrganization(orgModel.uuid, data, false);
    },
    [state, orgModel]
  );

  /**
   *
   * @param field
   * @returns {function(*): void}
   */
  const onChangeValue = (field) => (e) => {
    const data = { ...state };
    data[field] = e.target.value;
    setState(data);
  };

  const loading = currentOrganization.edit;

  return (
    <SettingsPageTemplate
      title="Organization settings"
      sidebarId="org-settings"
      icon={"settings"}
    >
      <Form
        organization={state}
        onSetValue={onChangeValue}
        onSubmit={onSaveChangesClick}
        disabled={loading}
        organizationSettings={orgModel?.settings}
      />
    </SettingsPageTemplate>
  );
};

export default OrganizationSettingsPage;
