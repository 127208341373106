/**
 * Created by piotr.pozniak@thebeaverhead.com on 22/01/2024
 */

import React, { useCallback, useRef } from "react";
import PropTypes from "prop-types";
import { QRCode as QRComponent } from "react-qr-code";

// This function returns a Promise whenever the $img is loaded
const loadImage = async (url) => {
  const $img = document.createElement("img");
  $img.src = url;
  return new Promise((resolve, reject) => {
    $img.onload = () => resolve($img);
    $img.onerror = reject;
    $img.src = url;
  });
};

const QRCode = ({ value }) => {
  const qrCodeRef = useRef();

  /**
   *
   * @type {(function(): void)|*}
   */
  const onDownload = useCallback(
    async (e) => {
      e.preventDefault();
      // Data header for a svg image:
      const dataHeader = "data:image/svg+xml;charset=utf-8";
      // Serialize it as xml string:
      const serializeAsXML = ($e) => new XMLSerializer().serializeToString($e);

      // Encode URI data as UTF8 data:
      const encodeAsUTF8 = (s) => `${dataHeader},${encodeURIComponent(s)}`;

      // Encode it as a data string:
      const svgData = encodeAsUTF8(serializeAsXML(qrCodeRef.current));

      const imgData = await loadImage(svgData);

      const $canvas = document.createElement("canvas");
      $canvas.width = qrCodeRef.current.clientWidth;
      $canvas.height = qrCodeRef.current.clientHeight;
      $canvas
        .getContext("2d")
        .drawImage(
          imgData,
          0,
          0,
          qrCodeRef.current.clientWidth,
          qrCodeRef.current.clientHeight
        );
      const ImageBase64 = $canvas.toDataURL(`image/png`, 1.0);

      const achor = document.createElement("a"); //Create <a>
      achor.href = ImageBase64; //Image Base64 Goes here
      achor.download = "Image.png"; //File name Here
      achor.click(); //Downloaded file
    },
    [value, qrCodeRef.current]
  );

  return (
    <div className="form-group ">
      <h5>
        QR Code{" "}
        <button
          className={"btn btn-sm btn-outline-dark m-l-5"}
          onClick={onDownload}
        >
          Download
        </button>{" "}
      </h5>
      <QRComponent value={value} ref={qrCodeRef} />
    </div>
  );
};

QRCode.defaultProps = {};

QRCode.propTypes = {
  value: PropTypes.string.isRequired,
};

export default QRCode;
