/**
 * Created by piotr.pozniak@thebeaverhead.com on 02/08/2024
 */

import React from "react";
import PropTypes from "prop-types";

const CardWidget = ({ icon, value, label, variant }) => {
  return (
    <div className="dash-widget2">
      <span className={`dash-widget-icon bg-${variant}`}>
        <i className="material-icons icon-responsive">{icon}</i>
      </span>
      <div className="dash-widget-info">
        <h3>{value}</h3>
        <span>{label}</span>
      </div>
    </div>
  );
};

CardWidget.defaultProps = {
  variant: "secondary",
};

CardWidget.propTypes = {
  icon: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  variant: PropTypes.string,
};

export default CardWidget;
