/**
 * Created by piotr.pozniak@thebeaverhead.com on 04/04/2024
 */

import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import copyTextToClipboard from "../../helpers/clipboard";
import { useTranslation } from "react-i18next";

const LinkCopyInput = ({ url }) => {
  const [showSuccess, setShowSuccess] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (showSuccess) {
      const notificationDom = document.querySelector(
        ".dc-share-popup--link-input-notification"
      );

      if (notificationDom) {
        notificationDom.scrollIntoView();
      }
      setTimeout(() => {
        setShowSuccess(false);
      }, 3000);
    }
  }, [showSuccess]);

  /**
   *
   * @type {(function(*): void)|*}
   */
  const onCopy = useCallback(
    (e) => {
      const success = copyTextToClipboard("dc-share-input");

      if (success) {
        setShowSuccess(true);
      }
    },
    [url]
  );

  const successNotification = showSuccess ? (
    <div>
      <br />
      <div className="dc-share-popup--link-input-notification" role="alert">
        {t("Link copied to clipboard")}
      </div>
    </div>
  ) : null;

  return (
    <>
      <div className={"dc-share-popup--link-input"}>
        <div className={"dc-share-popup--link-input_value-container"}>
          <input
            id={"dc-share-input"}
            className={"dc-share-popup--link-input_value"}
            value={url}
          ></input>
        </div>
        <button
          className={"dc-share-popup--link-input_button"}
          onClick={onCopy}
        >
          {t("Copy")}
        </button>
      </div>
      {successNotification}
    </>
  );
};

LinkCopyInput.defaultProps = {};

LinkCopyInput.propTypes = {
  url: PropTypes.string.isRequired,
};

export default LinkCopyInput;
