/**
 * Created by piotr.pozniak@thebeaverhead.com  on 06/04/2022
 */

import React, { useCallback, useContext, useState } from "react";
import PropTypes from "prop-types";
import PreloadImage from "../PreloadImage";
import WidgetSettingsContext from "../../../../contexts/WidgetSettingsContext";

const LeftColumn = (props) => {
  const { item } = props;

  const widgetSettingsContext = useContext(WidgetSettingsContext);

  const showImage = Number.parseInt(widgetSettingsContext["gSImageMain"]) === 1;

  const image =
    showImage && item.cover_image ? (
      <PreloadImage src={item.cover_image} />
    ) : (
      <div className={"dce-tc__img-placeholder"} />
    );

  return (
    <div className={"col--left"}>
      <div className={"group-cover-container dce-tc__loader-gradient"}>
        {image}
      </div>
    </div>
  );
};

LeftColumn.defaultProps = {};

LeftColumn.propTypes = {
  item: PropTypes.object.isRequired,
  showImage: PropTypes.bool,
};

export default LeftColumn;
