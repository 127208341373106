/**
 * Created by piotr.pozniak@thebeaverhead.com on 22/11/2023.
 */

export const UserRoles = {
  owner: "owner",
  admin: "admin",
  // user: "user",
};

export const OrganizationMemberStatusMapping = {
  0: "newMember",
  1: "accepted",
  2: "rejected",
  3: "pending",
  10: "delete",
};

export const OrganizationMemberStatus = {
  newMember: 0,
  accepted: 1,
  rejected: 2,
  pending: 3,
  delete: 10,
};

export const OrganizationMemberStatusLabels = {
  newMember: "New Member",
  accepted: "Accepted",
  rejected: "Rejected",
  pending: "Pending",
  delete: "Marked for deletion",
};
