/**
 * Created by piotr.pozniak@thebeaverhead.com on 23/05/2024
 */

import React from "react";
import PropTypes from "prop-types";
import { getUploadResourceUrl } from "../../../../helpers/url";

const Logo = ({ show, widget }) => {
  const orgSettings = widget.model?.organization?.settings;

  const orgLogo = getUploadResourceUrl(orgSettings?.logo);

  if (!show || !orgLogo) {
    return null;
  }

  return (
    <div className={"dc-calendar-branding--header-image-container"}>
      <img src={orgLogo} />
    </div>
  );
};

Logo.defaultProps = {
  show: false,
};

Logo.propTypes = {
  widget: PropTypes.object,
  show: PropTypes.bool,
};

export default Logo;
