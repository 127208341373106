import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import appConfig from "../../../../../appConfig";

const DesignTooltip = (props) => {
  if (!props.help) {
    return null;
  }
  const _url = appConfig.branding === "dc" ? props.help.url : null;

  const Component = appConfig.branding === "dc" ? "a" : "div";

  return (
    <Component
      className="help-anchor"
      href={_url}
      target="_blank"
      rel="nofollow"
    >
      <div
        className={"design-tooltip"}
        data-toggle="tooltip"
        data-toggleaa="2222"
        data-placement="top"
        title={props.help.message}
      >
        <i
          className={classNames(
            "material-icons text-primary",
            props.iconClassName
          )}
        >
          {props.icon}
        </i>
      </div>
    </Component>
  );
};

DesignTooltip.propTypes = {
  help: PropTypes.shape({
    url: PropTypes.string,
    message: PropTypes.string,
  }),
  icon: PropTypes.string,
  iconClassName: PropTypes.string,
};

DesignTooltip.defaultProps = {
  icon: "info",
  iconClassName: "material-icons",
};

export default DesignTooltip;
