/**
 * Created by piotr.pozniak@thebeaverhead.com on 01/07/2022
 */

import React, { useCallback } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const ButtonCTA = (props) => {
  const onClick = useCallback((e) => {
    e.stopPropagation();
  }, []);

  return (
    <div className={classNames(props.className)}>
      <a
        className={"dce--groups-card--cta-btn"}
        href={props.url}
        target={props.target}
        onMouseUp={onClick}
      >
        {props.label}
      </a>
    </div>
  );
};

ButtonCTA.defaultProps = {};

ButtonCTA.propTypes = {
  url: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  target: PropTypes.string,
};

export default ButtonCTA;
