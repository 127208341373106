/**
 * Created by piotr.pozniak@thebeaverhead.com on 25/06/2019.
 */

import {
  CALENDAR_TEMPLATES_PLANS,
  FEATURED_EVENTS_PLANS,
  FILTER_SETTINGS_PLANS,
  FILTERS_EVENTS_PLANS,
  IT,
  PLANS,
  PRIVATE_EVENTS_PLANS,
} from "../../consts";
import { hasSubscription } from "./helpers/organization";
import appConfig from "../../appConfig";

/**
 *
 * @returns {boolean}
 */
export function updateJquery(retries = 0) {
  try {
    if (retries > 3) {
      return;
    }
    window.updateJquery();
    // console.log("jquery happily updated");
    return true;
  } catch (e) {
    setTimeout(() => updateJquery(retries + 1), 40);
    console.log("updating query failed");
  }
}

/**
 *
 * @param modalSelector
 */
export function hideModal(modalSelector) {
  window.$ && window.$(modalSelector).modal("hide");
}

/**
 *
 * @type {Intl.NumberFormat}
 */
export const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

/**
 *
 * @type {Intl.NumberFormat}
 */
export const timeFormatter = new Intl.DateTimeFormat("en-US", {
  dateStyle: "full",
  timeStyle: "short",
});

/**
 *
 * @param arr
 * @returns {*}
 */
export function arrayUnique(arr) {
  return arr.filter(function (item, index) {
    return arr.indexOf(item) >= index;
  });
}

/**
 *
 * @param plans
 * @returns {*}
 */
export const getCurrentPlan = (plans) => {
  const validPlan = plans.reduce(
    (highest, plan) => (plan.created >= highest.created ? plan : highest),
    { created: 0 }
  );

  if (validPlan) {
    return validPlan;
  }

  const freePlan = plans.find((i) => i.code === PLANS.free);

  if (freePlan) {
    return freePlan;
  }

  return validPlan;
};

/**
 * From: https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript
 * @param text
 */
export const copyTextToClipboard = (text) => {
  var textArea = document.createElement("textarea");

  //
  // *** This styling is an extra step which is likely not required. ***
  //
  // Why is it here? To ensure:
  // 1. the element is able to have focus and selection.
  // 2. if element was to flash render it has minimal visual impact.
  // 3. less flakyness with selection and copying which **might** occur if
  //    the textarea element is not visible.
  //
  // The likelihood is the element won't even render, not even a
  // flash, so some of these are just precautions. However in
  // Internet Explorer the element is visible whilst the popup
  // box asking the user for permission for the web page to
  // copy to the clipboard.
  //

  // Place in top-left corner of screen regardless of scroll position.
  textArea.style.position = "fixed";
  textArea.style.top = 0;
  textArea.style.left = 0;

  // Ensure it has a small width and height. Setting to 1px / 1em
  // doesn't work as this gives a negative w/h on some browsers.
  textArea.style.width = "2em";
  textArea.style.height = "2em";

  // We don't need padding, reducing the size if it does flash render.
  textArea.style.padding = 0;

  // Clean up any borders.
  textArea.style.border = "none";
  textArea.style.outline = "none";
  textArea.style.boxShadow = "none";

  // Avoid flash of white box if rendered for any reason.
  textArea.style.background = "transparent";

  textArea.value = text;

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand("copy");
    var msg = successful ? "successful" : "unsuccessful";
    console.log("Copying text command was " + msg);
  } catch (err) {
    console.log("Oops, unable to copy");
  }

  document.body.removeChild(textArea);
};

export const calendarSettingsValidator = (data) => {
  const errors = {};

  if (!data.name || !data.name.length) {
    errors.nameError = "Please enter a Calendar Name. ";
  }

  if (!data.integration_uuid || data.integration_uuid.length <= 1) {
    errors.integrationError = "Please choose an integration. ";
  }

  return errors;
};

/**
 *
 * @param data
 * @returns {{}}
 */
export const groupSettingsValidator = (data) => {
  const errors = {};

  if (!data.name || !data.name.length) {
    errors.nameError = "Please enter a Widget Name. ";
  }

  if (!data.integration_uuid || data.integration_uuid.length <= 1) {
    errors.integrationError = "Please choose an integration. ";
  }

  return errors;
};
/**
 *
 * @param data
 * @returns {{}}
 */
export const alertSettingsValidator = (data) => {
  const errors = {};

  if (!data.name || !data.name.length) {
    errors.nameError = "Please enter a Widget Name. ";
  }

  if (!data.integration_uuid || data.integration_uuid.length <= 1) {
    errors.integrationError = "Please choose an integration. ";
  }

  return errors;
};

/**
 *
 * @param data
 * @returns {{}}
 */
export const widgetSettingsValidator = (data) => {
  const errors = {};

  if (!data.name || !data.name.length) {
    errors.nameError = "Please enter a Widget Name. ";
  }

  if (!data.integration_uuid || data.integration_uuid.length <= 1) {
    errors.integrationError = "Please choose an integration. ";
  }

  return errors;
};

/**
 * Checks whether calendar can be updated. If it is set to available, it will check
 * all features. If it passes all criteria, will return an empty array. Otherwise,
 * returned array will contain descriptions of all issues found.
 * @param calendarData
 * @param organization
 * @param calendars
 * @param _plan - to owervrite user's plan
 * @returns {*[]}
 */
export const calendarFeaturesCheck = (
  calendarData,
  organization,
  calendars,
  _plan = null
) => {
  const disableReasons = [];
  // check if use can make calendar published
  if (Number.parseInt(calendarData.available) === 1) {
    const subscription = _plan || hasSubscription(organization);
    const planCode = subscription ? subscription.code : PLANS.free;

    // no plan or plan is FREE, only one calendar no premium integration is allowed
    if (planCode === PLANS.free) {
      const publicFreeCalendars = calendars.collection.filter((i) => {
        return (
          Number.parseInt(i.available) && i.integration?.type === IT.google
        );
      });

      if (publicFreeCalendars.length > 1) {
        disableReasons.push(
          "On this plan, only ONE calendar can be published at a time."
        );
      }
    }

    // if on free plan, widgets using premium integrations aren't allowed
    if (planCode === PLANS.free) {
      if (calendarData.integration.type !== IT.google) {
        disableReasons.push(
          "This calendar uses a premium integration which is not included in your plan."
        );
      }
    }

    // check if selected template is included in the plan
    if (CALENDAR_TEMPLATES_PLANS[calendarData.template].indexOf(planCode) < 0) {
      disableReasons.push(
        `Your ${appConfig.shortDisplayName} plan does not include this template. `
      );
    }

    // check for private events
    if (calendarData.integration.type === IT.ccb) {
      const hasPrivateEvents =
        Number.parseInt(calendarData.show_private_events) === 1;

      if (hasPrivateEvents && !PRIVATE_EVENTS_PLANS[planCode]) {
        disableReasons.push(
          "The combination of your plan + your CCB integration does not include publishing a calendar with private events. "
        );
      }
    }

    // check for featured events
    if (Boolean(calendarData.has_featured_events)) {
      if (!FEATURED_EVENTS_PLANS[planCode]) {
        disableReasons.push(
          `Your ${appConfig.shortDisplayName} plan does not include publishing a calendar with Featured Events.`
        );
      }
    }

    // check for filters
    const checkedFilters = [];

    if (
      calendarData.filter_settings &&
      calendarData.filter_settings.filters &&
      calendarData.filter_settings.filters.groups &&
      calendarData.filter_settings.filters.groups.length
    ) {
      calendarData.filter_settings.filters.groups.forEach((filterGroup) => {
        if (!filterGroup.rules) {
          return;
        }

        filterGroup.rules.forEach((rule) => {
          if (!rule.value) {
            return;
          }

          if (checkedFilters.indexOf(rule.field.value) >= 0) {
            return;
          }

          checkedFilters.push(rule.field.value);

          const isRestricted =
            FILTERS_EVENTS_PLANS[calendarData.integration.type][
              planCode
            ].indexOf(rule.field.value) < 0;
          if (!isRestricted) {
            return;
          }
          const filteredEventPlanOptions =
            FILTER_SETTINGS_PLANS[calendarData.integration.type][
              rule.field.value
            ];

          let isAllowed = true;

          if (
            filteredEventPlanOptions &&
            filteredEventPlanOptions.allowedValue
          ) {
            if (rule.value !== filteredEventPlanOptions.allowedValue) {
              isAllowed = false;
            }
          } else {
            isAllowed = false;
          }

          if (!isAllowed) {
            disableReasons.push(
              `The combination of your ${appConfig.shortDisplayName} plan + your integration does not include using the ${rule.field.label} filter option. `
            );
          }
        });
      });
    }
  }

  return disableReasons;
};

/**
 *
 * @param calendarData
 * @param calendars
 * @returns {string}
 */
export const getTheLowestPlanMeetingCalendarCriteria = (
  calendarData,
  calendars
) => {
  const minimumPlans = [PLANS.free];

  // no plan or plan is FREE, only one calendar no premium integration is allowed
  const publicFreeCalendars = calendars.collection.filter(
    (i) =>
      Number.parseInt(i.available) &&
      i.integration &&
      i.integration.type === IT.google
  );

  if (publicFreeCalendars.length > 0) {
    // console.log("setting basic because of numer of free calendars");
    minimumPlans.push(PLANS.starter);
  }

  if (calendarData.integration.type !== IT.google) {
    // console.log("setting basic because of premium integration");
    minimumPlans.push(PLANS.starter);
  }

  // check if selected template is included in the plan
  // get the first element from CALENDAR_TEMPLATES_PLANS[calendarData.template] that is not Plans.free
  const templatePlan = CALENDAR_TEMPLATES_PLANS[calendarData.template].find(
    (i) => i !== PLANS.free
  );

  // console.log("setting template min calendar to", templatePlan);
  minimumPlans.push(templatePlan);

  // check for private events
  if (calendarData.integration.type === IT.ccb) {
    const hasPrivateEvents =
      Number.parseInt(calendarData.show_private_events) === 1;

    if (hasPrivateEvents) {
      // set plan to first item from PRIVATE_EVENTS_PLANS that is true
      const privateEventsPlan = Object.keys(PRIVATE_EVENTS_PLANS).find(
        (i) => PRIVATE_EVENTS_PLANS[i]
      );

      // console.log("setting to", privateEventsPlan, "because of private events");
      minimumPlans.push(privateEventsPlan);
    }
  }

  // check for featured events
  if (Boolean(calendarData.has_featured_events)) {
    // set plan to first item from FEATURED_EVENTS_PLANS that is true
    const featuredEventsPlan = Object.keys(FEATURED_EVENTS_PLANS).find(
      (i) => FEATURED_EVENTS_PLANS[i]
    );
    // console.log("setting to", featuredEventsPlan, "because of featured events");

    minimumPlans.push(featuredEventsPlan);
  }

  // check for filters
  if (
    calendarData.filter_settings &&
    calendarData.filter_settings.filters &&
    calendarData.filter_settings.filters.groups &&
    calendarData.filter_settings.filters.groups.length
  ) {
    calendarData.filter_settings.filters.groups.map((filterGroup) => {
      if (!filterGroup.rules) {
        return;
      }
      filterGroup.rules.map((rule) => {
        // add to minimumPlans the first key from FILTERS_EVENTS_PLANS[calendarData.integration.type] that is true for the filter.kind
        let filterPlan = Object.keys(
          FILTERS_EVENTS_PLANS[calendarData.integration.type]
        ).find(
          (i) =>
            FILTERS_EVENTS_PLANS[calendarData.integration.type][i].indexOf(
              rule.field.value
            ) > -1
        );

        // check for allowed values
        const filterSettings =
          FILTER_SETTINGS_PLANS[calendarData.integration.type][
            rule.field.value
          ];

        if (
          filterPlan &&
          filterSettings &&
          filterSettings.allowedValue !== rule.value
        ) {
          filterPlan = filterSettings.plans[0];
        }

        if (filterPlan) {
          minimumPlans.push(filterPlan);
        }
      });
    });
  }

  const plansIndexes = Object.values(PLANS);

  // return the highest plan from minimumPlans where plan weight is index in the PLANS object
  return minimumPlans.reduce((a, b) => {
    return plansIndexes.indexOf(a) > plansIndexes.indexOf(b) ? a : b;
  });
};

/**
 * Checks whether group can be updated. If it is set to available, it will check
 * all features. If it passes all criteria, will return an empty array. Otherwise,
 * returned array will contain descriptions of all issues found.
 * @param groupData
 * @param organization
 * @returns {*[]}
 */
export const groupFeaturesCheck = (groupData, organization) => {
  const disableReasons = [];
  // check if use can make calendar published
  if (Number.parseInt(groupData.available) === 1) {
    const subscription = hasSubscription(organization);
    const planCode = subscription ? subscription.code : PLANS.free;

    // if on free plan, widgets using premium integrations aren't allowed
    if (planCode === PLANS.free) {
      disableReasons.push("This widget is not included in your plan.");
    }
  }

  return disableReasons;
};

export const alertFeaturesCheck = (groupData, organization) => {
  const disableReasons = [];
  // check if use can make calendar published
  if (Number.parseInt(groupData.available) === 1) {
    const subscription = hasSubscription(organization);
    const planCode = subscription ? subscription.code : PLANS.free;

    // if on free plan, widgets using premium integrations aren't allowed
    if (planCode === PLANS.free) {
      disableReasons.push("This widget is not included in your plan.");
    }
  }

  return disableReasons;
};

/**
 *
 * @param groupData
 * @param organization
 * @returns {*[]}
 */
export const signupFeaturesCheck = (groupData, organization) => {
  const disableReasons = [];
  // check if use can make calendar published
  if (Number.parseInt(groupData.available) === 1) {
    const subscription = hasSubscription(organization);
    const planCode = subscription ? subscription.code : PLANS.free;

    // if on free plan, widgets using premium integrations aren't allowed
    if (planCode === PLANS.free) {
      disableReasons.push("This widget is not included in your plan.");
    }
  }

  return disableReasons;
};

/**
 *
 * @param durationInSeconds
 * @returns {`${string|string} ${string|string} ${string|string}`}
 */
export const durationToString = (durationInSeconds) => {
  const duration = new Date(durationInSeconds * 1000);
  const hours = duration.getUTCHours();
  const minutes = duration.getUTCMinutes();
  const seconds = duration.getUTCSeconds();

  return `${hours ? hours + "h" : ""} ${minutes ? minutes + "m" : ""} ${
    seconds ? seconds + "s" : ""
  }`;
};

/**
 * Tests if value is an object.
 * @param value
 * @returns {boolean}
 */
export const isObject = (value) => {
  return value && typeof value === "object" && value.constructor === Object;
};

/**
 * Tests if value is an array.
 * @param value
 * @returns {boolean}
 */
export const isArray = (value) => {
  return value && typeof value === "object" && value.constructor === Array;
};
