/**
 * Created by piotr.pozniak@thebeaverhead.com on 19/06/2023
 */

import React, { useMemo } from "react";
import PropTypes from "prop-types";
import LocalTz from "../LocalTz";
import { formatDate } from "../../../../helpers/date";
import { useTranslation } from "react-i18next";

const EventHeaderTime = ({
  startDate,
  endDate,
  isSpanningAcrossDays,
  showWeekDay,
  weekDayFormatter,
  isAllDayEvent,
}) => {
  const formattedStartDateOptions = {
    month: "long",
    day: "2-digit",
  };
  const { t } = useTranslation();

  // Add 'weekday' option if showWeekDay is true
  if (showWeekDay) {
    formattedStartDateOptions.weekday = "short";
  }

  const formattedEndDate = isSpanningAcrossDays
    ? "(" + formatDate(endDate, { month: "long", day: "2-digit" }) + ")"
    : "";

  const tz = isAllDayEvent ? null : <LocalTz />;

  const formattedTime = useMemo(() => {
    if (isAllDayEvent) {
      const allDayDate = isSpanningAcrossDays
        ? `${formatDate(startDate, { weekday: "short" })}, ${formatDate(
            startDate,
            { month: "long", day: "2-digit" }
          )} - ${formatDate(endDate, { weekday: "short" })}, ${formatDate(
            endDate,
            { month: "long", day: "2-digit" }
          )}`
        : formatDate(startDate, { weekday: "long" });

      return `${t("All day")}  - (${allDayDate})`;
    }
    return (
      <React.Fragment>
        ({formatDate(startDate, formattedStartDateOptions)})
        {" " +
          formatDate(startDate, {
            hour: "numeric",
            minute: "2-digit",
            hour12: true,
          }).toLowerCase()}{" "}
        - {formattedEndDate}
        {" " +
          formatDate(endDate, {
            hour: "numeric",
            minute: "2-digit",
            hour12: true,
          }).toLowerCase()}
      </React.Fragment>
    );
  }, [startDate, isAllDayEvent]);

  return (
    <p>
      <i className="material-icons">access_time</i> {formattedTime} {tz}
    </p>
  );
};

EventHeaderTime.defaultProps = {};

EventHeaderTime.propTypes = {
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
  isSpanningAcrossDays: PropTypes.bool.isRequired,
  showWeekDay: PropTypes.bool.isRequired,
  weekDayFormatter: PropTypes.string.isRequired,
  isAllDayEvent: PropTypes.bool.isRequired,
};

export default EventHeaderTime;
