/**
 * Created by piotr.pozniak@thebeaverhead.com on 05/07/2022
 */

export const Primitives = [
  {
    visibilitySetting: "RegistrationStatus",
    label: "Registration status",
  },
  {
    visibilitySetting: "InteractionType",
    label: "Interaction type",
  },
  {
    visibilitySetting: "Childcare",
    label: "Childcare provided",
  },
  {
    visibilitySetting: "GroupType",
    label: "Group type",
  },
  {
    visibilitySetting: "MembershipType",
    label: "Membership type",
  },
  {
    visibilitySetting: "Leader",
    label: "Main leader",
  },
  {
    visibilitySetting: "ContactEmail",
    label: "Contact email",
  },
  {
    visibilitySetting: "Department",
    label: "Department",
  },
  {
    visibilitySetting: "Capacity",
    label: "Group capacity",
  },
  {
    visibilitySetting: "MembersCount",
    label: "Members count",
  },
  {
    visibilitySetting: "EnrollmentOpen",
    label: "Enrollment available",
  },
];

export default Primitives;
