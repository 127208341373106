/**
 * Created by piotr.pozniak@thebeaverhead.com on 23/02/2024
 */

import React from "react";
import PropTypes from "prop-types";
import appConfig from "../../../../appConfig";

const Tos = (props) => {
  return (
    <div className={"nav-user-menu--dropdown_tos"}>
      <p className={"text-center"}>
        <a href={appConfig.pp_link} target={"_app-pp"}>
          <small>Privacy Policy</small>
        </a>
        <small> | </small>
        <a href={appConfig.tos_link} target={"_app-tos"}>
          <small>Terms of Service</small>
        </a>
      </p>
    </div>
  );
};

Tos.defaultProps = {};

Tos.propTypes = {};

export default Tos;
