/**
 * Created by enes.kahrovic@thebeaverhead.com on 24/10/2018.
 */
import React, { useState, useEffect } from "react";

import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import PageTemplate from "./../templates/PageTemplate";
import LoadingIndicator from "../../calendar/components/LoadingIndicator";
import ApiError from "../component/ApiError";

import {
  AvailableIntegrations,
  IntegrationStatus,
} from "../consts/integrations";
import IntegrationCard from "../modals/NewIntegration/IntegrationCard";
import { useIntegrationsStore } from "../../../hooks/redux/integrations";
import { useIntegrationStore } from "../../../hooks/redux/integration";
import DesignTooltip from "../component/Builder/Input/DesignTooltip";
import { updateJquery } from "../tools";
import { useCurrentOrganizationStore } from "../../../hooks/redux/currentOrganization";
import { hasSubscription } from "../helpers/organization";
import useQuery from "../../../hooks/useQuery";
import FeatureFlag from "../component/FeatureFlag";

const IntegrationsPage = (props) => {
  const history = useHistory();
  const match = useRouteMatch();

  const [state, setState] = useState({
    integration: match.params.uuid == "new" ? {} : null,

    showEditModal: false,
  });

  const { currentOrganization } = useCurrentOrganizationStore();
  const { integrations } = useIntegrationsStore();
  const { integration, updateIntegration, syncIntegration, deleteIntegration } =
    useIntegrationStore();
  const { setIntegrationModel } = useIntegrationStore();

  let query = useQuery();

  useEffect(() => {
    if (integrations.updateSuccess) {
      const recentlyAdded = integrations.collection.length
        ? integrations.collection[integrations.collection.length - 1]
        : null;

      const hasPlan = hasSubscription(currentOrganization);

      if (
        recentlyAdded &&
        !recentlyAdded.last_import &&
        hasPlan &&
        recentlyAdded.status === IntegrationStatus.notSynced
      ) {
        syncIntegration(recentlyAdded.uuid);
      }

      setIntegrationModel(null);
    }
  }, [integrations.updateSuccess]);

  /**
   * detect if user has integrations - if not- show new integration modal.
   * detect if page comes from callback page and requires triggering sync on particular integration
   */
  useEffect(() => {
    if (integrations.fetchSuccess && query.get("autosync")) {
      const uuid = query.get("autosync");
      const integrationToSync = integrations.collection.find(
        (i) => i.uuid == uuid
      );
      if (integrationToSync.status === IntegrationStatus.synced) {
        history.replace("/integrations");
        syncIntegration(uuid, false);
      }
    }

    updateJquery();
  }, [integrations.fetchSuccess]);

  useEffect(() => {
    window.$ &&
      window
        .$('[data-toggle="tooltip"]')
        .tooltip({ className: "integration-tooltip" });
  }, []);

  /**
   *
   * @param integration
   */
  const onIntegrationDelete = (integration) => {
    if (
      window.confirm(
        `Are you sure you want to disconnect from ${integration.name}? You will not be able work with any of your ${integration.name} widgets views until you re-integrate.`
      )
    ) {
      deleteIntegration(integration.uuid);
    }
  };

  /**
   *
   * @param integration
   */
  const onChangeStatus = (integration) => {
    integration.is_auto_sync = Number.parseInt(integration.is_auto_sync)
      ? 0
      : 1;

    updateIntegration(integration.uuid, integration);
  };

  /**
   *
   * @param integration
   */
  const onQuickSync = (integration) => {
    const integrationConfig = AvailableIntegrations.find(
      (i) => i.type === integration.type
    );

    if (
      integrationConfig.beforeSyncAlert &&
      !window.confirm(integrationConfig.beforeSyncAlert)
    ) {
      return false;
    }

    syncIntegration(integration.uuid, false);
  };

  /**
   *
   * @param integration
   */
  const onFullReSync = (integration) => {
    const integrationConfig = AvailableIntegrations.find(
      (i) => i.type === integration.type
    );

    if (
      integrationConfig.beforeSyncAlert &&
      !window.confirm(integrationConfig.beforeSyncAlert)
    ) {
      return false;
    }

    if (
      window.confirm(
        "This will wipe your events data and sync data, and sync your data from scratch. It may take some minutes. During the sync, your widgets will not show any items. Additionally, all filters and views will be reset. Are you sure you want to continue?"
      )
    ) {
      syncIntegration(integration.uuid, true);
    }
  };

  const loading = integrations.fetch || integrations.delete;

  const loadingIndicator = loading ? <LoadingIndicator /> : null;

  const integrationGroupedCards = {
    active: [],
    disabled: [],
    other: [],
  };

  if (currentOrganization.model) {
    AvailableIntegrations.forEach((i, idx) => {
      const matchingIntegration = integrations.collection.find(
        (j) => i.type == j.type && Number.parseInt(j.available)
      );

      const updateError =
        matchingIntegration &&
        integration.updateError &&
        integration.updateIntegrationUUID === matchingIntegration.uuid
          ? integration.updateError
          : null;

      const isUpdating =
        matchingIntegration &&
        integrations.update &&
        integrations.updateUUID === matchingIntegration.uuid;

      const integrationCard = (
        <IntegrationCard
          key={"aip" + idx}
          title={i.title}
          description={i.description}
          available={i.available}
          type={i.type}
          beta={i.beta}
          premium={i.premium}
          help={i.help}
          icon={i.icon}
          isLoading={isUpdating}
          integration={matchingIntegration}
          onDelete={onIntegrationDelete}
          onChangeStatus={onChangeStatus}
          onFullReSync={onFullReSync}
          onQuickSync={onQuickSync}
          updateError={updateError}
        />
      );

      if (matchingIntegration) {
        if (matchingIntegration.disabled_date) {
          integrationGroupedCards.disabled.push(integrationCard);
        } else {
          integrationGroupedCards.active.push(integrationCard);
        }
      } else {
        integrationGroupedCards.other.push(integrationCard);
      }
    });
  }

  const activeIntegrations = integrationGroupedCards.active.length ? (
    <div className="row">
      <div className={"col-sm-12"}>
        <h4 className={"text-success"}>Active integrations:</h4>
      </div>
      {integrationGroupedCards.active}
    </div>
  ) : null;

  const disabledIntegrations = integrationGroupedCards.disabled.length ? (
    <div className="row">
      <div className={"col-sm-12"}>
        <h4>Disabled (previously active):</h4>
      </div>
      {integrationGroupedCards.disabled}
    </div>
  ) : null;

  const otherIntegrations = integrationGroupedCards.other.length ? (
    <div className="row">
      <div className={"col-sm-12"}>
        {integrationGroupedCards.other.length !==
        AvailableIntegrations.length ? (
          <h4>Other available integrations:</h4>
        ) : null}
      </div>
      {integrationGroupedCards.other}
    </div>
  ) : null;

  let syncTimes = "at 2:00 am CDT.";

  if (currentOrganization.subscription) {
    if (currentOrganization.subscription.code === "STANDARD") {
      syncTimes = "at 3:00 am CDT.";
    } else if (currentOrganization.subscription.code === "PREMIUM") {
      syncTimes = "at 11 am and 11 pm CDT.";
    }
  }

  const allowedIntegrationsCount = currentOrganization.subscription
    ? currentOrganization.subscription.integrations_limit
    : null;

  const autosyncInfo = integrations.collection.length ? (
    <FeatureFlag name={"autosync-info"}>
      <div className={"alert alert-info text-center"}>
        All integrations auto-sync <strong>every day {syncTimes}</strong>
      </div>
    </FeatureFlag>
  ) : null;

  const headerSubtitle = currentOrganization.subscription ? (
    <React.Fragment>
      {`Your plan includes ${allowedIntegrationsCount} premium integration${
        allowedIntegrationsCount > 0 ? "s" : ""
      }`}
      <DesignTooltip
        help={{
          message:
            "Soon, you will be able to subscribe to an additional premium integration within your same account for an additional cost.",
        }}
        icon={"info"}
      ></DesignTooltip>
    </React.Fragment>
  ) : null;

  return (
    <PageTemplate sidebarId="integrations" title={"Integrations"} icon={"hub"}>
      <div className="row">
        <div className="col-sm-8 col-xs-12">
          <h4 className="page-title">{headerSubtitle}</h4>
        </div>
      </div>
      {activeIntegrations}
      {disabledIntegrations}

      {otherIntegrations}
      <div className="row">
        <div className="col-md-12">&nbsp;</div>
        <div className="col-md-12">
          {autosyncInfo}

          {loadingIndicator}

          <ApiError
            error={integrations.deleteError}
            defaultErrorMessage={
              "Could not delete integration. Please try again"
            }
          />

          <ApiError
            error={integrations.syncError}
            defaultErrorMessage={
              "Could not sync integration data. Please try again"
            }
          />
        </div>
      </div>
    </PageTemplate>
  );
};

export default IntegrationsPage;
