/**
 * Created by piotr.pozniak@thebeaverhead.com on 29/07/2021.
 */

/**
 *
 * @param state
 */
export const openAuthWindow = (authUrl) => {
  window.open(authUrl, "_self");
};
