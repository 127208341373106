/**
 * Created by piotr.pozniak@thebeaverhead.com on 19/04/2023
 */

import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { isObject } from "../../tools";
import { ucFirst } from "../../../../helpers/string";

const HintsMapping = {
  BLACKBIRD: "The request includes an unsupported kind parameter",
  CHINCHILLA: "The client application was not found or is invalid",
  FLAMINGO: "The authenticated user does not exist",
  LEMMING: "The authenticated organization does not exist",
  PELICAN: "The provided OAuth token does not have a required scope",
  BABOON: "The provided OAuth access token is either revoked or expired",
  TRASH_PANDA:
    "The authenticated person does not have access to the requested application",
  WEASEL: "The signature's scope is not valid for the requested path",
  ANTEATER: "Signature validation failed with a missing authorization header",
  BLUE_JAY:
    "Signature authentication failed because the authorization header is an incorrect format",
  MOCKINGBIRD:
    "The signature authentication failed because of an invalid token",
};

const PCOForm = ({ integrationType, integration }) => {
  // test if integration.accessErrors is an object
  const message = isObject(integration.accessErrors)
    ? Object.keys(integration.accessErrors).map((key) => {
        const error = integration.accessErrors[key].errors[0];

        // detail string contains a hint in bracket, eg, "bla bla (WEASEL)".
        // If so, replace it with a more user-friendly message
        const hint = error.detail.match(/\((.*)\)/);
        if (hint && hint[1]) {
          error.detail = error.detail.replace(
            hint[1],
            HintsMapping[hint[1]]
              ? `${hint[1]} - ${HintsMapping[hint[1]]}`
              : hint[1]
          );
        }

        return (
          <p key={key}>
            <strong>Service {key.toUpperCase()}</strong> - {error.title}:{" "}
            {error.detail}.
          </p>
        );
      })
    : integration.accessErrors;

  const checkLinks = isObject(integration.accessErrors)
    ? Object.keys(integration.accessErrors).map((key) => {
        return (
          <li key={`cl-${key}`}>
            <a
              target={`_blank-${key}`}
              className={"text-primary"}
              href={`https://accounts.planningcenteronline.com/request_access/${key}`}
            >
              {ucFirst(key)} - Check access
              <i className={"material-icons icon-sm"}>open_in_new</i>
            </a>
          </li>
        );
      })
    : null;

  return (
    <div>
      <div>
        <p>Your {integrationType.title} API integration wasn't successful.</p>
        <div className={classnames("alert alert-danger show")} role="alert">
          {message}
        </div>
      </div>
      <p>
        You have chosen to allow access to the following {integrationType.title}{" "}
        service(s):
      </p>
      <ul>{checkLinks}</ul>
      <p>
        However, the logged in user does not have access to these services.
        Either uncheck these services or change your logged in user.
      </p>
    </div>
  );
};

PCOForm.defaultProps = {};

PCOForm.propTypes = {
  integrationType: PropTypes.object.isRequired,
  integration: PropTypes.object.isRequired,
};

export default PCOForm;
