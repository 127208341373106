/**
 * Created by piotr.pozniak@thebeaverhead.com on 06/04/2022
 */

import React from "react";
import PropTypes from "prop-types";

const LoadingIndicator = (props) => {
  const style = {
    gridTemplateColumns: props.cols,
  };

  return (
    <React.Fragment>

      <div className={"dce--mosaic-tile  dce--mosaic-tile-loader dce--mosaic-tile_wide dce--mosaic-tile_tall"}></div>
      <div className={"dce--mosaic-tile  dce--mosaic-tile-loader"}></div>
      <div className={"dce--mosaic-tile  dce--mosaic-tile-loader dce--mosaic-tile_tall"}></div>
    </React.Fragment>
  );
};

LoadingIndicator.defaultProps = {
  cols: "1fr 1fr 1fr",
};

LoadingIndicator.propTypes = {
  cols: PropTypes.string,
};

export default LoadingIndicator;
