/**
 * Created by piotr.pozniak@thebeaverhead.com on 25/02/2019.
 */

import React, { useMemo } from "react";
import PropTypes from "prop-types";
import SummaryBranding from "./SummaryBranding";
import { useCurrentOrganizationStore } from "../../../../hooks/redux/currentOrganization";
import { useSubscriptionPlansStore } from "../../../../hooks/redux/subscriptionPlans";
import { Link } from "react-router-dom";

const dateFormat = Intl.DateTimeFormat(window.navigator.language || "en-US", {
  year: "numeric",
  month: "short",
  day: "numeric",
});

const OrganizationSubscription = (props) => {
  /**
   *
   * @param callback
   * @returns {function(*): boolean}
   */
  const onClickCallback = (callback) => (e) => {
    e.preventDefault();

    callback();
    return false;
  };

  const { currentOrganization } = useCurrentOrganizationStore();
  const currentSubscription = currentOrganization.subscription;
  const { subscriptionPlans } = useSubscriptionPlansStore();
  const subscriptionPlan = useMemo(
    () =>
      subscriptionPlans.collection.find(
        (i) => i.uuid == currentSubscription.uuid
      ),
    [subscriptionPlans.collection, currentSubscription]
  );

  const organizationModel = currentOrganization.model;
  const organizationCredits = currentOrganization.credits;

  const isAnnual = currentSubscription.is_annual * 1 ? true : false;

  const validUntil = currentSubscription.expiry ? (
    <span>
      It is{" "}
      <strong>
        valid until{" "}
        {dateFormat.format(new Date(currentSubscription.expiry * 1000))}
      </strong>
      .
    </span>
  ) : null;

  //console.log(subscriptionPlan);
  let nextPaymentQuote = 0;
  let renewalPrice = subscriptionPlan
    ? Number(isAnnual ? subscriptionPlan.price_annual : subscriptionPlan.price)
    : 0;

  if (subscriptionPlan) {
    nextPaymentQuote = renewalPrice;

    // deduct from balance if applicable
    if (organizationCredits && organizationCredits.balance < 0) {
      const quoteDeducted = nextPaymentQuote + organizationCredits.balance;
      nextPaymentQuote = quoteDeducted < 0 ? 0 : quoteDeducted;
    }
  }

  if (organizationModel.installation_fee_paid < 1) {
    nextPaymentQuote += 50;
    renewalPrice += 50;
  }

  let nextPayment = null;

  if (
    currentSubscription.expiry &&
    organizationModel.has_trial_finished * 1 &&
    !currentSubscription.is_terminated * 1
  ) {
    const nextPaymentDate = (
      <strong>
        {" "}
        {dateFormat.format(new Date(currentSubscription.expiry * 1000))}
      </strong>
    );

    if (nextPaymentQuote > 0) {
      if (nextPaymentQuote == renewalPrice) {
        nextPayment = (
          <li>
            The next payment of{" "}
            <strong>
              ${nextPaymentQuote} for your {isAnnual ? "yearly" : "monthly"}{" "}
              {currentSubscription.name}{" "}
            </strong>
            plan will be charged on {nextPaymentDate}.
          </li>
        );
      } else {
        nextPayment = (
          <li>
            After using your credit balance, an additional payment of{" "}
            <strong>
              ${Number(nextPaymentQuote).toFixed(2)} for your{" "}
              {isAnnual ? "yearly" : "monthly"} {currentSubscription.name}{" "}
            </strong>
            plan will be charged on {nextPaymentDate}.
          </li>
        );
      }
    } else {
      nextPayment = (
        <li>Your plan will be renewed free of charge on {nextPaymentDate}.</li>
      );
    }
  }

  const planTerminated =
    currentSubscription.is_terminated * 1 ? (
      <li>
        You have canceled your subscription. You can renew it by clicking the
        button below.
      </li>
    ) : null;

  const creditBalance =
    organizationCredits && organizationCredits.balance < 0 ? (
      <li>
        Your credit balance is: $
        {Number(
          organizationCredits ? -1 * organizationCredits.balance : 0
        ).toFixed(2)}
      </li>
    ) : null;

  const submitButtonLabel =
    currentSubscription.is_terminated * 1 ? "Renew plan" : "Change plan";

  const planDetails =
    currentSubscription && currentSubscription.expiry ? (
      <div className="card-box">
        {/*<h3>Subscription summary:</h3>*/}
        <p className={"m-l-15"}>
          You're currently on a{" "}
          <strong>{currentSubscription.name} subscription </strong>
          plan. {validUntil}
        </p>

        <ul className={"user-subscription-summary"}>
          {creditBalance}
          {planTerminated}
          {nextPayment}

          {/*{lastPayment}*/}
          <SummaryBranding
            subscription={currentSubscription}
            plan={subscriptionPlan || {}}
          />
        </ul>
        <button className="btn btn-primary" onClick={props.onUpdatePlan}>
          {submitButtonLabel}
        </button>

        <p>
          <br />
          {organizationModel.has_trial_finished ? (
            <span>
              <small>
                <Link to="/billing/update-billing-details">
                  Update billing details{" "}
                </Link>
              </small>{" "}
              |{" "}
            </span>
          ) : null}
          {organizationModel.has_trial_finished * 1 &&
          !(currentSubscription.is_terminated * 1) ? (
            <span>
              <small>
                <Link to="/billing/cancel-subscription">
                  Cancel subscription
                </Link>
              </small>{" "}
              |{" "}
            </span>
          ) : null}
          <small>
            <a href="#" onClick={onClickCallback(props.onShowBillingHistory)}>
              Show payments history
            </a>
          </small>
          {/*{" "}
        |{" "}
        <small>
          <a href="#">Contact billing support</a>
        </small>*/}
        </p>
      </div>
    ) : (
      <div className="card-box">no plan</div>
    );

  return (
    <div className="row">
      <div className="col-12">{planDetails}</div>
    </div>
  );
};

OrganizationSubscription.propTypes = {
  onUpdatePlan: PropTypes.func.isRequired,
  onShowBillingHistory: PropTypes.func.isRequired,
};

export default OrganizationSubscription;
