/**
 * Created by piotr.pozniak@thebeaverhead.com on 12/10/2021.
 */

import React from "react";
import PropTypes from "prop-types";

const BreezeApiUser = (props) => {
  const { username, address } = props.integrationModel;

  const subdomain = address.split("://")[1].split(".")[0];

  return (
    <a href={`https://${subdomain}.breezechms.com`} target="_blankBreeze">
      {props.integrationModel.username}{" "}
      <i className="material-icons icon-xs" aria-hidden="true">
        open_in_new
      </i>
    </a>
  );
};

BreezeApiUser.propTypes = {
  integrationModel: PropTypes.shape({
    uuid: PropTypes.string,
    status: PropTypes.number,
    state: PropTypes.string,
    address: PropTypes.string.isRequired,
  }),
};

export default BreezeApiUser;
