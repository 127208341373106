/**
 * Created by piotr.pozniak@thebeaverhead.com on 23/07/2021.
 */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDomainsStore } from "../../../hooks/redux/domains";
import { useAppStore } from "../../../hooks/redux/app";
import LoadingIndicator from "../../calendar/components/LoadingIndicator";
import ModalTemplate from "./ModalTemplate";
import DomainForm from "./Domain/DomainForm";
function EditDomainModal(props) {
  const matchParams = props.match.params;
  const { domains, updateDomain } = useDomainsStore();
  const [domain, setDomain] = useState();
  const { clearErrors } = useAppStore();

  const isNew = matchParams.uuid === "new" ? true : false;

  const header = isNew ? "Submit Website" : "Edit Website";

  const { update, updateError, updateSuccess } = domains;

  useEffect(() => {
    if (updateSuccess) {
      onClose();
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (matchParams.uuid === "new") return;
    setDomain(
      domains.collection.find(
        (domainItem) => domainItem.uuid === matchParams.uuid
      )
    );
  }, [domains]);

  const onClose = async () => {
    await clearErrors();
    props.history.push("/domains");
  };

  /**
   * Handles add/update domain action.
   *
   * @param domain
   */
  const onSubmit = async (domain) => {
    await updateDomain(domain.uuid ? domain.uuid : null, domain);
  };

  const loadingIndicator = update ? <LoadingIndicator /> : null;

  const welcomeInfo = isNew ? (
    <div className="card-box">
      <p>
        To embed your calendars, provide the domain name to which you would like
        to grant access.
      </p>
      <p>You can control which websites can use your calendar.</p>
      <p>Valid website domains examples:</p>
      <ul>
        <li>mydomain.com</li>
        <li>subdomain.mydomain.com</li>
      </ul>
      <p>
        Do not add any trailing slashes, protocols (http://, https://) or www
        subdomain to your website domain.
      </p>
    </div>
  ) : null;

  return (
    <ModalTemplate id={"domainModal"} header={""} size={"lg"} onClose={onClose}>
      <div className={"integration-modal"}>
        <div className="modal-dialog">
          <div className="modal-content modal-md">
            <div className="modal-header">
              <h4 className="modal-title">{header}</h4>
            </div>
            {!domain && !isNew ? null : (
              <div className="modal-body">
                {welcomeInfo}
                <DomainForm
                  isNew={isNew}
                  domain={domain}
                  updateError={updateError}
                  loading={loadingIndicator}
                  onSubmit={onSubmit}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </ModalTemplate>
  );
}

EditDomainModal.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      uuid: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

EditDomainModal.defaultProps = {
  match: {
    params: {
      uuid: "new",
    },
  },
};

export default EditDomainModal;
