/**
 * Created by piotr.pozniak@thebeaverhead.com on 28/06/2022
 */

import React from 'react';
import PropTypes from 'prop-types';
import classnames from "classnames";

const FiltersToggle = props => {

  return (
    <span
      className={classnames("dce-groups--filters-toggle", {
       active: props.showFilters,
      })}
      onClick={props.onToggle}
    >
      <i className="material-icons">tune</i>
    </span>
  )
}

FiltersToggle.defaultProps = {};

FiltersToggle.propTypes = {
 showFilters: PropTypes.bool,
 onToggle: PropTypes.func.isRequired
}

export default FiltersToggle;
