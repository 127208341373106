/**
 * Created by piotr.pozniak@thebeaverhead.com on 19/04/2023
 */

import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { isObject } from "../../tools";

const GoogleForm = ({ integrationType, integration }) => {
  const message = isObject(integration.accessErrors)
    ? Object.keys(integration.accessErrors).map((key) => {
        const error = integration.accessErrors[key];

        const errorCode = error[0].reason;
        const errorMessage = error[0].message;

        return (
          <p key={key}>
            <strong>Service {key.toUpperCase()}</strong> - {errorMessage} (
            {errorCode})
          </p>
        );
      })
    : integration.accessErrors;

  return (
    <div>
      <div>
        <div>
          <p>Your {integrationType.title} API integration wasn't successful.</p>
          <div className={classnames("alert alert-danger show")} role="alert">
            {message}
          </div>
        </div>
        You have chosen to allow access to one or more Google services. However,
        the logged in user does not have access to one or more of these
        services. Either uncheck these services or change your logged in user.
      </div>
    </div>
  );
};

GoogleForm.defaultProps = {};

GoogleForm.propTypes = {
  integrationType: PropTypes.object.isRequired,
  integration: PropTypes.object.isRequired,
};

export default GoogleForm;
