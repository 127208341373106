/**
 * Created by piotr.pozniak@thebeaverhead.com on 08/04/2024
 */

import React, { useCallback, useContext, useMemo } from "react";
import PropTypes from "prop-types";
import DetailsRow from "./DetailsRow";
import classNames from "classnames";
import WidgetSettingsContext from "../../../../contexts/WidgetSettingsContext";

const EventPlace = ({ place }) => {
  const calendarSettingsContext = useContext(WidgetSettingsContext);

  const [selectedPhotoUrl, setSelectedPhotoUrl] = React.useState(
    place?.photos?.[0]?.url
  );

  const showPlace = !!calendarSettingsContext["cPopupShowPlace"];

  const onSelectPhoto = useCallback(
    (url) => () => {
      setSelectedPhotoUrl(url);
    },
    [selectedPhotoUrl]
  );

  const images = useMemo(
    () =>
      place?.photos?.map((photo) => {
        return (
          <div className={"cursor-pointer"} onClick={onSelectPhoto(photo.url)}>
            <img
              src={photo.url}
              alt={photo.caption}
              className={classNames("dc-event-row--place_image-preview", {
                "dc-event-row--place_image-preview--selected":
                  photo.url === selectedPhotoUrl,
              })}
            />
          </div>
        );
      }),
    [selectedPhotoUrl]
  );

  const imageSelector = images ? (
    <div className={"dc-event-row--place_image-selector"}>{images}</div>
  ) : null;

  const currentPhoto = useMemo(
    () => place?.photos?.find((photo) => photo.url === selectedPhotoUrl),
    [selectedPhotoUrl]
  );

  if (!place || !showPlace || !currentPhoto) {
    return null;
  }

  return (
    <div className={"dc-event-row--place"}>
      <div className={"event-row--image-container aspect-169"}>
        <div className={"dc-event-row--place_image-overlay"}></div>
        <img
          src={currentPhoto.url}
          alt={currentPhoto.caption}
          className={"event-row--image"}
        />
        {imageSelector}
        <div className={"dc-event-row--place_name"}>{place.displayName}</div>
      </div>
    </div>
  );
};

EventPlace.defaultProps = {};

EventPlace.propTypes = {};

export default EventPlace;
