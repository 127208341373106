/**
 * Created by piotr.pozniak@thebeaverhead.com on 12/12/2023
 */

import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

const Direction = {
  LEFT: "left",
  RIGHT: "right",
};

/**
 * scrollDom has .bubble-view--bubble children nodes. Each node has its own width.
 * scrollDom is flex box with gap between nodes, uses gap CSS property.
 * The scroll width is a sum of all children nodes with their gaps that can fit
 * into the scrollDom parent width but can't exceed it.
 * @param scrollDOM
 */
const getScrollWidth = (scrollDOM) => {
  let scrollWidth = 0;

  // get child node width
  scrollWidth = scrollDOM.children[0].offsetWidth;

  // get parent gap
  const parentGap = parseInt(
    window.getComputedStyle(scrollDOM).getPropertyValue("gap")
  );

  scrollWidth += parentGap;

  // calculate how many of scrollWidth can fit into scrollDOM parent width
  const parentWidth = scrollDOM.parentNode.offsetWidth;
  const childCount = Math.floor(parentWidth / scrollWidth);
  scrollWidth = childCount * scrollWidth;

  return scrollWidth;
};

const BubbleViewScrollArrow = ({ direction, calendarID }) => {
  const [show, setShow] = useState(false);

  const scrollDOM = document.querySelector(
    `.${calendarID} .calendar-bubble-view_container`
  );

  const parentDOM = document.querySelector(
    `.${calendarID} .calendar-bubble-view`
  );

  useEffect(() => {
    if (!scrollDOM || !parentDOM) {
      return;
    }

    const onResize = (entries) => {
      const entry = entries[0];

      if (scrollDOM.scrollWidth >= parentDOM.scrollWidth) {
        if (direction === Direction.RIGHT) {
          setShow(true);
        }
      }
    };

    const observer = new ResizeObserver(onResize);
    observer.observe(scrollDOM);

    const onScroll = (e) => {
      const scrollLeft = e.target.scrollLeft;
      const scrollWidth = e.target.scrollWidth;
      const clientWidth = e.target.clientWidth;

      if (direction === Direction.RIGHT) {
        if (scrollLeft + clientWidth >= scrollWidth) {
          setShow(false);
        } else {
          setShow(true);
        }
      } else {
        if (scrollLeft === 0) {
          setShow(false);
        } else {
          setShow(true);
        }
      }
    };

    scrollDOM.addEventListener("scroll", onScroll);

    return () => {
      observer.disconnect();
      scrollDOM.removeEventListener("scroll", onScroll);
    };
  }, [setShow, scrollDOM]);

  const onClick = useCallback(() => {
    let scrollLeft = scrollDOM.scrollLeft;
    const scrollWidth = getScrollWidth(scrollDOM);

    if (direction === Direction.RIGHT) {
      scrollLeft += scrollWidth;
    } else {
      scrollLeft -= scrollWidth;
    }
    scrollDOM.scrollTo({
      top: 0,
      left: scrollLeft,
      behavior: "smooth",
    });
  }, [show]);

  if (!show) {
    return null;
  }

  return (
    <div
      className={`calendar-bubble-view_scroll calendar-bubble-view_scroll-${direction}`}
      onClick={onClick}
    >
      <div className={"calendar-bubble-view_scroll_button"}>
        <i className={"material-icons"}>{`keyboard_arrow_${direction}`}</i>
      </div>
    </div>
  );
};

BubbleViewScrollArrow.defaultProps = {};

BubbleViewScrollArrow.propTypes = {
  direction: PropTypes.oneOf([Direction.LEFT, Direction.RIGHT]),
  calendarID: PropTypes.string.isRequired,
};

export default BubbleViewScrollArrow;
export { Direction };
