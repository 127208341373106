/**
 * Created by piotr.pozniak@thebeaverhead.com on 22/02/2019.
 */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import PageTemplate from "../../templates/PageTemplate";
import LoadingIndicator from "../../../calendar/components/LoadingIndicator";
import moment from "moment";
import ApiError from "../../component/ApiError";
import FormError from "../../component/FormError";

const EditModal = (props) => {
  const [state, setState] = useState({
    codeError: null,
    durationError: null,
    startDateError: null,
    endDateError: null,
    subscriptionPlanError: null,
  });

  useEffect(() => {
    if (props.coupons.updateSuccess) {
      window.$("#add_coupon").modal("hide");
      onClose();
    }
  }, [props.coupons.updateSuccess]);

  /**
   *
   */
  const onSubmit = (e) => {
    e.preventDefault();

    const coupon = props.coupon;

    const code = document.querySelector("#coupon_code").value;
    const duration = document.querySelector("#coupon_duration").value * 1;
    const price = document.querySelector("#coupon_price").value * 100;
    const startDate = moment(
      document.querySelector("#coupon_start_date").value
    );
    const endDate = moment(document.querySelector("#coupon_end_date").value);
    const subscriptionPlan = document.querySelector(
      "#coupon_subscription_plan_uuid"
    ).value;

    let codeError = null;
    let durationError = null;
    let startDateError = null;
    let endDateError = null;
    let subscriptionPlanError = null;

    if (!code || !code.length) {
      codeError = "Please enter Coupon Code. ";
    }

    if (!duration) {
      durationError = "Please enter Duration. ";
    }

    if (!startDate.isValid()) {
      startDateError = "Please enter valid start date";
    }

    if (!endDate.isValid()) {
      endDateError = "Please enter valid end date";
    } else if (endDate.isBefore(startDate)) {
      endDateError = "End date must not be earlier than start date.";
    }

    if (!subscriptionPlan) {
      subscriptionPlanError = "Please choose subscription plan.";
    }

    setState({
      codeError,
      durationError,
      startDateError,
      endDateError,
      subscriptionPlanError,
    });

    if (
      !codeError &&
      !durationError &&
      !startDateError &&
      !endDateError &&
      !subscriptionPlanError
    ) {
      const data = {
        ...props.coupon,
        code,
        duration: duration * 24 * 60 * 60,
        start_date: startDate.unix(),
        end_date: endDate.unix(),
        available: 1,
        price,
        subscription_plan_uuid:
          subscriptionPlan == "all" ? null : subscriptionPlan,
      };

      props.onEdit(data);
    }

    return false;
  };

  /**
   *
   */
  const onClose = () => {
    setTimeout(props.onClose, 350);
  };

  const coupon = props.coupon;

  const isNew = coupon.uuid ? false : true;

  const header = isNew ? "Add Coupon" : "Edit Coupon";

  const submitButtonLabel = isNew ? "Create" : "Update";

  const { update, updateError } = props.coupons;

  const loadingIndicator = update ? <LoadingIndicator /> : null;

  const subscriptionPlanOptions = props.subscriptionPlans.collection
    .filter((i) => Number.parseInt(i.available))
    .map((i, idx) => {
      return (
        <option key={"sop-" + idx} value={i.uuid}>
          {i.name} {Number.parseInt(i.is_branded) ? " Branded" : ""}
        </option>
      );
    });

  subscriptionPlanOptions.unshift(
    <option key={"sop-all"} value={"all"}>
      Apply to all
    </option>
  );

  return (
    <div id="add_coupon" className="modal custom-modal fade" role="dialog">
      <div className="modal-dialog">
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          onClick={onClose}
        >
          &times;
        </button>
        <div className="modal-content modal-md">
          <div className="modal-header">
            <h4 className="modal-title">{header}</h4>
          </div>
          <div className="modal-body">
            <form>
              <div className="form-group">
                <label>
                  Code <span className="text-danger">*</span>
                </label>

                <input
                  id="coupon_code"
                  className={
                    "form-control " + (state.codeError ? "is-invalid" : "")
                  }
                  type="text"
                  placeholder="Coupon code"
                  defaultValue={coupon.code}
                />
              </div>

              <div className="form-group">
                <label>
                  Duration (days)<span className="text-danger">*</span>
                </label>

                <input
                  className={
                    "form-control " + (state.durationError ? "is-invalid" : "")
                  }
                  id="coupon_duration"
                  type="number"
                  placeholder="Coupon duration in days"
                  defaultValue={coupon.duration / 24 / 60 / 60}
                />
                <small>
                  Specifies how many days, free of charge, user will receive.
                </small>
              </div>

              <div className="form-group">
                <label>
                  Price ($)<span className="text-danger">*</span>
                </label>

                <input
                  className={"form-control"}
                  id="coupon_price"
                  type="number"
                  placeholder="Coupon price"
                  defaultValue={Number.parseInt(coupon.price) / 100}
                />
                <small>
                  Amount that will be collected when redeeming this coupon.
                </small>
              </div>

              <div className="form-group">
                <label>
                  Subscription Plan applies
                  <span className="text-danger">*</span>
                </label>

                <select
                  className={
                    "form-control " +
                    (state.subscriptionPlanError ? "is-invalid" : "")
                  }
                  id="coupon_subscription_plan_uuid"
                  defaultValue={
                    coupon.uuid != "new" && !coupon.subscription_plan_uuid
                      ? "all"
                      : coupon.subscription_plan_uuid
                  }
                >
                  <option value={null}>Choose plan...</option>
                  {subscriptionPlanOptions}
                </select>

                <small>Specifies the plan features to be granted.</small>
              </div>

              <div className="form-group">
                <label>
                  Start date<span className="text-danger">*</span>
                </label>

                <input
                  className={
                    "form-control " + (state.startDateError ? "is-invalid" : "")
                  }
                  id="coupon_start_date"
                  type="date"
                  placeholder="Start date"
                  data-date-format="MM/DD/YYYY"
                  defaultValue={moment(coupon.start_date * 1000).format(
                    "YYYY-MM-DD"
                  )}
                />
                <small>Coupon will be available to use from this date.</small>
              </div>
              <div className="form-group">
                <label>
                  End date<span className="text-danger">*</span>
                </label>

                <input
                  className={
                    "form-control " + (state.endDateError ? "is-invalid" : "")
                  }
                  id="coupon_end_date"
                  type="date"
                  placeholder="End date"
                  defaultValue={moment(coupon.end_date * 1000).format(
                    "YYYY-MM-DD"
                  )}
                />
                <small>After this date, the coupon will be outdated.</small>
              </div>

              <div className="m-t-20 text-center">
                <div className="form-group">
                  <button className="btn btn-primary btn-lg" onClick={onSubmit}>
                    {submitButtonLabel}
                  </button>
                </div>
              </div>

              <ApiError
                error={updateError}
                defaultErrorMessage={"Could not add coupon. Please try again"}
              />
              {loadingIndicator}

              <FormError
                errors={[
                  state.codeError,
                  state.durationError,
                  state.endDateError,
                  state.startDateError,
                ]}
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

EditModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  coupons: PropTypes.object.isRequired,
  coupon: PropTypes.shape({
    uuid: PropTypes.string,
  }).isRequired,
};

export default EditModal;
