/**
 * Created by piotr.pozniak@thebeaverhead.com on 20/08/2018.
 */

import React from "react";
import PropTypes from "prop-types";
import ReactPagination from "react-js-pagination";


class Pagination extends React.Component {


  static defaultProps = {
    currentPage: 1,
    itemsPerPage: 25
  }



  render() {

    const {total, currentPage, itemsPerPage } = this.props;

    let lastPageIndex = Math.ceil(total/itemsPerPage);

    if (!lastPageIndex) {
      return null;
    }

    return (

      <ReactPagination
        activePage={currentPage}
        itemsCountPerPage={itemsPerPage}
        totalItemsCount={total}
        pageRangeDisplayed={7}
        itemClass="page-item"
        linkClass="page-link"
        onChange={this.props.onPageChange}
        nextPageText={lastPageIndex ? "»" : null}
        prevPageText={lastPageIndex ? "«" : null}
        firstPageText={lastPageIndex ? "1" : null}
        lastPageText={lastPageIndex+ ""}
        getPageUrl={this.props.getPageUrl}
      />

    );
  }
}

Pagination.propTypes = {
  currentPage: PropTypes.number,
  total: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number,
  onPageChange: PropTypes.func.isRequired,
  getPageUrl: PropTypes.func.isRequired,
};

export default Pagination;
