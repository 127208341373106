import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as affiliatesActions from "../../actions/affiliates";
import { resetMagicLink } from "../../actions/affiliates";

export function useAffiliatesStore() {
  const dispatch = useDispatch();
  const _affiliates = useSelector((store) => store.affiliates);

  const fetchAffiliate = useCallback(
    async () => await dispatch(affiliatesActions.fetchAffiliate()),
    [dispatch]
  );

  const fetchMagicLink = useCallback(
    async (affiliateId) =>
      await dispatch(affiliatesActions.fetchMagicLink(affiliateId)),
    [dispatch]
  );

  const resetMagicLink = useCallback(
    async () => await dispatch(affiliatesActions.resetMagicLink()),
    [dispatch]
  );

  const createAffiliate = useCallback(
    async () => await dispatch(affiliatesActions.createAffiliate()),
    [dispatch]
  );

  const updateAffiliate = useCallback(
    async (affiliateId) =>
      await dispatch(affiliatesActions.updateAffiliate(affiliateId)),
    [dispatch]
  );

  const updateLinkToken = useCallback(
    async (affiliateId, linkId, token) =>
      await dispatch(
        affiliatesActions.updateLinkToken(affiliateId, linkId, token)
      ),
    [dispatch]
  );

  return {
    affiliates: _affiliates,
    fetchAffiliate,
    fetchMagicLink,
    createAffiliate,
    updateAffiliate,
    updateLinkToken,
    resetMagicLink,
  };
}
