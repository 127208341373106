/**
 * Created by piotr.pozniak@thebeaverhead.com on 12/04/2022
 */

import React from "react";
import PropTypes from "prop-types";
import LoadingIndicator from "../Mosaic/LoadingIndicator";
import Tile from "../Mosaic/Tile";
import classnames from "classnames";
import { WT } from "../../../admin/consts/widget";
import {GT, PT} from "../../../../consts";

const Mosaic = (props) => {
  const { group, items } = props;

  const tiles = items.collection.map((i, idx) => {
    return (
      <Tile
        item={i}
        key={`tcl-${idx}`}
        wide={idx % 9 === 0}
        tall={idx % 5 === 0}
        onSelectedEvent={props.onSelectedEvent}
      />
    );
  });

  const content =
    items.fetch || !props.initialized ? <LoadingIndicator /> : tiles;

  return (
      <div className={`dce--${GT.mosaic}`}>{content}</div>
  );
};

Mosaic.defaultProps = {};

Mosaic.propTypes = {
  onSelectedEvent: PropTypes.func,

};

export default Mosaic;
