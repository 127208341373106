/**
 * Created by piotr.pozniak@thebeaverhead.com on 16/07/2022
 */

import React, { useContext, useMemo } from "react";
import PropTypes from "prop-types";
import Selector from "./Selectors/Selector";
import IntegrationContext from "../strategies/IntegrationContext";
import { useFiltersStore } from "../../../hooks/redux/filters";
import WidgetSettingsContext from "../../../contexts/WidgetSettingsContext";

const CalendarHeaderSelectors = (props) => {
  const integrationStrategy = useContext(IntegrationContext);
  const widgetSettings = useContext(WidgetSettingsContext);
  const { filters } = useFiltersStore();

  const widgetFiltersByKind = useMemo(
    () =>
      integrationStrategy.availableFilters.reduce((acc, kind) => {
        acc[kind] = filters.widgetFiltersCollection.filter(
          (i) => i.kind === kind
        );
        return acc;
      }, {}),
    [filters.widgetFiltersCollection]
  );

  return useMemo(
    () =>
      integrationStrategy.availableFilters
        .map((kind) => {
          const showSelector = widgetSettings["show" + kind + "Dropdown"];

          if (!showSelector) {
            return null;
          }

          const filterLabel = widgetSettings["label" + kind + "Dropdown"];
          return (
            <Selector
              key={"sk-" + kind}
              allOption={{ kind, id: null, name: "Any" }}
              onChange={props.onChangeFilter}
              options={
                widgetFiltersByKind[kind] && widgetFiltersByKind[kind].length
                  ? widgetFiltersByKind[kind]
                  : []
              }
              currentSelection={
                props.selectedFilters ? props.selectedFilters[kind] : {}
              }
              header={
                filterLabel || integrationStrategy.filtersSettings[kind].label
              }
            />
          );
        })
        .filter((i) => i),
    [
      widgetSettings,
      widgetFiltersByKind,
      props.selectedFilters,
      integrationStrategy.availableFilters,
    ]
  );
};

CalendarHeaderSelectors.defaultProps = {};

CalendarHeaderSelectors.propTypes = {
  onChangeFilter: PropTypes.func.isRequired,
  selectedFilters: PropTypes.object,
};

export default CalendarHeaderSelectors;
