/**
 * Created by piotr.pozniak@thebeaverhead.com on 22/02/2024
 */

import React from "react";
import PropTypes from "prop-types";
import { useUserStore } from "../../../../../hooks/redux/user";
import { useCurrentOrganizationStore } from "../../../../../hooks/redux/currentOrganization";

const UserName = (props) => {
  const { user } = useUserStore();
  const { currentOrganization } = useCurrentOrganizationStore();
  const orgModel = currentOrganization.model || {};

  const userModel = user.model;

  return (
    <div className="nav-user-menu--user-name_container" onClick={props.onClick}>
      <span className="user-img">
        <img
          className="rounded-circle"
          src="/img/user.jpg"
          width="40"
          alt="Admin"
        />
      </span>
      <div className={"nav-user-menu--user-name"}>
        <div className={"nav-user-menu--user-name_name"}>{userModel.name}</div>
        <div className={"nav-user-menu--user-name_org-name"}>
          @{orgModel.name}
        </div>
      </div>
      <i className={"material-icons"} aria-hidden={false}>
        keyboard_arrow_down
      </i>
    </div>
  );
};

UserName.defaultProps = {};

UserName.propTypes = {};

export default UserName;
