/**
 * Created by piotr.pozniak@thebeaverhead.com on 07/02/2023.
 */

import axios from "axios";
import AxiosConfig from "../AxiosConfig";

/**
 *
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export function fetchQueueJobs(page, jobType = "Integration", perPage = 50) {
  return function (dispatch) {
    dispatch({ type: "QUEUE_JOBS_FETCH" });

    const url =
      "/api/queued-jobs.json?" +
      AxiosConfig.objectToURLQuery({
        page,
        limit: perPage,
        job_type: jobType,
      });

    return axios
      .get(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "QUEUE_JOBS_FETCH_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "QUEUE_JOBS_FETCH_REJECTED",
          payload: error,
        });
      });
  };
}

/**
 *
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export function clearQueueCollection() {
  return function (dispatch) {
    dispatch({ type: "QUEUE_JOBS_CLEAR" });
  };
}
