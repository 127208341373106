/**
 * Created by piotr.pozniak@thebeaverhead.com on 19/05/2024
 */

import React from "react";
import PropTypes from "prop-types";
import NumberInput from "./NumberInput";
import DateRangeInput from "./DateRangeInput";

const InputMapping = {
  ["number"]: NumberInput,
  ["daterange"]: DateRangeInput,
};

const Input = (props) => {
  const Component = InputMapping[props.type] || null;

  if (!Component) {
    return null;
  }

  return <Component {...props} />;
};

Input.defaultProps = {};

Input.propTypes = {};

export default Input;
