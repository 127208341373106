/**
 * Created by enes.kahrovic@thebeaverhead.com on 24/10/2018.
 */

import React, { useEffect } from "react";

import { Link, useHistory } from "react-router-dom";
import PageTemplate from "./../templates/PageTemplate";
import TableRow from "./CalendarsPage/TableRow";
import LoadingIndicator from "../../calendar/components/LoadingIndicator";
import ApiError from "../component/ApiError";
import { getCalendarCurrentData } from "../../../actions/calendar";
import { useCalendarsStore } from "../../../hooks/redux/calendars";
import { useCalendarStore } from "../../../hooks/redux/calendar";
import { formatError } from "../../../helpers/errors";
import { calendarFeaturesCheck, updateJquery } from "../tools";
import NotificationPopup from "../component/NotificationPopup";
import useNotificationToast from "../hooks/useNotificationToastHook";
import { useCurrentOrganizationStore } from "../../../hooks/redux/currentOrganization";

const CalendarsPage = (props) => {
  const history = useHistory();
  const { calendars, fetchCalendars } = useCalendarsStore();
  const { calendar, updateCalendar, setCalendar, deleteCalendar } =
    useCalendarStore();
  const { currentOrganization } = useCurrentOrganizationStore();
  const { addNotification } = useNotificationToast();

  useEffect(() => {
    fetchCalendars();
  }, []);

  useEffect(() => {
    if (calendar.updateError) {
      addNotification({
        content: (props) => (
          <NotificationPopup {...props}>
            <p className="notification-title">The following error occurred:</p>
            {formatError(calendar.updateError).join("")}
          </NotificationPopup>
        ),
      });
    }
  }, [calendar.updateError]);

  /**
   *
   * @param domain
   */
  const onChangeStatus = async (calendar) => {
    const _calendar = { ...calendar };
    _calendar.available = calendar.available ? 0 : 1;
    const calendarData = getCalendarCurrentData(_calendar);

    const issues = calendarFeaturesCheck(
      _calendar,
      currentOrganization,
      calendars
    );

    if (issues.length) {
      addNotification({
        content: (props) => (
          <NotificationPopup {...props}>
            <p className="notification-title">
              <i className={"material-icons"}>warning</i> You cannot publish
              this calendar because:
            </p>
            <ul className={"notification-message pl-4"}>
              {issues.map((i, idx) => (
                <li key={`per-${idx}`}>{i}</li>
              ))}
            </ul>
          </NotificationPopup>
        ),
      });

      return false;
    }
    await updateCalendar(calendar.uuid, calendarData);
    setCalendar(null);
  };

  useEffect(() => {
    /**
     * If no calendars available, navigate to new calendar page.
     * Handle back button so user does not enter an infinite loop.
     */
    if (calendars.fetchSuccess && !calendars.collection.length) {
      if (history.action === "POP" && props) {
        history.go(-1);
      } else {
        history.push("/calendar/new");
      }
    }
    updateJquery();
  }, [calendars.fetchSuccess]);

  /**
   *
   * @param calendar
   */
  const onClone = async (calendar) => {
    const calendarData = getCalendarCurrentData(calendar);
    calendarData.uuid = null;
    calendarData.available = 0;
    calendarData.name = "Copy of " + calendarData.name;

    await updateCalendar(null, calendarData);
    await setCalendar(null);
  };

  /**
   *
   */
  const onDelete = (calendar) => {
    if (
      window.confirm(
        "Are you sure you want to delete calendar " +
          calendar.name +
          "? Changes are irreversible!"
      )
    ) {
      deleteCalendar(calendar.uuid);
    }
  };

  const loading = calendars.fetch || calendars.delete || calendars.sync;

  const loadingIndicator = loading ? <LoadingIndicator /> : null;

  const calendarRows = calendars.collection.map((item, idx) => {
    return (
      <TableRow
        disabled={loading}
        key={idx}
        calendar={item}
        onChangeStatus={onChangeStatus}
        onDelete={onDelete}
        calendarEditUUID={calendars.updateCalendarUUID}
        onClone={onClone}
      />
    );
  });

  const calendarsData = calendars.collection.length ? (
    <table className="table table-striped custom-table">
      <thead>
        <tr>
          <th width="30%">Name</th>
          <th width="15%">Integration</th>
          <th width="20%">Template</th>
          <th width="10%">Private events</th>
          <th width="10%">Status</th>
          <th width="10%">Last edited</th>
          <th className="text-right">Action</th>
        </tr>
      </thead>
      <tbody>{calendarRows}</tbody>
    </table>
  ) : (
    loadingIndicator
  );

  return (
    <React.Fragment>
      <PageTemplate
        sidebarId="calendar"
        title={"Calendars"}
        icon={"event"}
        headerControls={[
          <Link
            key={"/calendar/new"}
            to="/calendar/new"
            className="btn btn-primary btn-rounded pull-right d-flex"
          >
            <i className="material-icons">add</i> Create new
          </Link>,
        ]}
      >
        <div className="row">
          <div className="col-md-12">
            <div className="table-responsive min-height-50">
              {calendarsData}

              <ApiError
                error={calendars.deleteError}
                defaultErrorMessage={
                  "Could not delete the domain. Please try again"
                }
              />
            </div>
          </div>
        </div>
      </PageTemplate>
    </React.Fragment>
  );
};

export default CalendarsPage;
