/**
 * Created by piotr.pozniak@thebeaverhead.com on 02/04/2024
 */

import React, { useCallback, useContext } from "react";
import PropTypes from "prop-types";
import ShareLink from "./ShareLink";
import ShareButton from "./ShareButton";
import QRCode from "./QRCode";
import ShareButtonFacebook from "./ShareButtonFacebook";
import ShareButtonX from "./ShareButtonX";
import LinkCopyInput from "./LinkCopyInput";
// import WidgetSettingsContext from "../../../../contexts/WidgetSettingsContext";
import WidgetSettingsContext from "../../contexts/WidgetSettingsContext";
import { useTranslation } from "react-i18next";

const SharePopup = ({ url, title, text, onClose }) => {
  const calendarSettingsContext = useContext(WidgetSettingsContext);
  const { t } = useTranslation();

  const _onClose = useCallback((e) => {
    onClose(e);
  }, []);

  const showQrCode =
    Number.parseInt(calendarSettingsContext["popupQrCode"]) === 1;

  const showShareLink =
    Number.parseInt(calendarSettingsContext["popupShareLink"]) === 1;

  const showFacebookButton =
    Number.parseInt(calendarSettingsContext["shareFacebookShow"]) === 1;

  const showXButton =
    Number.parseInt(calendarSettingsContext["shareXButtonShow"]) === 1;

  const qrCodeBgColor = calendarSettingsContext["qrCodeBgColor"];
  const qrCodeFgColor = calendarSettingsContext["qrCodeFgColor"];

  const isShowShareLink = showShareLink ? <LinkCopyInput url={url} /> : null;

  return (
    <div
      className="dc-share-popup dc-share-popup--hidden dc-share-popup--fade-in"
      id="dc-share-popup"
    >
      <div className={"dc-share-popup--card"}>
        <div className={"dc-share-popup--card--close"} onClick={_onClose}></div>
        <div className={"dc-share-popup--card-section"}>
          <div className={"dc-share-popup--card-section--header"}>
            {t("Share")}
          </div>
          <div className={"dc-share-popup--card-section--subheader"}>
            {t("Link to")} {title}
          </div>

          {/*<div
          className={
            "dc-share-popup--card-section--header dc-share-popup--card-section--header_no-margin"
          }
        >
          <div class={"dc-share-popup--card-section--header_center"}>
            Share contactless
          </div>
        </div>*/}
          <div className={""}>
            <QRCode
              value={url}
              title={null}
              showDownloadButton={false}
              containerClassName={"dc-share-popup--card-section--qr"}
              showQrCode={showQrCode}
              qrCodeBgColor={qrCodeBgColor}
              qrCodeFgColor={qrCodeFgColor}
            />
          </div>
        </div>

        <div className={"dc-share-popup--card-section--buttons"}>
          {showFacebookButton ? (
            <ShareButtonFacebook title={title} url={url} />
          ) : null}
          {showXButton ? <ShareButtonX title={title} url={url} /> : null}
          <ShareLink title={title} url={url} text={text} />
        </div>
        {isShowShareLink}
      </div>
    </div>
  );
};

SharePopup.defaultProps = {};

SharePopup.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  onClose: PropTypes.func,
};

export default SharePopup;
