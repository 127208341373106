/**
 * Created by piotr.pozniak@thebeaverhead.com on 21/01/2022
 */

import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const PreloadImage = (props) => {
  const [state, setState] = useState({
    opacity: 0,
  });

  const onload = (e) => {
    setState({ opacity: 1 });
  };

  const preloader = !state.opacity ? (
    <img style={{ width: 0, height: 0 }} src={props.src} onLoad={onload} />
  ) : null;

  return (
    <React.Fragment>
      <img
        style={{ opacity: state.opacity }}
        alt={props.alt}
        className={classNames(props.className, "img-preview")}
        src={state.opacity ? props.src : null}
      />
      {preloader}
    </React.Fragment>
  );
};

PreloadImage.defaultProps = {};

PreloadImage.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  src: PropTypes.string,
  previewData: PropTypes.string,
};

export default PreloadImage;
