/**
 * Created by piotr.pozniak@thebeaverhead.com on 19/04/2023
 */

import React from "react";
import PropTypes from "prop-types";

const CCBForm = (props) => {
  return <div></div>;
};

CCBForm.defaultProps = {};

CCBForm.propTypes = {};

export default CCBForm;
