/**
 * Created by piotr.pozniak@thebeaverhead.com on 24/08/2022
 */

import React, { useMemo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useFiltersStore } from "../../../../hooks/redux/filters";
import CrownIcon from "../CrownIcon";
import { useWidgetsStore } from "../../../../hooks/redux/widgets";
import { useCurrentOrganizationStore } from "../../../../hooks/redux/currentOrganization";

const WidgetName = (props) => {
  const { widgetName, widget, isLoading } = props;
  const { widgets } = useWidgetsStore();
  const { currentOrganization } = useCurrentOrganizationStore();
  const { filters } = useFiltersStore();

  const available = widget.available;
  const mustPublish = useMemo(() => {
    const errors = props.widgetFeaturesCheck(
      { ...widget, available: 1 },
      currentOrganization,
      widgets,
      null
    );

    return errors.length;
  }, [widget, filters]);

  const statusMobile =
    !isLoading && !mustPublish ? (
      <div className="widget-previously-published">
        <span
          disabled={true}
          className={classNames("d-flex", {
            "text-success": available,
            "text-secondary": !available,
          })}
          href={null}
        >
          <i className="material-icons">adjust</i>
          {available ? "Published" : "Unpublished"}
        </span>
      </div>
    ) : null;

  const publishCTAMobile = mustPublish ? (
    <span className={"widget-previously-published"}>
      <CrownIcon className={"small mr-1"} /> Upgrade to Publish
    </span>
  ) : null;

  const previouslyPublished = widget.disabled_date ? (
    <span className={"widget-previously-published text-danger"}>
      <i className={"material-icons"}>error</i>Previously Published
      <i
        className={"material-icons text-secondary m-l-5 cursor-pointer"}
        data-toggle={"tooltip"}
        data-toggleaa={"2222"}
        data-placement={"bottom"}
        title={"This calendar has been unpublished because of plan downgrade."}
      >
        help
      </i>
    </span>
  ) : null;

  return (
    <div className={"widget-name--wrapper"}>
      <div className={"d-flex flex-column"}>
        <div className={"mobile-widget-name"}>{widgetName}</div>
        <div className={"d-flex mobile-widget-info"}>
          {statusMobile} {publishCTAMobile} {previouslyPublished}
        </div>
      </div>
    </div>
  );
};

WidgetName.defaultProps = {};

WidgetName.propTypes = {
  widgetName: PropTypes.string,
  widget: PropTypes.object,
  widgetFeaturesCheck: PropTypes.func.isRequired,
};

export default WidgetName;
