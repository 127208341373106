import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { ucFirst } from "../../helpers/string";
import AxiosConfig from "../../AxiosConfig";

const ShareButton = ({ url, title, text, type }) => {
  const imgSrc =
    AxiosConfig.getEndpointAddress() + "/img/socials/" + type + ".svg";

  const icon = (
    <img className={"dc-share-button--img"} src={imgSrc} alt={title} />
  );
  return (
    <a
      href={url}
      className={"dc-share-button"}
      target={`_dc_${type}`}
      title={`Share on ${ucFirst(type)}`}
    >
      <div
        className={classNames("dc-socials-icon", `dc-socials-icon--${type}`)}
      >
        {icon}
      </div>
      <div className={"dc-share-button--label"}>{ucFirst(type)}</div>
    </a>
  );
};

ShareButton.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
};
export default ShareButton;
