/**
 * Created by piotr.pozniak@thebeaverhead.com on 20/11/2023.
 */

import React, { useState, useEffect, useMemo, useCallback } from "react";

import { useHistory } from "react-router-dom";
import PageTemplate from "./../templates/PageTemplate";
import TableRow from "./OrganizationsPage/TableRow";
import LoadingIndicator from "../../calendar/components/LoadingIndicator";
import Pagination from "../component/Pagination";
import ApiError from "../component/ApiError";
import SuccessAlert from "../component/SuccessAlert";
import Th from "../component/Table/Th";
import { useSubscriptionStore } from "../../../hooks/redux/subscription";
import { useOrganizationsStore } from "../../../hooks/redux/organizations";
import { store } from "../store";
import { useAutologinStore } from "../../../hooks/redux/autologin";
import { UserRoles } from "../consts/crm";
import { useUserStore } from "../../../hooks/redux/user";
import appConfig from "../../../appConfig";
import { useTranslation } from "react-i18next";

const OrganizationsPage = (props) => {
  const queryParams = useMemo(() => {
    return new URLSearchParams(window.location.search);
  }, [window.location.search]);

  const {
    organizations,
    fetch: fetchOrganizations,
    deleteOrganization,
  } = useOrganizationsStore();

  const { t } = useTranslation();

  const [state, setState] = useState({
    editUser: {
      uuid: null,
    },

    orderField: queryParams.get("sort"),
    orderDirection: queryParams.get("direction"),
  });

  const { terminateSubscriptionAdmin } = useSubscriptionStore();
  const { user } = useUserStore();
  const { autologinUser } = useAutologinStore();

  const history = useHistory();

  const refreshorganizationsList = useCallback(() => {
    const page = props.match.params.page;
    const ipp = props.match.params.ipp;
    fetchOrganizations(
      page ? page * 1 : 1,
      ipp ? ipp * 1 : 50,
      organizations.filters.name,
      organizations.filters.email,
      queryParams.get("sort"),
      queryParams.get("direction")
    );
  }, [props.match.params.page, window.location.search]);

  useEffect(() => {
    /*
    const page = props.match.params.page;
    const ipp = props.match.params.ipp;

    props.usersActions.fetch(
      page ? page * 1 : 1,
      50,
      props.users.filters.name,
      props.users.filters.email
    );
*/

    return () => {
      window.$("#user_edit_modal").modal("hide");
    };
  }, []);

  useEffect(() => {
    refreshorganizationsList();
  }, [props.match.params.page, window.location.search]);

  /**
   *
   */
  useEffect(() => {
    onPaginationPageChange(1);
  }, [state.orderField, state.orderDirection]);

  useEffect(() => {
    if (organizations.activateSuccess) {
      setState({
        editUser: organizations.collection.find(
          (i) => i.uuid == state.editUser.uuid
        ),
      });
    }
  }, [organizations.activateSuccess]);

  useEffect(() => {
    if (organizations.updateSuccess) {
      window.$("#user_edit_modal").modal("hide");
    }
  }, [organizations.updateSuccess]);

  useEffect(() => {
    if (state.editUser.uuid) {
      window.$("#user_edit_modal").modal("show");
    }
  }, [state.editUser]);

  useEffect(() => {
    /*    console.log(
      "organizations.terminateSubscriptionSuccess",
      organizations.terminateSubscriptionSuccess
    );*/
    if (organizations.terminateSubscriptionSuccess) {
      refreshorganizationsList();
    }
  }, [organizations.terminateSubscriptionSuccess]);

  /**
   *
   * @param user
   */
  const onUserDelete = (user) => {
    const promptValue =
      window.prompt(`Are you sure you want to delete user ${user.name}?
    Type '${user.name}' to confirm.`);

    if (promptValue === user.name) {
      deleteOrganization(user.uuid);
    }
  };

  /**
   *
   * @type {(function(*): Promise<*>)|*}
   */
  const onTerminateSubscription = useCallback(async (organization) => {
    if (
      window.confirm(
        `Are you sure you want to terminate subscription for organization ${organization.name} (${organization.treasurer_email})?`
      )
    ) {
      await terminateSubscriptionAdmin(organization.uuid);
    }
  }, []);

  const onAutoLogin = async (organization) => {
    // console.log(organization);

    const autologinAsUser = organization.users.find(
      (i) => i.role === UserRoles.owner
    );
    await autologinUser(autologinAsUser.email, user);

    if (store.getState().autologin.loginSuccess) {
      history.push("/dashboard");
    }
  };

  /**
   *
   * @param page
   * @returns {string}
   */
  const getCurrentPageUrl = (page) =>
    `/${appConfig.links.organizations}/` + page;

  /**
   *
   * @param page
   * @returns {*}
   */
  const onPaginationPageChange = (page) => {
    const sort = queryParams.get("sort");
    const direction = queryParams.get("direction");

    let url = `/${appConfig.links.organizations}/${page}`;

    if (state.orderField) {
      url += `?sort=${state.orderField}`;

      if (state.orderDirection) {
        url += `&direction=${state.orderDirection}`;
      }
    }

    history.push(url);
    window.scrollTo({ top: 0 });
  };

  const loading =
    organizations.fetch ||
    organizations.delete ||
    organizations.terminateSubscription;

  const organizationsList = organizations.collection.map((item, idx) => {
    return (
      <TableRow
        disabled={
          loading ||
          (organizations.terminateSubscription &&
            organizations.terminateSubscription.uuid == item.uuid)
        }
        key={idx}
        organization={item}
        onDelete={onUserDelete}
        onAutoLogin={onAutoLogin}
        onTerminateSubscription={onTerminateSubscription}
      />
    );
  });

  const loadingIndicator = loading ? <LoadingIndicator /> : null;

  /**
   *
   * @param e
   */
  const clearFilters = (e) => {
    e.preventDefault();

    fetchOrganizations(1, 50, "", "");
  };

  /**
   *
   * @param name
   * @param direction
   */
  const onChangeOrder = (name, direction) => {
    const directionsMapping = {};
    directionsMapping[null] = "asc";
    directionsMapping["asc"] = "desc";
    directionsMapping["desc"] = null;

    setState({
      ...state,
      orderField: name,
      orderDirection:
        state.orderField == name ? directionsMapping[direction] : "asc",
    });
  };

  /**
   *
   * @param e
   */
  const filterOrgs = (e) => {
    e.preventDefault();

    const name = document.getElementById("organizations-filter-name").value;
    const email = document.getElementById("organizations-filter-email").value;

    fetchOrganizations(1, 50, name, email);
  };

  const tableRows = [
    {
      label: "Name",
      orderField: "name",
    },
    {
      label: "Treasury Email",
      orderField: "email",
    },
    {
      label: "No. of Users",
    },
    {
      label: "Owner Account",
    },
    {
      label: "First Subscription",
      orderField: "installation_fee_paid",
    },
    {
      label: "Plan",
      orderField: "plan",
    },
    {
      label: "Plan Expires",
      orderField: "expiry",
    },
  ];

  const usersData = organizations.collection.length ? (
    <div>
      <table className="table table-striped custom-table">
        <thead>
          <tr>
            {tableRows.map((i, idx) => {
              return (
                <Th
                  key={"tr-" + idx}
                  onOrder={onChangeOrder}
                  orderField={i.orderField}
                  currentDirection={
                    state.orderField === i.orderField
                      ? state.orderDirection
                      : null
                  }
                >
                  {i.label}
                </Th>
              );
            })}
            <th className="text-right">Action</th>
          </tr>
        </thead>
        <tbody>{organizationsList}</tbody>
      </table>
    </div>
  ) : null;

  return (
    <PageTemplate
      sidebarId="organizations"
      title={t("Organizations")}
      icon={"corporate_fare"}
    >
      <h5 className="col-sm-12">
        <ApiError
          error={organizations.deleteError}
          defaultErrorMessage={"Couldn't delete User. Please try again"}
        />
        <ApiError
          error={organizations.terminateSubscriptionError}
          defaultErrorMessage={
            "Couldn't terminate user's subscription. Please try again"
          }
        />

        <SuccessAlert>
          {organizations.deleteSuccess
            ? "User has been deleted successfully."
            : null}
        </SuccessAlert>
      </h5>
      <div className="row">
        <div className="col-md-12">
          <div className={"card"}>
            <div className={"card-header"}>Filter</div>
            <div className={"card-body"}>
              <div className={"row filter-row"}>
                <div className="col-sm-6 col-md-3">
                  <div className="form-group form-focus">
                    <label className="focus-label">User Name</label>
                    <input
                      id={"organizations-filter-name"}
                      className="form-control floating"
                      defaultValue={organizations.filters.name}
                      type="text"
                      disabled={loading}
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-3">
                  <div className="form-group form-focus">
                    <label className="focus-label">Email</label>
                    <input
                      id={"organizations-filter-email"}
                      className="form-control floating"
                      defaultValue={organizations.filters.email}
                      type="text"
                      disabled={loading}
                    />
                  </div>
                </div>
              </div>
              <div className={"row filter-row"}>
                <div className="col-md-6"></div>
                <div className="col-sm-6 col-md-3">
                  <a
                    href="#"
                    className="btn btn-warning btn-block"
                    onClick={clearFilters}
                  >
                    {" "}
                    Clear{" "}
                  </a>
                </div>
                <div className="col-sm-6 col-md-3">
                  <a
                    href="#"
                    className="btn btn-success btn-block"
                    onClick={filterOrgs}
                  >
                    {" "}
                    Search{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="table-responsive">
            {usersData}

            <Pagination
              itemsPerPage={organizations.pagination.perPage}
              currentPage={organizations.pagination.page}
              total={organizations.pagination.count}
              getPageUrl={getCurrentPageUrl}
              onPageChange={onPaginationPageChange}
            />
            {loadingIndicator}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-4">
          <small>Table colors explanation:</small>
          <table className="table table-striped custom-table">
            <tbody>
              <tr className={"table-info"}>
                <td>Organization is on trial</td>
              </tr>
              <tr className={"table-warning"}>
                <td>
                  Organization has cancelled their subscription but it is still
                  active for the remaining period of time
                </td>
              </tr>

              <tr className={"table-danger"}>
                <td>Organization has cancelled their subscription</td>
              </tr>
              <tr className={"table-secondary"}>
                <td>
                  Organization is either on trial or subscription but did not
                  pay for plan, their subscription is still active
                </td>
              </tr>
              <tr className={"table-success"}>
                <td>Organization is on a paid plan</td>
              </tr>
              <tr className="">
                <td>
                  Organizations without verified email, terminated trial period
                  or with cancelled subscription due to unpaid plan
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </PageTemplate>
  );
};

export default OrganizationsPage;
