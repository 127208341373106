/**
 * Created by piotr.pozniak@thebeaverhead.com on 01/07/2022.
 */

import React, { useContext } from "react";
import DesignTooltip from "./DesignTooltip";
import PropTypes from "prop-types";
import { BuilderInputSetupPropTypes } from "./BuilderOptionPropTypes";
import WidgetSettingsContext from "../../../../../contexts/WidgetSettingsContext";

const RangeSetup = (props) => {
  const { name, title, control } = props;
  const widgetSettingsContext = useContext(WidgetSettingsContext);

  /**
   *
   * @param field
   * @returns {(function(*): void)|*}
   */
  const onChange = (e) => {
    const option = control.options[e.target.value - 1];
    props.updateDesignProp([
      { field: name + "Option", value: "custom" },
      { field: name, value: option.value },
    ]);
  };

  const rangeValue = widgetSettingsContext[name];
  const maxOptions = control.options.length;
  const selectedOptionIndex = control.options.findIndex(
    (i) => i.value === rangeValue
  );
  const value = selectedOptionIndex + 1;
  const selectedOption = control.options[selectedOptionIndex];

  return (
    <div className={"design-element"}>
      <label className="option-label">
        <span className={"option-title"}>{title}</span>
        <DesignTooltip help={props.tooltip} />
      </label>

      <input
        type="range"
        min="1"
        max={maxOptions}
        defaultValue={value}
        className="slider"
        onChange={onChange}
      />
      <p className={"text-center"}>{selectedOption.label}</p>
    </div>
  );
};

RangeSetup.propTypes = {
  ...BuilderInputSetupPropTypes,

  control: PropTypes.shape({
    kind: PropTypes.oneOf(["range"]).isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ),
  }).isRequired,
};

export default RangeSetup;
