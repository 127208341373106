/**
 * Created by piotr.pozniak@thebeaverhead.com on 24/12/2021.
 */

import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as subscriptionPlansActions from "../../actions/plans";

export function useSubscriptionPlansStore() {
  const dispatch = useDispatch();
  const _subscriptionPlans = useSelector((store) => store.subscriptionPlans);


  const fetchPlans = useCallback(
    async () => await dispatch(subscriptionPlansActions.fetchPlans()),
    [dispatch]
  );
  const updatePlan = useCallback(
    async (uuid, data) => await dispatch(subscriptionPlansActions.updatePlan(uuid, data)),
    [dispatch]
  );

  const deletePlan = useCallback(
    async (uuid) => await dispatch(subscriptionPlansActions.deletePlan(uuid)),
    [dispatch]
  );

  return {
    subscriptionPlans: _subscriptionPlans,
    fetchPlans,
    updatePlan,
    deletePlan

  };
}
