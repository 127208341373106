/**
 * Created by piotr.pozniak@thebeaverhead.com on 02/08/2024
 */

import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { copyTextToClipboard } from "../../tools";

export const CopiedBadge = () => (
  <div className={"btn btn-dark btn-sm"}>Copied!</div>
);

const LinkTableRow = ({ link }) => {
  const [showLinkCopied, setShowLinkCopied] = useState(false);
  const [showTokenCopied, setTokenLinkCopied] = useState(false);

  useEffect(() => {
    if (showLinkCopied || showTokenCopied) {
      const timeout = setTimeout(() => {
        setShowLinkCopied(false);
        setTokenLinkCopied(false);
      }, 3000);
      return () => clearTimeout(timeout);
    }
  }, [showLinkCopied, showTokenCopied]);
  /**
   *
   * @type {(function(): void)|*}
   */
  const onCopyLink = useCallback(() => {
    copyTextToClipboard(link.url);
    setShowLinkCopied(true);
  }, [showLinkCopied]);

  /**
   *
   * @type {(function(): void)|*}
   */
  const onCopyToken = useCallback(() => {
    copyTextToClipboard(link.token);
    setTokenLinkCopied(true);
  }, [showTokenCopied]);

  return (
    <tr>
      <td>
        {link.url}{" "}
        {showLinkCopied ? (
          <CopiedBadge />
        ) : (
          <button className={"btn btn-primary btn-sm"} onClick={onCopyLink}>
            Copy link
          </button>
        )}
      </td>
      <td>
        <div className={"d-flex align-items-center"}>
          {link.token}
          {showTokenCopied ? (
            <CopiedBadge />
          ) : (
            <button
              className={"btn btn-link cursor-pointer"}
              onClick={onCopyToken}
            >
              <i className={"material-icons icon-sm"}>content_copy</i>{" "}
            </button>
          )}
        </div>
      </td>
      <td>{link.visitors}</td>
      <td>{link.leads}</td>
      <td>{link.conversions}</td>
    </tr>
  );
};

LinkTableRow.defaultProps = {};

LinkTableRow.propTypes = {};

export default LinkTableRow;
