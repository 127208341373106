import React from "react";
import PropTypes from "prop-types";
import { CT, ST } from "../../../../consts";

const SignupTypeName = (props) => {
  let icon = "";
  let fullName = "";
  switch (props.type) {
    case ST.cardGrid:
      icon = "view_module";
      fullName = "Cards (Grid)";
      break;
    case ST.cardList:
      icon = "reorder";
      fullName = "Cards (List)";
      break;
  }

  return (
    <span className={"calendars--row--calendar-type"}>
      <i className="material-icons">{icon}</i> {fullName}
    </span>
  );
};

SignupTypeName.propTypes = {
  type: PropTypes.string.isRequired,
};

export default SignupTypeName;
