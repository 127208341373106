/**
 * Created by piotr.pozniak@thebeaverhead.com on 16/02/2022
 */

import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const NoEvents = (props) => {
  const { t } = useTranslation();

  return (
    <p className="events-list-container--events-empty">{t("No Events")}</p>
  );
};

NoEvents.defaultProps = {};

NoEvents.propTypes = {};

export default NoEvents;
