/**
 * Created by piotr.pozniak@thebeaverhead.com on 14/05/2024
 */

import React, { useCallback } from "react";
import PropTypes from "prop-types";
import DesignTooltip from "../../component/Builder/Input/DesignTooltip";
import ColorSelector from "../../component/Builder/Input/ColorSelector";

const ColorInput = ({ title, id, tooltipMessage, value, onChange }) => {
  /**
   *
   * @type {(function(*): void)|*}
   */
  const onColorChange = useCallback(
    (e) => {
      onChange(e.hex);
    },
    [onChange]
  );

  const displayValue = value ? (
    <small>
      <strong>{value}</strong>
    </small>
  ) : (
    <small>Not set</small>
  );
  return (
    <div className={"form-group row branding-color-input"}>
      <div className={"col-3 col-form-label d-flex"}>
        {title}{" "}
        <DesignTooltip iconClassName={"icon-sm"} help={tooltipMessage} />
      </div>
      <div className={"col-9 d-flex justify-content-end"}>
        <div className={"d-flex flex-column align-items-center"}>
          <ColorSelector
            onColorChagnge={onColorChange}
            id={`brand-color-${id}`}
            color={value}
          />
          {displayValue}
        </div>
      </div>
    </div>
  );
};

ColorInput.defaultProps = {};

ColorInput.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  tooltipMessage: PropTypes.object,
};

export default ColorInput;
