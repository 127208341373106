/**
 * Created by piotr.pozniak@thebeaverhead.com on 22/09/2022.
 */

import React from "react";
import { PLANS } from "../../../../consts";
import { Link } from "react-router-dom";
import { useCurrentOrganizationStore } from "../../../../hooks/redux/currentOrganization";
import { hasSubscription } from "../../helpers/organization";

const FailedPayment2Info = (props) => {
  const { currentOrganization } = useCurrentOrganizationStore();

  if (!currentOrganization.model) {
    return null;
  }
  const currentOrganizationModel = currentOrganization.model;
  const organizationPlan = hasSubscription(currentOrganization);

  const isOnFreePlan =
    !organizationPlan || organizationPlan.code === PLANS.free;

  if (
    !Number.parseInt(
      currentOrganizationModel.sent_timestamp_renewal_failed_period_2
    ) ||
    isOnFreePlan
  ) {
    return null;
  }

  return (
    <React.Fragment>
      <div className="alert alert-danger">
        <p>
          It looks like your payment failed again. Your account will revert to
          the FREE PLAN within <b>3</b> days if you decide to do nothing.{" "}
          <Link to={"/billing"}>Update billing.</Link>
        </p>
      </div>{" "}
    </React.Fragment>
  );
};

export default FailedPayment2Info;
