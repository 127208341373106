/**
 * Created by piotr.pozniak@thebeaverhead.com on 04/10/2023
 */
import React, { createContext } from "react";
import { getShareableUrl, indexPageUrl } from "../../signups/tools";

const defaultValues = {
  widgetDisplayName: "",
  allowedIntegrations: [],
  getTheLowestPlanMeetingWidgetCriteria: () => {},
  widgetFeaturesCheck: () => [],
  widgetSettingsValidator: () => {},
  getShareableUrl: (slug) => {},
  indexPageUrl: "",
  integrationStrategySelector: (integrationType) => {},
};

const WidgetContext = createContext(defaultValues);

export default WidgetContext;
