/**
 * Created by piotr.pozniak@thebeaverhead.com on 11/04/2023.
 */

import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import Editor from "./Editor";
import { AvailableRulesOptionsContext, FiltersContext } from "./contexts";
import AsyncSelection from "../WidgetSettings/AsyncSelection";
import AxiosConfig from "../../../../AxiosConfig";

const exampleFilter = {
  conjunction: "and",
  groups: [
    {
      conjunction: "and",
      rules: [
        {
          field: {
            value: "tag",
            label: "Tag",
          },
          value: "",
          label: "Test",
          operator: {
            value: "is",
            label: "is",
          },
        },
      ],
    },
  ],
};

const asyncSelectionExample = {
  component: AsyncSelection,
  props: {
    baseUrl:
      AxiosConfig.getEndpointAddress() +
      `/api/filters/calendar_filters/5613aadf-4797-418f-9f85-bfc81c877baa.json?type=all_filters&kind=group&q=`,
    placeholder: "All Group",
    noOptionsMessage: "Start typing to search Group",
  },
};

const exampleAvailableRules = [
  {
    field: {
      value: "tag",
      label: "Tag",
    },
    isAllowed: true,
    operators: [
      {
        label: "is",
        value: "is",
        input: asyncSelectionExample,
      },
      {
        label: "is not",
        value: "not",
        input: {
          component: AsyncSelection,
          props: {
            url: "/api/v1/groups",
          },
        },
      },
    ],
  },
];

const Filters = (props) => {
  const [isEditMode, setIsEditMode] = React.useState(true);

  const filters = useMemo(() => {
    if (props.filters && Object.keys(props.filters).length) {
      return props.filters;
    }
    return { conjunction: "and", groups: [] };
  }, [props.filters]);

  const [newRule, setNewRule] = React.useState(null);

  /**
   *
   * @type {(function(*, *): void)|*}
   */
  const updateGroup = useCallback(
    (groupId, group) => {
      const newFilters = { ...filters };
      if (!group.rules.length) {
        newFilters.groups.splice(groupId, 1);
        props.onChange(newFilters);
        return;
      }

      newFilters.groups[groupId] = group;
      props.onChange(newFilters);
    },
    [filters, props.onChange]
  );

  /**
   *
   * @type {(function(*): void)|*}
   */
  const updateMainConjunction = useCallback(
    (conjunction) => {
      const newFilters = { ...filters };
      newFilters.conjunction = conjunction;
      props.onChange(newFilters);
    },
    [filters]
  );

  /**
   *
   * @type {(function(): void)|*}
   */
  const clearNewRule = useCallback(() => {
    setNewRule(null);
  }, [newRule]);

  const editor = isEditMode ? (
    <AvailableRulesOptionsContext.Provider value={props.availableFilters}>
      <FiltersContext.Provider
        value={{
          filters,
          updateGroup,
          updateMainConjunction,
          newRule,
          clearNewRule,
          setNewRule,
        }}
      >
        <Editor />
      </FiltersContext.Provider>
    </AvailableRulesOptionsContext.Provider>
  ) : null;

  return <div>{editor}</div>;
};

Filters.defaultProps = {};
Filters.propTypes = {
  availableFilters: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      }).isRequired,
      isAllowed: PropTypes.oneOfType([PropTypes.bool, PropTypes.func])
        .isRequired,
      operators: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
          input: PropTypes.shape({
            component: PropTypes.func.isRequired,
            props: PropTypes.object,
          }),
        })
      ).isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Filters;
