/**
 * Created by piotr.pozniak@thebeaverhead.com on 15/04/2022.
 */
// import PrivateEventsSyncWarning from "./PCOFilteringStrategy/PrivateEventsSyncWarning";
import FilterLabelCTA from "./PCOFilteringStrategy/FilterLabelCTA";
import { PLANS } from "../../../consts";
import SignupsIntegrationStrategy from "./GroupsIntegrationStrategy";
import { BooleanOptions } from "./filterOptions";

class PCOStrategy extends SignupsIntegrationStrategy {
  availableFilters = [
    "featured",
    "free",
    "open",
    "closed",
    "signup_category",
    "signup_campus",
  ];

  filterLabelCTA = FilterLabelCTA();

  /**
   *
   * @param data
   * @returns {{}}
   */
  filterRawDataToStateObject = (data) => {
    const filterObjects = this.availableFilters.reduce(
      (i, j) => ({ ...i, [j]: [] }),
      {}
    );
    data.map((filter) => {
      filter.value = filter.platform_metadata_id;
      filter.uuid = filter.platform_metadata_id;
      filter.label = this.filterRenderName(filter);

      if (!filterObjects[filter.kind]) {
        return;
      }
      filterObjects[filter.kind].push(filter);
    });

    return filterObjects;
  };

  /**
   *
   * @param data
   * @returns {*}
   */
  filterRawToDisplayObject = (data) => {
    return data.map((filter) => {
      filter.value = filter.platform_metadata_id;
      filter.uuid = filter.platform_metadata_id;
      filter.label = this.filterRenderName(filter);
      return filter;
    });
  };

  /**
   *
   * @param data
   * @param showInactive
   * @returns {*}
   */
  filterRenderName = (filter) => {
    const name = filter.data.attributes.name;

    return name;
  };

  _filters = [
    {
      field: {
        value: "signup_category",
        label: "Category",
      },
      allowedPlans: [PLANS.free, PLANS.starter, PLANS.medium, PLANS.highest],
    },
    {
      field: {
        value: "signup_campus",
        label: "Campus",
      },
      allowedPlans: [PLANS.free, PLANS.starter, PLANS.medium, PLANS.highest],
    },
    {
      field: {
        value: "free",
        label: "Show free forms",
      },
      allowedPlans: [PLANS.free, PLANS.starter, PLANS.medium, PLANS.highest],
      options: BooleanOptions,
      allowedOperators: ["is"],
      allowedValues: ["true", "false"],
      fetch: false,
    },
    {
      field: {
        value: "featured",
        label: "Show featured only",
      },
      allowedPlans: [PLANS.free, PLANS.starter, PLANS.medium, PLANS.highest],
      options: BooleanOptions,
      allowedOperators: ["is"],
      allowedValues: ["true", "false"],
      fetch: false,
    },
    {
      field: {
        value: "open",
        label: "Show open only",
      },
      allowedPlans: [PLANS.free, PLANS.starter, PLANS.medium, PLANS.highest],
      options: BooleanOptions,
      allowedOperators: ["is"],
      allowedValues: ["true", "false"],
      fetch: false,
    },
    {
      field: {
        value: "closed",
        label: "Show closed only",
      },
      allowedPlans: [PLANS.free, PLANS.starter, PLANS.medium, PLANS.highest],
      options: BooleanOptions,
      allowedOperators: ["is"],
      allowedValues: ["true", "false"],
      fetch: false,
    },
  ];
}

export default PCOStrategy;
