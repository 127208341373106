/**
 * Created by piotr.pozniak@thebeaverhead.com on 05/07/2022
 */

import React from "react";
import PropTypes from "prop-types";
import Row from "./Row";
import ShareLinkButton from "./ShareLinkButton";

const Share = (props) => {
  const { widgetSlug, group } = props;

  const baseUrl = window.location.origin;

  const groupUrl = baseUrl + "/gi/" + widgetSlug + "/" + group.slug;

  return (
    <Row>
      <div className={"dce-groups-modal--share-container"}>
        <ShareLinkButton url={groupUrl} title={group.name} text={group.description} />
      </div>
    </Row>
  );
};

Share.defaultProps = {};

Share.propTypes = {
  widgetSlug: PropTypes.string.isRequired,
  group: PropTypes.object,
};

export default Share;
