/**
 * Created by piotr.pozniak@thebeaverhead.com on 21/03/2024.
 */

import React, { useCallback, useEffect, useState } from "react";

import { useAppStore } from "./../../../hooks/redux/app";
import SettingsPageTemplate from "../templates/SettingsPageTemplate";
import Form from "./OrganizationBrandingPage/Form";
import { useCurrentOrganizationStore } from "../../../hooks/redux/currentOrganization";
import useFormHook from "../hooks/useFormHook";

const OrganizationSettingsPage = (props) => {
  const { clearErrors } = useAppStore();
  const { currentOrganization, editOrganization, uploadBrandingFile } =
    useCurrentOrganizationStore();
  const orgModel = currentOrganization.model || { settings: {} };

  const { form, onInputFileChange, onValueChange } = useFormHook(
    currentOrganization.model?.settings || {}
  );
  useEffect(() => {
    if (currentOrganization.editSuccess) {
      setTimeout(() => {
        clearErrors();
      }, 4500);
    }
  }, [currentOrganization.editSuccess]);

  useEffect(() => {
    return clearErrors;
  }, []);
  /**
   *
   */
  const onSubmit = useCallback(async () => {
    const _formPayload = {
      ...form,
    };

    delete _formPayload.logo;
    delete _formPayload.favicon;

    const payload = {
      settings: {
        ...orgModel.settings,
        ..._formPayload,
      },
    };
    await editOrganization(orgModel.uuid, payload, false);
    if (form?.logo !== orgModel.settings?.logo) {
      await uploadBrandingFile("logo", form.logo);
    }
    if (form?.favicon !== orgModel.settings?.favicon) {
      await uploadBrandingFile("favicon", form.favicon);
    }
  }, [form, orgModel]);

  const loading = currentOrganization.edit;

  return (
    <SettingsPageTemplate
      title="Branding"
      sidebarId="branding"
      icon={"fingerprint"}
    >
      <Form
        onSubmit={onSubmit}
        values={form}
        disabled={loading}
        onInputFileChange={onInputFileChange}
        onValueChange={onValueChange}
        apiErrors={currentOrganization.editError}
        apiSuccess={currentOrganization.editSuccess}
      />
    </SettingsPageTemplate>
  );
};

export default OrganizationSettingsPage;
