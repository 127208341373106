/**
 * Created by piotr.pozniak@thebeaverhead.com on 23/11/2023
 */

import React, { useCallback, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import ApiError from "../../component/ApiError";
import { getPlanSubscriptionName } from "../../../../helpers/subscription";
import { useOrganizationStore } from "../../../../hooks/redux/organization";
import LoadingIndicator from "../../../groups/components/LoadingIndicator";

const SubscriptionHistory = (props) => {
  const { organization } = useOrganizationStore();
  const organizationModel = organization.model;

  const currentPlan = useMemo(() => {
    return organizationModel.organizations_plans &&
      organizationModel.organizations_plans.length
      ? organizationModel.organizations_plans[0]
      : null;
  }, [organizationModel.organizations_plans]);

  const [expiry, setExpiry] = React.useState(0);

  useEffect(() => {
    if (currentPlan) {
      const expiryDate = new Date(currentPlan.expiry * 1000);
      // set expiry to date in format YYYY-MM-DD
      setExpiry(expiryDate.toLocaleDateString("en-CA"));
    }
  }, [currentPlan]);

  /**
   *
   * @type {(function(*): void)|*}
   */
  const onChangeExpiryDate = useCallback(
    (e) => {
      setExpiry(e.target.value);
    },
    [expiry]
  );

  /**
   *
   * @type {function(*): boolean}
   */
  const onEditExpiryDate = useCallback(
    (e) => {
      e.preventDefault();
      const expiryDate = Number.parseInt(new Date(expiry).getTime() / 1000);
      props.onEditExpiryDate(expiryDate);
      return false;
    },
    [expiry, onChangeExpiryDate]
  );

  const stripeLink = organizationModel.stripe_customer_id ? (
    <a
      target={"_blank"}
      href={
        "https://dashboard.stripe.com/customers/" +
        organizationModel.stripe_customer_id
      }
    >
      Stripe Customer Panel
    </a>
  ) : (
    "-"
  );

  const currentPlanInfoOptions = [
    { label: "Plan Name", value: getPlanSubscriptionName(currentPlan) },
    { label: "Price", value: currentPlan ? currentPlan.price : "-" },
    {
      label: "Is annual",
      value: currentPlan ? (currentPlan.is_annual * 1 ? "Yes" : "No") : "-",
    },
    {
      label: "Subscription cancelled",
      value: currentPlan ? (currentPlan.is_terminated * 1 ? "Yes" : "No") : "-",
    },
    {
      label: "Websites limit",
      value: currentPlan ? currentPlan.subscription_plan.domains_limit : "-",
    },
    {
      label: "Applied Coupon",
      value: currentPlan
        ? currentPlan.applied_coupon_code * 1
          ? "Yes"
          : "No"
        : "-",
    },
    {
      label: "Stripe Panel",
      value: stripeLink,
    },
  ];

  const currentPlanInfo = currentPlanInfoOptions.map((i, idx) => {
    return (
      <div className="form-group row" key={"cpio-" + idx}>
        <label className=" col-md-3">{i.label}</label>
        <div className="col-md-9">{i.value}</div>
      </div>
    );
  });

  const loadingIndicator =
    organization.fetch || organization.edit ? <LoadingIndicator /> : null;

  return (
    <React.Fragment>
      <div className="card-box">
        <h3 className="page-title">Subscription details</h3>

        <div className="form-group row">
          <label className=" col-md-3">Organization created</label>
          <div className="col-md-9">
            {new Date(organizationModel.created * 1000).toLocaleDateString(
              window.navigator.language
            )}{" "}
            {new Date(organizationModel.created * 1000).toLocaleTimeString(
              window.navigator.language
            )}
          </div>
        </div>

        <div className="form-group row">
          <label className=" col-md-3">On trial period</label>
          <div className="col-md-9">
            {organizationModel.has_trial_finished * 1 ? "No" : "Yes"}
          </div>
        </div>

        <div className="form-group row">
          <label className=" col-md-3">Installation fee paid</label>
          <div className="col-md-9">
            {organizationModel.installation_fee_paid * 1 ? "Yes" : "No"}
          </div>
        </div>
      </div>
      <div className="card-box">
        <h3 className="page-title">Current plan</h3>

        {currentPlanInfo}

        <div className="form-group row">
          <label className=" col-md-3">Expiry date</label>
          <div className="col-md-6">
            <div className="form-group">
              <input
                className="form-control "
                type="date"
                id={"expiryDate"}
                value={expiry}
                onChange={onChangeExpiryDate}
                disabled={organization.edit}
                maxLength={64}
                minLength={2}
              />
            </div>
            <small>
              New expiry date should be greater than expiry of active plan and
              less than 2 years
            </small>
          </div>
        </div>

        <div className="text-center m-t-20">
          <button
            className="btn btn-primary btn-lg"
            type="submit"
            onClick={onEditExpiryDate}
            disabled={organization.edit}
          >
            Update
          </button>
          {loadingIndicator}
          <ApiError
            error={organization.editError}
            defaultErrorMessage={"Could not update organization subscription"}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

SubscriptionHistory.defaultProps = {};

SubscriptionHistory.propTypes = { onEditExpiryDate: PropTypes.func.isRequired };

export default SubscriptionHistory;
