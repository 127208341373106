/**
 * Created by piotr.pozniak@thebeaverhead.com on 08/06/2022.
 */
import { useState, useEffect, useRef } from "react";

const useAggressiveResponsiveWidget = (
  widgetId,
  breakpointWidth,
  className,
  hasAggressiveResponsiveness
) => {
  const observer = useRef(null);

  useEffect(() => {
    // console.log(hasAggressiveResponsiveness, observer.current);

    if (hasAggressiveResponsiveness) {
      if (!observer.current && typeof ResizeObserver === "function") {
        // console.log('new observer')
        observer.current = new ResizeObserver((e) => {
          const widgetContainer = document.querySelector(`.${widgetId}`);

          // console.log('observer callback', widgetContainer.clientWidth)
          if (widgetContainer.clientWidth < breakpointWidth) {
            widgetContainer.className += ` ${className}`;
          } else {
            widgetContainer.className = widgetContainer.className.replaceAll(
              ` ${className}`,
              ""
            );
          }
        });
      } else {
        observer.current.disconnect();
      }

      observer.current.observe(document.querySelector(`.${widgetId}`));
    } else {
      const widgetContainer = document.querySelector(`.${widgetId}`);
      // console.log('removing aggressiveness', className, widgetContainer, widgetContainer.className)
      widgetContainer.className = widgetContainer.className.replaceAll(
        className,
        ""
      );
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [hasAggressiveResponsiveness]);
};

export default useAggressiveResponsiveWidget;
