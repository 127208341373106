/**
 * Created by piotr.pozniak@thebeaverhead.com on 13/10/2018.
 */

import React from "react";
import PropTypes from "prop-types";
import { formatDate } from "../../../../helpers/date";

const CalendarHeaderLabel = (props) => {
  if (!props.show) {
    return null;
  }

  const { currentDate, events, eventsNumberOption, showUpcomingNavigation } =
    props;

  const showNavigation = ["upcoming", "all"].indexOf(eventsNumberOption) >= 0;

  let headerTitle = formatDate(currentDate, {
    month: "long",
    year: "numeric",
  });

  if (!showNavigation || showUpcomingNavigation) {
    const firstEventDate = events.length
      ? new Date(events[0].start_time * 1000)
      : currentDate;

    const lastEventDate = events.length
      ? new Date(events[events.length - 1].start_time * 1000)
      : currentDate;

    const fromMonth = formatDate(firstEventDate, { month: "long" });
    const fromYear = formatDate(firstEventDate, { year: "numeric" });
    const toMonth = formatDate(lastEventDate, { month: "long" });
    const toYear = formatDate(lastEventDate, { year: "numeric" });

    if (fromYear !== toYear) {
      headerTitle = `${fromMonth} ${fromYear} - ${toMonth} ${toYear}`;
    } else if (fromMonth !== toMonth) {
      headerTitle = `${fromMonth} - ${toMonth} ${fromYear}`;
    } else {
      headerTitle = `${fromMonth} ${fromYear}`;
    }
  }

  if (props.showDayName) {
    headerTitle += " - " + formatDate(currentDate, { weekday: "long" });
  }
  //const header = showMonthHeader ?  : null;
  return <div className="dce__h1">{headerTitle}</div>;
};

CalendarHeaderLabel.defaultProps = {
  showDayName: false,
  showUpcomingNavigation: false,
};

CalendarHeaderLabel.propTypes = {
  currentDate: PropTypes.object,
  show: PropTypes.bool,
  events: PropTypes.array,
  showDayName: PropTypes.bool,
  showUpcomingNavigation: PropTypes.bool,
  eventsNumberOption: PropTypes.string,
};

export default CalendarHeaderLabel;
