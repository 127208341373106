/**
 * Created by piotr.pozniak@thebeaverhead.com on 06/04/2022
 */


import React from 'react';
import PropTypes from 'prop-types';

const LoadingIndicator = props => {

  return (
    <div className={"dce--tc-loader-container"}>
      <div className={"dce--tc__loader-row"}>
        <div className={"dce--tc__loader-col"}>
          <div className={"dce-tc__loader-col-img dce-tc__loader-gradient"}></div>
        </div>
        <div className={"dce--tc__loader-col"}>
          <div className={"dce-tc__loader-col-name dce-tc__loader-gradient"}></div>
          <div className={"dce-tc__loader-col-description dce-tc__loader-gradient"}></div>
        </div>
      </div>
    </div>
  )
}

LoadingIndicator.defaultProps = {};

LoadingIndicator.propTypes = {}

export default LoadingIndicator;
