/**
 * Created by enes.kahrovic@thebeaverhead.com on 25/02/2019.
 */

import React, { useEffect, useState } from "react";

import PageTemplate from "./../templates/PageTemplate";
import TableRow from "./SubscriptionPlans/TableRow";
import EditModal from "./SubscriptionPlans/EditModal";
import LoadingIndicator from "../../calendar/components/LoadingIndicator";
import ApiError from "../component/ApiError";
import { useSubscriptionPlansStore } from "../../../hooks/redux/subscriptionPlans";
import { useAppStore } from "../../../hooks/redux/app";
import { store } from "../store";

const SubscriptionPlansPage = (props) => {
  const [state, setState] = useState({
    subscriptionPlan: null,
  });

  const { subscriptionPlans, deletePlan, updatePlan } =
    useSubscriptionPlansStore();

  const { clearErrors } = useAppStore();

  useEffect(() => {
    return () => window.$("#add_plan").modal("hide");
  }, []);

  useEffect(() => {
    if (state.subscriptionPlan) {
      window.$("#add_plan").modal("show");
    } else {
      clearErrors();
    }
  }, [state.subscriptionPlan]);

  useEffect(() => {
    if (subscriptionPlans.updateSuccess) {
      clearErrors();
      window.$("#add_plan").modal("hide");
    }
  }, [subscriptionPlans.updateSuccess]);

  /**
   *
   * @param user
   */
  const onShowEditPlan = (plan) => {
    setState({
      ...state,
      subscriptionPlan: plan,
    });
  };

  /**
   *
   * @param user
   */
  const onPlanDelete = (plan) => {
    if (
      window.confirm(
        "Are you sure you want to delete Subscription Plan '" + plan.name + "'?"
      )
    ) {
      deletePlan(plan.uuid);
    }
  };

  /**
   *
   */
  const onEdit = async (plan) => {
    await updatePlan(plan.uuid ? plan.uuid : null, plan);

    const subscriptionPlanStore = store.getState().subscription;
    if (subscriptionPlanStore.updateSuccess) {
      setState({ ...state, subscriptionPlan: null });
    }
  };

  const onCloseModal = () => {
    setState({
      ...state,
      subscriptionPlan: null,
    });
  };

  const onAddPlanClick = (e) => {
    e.preventDefault();
    if (!loading) {
      onShowEditPlan({});
    }
  };
  const loading = subscriptionPlans.fetch || subscriptionPlans.delete;

  const plansList = subscriptionPlans.collection.map((item, idx) => {
    return (
      <TableRow
        disabled={loading}
        key={idx}
        subscriptionPlan={item}
        onEdit={onShowEditPlan}
        onDelete={onPlanDelete}
      />
    );
  });

  const loadingIndicator = loading ? <LoadingIndicator /> : null;

  const monthlySubscribersTotal = subscriptionPlans.collection.length
    ? subscriptionPlans.collection
        .map((i) => i.total_subscribers_all - i.total_subscribers_yearly)
        .reduce((a, i) => Number.parseInt(a) + Number.parseInt(i))
    : 0;

  const yearlySubscribersTotal = subscriptionPlans.collection.length
    ? subscriptionPlans.collection
        .map((i) => i.total_subscribers_yearly)
        .reduce((a, i) => {
          return Number.parseInt(a) + Number.parseInt(i);
        })
    : 0;

  const subscriptionPlansData = subscriptionPlans.collection.length ? (
    <table className="table table-striped custom-table">
      <thead>
        <tr>
          <th>Name</th>
          <th>Is branded</th>
          <th>Available</th>
          <th>Price</th>
          <th>Price Annual</th>
          <th>
            Active subscriptions <br />
            (monthly/yearly)
          </th>
          <th className="text-right">Action</th>
        </tr>
      </thead>
      <tbody>{plansList}</tbody>
      <tfoot>
        <tr>
          <td colSpan={5} align={"right"}>
            Total:{" "}
          </td>
          <td>
            {monthlySubscribersTotal} / {yearlySubscribersTotal}
          </td>
          <td></td>
        </tr>
      </tfoot>
    </table>
  ) : null;

  const editModal = state.subscriptionPlan ? (
    <EditModal
      subscriptionPlan={state.subscriptionPlan}
      subscriptionPlans={subscriptionPlans}
      onEdit={onEdit}
      onClose={onCloseModal}
    />
  ) : null;

  return (
    <PageTemplate
      sidebarId="plans"
      title={"Subscription Plans"}
      icon={"card_membership"}
      headerControls={[
        <a
          href="#"
          className="btn btn-primary btn-rounded pull-right d-flex"
          onClick={onAddPlanClick}
        >
          <i className="material-icons">add</i> Add Subscription Plan
        </a>,
      ]}
    >
      <div className="row">
        <div className="col-md-12">
          <div className="table-responsive">
            {subscriptionPlansData}

            {loadingIndicator}

            <ApiError
              error={subscriptionPlans.deleteError}
              defaultErrorMessage={
                "Could not delete Api User. Please try again"
              }
            />

            <ApiError
              error={subscriptionPlans.syncError}
              defaultErrorMessage={"Could not sync CCB data. Please try again"}
            />
          </div>
        </div>
      </div>

      {editModal}
    </PageTemplate>
  );
};

export default SubscriptionPlansPage;
