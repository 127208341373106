import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { formatDate } from "../../../../../helpers/date";

const WeekListItem = ({ weekStart, currentWeek, onClickWeekList }) => {
  const weekEnd = new Date(weekStart);
  weekEnd.setDate(weekStart.getDate() + 6);

  return (
    <li
      key={"ww-wl-" + weekStart.getTime()}
      className={classnames({
        selected:
          weekStart <= new Date(currentWeek) &&
          new Date(currentWeek) <= weekEnd,
      })}
      onClick={onClickWeekList(weekStart)}
    >
      {formatDate(weekStart, { month: "short", day: "numeric" }) +
        " - " +
        formatDate(weekEnd, {
          month: "short",
          day: "numeric",
          year: "numeric",
        })}
    </li>
  );
};

WeekListItem.propTypes = {
  weekStart: PropTypes.object.isRequired,
  currentWeek: PropTypes.object.isRequired,
  onClickWeekList: PropTypes.func.isRequired,
};

export default WeekListItem;
