/**
 * Created by piotr.pozniak@thebeaverhead.com on 28/07/2022
 */

import React, { useCallback, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Store as NotificationComponent } from "react-notifications-component";

const NotificationPopup = (props) => {
  useEffect(() => {
    return () => {
      NotificationComponent.removeNotification(props.id);
    };
  }, []);

  /**
   *
   * @type {(function(): void)|*}
   */
  const onClose = useCallback(() => {
    NotificationComponent.removeNotification(props.id);
  }, []);

  return (
    <div
      className={classNames(
        "notification-danger",
        "n-child",
        "alert",
        "alert-danger"
      )}
    >
      <button
        type="button"
        className="close"
        data-dismiss="modal"
        onClick={onClose}
      >
        &times;
      </button>
      {props.children}
    </div>
  );
};

NotificationPopup.defaultProps = {};

NotificationPopup.propTypes = {};

export default NotificationPopup;
