/**
 * Created by piotr.pozniak@thebeaverhead.com on 15/02/2023.
 */

import React, { useState, useEffect, useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";
import PageTemplate from "./../templates/PageTemplate";

import NewAlertPreview from "./NewAlertPage/NewAlertPreview";
import MandatoryInformation from "./AlertPage/SettingsTabs/MandatoryInformation";
import NewWidgetWizard from "../component/NewWidgetWizard";
import { store } from "../store";
import SidebarTab from "../component/WidgetSettings/SidebarTab";
import SuccessAlert from "../component/SuccessAlert";
import ApiError from "../component/ApiError";
import { alertSettingsValidator, updateJquery } from "../tools";
import { AT, IT, PLANS } from "../../../consts";
import { useAlertStore } from "../../../hooks/redux/alert";
import { useAlertsStore } from "../../../hooks/redux/alerts";
import { useIntegrationsStore } from "../../../hooks/redux/integrations";
import { WT } from "../consts/widget";
import { useDomainsStore } from "../../../hooks/redux/domains";

const IntegrationDefaultFilters = {
  [IT.google]: null,
  [IT.ccb]: null,
  [IT.breeze]: null,
  [IT.pco]: {
    conjunction: "and",
    groups: [
      {
        conjunction: "and",
        rules: [
          {
            field: {
              value: "approval_status",
              label: "Approval Status",
            },
            value: "A",
            label: "Approved",
            operator: {
              value: "is",
              label: "is",
            },
          },
        ],
      },
    ],
  },
};

const initialState = {
  alertModel: {
    name: "",
    available: 0,
    integration_uuid: 0,
    template: "",
    show_private_events: 0,
    show_events_with_images: 0,
  },
  errors: {},
};

const NewAlertPage = (props) => {
  const sidebarOverlayRef = useRef(null);
  const chatSidebarOverlayRef = useRef(null);
  const { alert } = useAlertStore();
  const { integrations } = useIntegrationsStore();
  const { alerts } = useAlertsStore();
  const { domains } = useDomainsStore();

  const loading = alert.update || alert.fetch || alert.delete;

  const { updateAlert, setAlert } = useAlertStore();

  const history = useHistory();

  const [state, setState] = useState(JSON.parse(JSON.stringify(initialState)));

  /**
   *
   * @param fieldName
   * @param value
   */
  const onUpdateField = (fieldName, value) => {
    const data = {
      ...state,
    };

    data.alertModel[fieldName] = value;

    data.errors = alertSettingsValidator(data.alertModel);

    setState(data);
  };

  useEffect(() => {
    if (alert.fetchSuccess) {
      if (!alert.model.integration) {
        history.push("/dashboard");
      }
    }

    if (alerts.fetchSuccess) {
      setTimeout(updateJquery, 200);
    }
  }, [alert.fetchSuccess, alerts.fetchSuccess]);

  useEffect(() => {
    window.$(".chat-main-row").animate({ scrollTop: 0 }, 200);

    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [state.alertModel.template]);

  useEffect(() => {
    return () => setAlert(null);
  }, []);

  /**
   *
   */
  const onSubmit = async () => {
    const defaultUISettings = availableAlerts.find(
      (i) => i.slug == state.alertModel.template
    ).defaultDesignSettings;

    const data = { ...state.alertModel };
    data.widget_settings = { design: defaultUISettings };

    if (domains.collection.length && domains.collection === 1) {
      data.widget_settings.dcAPageToDisplay = domains.collection[0].domain;
    }
    data.widget_settings.dcAPageToShowOnOption = "*";

    // for PCO, turn private events on by default
    const selectedIntegration = integrations.collection.find(
      (i) => i.uuid === state.alertModel.integration_uuid
    );

    if (selectedIntegration && selectedIntegration.type === IT.pco) {
      data.show_private_events = 1;
    }

    if (IntegrationDefaultFilters[selectedIntegration.type]) {
      data.filter_settings = {
        filters: IntegrationDefaultFilters[selectedIntegration.type],
      };
    }

    await updateAlert(null, data);

    const storeState = store.getState();

    if (storeState.alert.updateSuccess) {
      await setAlert(null);

      history.push("/alert/" + storeState.alert.model.uuid);
    }
  };

  /**
   *
   * @param widgetType
   * @returns {(function(): void)|*}
   */
  const onSelectedNewWidgetType = (widgetType) => () => {
    setAlert({});
    const data = {
      ...state,
    };
    data.alertModel.template = widgetType;
    setState(data);
  };

  const availableAlerts = [
    {
      title: "Banner Alert",
      description:
        "A Banner Alert that appears at the top or bottom of your website. It can be used to promote an event.",
      slug: AT.banner,
      thumbnailUrl: "/img/alert_type/banner.png",
      features: ["Less invasive", "Scrolls with the page"],
      isSelected: state.alertModel.template == AT.banner,
      onSelect: onSelectedNewWidgetType(AT.banner),
      forPlans: [PLANS.starter, PLANS.medium, PLANS.highest],
      defaultDesignSettings: {},
      previewUrl: null, //"https://display.church/products/alerts/#banner",
    },
    /*{
      title: "Modal",
      description:
        "A modal that appears in the middle of your website. Make your even difficult to miss!",
      slug: AT.modal,
      thumbnailUrl: "/img/alert_type/cards_icon.png",
      features: [
        "Stands out from the rest of the page",
        "Captures attention with a large area",
      ],
      isSelected: state.alertModel.template == AT.modal,
      onSelect: onSelectedNewWidgetType(AT.modal),
      forPlans: [PLANS.starter, PLANS.medium, PLANS.highest],
      defaultDesignSettings: {},
      previewUrl: "https://display.church/products/alerts/#modal",
      isNew: true,
    },
    {
      title: "Alert",
      description:
        "A gentle reminder that appears int he corner of your website. A nudging reminder to check out your event.",
      slug: AT.alert,
      thumbnailUrl: "/img/alert_type/outlined_icon.png",
      features: [
        "Does not take up much space",
        "Gives a nudge to check out your event",
      ],
      isSelected: state.alertModel.template == AT.alert,
      onSelect: onSelectedNewWidgetType(AT.alert),
      forPlans: [PLANS.starter, PLANS.medium, PLANS.highest],
      defaultDesignSettings: {},
      previewUrl: "https://display.church/products/alerts/#alert",
      isNew: true,
    },
    {
      title: "Full pager",
      description:
        "A full page event presentation of your event. It can include image or a movie to make it more interesting.",
      slug: AT.takeover,
      thumbnailUrl: "/img/alert_type/mosaic_icon.png",
      features: [
        "Full page presentation with rich media content",
        "Rich media event presentation",
      ],
      isSelected: state.alertModel.template == AT.takeover,
      onSelect: onSelectedNewWidgetType(AT.takeover),
      forPlans: [PLANS.starter, PLANS.medium, PLANS.highest],
      defaultDesignSettings: {},
      previewUrl: "https://display.church/products/alerts/#takeover",
      isNew: true,
    },*/
  ];

  const settingsTabsOptions = [];

  settingsTabsOptions.push({
    label: "Setup",
    id: "setup_tab",
    additionalClassName: "active show",
  });

  const cancelSelection = useCallback(() => {
    setAlert({});
    setState(JSON.parse(JSON.stringify(initialState)));
    if (sidebarOverlayRef?.current) {
      sidebarOverlayRef.current?.classList?.remove("opened");
    }
    if (chatSidebarOverlayRef?.current) {
      chatSidebarOverlayRef.current?.classList?.remove("opened");
    }
  }, [state.alertModel]);

  const settingsTabs = settingsTabsOptions.map((item, idx) => {
    return (
      <li key={"settings-tab-" + idx} className="nav-item">
        <a
          className={"nav-link " + item.additionalClassName}
          href={"#" + item.id}
          data-toggle="tab"
        >
          {item.label}
        </a>
      </li>
    );
  });

  const footer = !loading ? (
    <div>
      <SuccessAlert>
        {alert.updateSuccess ? "Your changes have been saved." : null}
      </SuccessAlert>
      <ApiError
        error={alert.updateError}
        defaultErrorMessage={"Could not save your settings. Please try again"}
      />
      <div className="d-flex justify-content-end gap-xs">
        <button
          type="button"
          data-reff="#chat_sidebar"
          onClick={cancelSelection}
          className="btn btn-secondary d-xs-block d-lg-none sidenav_btn  task-chat nav-link "
        >
          Cancel
        </button>
        <button
          disabled={props.disabled}
          type="submit"
          className="btn btn-primary"
          onClick={onSubmit}
        >
          Create
        </button>
      </div>
    </div>
  ) : null;

  const hasAlertTypeSelected = state.alertModel.template.length ? true : false;

  const setupTab = (
    <SidebarTab
      id="setup_tab"
      show={true}
      footer={hasAlertTypeSelected ? footer : null}
    >
      <NewWidgetWizard
        hasSelectedWidget={state.alertModel.template}
        hasEnteredWidgetName={state.alertModel.name.length >= 3}
        hasSelectedIntegration={state.alertModel.integration_uuid != "0"}
        widgetName={"Event Alert Widget"}
        widgetType={WT.alerts}
      />

      {hasAlertTypeSelected ? (
        <MandatoryInformation
          alertModel={state.alertModel || {}}
          onUpdateField={onUpdateField}
          disabled={loading}
          formErrors={state.errors}
          integrations={integrations.collection}
          showSummary={false}
          expanded={true}
        />
      ) : null}
    </SidebarTab>
  );

  const alertOptions = availableAlerts.map((i, idx) => {
    return <NewAlertPreview key={i.slug} {...i} subscriptionPlanNames />;
  });

  return (
    <PageTemplate
      sidebarId="alerts"
      icon={"campaign"}
      title={"Event Alerts"}
      className={"page-wrapper-with-white-bg"}
    >
      <div className="chat-main-row">
        <div className="chat-main-wrapper">
          <div className="col-xs-12 message-view task-view">
            <div className="chat-window">
              <div className="chat-contents alert-background">
                <div className="chat-content-wrap">
                  <div className="chat-wrap-inner">
                    <div className="chat-box">
                      <div className="chats">
                        <div className="alert-preview-container">
                          <div className="container container-fluid">
                            <div className="row">
                              <div className="col-sm-12">
                                <h4 className="page-title">&nbsp;</h4>
                              </div>
                            </div>
                            <div className="">
                              <h2>New Event Alert Widget Setup</h2>
                              <p>
                                Start by selecting a widget type, adding a name,
                                and selecting an Integration.
                              </p>
                              <div className="row">{alertOptions}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="col-lg-3 message-view chat-profile-view chat-sidebar"
            id="chat_sidebar"
            ref={chatSidebarOverlayRef}
          >
            <div className="chat-window video-window">
              <div className="fixed-header">
                <ul className="nav nav-tabs nav-tabs-bottom">{settingsTabs}</ul>
              </div>
              <div className="tab-content chat-contents">{setupTab}</div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="sidebar-overlay"
        data-reff="#chat_sidebar"
        ref={sidebarOverlayRef}
      />
    </PageTemplate>
  );
};

export default NewAlertPage;
