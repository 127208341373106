/**
 * Created by piotr.pozniak@thebeaverhead.com on 27/11/2023
 */

import React from "react";
import { CancelReasons, StepPropTypes } from "./consts";

const CancelStep1 = (props) => {
  return (
    <React.Fragment>
      <div className={"card-header bg-white"}>
        <div className={"card-title m-b-0"}>
          You have not canceled your account yet... select an option below.
        </div>
      </div>
      <div className={"card-body"}>
        <form>
          <div className="form-group">
            <label>What's the single biggest reason for you canceling?</label>

            {CancelReasons.map((i, idx) => {
              return (
                <div className="radio" key={"csmo-" + idx}>
                  <label>
                    <input
                      name="leave-reason"
                      value={i.value}
                      type="radio"
                      onChange={props.onChange("reason", true)}
                    />{" "}
                    {i.label}
                  </label>
                </div>
              );
            })}
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

CancelStep1.defaultProps = {};

CancelStep1.propTypes = StepPropTypes;

export default CancelStep1;
