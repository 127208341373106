/**
 * Created by piotr.pozniak@thebeaverhead.com on 05/04/2022.
 */

import React, { useState, useEffect, useRef, useCallback } from "react";
import { useHistory } from "react-router-dom";
import PageTemplate from "./../templates/PageTemplate";

import NewGroupPreview from "./NewGroupPage/NewGroupPreview";
import MandatoryInformation from "./GroupPage/SettingsTabs/MandatoryInformation";
import NewWidgetWizard from "../component/NewWidgetWizard";
import { store } from "../store";
import SidebarTab from "../component/WidgetSettings/SidebarTab";
import SuccessAlert from "../component/SuccessAlert";
import ApiError from "../component/ApiError";
import { groupSettingsValidator, updateJquery } from "../tools";
import { GT, IT, PLANS } from "../../../consts";
import { useGroupStore } from "../../../hooks/redux/group";
import { useGroupsStore } from "../../../hooks/redux/groups";
import { useIntegrationsStore } from "../../../hooks/redux/integrations";
import { WT } from "../consts/widget";
import appConfig from "../../../appConfig";

const IntegrationDefaultFilters = {
  [IT.google]: null,
  [IT.pco]: {
    conjunction: "and",
    groups: [
      {
        conjunction: "and",
        rules: [
          {
            field: {
              value: "group_status",
              label: "Group status",
            },
            value: "open",
            label: "Open",
            operator: {
              value: "is",
              label: "is",
            },
          },
        ],
      },
    ],
  },
  [IT.breeze]: null,
  [IT.ccb]: {
    conjunction: "and",
    groups: [
      {
        conjunction: "and",
        rules: [
          {
            field: {
              value: "public_search_listed",
              label: "Publicly listed",
            },
            value: "true",
            label: "Yes",
            operator: {
              value: "is",
              label: "is",
            },
          },
          {
            field: {
              value: "registration_status",
              label: "Registration status",
            },
            value: "open",
            label: "Open (Available)",
            operator: {
              value: "is",
              label: "is",
            },
          },
        ],
      },
    ],
  },
};

const initialState = {
  groupModel: {
    name: "",
    available: 0,
    integration_uuid: 0,
    template: "",
  },
  errors: {},
};

const NewGroupPage = (props) => {
  const sidebarOverlayRef = useRef(null);
  const chatSidebarOverlayRef = useRef(null);
  const { group } = useGroupStore();
  const { integrations } = useIntegrationsStore();
  const { groups } = useGroupsStore();

  const loading = group.update || group.fetch || group.delete;

  const { updateGroup, setGroup } = useGroupStore();

  const history = useHistory();

  const [state, setState] = useState(JSON.parse(JSON.stringify(initialState)));

  /**
   *
   * @param fieldName
   * @param value
   */
  const onUpdateField = (fieldName, value) => {
    const data = {
      ...state,
    };

    data.groupModel[fieldName] = value;

    data.errors = groupSettingsValidator(data.groupModel);

    setState(data);
  };

  useEffect(() => {
    if (group.fetchSuccess) {
      if (!group.model.integration) {
        history.push("/dashboard");
      }
    }

    if (groups.fetchSuccess) {
      setTimeout(updateJquery, 200);
    }
  }, [group.fetchSuccess, groups.fetchSuccess]);

  useEffect(() => {
    window.$(".chat-main-row").animate({ scrollTop: 0 }, 200);

    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [state.groupModel.template]);

  useEffect(() => {
    return () => setGroup(null);
  }, []);

  /**
   *
   */
  const onSubmit = async () => {
    const defaultUISettings = availableGroups.find(
      (i) => i.slug == state.groupModel.template
    ).defaultDesignSettings;

    const selectedIntegration = integrations.collection.find(
      (i) => i.uuid === state.groupModel.integration_uuid
    );

    const data = { ...state.groupModel };
    data.widget_settings = { design: defaultUISettings };

    // apply filter defaults
    if (IntegrationDefaultFilters[selectedIntegration.type]) {
      data.filter_settings = {
        filters: IntegrationDefaultFilters[selectedIntegration.type],
      };
    }
    await updateGroup(null, data);

    const storeState = store.getState();

    if (storeState.group.updateSuccess) {
      await setGroup(null);

      history.push("/group/" + storeState.group.model.uuid);
    }
  };

  /**
   *
   * @param widgetType
   * @returns {(function(): void)|*}
   */
  const onSelectedNewWidgetType = (widgetType) => () => {
    setGroup({});
    const data = {
      ...state,
    };
    data.groupModel.template = widgetType;
    setState(data);
  };

  const cancelSelection = useCallback(() => {
    setGroup({});
    setState(JSON.parse(JSON.stringify(initialState)));
    if (sidebarOverlayRef?.current) {
      sidebarOverlayRef.current?.classList?.remove("opened");
    }
    if (chatSidebarOverlayRef?.current) {
      chatSidebarOverlayRef.current?.classList?.remove("opened");
    }
  }, [state.groupModel]);

  const availableGroups = [
    {
      title: "Groups List",
      description:
        "Simple List offers an updated view of a familiar design. The main benefits are:",
      slug: GT.twoColumn,
      thumbnailUrl: "/img/group_type/simple_list_icon.png",
      features: [
        "Neatly stacked list format",
        "Balanced information via image + description",
      ],
      isSelected: state.groupModel.template == GT.twoColumn,
      onSelect: onSelectedNewWidgetType(GT.twoColumn),
      forPlans: [PLANS.starter, PLANS.medium, PLANS.highest],
      defaultDesignSettings: {},
      previewUrl: `${appConfig.homepage}/products/groups/#groups-list`,
    },
    {
      title: "Groups Grid",
      description:
        "Cards (Grid) is a modern template based on social media type cards. The main benefits are:",
      slug: GT.card,
      thumbnailUrl: "/img/group_type/cards_icon.png",
      features: [
        "Looks intuitive and inviting",
        "Emphasis on visual image for a more personal connection",
      ],
      isSelected: state.groupModel.template == GT.card,
      onSelect: onSelectedNewWidgetType(GT.card),
      forPlans: [PLANS.starter, PLANS.medium, PLANS.highest],
      defaultDesignSettings: {},
      previewUrl: `${appConfig.homepage}/products/groups/#groups-grid`,
    },
    {
      title: "Small Groups Outlined",
      description:
        "Outlined offers a visually-striking, interactive template. The main benefits are:",
      slug: GT.outlined,
      thumbnailUrl: "/img/group_type/outlined_icon.png",
      features: [
        "Hover effect creates interest",
        "Invites people to click and see more",
      ],
      isSelected: state.groupModel.template == GT.outlined,
      onSelect: onSelectedNewWidgetType(GT.outlined),
      forPlans: [PLANS.starter, PLANS.medium, PLANS.highest],
      defaultDesignSettings: {},
      previewUrl: `${appConfig.homepage}/products/groups/#groups-outlined`,
    },
    {
      title: "Mosaic",
      description:
        "Mosaic allows a dynamic patchwork view which emphasizes the connectivity within your organization.",
      slug: GT.mosaic,
      thumbnailUrl: "/img/group_type/mosaic_icon.png",
      features: [
        "Differing sizes encourage more attention",
        "Still interesting at different screen resolutions",
      ],
      isSelected: state.groupModel.template == GT.mosaic,
      onSelect: onSelectedNewWidgetType(GT.mosaic),
      forPlans: [PLANS.starter, PLANS.medium, PLANS.highest],
      defaultDesignSettings: {},
      previewUrl: `${appConfig.homepage}/products/groups/#groups-mosaic`,
    },
  ];

  const settingsTabsOptions = [];

  settingsTabsOptions.push({
    label: "Setup",
    id: "setup_tab",
    additionalClassName: "active show",
  });

  const settingsTabs = settingsTabsOptions.map((item, idx) => {
    return (
      <li key={"settings-tab-" + idx} className="nav-item">
        <a
          className={"nav-link " + item.additionalClassName}
          href={"#" + item.id}
          data-toggle="tab"
        >
          {item.label}
        </a>
      </li>
    );
  });

  const footer = !loading ? (
    <div>
      <SuccessAlert>
        {group.updateSuccess ? "Your changes have been saved." : null}
      </SuccessAlert>
      <ApiError
        error={group.updateError}
        defaultErrorMessage={"Could not save your settings. Please try again"}
      />
      <div className="d-flex justify-content-end gap-xs">
        <button
          type="button"
          data-reff="#chat_sidebar"
          onClick={cancelSelection}
          className="btn btn-secondary d-xs-block d-lg-none sidenav_btn  task-chat nav-link "
        >
          Cancel
        </button>
        <button
          disabled={props.disabled}
          type="submit"
          className="btn btn-primary"
          onClick={onSubmit}
        >
          Create
        </button>
      </div>
    </div>
  ) : null;

  const hasGroupTypeSelected = state.groupModel.template.length ? true : false;

  const setupTab = (
    <SidebarTab
      id="setup_tab"
      show={true}
      footer={hasGroupTypeSelected ? footer : null}
    >
      <NewWidgetWizard
        hasSelectedWidget={state.groupModel.template}
        hasEnteredWidgetName={state.groupModel.name.length >= 3}
        hasSelectedIntegration={state.groupModel.integration_uuid != "0"}
        widgetName={"Group Widget"}
        widgetType={WT.groups}
      />

      {hasGroupTypeSelected ? (
        <MandatoryInformation
          groupModel={state.groupModel || {}}
          onUpdateField={onUpdateField}
          disabled={loading}
          formErrors={state.errors}
          integrations={integrations.collection}
          expanded={true}
          showSummary={false}
        />
      ) : null}
    </SidebarTab>
  );

  const groupOptions = availableGroups.map((i, idx) => {
    return <NewGroupPreview key={i.slug} {...i} subscriptionPlanNames />;
  });

  return (
    <PageTemplate
      sidebarId="groups"
      icon={"group"}
      title={"Groups"}
      className={"page-wrapper-with-white-bg"}
    >
      <div className="chat-main-row">
        <div className="chat-main-wrapper">
          <div className="col-xs-12 message-view task-view">
            <div className="chat-window">
              <div className="chat-contents group-background">
                <div className="chat-content-wrap">
                  <div className="chat-wrap-inner">
                    <div className="chat-box">
                      <div className="chats">
                        <div className="group-preview-container">
                          <div className="container container-fluid">
                            <div className="row">
                              <div className="col-sm-12">
                                <h4 className="page-title">&nbsp;</h4>
                              </div>
                            </div>
                            <div className="">
                              <h2>New Group Widget Setup</h2>
                              <p>
                                Start by selecting a widget type, adding a name,
                                and selecting an Integration.
                              </p>
                              <div className="row">{groupOptions}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="col-lg-3 message-view chat-profile-view chat-sidebar"
            id="chat_sidebar"
            ref={chatSidebarOverlayRef}
          >
            <div className="chat-window video-window">
              <div className="fixed-header">
                <ul className="nav nav-tabs nav-tabs-bottom">{settingsTabs}</ul>
              </div>
              <div className="tab-content chat-contents">{setupTab}</div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="sidebar-overlay"
        data-reff="#chat_sidebar"
        ref={sidebarOverlayRef}
      />
    </PageTemplate>
  );
};

export default NewGroupPage;
