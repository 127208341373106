import React, { Component, useMemo } from "react";
import PropTypes from "prop-types";
import { formatter } from "../../tools";
import moment from "moment";
import classnames from "classnames";
import HamburgerDropdown from "../../component/UI/HamburderDropdown";

const TableRow = (props) => {
  /**
   *
   * @param callback
   * @returns {function(*): boolean}
   */
  const onParentCallback = (callback) => (e) => {
    e.preventDefault();

    callback(props.payout);

    return false;
  };

  /**
   *
   * @param e
   */
  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const payout = props.payout;

  const vendorAPaid =
    (payout.vendor_a_payout > 0 && payout.vendor_a_paid) ||
    !payout.vendor_a_payout;
  const vendorBPaid =
    (payout.vendor_b_payout > 0 && payout.vendor_b_paid) ||
    !payout.vendor_b_payout;

  const bothVendorsUnpaid = !vendorAPaid && !vendorBPaid;
  const oneVendorUnpaid = !vendorAPaid || !vendorBPaid;

  const menuOptions = useMemo(() => {
    const options = [
      {
        label: "View",
        icon: "money",
        onClick: onParentCallback(props.onView),
      },
    ];
    if (props.canDelete) {
      options.push({
        label: "Remove",
        icon: "delete",
        onClick: onParentCallback(props.onDelete),
      });
    }
    return options;
  }, [props.payout, props.onView, props.onDelete]);

  const row = (
    <tr
      className={classnames({
        "table-danger": bothVendorsUnpaid,
        "table-warning": !bothVendorsUnpaid && oneVendorUnpaid,
      })}
      onClick={onParentCallback(props.onRowClick)}
    >
      <td>
        {moment(payout.period_start * 1000)
          .utc()
          .format("MMM")}
      </td>
      <td>{formatter.format(payout.income)}</td>
      <td>
        {formatter.format(
          payout.vendor_a_expenses_total + payout.vendor_b_expenses_total
        )}
      </td>

      <td>{formatter.format(payout.balance)}</td>
      <td>{formatter.format(payout.income_carried_over)}</td>
      <td>{formatter.format(payout.vendor_a_expenses_total)}</td>
      <td>{formatter.format(payout.vendor_a_payout)}</td>
      <td>{formatter.format(payout.vendor_b_expenses_total)}</td>
      <td>{formatter.format(payout.vendor_b_payout)}</td>
      <td className="text-right">
        <HamburgerDropdown options={menuOptions} />
      </td>
    </tr>
  );

  const detailsRow = props.showDetails ? (
    <tr>
      <td colSpan={10} className={"payout-detail-row"}>
        <div className="table-details__container">
          <h5>
            Income for {moment(payout.period_start * 1000).format("MMM YYYY")}
          </h5>

          <table className="table-details">
            <tbody>
              {payout.stripe_payouts.length
                ? payout.stripe_payouts.map((i, idx) => {
                    return (
                      <tr key={`${payout.period_start}-${idx}`}>
                        <td width={"70%"}>{i.stripe_payout_id}</td>
                        <td align="right">{i.amount}</td>
                      </tr>
                    );
                  })
                : "--"}
            </tbody>
          </table>

          <h5>&nbsp;</h5>
          <h5>
            Expenses for {moment(payout.period_start * 1000).format("MMM YYYY")}
          </h5>

          <table className="table-details">
            <tbody>
              {payout.payout_expenses.map((i, idx) => {
                return (
                  <tr key={`${payout.period_start}-e-${idx}`}>
                    <td width={"60%"}>{i.description}</td>
                    <td>{i.vendor}</td>
                    <td>{i.kind == "" ? "one-time" : i.kind}</td>
                    <td align="right">{i.amount}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <h5>&nbsp;</h5>
        </div>
      </td>
    </tr>
  ) : null;
  return (
    <React.Fragment>
      {row}
      {detailsRow}
    </React.Fragment>
  );
};

TableRow.propTypes = {
  payout: PropTypes.object.isRequired,
  onView: PropTypes.func.isRequired,
  canDelete: PropTypes.bool,
  onDelete: PropTypes.func,
  onRowClick: PropTypes.func,
  showDetails: PropTypes.bool,
};

export default TableRow;
