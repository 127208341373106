/**
 * Created by piotr.pozniak@thebeaverhead.com on 16/08/2022
 */

import React, { useCallback } from "react";
import PropTypes from "prop-types";
import Scope from "./Scope";
import appConfig from "../../../../appConfig";

const Scopes = (props) => {
  const { definitions, scopes, idPrefix } = props;

  const onScopeUpdate = useCallback(
    (name, checked) => {
      if (props.onScopeUpdate) {
        props.onScopeUpdate(name, checked);
      }
    },
    [props.onScopeUpdate]
  );

  return (
    <div className={"col-xs-12 col-md-12"}>
      <h4>Scopes</h4>
      <p>
        Use scopes to specify what data {appConfig.shortDisplayName} should
        pull. Ensure you have granted required permissions.
      </p>
      <div>
        <ul className={"list-unstyled"}>
          {Object.keys(definitions).map((definitionName, idx) => {
            const definition = definitions[definitionName];
            const scope = scopes.find((j) => j.name === definitionName);

            return (
              <Scope
                key={`${idPrefix}-${definitionName}`}
                idPrefix={idPrefix}
                name={definitionName}
                available={scope && scope.available ? true : false}
                isMandatory={definition.mandatory}
                label={definition.label}
                description={definition.description}
                isLoading={props.isLoading}
                integrationModel={props.integrationModel}
                additionalComponent={definition.additionalComponent}
                additionalInfo={definition.additionalInfo}
                onScopeUpdate={onScopeUpdate}
                allowedPlans={definition.allowedPlans}
              />
            );
          })}
        </ul>
      </div>
    </div>
  );
};

Scopes.defaultProps = {
  onScopeUpdate: undefined,
};

Scopes.propTypes = {
  definitions: PropTypes.object,
  scopes: PropTypes.array,
  idPrefix: PropTypes.string,
  integrationModel: PropTypes.object,
  isLoading: PropTypes.bool,
  onScopeUpdate: PropTypes.func,
};

export default Scopes;
