/**
 * Created by piotr.pozniak@thebeaverhead.com on 04/04/2024
 */

import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import SharePopup from "../../../../WidgetsComponents/SharePopup";

const ShareButton = ({ url, title, text }) => {
  const [showPopup, setShowPopup] = useState(false);

  /**
   *
   * @type {function(*): boolean}
   */
  const onShare = useCallback(
    (e) => {
      e.preventDefault();
      setShowPopup(true);
      return false;
    },
    [showPopup]
  );

  /**
   *
   * @type {function(*): boolean}
   */
  const onClose = useCallback(
    (e) => {
      e.preventDefault();
      setShowPopup(false);
      return false;
    },
    [showPopup]
  );

  const sharePopup = showPopup ? (
    <SharePopup url={url} title={title} text={text} onClose={onClose} />
  ) : null;

  return (
    <>
      <a
        href={url}
        onClick={onShare}
        target={"_blank"}
        className={"share-event share-event--link"}
      >
        <i className="material-icons">link</i>
      </a>
      {sharePopup}
    </>
  );
};

ShareButton.defaultProps = {};

ShareButton.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
};

export default ShareButton;
