/**
 * Created by piotr.pozniak@thebeaverhead.com on 15/12/2020.
 */
import React, { useContext } from "react";
import PropTypes from "prop-types";
import InfiniteScroll from "react-infinite-scroller";
import WidgetSettingsContext from "../../../contexts/WidgetSettingsContext";
import appConfig from "../../../appConfig";

const InfiniteScrollWrapper = (props) => {
  const { embedded, hasMore, loading } = props;
  const widgetSettings = useContext(WidgetSettingsContext);

  const hasInfiniteScroll =
    Number.parseInt(widgetSettings["infiniteScroll"]) === 1;

  const infiniteScrollParent = widgetSettings["infiniteScrollParentSelector"];

  const infiniteScrollUseWindow = embedded && infiniteScrollParent == "";

  /**
   *
   * @param page
   */
  const onMore = (page) => {
    props.onLoadMore(page);
  };

  /**
   *
   * @returns {Element}
   */
  const getScrollParent = () => {
    let parent = null;

    if (props.embedded) {
      if (infiniteScrollParent == "") {
        return window;
      }

      const nodesList = document.querySelectorAll(infiniteScrollParent);

      if (nodesList && nodesList.length) {
        if (nodesList.length > 1) {
          console.log(
            appConfig.displayName +
              ": There are more nodes matching infinite scroll parent selector, selecting first one of the following list:",
            nodesList
          );
        }
        return nodesList[0];
      }
    } else {
      parent = document.getElementsByClassName("chat-wrap-inner")[0];
    }

    return parent;
  };

  return hasInfiniteScroll && props.children ? (
    <InfiniteScroll
      pageStart={1}
      className={props.className}
      loadMore={onMore}
      hasMore={hasMore && !loading}
      loader={null}
      initialLoad={false}
      getScrollParent={getScrollParent}
      useWindow={infiniteScrollUseWindow}
    >
      {props.children}
    </InfiniteScroll>
  ) : (
    <div className={props.className}>{props.children}</div>
  );
};

InfiniteScrollWrapper.propTypes = {
  loading: PropTypes.bool,
  hasMore: PropTypes.bool,
  embedded: PropTypes.bool,
  onLoadMore: PropTypes.func,
};

export default InfiniteScrollWrapper;
