/**
 * Created by piotr.pozniak@thebeaverhead.com on 20/09/2019.
 */

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import IntegrationContext from "../../strategies/IntegrationContext";
import { useTranslation } from "react-i18next";

const Selector = (props) => {
  const [showMenu, setShowMenu] = useState(false);
  const integrationContext = useContext(IntegrationContext);
  const { t } = useTranslation();

  const selectorId = "selector-" + props.header.toLowerCase().replace(" ", "");

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideSelector);

    return () => {
      document.removeEventListener("click", handleClickOutsideSelector);
    };
  });

  /**
   *
   * @param location
   */
  const onChange = useCallback(
    (filter) => () => {
      setShowMenu(false);
      props.onChange(filter);
    },
    [props.onChange, showMenu]
  );
  /**
   *
   */
  const toggleMenu = useCallback(() => {
    setShowMenu(!showMenu);
  }, [showMenu]);

  const handleClickOutsideSelector = (e) => {
    const container = document.querySelector("#" + selectorId);
    e.stopPropagation();

    if (!container.contains(e.target) && showMenu) {
      setShowMenu(false);
    }
  };

  const { options } = props;

  let allSelected = !props.currentSelection.id ? (
    <i className={"material-icons"}>done</i>
  ) : null;

  const selectOptions = options.length
    ? options
        .filter((i) => {
          const fieldName = integrationContext.filterRenderName(i);
          return fieldName && fieldName.length;
        })
        .map((i, idx) => {
          let tick = null;

          if (!props.currentSelection || i.id === props.currentSelection.id) {
            tick = <i className={"material-icons"}>done</i>;
          }
          const fieldName = integrationContext.filterRenderName(i);

          return (
            <p
              key={"fl-" + idx}
              onClick={onChange(i)}
              className={classnames({ active: tick ? true : false })}
            >
              {tick} {fieldName}
            </p>
          );
        })
    : null;

  return options ? (
    <div className="calendar-filters--selector" id={selectorId}>
      <div className="calendar-filters--options">
        <p
          className={classnames("calendar-filters--options_selected", {
            active: showMenu,
          })}
          onClick={toggleMenu}
        >
          {props.header}
        </p>

        <div
          className={
            "calendar-filters--options_dropdown-container " +
            (showMenu ? "expanded" : "")
          }
        >
          <div className="calendar-filters--options_dropdown ">
            <p
              onClick={onChange(props.allOption)}
              className={classnames({ active: !props.currentSelection.id })}
            >
              {allSelected}
              {t("Any")}
            </p>
            {selectOptions}
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

Selector.propTypes = {
  header: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  currentSelection: PropTypes.object,
  allOption: PropTypes.shape({
    kind: PropTypes.string.isRequired,
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
  }),
};

export default Selector;
