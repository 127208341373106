/**
 * Created by piotr.pozniak@thebeaverhead.com on 05/04/2022.
 */

import React, { useCallback, useContext, useEffect, useState } from "react";

import PropTypes from "prop-types";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import CardView from "./CardView/CardView";
import { GT, PT } from "../../../consts";
import { useGroupStore } from "../../../hooks/redux/group";
import TwoColumns from "./TwoColumns/TwoColumns";
import Outlined from "./Outlined/Outlined";
import Mosaic from "./Mosaic/Mosaic";
import { useAppStore } from "../../../hooks/redux/app";
import classnames from "classnames";
import { WT } from "../../admin/consts/widget";
import useAggressiveResponsiveWidget from "../../../hooks/useAgressiveResponsiveWidget";
import { getAllOptionValues } from "../../../builder";
import Header from "./Header/Header";
import IntegrationContext from "../strategies/IntegrationContext";
import { filtersToQueryParam } from "../../../helpers/filters";
import GroupModal from "./GroupModal";
import PoweredBy from "../../calendar/components/PoweredBy";
import WidgetSettingsContext from "../../../contexts/WidgetSettingsContext";

const WidgetComponents = {};
WidgetComponents[GT.card] = CardView;
WidgetComponents[GT.twoColumn] = TwoColumns;
WidgetComponents[GT.mosaic] = Mosaic;
WidgetComponents[GT.outlined] = Outlined;

const Group = (props) => {
  const { group, groupItems, fetchItems, fetchItem } = useGroupStore();
  const { app } = useAppStore();
  const widgetSettings = getAllOptionValues(group.model);
  const [selectedItem, setSelectedItem] = useState(null);

  const hasValidProps = group.model.slug ? true : false;
  const hasSingleGroup = props.singleGroupSlug ? true : false;
  const integrationContext = useContext(IntegrationContext);

  const widgetId = `dce_${WT.groups}__${group.model.uuid}`;
  const hasAggressiveResponsiveness =
    Number.parseInt(widgetSettings["WAggressiveResp"]) === 1;

  const showPopup = Number.parseInt(widgetSettings["gPopupShow"]) === 1;

  const [state, setState] = useState({
    initialized: hasValidProps,
    page: 1,
  });

  const [selectedFilters, setSelectedFilters] = useState(
    integrationContext.availableFilters.reduce(
      (i, j) => ({ ...i, [j]: {} }),
      {}
    )
  );

  useAggressiveResponsiveWidget(
    widgetId,
    600,
    "dce-ar-responsiveness",
    hasAggressiveResponsiveness
  );

  useEffect(() => {
    if (group.model.slug) {
      setState({
        ...state,
        initialized: true,
      });
      window.__DCE[WT.groups].init = false;
    }
  }, [group.model.slug]);

  useEffect(() => {
    if (hasSingleGroup) {
      fetchItem(group.model.uuid, props.singleGroupSlug);
    } else if (hasValidProps) {
      _fetchItems();
    }
  }, []);

  useDidMountEffect(() => {
    if (group.model.template) {
      _fetchItems();
    }
  }, [state.page, group.model.filter_settings, selectedFilters]);

  useEffect(() => {
    if (hasSingleGroup && groupItems.fetchSuccess) {
      setSelectedItem(groupItems.collection[0]);
    }
  }, [groupItems.fetchSuccess]);

  /**
   *
   * @param location
   */
  const onChangeFilter = useCallback(
    (filter) => {
      const _selectedFilters = { ...selectedFilters };
      _selectedFilters[filter.kind] = filter;

      setSelectedFilters(_selectedFilters);
    },
    [selectedFilters]
  );

  /**
   * TODO
   */
  const _fetchItems = async () => {
    //const { store } = configureStore();

    if (groupItems.fetch || hasSingleGroup) {
      return false;
    }

    const filters = filtersToQueryParam(selectedFilters);

    await fetchItems(group.model.uuid, null, null, filters);

    /*
    const filters = filtersToQueryParam(state.selectedFilters);
    const stateSelectedDate = state.selectedDate.clone();

    if (
      isCTAllowed(group.model.template, [CT.weekly]) ||
      eventsNumberOption == "current_week"
    ) {
      let startDate = stateSelectedDate.startOf("week").format("YYYY-MM-DD");

      if (eventsNumberOption == "upcoming") {
        startDate = stateSelectedDate.isBefore(moment())
          ? moment().format("YYYY-MM-DD")
          : stateSelectedDate.startOf("week").format("YYYY-MM-DD");
      }

      return await fetchEventsWithFeaturedEvents(
        groupUUID,
        startDate,
        moment(stateSelectedDate).endOf("week").format("YYYY-MM-DD"),
        null,
        null,
        filters
      );
    }*/
  };

  /**
   *
   * @type {(function(*): void)|*}
   */
  const onSelectGroup = useCallback(
    (group) => {
      setSelectedItem(group);
    },
    [selectedItem]
  );

  /**
   *
   * @type {(function(): void)|*}
   */
  const closeGroupModal = useCallback(() => {
    setSelectedItem(null);
  }, [selectedItem]);

  /**
   *
   * @param page
   */
  const onLoadMore = useCallback((page) => {});

  const widgetType = group.model.template;

  const WidgetComponent = WidgetComponents[widgetType];

  const groupComponent = (
    <WidgetComponent
      embedded={props.embedded}
      group={group}
      items={groupItems}
      initialized={state.initialized}
      onLoadMore={onLoadMore}
      previewType={app.previewType}
      onSelectedEvent={onSelectGroup}
    />
  );

  const header = (
    <Header onChangeFilter={onChangeFilter} selectedFilters={selectedFilters} />
  );

  const selectedItemModal =
    (hasSingleGroup && selectedItem) || (showPopup && selectedItem) ? (
      <GroupModal
        widgetId={widgetId}
        group={selectedItem}
        closeModal={closeGroupModal}
        widgetSlug={group.model.slug}
        isSingleItem={hasSingleGroup}
      />
    ) : null;

  const watermark =
    group.model && group.model.is_watermarked ? <PoweredBy /> : null;

  const content = !hasSingleGroup ? (
    app.previewType === PT.mobile ? (
      <div className={"mobile-preview--smartphone"}>
        {header}
        {groupComponent}
      </div>
    ) : (
      <React.Fragment>
        {header}
        {groupComponent}
      </React.Fragment>
    )
  ) : null;

  return (
    <WidgetSettingsContext.Provider value={widgetSettings}>
      <div
        className={classnames(widgetId, WT.groups, `dce-${WT.groups}`, {
          "mobile-preview": app.previewType === PT.mobile,
        })}
      >
        {/*<CalendarSettingsContext.Provider value={calendarSettings}>*/}
        {content}
        {selectedItemModal}
        {/*</CalendarSettingsContext.Provider>*/}
        {watermark}
      </div>
    </WidgetSettingsContext.Provider>
  );
};

Group.defaultProps = {};

Group.propTypes = {
  embedded: PropTypes.bool,
};

export default Group;
