import React, { useCallback } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { CSSUnits } from "../../../../../builder/consts";

export const CSSUnitSelector = ({ value, setValue }) => {
  const handleClick = useCallback(
    (unit) => () => {
      setValue(unit);
    },
    [setValue]
  );

  return (
    <div className="dimension-input">
      {CSSUnits.map((unit) => (
        <label
          key={unit}
          onClick={handleClick(unit)}
          className={classnames({ "active-label": value === unit })}
        >
          {unit.toUpperCase()}
        </label>
      ))}
    </div>
  );
};

CSSUnitSelector.propTypes = {
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
};
