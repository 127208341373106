/**
 * Created by piotr.pozniak@thebeaverhead.com on 15/07/2022
 */

import React, { useMemo } from "react";
import PropTypes from "prop-types";
import LocalTz from "../LocalTz";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../../../helpers/date";

const DateFormatter = (props) => {
  const { start_time, end_time, showTimezone } = props;
  const { t } = useTranslation();

  //const tzOffset = new Date().getTimezoneOffset() * 60 * 1000;
  const startTime = useMemo(
    () => new Date(start_time * 1000 /* + tzOffset*/),
    [start_time]
  );
  const endTime = useMemo(
    () => new Date(end_time * 1000 /* + tzOffset*/),
    [end_time]
  );

  const isSameDay = startTime.isSameDay(endTime);

  const formatter = isSameDay;

  const tz = showTimezone ? <LocalTz /> : null;

  const formattedDate = useMemo(() => {
    if (!isSameDay) {
      const allDayDate = !isSameDay
        ? `${formatDate(startTime, {
            weekday: "long",
            day: "numeric",
            month: "short",
          })} - ${formatDate(endTime, {
            weekday: "long",
            day: "numeric",
            month: "short",
          })}`
        : formatDate(startTime, {
            weekday: "long",
          });
      return (
        <span>
          {t("All day")} ({allDayDate})
        </span>
      );
    } else {
      return (
        <span>
          {formatDate(startTime, { month: "long", day: "numeric" })} @{" "}
          {formatDate(startTime, {
            hour: "numeric",
            minute: "2-digit",
          }).toLowerCase()}{" "}
          -{" "}
          {!isSameDay
            ? formatDate(endTime, { month: "long", day: "numeric" })
            : formatDate(endTime, { hour: "numeric", minute: "2-digit" })}{" "}
          {tz}
        </span>
      );
    }
  }, [startTime, endTime, tz, t]);

  return formattedDate;
};

DateFormatter.defaultProps = {};

DateFormatter.propTypes = {
  start_time: PropTypes.number.isRequired,
  end_time: PropTypes.number.isRequired,
  showTimezone: PropTypes.bool,
};

export default DateFormatter;
