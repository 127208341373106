/**
 * Created by piotr.pozniak@thebeaverhead.com on 24/11/2023.
 */

import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as organizationActions from "../../actions/organization";
import { uploadBrandingFile } from "../../actions/organization";

export function useCurrentOrganizationStore() {
  const dispatch = useDispatch();
  const _currentOrganization = useSelector(
    (store) => store.currentOrganization
  );

  const editOrganization = useCallback(
    async (uuid, data, isAdminRequest) =>
      await dispatch(
        organizationActions.editOrganization(uuid, data, isAdminRequest)
      ),
    [dispatch]
  );

  const fetchBillingHistory = useCallback(
    async () => await dispatch(organizationActions.fetchBillingHistory()),
    [dispatch]
  );

  const editOrganizationSubscription = useCallback(
    async (uuid, expiry) =>
      await dispatch(
        organizationActions.editOrganizationSubscription(uuid, expiry)
      ),
    [dispatch]
  );
  const uploadBrandingFile = useCallback(
    async (type, file) =>
      await dispatch(organizationActions.uploadBrandingFile(type, file)),
    [dispatch]
  );

  return {
    currentOrganization: _currentOrganization,
    editOrganization,
    fetchBillingHistory,
    editOrganizationSubscription,
    uploadBrandingFile,
  };
}
