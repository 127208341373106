import React from "react";
import PropTypes from "prop-types";
import CalendarOption from "./CalendarOption";
import appConfig from "../../../../../appConfig";

const GoogleCalendarsForm = (props) => {
  const {
    idPrefix,
    calendars,
    handleCalendarCheckboxToggle,
    onSubmit,
    calendarSyncingIndicator = null,
    calendarsSelectionRequired = false,
  } = props;

  const calendarOptions = calendarSyncingIndicator
    ? calendarSyncingIndicator
    : calendars.length
    ? calendars.map((calendar) => {
        return (
          <CalendarOption
            key={`${idPrefix}-${calendar.id}`}
            idPrefix={idPrefix}
            name={calendar.id}
            available={calendar && calendar.sync ? true : false}
            label={calendar.label}
            handleCalendarCheckboxToggle={handleCalendarCheckboxToggle}
          />
        );
      })
    : null;

  const headerText = calendarsSelectionRequired
    ? "Final step!"
    : "Your Google calendar";

  const buttonText = calendarsSelectionRequired ? "Sync Calendars" : "Update";

  return (
    <div className={"col-xs-12 col-md-12"}>
      <form className="card-body" onSubmit={onSubmit}>
        <h4>
          <strong>{headerText}</strong>
        </h4>
        <p>
          Select which calendars you'd like to sync with {appConfig.displayName}
          .
        </p>
        <div>
          <ul className={"list-unstyled"}>{calendarOptions}</ul>
        </div>
        <div className="col-xs-12 col-md-12">
          <div className="m-t-20 text-center">
            <div className="form-group">
              <button
                className="btn btn-primary btn-md"
                type="submit"
                disabled={calendarSyncingIndicator}
              >
                {buttonText}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

GoogleCalendarsForm.defaultProps = {};

GoogleCalendarsForm.propTypes = {
  idPrefix: PropTypes.string,
  handleCalendarCheckboxToggle: PropTypes.func,
  onSubmit: PropTypes.func,
  calendars: PropTypes.array,
};

export default GoogleCalendarsForm;
