/**
 * Created by piotr.pozniak@thebeaverhead.com on 03/02/2023
 */

import React from "react";
import PropTypes from "prop-types";

const DiscountHeader = (props) => {
  const { coupon } = props;

  if (!coupon) {
    return null;
  }

  const duration =
    coupon.duration === "once" ? "first payment" : "each payment";

  return (
    <div className={"row"}>
      <div className={"col-12"}>
        <div className={"d-flex justify-content-center pricing-discount"}>
          <div>
            Congratulations: Affiliate Discount Found!{" "}
            <strong>{coupon.name}</strong>! {duration}.
          </div>
        </div>
      </div>
    </div>
  );
};

DiscountHeader.defaultProps = {};

DiscountHeader.propTypes = {};

export default DiscountHeader;
