/**
 * Created by piotr.pozniak@thebeaverhead.com on 19/07/2023
 */

import React, { useCallback } from "react";
import PropTypes from "prop-types";

const SuggestionPill = (props) => {
  const onClick = useCallback(() => {
    props.onClick(props.content);
  }, [props.content]);

  return (
    <div className={"dc-ai-suggestions__pill"} onClick={onClick}>
      {props.content} &#8594;
    </div>
  );
};

SuggestionPill.defaultProps = {};

SuggestionPill.propTypes = {};

export default SuggestionPill;
