/**
 * Created by piotr.pozniak@thebeaverhead.com on 23/02/2024
 */

import React from "react";
import PropTypes from "prop-types";
import { useUserStore } from "../../../../../hooks/redux/user";
import { Link } from "react-router-dom";

const DropdownHeader = (props) => {
  const { user } = useUserStore();

  return (
    <div className="nav-user-menu--dropdown-header">
      <div className="nav-user-menu--dropdown-header--user">
        <h5 className="nav-user-menu--dropdown-header--user--email">
          {user.model.email}
        </h5>
        <Link to={"/settings"}>
          <span>Edit profile</span>
        </Link>
      </div>
      <Link
        to={"/organization-settings"}
        className={"btn btn-mgmt btn-sm d-flex align-items-center gap-xxs"}
      >
        <i className={"material-icons icon-sm"}>card_membership</i>
        Manage Organization
      </Link>
      <div className={"nav-user-menu--dropdown-header--close"}>
        <button
          className={"btn btn-text btn-secondary btn-sm align-items-center"}
          onClick={props.onClose}
        >
          <i
            className={"material-icons icon-sm"}
            aria-description={"User menu close button"}
          >
            close
          </i>
        </button>
      </div>
    </div>
  );
};

DropdownHeader.defaultProps = {};

DropdownHeader.propTypes = {
  onClose: PropTypes.func,
};

export default DropdownHeader;
