/**
 * Created by piotr.pozniak@thebeaverhead.com on 22/09/2023.
 */
import { useMemo } from "react";

const useAiSearch = (integration) => {
  const hasAIEnabled = useMemo(() => {
    return (
      integration &&
      integration.settings &&
      integration.settings.create_embeddings
    );
  }, [integration]);

  return { hasAIEnabled };
};

export default useAiSearch;
