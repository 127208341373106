/**
 * Created by piotr.pozniak@thebeaverhead.com on 25/02/2019.
 */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import LoadingIndicator from "../../../calendar/components/LoadingIndicator";
import classnames from "classnames";
import ApiError from "../../component/ApiError";

const CreateModal = (props) => {
  const [state, setState] = useState({
    licenses: [
      { key: "", cost: "" },
      { key: "", cost: "" },
      { key: "", cost: "" },
    ],
    licensesErrors: [null, null, null],
  });

  useEffect(() => {
    if (props.licenses.createSuccess) {
      onClose();
    }
  }, [props.licenses.createSuccess]);

  /**
   *
   */
  const onSubmit = (e) => {
    e.preventDefault();

    const uuidTest =
      /[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}/;
    const licensesErrors = [];
    const licenses = [];
    let hasError = false;

    state.licenses.map((i, idx) => {
      const license = i.key;
      const cost = Number(i.cost);

      let licenseHasError = false;

      if (license.length) {
        if (!uuidTest.test(license)) {
          licensesErrors[idx] = "Invalid license #" + (idx + 1) + " key";
          licenseHasError = hasError = true;
        }
      }

      if (!cost) {
        licensesErrors[idx] = "Enter License #" + (idx + 1) + " cost";
        licenseHasError = hasError = true;
      }

      if (!licenseHasError) {
        licenses.push({ license_key: license, cost });
      }
    });

    setState({
      ...state,
      licensesErrors,
    });

    if (!hasError) {
      props.onSubmit(licenses);
    }

    return false;
  };

  /**
   *
   */
  const onClose = () => {
    setTimeout(props.onClose, 350);
  };

  /**
   *
   * @param idx
   */
  const onRemoveRow = (idx) => (e) => {
    state.licenses.splice(idx, 1);

    setState({
      ...state,
      licenses: state.licenses,
    });
  };

  /**
   *
   */
  const onAddMore = (e) => {
    e.preventDefault();
    state.licenses.push({ key: "", cost: "" });
    setState({
      ...state,
      licenses: state.licenses,
    });

    return false;
  };

  /**
   *
   * @param idx
   * @param field
   * @returns {function(...[*]=)}
   */
  const onChangeLicense = (idx, field) => (e) => {
    state.licenses[idx][field] = e.target.value;

    setState({ ...state, licenses: [...state.licenses] });
  };

  const { create, createError } = props.licenses;

  const loadingIndicator = create ? <LoadingIndicator /> : null;

  const licensesCount = state.licenses.length;
  const licensesInputs = state.licenses.map((i, idx) => {
    const removeBtn =
      licensesCount > 1 ? (
        <button
          type="button"
          className="btn btn-danger btn-sm d-flex"
          onClick={onRemoveRow(idx)}
        >
          <i className="material-icons">close</i>
        </button>
      ) : null;

    const hasError = state.licensesErrors[idx];

    return (
      <React.Fragment key={"li-" + idx}>
        <div className={"row"}>
          <div className={"col-xs-8 col-sm-8"}>
            <label htmlFor={"license_key_" + idx}>
              License Key #{idx + 1}
              <span className="text-danger">*</span>
            </label>
          </div>
          <div className={"col-xs-3 col-sm-3"}>
            <label htmlFor={"license_cost_" + idx}>
              Cost
              <span className="text-danger">*</span>
            </label>
          </div>
          <div className={"col-xs-1 col-sm-1"}>&nbsp;</div>
        </div>
        <div className={"row"}>
          <div className={"col-xs-8 col-sm-8"}>
            <input
              className={classnames("form-control", {
                "is-invalid": hasError,
              })}
              id={"license_key_" + idx}
              type="text"
              placeholder="License key"
              value={i.key}
              onChange={onChangeLicense(idx, "key")}
            />
          </div>
          <div className={"col-xs-3 col-sm-3"}>
            <input
              className={classnames("form-control", {
                "is-invalid": hasError,
              })}
              id={"license_cost_" + idx}
              type="text"
              value={i.cost}
              onChange={onChangeLicense(idx, "cost")}
              placeholder="$cost"
            />
          </div>
          <div className={"col-xs-1 col-sm-1"}>{removeBtn}</div>
        </div>
        <div className={"row"}>
          <small className="text-danger">{hasError}</small>
          <br />
        </div>
      </React.Fragment>
    );
  });

  return (
    <div id="create_license" className="modal custom-modal fade" role="dialog">
      <div className="modal-dialog">
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          onClick={onClose}
        >
          &times;
        </button>
        <div className="modal-content modal-md">
          <div className="modal-header">
            <h4 className="modal-title">Add Licenses</h4>
          </div>
          <div className="modal-body">
            <form className={"container"}>
              {licensesInputs}

              <div className="m-t-20">
                <div className="form-group">
                  <button
                    className="btn btn-success"
                    type="button"
                    onClick={onAddMore}
                  >
                    + Add more
                  </button>
                </div>
              </div>

              <div className="m-t-20 text-center">
                <div className="form-group">
                  <button className="btn btn-primary btn-lg" onClick={onSubmit}>
                    Submit licenses
                  </button>
                </div>
              </div>

              <ApiError
                error={createError}
                defaultErrorMessage={"Could not add licenses. Please try again"}
              />
              {loadingIndicator}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

CreateModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default CreateModal;
