/**
 * Created by piotr.pozniak@thebeaverhead.com on 05/10/2023
 */

import React from "react";
import PropTypes from "prop-types";
import ClipboardCopyInput from "../../../component/ClipboardCopyInput";
import NoDomainsWarning from "./NoDomainsWarning";
import {
  getInstallDivTag,
  getInstallIframeTag,
  installScripTag,
} from "./helpers";
import { ucFirst } from "../../../../../helpers/string";
import DismissableMessage from "./DismissableMessage";

const AdvancedEmbed = ({
  widgetTypeName,
  widgetType,
  uuid,
  slug,
  dismissableMessage,
  dismissableMessageId,
}) => {
  return (
    <form action="#">
      <NoDomainsWarning widgetTypeName={widgetTypeName} />
      <DismissableMessage
        message={dismissableMessage}
        id={dismissableMessageId}
      />
      <ol>
        <li>
          <div className="col-xs-12">
            <p>
              <em>
                To install the {widgetTypeName} on your website, copy the
                following code into the &lt;head&gt; of your website:
              </em>
            </p>

            <ClipboardCopyInput
              id={"embeddable_script"}
              value={installScripTag}
              placeholder={ucFirst(widgetTypeName) + " embeddable script"}
            />
            <p>
              <br />
            </p>
          </div>
        </li>

        <li>
          <ol>
            <li>
              <div className="col-xs-12">
                <p>
                  <em>
                    Then put the following HTML5 object where you want the
                    {widgetTypeName} to appear:
                  </em>
                </p>
                <ClipboardCopyInput
                  id={"embeddable_div"}
                  value={getInstallDivTag(widgetType, uuid)}
                  placeholder={`${widgetTypeName} embeddable div`}
                />
                <p align="center">
                  <br />
                  OR
                  <br />
                </p>
              </div>
            </li>
            <li>
              <div className="col-xs-12">
                <p>
                  <em>
                    <strong className={"text-danger"}>EXPERIMENTAL</strong>
                    In case your website theme is using an aggressive stylesheet
                    and the {widgetTypeName} does not display nicely on your
                    website, copy/paste the following code which will embed the{" "}
                    {widgetTypeName}
                    in the iframe on your website:
                  </em>
                </p>
                <ClipboardCopyInput
                  id={"embeddable_div_iframe"}
                  value={getInstallIframeTag(widgetType, uuid, slug)}
                  placeholder={`${widgetTypeName} embeddable div with iframe`}
                />
              </div>
            </li>
          </ol>
        </li>
      </ol>
    </form>
  );
};

AdvancedEmbed.defaultProps = {
  widgetTypeName: "widget",
};

AdvancedEmbed.propTypes = {
  widgetTypeName: PropTypes.string,
  widgetType: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  dismissableMessage: PropTypes.string,
  dismissableMessageId: PropTypes.string,
};

export default AdvancedEmbed;
