/**
 * Created by piotr.pozniak@thebeaverhead.com on 05/04/2022.
 */

import React, { useCallback } from "react";

import SidebarTab from "../../component/WidgetSettings/SidebarTab";
import MandatoryInformation from "./SettingsTabs/MandatoryInformation";
import PropTypes from "prop-types";
import SuccessAlert from "../../component/SuccessAlert";
import ApiError from "../../component/ApiError";
import LoadingIndicator from "../../../calendar/components/LoadingIndicator";
import Filtering from "./SettingsTabs/Filtering";
import FormError from "../../component/FormError";
import classNames from "classnames";

const SetupTab = (props) => {
  const { group, integrations, hasSetupChanged } = props;

  const integrationModel =
    group.model && integrations.length
      ? integrations.find((i) => i.uuid === group.model.integration.uuid)
      : {};

  const onUpdateFilters = useCallback(
    (filters) => {
      props.onUpdateFilterSettings("filters", filters);
    },
    [props.alertModel]
  );

  const filtering =
    group.model && group.model.uuid ? (
      <Filtering
        widget={group}
        user={props.user}
        widgetModel={props.groupModel || {}}
        onUpdateFilters={onUpdateFilters}
        disabled={props.disabled}
        integrationModel={integrationModel}
        formErrors={props.errors}
      />
    ) : null;

  /*
    const cloneSettings =
      group.model && group.model.uuid && props.groups.length > 1 ? (
        <CloneSettings
          group={group}
          groups={props.groups}
          onSubmit={props.onSubmit}
          overwriteDesignProp={props.overwriteDesignProp}
        />
      ) : null;


  */

  const footer = !props.disabled ? (
    <div>
      <SuccessAlert>
        {group.updateSuccess ? "Your changes have been saved." : null}
      </SuccessAlert>
      <ApiError
        error={group.updateError}
        defaultErrorMessage={"Could not save your settings. Please try again"}
      />
      {Object.keys(props.errors).length ? (
        <FormError errors={Object.values(props.errors)} />
      ) : null}

      {!group.updateSuccess && !group.updateError ? (
        <div
          className={classNames("alert d-flex m-0 gap-xxs p-2", {
            "alert-warning": hasSetupChanged,
            "justify-content-end": !hasSetupChanged,
          })}
        >
          {hasSetupChanged ? (
            <div className="text-left">
              Setup changes will appear visible after saving them.
            </div>
          ) : null}
          <button
            disabled={props.disabled}
            type="submit"
            className="btn btn-primary"
            onClick={props.onSubmit}
          >
            Update
          </button>
        </div>
      ) : null}
    </div>
  ) : (
    <LoadingIndicator />
  );

  return (
    <SidebarTab id="setup_tab" show={true} footer={footer}>
      {filtering}
      <MandatoryInformation
        groupModel={props.groupModel || {}}
        integrations={integrations}
        onUpdateField={props.onUpdateField}
        disabled={props.disabled}
        formErrors={props.errors}
      />

      {/*{cloneSettings}*/}
    </SidebarTab>
  );
};

SetupTab.propTypes = {
  // form fields and errors
  groupModel: PropTypes.object,
  errors: PropTypes.object,

  // for event filters
  group: PropTypes.object,

  // for cloning
  groups: PropTypes.array,

  // for mandatory information and items filtering
  integrations: PropTypes.array,

  // whether diable form
  disabled: PropTypes.bool,

  onSubmit: PropTypes.func.isRequired,
  onUpdateField: PropTypes.func.isRequired,

  // whether to show events filtering/featured events
  isNew: PropTypes.bool,

  // for cloning
  overwriteDesignProp: PropTypes.func,

  // to determine user's plan
  user: PropTypes.object,
};

export default SetupTab;
