/**
 * Created by piotr.pozniak@thebeaverhead.com on 02/03/2021.
 */

import React, { useState, useEffect, useRef, useContext } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import CardEvent from "./CardEvent";
import RowModal from "../EventsList/RowModal";
import LoadingIndicator from "../LoadingIndicator";
import Slider from "react-slick";
import { CT, PT } from "../../../../consts";
import PoweredBy from "../PoweredBy";
import CalendarHeader from "../CalendarHeader";
import SearchInput from "../AiSearch/SearchInput";
import useAiSearch from "../../../../hooks/useAiSearch";
import WidgetSettingsContext from "../../../../contexts/WidgetSettingsContext";

const CardSlider = (props) => {
  const calendarID = "dce_calendar__" + props.calendar.model.uuid;

  const [state, setState] = useState({
    selectedEvent: null,
    swiping: false,
    currentSlide: 0,
    currentPage: 1,
    windowWidth: 0,
    isMobileWidth: false,
  });

  /**
   *
   * @param width
   * @returns {boolean}
   */
  const checkIfMobileWidth = (width) => {
    return width < 700;
  };

  const isMobileWidth = useRef(false);
  const { calendar, events } = props;

  const { hasAIEnabled } = useAiSearch(calendar.model.integration);
  const widgetSettings = useContext(WidgetSettingsContext);

  const slickRef = useRef();

  const loading =
    calendar.rsvp ||
    calendar.fetch ||
    calendar.delete ||
    calendar.update ||
    events.fetch;

  const inpicDatePlacement = widgetSettings["cwDateLocation"];

  useEffect(() => {
    const calendarWrapper = document.querySelector("." + calendarID);

    if (props.previewType === PT.desktop) {
      isMobileWidth.current = false;
      setState({ ...state, isMobileWidth: false });
    } else if (props.previewType === PT.mobile) {
      isMobileWidth.current = true;
      setState({ ...state, isMobileWidth: true });
    } else {
      if (calendarWrapper) {
        isMobileWidth.current = checkIfMobileWidth(calendarWrapper.clientWidth);
        setState({
          ...state,
          //  isMobileWidth: checkIfMobileWidth(calendarWrapper.clientWidth),
        });
      } else {
        isMobileWidth.current = false;
        setState({ ...state, isMobileWidth: false });
      }
    }
  }, [state.windowWidth, props.previewType]);

  const showTitle = Number.parseInt(widgetSettings["ccShowTitle"]) === 1;
  const showDate = Number.parseInt(widgetSettings["ccShowDate"]) === 1;
  const showLocation = Number.parseInt(widgetSettings["ccShowLocation"]) === 1;
  const showDescription =
    Number.parseInt(widgetSettings["ccShowDescription"]) === 1;
  const showSummary = Number.parseInt(widgetSettings["ccShowSummary"]) === 1;
  const hoverEffect = widgetSettings["cwHoverEffect"];
  const showDots = Number.parseInt(widgetSettings["sliderShowDots"]) === 1;
  const centerSlides =
    Number.parseInt(widgetSettings["sliderCenterSlides"]) === 1;
  const loop = Number.parseInt(widgetSettings["sliderLoop"]) === 1;
  const autoPlay = Number.parseInt(widgetSettings["sliderAutoPlay"]) === 1;
  const autoplaySpeed = widgetSettings["sliderAutoplaySpeed"];
  const sliderSlidesToShowValue = widgetSettings["sliderSlidesToShow"] * 1;
  const numberOfSlides = isMobileWidth.current /*state.isMobileWidth*/
    ? 1
    : sliderSlidesToShowValue;
  const sliderNextSlideToShow =
    Number.parseInt(widgetSettings["sliderNextSlideToShow"]) * 0.1;
  const dotsSize = widgetSettings["sliderSizeOfDots"];
  const showWeekDayInList =
    Number.parseInt(widgetSettings["eventDetailsShowDayOfWeek"]) === 1;
  const weekDayFormatter = widgetSettings["eventsShowDayWeekOption"];
  const hasInfiniteScroll =
    Number.parseInt(widgetSettings["infiniteScroll"]) === 1;
  const useEventAccent =
    Number.parseInt(widgetSettings["generalUseEventAccent"]) === 1;
  const showImage =
    Number.parseInt(widgetSettings["eventDetailsShowImage"]) === 1;
  const showDetailsAsModal =
    Number.parseInt(widgetSettings["generalOpenDetailsModal"]) === 1;

  const recurringShowBadge =
    Number.parseInt(widgetSettings["recurringShowBadge"]) === 1;
  const featuredShowBadge =
    Number.parseInt(widgetSettings["featuredShowBadge"]) === 1;

  const showCardCta = Number.parseInt(widgetSettings["cCardShowCta1st"]) === 1;

  const badgesLocation = widgetSettings["recurringBadgeLocation"];
  const selectedDateTimeFormat = widgetSettings["ccShowTimeOptions"];
  const showImageContainer =
    Number.parseInt(widgetSettings["eventDetailsShowImageContainer"]) === 1;

  const triggerCTAOnCardClick =
    Number.parseInt(widgetSettings["cCardActionOnClick"]) === 1;

  const showAccentColorOnCard =
    Number.parseInt(widgetSettings["cwShowAccentBar"]) === 1;

  const watermark =
    calendar.model && calendar.model.is_watermarked ? (
      <PoweredBy align={hasInfiniteScroll ? "right" : "left"} />
    ) : null;

  useEffect(() => {
    if (hasInfiniteScroll) {
      props.onLoadMore(state.currentPage);
    }
  }, [state.currentPage]);

  useEffect(() => {
    if (state.currentSlide === -1) {
      setTimeout(() => slickRef.current.slickGoTo(0, true), 500);
    }
  }, [state.currentSlide]);

  useEffect(() => {
    if (slickRef.current) {
      if (autoPlay) {
        slickRef.current.slickPlay();
      } else {
        slickRef.current.slickPause();
      }
    }

    let timeout = null;
    const onWindowResize = () => {
      if (timeout) {
        clearTimeout(timeout);
      }

      timeout = setTimeout(() => {
        const windowWidth =
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth;

        if (state.windowWidth == windowWidth) {
          return;
        }

        setState({
          ...state,
          windowWidth,
        });
      }, 300);
    };
    window.addEventListener("resize", onWindowResize);

    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  }, []);

  /**
   *
   * @param event
   */
  const onSelectedEvent = (event) => {
    if (state.swiping) {
      return false;
    }

    if (showDetailsAsModal) {
      setState({ ...state, selectedEvent: event });
    }
  };

  /**
   *
   * @param rowId
   */
  const onCloseOpenedEventsDetails = (rowId) => {
    setState({ ...state, selectedEvent: null });

    if (props.onClosedEventRowModal) {
      props.onClosedEventRowModal();
    }
  };

  const cardEvents = events.collection.map((i, idx) => {
    return (
      <div key={i.slug} className={"card-slider_card-wrapper"}>
        <CardEvent
          event={i}
          onSelectedEvent={!state.swiping ? onSelectedEvent : null}
          inpicDatePlacement={inpicDatePlacement}
          showDate={showDate}
          showTitle={showTitle}
          showLocation={showLocation}
          showDescription={showDescription}
          showSummary={showSummary}
          hoverEffect={hoverEffect}
          embedded={props.embedded}
          showWeekDay={showWeekDayInList}
          weekDayFormatter={weekDayFormatter}
          useEventAccent={useEventAccent}
          showImage={showImage}
          showRecurringBadge={recurringShowBadge}
          showFeaturedBadge={featuredShowBadge}
          badgesLocation={badgesLocation}
          showCardCta={showCardCta}
          selectedDateTimeFormat={selectedDateTimeFormat}
          showImageContainer={showImageContainer}
          triggerCTAOnCardClick={triggerCTAOnCardClick}
          showAccentOnCard={showAccentColorOnCard}
        />
      </div>
    );
  });

  const modalEvent = state.selectedEvent ? (
    <RowModal
      event={state.selectedEvent}
      showDetails={true}
      calendarSlug={calendar.model.slug}
      embedded={props.embedded}
      ccbAddress={calendar.model.integration.address}
      onRSVPEvent={props.onRSVPEvent}
      loading={props.loading}
      error={props.error}
      closeOpenedEventsDetails={onCloseOpenedEventsDetails}
      expandedRowSlug={state.selectedEvent.slug}
      expandAsAccordion={false}
      expandAsModal={true}
      calendarID={calendarID}
      showWatermark={calendar.model && calendar.model.is_watermarked}
    />
  ) : null;
  const loadingIndicator = loading ? <LoadingIndicator /> : null;

  // number of dots based on number of events, not less than 10
  const dotsDividingFactor = !centerSlides ? numberOfSlides : 1;
  const eventsLengthForDots =
    events.collection.length / dotsDividingFactor < 10
      ? events.collection.length
      : 10;

  const numberOfDots = Math.ceil(eventsLengthForDots / dotsDividingFactor);
  const halfDots = Math.floor(numberOfDots / 2);

  /**
   *
   */
  const sliderOnSwipe = () => {
    setState({ ...state, swiping: true });
  };

  /**
   *
   * @param currentIndex
   * @param nextIndex sometimes the slider returns next index as float, it needs
   * to be ceiled before use.
   */
  const sliderBeforeChange = (currentIndex, nextIndex) => {
    //console.log("beforeChange dots", e, a);
    nextIndex = Math.ceil(nextIndex);

    let page = state.currentPage;

    if (hasInfiniteScroll && nextIndex >= events.collection.length - 3) {
      page++;
    }

    setTimeout(
      () =>
        setState({
          ...state,
          swiping: false,
          currentSlide: Math.ceil(
            nextIndex / (!centerSlides ? numberOfSlides : 1)
          ),
          currentPage: page,
        }),
      200
    );
  };

  const sliderAppendDots = (dots) => {
    if (loadingIndicator) {
      return (
        <div>
          <div className={"slider-loading-indicator"}>{loadingIndicator}</div>
        </div>
      );
    }

    const updatedDots = dots.map((i, idx) => {
      return (
        <li
          key={"sdts-" + idx}
          className={classnames("slick-dots-" + dotsSize, {
            "slick-active": idx == state.currentSlide,
          })}
        >
          {i.props.children}
        </li>
      );
    });

    let leftPosition = 0;

    // move only if selected slide is further than a half of visible dots
    if (state.currentSlide > halfDots) {
      // maximum movement, it wil not go further than twice the half of visible dots -1
      // - - - | * * * * O * * *
      //                 ^ won't go further than here
      //
      let maxMovementFactor = dots.length - halfDots * 2 - 1;
      maxMovementFactor = maxMovementFactor < 0 ? 0 : maxMovementFactor;

      // how many dots to move, current slide - half of the visible dots
      const currentMovement = state.currentSlide - halfDots;

      leftPosition =
        -(currentMovement > maxMovementFactor
          ? maxMovementFactor
          : currentMovement) * 30;
    }

    return (
      <div style={{ width: numberOfDots * 30 + "px" }}>
        <ul
          style={{
            left: leftPosition + "px",
          }}
        >
          {updatedDots}
        </ul>
      </div>
    );
  };

  const sliderSettings = {
    dots: showDots,
    infinite: loop && events.collection.length >= numberOfSlides,
    autoplay: autoPlay,
    autoplaySpeed: autoplaySpeed * 1000,
    speed: 500,
    slidesToShow: numberOfSlides + sliderNextSlideToShow,
    slidesToScroll: numberOfSlides,
    arrows: false,
    rows: 1,
    fade: false,
    centerPadding: centerSlides ? "60px" : "25px",
    centerMode: centerSlides,
    onSwipe: sliderOnSwipe,
    beforeChange: sliderBeforeChange,
    appendDots: sliderAppendDots,
  };

  //slick-active

  const search =
    hasAIEnabled && widgetSettings["aiEnabled"] ? (
      <SearchInput
        searchEvents={props.searchEvents}
        fetchEvents={props.fetchEvents}
      />
    ) : null;

  return (
    <div
      className={classnames(
        "calendar-card-view",
        `dce--${CT.cardSlider}`,
        calendarID,
        {
          "mobile-preview": props.previewType == PT.mobile,
        }
      )}
    >
      <CalendarHeader
        disabled={events.fetch}
        calendar={calendar}
        currentDate={props.selectedDate}
        onChangeDate={props.onSelectedDateChange}
        events={events.collection}
        locations={events.locations}
        onUpcomingNavigationChange={props.onUpcomingNavigationChange}
        selectedFilters={props.selectedFilters}
        onChangeFilter={props.onChangeFilter}
      />
      {search}
      <Slider {...sliderSettings} className={"card-view-slider"} ref={slickRef}>
        {cardEvents}
      </Slider>
      {watermark}

      {modalEvent}
    </div>
  );
};

CardSlider.propTypes = {
  calendar: PropTypes.object,
  events: PropTypes.object,
  onSelectedDateChange: PropTypes.func.isRequired,
  onUpcomingNavigationChange: PropTypes.func.isRequired,
  selectedDate: PropTypes.object,
  initialized: PropTypes.bool,
  embedded: PropTypes.bool,
  previewType: PropTypes.oneOf(Object.values(PT)),
  onLoadMore: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  selectedFilters: PropTypes.object.isRequired,
};

export default CardSlider;
