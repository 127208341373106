/**
 *
 * @param plan
 * @returns {*}
 */
export function getPlanSubscriptionName(plan) {
  return plan
    ? (plan.is_trial * 1 ? "Trial " : "") + plan.subscription_plan.name
    : null;
}
