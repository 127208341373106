/**
 * Created by piotr.pozniak@thebeaverhead.com on 02/09/2021.
 */

import TZ from "timezone-names";
import { useContext } from "react";
import WidgetSettingsContext from "../../../contexts/WidgetSettingsContext";

export const localTzMatches = new Date().toString().match(/\(([A-Za-z\s].*)\)/);

const localTzFullName = localTzMatches
  ? localTzMatches.length == 2
    ? localTzMatches[1]
    : localTzMatches[0]
  : null;

const tz = TZ.getTimezoneByName(localTzFullName);

export default () => {
  const settingsContext = useContext(WidgetSettingsContext);

  const showTz = settingsContext["generalShowTz"];

  return showTz ? (tz ? tz.Abbreviation : localTzFullName) : "";
};
