/**
 * Created by piotr.pozniak@thebeaverhead.com on 01/12/2021.
 */

import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import EventBadge from "./EventBadge";
import { useTranslation } from "react-i18next";

const EventBadges = (props) => {
  const badges = [];

  const { t } = useTranslation();
  if (props.showRecurringBadge) {
    badges.push({
      label: t("Recurring"),
      icon: "autorenew",
      badgeType: "recurring",
    });
  }

  if (props.showFeaturedBadge) {
    badges.push({
      label: t("Featured"),
      icon: "star_outline",
      badgeType: "featured",
    });
  }

  const eventBadges = badges.map((i, idx) => {
    return (
      <EventBadge
        key={`ebg-${idx}`}
        label={i.label}
        icon={i.icon}
        badgeType={i.badgeType}
      />
    );
  });

  return (
    <div
      className={classnames(
        `dce--event-badge_container-position-${props.location}`,
        "dce--event-badge_container"
      )}
    >
      {eventBadges}
    </div>
  );
};

EventBadges.defaultProps = {
  location: "image-top",
};

EventBadges.propTypes = {
  showRecurringBadge: PropTypes.bool,
  showFeaturedBadge: PropTypes.bool,
  location: PropTypes.string,
};

export default EventBadges;
