/**
 * Created by piotr.pozniak@thebeaverhead.com on 16/01/2019.
 */

import React, { useState, useEffect, useRef } from "react";

import PropTypes from "prop-types";
import classnames from "classnames";
import EventsCounter from "./EventsCounter";
import EventNameWithTime from "./EventNameWithTime";

const MAX_EVENTS_TO_SHOW = 4;
const DetailedEventsCounter = (props) => {
  const [state, setState] = useState({
    height: 0,
    width: 0,
  });

  const ref = useRef();

  /**
   *
   */
  useEffect(() => {
    setTimeout(() => {
      if (ref.current) {
        const height = ref.current.clientHeight;
        const width = ref.current.clientWidth;

        setState({ width, height });
      }
    }, 500);
  }, []);

  const { events, isLeft } = props;

  if (!events.length) {
    return null;
  }

  const hasMore =
    events.length > MAX_EVENTS_TO_SHOW ? (
      <li className="detailed-event-counter-no-border">
        + {events.length - MAX_EVENTS_TO_SHOW} More (click date FMI)
      </li>
    ) : null;

  return (
    <div
      className={classnames("detailed-events-counter", {
        lefter: isLeft,
      })}
      ref={ref}
      style={{
        top: -state.height + "px",
        left: isLeft ? "initial" : props.parentContainerWidth + "px",
        right: isLeft ? props.parentContainerWidth + "px" : "initial",
      }}
    >
      <ul>
        {events.slice(0, MAX_EVENTS_TO_SHOW).map((i) => {
          const inlineStyles = {};
          if (props.useEventAccent && i.accentColor) {
            inlineStyles.borderLeftColor = i.accentColor;
          }
          return (
            <li key={"dec-" + i.id} style={inlineStyles}>
              <EventNameWithTime event={i} />
            </li>
          );
        })}
        {hasMore}
      </ul>

      <EventsCounter eventsCount={events.length} isLeft={true} />
    </div>
  );
};

DetailedEventsCounter.propTypes = {
  events: PropTypes.array.isRequired,
  useEventAccent: PropTypes.bool,
};

export default DetailedEventsCounter;
