/**
 * Created by piotr.pozniak@thebeaverhead.com on 11/04/2023.
 */

import React, { useCallback, useContext } from "react";
import PropTypes from "prop-types";
import FilterGroup from "./FilterGroup";
import Separated from "./Separated";
import Conjunction from "./Conjunction";
import RuleDropdown from "./RuleDropdown";
import {
  AvailableRulesOptionsContext,
  ConjunctionContext,
  FiltersContext,
} from "./contexts";
import AddButton from "./AddButton";

const Editor = () => {
  const [conjunctionHovered, setConjunctionHovered] = React.useState(false);

  const { filters, updateMainConjunction, updateGroup } =
    useContext(FiltersContext);

  const filterGroups = filters.groups || [];

  /**
   *
   * @type {(function(*): void)|*}
   */
  const onUpdateMainConjunction = useCallback(
    (conjunction) => {
      setConjunctionHovered(false);
      updateMainConjunction(conjunction);
    },
    [filters]
  );

  /**
   *
   * @type {(function(*): void)|*}
   */
  const onMainConjunctionToggleHovered = useCallback(() => {
    setConjunctionHovered(!conjunctionHovered);
  }, [conjunctionHovered]);

  const groups = filterGroups.map((group, index) => {
    return (
      <FilterGroup
        key={`g-${index}`}
        groupId={index}
        rules={group.rules}
        conjunction={group.conjunction}
      />
    );
  });

  return (
    <ConjunctionContext.Provider value={{ hovered: conjunctionHovered }}>
      <div className={"filters"}>
        <Separated
          id={"main"}
          separator={
            <Conjunction
              value={filters.conjunction}
              toggleHovered={onMainConjunctionToggleHovered}
              onChange={onUpdateMainConjunction}
            />
          }
        >
          {groups}
        </Separated>
      </div>
      <AddButton
        className={"filters__add-group-button"}
        groupId={filterGroups.length}
      >
        <span>+</span> Add filter
      </AddButton>
    </ConjunctionContext.Provider>
  );
};

Editor.defaultProps = {};
Editor.propTypes = {};

/**
 * availableRules: PropTypes.arrayOf(
 *     PropTypes.shape({
 *       field: PropTypes.string.isRequired,
 *       label: PropTypes.string.isRequired,
 *       operators: PropTypes.arrayOf(
 *         PropTypes.shape({
 *           label: PropTypes.string.isRequired,
 *           value: PropTypes.string.isRequired,
 *         })
 *       ).isRequired,
 *     })
 *   ).isRequired,
 */
export default Editor;
