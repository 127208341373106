import React, { useCallback, useEffect } from "react";
import SettingsPageTemplate from "../templates/SettingsPageTemplate";
import AffiliatePromoCard from "./AffiliatesPage/AffiliatePromoCard";
import { useAffiliatesStore } from "../../../hooks/redux/affiliates";
import LoadingIndicator from "../../groups/components/LoadingIndicator";
import ProgressCircle from "../component/ProgressCircle";
import AffiliatePanel from "./AffiliatesPage/AffiliatePanel";
import AffiliateStats from "./AffiliatesPage/AffiliateStats";
import ApiError from "../component/ApiError";
import FetchAffiliatesError from "./AffiliatesPage/FetchAffiliatesError";

const AffiliatesPage = () => {
  const { affiliates, fetchAffiliate } = useAffiliatesStore();

  useEffect(() => {
    fetchAffiliate();
  }, []);

  const welcomeCard =
    !affiliates.model && affiliates.fetchSuccess && !affiliates.create ? (
      <div className={"row"}>
        <div className={"col-xs-12 offset-xs-0 col-xl-8 offset-xl-2"}>
          <AffiliatePromoCard />
        </div>
      </div>
    ) : null;

  const loadingCard =
    (!affiliates.model && affiliates.fetch) || affiliates.create ? (
      <div className={"row"}>
        <div className={"col-xs-12 offset-xs-0 col-xl-8 offset-xl-2"}>
          <div className={"d-flex w-100 justify-content-center m-t-50"}>
            <ProgressCircle size={"lg"} />
          </div>
        </div>
      </div>
    ) : null;

  const affiliatePanel =
    affiliates.model && affiliates.fetchSuccess ? <AffiliatePanel /> : null;

  const stats =
    affiliates.model && affiliates.fetchSuccess ? <AffiliateStats /> : null;
  return (
    <SettingsPageTemplate
      title="Affiliates"
      sidebarId="affiliates"
      icon={"handshake"}
    >
      <FetchAffiliatesError />
      {loadingCard}
      {welcomeCard}
      {stats}
      {affiliatePanel}
    </SettingsPageTemplate>
  );
};

export default AffiliatesPage;
