/**
 * Created by piotr.pozniak@thebeaverhead.com on 26/01/2021.
 */

import React from "react";
import { useSubscriptionPlansStore } from "../../../../hooks/redux/subscriptionPlans";

export const formatAvailablePlans = (forPlans, plans) => {
  let availableStr = "available with ";

  if (forPlans.length === 3) {
    availableStr += "all plans";
  } else {
    const plansDetails = forPlans.map((i) => {
      const plan = plans.find((j) => j.code === i);

      if (!plan) {
        return "";
      }
      return "the " + plan.name + " ($" + Number.parseInt(plan.price) + "/m)";
    });

    availableStr +=
      plansDetails.join(" and ") + " plan" + (forPlans.length > 1 ? "s" : "");
  }

  return availableStr;
};

const AvailableInPlans = (props) => {
  const { subscriptionPlans } = useSubscriptionPlansStore();

  if (!subscriptionPlans.collection || !subscriptionPlans.collection.length) {
    return "";
  }

  const plans = subscriptionPlans.collection;

  return formatAvailablePlans(props.forPlans, plans);
};

export default AvailableInPlans;
