/**
 * Created by piotr.pozniak@thebeaverhead.com on 27/11/2023
 */

import React from "react";
import PropTypes from "prop-types";
import { applyCouponDiscount } from "../../component/Pricing";

const TotalDue = (props) => {
  const { totalDue, appliedCoupon, affiliateDiscount } = props;

  let discountedTotal = 0;

  if (appliedCoupon) {
    discountedTotal = Number.parseFloat(appliedCoupon.price / 100).toFixed(2);
  } else if (affiliateDiscount) {
    discountedTotal = applyCouponDiscount(affiliateDiscount, totalDue);
    // ...
  }

  const discountedTotalDue =
    appliedCoupon || affiliateDiscount ? (
      <strong className={"total-due"}>
        <span className={"text-secondary text-strikethrough"}>${totalDue}</span>{" "}
        <span className={"text-primary"}>${discountedTotal}</span>
      </strong>
    ) : (
      <strong className={"text-primary total-due"}>${totalDue}</strong>
    );

  return (
    <p>
      <span className={"text-secondary"}>Your Total Due Today:</span>{" "}
      {discountedTotalDue}
    </p>
  );
};

TotalDue.defaultProps = {};

TotalDue.propTypes = {
  totalDue: PropTypes.number.isRequired,
  appliedCoupon: PropTypes.object,
  affiliateDiscount: PropTypes.object,
};

export default TotalDue;
