/**
 * Created by piotr.pozniak@thebeaverhead.com on 16/02/2023
 */

import React from "react";
import PropTypes from "prop-types";
import appConfig from "../../../../appConfig";

const BrowserWindowMockup = ({ address, children }) => {
  return (
    <div className={"browser-window"}>
      <div className="browser-window--container">
        <div className="browser-window--row">
          <div className="browser-window--column browser-window_left">
            <span className="browser-window_dot red"></span>
            <span className="browser-window_dot yellow"></span>
            <span className="browser-window_dot green"></span>
          </div>
          <div className="browser-window--column browser-window_middle">
            <input type="text" defaultValue={address} />
          </div>
          <div className="browser-window--column browser-window_right">
            <div className={"browser-window--bar-container"}>
              <span className="browser-window--bar-container_bar"></span>
              <span className="browser-window--bar-container_bar"></span>
              <span className="browser-window--bar-container_bar"></span>
            </div>
          </div>
        </div>

        <div className={"browser-window--preview"}>
          {/*<div className={"browser-window--preview-overlay"} />*/}
          {/*<iframe
            className={"browser-window--preview-iframe"}
            src={address}
            width={"100%"}
            height={"100%"}
          />*/}
          <div className="browser-window--content">{children}</div>
        </div>
      </div>
    </div>
  );
};

BrowserWindowMockup.defaultProps = {
  address: `${appConfig.homepage}`,
};

BrowserWindowMockup.propTypes = {
  address: PropTypes.string,
};

export default BrowserWindowMockup;
