import React from "react";
import PropTypes from "prop-types";

export const Separated = ({ id, children, separator }) => {
  return (
    <React.Fragment>
      {React.Children.toArray(children)
        .reduce((previousValue, currentValue, idx) => {
          const sep = {
            ...separator,
            key: `sep-${idx}-${id}`,
          };
          return [...previousValue, currentValue, sep];
        }, [])
        .slice(0, -1)}
    </React.Fragment>
  );
};

Separated.defaultProps = {
  separator: " | ",
};

Separated.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  children: PropTypes.node,
  separator: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};
export default Separated;
