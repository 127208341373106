/**
 * Created by piotr.pozniak@thebeaverhead.com on 18/11/2022
 */

import React from "react";
import PropTypes from "prop-types";
import TableRow from "./TableRow";

const ChargesTab = (props) => {
  /*if (!billing.invoices.length) {
    return (
      <div className="row">
        <div className="col-sm-12">No payments.</div>
      </div>
    );
  }*/

  const paymentRows = props.charges.map((item, idx) => {
    return (
      <TableRow
        key={idx}
        date={item.created}
        description={item.description}
        quote={item.amount}
        link={item.receipt_url}
      />
    );
  });

  return (
    <div className={"tab-pane"} id={"charges"}>
      <div className="table-responsive">
        <table className="table table-striped custom-table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Item</th>
              <th>Amount</th>
              <th>Receipt</th>
            </tr>
          </thead>
          <tbody>{paymentRows}</tbody>
        </table>
      </div>
    </div>
  );
};

ChargesTab.defaultProps = {};

ChargesTab.propTypes = {
  charges: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      date: PropTypes.number.isRequired,
      receipt_url: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      amount: PropTypes.number.isRequired,
    })
  ),
};

export default ChargesTab;
