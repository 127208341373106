/**
 * Created by piotr.pozniak@thebeaverhead.com on 14/05/2024
 */

import React, { useCallback } from "react";
import PropTypes from "prop-types";
import appConfig from "../../../../appConfig";
import FileUpload from "./FileUpload";
import ColorInput from "./ColorInput";
import ApiError from "../../component/ApiError";
import { getUploadResourceUrl } from "../../../../helpers/url";

const iconTooltip = {
  message:
    "A smaller representation of your logo (like a favicon). It must be square and at least 128px by 128px with a max size of 512KB. The supported MIME types are JPG and PNG.",
};

const logoTooltip = {
  message:
    "The full-sized version of your logo. It must be at least 128px by 128px with a max size of 512KB. The supported MIME types are JPG and PNG.",
};

const brandColorMessage = {
  message: "Add a splash of color to your branded pages.",
};

const accentColorMessage = {
  message: "Used for the main colors across the pages.",
};

const Form = ({
  onSubmit,
  values,
  disabled,
  onValueChange,
  onInputFileChange,
  apiError,
  apiSuccess,
}) => {
  /**
   *
   * @type {(function(*): void)|*}
   */
  const onFormSubmit = useCallback(
    (e) => {
      e.preventDefault();
      onSubmit();

      return false;
    },
    [onSubmit]
  );

  const footerContent = disabled ? (
    "Loading"
  ) : (
    <>
      <button type={"submit"} className={"btn btn-primary"} disabled={disabled}>
        Save changes
      </button>
      <ApiError error={apiError} />
      {apiSuccess ? (
        <div className={"alert alert-success w-100"}>Changes saved!</div>
      ) : null}
    </>
  );

  return (
    <form onSubmit={onFormSubmit}>
      <div className={"container-fluid"}>
        <div className={"row"}>
          <div className={"col-xs-12 col-sm-6"}>
            <div className="card">
              <div className={"card-header bg-white"}>
                <h4 className={"card-title"}>Brand elements</h4>
                <p>
                  Set your default brand elements to determine how{" "}
                  {appConfig.displayName} products appear to end-users.
                </p>
              </div>
              <div className={"card-body"}>
                <form>
                  <FileUpload
                    src={getUploadResourceUrl(values.favicon)}
                    title="Icon"
                    tooltipMessage={iconTooltip}
                    type={"favicon"}
                    onChange={onInputFileChange("favicon")}
                  />

                  <FileUpload
                    title="Logo"
                    tooltipMessage={logoTooltip}
                    src={getUploadResourceUrl(values.logo)}
                    type={"logo"}
                    maxFileSizeKb={512}
                    width={160}
                    onChange={onInputFileChange("logo")}
                  />
                  <hr />
                  <ColorInput
                    id={"brandColor"}
                    title={"Brand color"}
                    tooltipMessage={brandColorMessage}
                    value={values.brandColor}
                    onChange={onValueChange("brandColor")}
                  />
                  <ColorInput
                    id={"accentColor"}
                    title={"Accent color"}
                    tooltipMessage={accentColorMessage}
                    value={values.accentColor}
                    onChange={onValueChange("accentColor")}
                  />
                </form>
              </div>
              <div
                className={
                  "card-footer bg-white d-flex flex-column align-items-end gap-xs"
                }
              >
                {footerContent}
              </div>
            </div>
          </div>
          <div className={"col-xs-12 col-sm-6"}></div>
        </div>
      </div>
    </form>
  );
};

Form.defaultProps = {};

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  onValueChange: PropTypes.func.isRequired,
  onInputFileChange: PropTypes.func.isRequired,
  apiError: PropTypes.object,
  apiSuccess: PropTypes.bool,
};

export default Form;
