import React, { useCallback } from "react";
import PropTypes from "prop-types";

const CalendarOption = (props) => {
  const { idPrefix, name, available, label, handleCalendarCheckboxToggle } =
    props;

  const onToggle = useCallback(
    (e) => {
      handleCalendarCheckboxToggle(name, e.target.checked ? true : false);
    },
    [props.integrationModel]
  );

  return (
    <li className="d-flex align-items-center">
      <input
        id={`${idPrefix}${name}`}
        type={"checkbox"}
        defaultChecked={available ? true : false}
        onClick={onToggle}
      />
      <label htmlFor={`${idPrefix}${name}`} className="ml-1 mb-0">
        {label}
      </label>
    </li>
  );
};

CalendarOption.defaultProps = {};

CalendarOption.propTypes = {
  idPrefix: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  available: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  handleCalendarCheckboxToggle: PropTypes.func,
};

export default CalendarOption;
