/**
 * Created by piotr.pozniak@thebeaverhead.com on 24/05/2019.
 */

import React, { useState } from "react";
import PropTypes from "prop-types";

const BillingDetailsForm = (props) => {
  const organization = props.organization;

  /**
   *
   * @param field
   * @returns {Function}
   */
  const onSetValue = (field) => (e) => {
    e.preventDefault();

    const data = { ...organization };
    data[field] = e.target.value;

    props.onSetValue(data);
  };

  const disabled = props.disabled;

  return (
    <div>
      <div className="card-title">Billing details</div>
      <div className="row">
        <div className="col-sm-6">
          <div className="form-group">
            <label htmlFor="billing_name">
              Billing Name <span className="text-danger">*</span>
            </label>
            <input
              id="billing_name"
              className={
                "form-control " + (props.billingNameError ? "is-invalid" : "")
              }
              type="text"
              value={organization.billing_name}
              onChange={onSetValue("billing_name")}
              placeholder="Billing name"
              disabled={disabled}
            />
          </div>
          <div className="form-group">
            <label htmlFor="billing_contact">Contact Person</label>
            <input
              id="billing_contact"
              className="form-control "
              value={organization.billing_contact}
              onChange={onSetValue("billing_contact")}
              type="text"
              placeholder="Contact Person"
              disabled={disabled}
            />
          </div>
          <div className="form-group">
            <label htmlFor="treasurer_email">Treasurer Email</label>
            <input
              id="treasurer_email"
              className={"form-control"}
              type="text"
              value={organization.treasurer_email}
              onChange={onSetValue("treasurer_email")}
              placeholder="Treasurer email"
              disabled={disabled}
            />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <label htmlFor="billing_address">Address</label>
            <input
              className="form-control "
              id={"billing_address"}
              value={organization.billing_address}
              type="text"
              placeholder="Address"
              onChange={onSetValue("billing_address")}
              disabled={disabled}
            />
          </div>
          <div className="form-group">
            <label htmlFor="billing_city">City</label>
            <input
              className="form-control"
              type="text"
              id="billing_city"
              value={organization.billing_city}
              placeholder="City"
              onChange={onSetValue("billing_city")}
              disabled={disabled}
            />
          </div>
          <div className="form-group">
            <label htmlFor="billing_state">State/Province</label>
            <input
              className="form-control"
              aria-hidden="true"
              id="billing_state"
              value={organization.billing_state}
              placeholder="State"
              onChange={onSetValue("billing_state")}
              disabled={disabled}
            />
          </div>
          <div className="form-group">
            <label htmlFor="billing_zip">Postal Code</label>
            <input
              id="billing_zip"
              className="form-control"
              type="text"
              value={organization.billing_zip}
              onChange={onSetValue("billing_zip")}
              placeholder="Postal Code/ZIP"
              disabled={disabled}
            />
          </div>
          <div className="form-group">
            <label htmlFor="billing_country">Country</label>
            <input
              id="billing_country"
              className="form-control"
              tabIndex="-1"
              aria-hidden="true"
              value={organization.billing_country}
              placeholder="Country"
              onChange={onSetValue("billing_country")}
              disabled={disabled}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

BillingDetailsForm.defaultProps = {
  showCCForm: true,
};

BillingDetailsForm.propTypes = {
  disabled: PropTypes.bool,
  organization: PropTypes.object.isRequired,
  onSetValue: PropTypes.func.isRequired,
  billingNameError: PropTypes.string,
};

export default BillingDetailsForm;
