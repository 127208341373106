import React from "react";
import PropTypes from "prop-types";
import { UserRoles } from "../../consts/crm";
import { capitalizeString } from "../../../../helpers/general";

const Role = ({ role, setRole, disabled }) => {
  const handleRoleChange = (e) => {
    setRole(e.target.value);
  };

  return (
    <div className="role-container">
      <select
        className={"form-control"}
        value={role}
        onChange={handleRoleChange}
        disabled={disabled}
      >
        {Object.values(UserRoles).map((roleOption) => (
          <option key={roleOption} value={roleOption}>
            {capitalizeString(roleOption)}
          </option>
        ))}
      </select>
    </div>
  );
};

Role.propTypes = {
  role: PropTypes.string.isRequired,
  setRole: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default Role;
