/**
 * Created by piotr.pozniak@thebeaverhead.com on 23/10/2018.
 */


import axios from "axios";
import AxiosConfig from "../AxiosConfig";

/**
 *
 * @param email
 * @param password
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export function fetchCalendars() {

  return function(dispatch) {

    dispatch({type: "CALENDARS_FETCH"});

    const queryParams = [];

    const url = "/api/calendars.json";

    return axios.get(
      url,
      AxiosConfig.getAuthConfig()
    )
      .then((response) => {
        dispatch({
          type: "CALENDARS_FETCH_FULFILLED",
          payload: response.data
        });

      })
      .catch(function(error) {

        dispatch({
          type: "CALENDARS_FETCH_REJECTED",
          payload: error,
        })
      });
  }
}
