import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import appConfig from "../../../appConfig";
import Tos from "../templates/PageTemplate/Tos";
import LoadingIndicator from "../../calendar/components/LoadingIndicator";
import { useOrganizationMembersStore } from "../../../hooks/redux/organizationMembers";
import InvitationResponseOptions from "./InvitationVerificationPage/InvitationResponseOptions";
import ApiError from "../component/ApiError";
import EmailSignUpForm from "./RegisterPage/EmailSignUpForm";
import { useUserStore } from "../../../hooks/redux/user";

const InvitationVerificationPage = (props) => {
  const history = useHistory();
  const matchParams = props.match.params;
  const { email, invitationHash } = matchParams;

  const [showLoading, setShowLoading] = useState(true);

  const {
    organizationMembers,
    checkInvitation,
    acceptInvitation,
    rejectInvitation,
  } = useOrganizationMembersStore();
  const { setUser } = useUserStore();

  const {
    // Check invite states
    checkInvitation: checkInvitationLoading,
    checkInvitationSuccess,
    checkInvitationError,
    invitationOrganizationData,

    // Reject invite states
    rejectInvitation: rejectInvitationLoader,
    rejectInvitationSuccess,
    rejectInvitationError,
    rejectInvitationData,

    // Accept invite states
    acceptInvitation: acceptInvitationLoader,
    acceptInvitationSuccess,
    acceptInvitationError,
    acceptInvitationData,
  } = organizationMembers;

  // Check-invitation API
  useEffect(() => {
    if (email && invitationHash) {
      checkInvitation({
        invitationHash,
        email,
      });
    }
    setShowLoading(false);
  }, [email, invitationHash]);

  // Redirecting user to homepage upon reject invitation
  useEffect(() => {
    if (rejectInvitationData && rejectInvitationSuccess) {
      setTimeout(() => {
        window.location.href = appConfig.homepage;
      }, [5000]);
    }
  }, [rejectInvitationSuccess, rejectInvitationData]);

  // Redirecting user to dashboard upon accept invitation if account exist
  useEffect(() => {
    if (
      acceptInvitationSuccess &&
      acceptInvitationData &&
      acceptInvitationData.user &&
      acceptInvitationData.token
    ) {
      setUser(acceptInvitationData);
      history.push("/dashboard");
    }
  }, [acceptInvitationSuccess, acceptInvitationData]);

  const title = useMemo(() => {
    if (checkInvitationLoading || showLoading) return "Loading...";
    if (checkInvitationError) return "Something went wrong";
    if (rejectInvitationSuccess) return "Invitation rejected";
    if (
      acceptInvitationSuccess &&
      acceptInvitationData &&
      acceptInvitationData.success === 0
    )
      return "Last step to accept the invitation";
    if (checkInvitationSuccess && invitationOrganizationData)
      return "Accept invitation";
  }, [
    checkInvitationLoading,
    showLoading,
    checkInvitationSuccess,
    invitationOrganizationData,
    rejectInvitationSuccess,
    acceptInvitationSuccess,
    acceptInvitationData,
  ]);

  const indicator = useMemo(() => {
    return showLoading ||
      checkInvitationLoading ||
      rejectInvitationLoader ||
      acceptInvitationLoader ? (
      <div>
        <LoadingIndicator />
      </div>
    ) : null;
  }, [
    showLoading,
    checkInvitationLoading,
    rejectInvitationLoader,
    acceptInvitationLoader,
  ]);

  const invalidInvitation = checkInvitationError ? (
    <div>
      <p className="font-weight-light text-center mb-4">
        Your invitation link seems to be invalid or expired. Contact with
        organization manager to resend you an invitation,
      </p>
      <div className={"text-center m-b-15"}>
        <Link to={"/login"} className={"text-primary"}>
          Go back to login
        </Link>
      </div>
    </div>
  ) : null;

  const invitationRejected = rejectInvitationSuccess ? (
    <div className={"text-center m-b-30"}>
      <h4>Thank you. You're being redirected to our homepage.</h4>
    </div>
  ) : null;

  const formValues = useMemo(() => {
    return {
      email: decodeURIComponent(email),
      organization_uuid:
        invitationOrganizationData && invitationOrganizationData.uuid,
      organizationName:
        invitationOrganizationData && invitationOrganizationData.name,
      invitationHash,
    };
  }, [invitationOrganizationData, email]);

  const registerUser =
    acceptInvitationSuccess &&
    acceptInvitationData &&
    acceptInvitationData.success === 0 ? (
      <div className={"text-center m-b-30"}>
        <h4>
          You don't have DC account, create one using form below to finish the
          process
        </h4>
        <EmailSignUpForm
          formValues={formValues}
          disabledFields={{ email: true, organizationName: true }}
          submitBtnText={`JOIN ${invitationOrganizationData.name.toUpperCase()}`}
          displayCouponLink={false}
        />
      </div>
    ) : null;

  const error =
    rejectInvitationError || acceptInvitationError ? (
      <div>
        <ApiError
          error={rejectInvitationError || acceptInvitationError}
          defaultErrorMessage={rejectInvitationError || acceptInvitationError}
        />
        <div className={"text-center m-b-15"}>
          <Link to={"/login"} className={"text-primary"}>
            Go back to login
          </Link>
        </div>
      </div>
    ) : null;

  const onAccept = useCallback(() => {
    acceptInvitation({
      email: decodeURIComponent(email),
      invitation_hash: invitationHash,
    });
  }, [acceptInvitation]);

  const onReject = useCallback(() => {
    rejectInvitation({
      email: decodeURIComponent(email),
      invitation_hash: invitationHash,
    });
  }, [rejectInvitation]);

  const invitationResponseOptions =
    checkInvitationSuccess &&
    invitationOrganizationData &&
    !checkInvitationError &&
    !rejectInvitationSuccess &&
    !(
      acceptInvitationSuccess &&
      acceptInvitationData &&
      acceptInvitationData.success === 0
    ) ? (
      <InvitationResponseOptions
        organization={invitationOrganizationData}
        onAccept={onAccept}
        onReject={onReject}
      />
    ) : null;

  return (
    <div className="main-wrapper">
      <div className="account-page">
        <div className="container">
          <div className="account-logo">
            <Link to="/">
              <img
                src={`/img/branding/${appConfig.branding}/full_length_logo.png`}
                alt={appConfig.displayName}
              />
            </Link>
          </div>
          <div className="account-box">
            <div className="account-wrapper">
              <h3 className="account-title">{title}</h3>
              {indicator}
              {registerUser}
              {invalidInvitation}
              {invitationRejected}
              {invitationResponseOptions}
              {error}
            </div>
          </div>
          <Tos />
        </div>
      </div>
    </div>
  );
};

export default InvitationVerificationPage;
