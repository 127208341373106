/**
 * Created by piotr.pozniak@thebeaverhead.com on 05/08/2024
 */

import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { durationToString } from "../../tools";
import { syncDurationInSeconds } from "../../helpers/sync";

const QueueJobToDuration = ({ job, asPercentage, showETA }) => {
  const [lastUpdated, setLastUpdated] = useState(new Date());

  const isSyncing = useMemo(() => {
    return job.fetched && !job.failed && !job.completed;
  }, [job]);

  const durationInSeconds = useMemo(() => {
    return syncDurationInSeconds(job);
  }, [job, lastUpdated]);

  useEffect(() => {
    if (isSyncing) {
      const interval = setInterval(() => {
        setLastUpdated(new Date());
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [isSyncing, durationInSeconds]);

  if (!durationInSeconds) {
    return <span>-</span>;
  }

  // format duration to human readable string using Date objects
  const lastDuration = job.data.last_duration + 30;

  let timeRemaining = null;

  if (showETA) {
    timeRemaining = lastDuration - durationInSeconds;
  }

  const percentageProgress = Math.round(
    (durationInSeconds * 100) / lastDuration
  );

  const output =
    asPercentage && percentageProgress <= 100
      ? `${percentageProgress}%`
      : durationToString(durationInSeconds);

  return (
    <span>
      {output}{" "}
      {showETA && timeRemaining >= 0
        ? ` (ETA ${durationToString(timeRemaining)})`
        : null}
    </span>
  );
};

QueueJobToDuration.defaultProps = {
  asPercentage: false,
  showETA: false,
};

QueueJobToDuration.propTypes = {
  job: PropTypes.object.isRequired,
  asPercentage: PropTypes.bool,
  showETA: PropTypes.bool,
};

export default QueueJobToDuration;
