/**
 * Created by piotr.pozniak@thebeaverhead.com on 28/06/2022
 */

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import PropTypes from "prop-types";
import FiltersToggle from "./FiltersToggle";
import classNames from "classnames";
import IntegrationContext from "../../../strategies/IntegrationContext";
import { useFiltersStore } from "../../../../../hooks/redux/filters";
import { useGroupStore } from "../../../../../hooks/redux/group";
import Selector from "./Selectors/Selector";
import useHeaderFilters from "../../../../../hooks/useHeaderFilters";
import WidgetSettingsContext from "../../../../../contexts/WidgetSettingsContext";

const Filters = (props) => {
  const [showFilters, setShowFilters] = useState(true);
  const { filters, fetchGroupFilters } = useFiltersStore();
  const { group } = useGroupStore();

  const integrationStrategy = useContext(IntegrationContext);
  const widgetSettings = useContext(WidgetSettingsContext);

  useHeaderFilters(
    integrationStrategy,
    widgetSettings,
    fetchGroupFilters,
    group.model.uuid,
    showFilters,
    [integrationStrategy.availableFilters, showFilters]
  );

  /**
   * This feature is disabled, it shows a toggle button to show/hide the filters
   * @type {(function(): void)|*}
   */
  const onToggleFilters = useCallback(() => {
    setShowFilters(!showFilters);
  }, [showFilters]);

  // console.log(integrationStrategy);

  const selectors = integrationStrategy.availableFilters
    .map((kind) => {
      const showSelector = widgetSettings[`show${kind}Dropdown`];

      if (!showSelector) {
        return null;
      }

      const filterSettings = integrationStrategy._filters.find(
        (i) => i.field.value === kind
      );

      const options =
        filterSettings && filterSettings.options
          ? filterSettings.options
          : filters.widgetFiltersCollection &&
            filters.widgetFiltersCollection.filter((i) => i.kind === kind);

      return (
        <Selector
          key={"sk-" + kind}
          allOption={{ kind, id: null, name: "Any" }}
          onChange={props.onChangeFilter}
          options={options}
          currentSelection={
            props.selectedFilters ? props.selectedFilters[kind] : {}
          }
          header={integrationStrategy.filtersSettings[kind].label}
        />
      );
    })
    .filter((i) => i);

  return (
    <div className={"dce-groups--filters"}>
      {/*<FiltersToggle onToggle={onToggleFilters} showFilters={showFilters} />*/}

      <div
        className={classNames("dce-groups--filters-panel", {
          show: showFilters,
        })}
      >
        {selectors}
      </div>
    </div>
  );
};

Filters.defaultProps = {};

Filters.propTypes = {
  onChangeFilter: PropTypes.func.isRequired,
  selectedFilters: PropTypes.object,
};

export default Filters;
