/**
 * Created by piotr.pozniak@thebeaverhead.com on 02/09/2022
 */

import React from "react";
import PropTypes from "prop-types";
import PoweredBy from "../../../calendar/components/PoweredBy";
import appConfig from "../../../../appConfig";

/**
 * There are legacy, not available subscription plans that did not have a branding option at all.
 * After branding introduction, these plans were granted no branding for free but there is no record about
 * that. These hardcoded 3 plans that grants no branding for free.
 * @type {string[]}
 */
const LegacySubscriptions = [
  "53013bc2-6018-4804-806c-6b1bd057b241",
  "79703797-942f-44af-af39-ef99bbb4d2e0",
  "eea1b77e-a31b-49e9-90df-508943ffef62",
];

const SummaryBranding = (props) => {
  const { subscription, plan } = props;

  const isBranded = subscription.is_branded === 1;
  const isAnnual = subscription.is_annual === 1;
  const isLegacy = LegacySubscriptions.indexOf(plan.uuid) >= 0;

  const charge =
    !isBranded && !isLegacy ? (
      <React.Fragment>
        with a charge of <strong>${isAnnual ? 50 : 5}</strong> /{" "}
        {isAnnual ? "yr" : "m"}
      </React.Fragment>
    ) : null;

  return (
    <li>
      ‘Powered by {appConfig.shortDisplayName}’ Branding is{" "}
      {isBranded ? "not hidden." : <span>hidden {charge}.</span>}
      <PoweredBy />
    </li>
  );
};

SummaryBranding.defaultProps = {};

SummaryBranding.propTypes = {
  subscription: PropTypes.shape({
    is_branded: PropTypes.number,
    is_annual: PropTypes.number,
  }),
  plan: PropTypes.shape({
    uuid: PropTypes.string,
  }),
};

export default SummaryBranding;
