/**
 * Created by piotr.pozniak@thebeaverhead.com on 22/05/2024
 */

import React, { useCallback, useContext, useMemo } from "react";
import PropTypes from "prop-types";
import Accordion from "../../component/WidgetSettings/Acordion";
import { IT } from "../../../../consts";
import RadioInput from "../../component/WidgetSettings/RadioInput";
import WidgetContext from "../../contexts/WidgetContext";

const SortOptions = {
  [IT.pco]: [
    {
      label: "Event start time first, then alphabetically",
      value: "starts_at:asc",
    },
    {
      label: "Alphabetically",
      value: "name:asc",
    },
    {
      label: "Reverse alphabetically",
      value: "name:desc",
    },
  ],
  [IT.ccb]: [
    {
      label: "Event start time first, then alphabetically",
      value: "starts_at:asc",
    },
    {
      label: "Alphabetically",
      value: "name:asc",
    },
    {
      label: "Reverse alphabetically",
      value: "name:desc",
    },
  ],
};

const sortingSummary = (integrationType, settings) => {
  const sort = settings?.sorting?.sort
    ? SortOptions[integrationType].find(
        (i) => i.value === settings.sorting.sort
      )
    : SortOptions[integrationType][0];

  if (integrationType === IT.pco && settings?.sorting?.is_featured_first) {
    return sort ? `${sort.label}. Featured first.` : "";
  }

  return sort ? sort.label : "";
};

const SortSetup = (props) => {
  const settings = props.widgetModel.widget_settings;

  /**
   *
   * @param e
   */
  const onChange = useCallback(
    (e) => {
      const data = settings.sorting ? { ...settings.sorting } : {};
      data.sort = e.target.value;
      props.onUpdateWidgetSettings("sorting", data);
    },
    [settings, props.onUpdateWidgetSettings]
  );

  /**
   *
   * @param fieldName
   * @returns {(function(*): void)|*}
   */
  const onRadioInputChanged = useCallback(
    (fieldName) => (e) => {
      const data = settings.sorting ? { ...settings.sorting } : {};

      data.is_featured_first = e.target.checked;

      props.onUpdateWidgetSettings("sorting", data);
    },
    [settings, props.onUpdateWidgetSettings]
  );

  const sortOptions = useMemo(
    () =>
      SortOptions[props.widgetModel.integration.type].map((i) => {
        return (
          <option key={i.value} value={i.value}>
            {i.label}
          </option>
        );
      }),
    []
  );

  return (
    <Accordion
      title={"Sorting"}
      subtitle={sortingSummary(props.widgetModel.integration.type, settings)}
      keepExpanded={props.expanded}
    >
      <form action="#">
        <div className="form-group">
          <div className="col-md-xs">
            <select
              className={"form-control form-control-sm "}
              id="clone_widget_uuid"
              onChange={onChange}
              value={settings?.sorting?.sort}
            >
              {sortOptions}
            </select>
          </div>
        </div>
        {props.widgetModel.integration.type === IT.pco ? (
          <div className="form-group">
            <RadioInput
              fieldName={"sort_featured"}
              label={"Show featured events first"}
              onChange={onRadioInputChanged}
              checked={settings?.sorting?.is_featured_first}
            />
          </div>
        ) : null}
      </form>
    </Accordion>
  );
};

SortSetup.defaultProps = {};

SortSetup.propTypes = {};

export default SortSetup;
