/**
 * Created by piotr.pozniak@thebeaverhead.com on 15/02/2023
 */

import React, { useState, useEffect, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import AlertTypeName from "./AlertTypeName";
import { Link } from "react-router-dom";
import { copyTextToClipboard } from "../../tools";
import classnames from "classnames";
import PublishDropdown from "../../component/UI/PublishDropdown";
import HamburderDropdown from "../../component/UI/HamburderDropdown";

const TableRow = (props) => {
  const [clipboardCopied, setClipboardCopied] = useState(false);

  useEffect(() => {
    if (clipboardCopied) {
      setTimeout(() => setClipboardCopied(false), 300);
    }
  }, [clipboardCopied]);

  /**
   *
   */
  const onUserAction = useCallback(
    (callback) => (e) => {
      callback(props.alert);

      return false;
    },
    [props.alert]
  );

  const {
    uuid,
    name,
    integration,
    modified,
    available,
    template,
    disabled_date,
  } = props.alert;

  const disabled = props.disabled;
  const editUrl = integration
    ? "/alert/" + uuid
    : "/alert/attach-integration/" + uuid;

  const disabledAlert = disabled_date ? (
    <div
      data-toggle="tooltip"
      data-placement="top"
      data-trigger={"hover"}
      title={"This widget has been disabled due to plan downgrade."}
      className={"cursor-pointer"}
    >
      <i className={"material-icons design-tooltip text-danger"}>warning</i>
    </div>
  ) : null;

  const menuOptions = useMemo(
    () => [
      {
        label: "Edit",
        icon: "edit",
        href: editUrl,
      },
      {
        label: "Clone",
        icon: "content_copy",
        onClick: onUserAction(props.onClone),
      },
      {
        label: "Delete",
        icon: "delete",
        onClick: onUserAction(props.onDelete),
      },
    ],
    [onUserAction]
  );

  return (
    <tr
      className={classnames({
        "table-success blinker": clipboardCopied,
        "table-secondary": uuid == props.alertEditUUID,
      })}
    >
      <td>{disabledAlert}</td>
      <td>
        <Link to={editUrl}>{name}</Link>
      </td>
      <td>
        {integration ? (
          integration.type.toUpperCase()
        ) : (
          <span className={"badge badge-danger"}>Disconnected</span>
        )}
      </td>

      <td>{<AlertTypeName type={template} />}</td>

      <td>
        <PublishDropdown
          available={available}
          onChangeStatus={onUserAction(props.onChangeStatus)}
          disabled={disabled}
          customIcon={disabledAlert}
        />
      </td>
      <td align="center">
        {modified ? moment(modified * 1000).format("YYYY-MM-DD HH:mm") : "-"}
      </td>
      <td className="text-right">
        <HamburderDropdown disabled={disabled} options={menuOptions} />
      </td>
    </tr>
  );
};

TableRow.propTypes = {
  alert: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    available: PropTypes.number.isRequired,
    disabled_date: PropTypes.number.isRequired,
  }),
  disabled: PropTypes.bool,
  alertEditUUID: PropTypes.string,
  onChangeStatus: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onClone: PropTypes.func.isRequired,
};

export default TableRow;
