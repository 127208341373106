import React, { useMemo } from "react";
import PropTypes from "prop-types";
import LocalTz from "../LocalTz";
import { formatDate } from "../../../../helpers/date";
import { useTranslation } from "react-i18next";

const EventDetailsTime = ({
  startDate,
  endDate,
  isAllDayEvent,
  showEventTimeOption,
}) => {
  const tz = isAllDayEvent ? null : <LocalTz />;
  const { t } = useTranslation();

  const formattedTime = useMemo(() => {
    if (showEventTimeOption === "compact") {
      if (isAllDayEvent) {
        const multiDayEvent = !startDate.isSameDay(endDate);

        const allDayDate = multiDayEvent
          ? `${formatDate(startDate, {
              weekday: "long",
              month: "long",
              day: "numeric",
            })} - ${formatDate(endDate, {
              weekday: "long",
              month: "long",
              day: "numeric",
            })}`
          : formatDate(startDate, { weekday: "long" });
        return `${t("All day")} (${allDayDate})`;
      } else {
        const startDayOfWeek = formatDate(startDate, { weekday: "long" });
        const endDayOfWeek = formatDate(endDate, { weekday: "long" });
        const startTime = formatDate(startDate, {
          hour: "numeric",
          minute: "2-digit",
        });
        const endTime = formatDate(endDate, {
          hour: "numeric",
          minute: "2-digit",
        });

        if (startDate.isSameDay(endDate)) {
          return `${startDayOfWeek} ${startTime} - ${endTime}`;
        } else {
          return `${startDayOfWeek} ${startTime} - ${endDayOfWeek} ${endTime}`;
        }
      }
    } else if (showEventTimeOption === "short") {
      if (isAllDayEvent) {
        return t("All day");
      } else {
        return `${formatDate(startDate, {
          hour: "numeric",
          minute: "2-digit",
        })} - ${formatDate(endDate, { hour: "numeric", minute: "2-digit" })}`;
      }
    } else {
      const multiDayEvent = !startDate.isSameDay(endDate);

      const allDayDate = multiDayEvent
        ? `${formatDate(startDate, {
            weekday: "long",
            month: "long",
            day: "numeric",
          })} - ${formatDate(endDate, {
            weekday: "long",
            month: "long",
            day: "numeric",
          })}`
        : formatDate(startDate, {
            weekday: "long",
            month: "long",
            day: "numeric",
          });

      return isAllDayEvent ? (
        `${t("All day")} (${allDayDate})`
      ) : (
        <React.Fragment>
          {formatDate(startDate, {
            month: "long",
            day: "numeric",
            weekday: "long",
            hour: "numeric",
            minute: "2-digit",
          })}
          {" - "}
          {formatDate(endDate, {
            month: "long",
            day: "numeric",
            weekday: "long",
            hour: "numeric",
            minute: "2-digit",
          })}
        </React.Fragment>
      );
    }
  }, [startDate, endDate, isAllDayEvent, showEventTimeOption]);

  return (
    <p className="p i">
      {formattedTime} {tz}
    </p>
  );
};

EventDetailsTime.defaultProps = {
  showEventTimeOption: "regular",
};

EventDetailsTime.propTypes = {
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
  showEventTimeOption: PropTypes.oneOf(["regular", "compact", "short"]),
};

export default EventDetailsTime;
