/**
 * Created by piotr.pozniak@thebeaverhead.com on 15/02/2023.
 */

import axios from "axios";
import AxiosConfig from "../AxiosConfig";

/**
 *
 * @returns {function(*): Promise<AxiosResponse<any>>}
 */
export function fetchAlerts() {
  return function (dispatch) {
    dispatch({ type: "ALERTS_FETCH" });

    const queryParams = [];

    const url = "/api/alerts.json";

    return axios
      .get(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "ALERTS_FETCH_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "ALERTS_FETCH_REJECTED",
          payload: error,
        });
      });
  };
}
