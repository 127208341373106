/**
 * Created by piotr.pozniak@thebeaverhead.com on 02/08/2024
 */

import React from "react";
import PropTypes from "prop-types";
import CardWidget from "../../component/CardWidget";
import AffiliatePanelButton from "./AffiliatePanelButton";
import { useAffiliatesStore } from "../../../../hooks/redux/affiliates";

const formatValue = (value) => {
  return `$${Math.round(value.cents / 100)}`;
};
const AffiliateStats = (props) => {
  const { affiliates } = useAffiliatesStore();

  const stats = affiliates.model?.commission_stats?.currencies?.USD;

  return (
    <div>
      <div
        className={
          "w-100 d-flex align-items-center justify-content-between mb-4"
        }
      >
        <h4>Commissions</h4>
        <AffiliatePanelButton />
      </div>
      <div className={"row"}>
        <div className={"col-3"}>
          <CardWidget
            value={formatValue(stats.unpaid)}
            label={"Unpaid"}
            icon={"pending"}
          />
        </div>
        <div className={"col-3"}>
          <CardWidget
            value={formatValue(stats.due)}
            label={"Due"}
            icon={"hourglass_top"}
            variant={"primary"}
          />
        </div>
        <div className={"col-3"}>
          <CardWidget
            value={formatValue(stats.paid)}
            label={"Paid"}
            icon={"paid"}
            variant={"success"}
          />
        </div>
        <div className={"col-3"}>
          <CardWidget
            value={formatValue(stats.total)}
            label={"Total"}
            icon={"account_balance"}
            variant={"success"}
          />
        </div>
      </div>
    </div>
  );
};

AffiliateStats.defaultProps = {};

AffiliateStats.propTypes = {};

export default AffiliateStats;
