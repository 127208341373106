/**
 * Created by piotr.pozniak@thebeaverhead.com on 11/12/2023
 */

import React, { useCallback, useContext, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { CT, PT } from "../../../../consts";
import LoadingIndicator from "../LoadingIndicator";
import { openEventPage } from "../../../../helpers/calendar";
import Bubble from "./Bubble";
import BubbleViewScrollArrow, { Direction } from "./BubbleViewScrollArrow";
import RowModal from "../EventsList/RowModal";
import WidgetSettingsContext from "../../../../contexts/WidgetSettingsContext";

const BubbleView = (props) => {
  const { calendar, events } = props;

  const [selectedEvent, setSelectedEvent] = useState(null);

  const calendarID = "dce_calendar__" + calendar.model.uuid;

  const widgetSettings = useContext(WidgetSettingsContext);
  const showBorder = Number.parseInt(widgetSettings["bcShowBorder"]) === 1;

  const openChurchCenter =
    Number.parseInt(widgetSettings["generalGoToChurchCenter"]) === 1;

  const showDetailsAsModal =
    Number.parseInt(widgetSettings["generalOpenDetailsModal"]) === 1;

  const openLinkInNewTab =
    Number.parseInt(widgetSettings["generalOpenLinkInNewTab"]) === 1;

  const showEventName =
    Number.parseInt(widgetSettings["bcShowEventName"]) === 1;
  const showDate = Number.parseInt(widgetSettings["bcShowEventDate"]) === 1;
  const showRecurringIcon =
    Number.parseInt(widgetSettings["bcShowRecurringIcon"]) === 1;

  const bubbleSize = widgetSettings["bcBubbleSize"];
  const bubbleStyle = widgetSettings["bcBubbleStyle"];
  const wrapItems = Number.parseInt(widgetSettings["bcWrap"]) === 1;
  const showImageBorder =
    Number.parseInt(widgetSettings["bcBubbleImgBorder"]) === 1;

  const loading =
    calendar.rsvp ||
    calendar.fetch ||
    calendar.delete ||
    calendar.update ||
    events.fetch;

  const loadingIndicator = loading ? <LoadingIndicator /> : null;

  /**
   *
   * @type {(function(*): void)|*}
   */
  const onSelectedEvent = useCallback(
    (event) => {
      if (openChurchCenter) {
        openEventPage(event, openLinkInNewTab);
        return;
      }
      if (showDetailsAsModal) {
        setSelectedEvent(event);
      }
    },
    [openChurchCenter, showDetailsAsModal, openLinkInNewTab, selectedEvent]
  );

  /**
   *
   * @type {(function(*): void)|*}
   */
  const onCloseOpenedEventsDetails = useCallback(
    (rowId) => {
      setSelectedEvent(null);

      if (props.onClosedEventRowModal) {
        props.onClosedEventRowModal();
      }
    },
    [selectedEvent, props.onClosedEventRowModal]
  );

  const modalEvent = selectedEvent ? (
    <RowModal
      event={selectedEvent}
      showDetails={true}
      calendarSlug={calendar.model.slug}
      embedded={props.embedded}
      ccbAddress={calendar.model.integration.address}
      onRSVPEvent={props.onRSVPEvent}
      loading={props.loading}
      error={props.error}
      closeOpenedEventsDetails={onCloseOpenedEventsDetails}
      expandedRowSlug={selectedEvent.slug}
      expandAsAccordion={false}
      expandAsModal={true}
      calendarID={calendarID}
      showWatermark={calendar.model && calendar.model.is_watermarked}
    />
  ) : null;

  const eventsBubbles = events.collection.map((i, idx) => (
    <Bubble
      key={`eb-${idx}`}
      event={i}
      onSelectedEvent={onSelectedEvent}
      showEventName={showEventName}
      showEventDate={showDate}
      showRecurringIcon={showRecurringIcon}
      style={bubbleStyle}
      size={bubbleSize}
      showImageBorder={showImageBorder}
    />
  ));

  const scrollArrows =
    !wrapItems && events.collection.length > 0 ? (
      <React.Fragment>
        <BubbleViewScrollArrow
          direction={Direction.LEFT}
          calendarID={calendarID}
        />
        <BubbleViewScrollArrow
          direction={Direction.RIGHT}
          calendarID={calendarID}
        />
      </React.Fragment>
    ) : null;

  return (
    <div className={classnames("calendar-bubble-view", `dce--${CT.bubble}`)}>
      {loadingIndicator}
      <div
        className={classnames({
          "calendar-bubble-view_container--frame": showBorder,
        })}
      >
        {scrollArrows}
        <div
          className={classnames("calendar-bubble-view_container", {
            "calendar-bubble-view_container--wrap": wrapItems,
          })}
        >
          {eventsBubbles}
        </div>
      </div>
      {modalEvent}
    </div>
  );
};

BubbleView.defaultProps = {};

BubbleView.propTypes = {
  calendar: PropTypes.object,
  events: PropTypes.object,
  onSelectedDateChange: PropTypes.func.isRequired,
  onUpcomingNavigationChange: PropTypes.func.isRequired,
  selectedDate: PropTypes.object,
  initialized: PropTypes.bool,
  embedded: PropTypes.bool,
  previewType: PropTypes.oneOf(Object.values(PT)),
  onLoadMore: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  selectedFilters: PropTypes.object.isRequired,
};

export default BubbleView;
