/**
 * Created by piotr.pozniak@thebeaverhead.com on 25/02/2019.
 */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import LoadingIndicator from "../../../calendar/components/LoadingIndicator";
import ApiError from "../../component/ApiError";

const EditModal = (props) => {
  const [state, setState] = useState({
    licenseKeyError: null,
    licenseCostError: null,
  });
/*

  useEffect(() => {
    if (props.licenses.updateSuccess) {
      onClose();
    }
  }, [props.licenses.updateSuccess]);
*/

  /**
   *
   */
  const onSubmit = (e) => {
    e.preventDefault();

    const license = props.license;

    let licenseKeyError = null,
      licenseCostError = null;
    const licenseKey = document.querySelector("#license_key").value;
    const licenseCost = Number(document.querySelector("#license_cost").value);
    const uuidTest = /[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}/;

    if (!licenseKey) {
      licenseKeyError = "Enter license key.";
    } else if (!uuidTest.test(licenseKey)) {
      licenseKeyError = "License key is invalid.";
    }

    if (!licenseCost) {
      licenseCostError = "Enter license cost.";
    }

    setState({
      licenseKeyError,
      licenseCostError,
    });

    if (!licenseKeyError && !licenseCostError) {
      const data = {
        ...props.license,
        license_key: licenseKey,
        cost: licenseCost,
      };

      props.onEdit(data);
    }

    return false;
  };

  /**
   *
   */
  const onClose = () => {
    setTimeout(props.onClose, 350);
  };

  const license = props.license;

  const { update, updateError } = props.licenses;

  const loadingIndicator = update ? <LoadingIndicator /> : null;

  return (
    <div id="edit_license" className="modal custom-modal fade" role="dialog">
      <div className="modal-dialog">
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          onClick={onClose}
        >
          &times;
        </button>
        <div className="modal-content modal-md">
          <div className="modal-header">
            <h4 className="modal-title">Edit License</h4>
          </div>
          <div className="modal-body">
            <form className={"container"}>
              <div className={"row"}>
                <div className={"col-xs-9 col-sm-9"}>
                  <label htmlFor={"license_key"}>
                    License Key <span className="text-danger">*</span>
                  </label>
                </div>
                <div className={"col-xs-3 col-sm-3"}>
                  <label htmlFor={"license_cost"}>
                    Cost <span className="text-danger">*</span>
                  </label>
                </div>
              </div>
              <div className={"row"}>
                <div className={"col-xs-9 col-sm-9"}>
                  <div className="form-group">
                    <input
                      id="license_key"
                      className={
                        "form-control " +
                        (state.licenseKeyError ? "is-invalid" : "")
                      }
                      type="text"
                      placeholder="Coupon code"
                      defaultValue={license.license_key}
                    />
                  </div>
                </div>
                <div className={"col-xs-3 col-sm-3"}>
                  <div>
                    <input
                      id="license_cost"
                      className={
                        "form-control " +
                        (state.licenseCostError ? "is-invalid" : "")
                      }
                      type="number"
                      placeholder="$cost"
                      defaultValue={license.cost}
                    />
                  </div>
                </div>
              </div>
              <div className="m-t-20 text-center">
                <div className="form-group">
                  <button className="btn btn-primary btn-lg" onClick={onSubmit}>
                    Update
                  </button>
                </div>
              </div>

              <ApiError
                error={updateError}
                defaultErrorMessage={
                  "Could not update license key. Please try again"
                }
              />

              {loadingIndicator}
              <ApiError error={state.licenseKeyError} />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

EditModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  licenses: PropTypes.object.isRequired,
  license: PropTypes.shape({
    uuid: PropTypes.string,
  }).isRequired,
};

export default EditModal;
