/**
 * Created by piotr.pozniak@thebeaverhead.com on 18/05/2024
 */

import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import useDidMountEffect from "../../../../../hooks/useDidMountEffect";
import { firstDayOfWeek, formatDate } from "../../../../../helpers/date";
import sleep from "../../../../../helpers/sleep";
import WeekListItem from "./WeekListItem";

const WeeklistDropdown = ({
  currentWeek,
  showUpcomingEventsOnly,
  onChangeWeek,
  startDate,
  endDate,
}) => {
  const [showWeeksDropdown, setShowWeeksDropdown] = useState(false);

  const [state, setState] = useState({
    scrollTop: false,
    scrollBottom: false,
    scrollTopPreflight: false,
    weekListStart:
      startDate && endDate
        ? firstDayOfWeek(new Date(startDate))
        : showUpcomingEventsOnly
        ? firstDayOfWeek(new Date(currentWeek))
        : firstDayOfWeek(new Date(currentWeek).subtractWeeks(2)),
  });

  const totalWeeks = useMemo(() => {
    if (!startDate || !endDate) return 0;
    return Math.ceil((endDate - startDate) / (7 * 24 * 60 * 60 * 1000));
  }, [startDate, endDate]);

  const canScrollWeeksTop = useMemo(
    () =>
      (!showUpcomingEventsOnly ||
        (showUpcomingEventsOnly &&
          state.weekListStart.diffWeeks(firstDayOfWeek(new Date())) > 4)) &&
      (!(startDate && endDate) ||
        (totalWeeks > 5 &&
          !new Date(state.weekListStart).isSameDay(
            firstDayOfWeek(new Date(startDate))
          ))),
    [
      showUpcomingEventsOnly,
      state.weekListStart,
      startDate,
      endDate,
      showWeeksDropdown,
      totalWeeks,
    ]
  );

  useDidMountEffect(() => {
    if (state.scrollTop || state.scrollBottom) {
      setState((prev) => ({ ...prev, scrollTopPreflight: true }));

      sleep(0)
        .then(() => {
          setState((prev) => ({ ...prev, scrollTopPreflight: false }));
          return sleep(600);
        })
        .then(() => {
          setState((prev) => ({
            ...prev,
            scrollTop: false,
            scrollBottom: false,
            weekListStart: new Date(prev.weekListStart).addWeeks(
              prev.scrollTop ? -5 : 5
            ),
          }));
        });
    }
  }, [state.scrollBottom, state.scrollTop]);

  /**
   *
   * @param week {Date}
   * @returns {function(*): void}
   */
  const onClickWeekList = (week) => (e) => {
    // if showing only upcoming events, the list should from current week.
    // Current week is always first week to render then. It means it should align
    // subtracted weeks for this case

    const weeksToSubtract = !showUpcomingEventsOnly
      ? 2
      : week.diffWeeks(firstDayOfWeek(new Date()));

    setShowWeeksDropdown(false);
    setState((prev) => ({
      ...prev,
      weekListStart: firstDayOfWeek(new Date(week)).subtractWeeks(
        weeksToSubtract > 2 ? 2 : weeksToSubtract
      ),
    }));

    onChangeWeek(week);
  };

  const onMoverClick = (direction) => (e) => {
    setState((prev) => {
      const data = { ...prev, [direction]: true };

      if (data.scrollTop) {
        data.scrollTopPreflight = true;
      }
      return data;
    });
  };

  const weekListItems = useMemo(() => {
    if (startDate && endDate) {
      const weeks = [];
      let weekStart = new Date(state.weekListStart);

      if (state.scrollBottom || state.scrollTop) {
        const scrollFactor = state.scrollTop ? -5 : 0;
        weekStart.setDate(weekStart.getDate() + scrollFactor * 7);
      }

      while (weekStart <= endDate) {
        const weekEnd = new Date(weekStart);
        weekEnd.setDate(weekStart.getDate() + 6);

        if (
          (weekStart >= startDate && weekStart <= endDate) ||
          (weekEnd >= startDate && weekEnd <= endDate) ||
          (weekStart < startDate && weekEnd > endDate)
        ) {
          weeks.push(new Date(weekStart));
        }

        weekStart.setDate(weekStart.getDate() + 7);
      }

      return weeks.map((weekStart) => (
        <WeekListItem
          key={weekStart.getTime()}
          weekStart={weekStart}
          currentWeek={currentWeek}
          onClickWeekList={onClickWeekList}
        />
      ));
    } else {
      return Array(state.scrollBottom || state.scrollTop ? 10 : 5)
        .fill()
        .map((i, idx) => {
          const subtractFactor = state.scrollTop ? -5 : 0;

          const weekList = new Date(state.weekListStart).addWeeks(
            idx + subtractFactor
          );

          return (
            <WeekListItem
              key={weekList.getDate()}
              weekStart={weekList}
              currentWeek={currentWeek}
              onClickWeekList={onClickWeekList}
            />
          );
        });
    }
  }, [
    startDate,
    endDate,
    state.weekListStart,
    state.scrollBottom,
    state.scrollTop,
    currentWeek,
    onClickWeekList,
    showWeeksDropdown,
  ]);

  const canScrollWeeksBottom = useMemo(
    () =>
      !(startDate && endDate) ||
      Math.abs(state.weekListStart.diffWeeks(endDate)) > 5,
    [state.weekListStart, endDate, totalWeeks, startDate]
  );

  const listOfWeeks = useMemo(
    () => (
      <div
        className={classnames("date-range-selector-week-dropdown-list", {
          show: showWeeksDropdown,
        })}
      >
        <div
          className={"date-range-selector-week-dropdown-list-mover"}
          onClick={canScrollWeeksTop ? onMoverClick("scrollTop") : null}
        >
          {canScrollWeeksTop ? (
            <i className={"material-icons"}>expand_less</i>
          ) : null}
        </div>
        <div className={"date-range-selector-week-dropdown-list-container"}>
          <ul
            className={classnames({
              scrollTop: !state.scrollTopPreflight && state.scrollTop,
              scrollBottom: state.scrollBottom,
              scrollTopPreflight: state.scrollTopPreflight,
            })}
          >
            {weekListItems}
          </ul>
        </div>
        <div
          className={"date-range-selector-week-dropdown-list-mover"}
          onClick={canScrollWeeksBottom ? onMoverClick("scrollBottom") : null}
        >
          {canScrollWeeksBottom &&
          (!(startDate && endDate) || totalWeeks > 5) ? (
            <i className={"material-icons"}>expand_more</i>
          ) : null}
        </div>
      </div>
    ),
    [
      showWeeksDropdown,
      canScrollWeeksBottom,
      startDate,
      endDate,
      totalWeeks,
      weekListItems,
      canScrollWeeksTop,
      state.scrollTopPreflight,
      state.scrollBottom,
      state.scrollTop,
    ]
  );

  /**
   *
   */
  const onMouseEnterWeekDropdown = () => {
    setShowWeeksDropdown(true);
  };

  /**
   *
   */
  const onMouseLeaveWeekDropdown = () => {
    setShowWeeksDropdown(false);
  };

  return (
    <div
      className={"date-range-selector-week-dropdown-controls"}
      onMouseLeave={onMouseLeaveWeekDropdown}
      onMouseEnter={onMouseEnterWeekDropdown}
    >
      <i className={"material-icons"}>unfold_more</i>
      {listOfWeeks}
    </div>
  );
};

WeeklistDropdown.defaultProps = {};

WeeklistDropdown.propTypes = {
  currentWeek: PropTypes.object.isRequired,
  showUpcomingEventsOnly: PropTypes.bool,
  onChangeWeek: PropTypes.func.isRequired,
};

export default WeeklistDropdown;
