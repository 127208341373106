/**
 * Created by piotr.pozniak@thebeaverhead.com on 21/08/2018.
 */

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import PageHeader from "./PageHeader";
import BreadcrumbBar from "../../component/BreadcrumbBar";
import classNames from "classnames";
import { useUserStore } from "../../../../hooks/redux/user";
import SidebarMenu from "./SidebarMenu";
import { updateJquery } from "../../tools";

const Index = (props) => {
  const { user } = useUserStore();

  useEffect(() => {
    updateJquery();

    return () => {
      updateJquery();
    };
  }, []);

  const content = props.title ? (
    <div
      className={classNames(
        "page-wrapper page-wrapper-with-breadcrumbs",
        props.className
      )}
    >
      <div className="content container-fluid">
        <BreadcrumbBar
          title={props.title}
          icon={props.icon}
          sidebarId={props.sidebarId}
          controls={props.headerControls}
        />

        {props.children}
      </div>
    </div>
  ) : (
    props.children
  );

  return (
    <div className="main-wrapper">
      <PageHeader />
      <div className="sidebar" id="sidebar">
        <div className="sidebar-inner slimscroll">
          <SidebarMenu sidebarId={props.sidebarId} />
        </div>
      </div>

      {content}
    </div>
  );
};

Index.defaultProps = {
  className: "",
};

Index.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  sidebarId: PropTypes.string,
  icon: PropTypes.string,
  headerControls: PropTypes.array,
  className: PropTypes.string,
};

export default Index;
