/**
 * Created by piotr.pozniak@thebeaverhead.com on 06/02/2019.
 */

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { Link, withRouter } from "react-router-dom";
import PageTemplate from "./../templates/PageTemplate";
import TableRow from "./CouponsPage/TableRow";
import LoadingIndicator from "../../calendar/components/LoadingIndicator";
import EditModal from "./CouponsPage/EditModal";
import ApiError from "../component/ApiError";
import SuccessAlert from "../component/SuccessAlert";
import { deleteCalendar, updateCalendar } from "../../../actions/calendar";
import { fetchCalendars } from "../../../actions/calendars";
import { clearErrors } from "../../../actions/main";
import { login } from "../../../actions/user";
import {
  deleteCoupon,
  fetchCoupons,
  updateCoupon,
} from "../../../actions/coupons";

const CouponsPage = (props) => {
  const [state, setState] = useState({
    editModalKey: new Date() * 1,
    coupon: { uuid: null },
  });

  useEffect(() => {
    props.clearErrors();
    props.fetchCoupons(1);

    return () => {
      window.$("#add_coupon").modal("hide");
      props.clearErrors();
    };
  }, []);

  useEffect(() => {
    if (state.coupon && state.coupon.uuid != null) {
      window.$("#add_coupon").modal("show");
    }
  }, [state.coupon]);

  /**
   *
   * @param user
   */
  const onCouponDelete = (coupon) => {
    if (
      window.confirm(
        "Are you sure you want to delete cupon '" + coupon.code + "'?"
      )
    ) {
      props.deleteCoupon(coupon.uuid);
    }
  };

  /**
   *
   * @param user
   */
  const onShowEditCoupon = (coupon) => {
    setState({
      coupon,
      editModalKey: new Date() * 1,
    });
  };

  /**
   *
   * @returns {function(*): boolean}
   */
  const onClickAddCoupon = (e) => {
    e.preventDefault();
    onShowEditCoupon({ uuid: "new" });
    return false;
  };

  /**
   *
   */
  const onEdit = (coupon) => {
    props.updateCoupon(
      coupon.uuid && coupon.uuid !== "new" ? coupon.uuid : null,
      coupon
    );
  };

  /**
   *
   */
  const onClosEditModal = () => {
    setState({ coupon: { uuid: null }, editModalKey: new Date() * 1 });

    props.clearErrors();
  };

  const coupons = props.coupons;
  const loading = coupons.fetch || coupons.delete;

  const couponsList = coupons.collection.map((item, idx) => {
    return (
      <TableRow
        disabled={loading}
        key={idx}
        coupon={item}
        onDelete={onCouponDelete}
        onEdit={onShowEditCoupon}
      />
    );
  });

  const loadingIndicator = loading ? <LoadingIndicator /> : null;

  const couponsTable = coupons.collection.length ? (
    <table className="table table-striped custom-table">
      <thead>
        <tr>
          <th>Start</th>
          <th>End</th>
          <th>Duration (days)</th>
          <th>Code</th>
          <th>Price</th>
          <th className="text-right">Action</th>
        </tr>
      </thead>
      <tbody>{couponsList}</tbody>
    </table>
  ) : null;
  return (
    <PageTemplate
      sidebarId="coupons"
      title={"Coupons"}
      icon={"local_activity"}
      headerControls={[
        <a
          href="#"
          className="btn btn-primary btn-rounded pull-right d-flex"
          onClick={onClickAddCoupon}
        >
          <i className="material-icons">add</i> Add Coupon
        </a>,
      ]}
    >
      <div className="row">
        <div className="col-md-12">
          <div className="table-responsive">
            {couponsTable}
            {loadingIndicator}
          </div>
        </div>
      </div>
      <EditModal
        key={"em-" + state.editModalKey}
        coupon={state.coupon}
        coupons={coupons}
        onEdit={onEdit}
        onClose={onClosEditModal}
        subscriptionPlans={props.subscriptionPlans}
      />
    </PageTemplate>
  );
};

const mapStateToProps = (store) => ({
  coupons: store.coupons,
  subscriptionPlans: store.subscriptionPlans,
});

const mapDispatchToProps = (dispatch) => ({
  clearErrors: () => dispatch(clearErrors()),
  fetchCoupons: () => dispatch(fetchCoupons()),
  deleteCoupon: (uuid) => dispatch(deleteCoupon(uuid)),
  updateCoupon: (uuid, data) => dispatch(updateCoupon(uuid, data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CouponsPage));
