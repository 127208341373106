/**
 * Created by piotr.pozniak@thebeaverhead.com on 09/11/2021.
 */

import React from "react";
import PropTypes from "prop-types";
import DesignTooltip from "../Builder/Input/DesignTooltip";
import CrownIcon from "../CrownIcon";

const RadioInput = (props) => {
  const tooltip = props.tooltip ? <DesignTooltip help={props.tooltip} /> : null;

  const crown = props.isPremium ? (
    <CrownIcon
      className={"m-r-5 small"}
      tooltipMessage={
        "This is a Premium Feature. You will need to Upgrade to use it. Upgrade Now"
      }
    />
  ) : null;

  return (
    <div className="design-element checkbox">
      <label className={"option-label"}>
        {crown}
        {props.label}
        {tooltip}
      </label>

      <div className="material-switch pull-right">
        <input
          id={props.fieldName}
          type="checkbox"
          checked={props.checked}
          onChange={props.onChange(props.fieldName)}
        />
        <label htmlFor={props.fieldName} className="badge-primary" />
      </div>
    </div>
  );
};

RadioInput.propTypes = {
  fieldName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]).isRequired,
  tooltip: PropTypes.shape({
    help: PropTypes.string,
    url: PropTypes.string,
  }),
  isPremium: PropTypes.bool,
};

export default RadioInput;
