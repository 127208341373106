/**
 * Created by piotr.pozniak@thebeaverhead.com on 23/11/2022.
 */

import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import Tos from "../templates/PageTemplate/Tos";
import { useUserStore } from "../../../hooks/redux/user";
import AxiosConfig from "./../../../AxiosConfig";
import LoadingIndicator from "./../../calendar/components/LoadingIndicator";
import ApiError from "./../component/ApiError";
import classnames from "classnames";
import useAuthHook from "./../hooks/useAuthHook";
import AccountNotFound from "./AccountVerificationPage/AccountNotFound";
import SignUpForm from "./AccountVerificationPage/SignUpForm";
import appConfig from "../../../appConfig";
import { useOrganizationMembersStore } from "../../../hooks/redux/organizationMembers";

const AccountVerificationPage = (props) => {
  const history = useHistory();
  const [showLoading, setShowLoading] = useState(true);
  const [showNoAccount, setShowNoAccount] = useState(false);
  const [authError, setAuthError] = useState(null);

  // flow can be either "login" or "register"
  const [flow, setFlow] = useState("login");
  const { user, checkSession } = useUserStore();
  const [flowParams, setFlowParams] = useState();

  const { organizationMembers, checkInvitation } =
    useOrganizationMembersStore();
  const { invitationOrganizationData } = organizationMembers;

  const [showForm, setShowForm] = useState(false);
  const [oAuthData, setOAuthData] = useState(null);

  const { isLoading: authLoading, authError: apiError } = useAuthHook();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    const token = params.get("token");
    const error = params.get("error");
    const accessToken = params.get("access_token");
    let _flow = params.get("flow");

    if (!_flow) {
      return () => {};
    }

    const _params = _flow ? _flow.split("&") : [];

    if (_params.length > 1) {
      _flow = _params.shift();
    }

    const flowParams = _params
      .map((item) => {
        const [key, value] = item.split("=");
        const param = {};
        param[key] = value;
        return param;
      })
      .reduce((acc, item) => {
        return { ...acc, ...item };
      }, {});

    setFlowParams(flowParams);
    setFlow(_flow);

    if (error && error !== "") {
      setAuthError(error);
    } else if (token && token !== "" && !user.checkSession) {
      AxiosConfig.setAuthToken(token);
      checkSession();
    } else if (accessToken && accessToken !== "") {
      setOAuthData({
        accessToken,
        refreshToken: params.get("refresh_token"),
        expiresIn: params.get("expires_in"),
        email: params.get("email"),
        firstName: params.get("first_name"),
        lastName: params.get("last_name"),
        organization_uuid: flowParams.organization_uuid,
      });

      if (flowParams && flowParams.invitationHash) {
        checkInvitation({
          invitationHash: flowParams.invitationHash,
          email: params.get("email"),
        });
      }

      if (_flow === "register") {
        setShowForm(true);
      } else {
        setShowNoAccount(true);
      }
    }

    if (_flow === "register" && flowParams && flowParams.invitationHash) {
      setShowForm(false);
      setShowLoading(true);
    } else {
      setShowLoading(false);
    }

    history.replace("/account-verification");
  }, [window.location.search]);

  useEffect(() => {
    if (organizationMembers.checkInvitationSuccess) {
      setShowLoading(false);
      setShowForm(true);
      setOAuthData({
        ...oAuthData,
        organizationName: organizationMembers.invitationOrganizationData.name,
        organizationUuid: organizationMembers.invitationOrganizationData.uuid,
      });
    }
  }, [organizationMembers.checkInvitationSuccess]);

  useEffect(() => {
    if (user.model && ["users", "admins"].indexOf(user.model.role) >= 0) {
      history.replace("/dashboard");
    }
  }, [user.checkSessionSuccess]);

  const indicator = useMemo(() => {
    return showLoading ? (
      <div className={""}>
        <LoadingIndicator />
      </div>
    ) : null;
  }, [showLoading]);

  let title = "Loading...";

  if (showNoAccount || authError) {
    title = "Something went wrong";
  }
  if (showForm) {
    title = "Create account";
  }

  const onCreateAccount = useCallback(() => {
    setShowForm(true);
  }, [showForm]);

  const accountNotFound =
    showNoAccount && !showForm ? (
      <AccountNotFound
        email={oAuthData.email}
        onCreateAccount={onCreateAccount}
      />
    ) : null;

  const registerForm = showForm ? (
    <SignUpForm
      oAuthData={oAuthData}
      submitBtnText={
        invitationOrganizationData && invitationOrganizationData.name
          ? `JOIN ${invitationOrganizationData.name.toUpperCase()}`
          : false
      }
    />
  ) : null;

  const error = authError ? (
    <div>
      <ApiError error={authError} defaultErrorMessage={authError} />
      <div className={"text-center m-b-15"}>
        <Link to={"/login"} className={"text-primary"}>
          Go back to login
        </Link>
      </div>
    </div>
  ) : null;

  return (
    <div className="main-wrapper">
      <div className="account-page">
        <div className="container">
          <div className="account-logo">
            <Link to="/">
              <img
                src={`/img/branding/${appConfig.branding}/full_length_logo.png`}
                alt={appConfig.displayName}
              />
            </Link>
          </div>
          <div className="account-box">
            <div className="account-wrapper">
              <h3 className="account-title">{title}</h3>

              {indicator}
              {accountNotFound}
              {registerForm}
              {error}
            </div>
          </div>
          <Tos />
        </div>
      </div>
    </div>
  );
};

export default AccountVerificationPage;
