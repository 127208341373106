/**
 * Created by piotr.pozniak@thebeaverhead.com on 16/05/2024
 */

import React, { useContext } from "react";
import PropTypes from "prop-types";
import WidgetSettingsContext from "../../../../contexts/WidgetSettingsContext";
import appConfig from "../../../../appConfig";
import { getUploadResourceUrl } from "../../../../helpers/url";
import Logo from "./Logo";
import BackButton from "./BackButton";

/**
 *
 */
const isHostedPage = () => {
  const hostedPages = [
    `my.${appConfig.domain}`,
    `dev.${appConfig.domain}`,
    `localhost`,
  ];

  return hostedPages.includes(window.location.hostname);
};

const BrandingHeader = ({ calendar, embedded }) => {
  const orgSettings = calendar.model?.organization?.settings;

  const orgLogo = getUploadResourceUrl(orgSettings?.logo);

  const widgetSettings = useContext(WidgetSettingsContext);
  const showLogo = widgetSettings["brandShowLogo"];
  const showBackButton = widgetSettings["brandShowBackButton"];
  const backButtonLabel = widgetSettings["brandBackButtonLabel"];
  const backButtonUrl = widgetSettings["brandBackButtonURL"];

  if (!embedded || !orgLogo || !isHostedPage()) {
    return null;
  }

  if (!showLogo && !showBackButton) {
    return null;
  }

  return (
    <div className={"dc-calendar-branding--header"}>
      <Logo show={showLogo} widget={calendar} />
      <BackButton
        show={showBackButton}
        label={backButtonLabel}
        url={backButtonUrl}
      />
    </div>
  );
};

BrandingHeader.defaultProps = {};

BrandingHeader.propTypes = {
  calendar: PropTypes.object,
  embedded: PropTypes.bool,
};

export default BrandingHeader;
