/**
 * Created by piotr.pozniak@thebeaverhead.com on 02/12/2021.
 */

import React, { useState, useContext, useCallback } from "react";
import PropTypes from "prop-types";
import IntegrationContext from "../../../../calendar/strategies/IntegrationContext";
import ExpandableAlert from "../../../component/ExpandableAlert";
import CrownIcon from "../../../component/CrownIcon";
import { PLANS } from "../../../../../consts";
import Filters from "../../../component/Filters/Filters";
import useFiltersHook from "../../../hooks/useFiltersHook";
import RadioInput from "../../../component/WidgetSettings/RadioInput";
import { useCurrentOrganizationStore } from "../../../../../hooks/redux/currentOrganization";
import Hr from "../../../component/Hr";
import { useBuilderStore } from "../../../../../hooks/redux/builder";
import { hasSubscription } from "../../../helpers/organization";
import FeaturedHighlights from "./FeaturedHighlights";
import Accordion from "../../../component/WidgetSettings/Acordion";

const getSummary = (settings, filters) => {
  if (!settings.hasFeaturedEvents) {
    return "";
  }

  let filtersSummary = "";
  if (!filters || (filters && !filters.groups.length)) {
    filtersSummary = "no events (apply filters to feature specific events). ";
  } else {
    const rule = filters.groups[0].rules[0];

    filtersSummary =
      " events matching " +
      rule.field.label +
      " " +
      rule.operator.label +
      " " +
      rule.label;

    if (filters.groups.length > 1 || filters.groups[0].rules.length > 1) {
      let filtersCount = -1;
      for (const gIdx in filters.groups) {
        const group = filters.groups[gIdx];
        filtersCount += group.rules.length;
      }
      filtersSummary += " and " + filtersCount + " more filters.";
    } else {
      filtersSummary += " filter.";
    }
  }

  const eventsHighlights = settings.hasEventsHighlights
    ? " Features events as highlights."
    : "";

  return (
    <span className={"d-flex"}>
      <i className={"material-icons text-info p-0 m-0"}>info</i>
      <span className={"m-1"}>
        {`Features ${filtersSummary} ${eventsHighlights}`}
      </span>
    </span>
  );
};

const FeaturedEventsFiltering = (props) => {
  const integrationStrategy = useContext(IntegrationContext);

  const { widgetModel } = props;
  const { calendar } = props;
  const { currentOrganization } = useCurrentOrganizationStore();

  const subscription = hasSubscription(currentOrganization);

  const hasFeaturedEvents = Boolean(widgetModel.has_featured_events);

  const { availableFilters } = useFiltersHook({
    widget: calendar,
    integrationStrategy,
  });

  /**
   *
   * @type {(function(*): void)|*}
   */
  const onUpdateFilters = useCallback(
    (filters) => {
      props.onUpdateFilterSettings("featured_filters", filters);
    },
    [widgetModel]
  );

  /**
   *
   * @type {function(*): function(*): void}
   */
  const onFilteringCheckboxChange = useCallback(
    (field) => (e) => {
      const isBoolean = typeof widgetModel[field] === "boolean";
      if (isBoolean) {
        return props.onUpdateField(field, !widgetModel[field]);
      }

      const newValue = !(widgetModel[field] * 1) ? 1 : 0;

      props.onUpdateField(field, newValue);
    },
    [widgetModel]
  );

  const isPremium =
    integrationStrategy.featuredAllowedPlans.indexOf(
      subscription.code || PLANS.free
    ) < 0;

  const premiumIcon = isPremium ? (
    <CrownIcon
      tooltipMessage={
        "This is a Premium Feature. You will need to Upgrade to use it."
      }
    />
  ) : null;

  const showInfoAlert = integrationStrategy.featuredFilterLabelCTA ? (
    <ExpandableAlert
      errorLevel={"info"}
      header={"How to use filters below?"}
      body={integrationStrategy.featuredFilterLabelCTA}
    />
  ) : null;

  const widgetFilters = widgetModel.filter_settings
    ? widgetModel.filter_settings.featured_filters
    : null;

  const content = hasFeaturedEvents ? (
    <React.Fragment>
      {showInfoAlert}

      <form action="#">
        <Filters
          availableFilters={availableFilters}
          filters={widgetFilters}
          onChange={onUpdateFilters}
        />
        <FeaturedHighlights />
      </form>
    </React.Fragment>
  ) : null;

  const subtitle = getSummary(
    {
      hasFeaturedEvents: hasFeaturedEvents,
      hasEventsHighlights:
        widgetModel.widget_settings &&
        widgetModel.widget_settings.has_feature_highlights,
    },
    (widgetModel.filter_settings &&
      widgetModel.filter_settings.featured_filters) ||
      null
  );

  return (
    <Accordion
      title={
        <span>
          Featured Events <span className={"badge badge-success"}>NEW</span>
        </span>
      }
      subtitle={subtitle}
    >
      <RadioInput
        fieldName={"has_featured_events"}
        label={"Show featured events"}
        onChange={onFilteringCheckboxChange}
        checked={hasFeaturedEvents}
      />
      {content}
    </Accordion>
  );
};

FeaturedEventsFiltering.propTypes = {
  widgetModel: PropTypes.object.isRequired,
  onUpdateFilterSettings: PropTypes.func.isRequired,
  onUpdateField: PropTypes.func.isRequired,
  onUpdateWidgetSettings: PropTypes.func.isRequired,
};

export default FeaturedEventsFiltering;
