/**
 * Created by piotr.pozniak@thebeaverhead.com on 05/07/2022
 */

import React from "react";
import PropTypes from "prop-types";

const Row = (props) => {
  const { icon, title } = props;

  const iconBlock = icon ? (
    <div className="col-icon">
      <i className="material-icons">{icon}</i>
    </div>
  ) : null;

  const titleBlock = title ? (
    <div className="">
      <h3 className="">{title}</h3>
    </div>
  ) : null;

  return (
    <div className={"dce-groups-modal-row--details"}>
      <div className="dce-groups-modal-row--details-row">
        {iconBlock}
        <div className="col-content ">
          {titleBlock}
          <div>{props.children}</div>
        </div>
      </div>
    </div>
  );
};

Row.defaultProps = {};

Row.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
};

export default Row;
