/**
 * Created by piotr.pozniak@thebeaverhead.com on 20/08/2018.
 */

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import isEmail from "validator/lib/isEmail";

import { Link } from "react-router-dom";
import SuccessAlert from "../component/SuccessAlert";
import ApiError from "../component/ApiError";
import FormError from "../component/FormError";
import classnames from "classnames";
import { updateJquery } from "../tools";
import { clearErrors } from "../../../actions/main";
import UnauthorizedUserMobileHelp from "../component/UnauthorizedUserMobileHelp";
import Tos from "../templates/PageTemplate/Tos";
import { useUserStore } from "../../../hooks/redux/user";
import appConfig from "../../../appConfig";

const PasswordReminderPage = (props) => {
  const [state, setState] = useState({
    email: "",
    emailError: null,
  });

  const { remindPassword } = useUserStore();

  const isSignedIn = props.user.model && props.user.model.uuid ? true : false;

  useEffect(() => {
    updateJquery();

    return props.clearErrors;
  }, []);

  /**
   *
   */
  const onLoginClick = async (e) => {
    e.preventDefault();

    props.clearErrors();

    if (!isEmail(state.email)) {
      return setState({
        ...state,
        emailError: "Please provide a valid email address.",
      });
    }

    setState({
      ...state,
      emailError: null,
    });

    await remindPassword(state.email);

    return false;
  };

  /**
   *
   * @param e
   */
  const onEmailChange = (e) => {
    setState({ ...state, email: e.target.value });
  };

  const isLoading = props.user.remindPassword;

  const title = isSignedIn ? "Change password" : "Forgot Password?";
  const backButtonLabel = isSignedIn ? "Back to Settings" : "Back to Login";
  const backButtonUrl = isSignedIn ? "/settings" : "/login";

  return (
    <div className="main-wrapper">
      <div className="account-page">
        <div className="container">
          <div className="account-logo">
            <Link to="/">
              <img
                src={`/img/branding/${appConfig.branding}/full_length_logo.png`}
                alt={appConfig.displayName}
              />
            </Link>
          </div>
          <div className="account-box">
            <div className="account-wrapper">
              <h3 className="account-title">{title}</h3>

              <form>
                <div className="form-group form-focus">
                  <label className="focus-label">Email</label>
                  <input
                    className={classnames("form-control floating", {
                      "is-invalid": state.emailError,
                    })}
                    type="text"
                    value={state.email}
                    autoFocus={true}
                    onChange={onEmailChange}
                    disabled={isLoading}
                  />
                </div>
                <div className="form-group text-center">
                  <button
                    className="btn btn-primary btn-block account-btn"
                    type="submit"
                    onClick={onLoginClick}
                    disabled={isLoading}
                  >
                    Reset Password
                  </button>
                </div>

                <FormError errors={[state.emailError]} />

                <ApiError
                  error={props.user.remindPasswordError}
                  defaultErrorMessage={"Could not send a reset password email."}
                />

                <SuccessAlert>
                  {props.user.remindPasswordSuccess
                    ? "We've sent an email with a reset password link. Please check your mailbox."
                    : null}
                </SuccessAlert>

                <div className="text-center">
                  <Link to={backButtonUrl}>{backButtonLabel}</Link>
                </div>

                <UnauthorizedUserMobileHelp />
              </form>
            </div>
          </div>
          <Tos />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (store) => ({
  main: store.main,
  user: store.user,
});

const mapDispatchToProps = (dispatch) => ({
  clearErrors: () => dispatch(clearErrors()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordReminderPage);
