/**
 * Created by piotr.pozniak@thebeaverhead.com on 05/12/2022
 */

import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import classnames from "classnames";

const AccountNotFound = (props) => {
  /**
   *
   * @type {(function(*): void)|*}
   */
  const onCreateAccount = useCallback((e) => {
    e.preventDefault();
    props.onCreateAccount();
  }, []);

  return (
    <div>
      <div>
        <p>Your Google login wasn't successful for some reason.</p>
        <div className={classnames("alert alert-danger show")} role="alert">
          <p>
            <strong>{props.email} email address does not exist.</strong>
          </p>
          <span>
            The email address you've entered doesn't appear to exist. Please
            check your entry and try again.
          </span>
        </div>
        <div className={"text-center m-b-15"}>
          <Link to={"/login"} className={"text-primary"}>
            Go back to login
          </Link>
        </div>
      </div>
      <h4 className={"or"}>or</h4>
      <div className="d-flex flex-column align-items-center">
        <button className={"btn btn-outline-success"} onClick={onCreateAccount}>
          Create a free account
        </button>
      </div>
    </div>
  );
};

AccountNotFound.defaultProps = {};

AccountNotFound.propTypes = {
  email: PropTypes.string,
  onCreateAccount: PropTypes.func.isRequired,
};

export default AccountNotFound;
