/**
 * Created by piotr.pozniak@thebeaverhead.com on 11/03/2024
 */

import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { useUserStore } from "../../../../../hooks/redux/user";
import { Link } from "react-router-dom";

const OrgSelector = (props) => {
  const { user, changeOrganization } = useUserStore();

  const onChangeOrganization = useCallback(
    (orgUUID) => (e) => {
      e.preventDefault();
      e.stopPropagation();

      if (orgUUID === user.model.current_org_uuid) {
        return;
      }

      props.onClose(e);
      changeOrganization(orgUUID);
    },
    []
  );

  const content = user.model?.organizations
    ? user.model.organizations
        // sort organizations alphabetically
        .sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        })
        // current organization first
        .sort((a, b) => {
          if (a.uuid === user.model.current_org_uuid) {
            return -1;
          }
          return 0;
        })
        .map((org) => (
          <div
            key={org.uuid}
            className={"nav-user-menu--dropdown-container__list--item"}
          >
            <a
              href={"/admin/organizations/" + org.uuid}
              onClick={onChangeOrganization(org.uuid)}
            >
              {org.name}
            </a>
          </div>
        ))
    : null;

  if (!content || content.length === 1) {
    return null;
  }

  return (
    <div className="nav-user-menu--dropdown-container">
      <div className={"nav-user-menu--dropdown-container-orgs"}>
        <div>Your organizations:</div>
        <div className={"nav-user-menu--dropdown-container__list"}>
          {content}
          {/*<div
            className={
              "nav-user-menu--dropdown-container__list--item nav-user-menu--dropdown-container__list--footer"
            }
          >
            <Link to={"#"}>Add another organization</Link>
          </div>*/}
        </div>
      </div>
    </div>
  );
};

OrgSelector.defaultProps = {};

OrgSelector.propTypes = {
  onClose: PropTypes.func,
};

export default OrgSelector;
