/**
 * Created by piotr.pozniak@thebeaverhead.com on 18/05/2024
 */

import React, { useContext } from "react";
import PropTypes from "prop-types";
import WidgetSettingsContext from "../../../../../contexts/WidgetSettingsContext";
import { useTranslation } from "react-i18next";

const ThisWeekButton = ({ currentWeek, onClick }) => {
  const widgetSettings = useContext(WidgetSettingsContext);
  const { t } = useTranslation();
  const allowChangingWeeks =
    Number.parseInt(widgetSettings["wwAllowChangingWeeks"]) === 1;

  if (!allowChangingWeeks || currentWeek.isSameWeek(new Date())) {
    return null;
  }

  return (
    <div className={"date-range-selector__this-week"} onClick={onClick}>
      {t("This week")}
    </div>
  );
};

ThisWeekButton.defaultProps = {};

ThisWeekButton.propTypes = {
  currentWeek: PropTypes.object,
  onClick: PropTypes.func,
};

export default ThisWeekButton;
