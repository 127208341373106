/**
 * Created by piotr.pozniak@thebeaverhead.com on 05/04/2022.
 */

import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Accordion from "./WidgetSettings/Acordion";

const NewWidgetWizard = (props) => {
  const { widgetName } = props;

  const WizardOptions = [
    {
      label: "Select template from the list",
      checked: props.hasSelectedWidget,
    },
    {
      label: "Name your new widget",
      checked: props.hasEnteredWidgetName,
    },
    {
      label: "Choose integration to connect to",
      checked: props.hasSelectedIntegration,
    },
  ];

  const options = WizardOptions.map((i, idx) => {
    return (
      <div
        key={"ncw-" + idx}
        className={classnames("task wizard-option", { completed: i.checked })}
      >
        <span className="task-action-btn task-check">
          <span className="action-circle large complete-btn">
            <i className="material-icons">check</i>
          </span>
        </span>
        <span className={"new-widget--sidebar-wizard_label"}>{i.label}</span>
      </div>
    );
  });

  return (
    <Accordion title={"New " + widgetName + " checklist"} keepExpanded={true}>
      <div className={"m-t-10 new-widget--sidebar-wizard"}>{options}</div>
    </Accordion>
  );
};

NewWidgetWizard.propTypes = {
  hasSelectedWidget: PropTypes.string,
  hasEnteredWidgetName: PropTypes.bool,
  hasSelectedIntegration: PropTypes.bool,
  widgetName: PropTypes.string.isRequired,
  widgetType: PropTypes.string.isRequired,
};

export default NewWidgetWizard;
