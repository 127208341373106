/**
 * Created by piotr.pozniak@thebeaverhead.com on 23/10/2018.
 */

import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import PageTemplate from "./../templates/PageTemplate";

import { store } from "../store";
import SidebarTab from "../component/WidgetSettings/SidebarTab";
import SuccessAlert from "../component/SuccessAlert";
import ApiError from "../component/ApiError";
import { groupSettingsValidator, updateJquery } from "../tools";
import BreadcrumbBar from "../component/BreadcrumbBar";
import { useGroupStore } from "./../../../hooks/redux/group";
import { useIntegrationsStore } from "./../../../hooks/redux/integrations";
import { useUserStore } from "./../../../hooks/redux/user";
import { useGroupsStore } from "./../../../hooks/redux/groups";
import MandatoryInformation from "./GroupPage/SettingsTabs/MandatoryInformation";

const AttachIntegrationPage = (props) => {
  const { group, updateGroup, setGroup, fetchGroup } = useGroupStore();
  const { integrations } = useIntegrationsStore();
  const { user } = useUserStore();
  const { groups } = useGroupsStore();
  const loading = group.update || group.fetch || group.delete;

  const history = useHistory();

  const [state, setState] = useState({
    groupModel: {
      uuid: "",
      name: "",
      available: 0,
      integration_uuid: 0,
      template: "",
      show_private_events: 0,
      featured_events_limit: 0,
    },
    errors: {},
    initialized: false,
  });

  useEffect(() => {
    if (!state.initialized) {
      setState({ ...state, initialized: true });
    }
  }, []);

  useEffect(() => {
    if (state.initialized) {
      fetchGroup(props.match.params.uuid);
    }
  }, [state.initialized]);

  useEffect(() => {
    if (group.fetchSuccess) {
      setState({
        ...state,
        groupModel: {
          uuid: group.model.uuid,
          name: group.model.name,
          available: group.model.available,
          integration_uuid: null,
          template: group.model.template,
          show_private_events: group.model.show_private_events,
          featured_events_limit: group.model.featured_events_limit,
        },
      });
    }
  }, [group.fetchSuccess]);

  /**
   *
   * @param fieldName
   * @param value
   */
  const onUpdateField = (fieldName, value) => {
    const data = {
      ...state,
    };

    data.groupModel[fieldName] = value;

    data.errors = groupSettingsValidator(data.groupModel);

    setState(data);
  };

  useEffect(() => {
    if (groups.fetchSuccess) {
      setTimeout(updateJquery, 200);
    }

    /*    if (props.events.fetchSuccess) {
      setTimeout(() => window.$('[data-toggle="tooltip"]').tooltip(), 500);
    }*/
  }, [
    group.fetchSuccess,
    groups.fetchSuccess,
    //props.events.fetchSuccess, // ??
  ]);

  useEffect(() => {
    window.$(".chat-main-row").animate({ scrollTop: 0 }, 200);

    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [state.groupModel.template]);

  useEffect(() => {
    return () => setGroup(null);
  }, []);

  /**
   *
   */
  const onSubmit = async () => {
    const data = { ...state.groupModel };

    await updateGroup(state.groupModel.uuid, data);

    const storeState = store.getState();

    if (storeState.group.updateSuccess) {
      await setGroup(null);

      history.push("/group/" + state.groupModel.uuid);
    }
  };

  const settingsTabsOptions = [];

  settingsTabsOptions.push({
    label: "Setup",
    id: "setup_tab",
    additionalClassName: "active show",
  });

  const settingsTabs = settingsTabsOptions.map((item, idx) => {
    return (
      <li key={"settings-tab-" + idx} className="nav-item">
        <a
          className={"nav-link " + item.additionalClassName}
          href={"#" + item.id}
          data-toggle="tab"
        >
          {item.label}
        </a>
      </li>
    );
  });

  const footer = !loading ? (
    <div>
      <SuccessAlert>
        {group.updateSuccess ? "Your changes have been saved." : null}
      </SuccessAlert>
      <group
        error={group.updateError}
        defaultErrorMessage={"Could not save your settings. Please try again"}
      />
      <div className="text-right">
        <button
          disabled={group.disabled}
          type="submit"
          className="btn btn-primary"
          onClick={onSubmit}
        >
          Update
        </button>
      </div>
    </div>
  ) : null;

  const setupTab = (
    <SidebarTab id="setup_tab" show={true} footer={footer}>
      <MandatoryInformation
        groupModel={state.groupModel || {}}
        onUpdateField={onUpdateField}
        disabled={loading}
        formErrors={state.errors}
        integrations={integrations.collection}
        expanded={true}
        showSummary={false}
      />
    </SidebarTab>
  );

  const header = group.model ? group.model.name : "";

  const subHeader = "";

  return (
    <PageTemplate title={null} sidebarId={"groups"}>
      <div className="page-wrapper page-wrapper-with-breadcrumbs">
        <div className="content container-fluid">
          <BreadcrumbBar title={"Groups"} icon={"group"} sidebarId={"groups"} />
        </div>
        <div className="chat-main-row margin-small">
          <div className="chat-main-wrapper">
            <div className="col-xs-12 message-view task-view">
              <div className="chat-window">
                <div className="chat-contents calendar-background">
                  <div className="chat-content-wrap">
                    <div className="chat-wrap-inner">
                      <div className="chat-box">
                        <div className="chats">
                          <div className="calendar-preview-container">
                            <div className="content container-fluid">
                              <div className="row">
                                <div className="col-sm-12">
                                  <h4 className="page-title">&nbsp;</h4>
                                </div>
                              </div>
                              <div className="card-box">
                                <h2>Attach integration to your widget</h2>
                                <p>
                                  Your widget does not have an integration
                                  attached because you disconnected the
                                  integration.
                                </p>

                                <p>
                                  <strong>Option 1:</strong> Reconnect with your
                                  previous integration via the Integrations page
                                  using the same account details. Then,
                                  re-attach this widget to that integration. If
                                  you use the same account details, all your
                                  previous widget filters and design settings
                                  will be maintained.
                                </p>
                                <p>
                                  <strong>Option 2:</strong> Reconnect with your
                                  previous integration via the Integrations page
                                  using different account details OR select a
                                  different integration. Then, re-attach this
                                  widget to that integration. In either case,
                                  all your previous widget filters and design
                                  settings will be removed.
                                </p>
                                <div className="row"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-lg-3 message-view chat-profile-view chat-sidebar"
              id="chat_sidebar"
            >
              <div className="chat-window video-window">
                <div className="fixed-header">
                  <ul className="nav nav-tabs nav-tabs-bottom">
                    {settingsTabs}
                  </ul>
                </div>
                <div className="tab-content chat-contents">{setupTab}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="sidebar-overlay" data-reff="#chat_sidebar" />
    </PageTemplate>
  );
};

export default AttachIntegrationPage;
