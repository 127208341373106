/**
 * Created by piotr.pozniak@thebeaverhead.com on 15/06/2022
 */

import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { rrulestr } from "rrule";
import { fromRRuleOutput } from "../../../../helpers/calendar";
import { useTranslation } from "react-i18next";

const scheduleFormatter = Intl.DateTimeFormat(navigator.language, {
  hour: "numeric",
  minute: "numeric",
  hour12: true,
});

const monthFormatter = Intl.DateTimeFormat(navigator.language, {
  month: "2-digit",
});

const dayFormatter = Intl.DateTimeFormat(navigator.language, {
  day: "2-digit",
});

const headerFormatter = Intl.DateTimeFormat(navigator.language, {
  dateStyle: "full",
});
const utcOffset = new Date().getTimezoneOffset();

const adjustEventLocalTz = (date) => {
  if (!date) {
    return null;
  }

  //const dstDiff = date.getTimezoneOffset() - utcOffset;
  //date.setMinutes(date.getMinutes() + dstDiff);
  // return fromRRuleOutput(date); //moment(date).toDate();

  // looks like this adjustment is needed
  // const dstDiff = date.getTimezoneOffset() - utcOffset;
  // date.setMinutes(date.getMinutes() + dstDiff);

  return fromRRuleOutput(date); //moment(date).toDate();
};

const RecurrenceSeriesTable = (props) => {
  const { numberOfNextEvents } = props;
  const { t } = useTranslation();

  const rule = useMemo(
    () => (props.series ? rrulestr(props.series) : null),
    [props.series, props.numberOfNextEvents]
  );

  const eventsGroupedByDays = {};

  const nextEvents = useMemo(
    () =>
      rule
        .all((date, i) => {
          return i < numberOfNextEvents;
        })
        // adjust TZ
        .map((i) => {
          return adjustEventLocalTz(i);
        }),
    [props.series, props.numberOfNextEvents]
  );

  // if rule has until date, generate next date after the last generated date to learn whether there is more events in this series
  const eventAfterLastGeneratedEvent =
    nextEvents.length && rule.options.until
      ? adjustEventLocalTz(
          rule.after(
            new Date(
              nextEvents[nextEvents.length - 1].getTime() + 1000 * 24 * 60 * 60
            ),
            false
          )
        )
      : null;

  // avoid using rule.count() as it is slow. use it only for events that have until set (hopefully a few of them)
  const hasMore =
    // until and count is not set - repeats infinitely
    (!rule.options.until && !rule.options.count) ||
    // no count set but has until date and until last generated date is lower than next possibly generated date
    (!rule.options.count &&
      rule.options.until &&
      eventAfterLastGeneratedEvent &&
      nextEvents[nextEvents.length - 1].getTime() <
        eventAfterLastGeneratedEvent.getTime()) ||
    // has count set and count is created than the number of generated events
    (!rule.options.until &&
      rule.options.count &&
      rule.count() >= numberOfNextEvents);

  nextEvents.map((curr) => {
    const day = `${curr.getFullYear()}${monthFormatter.format(
      curr
    )}${dayFormatter.format(curr)}`;

    if (!eventsGroupedByDays[day]) {
      eventsGroupedByDays[day] = [];
    }
    eventsGroupedByDays[day].push(curr);
  });

  const eventsSchedule = nextEvents
    ? Object.keys(eventsGroupedByDays).map((key, idx) => {
        const daySchedule = eventsGroupedByDays[key];
        const firstDay = new Date(daySchedule[0]);

        const schedule = daySchedule.map((i, jdx) => {
          return (
            <li
              className="dce-recurrence-event-time"
              key={`red-${props.eventId}-${idx}${jdx}`}
            >
              <p>
                <em className="dce-recurrence-time">
                  {scheduleFormatter.format(i)}
                </em>
                <span className="dce-recurrence-description">
                  <b>{props.eventName}</b>
                </span>
              </p>
            </li>
          );
        });

        return (
          <div
            className="event-row--details-row__recurrence"
            key={`resd-${props.eventId}-${idx}`}
          >
            <ul className="event-row--details-row__recurrence dce-recurrence-ul">
              <li className="dce-recurrence-header">
                {headerFormatter.format(firstDay)}
              </li>
              {schedule}
            </ul>
          </div>
        );
      })
    : null;

  const loadMoreBtn = hasMore ? (
    <button className={"dce-recurrence--more-btn"} onClick={props.onShowMore}>
      {t("Show more")}
    </button>
  ) : null;

  return (
    <React.Fragment>
      <p>
        <strong>{t("Next events in this series")}:</strong>
      </p>
      {eventsSchedule}
      {loadMoreBtn}
    </React.Fragment>
  );
};

RecurrenceSeriesTable.defaultProps = {};

RecurrenceSeriesTable.propTypes = {
  onShowMore: PropTypes.func,
  eventName: PropTypes.string,
  series: PropTypes.string,
  eventId: PropTypes.string,
};

export default RecurrenceSeriesTable;
