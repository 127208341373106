/**
 * Created by piotr.pozniak@thebeaverhead.com on 24/09/2021.
 */

import React, { useMemo } from "react";
import { PLANS } from "../../../../consts";
import { Link } from "react-router-dom";
import { useCurrentOrganizationStore } from "../../../../hooks/redux/currentOrganization";
import {
  hasSubscription,
  hasTrialFinished as _hasTrialFinished,
} from "../../helpers/organization";
import { useUserStore } from "../../../../hooks/redux/user";

const UpgradePlanInfo = (props) => {
  const { currentOrganization } = useCurrentOrganizationStore();
  const { user } = useUserStore();

  const subscription = useMemo(
    () => hasSubscription(currentOrganization),
    [currentOrganization.model]
  );

  const hasTrialFinished = _hasTrialFinished(currentOrganization);
  // user's trial may have expired, but they may not have a free plan yet
  const isBeforeApplyingFreePlan =
    hasTrialFinished && subscription && subscription.code !== PLANS.free
      ? true
      : false;

  if (
    (subscription && subscription.code !== PLANS.free) ||
    (user.model && !user.model.is_account_verified)
  ) {
    return null;
  }

  const message = isBeforeApplyingFreePlan
    ? "Trial has expired."
    : "You are currently using the free version.";

  return (
    <div className="alert alert-warning">
      <p>{message}</p>
      <Link to={"/billing"}>
        <img
          src={"/img/crown.png"}
          className={"crown"}
          alt={"Upgrade plan - crown icon"}
        />{" "}
        Upgrade plan
      </Link>
      .
    </div>
  );
};

export default UpgradePlanInfo;
