/**
 * Created by piotr.pozniak@thebeaverhead.com on 23/02/2024
 */

import React from "react";
import PropTypes from "prop-types";

const FeatureSearch = ({ value, onChange, onClear }) => {
  const clearButton = value.length ? (
    <span className="input-group-append">
      <button
        className="btn d-flex align-items-center"
        type="button"
        onClick={onClear}
      >
        <i className="material-icons icon-sm" aria-hidden={true}>
          close
        </i>
      </button>
    </span>
  ) : null;

  return (
    <div className="design-search-box">
      <div className="input-group input-group-sm">
        <input
          type="text"
          className="form-control"
          placeholder="Settings search..."
          value={value}
          onChange={onChange}
        />
        {clearButton}
      </div>
    </div>
  );
};

FeatureSearch.defaultProps = {
  value: "",
};

FeatureSearch.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
};

export default FeatureSearch;
