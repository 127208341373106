/**
 * Created by piotr.pozniak@thebeaverhead.com on 28/09/2023.
 */

import React, { useCallback, useMemo } from "react";

import PropTypes from "prop-types";
import {
  CALENDAR_TEMPLATES_PLANS,
  INTEGRATION_NAMES,
  IT,
  PLANS,
  WIDGET_TEMPLATES_PLANS,
} from "../../../../consts";
import { useUserStore } from "../../../../hooks/redux/user";
import CrownIcon from "../../component/CrownIcon";
import { Link } from "react-router-dom";
import { useCurrentOrganizationStore } from "../../../../hooks/redux/currentOrganization";
import { hasSubscription } from "../../helpers/organization";
import Accordion from "../../component/WidgetSettings/Acordion";
import { ucFirst } from "../../../../helpers/string";

const MandatoryInformationForm = (props) => {
  /**
   *
   * @param fieldName
   * @param callback
   * @returns {function(*=): void}
   */
  const onFormFieldChange = (fieldName) => (e) => {
    props.onUpdateField(fieldName, e.target.value);
  };

  const { integrations, allowedIntegrations } = props;
  const { user } = useUserStore();
  const { currentOrganization } = useCurrentOrganizationStore();
  const subscription = hasSubscription(currentOrganization);

  const integrationsOptions = integrations.length
    ? integrations
        .filter((i) => Number.parseInt(i.available))
        .filter((i) => allowedIntegrations.includes(i.type))
        .map((item) => {
          const isPremium = item.type !== IT.google;
          return (
            <option key={"integration-" + item.uuid} value={item.uuid}>
              {item.name} {isPremium && " (PREMIUM)"}
            </option>
          );
        })
    : null;

  const isWidgetTypeAllowedForPlan = useMemo(() => {
    if (!props.widgetModel.template) {
      return true;
    }

    return (
      WIDGET_TEMPLATES_PLANS[props.widgetModel.widget_type][
        props.widgetModel.template
      ].indexOf(subscription.code || PLANS.free) > -1
    );
  }, [props.widgetModel.template]);

  const showPremiumIntegrationWarning = useMemo(() => {
    return (
      !isWidgetTypeAllowedForPlan ||
      (!subscription &&
        props.widgetModel.integration_uuid &&
        integrations.length &&
        integrations.findIndex(
          (i) =>
            i.uuid === props.widgetModel.integration_uuid &&
            i.type !== IT.google
        ) >= 0)
    );
  }, [isWidgetTypeAllowedForPlan, props.widgetModel]);

  // const premiumIntegrationWarning = showPremiumIntegrationWarning ? (
  //   <div className={"alert-transparent d-flex m-t-20"}>
  //     <CrownIcon className={"m-r-5"} />{" "}
  //     <span>
  //       <Link to={"/billing"}>Upgrade to Publish</Link> this widget. You can
  //       still explore it!
  //     </span>
  //   </div>
  // ) : null;

  /**
   *
   * @type {(function(*): void)|*}
   */
  const onFormSubmit = useCallback((e) => {
    e.preventDefault();
  }, []);

  const subtitle =
    props.showSummary && props.widgetModel && props.widgetModel.integration_uuid
      ? `${ucFirst(props.widgetModel.name)} calendar using ${
          INTEGRATION_NAMES[props.widgetModel.integration.type]
        }.`
      : null;

  return (
    <Accordion
      title={"Name & Integration"}
      subtitle={subtitle}
      keepExpanded={props.expanded}
    >
      <form action="#" onSubmit={onFormSubmit}>
        <div className="form-group">
          <div className="col-xs-12">
            <label htmlFor={"widget_name"} className={""}>
              Widget Name
            </label>
            <input
              type="text"
              className={
                "form-control form-control-sm " +
                (props.formErrors.nameError ? "is-invalid" : "")
              }
              placeholder="Widget Name"
              id="widget_name"
              onChange={onFormFieldChange("name")}
              value={props.widgetModel.name || ""}
              disabled={props.disabled}
            />
          </div>
        </div>
        <div className="form-group">
          <div className="col-md-xs">
            <label htmlFor={"widget_integration_uuid"} className={""}>
              Integration
            </label>
            <select
              key={new Date().valueOf()}
              className={
                "form-control form-control-sm " +
                (props.formErrors.integrationError ? "is-invalid" : "")
              }
              value={props.widgetModel.integration_uuid}
              id="widget_integration_uuid"
              onChange={onFormFieldChange("integration_uuid")}
              disabled={props.disabled}
            >
              <option value={0}>Select Integration...</option>
              {integrationsOptions}
            </select>
            {/*{premiumIntegrationWarning}*/}
          </div>
        </div>
      </form>
    </Accordion>
  );
};

MandatoryInformationForm.defaultProps = {
  allowedIntegrations: Object.values(IT),
};

MandatoryInformationForm.propTypes = {
  widgetModel: PropTypes.object.isRequired,
  onUpdateField: PropTypes.func.isRequired,
  integrations: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  // widgetType: PropTypes.string.isRequired,
  allowedIntegrations: PropTypes.arrayOf(PropTypes.oneOf(Object.values(IT))),
  showSummary: PropTypes.bool,
  expanded: PropTypes.bool,
};

export default MandatoryInformationForm;
