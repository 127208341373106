/**
 * Created by piotr.pozniak@thebeaverhead.com on 05/07/2022.
 */

import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";

import PropTypes from "prop-types";
import classnames from "classnames";
import PreloadImage from "./PreloadImage";
import Header from "./popup/Header";
import Primitives from "./popup/Primitives";
import Description from "./popup/Description";
import Leader from "./popup/Leader";
import Share from "./popup/Share";
import ButtonCTA from "./CardView/ButtonCTA";
import { formatCTAUrl } from "../tools";
import sleep from "../../../helpers/sleep";
import WidgetSettingsContext from "../../../contexts/WidgetSettingsContext";

const GroupModal = React.memo((props) => {
  const { group, widgetSlug } = props;

  const [modalDom] = useState(document.createElement("div"));
  const widgetSettingsContext = useContext(WidgetSettingsContext);

  const showHeader =
    Number.parseInt(widgetSettingsContext["gPopupDetailsShow"]) === 1;
  const showImage =
    Number.parseInt(widgetSettingsContext["gPopupImageShow"]) === 1;
  const showDescription =
    Number.parseInt(widgetSettingsContext["gPopupDescriptionShow"]) === 1;
  const showLeader =
    Number.parseInt(widgetSettingsContext["gPopupLeaderShow"]) === 1;

  const showPrimaryCta =
    Number.parseInt(widgetSettingsContext["gPopupShowCta1st"]) === 1;
  const primaryCtaAction = widgetSettingsContext["gPopupCTAAction1st"];
  const primaryCtaLink = group.ctas[primaryCtaAction] || null;
  const isPrimaryCTADefaultLabel =
    Number.parseInt(widgetSettingsContext["gPopup1stDefaultCTALabel"]) === 1;
  const primaryCTACustomLabel = widgetSettingsContext["gPopup1stCTALabel"];
  const primaryCTASubject = widgetSettingsContext["gPopupCTASubject1st"];
  const primaryCTAMessage = widgetSettingsContext["gPopupCTAMessage1st"];

  const showSecondaryCta =
    Number.parseInt(widgetSettingsContext["gPopupShowCta2nd"]) === 1;
  const secondaryCtaAction = widgetSettingsContext["gPopupCTAAction2nd"];
  const secondaryCtaLink = group.ctas[secondaryCtaAction] || null;
  const isSecondaryCTADefaultLabel =
    Number.parseInt(widgetSettingsContext["gPopup2ndDefaultCTALabel"]) === 1;
  const secondaryCTACustomLabel = widgetSettingsContext["gPopup2ndCTALabel"];
  const secondaryCTASubject = widgetSettingsContext["gPopupCTASubject2nd"];
  const secondaryCTAMessage = widgetSettingsContext["gPopupCTAMessage2nd"];
  const openLinkInNewTab =
    Number.parseInt(widgetSettingsContext["openLinksNewTab"]) === 1;

  const showShareBtn =
    Number.parseInt(widgetSettingsContext["gPopupShareShow"]) === 1;

  const showPrimitives =
    Number.parseInt(widgetSettingsContext["gPopupPrimitivesShow"]) === 1;

  useEffect(() => {
    document.body.appendChild(modalDom);

    return () => document.body.removeChild(modalDom);
  }, []);

  /**
   *
   * @param e
   */
  const onModalAreaClick = (e) => {
    if (props.isSingleItem) {
      return false;
    }

    const eventContainer = document.querySelector("#modal-group-container");
    e.stopPropagation();

    if (!eventContainer.contains(e.target)) {
      onEventsRowClick();
    }
  };

  /**
   *
   * @param setState
   * @param state
   * @returns {Promise<void>}
   */
  const setStatePromise = (setState, state) => {
    setState(state);
    return Promise.resolve();
  };

  const [VFXState, setVFXState] = useState({
    showWrapper: false,
    showContainer: false,
  });

  useEffect(() => {
    if (!props.isSingleItem) {
      document.body.style.overflow = "hidden";
      setStatePromise(setVFXState, { showWrapper: true, showContainer: false })
        .then(() => sleep(150))
        .then(() => {
          setStatePromise(setVFXState, {
            showWrapper: true,
            showContainer: true,
          });
        });
    } else {
      setVFXState({
        showWrapper: true,
        showContainer: true,
      });
    }
  }, []);

  const onEventsRowClick = () => {
    setStatePromise(setVFXState, {
      showWrapper: false,
      showContainer: false,
    })
      .then(() => sleep(150))
      .then(() => {
        document.body.style.overflow = "auto";
        props.closeModal(
          props.group.slug == props.expandedRowSlug ? "" : props.group.slug
        );
      });
  };

  const groupHeader = showHeader ? <Header group={group} /> : null;

  const coverImage =
    showImage && group.cover_image ? (
      <PreloadImage src={group.cover_image} />
    ) : null;

  const description = showDescription ? (
    <Description description={group.description} />
  ) : null;

  const leader =
    showLeader && group.leader ? <Leader leader={group.leader} /> : null;

  const detailsRow = showPrimitives ? <Primitives group={group} /> : null;

  const share = showShareBtn ? (
    <Share widgetSlug={widgetSlug} group={group} />
  ) : null;

  const primaryLink =
    showPrimaryCta && primaryCtaLink
      ? formatCTAUrl(
          primaryCtaAction,
          primaryCtaLink,
          primaryCTASubject,
          primaryCTAMessage
        )
      : null;

  const secondaryLink =
    showSecondaryCta && secondaryCtaLink
      ? formatCTAUrl(
          secondaryCtaAction,
          secondaryCtaLink,
          secondaryCTASubject,
          secondaryCTAMessage
        )
      : null;

  const primaryCTA =
    showPrimaryCta && primaryCtaLink ? (
      <div className={"dce-groups-modal-row--cta-container"}>
        <ButtonCTA
          label={
            isPrimaryCTADefaultLabel
              ? primaryCtaLink.label
              : primaryCTACustomLabel
          }
          url={primaryLink}
          className={"dce-groups-modal-row--primary-cta dce--groups-card--cta"}
          target={openLinkInNewTab ? "_blank" : "_self"}
        />
      </div>
    ) : null;

  const secondaryCta =
    showSecondaryCta && secondaryCtaLink ? (
      <div className={"dce-groups-modal-row--cta-container"}>
        <ButtonCTA
          label={
            isSecondaryCTADefaultLabel
              ? secondaryCtaLink.label
              : secondaryCTACustomLabel
          }
          url={secondaryLink}
          className={
            "dce-groups-modal-row--secondary-cta dce--groups-card--cta"
          }
          target={openLinkInNewTab ? "_blank" : "_self"}
        />
      </div>
    ) : null;

  const closeBtn = !props.isSingleItem ? (
    <div
      className="dce-groups-modal--container-close"
      onClick={onEventsRowClick}
    >
      <i className="material-icons">close</i>
    </div>
  ) : null;

  return ReactDOM.createPortal(
    <div
      className={classnames("dce-groups-modal--wrapper", props.widgetId, {
        show: VFXState.showWrapper,
      })}
      id="modal-group-wrapper"
      onClick={onModalAreaClick}
    >
      <div
        className={classnames("dce-groups-modal--container", {
          show: VFXState.showContainer,
        })}
        id={"modal-group-container"}
      >
        <div className={"dce-groups-modal--content"}>
          {closeBtn}
          {groupHeader}

          <div
            id={"dce-group-details-" + group.slug}
            className={classnames("dce-groups-details--wrapper")}
          >
            {coverImage}
            {description}
            {primaryCTA}
            {secondaryCta}
            {leader}
            {detailsRow}
            {share}
          </div>
        </div>
      </div>
    </div>,
    modalDom
  );
});

GroupModal.defaultProps = {
  showDetails: false,
};

GroupModal.propTypes = {
  widgetId: PropTypes.string,
  widgetSlug: PropTypes.string,
  showDetails: PropTypes.bool,
  isSingleItem: PropTypes.bool,
  embedded: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  group: PropTypes.object.isRequired,
  expandedRowSlug: PropTypes.string,
};

//Row.whyDidYouRender = true
export default GroupModal;
