/**
 * Created by piotr.pozniak@thebeaverhead.com on 15/12/2022
 */

import React from "react";
import PropTypes from "prop-types";
import { IntegrationStatus } from "../consts/integrations";
import ExpandableAlert from "./ExpandableAlert";
import PermissionsAlert from "../modals/NewIntegration/PermissionsAlert";
import { useIntegrationsStore } from "../../../hooks/redux/integrations";
import { Link } from "react-router-dom";

/**
 *
 * @param integration
 * @returns {*}
 */
const isDisabled = (integration) => {
  return integration && integration.disabled_date;
};
/**
 *
 * @param integration
 * @returns {boolean}
 */
const hasPermissionError = (integration) => {
  return (
    integration &&
    integration.status === IntegrationStatus.permissionsRevoked &&
    !integration.state
  );
};

/**
 *
 * @param integration
 * @returns {boolean}
 */
const hasSyncError = (integration) => {
  return (
    integration &&
    integration.status === IntegrationStatus.syncError &&
    !integration.state
  );
};

/**
 *
 * @param integration
 * @returns {boolean}
 */
export const hasIntegrationErrors = (integration) => {
  return hasSyncError(integration) || hasPermissionError(integration);
};

const IntegrationIssueNotification = (props) => {
  const { integration_uuid } = props;
  // get integration model from the collection from integrations hook store based on the integration_uuid
  const { integrations } = useIntegrationsStore();
  const integration = integrations.collection.find(
    (integration) => integration.uuid === integration_uuid
  );

  const syncError = hasSyncError(integration) ? (
    <ExpandableAlert
      icon={"warning"}
      errorLevel={"danger"}
      header={
        <span>
          Last sync failed. <Link to={"/integrations"}>Go to Integrations</Link>
          .
        </span>
      }
      body={
        <span>
          You may experience outdated data due to:{" "}
          {integration.last_error_message}
        </span>
      }
    />
  ) : null;

  const permissionsError = hasPermissionError(integration) ? (
    <PermissionsAlert integration={integration} />
  ) : null;

  const disabledIntegrationAlert = isDisabled(integration) ? (
    <div className={"alert alert-danger"}>
      <i className={"text-danger m-r-5 material-icons icon-sm"}>warning</i>
      You may experience outdated data because the integration this widget uses
      has been disabled due to plan downgrade.{" "}
      <Link to={"/billing"}>Manage your subscription</Link>.
    </div>
  ) : null;

  // if there are errors, return the wrapped in a div, otherwise return null

  return syncError || permissionsError || disabledIntegrationAlert ? (
    <div className={"overlapping-alert-container"}>
      {syncError}
      {permissionsError}
      {disabledIntegrationAlert}
    </div>
  ) : null;
};

IntegrationIssueNotification.defaultProps = {};

IntegrationIssueNotification.propTypes = {
  integration_uuid: PropTypes.string,
};

export default IntegrationIssueNotification;
