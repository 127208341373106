/**
 * Created by piotr.pozniak@thebeaverhead.com on 15/02/2023.
 */

import React, { useEffect } from "react";

import { Link, useHistory } from "react-router-dom";
import PageTemplate from "./../templates/PageTemplate";
import TableRow from "./AlertsPage/TableRow";
import LoadingIndicator from "../../calendar/components/LoadingIndicator";
import ApiError from "../component/ApiError";
import { getAlertCurrentData } from "../../../actions/alert";
import { useAlertsStore } from "../../../hooks/redux/alerts";
import { useAlertStore } from "../../../hooks/redux/alert";
import { formatError } from "../../../helpers/errors";
import NotificationPopup from "../component/NotificationPopup";
import useNotificationToast from "../hooks/useNotificationToastHook";
import { alertFeaturesCheck, updateJquery } from "../tools";
import { useUserStore } from "../../../hooks/redux/user";
import { useCurrentOrganizationStore } from "../../../hooks/redux/currentOrganization";

const AlertsPage = (props) => {
  const history = useHistory();

  const { alerts, fetchAlerts } = useAlertsStore();
  const { alert, updateAlert, setAlert, deleteAlert } = useAlertStore();
  const { addNotification } = useNotificationToast();
  const { currentOrganization } = useCurrentOrganizationStore();

  useEffect(() => {
    fetchAlerts();
  }, []);

  useEffect(() => {
    if (alert.updateError) {
      addNotification({
        content: (props) => (
          <NotificationPopup {...props}>
            <p className="notification-title">The following error occurred:</p>
            {formatError(alert.updateError).join("")}
          </NotificationPopup>
        ),
      });
    }
  }, [alert.updateError]);

  /**
   *
   * @param domain
   */
  const onChangeStatus = async (alert) => {
    const alertData = getAlertCurrentData({ ...alert });
    alertData.available = alert.available ? 0 : 1;

    const issues = alertFeaturesCheck(alertData, currentOrganization);
    if (issues.length) {
      addNotification({
        content: (props) => (
          <NotificationPopup {...props}>
            <p className="notification-title">
              <i className={"material-icons"}>warning</i> You cannot publish
              this Alert widget because:
            </p>
            <ul className={"notification-message pl-4"}>
              {issues.map((i, idx) => (
                <li key={`per-${idx}`}>{i}</li>
              ))}
            </ul>
          </NotificationPopup>
        ),
      });

      return false;
    }
    await updateAlert(alert.uuid, alertData);
    setAlert(null);
  };

  useEffect(() => {
    /**
     * If no alerts available, navigate to new alert page.
     * Handle back button so user does not enter an infinite loop.
     */
    if (alerts.fetchSuccess && !alerts.collection.length) {
      if (history.action === "POP" && props) {
        history.go(-1);
      } else {
        history.push("/alert/new");
      }
    }
    updateJquery();
  }, [alerts.fetchSuccess]);

  /**
   *
   * @param alert
   * @returns {Promise<void>}
   */
  const onClone = async (alert) => {
    const alertData = getAlertCurrentData({ ...alert });
    alertData.uuid = null;
    alertData.available = 0;
    alertData.name = "Copy of " + alertData.name;

    await updateAlert(null, alertData);
    await setAlert(null);
  };

  /**
   *
   */
  const onDelete = (alert) => {
    if (
      window.confirm(
        "Are you sure you want to delete Alert widget " +
          alert.name +
          "? Changes are irreversible!"
      )
    ) {
      deleteAlert(alert.uuid);
    }
  };

  const loading = alerts.fetch || alerts.delete || alerts.sync;

  const loadingIndicator = loading ? <LoadingIndicator /> : null;

  const alertRows = alerts.collection.map((item, idx) => {
    return (
      <TableRow
        disabled={loading}
        key={idx}
        alert={item}
        onDelete={onDelete}
        onChangeStatus={onChangeStatus}
        alertEditUUID={alerts.updateAlertUUID}
        onClone={onClone}
      />
    );
  });

  const alertsData = alerts.collection.length ? (
    <table className="table table-striped custom-table">
      <thead>
        <tr>
          <th width={"20px"}></th>
          <th width="30%">Name</th>
          <th width="15%">Integration</th>
          <th width="20%">Template</th>
          <th width="10%">Status</th>
          <th width="10%">Last edited</th>
          <th className="text-right">Action</th>
        </tr>
      </thead>
      <tbody>{alertRows}</tbody>
    </table>
  ) : (
    loadingIndicator
  );

  return (
    <PageTemplate
      sidebarId="alerts"
      title={"Event Alerts"}
      icon={"campaign"}
      headerControls={[
        <Link
          key="/alert/new"
          to="/alert/new"
          className="btn btn-primary btn-rounded pull-right d-flex"
        >
          <i className="material-icons">add</i> Create new
        </Link>,
      ]}
    >
      <div className="row">
        <div className="col-md-12">
          <div className="table-responsive min-height-50">
            {alertsData}

            <ApiError
              error={alerts.deleteError}
              defaultErrorMessage={
                "Could not delete the domain. Please try again"
              }
            />
          </div>
        </div>
      </div>
    </PageTemplate>
  );
};

export default AlertsPage;
