/**
 * Created by piotr.pozniak@thebeaverhead.com on 18/07/2022
 */

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const PrimitiveBadges = (props) => {
  return (
    <div
      className={classNames("dce--groups-primitives-badges", props.className)}
    >
      {props.children}
    </div>
  );
};

PrimitiveBadges.defaultProps = {};

PrimitiveBadges.propTypes = {
  className: PropTypes.string,
};

export default PrimitiveBadges;
