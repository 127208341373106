import React from "react";
import PropTypes from "prop-types";

const style = {
  width: "100%",
  aspectRatio: "16 / 9",
};
const YouTubePreview = ({ videoId }) => (
  <div>
    <iframe
      style={style}
      src={`https://www.youtube.com/embed/${videoId}`}
      title="YouTube Video Preview"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe>
  </div>
);

YouTubePreview.propTypes = {
  videoId: PropTypes.string,
};

export default YouTubePreview;
