import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { durationToString } from "../../tools";

class ImportTableRow extends React.Component {
  /**
   *
   */

  render() {
    const { id, created, data, duration, integration_uuid } = this.props.import;
    const integration = this.props.integrations.find(
      (i) => i.uuid == integration_uuid
    );
    const integrationName = integration ? integration.name : "-";
    const lastSync = moment(created * 1000).format("YYYY-MM-DD HH:mm");

    return (
      <tr>
        <td>{lastSync}</td>
        <td>{integrationName}</td>
        <td>{durationToString(duration)}</td>
        <td>{data.events_imported_count}</td>
      </tr>
    );
  }
}

ImportTableRow.propTypes = {
  import: PropTypes.shape({
    id: PropTypes.string.isRequired,
    created: PropTypes.number.isRequired,
    duration: PropTypes.number.isRequired,
    integration_uuid: PropTypes.string.isRequired,
    data: PropTypes.shape({
      events_imported_count: PropTypes.number,
    }).isRequired,
  }),
};

export default ImportTableRow;
