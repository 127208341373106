import React, { useState } from "react";
import PropTypes from "prop-types";
import { isValidEmail } from "../../../../helpers/calendar";

const Email = ({ email, setEmail, disabled, editable }) => {
  const [isValid, setIsValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  
  const handleEmailChange = (e) => {
    const inputEmail = e.target.value;
    setEmail(inputEmail);
  };

  const handleBlur = () => {
    const checkIfValid = isValidEmail(email);
    setIsValid(checkIfValid);
    if (!checkIfValid) {
      setErrorMessage("Please enter a valid email address.");
    } else {
      setErrorMessage("");
    }
  };

  if (!editable) {
    return email;
  }

  return (
    <div className="email-input-container">
      <input
        id={"email"}
        type="email"
        className={"form-control"}
        value={email}
        disabled={disabled}
        onChange={handleEmailChange}
        onBlur={handleBlur}
        maxLength={64}
        minLength={2}
      />
      {!isValid && (
        <div className="invalid-feedback d-block">{errorMessage}</div>
      )}
    </div>
  );
};

Email.propTypes = {
  email: PropTypes.string.isRequired,
  setEmail: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  editable: PropTypes.bool,
};

export default Email;
