/**
 * Created by piotr.pozniak@thebeaverhead.com on 25/02/2019.
 */

import React, { useCallback } from "react";

import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import CrownIcon from "../../component/CrownIcon";
import classNames from "classnames";
import FeatureFlag from "../../component/FeatureFlag";

const NewCalendarPreview = (props) => {
  const {
    title,
    description,
    thumbnailUrl,
    features,
    slug,
    isSelected,
    canSelect,
  } = props;

  const selectPlan = canSelect ? null : (
    <Link to={"/billing"}>
      <div className={"alert-transparent d-flex m-t-20"}>
        <CrownIcon className={"m-r-5"} />
        Upgrade to Publish this calendar
        <span
          className={
            "m-l-5 text-secondary premium-feature-help-icon d-flex align-items-center"
          }
          data-toggle={"tooltip"}
          data-toggleaa={"2222"}
          data-placement={"bottom"}
          title={
            "This is a Premium Feature. You can test it out but you will need to Upgrade to use it. Upgrade Now"
          }
        >
          <i className={"material-icons"}>help</i>
        </span>
      </div>
    </Link>
  );

  const previewButton = (
    <FeatureFlag name={"showNewWidgetPreviewButton"}>
      <a href={props.previewUrl} target="_blank">
        <button className={"btn btn-rounded w-md btn-default"}>
          Live preview
        </button>
      </a>
    </FeatureFlag>
  );

  const isNew = props.isNew ? (
    <div className={"badge-new-calendar"}>
      <span className={"badge badge-success-border "}>New arrival!</span>
    </div>
  ) : null;

  return (
    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <div className="pricing-box new-calendar">
        {/*<h3 className="">{title}</h3>*/}
        <div className="product-inner text-center">
          <img alt={title} src={thumbnailUrl} />

          {previewButton}
        </div>
        <div className={"product-description-container"}>
          {isNew}
          <div>
            <p>{description}</p>
            <ul>
              {features.map((i, idx) => {
                return (
                  <li
                    key={"fl-" + slug + "-" + idx}
                    className={"d-flex align-items-center"}
                  >
                    <i className="material-icons icon-lg" aria-hidden="true">
                      done
                    </i>
                    {i}
                  </li>
                );
              })}
            </ul>
          </div>

          <div className={"d-flex flex-column align-items-center w-100"}>
            <a
              href="#chat_sidebar"
              type="button"
              onClick={props.onSelect}
              className={classNames(
                "btn btn-rounded w-md sidenav_btn  d-xs-block d-sm-block d-md-block d-lg-none d-xl-none  task-chat",
                {
                  "btn-success": isSelected,
                  "btn-primary": !isSelected,
                }
              )}
            >
              {isSelected ? "Selected" : "Select"}
            </a>
            <button
              type="button"
              onClick={props.onSelect}
              className={classNames(
                "btn btn-rounded w-md d-none d-xs-none d-sm-none d-md-none d-lg-block d-xl-block",
                {
                  "btn-success": isSelected,
                  "btn-primary": !isSelected,
                }
              )}
            >
              {isSelected ? "Selected" : "Select"}
            </button>
            {selectPlan}
            {/*{availabilityInfo}*/}
          </div>
        </div>
      </div>
    </div>
  );
};

NewCalendarPreview.defaultProps = {
  isNew: false,
};

NewCalendarPreview.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.object,
  slug: PropTypes.string.isRequired,
  thumbnailUrl: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  features: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  canSelect: PropTypes.bool,
  defaultCalendarSettings: PropTypes.object,
  forPlans: PropTypes.array,
  isNew: PropTypes.bool,
};

export default NewCalendarPreview;
