/**
 * Created by piotr.pozniak@thebeaverhead.com on 13/10/2018.
 */

import React, { useEffect } from "react";
import { ReactNotifications } from "react-notifications-component";

import { BrowserRouter, useHistory, useParams } from "react-router-dom";

import AxiosConfig from "../../AxiosConfig";
import useDidMountEffect from "../../hooks/useDidMountEffect";
import MainRoutesComponent from "./MainRoutesComponent";
import ModalRoutesComponent from "./ModalRoutesComponent";
import { useAppStore } from "../../hooks/redux/app";
import { useUserStore } from "../../hooks/redux/user";
import { useCalendarsStore } from "../../hooks/redux/calendars";
import { useDomainsStore } from "../../hooks/redux/domains";
import { useSubscriptionPlansStore } from "../../hooks/redux/subscriptionPlans";
import { useAutologinStore } from "../../hooks/redux/autologin";
import { useIntegrationsStore } from "../../hooks/redux/integrations";
import { useCurrentOrganizationStore } from "../../hooks/redux/currentOrganization";
import appConfig from "../../appConfig";
import OrgSwitchOverlay from "./component/OrgSwitchOverlay";
import { useAppTagStore } from "../../hooks/redux/appTags";
/*
window.Rewardful = {
  coupon: {
    id: "qRrH71Ej",
  },
  ready: (fn) => {
    fn();
  },
};
*/

const RouterClass = (props) => {
  const { clearErrors, initializeIntercom, shutdownIntercom, updateIntercom } =
    useAppStore();
  const history = useHistory();
  const { user, checkSession, logout } = useUserStore();
  const { currentOrganization } = useCurrentOrganizationStore();
  const { fetchCalendars } = useCalendarsStore();
  const { fetchDomains } = useDomainsStore();
  const { fetchIntegrations } = useIntegrationsStore();
  const { fetchPlans } = useSubscriptionPlansStore();
  const { autologin } = useAutologinStore();
  const { fetchAppTag } = useAppTagStore();

  if (!AxiosConfig._token && user.token) {
    AxiosConfig.setAuthToken(user.token);
  }

  // TODO this is to surpress the error message appearing in the web browser.
  // Assign a ticket to resolve this issue.
  useEffect(() => {
    window.addEventListener("error", (e) => {
      console.log(e);
      if (
        e.message ===
        "ResizeObserver loop completed with undelivered notifications."
      ) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
  }, []);

  useEffect(() => {
    if (!user.model) {
      const invitationPathRegex = /^\/accept_invitation/;
      const isInvitationPage = invitationPathRegex.test(
        window.location.pathname
      );

      // Exit if on invitation page
      if (isInvitationPage) return;

      // redirect to login page if current location is not one of the following:
      // /login, /register, /password-reminder, /password-reset, /account-verification, /
      if (
        ![
          "/login",
          "/register",
          "/password-reminder",
          "/password-reset",
          "/account-verification",
          "/",
        ].includes(window.location.pathname)
      ) {
        history.push(
          "/login?redirect=" +
            encodeURI(window.location.pathname + window.location.search)
        );
      }
    }
  }, []);

  useEffect(() => {
    if (user.token) {
      checkSession();
    }

    initializeIntercom(user, currentOrganization, true);

    /*if (user.token) {
      console.log("1 fetching plans, integrations, domains");
      props.subscriptionPlansActions.fetchPlans();
      props.integrationsActions.fetchIntegrations();
      props.domainsActions.fetchDomains();
    }*/
  }, []);

  useEffect(() => {
    if (autologin.loginSuccess && user.token) {
      fetchPlans();
      fetchIntegrations();
      fetchDomains();
      fetchAppTag();
      fetchCalendars();
      shutdownIntercom();
    }
  }, [autologin.loginSuccess]);

  useEffect(() => {
    if (
      (user.loginSuccess || user.registerSuccess || user.checkSessionSuccess) &&
      !autologin.model
    ) {
      updateIntercom(user, currentOrganization, true);
    }
  }, [
    user.loginSuccess,
    user.registerSuccess,
    user.checkSessionSuccess,
    autologin.loginSuccess,
  ]);

  useEffect(() => {
    // session has expired
    if (user.sessionExpired) {
      logout();
      window.alert("Your session has expired. Please log in again.");
    }
  }, [user.sessionExpired]);

  useEffect(() => {
    if ((!user.model && user.loginSuccess && user.token) || autologin.logout) {
      if (autologin.logout) {
        clearErrors();
      }
      fetchDomains();
      fetchIntegrations();
    }
  }, [autologin.logout]);

  useDidMountEffect(() => {
    if (user.token !== null && !autologin.loginSuccess) {
      // todo user's token refreshes every time check session is successful. No need to refresh these every time.

      fetchPlans();
      fetchIntegrations();
      fetchDomains();
      fetchCalendars();
      fetchAppTag();
    }
  }, [user.token]);

  const modalLinks = [
    "/integration/new",
    "/integration/new/:type",
    "/integration/:id",
    "/domains/:uuid",
    `/${appConfig.links.organization}/edit/:uuid`,
    "/billing/cancel-subscription",
    "/billing/update-billing-details",
    "/subscribe/:planCode/:period/:isBranded",
    "/manage-members",
  ];

  const orgEditModalLink = `/${appConfig.links.organization}/edit/:uuid`;
  const prevModalLocation = [
    {
      "/integration/new": `/integrations`,
      "/integration/new/:type": `/integrations`,
      "/integration/:id": `/integrations`,
    },
    {
      "/domains/:uuid": `/domains`,
    },
    {
      orgEditModalLink: `/${appConfig.links.organizations}`,
    },
    { "/billing/cancel-subscription": "/billing" },
    { "/billing/update-billing-details": "/billing" },
    { "/subscribe/:planCode/:period/:isBranded": "/billing" },
    { "/manage-members": "/dashboard" },
  ];

  return (
    <BrowserRouter>
      <ReactNotifications />
      <OrgSwitchOverlay />
      <MainRoutesComponent user={user} modalLinks={modalLinks} />
      <ModalRoutesComponent
        user={user}
        modalLinks={modalLinks}
        prevModalLocation={prevModalLocation}
      />
    </BrowserRouter>
  );
};

export default RouterClass;
