/**
 * Created by piotr.pozniak@thebeaverhead.com on 08/03/2019.
 */

import React, { useContext, useEffect, useState } from "react";

import moment from "moment";
import classnames from "classnames";
import { formatError } from "../../../../helpers/errors";
import PropTypes from "prop-types";
import WidgetSettingsContext from "../../../../contexts/WidgetSettingsContext";

const RSVPOptions = [
  {
    value: "yes",
    label: "Yes",
  },
  {
    value: "no",
    label: "No",
  },
  {
    value: "maybe",
    label: "Maybe",
  },
];

const RSVPRequestOption = [
  {
    value: "request",
    label: "Register",
  },
];

const EventRSVP = (props) => {
  const [state, setState] = useState({
    showCredentialsPanel: false,
    selectedRSVPOption: props.selectedRSVPOption,
    hasRSVP: props.selectedRSVPOption ? true : false,
  });

  const widgetSettings = useContext(WidgetSettingsContext);

  useEffect(() => {
    if (!state.showCredentialsPanel) {
      props.onShowForm(false);
    } else {
      props.onShowForm(true);
    }
  }, [state]);

  /**
   *
   * @param e
   * @returns {boolean}
   */
  const onCloseRSVPForm = (e) => {
    e.preventDefault();

    setState({ showCredentialsPanel: false, selectedRSVPOption: null });
    // props.clearErrors();
  };

  /**
   *
   * @param value
   * @returns {Function}
   */
  const onRSVSelect = (value) => (e) => {
    setState({ showCredentialsPanel: true, selectedRSVPOption: value });
  };

  /**
   *
   * @param e
   */
  const onSubmitClick = (e) => {
    e.preventDefault();
    const event = props.event;

    const login = document.querySelector(
      "#event-rsvp-login-" + event.slug
    ).value;
    const password = document.querySelector(
      "#event-rsvp-password-" + event.slug
    ).value;

    props.onRSVPEvent(props.event, login, password, state.selectedRSVPOption);

    return false;
  };

  const { event, loading, ccbAddress } = props;

  let subheader = widgetSettings["rsvpCTAOpenPrompt"];

  let loginFormHeader = widgetSettings["rsvpLoginOpenFormLabel"];

  let submitButtonLabel = widgetSettings["rsvpLoginOpenSubmitLabel"];

  const hasRSVPLabels = {
    rsvpUserAttendingLabel: widgetSettings["rsvpUserAttendingLabel"],
    rsvpUserAttendingCanChangeLabel:
      widgetSettings["rsvpUserAttendingCanChangeLabel"],
  };

  const rsvpOpenForAllLabels = {
    rsvpCTARegisterPrompt: widgetSettings["rsvpCTARegisterPrompt"],
    rsvpLoginRegisterFormLabel: widgetSettings["rsvpLoginRegisterFormLabel"],
    rsvpLoginRegisterSubmitLabel:
      widgetSettings["rsvpLoginRegisterSubmitLabel"],
  };
  const registrationType = event.rsvp.type_id * 1;

  if (registrationType != 1) {
    subheader = rsvpOpenForAllLabels.rsvpCTARegisterPrompt;
    loginFormHeader = rsvpOpenForAllLabels.rsvpLoginRegisterFormLabel;
    submitButtonLabel = rsvpOpenForAllLabels.rsvpLoginRegisterSubmitLabel;
  }

  if (state.hasRSVP) {
    subheader = hasRSVPLabels.rsvpUserAttendingLabel;

    if (registrationType == 1) {
      subheader += hasRSVPLabels.rsvpUserAttendingCanChangeLabel;
    }
  }

  const eventUrl =
    ccbAddress.replace("api.php", "") +
    "w_calendar.php#events/" +
    event.integration_event_id +
    "/occurrence/" +
    moment(event.start_time * 1000).format("YYYYMMDD"); // 20190801

  const loadingIndicator = loading ? <div className={"evoloading"} /> : null;

  const RSVPError = props.error ? (
    <div className="form_row notification err">
      <p
        dangerouslySetInnerHTML={{
          __html: formatError(
            props.error,
            "Could not RSVP. Please try again."
          ).join("<br/>"),
        }}
      />
    </div>
  ) : null;

  const credentials = state.showCredentialsPanel
    ? [
        <div
          className={classnames("evors_incard_form", {
            error: props.error,
          })}
          key={"rsvp-b1-" + event.slug}
        >
          {loadingIndicator}

          <a className="evors_incard_close" onClick={onCloseRSVPForm} />
          <form
            onSubmit={onSubmitClick}
            id="evorsvp_form"
            className={classnames("evors_forms form_submit")}
            data-rsvpid=""
          >
            <span className="event-rsvp--header">{loginFormHeader}</span>

            <div className="form_row name">
              <input
                className="name input req"
                name="first_name"
                type="text"
                placeholder="User name"
                title="User name"
                id={"event-rsvp-login-" + event.slug}
              />
            </div>

            <div className="form_row email ">
              <input
                className="regular input req evors_rsvp_email"
                name="email"
                type="password"
                placeholder="Password"
                title="CCB Password"
                id={"event-rsvp-password-" + event.slug}
              />
            </div>

            <div className="form_row">
              <button
                type="submit"
                className="submit_rsvp_form"
                href="#"
                onClick={onSubmitClick}
              >
                {submitButtonLabel}
              </button>
            </div>
          </form>
          {RSVPError}
        </div>,
        <div className="evors_section" key={"rsvp-b2-" + event.slug}>
          <div className="event-row--details-row">
            <p className="">
              <span className="">Don't have a CCB account?</span>
              <a className="evors_change_rsvp" href={eventUrl} target="_blank">
                Go to CCB site
              </a>
            </p>
          </div>
        </div>,
      ]
    : null;

  const rsvpOptions = registrationType == 1 ? RSVPOptions : RSVPRequestOption;

  const RSVPButtons = rsvpOptions.map((i, idx) => {
    return (
      <span
        key={"rsvpidx-" + event.slug + "-" + idx}
        className={classnames("evors_btn", {
          active: state.selectedRSVPOption == i.value,
        })}
        onClick={onRSVSelect(i.value)}
      >
        {i.label}
      </span>
    );
  });

  return (
    <div className={"event-row--details"}>
      <div className="event-row--details-row">
        <div className="col-icon">
          <i className="material-icons">email</i>
        </div>
        <div className="col-content ">
          <div className="">
            <h3 className="">RSVP Event</h3>
          </div>
          <div>
            <div className="">
              <div>
                <p className="event-rsvp--cta">
                  <span>{subheader}</span>
                </p>
              </div>
              <div className="evoRS_status_option_selection">
                <p className="">{RSVPButtons}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {credentials}
    </div>
  );
};

EventRSVP.propTypes = {
  selectedRSVPOption: PropTypes.string,
  error: PropTypes.object,
  ccbAddress: PropTypes.string,
  event: PropTypes.object,
  onRSVPEvent: PropTypes.func,
  onShowForm: PropTypes.func,
  loading: PropTypes.bool,
};

export default EventRSVP;
