/**
 * Created by piotr.pozniak@thebeaverhead.com on 27/11/2023
 */

import React, { useCallback, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import ModalTemplate from "./ModalTemplate";
import { useAppStore } from "../../../hooks/redux/app";
import { useCurrentOrganizationStore } from "../../../hooks/redux/currentOrganization";
import { useSubscriptionStore } from "../../../hooks/redux/subscription";
import { useUserStore } from "../../../hooks/redux/user";
import Form from "./Subscribe/Form";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useSubscriptionPlansStore } from "../../../hooks/redux/subscriptionPlans";
import LoadingIndicator from "../../groups/components/LoadingIndicator";

const buildUrl = (code, period, branded) => {
  return `/subscribe/${code}/${period}/${branded}`;
};

const SubscribeModal = (props) => {
  const { currentOrganization } = useCurrentOrganizationStore();
  const { subscription } = useSubscriptionStore();
  const { subscriptionPlans } = useSubscriptionPlansStore();
  const { checkSession } = useUserStore();
  const { clearErrors } = useAppStore();

  const match = useRouteMatch();
  const history = useHistory();

  const selectedPlan = useMemo(
    () =>
      subscriptionPlans.collection.length
        ? subscriptionPlans.collection.find((i) => {
            return (
              i.available === 1 &&
              i.code === match.params.planCode &&
              i.is_branded === Number.parseInt(match.params.isBranded)
            );
          })
        : null,
    [match.url, subscriptionPlans.collection]
  );

  useEffect(() => {
    if (subscription.updateSuccess) {
      checkSession();

      onClose();

      return () => {
        clearErrors();
      };
    }
  }, [subscription.updateSuccess]);

  const modalTitle = currentOrganization.subscription
    ? "Change Subscription"
    : "Activate subscription";

  const onClose = async () => {
    await clearErrors();
    history.push("/billing/");
  };

  const onTogglePlanBranded = useCallback(() => {
    history.replace(
      buildUrl(
        match.params.planCode,
        match.params.period,
        Number.parseInt(match.params.isBranded) ? 0 : 1
      )
    );
  }, [match.url]);

  const loadingIndicator = subscriptionPlans.fetch ? (
    <LoadingIndicator />
  ) : null;

  const form = selectedPlan ? (
    <Form
      selectedPlan={selectedPlan}
      onTogglePlanBranded={onTogglePlanBranded}
      isBranded={Number.parseInt(match.params.isBranded) ? true : false}
      isYearly={match.params.period == "yearly"}
      /*appliedCoupon={appliedCoupon}
      setAppliedCoupon={setAppliedCoupon}*/
      onClose={onClose}
      disabled={subscription.update}
    />
  ) : null;

  return (
    <ModalTemplate
      id={"cancel-subscription-modal"}
      header={modalTitle}
      size={"lg"}
      onClose={onClose}
    >
      {form}
      {loadingIndicator}
    </ModalTemplate>
  );
};

SubscribeModal.defaultProps = {};

SubscribeModal.propTypes = {};

export default SubscribeModal;
