/**
 * Created by piotr.pozniak@thebeaverhead.com on 11/03/2024
 */

import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useUserStore } from "../../../hooks/redux/user";
import appConfig from "../../../appConfig";

const FeatureFlag = ({ name, children }) => {
  const { user } = useUserStore();

  const userModel = useMemo(() => {
    if (user?.model) {
      return user.model;
    }
    return null;
  }, [user]);

  const canUseFeature = useMemo(() => {
    const features = appConfig.featureFlags || {};

    const feature = features.find((feature) => feature.name === name);

    if (!feature) {
      return false;
    }

    if (feature.conditions && feature.conditions.length === 0) {
      return true;
    }

    for (const conditionIdx in feature.conditions) {
      const condition = feature.conditions[conditionIdx];
      if (userModel) {
        if (condition.user) {
          if (
            condition.user.emails &&
            condition.user.emails.includes(userModel.email)
          ) {
            return true;
          } else if (condition.user.role === userModel.role) {
            return true;
          }
        }
      }
    }

    return false;
  }, [userModel]);

  if (!canUseFeature) {
    return null;
  }

  return children;
};

FeatureFlag.defaultProps = {};

FeatureFlag.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default FeatureFlag;
