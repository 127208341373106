/**
 * Created by piotr.pozniak@thebeaverhead.com on 30/12/2022
 */
import React, { useCallback, useEffect, useState } from "react";
import { Store as NotificationComponent } from "react-notifications-component";
import { useAppStore } from "../../../hooks/redux/app";

export const useNotificationToast = (props) => {
  // state to hold all notifications Ids
  const [notifications, setNotifications] = useState([]);
  const { clearErrors } = useAppStore();

  // an effect that on return will remove all notifications
  useEffect(() => {
    return () => {
      notifications.forEach((id) => {
        NotificationComponent.removeNotification(id);
      });

      clearErrors();
    };
  }, [notifications]);

  // a function that adds a new notification using react-notifications-component
  const addNotification = useCallback(
    (notification) => {
      // add the new notification to the state
      const notificationId = NotificationComponent.addNotification({
        type: "danger",
        insert: "top",
        container: "top-center",
        dismiss: {
          click: false,
          duration: 0,
        },
        transition: { duration: 0 },
        ...notification,
      });

      // update the state
      setNotifications([...notifications, notificationId]);
    },
    [notifications]
  );

  return {
    addNotification,
  };
};

export default useNotificationToast;
