/**
 * Created by piotr.pozniak@thebeaverhead.com on 19/10/2018.
 */

import axios from "axios";
import AxiosConfig from "../AxiosConfig";
import triggerTagEvent from "../helpers/tagmanager";

/**
 *
 * @param email
 * @param password
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export function login(email, password) {
  return async function (dispatch) {
    dispatch({ type: "USER_LOGIN" });

    const queryParams = [];

    const url = "/api/users/login.json";

    return await axios
      .post(
        url,
        {
          email: email,
          password: password,
        },
        AxiosConfig.getConfig()
      )
      .then((response) => {
        AxiosConfig.setAuthToken(response.data.token);

        dispatch({
          type: "USER_LOGIN_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "USER_LOGIN_REJECTED",
          payload: error,
        });
      });
  };
}

/**
 *
 * @returns {Function}
 */
export function logout() {
  return function (dispatch) {
    AxiosConfig.setAuthToken(null);

    dispatch({ type: "USER_LOGOUT" });
  };
}

/**
 * @typedef {Object} registerData
 * @property {string} first_name
 * @property {string} last_name
 * @property {string} [organization_name]
 * @property {string} email
 * @property {string} password
 * @property {string} trial_coupon
 * @property {string} captcha_token
 * @property {string} [organization_uuid]
 */

/**
 *
 * @param {registerData} data
 * @returns {function(*): Promise<void>}
 */
export function register(data) {
  return async (dispatch) => {
    dispatch({ type: "USER_REGISTER" });

    const url = "/api/users.json";
    const body = {
      ...data,
      treasurer_email: data.email,
    };

    return await axios
      .post(url, body, AxiosConfig.getConfig())
      .then((response) => {
        AxiosConfig.setAuthToken(response.data.token);
        dispatch({
          type: "USER_REGISTER_FULFILLED",
          payload: response.data,
        });
        triggerTagEvent("new-account-registered", {
          userID: response.data.user.uuid,
          email: response.data.user.email,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "USER_REGISTER_REJECTED",
          payload: error,
        });
      });
  };
}

/**
 * @typedef {Object} activeAccountData
 * @property {string} first_name
 * @property {string} last_name
 * @property {string} email
 * @property {string} trial_coupon
 * @property {string} access_token
 * @property {string} organization_name
 * @property {string} organization_uuid
 */
/**
 *
 * @param {activeAccountData} data
 * @returns {function(*): Promise<void>}
 */
export function activateAccount(data) {
  return async (dispatch) => {
    dispatch({ type: "USER_ACTIVATE_PENDING_ACCOUNT" });

    const url = "/api/users/activate_account.json";

    return await axios
      .post(url, data, AxiosConfig.getConfig())
      .then((response) => {
        dispatch({
          type: "USER_ACTIVATE_PENDING_ACCOUNT_FULFILLED",
          payload: response.data,
        });
        triggerTagEvent("new-account-registered", {
          userID: response.data.user.uuid,
          email: response.data.user.email,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "USER_ACTIVATE_PENDING_ACCOUNT_REJECTED",
          payload: error,
        });
      });
  };
}

/**
 *
 * @param email
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export function remindPassword(email) {
  return async (dispatch) => {
    dispatch({ type: "USER_PASSWORD_REMIND" });

    const url = "/api/users/password_reminder.json";

    return await axios
      .post(
        url,
        {
          email,
        },
        AxiosConfig.getConfig()
      )
      .then((response) => {
        dispatch({
          type: "USER_PASSWORD_REMIND_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "USER_PASSWORD_REMIND_REJECTED",
          payload: error,
        });
      });
  };
}

/**
 *
 * @param email
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export function resetPassword(email, reset_token, password) {
  return function (dispatch) {
    dispatch({ type: "USER_PASSWORD_RESET" });

    const url = "/api/users/password_reset.json";

    return axios
      .put(
        url,
        {
          email,
          reset_token,
          password,
        },
        AxiosConfig.getConfig()
      )
      .then((response) => {
        AxiosConfig.setAuthToken(response.data.token);

        dispatch({
          type: "USER_PASSWORD_RESET_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "USER_PASSWORD_RESET_REJECTED",
          payload: error,
        });
      });
  };
}

/**
 *
 * @param uuid
 * @param data
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export function editUser(uuid, data, isAdminRequest = false) {
  return function (dispatch) {
    dispatch({ type: "USER_EDIT", isAdminRequest });

    const url = "/api/users/" + uuid + ".json";

    return axios
      .put(url, data, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "USER_EDIT_FULFILLED",
          payload: response.data,
          isAdminRequest,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "USER_EDIT_REJECTED",
          payload: error,
          isAdminRequest,
        });
      });
  };
}

/**
 *
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export function checkSession() {
  return function (dispatch) {
    dispatch({ type: "USER_CHECK_SESSION" });

    if (!AxiosConfig.getAuthToken() === null) {
      dispatch({
        type: "USER_CHECK_SESSION_REJECTED",
        payload: { error: "No token" },
      });
    }
    const url = "/api/users/check_session.json";

    return axios
      .get(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "USER_CHECK_SESSION_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "USER_CHECK_SESSION_REJECTED",
          payload: error,
        });
      });
  };
}

/**
 *
 * @param email
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export function autologin(email, user) {
  return async (dispatch) => {
    dispatch({ type: "USER_AUTOLOGIN" });

    const url = "/api/users/admin_login.json";

    return await axios
      .post(
        url,
        {
          email,
        },
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        AxiosConfig.setAuthToken(response.data.token);
        dispatch({
          type: "USER_AUTOLOGIN_FULFILLED",
          payload: response.data,
          user,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "USER_AUTOLOGIN_REJECTED",
          payload: error,
        });
      });
  };
}

/**
 *
 * @param email
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export function autologout(user) {
  return async (dispatch) => {
    AxiosConfig.setAuthToken(user.token);
    await dispatch({
      type: "USER_AUTOLOGOUT",
      payload: {
        user: user.model.model,
        token: user.model.token,
        plan: user.model.plan,
      },
    });
  };
}

/**
 *
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export function resendActivationToken() {
  return function (dispatch) {
    dispatch({ type: "USER_RESEND_TOKEN" });

    const url = "/api/users/resend_activation.json";

    return axios
      .get(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "USER_RESEND_TOKEN_FULFILLED",
        });
      })
      .catch(function (error) {
        dispatch({
          type: "USER_RESEND_TOKEN_REJECTED",
          payload: error,
        });
      });
  };
}

/**
 *
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export function changeOrganization(orgUUID) {
  return function (dispatch) {
    dispatch({ type: "USER_SWITCH_ORG", payload: { orgUUID } });

    const url = "/api/users/set_current_organization.json";

    const data = {
      organization_uuid: orgUUID,
    };

    return axios
      .put(url, data, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "USER_SWITCH_ORG_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "USER_SWITCH_ORG_REJECTED",
          payload: error,
        });
      });
  };
}

/**
 *
 * @param data
 * @returns {(function(*): void)|*}
 */
export function setUser(data) {
  return function (dispatch) {
    dispatch({ type: "USER_SET_DATA", payload: data });
  };
}
