/**
 * Created by piotr.pozniak@thebeaverhead.com on 11/04/2023.
 */

import React, { useCallback, useContext, useMemo } from "react";
import PropTypes from "prop-types";
import Separated from "./Separated";
import Conjunction from "./Conjunction";
import AddButton from "./AddButton";
import Rule from "./Rule";
import { ConjunctionContext, FiltersContext } from "./contexts";

const FilterGroup = ({ rules, conjunction, groupId }) => {
  const [hovered, setHovered] = React.useState(false);
  const { updateGroup, filters: _originalFilters } = useContext(FiltersContext);

  /**
   *
   * @type {(function(*): void)|*}
   */
  const onRemoveRule = useCallback(
    (id) => {
      // remove rules array item under id index
      rules.splice(id, 1);

      updateGroup(groupId, { conjunction, rules: [...rules] });
    },
    [rules, updateGroup]
  );

  /**
   *
   * @type {(function(): void)|*}
   */
  const onConjunctionHover = useCallback(() => {
    setHovered(!hovered);
  }, [hovered]);

  /**
   *
   * @type {(function(*): void)|*}
   */
  const onConjunctionChange = useCallback(
    (val) => {
      setHovered(false);
      updateGroup(groupId, {
        ..._originalFilters.groups[groupId],
        conjunction: val,
      });
    },
    [conjunction, _originalFilters.conjunction]
  );

  const filters = useMemo(
    () =>
      rules.map((rule, index) => {
        return (
          <Rule
            key={`${groupId}-r-${index}`}
            groupId={groupId}
            id={index}
            rule={rule}
            onRemoveRule={onRemoveRule}
          />
        );
      }),
    [rules, updateGroup]
  );

  return (
    <ConjunctionContext.Provider value={{ hovered }}>
      <div className={"filters__filter-group-container"}>
        <div className={"filters__filter-group"}>
          <Separated
            id={groupId}
            separator={
              <Conjunction
                value={conjunction}
                onChange={onConjunctionChange}
                toggleHovered={onConjunctionHover}
              />
            }
          >
            {filters}
          </Separated>
        </div>
        <AddButton groupId={groupId}>
          <div className={"filter-group__add-button"}>+</div>
        </AddButton>
      </div>
    </ConjunctionContext.Provider>
  );
};

const RulePropTypes = {
  field: PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  operator: PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

FilterGroup.defaultProps = {};
FilterGroup.propTypes = {
  groupId: PropTypes.number.isRequired,
  rules: PropTypes.arrayOf(PropTypes.shape(RulePropTypes)).isRequired,
};
export default FilterGroup;
