import { getWebBrowserLanguageCode } from "./general";

export const suppportedLanguages = Object.keys(
  process.env.WIDGET_TRANSLATIONS.calendar
);

export const getTranslationLanguage = () => {
  if (suppportedLanguages.includes(window.navigator.language)) {
    return window.navigator.language;
  }

  let lang = getWebBrowserLanguageCode();

  if (suppportedLanguages.includes(lang)) {
    return lang;
  }
  return "en";
};
