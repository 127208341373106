/**
 * Created by piotr.pozniak@thebeaverhead.com on 11/04/2022
 */

import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import LoadingIndicator from "../Outlined/LoadingIndicator";
import OutlinedCard from "./OutlinedCard";
import { GT } from "../../../../consts";
document.body.setScaledFont = function (f) {
  //this.style.fontSize = fs + "%";
  return this;
};

const Outlined = (props) => {
  const { group, items } = props;

  // dynamic font size
  const windowResizerObserver = useRef(null);

  useEffect(() => {
    // console.log(hasAggressiveResponsiveness, observer.current);

    if (!windowResizerObserver.current) {
      if (items.collection.length && typeof ResizeObserver === "function") {
        // console.log('new observer')
        windowResizerObserver.current = new ResizeObserver((e) => {
          const selector = document.querySelector(".dce--outlined-header");

          if (selector) {
            let s = selector.offsetWidth,
              fs = s * 0.95;
            document
              .querySelectorAll(".dce--outlined-header")
              .forEach((card) => {
                card.style.fontSize = fs + "%";
              });
          }
        });

        windowResizerObserver.current.observe(
          document.querySelector(".dce--outlined-header")
        );
      }
    } /*else {
      windowResizerObserver.current.disconnect();
    }*/

    return () => {
      if (windowResizerObserver.current) {
        windowResizerObserver.current.disconnect();
      }
    };
  }, [items.collection]);

  const cards = items.collection.map((i, idx) => {
    return (
      <OutlinedCard
        item={i}
        key={`tcl-${idx}`}
        onSelectedEvent={props.onSelectedEvent}
      />
    );
  });

  if (items.fetch || !props.initialized) {
    return <LoadingIndicator />;
  }

  return <div className={`dce--outlined dce--${GT.outlined}`}>{cards}</div>;
};

Outlined.defaultProps = {};

Outlined.propTypes = {
  onSelectedEvent: PropTypes.func,
};

export default Outlined;
