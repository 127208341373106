/**
 * Created by piotr.pozniak@thebeaverhead.com on 05/04/2022
 */

import React, { useContext } from "react";
import Card from "./Card";
import { GT, ST } from "../../../../consts";
import LoadingIndicator from "../CardView/LoadingIndicator";
import PropTypes from "prop-types";
import WidgetSettingsContext from "../../../../contexts/WidgetSettingsContext";

const CardView = (props) => {
  const { widget } = props;
  const widgetSettingsContext = useContext(WidgetSettingsContext);

  const hoverEffect = widgetSettingsContext["cwHoverEffect"];
  const style = widgetSettingsContext["cwStyle"];

  const items = props.items.collection.map((i, idx) => {
    return (
      <Card
        key={i.id}
        item={i}
        hoverEffect={hoverEffect}
        onSelectedEvent={props.onSelectedEvent}
        style={style}
      />
    );
  });

  const content =
    props.items.fetch || !props.initialized ? (
      <LoadingIndicator
        cols={
          widget.model &&
          widget.model.widget_settings &&
          widget.model.widget_settings.design
            ? widget.model.widget_settings.design.cwColsCount
            : "1fr 1fr 1fr"
        }
      />
    ) : (
      <div className={`dce--signups-cards dce--${ST.cardGrid}`}>{items}</div>
    );

  return content;
};

CardView.defaultProps = {};

CardView.propTypes = {
  embedded: PropTypes.bool,
  widget: PropTypes.object,
  items: PropTypes.object,
  initialized: PropTypes.bool,
  onLoadMore: PropTypes.func,
  previewType: PropTypes.string,

  onSelectedEvent: PropTypes.func,
};

export default CardView;
