/**
 * Created by piotr.pozniak@thebeaverhead.com on 05/04/2022
 */

import React, { useContext } from "react";
import CardGroup from "./CardGroup";
import { GT } from "../../../../consts";
import LoadingIndicator from "../CardView/LoadingIndicator";
import PropTypes from "prop-types";
import WidgetSettingsContext from "../../../../contexts/WidgetSettingsContext";

const CardView = (props) => {
  const { group } = props;

  const widgetSettingsContext = useContext(WidgetSettingsContext);

  const hoverEffect = widgetSettingsContext["cwHoverEffect"];

  const items = props.items.collection.map((i, idx) => {
    return (
      <CardGroup
        key={i.id}
        group={i}
        hoverEffect={hoverEffect}
        onSelectedEvent={props.onSelectedEvent}
      />
    );
  });

  const content =
    props.items.fetch || !props.initialized ? (
      <LoadingIndicator
        cols={
          group.model &&
          group.model.widget_settings &&
          group.model.widget_settings.design
            ? group.model.widget_settings.design.cwColsCount
            : "1fr 1fr 1fr"
        }
      />
    ) : (
      <div className={`dce--groups-cards dce--${GT.card}`}>{items}</div>
    );

  return content;
};

CardView.defaultProps = {};

CardView.propTypes = {
  embedded: PropTypes.bool,
  group: PropTypes.object,
  items: PropTypes.object,
  initialized: PropTypes.bool,
  onLoadMore: PropTypes.func,
  previewType: PropTypes.string,

  onSelectedEvent: PropTypes.func,
};

export default CardView;
