/**
 * Created by piotr.pozniak@thebeaverhead.com on 04/04/2024
 */

import React from "react";
import PropTypes from "prop-types";
import ShareButton from "./ShareButton";

const ShareButtonFacebook = ({ url, title, text }) => {
  const fbShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;

  return (
    <ShareButton url={fbShareUrl} title={title} text={text} type={"facebook"} />
  );
};

ShareButtonFacebook.defaultProps = {};

ShareButtonFacebook.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
};

export default ShareButtonFacebook;
