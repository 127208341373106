/**
 * Created by piotr.pozniak@thebeaverhead.com on 19/04/2023
 */

import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";
import LoadingIndicator from "../../calendar/components/LoadingIndicator";
import { AvailableIntegrations } from "../consts/integrations";
import { useRouteMatch } from "react-router";
import { useIntegrationStore } from "../../../hooks/redux/integration";
import { IT } from "../../../consts";
import PCOForm from "./IntegrationAccessCheckPage/PCOForm";
import BreezeForm from "./IntegrationAccessCheckPage/BreezeForm";
import CCBForm from "./IntegrationAccessCheckPage/CCBForm";
import GoogleForm from "./IntegrationAccessCheckPage/GoogleForm";
import { useAppStore } from "../../../hooks/redux/app";
import { isArray } from "../tools";
import { useIntegrationsStore } from "../../../hooks/redux/integrations";
import appConfig from "../../../appConfig";

const ErrorForms = {
  [IT.pco]: PCOForm,
  [IT.breeze]: BreezeForm,
  [IT.ccb]: CCBForm,
  [IT.google]: GoogleForm,
};

const IntegrationAccessCheckPage = (props) => {
  const [showLoading, setShowLoading] = useState(true);

  const match = useRouteMatch();
  const integrationUUID = match.params.uuid;
  const { clearErrors } = useAppStore();
  const { integration, checkAccess } = useIntegrationStore();
  const { integrations } = useIntegrationsStore();
  const history = useHistory();

  const integrationData =
    integrations &&
    integrations.collection &&
    integrations.collection.find((e) => e.uuid == integrationUUID);

  const isAnySyncedCalendar =
    integrationData &&
    integrationData.type === IT.google &&
    integrationData.settings &&
    integrationData.settings.calendars &&
    integrationData.settings.calendars.some((e) => e.sync == true);

  const integrationType = useMemo(
    () => AvailableIntegrations.find((i) => i.type === match.params.type),
    [match.params.type]
  );

  useEffect(() => {
    checkAccess(integrationUUID);

    return clearErrors;
  }, []);

  useEffect(() => {
    if (
      integration.checkAccessSuccess &&
      (!integration.accessErrors ||
        (isArray(integration.accessErrors) && !integration.accessErrors.length))
    ) {
      if (!isAnySyncedCalendar && integrationType.type === IT.google) {
        history.push(`/integrations/select-calendars/${integrationUUID}`);
      } else {
        history.push(`/integrations?autosync=${integrationUUID}`);
      }
    } else if (
      integration.checkAccessError ||
      (integration.checkAccessSuccess && integration.accessErrors)
    ) {
      setShowLoading(false);
    }
  }, [integration.checkAccessSuccess, integration.checkAccessError]);

  const indicator = useMemo(() => {
    return showLoading ? (
      <div className={""}>
        <LoadingIndicator />
      </div>
    ) : null;
  }, [showLoading]);

  const title = useMemo(() => {
    return showLoading
      ? `Verifying access to ${integrationType.title} API`
      : "Something went wrong";
  }, [showLoading]);

  const form = useMemo(() => {
    const FormComponent = ErrorForms[integrationType.type];

    return !showLoading ? (
      <FormComponent
        integration={integration}
        integrationType={integrationType}
      />
    ) : null;
  }, [showLoading, integration]);

  const goToIntegrationPageButton = useMemo(() => {
    return !showLoading ? (
      <div className="d-flex flex-column align-items-center mt-5">
        <Link to={`/integration/${integrationUUID}`}>
          <button className={"btn btn-outline-secondary"}>
            Go to integration setup
          </button>
        </Link>
      </div>
    ) : null;
  }, [showLoading]);

  return (
    <div className="main-wrapper">
      <div className="account-page">
        <div className="container">
          <div className="account-logo">
            <Link to="/">
              <img
                src={`/img/branding/${appConfig.branding}/full_length_logo.png`}
                alt={appConfig.displayName}
              />
            </Link>
          </div>
          <div className="account-box">
            <div className="account-wrapper">
              <h3 className="account-title">{title}</h3>
              {indicator}

              <div>
                {form}
                {goToIntegrationPageButton}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

IntegrationAccessCheckPage.defaultProps = {};

IntegrationAccessCheckPage.propTypes = {};

export default IntegrationAccessCheckPage;
