/**
 * Created by piotr.pozniak@thebeaverhead.com on 29/06/2022.
 */

/**
 *
 * @param filters
 * @returns {string}
 */
export const filtersToQueryParam = (filters) => {
  return Object.keys(filters)
    .map((kind) => ({
      field: kind,
      value: filters[kind].platform_metadata_id,
    }))
    .filter((i) => i.value);
  //.flat();
  // .join(",");
};
