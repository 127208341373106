/**
 * Created by piotr.pozniak@thebeaverhead.com on 22/02/2019.
 */

import axios from 'axios';
import AxiosConfig from '../AxiosConfig';


/**
 *
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export function fetchCoupons(page = 1, ipp = 20) {
  return function(dispatch) {

    dispatch({type: "COUPONS_FETCH", payload: {page, ipp}});

    const url = '/api/coupons.json?' + AxiosConfig.objectToURLQuery({page, ipp});

    return axios.get(
      url,
      AxiosConfig.getAuthConfig()
    )
      .then((response) => {

        dispatch({
          type: "COUPONS_FETCH_FULFILLED",
          payload: response.data
        });

      })
      .catch(function(error) {

        dispatch({
          type: "COUPONS_FETCH_REJECTED",
          payload: error,
        });

      });
  }
}


/**
 *
 * @param data
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export function updateCoupon(uuid, data) {
  return function(dispatch) {

    dispatch({type: "COUPON_UPDATE"});

    const url = '/api/coupons' + (uuid ? '/' + uuid : '') + '.json';
    const action = uuid ? axios.put : axios.post;

    return action(
      url,
      data,
      AxiosConfig.getAuthConfig()
    )
      .then((response) => {

        dispatch({
          type: "COUPON_UPDATE_FULFILLED",
          payload: response.data
        });

      })
      .catch(function(error) {

        dispatch({
          type: "COUPON_UPDATE_REJECTED",
          payload: error,
        });

      });
  }
}


/**
 *
 * @param uuid
 * @param data
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export function deleteCoupon(uuid) {
  return function(dispatch) {

    dispatch({type: "COUPON_DELETE"});

    const url = '/api/coupons/' + uuid + '.json';

    return axios.delete(
      url,
      AxiosConfig.getAuthConfig()
    )
      .then((response) => {

        dispatch({
          type: "COUPON_DELETE_FULFILLED",
          payload: {uuid}
        });

      })
      .catch(function(error) {

        dispatch({
          type: "COUPON_DELETE_REJECTED",
          payload: error,
        });

      });
  }
}
