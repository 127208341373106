/**
 * Created by piotr.pozniak@thebeaverhead.com on 15/12/2022
 */

import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { IntegrationStatus } from "../consts/integrations";
import ExpandableAlert from "./ExpandableAlert";
import PermissionsAlert from "../modals/NewIntegration/PermissionsAlert";
import { useIntegrationsStore } from "../../../hooks/redux/integrations";
import { Link } from "react-router-dom";
import { ucFirst } from "../../../helpers/string";

const IntegrationIssueNotification = (props) => {
  const { integration_uuid } = props;
  // get integration model from the collection from integrations hook store based on the integration_uuid
  const { integrations } = useIntegrationsStore();
  const integration = integrations.collection.find(
    (integration) => integration.uuid === integration_uuid
  );

  const syncError =
    integration &&
    integration.status === IntegrationStatus.syncError &&
    !integration.state
      ? true
      : false;

  const permissionsError =
    integration &&
    integration.status === IntegrationStatus.permissionsRevoked &&
    !integration.state
      ? true
      : null;

  const affectedScopes = useMemo(
    () =>
      integration
        ? props.mandatoryScopes
            .map((scope) => {
              const integrationScope = integration.integration_scopes.find(
                (i) => i.name === scope
              );

              return !integrationScope || !integrationScope.available ? (
                <li key={`${integration.uuid}-se-${scope}`}>
                  <strong className={"alert-permission-scope"}>
                    {ucFirst(scope)}
                  </strong>
                </li>
              ) : null;
            })
            .filter((i) => i)
        : [],
    [integration]
  );

  if (syncError || permissionsError || !affectedScopes.length) {
    return null;
  }

  return (
    <div className={"overlapping-alert-container"}>
      <ExpandableAlert
        errorLevel={"warning"}
        header={
          <span>
            Your {integration.name} does not sync the scopes that are required
            by this widget.
          </span>
        }
        body={
          <React.Fragment>
            <p>The following scopes aren't set for syncing:</p>
            <ul className={"list-unstyled"}>{affectedScopes}</ul>
            <p>
              Ensure your integration configuration syncs all{" "}
              <Link to={`/integration/${integration.uuid}`}>
                required scopes
              </Link>
              .
            </p>
          </React.Fragment>
        }
        icon={"link_off"}
      />
    </div>
  );
};

IntegrationIssueNotification.defaultProps = {};

IntegrationIssueNotification.propTypes = {
  integration_uuid: PropTypes.string,
  mandatoryScopes: PropTypes.arrayOf(PropTypes.string),
};

export default IntegrationIssueNotification;
