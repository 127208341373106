/**
 * Created by piotr.pozniak@thebeaverhead.com on 09/06/2021.
 */

import React, { useContext } from "react";
import OptionToggle from "./OptionToggle";
import { BuilderInputSetupPropTypes } from "./BuilderOptionPropTypes";
import PropTypes from "prop-types";
import WidgetSettingsContext from "../../../../../contexts/WidgetSettingsContext";

const CheckboxSetup = (props) => {
  const { settings, defaultValue, title, name, show } = props;
  const widgetSettingsContext = useContext(WidgetSettingsContext);

  if (!show) {
    return null;
  }

  const fieldValue = widgetSettingsContext[name];
  const value = props.control.options.renderValue
    ? props.control.options.renderValue(fieldValue)
    : fieldValue;

  return (
    <OptionToggle
      title={title}
      propertyName={name}
      propertyDefaultValue={value}
      updateDesignProp={props.updateDesignProp}
      settings={settings}
      dependency={props.dependency}
      showDependencyWarning={props.showDependencyWarning}
      help={props.tooltip}
      settingsName={props.settingsName}
    />
  );
};

CheckboxSetup.propTypes = {
  ...BuilderInputSetupPropTypes,

  control: PropTypes.shape({
    kind: PropTypes.oneOf(["checkbox"]).isRequired,
  }).isRequired,
};
export default CheckboxSetup;
