/**
 * Created by piotr.pozniak@thebeaverhead.com on 02/04/2019.
 */

import React from "react";

//import { Link } from "react-router-dom";
//import TableRow from "./TableRow";
import LoadingIndicator from "../../../../calendar/components/LoadingIndicator";
import InvoicesTab from "./InvoicesTab";
import ChargesTab from "./ChargesTab";

const BillingHistory = (props) => {
  const { billing, isLoading } = props;

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <div className="row">
      <div className="col-12">
        <div className={"card-box"}>
          <h4 className="card-title">Payments history</h4>
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <a
                className="nav-link active show"
                href="#invoices"
                data-toggle="tab"
              >
                Invoices
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#charges" data-toggle="tab">
                Charges
              </a>
            </li>
          </ul>

          <div className={"tab-content"}>
            <InvoicesTab invoices={billing.invoices} />
            <ChargesTab charges={billing.charges} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingHistory;
