/**
 * Created by piotr.pozniak@thebeaverhead.com on 17/02/2023.
 */

import axios from "axios";
import AxiosConfig from "../AxiosConfig";
import moment from "moment";

/**
 *
 * @param alertUUID
 * @param from
 * @param to
 * @param limit
 * @param page
 * @param filters
 * @param preservePreviousEvents
 * @param featuredOnly
 * @returns {function(*): Promise<AxiosResponse<any>>}
 */
export const fetchAlertEvents = (
  alertUUID,
  from,
  to = null,
  limit = null,
  page = null,
  filters = [],
  preservePreviousEvents = false
) => {
  return async (dispatch) => {
    dispatch({ type: "ALERT_EVENTS_FETCH" });

    const postData = {
      alert_uuid: alertUUID,
      start_date: from, //moment(from).startOf("day").unix(),
      tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    if (!page) {
      postData.end_date = moment(to).endOf("day").unix();
    }

    if (filters && filters.length) {
      postData["alert_filters"] = filters;
    }

    const queryParams = AxiosConfig.objectToURLQuery({
      page,
      limit,
      uuid: alertUUID,
    });

    const url = "/api4/events/get_alerts_list.json?" + queryParams;
    return await axios
      .post(
        AxiosConfig.getEndpointAddress() + url,
        postData,
        AxiosConfig.getAuthConfigIfAvailable()
      )
      .then((response) => {
        dispatch({
          type: `ALERT_EVENTS_FETCH_FULFILLED`,
          payload: response.data,
          preservePreviousEvents,
        });
      })
      .catch(function (error) {
        dispatch({
          type: `ALERT_EVENTS_FETCH_REJECTED`,
          payload: error,
          preservePreviousEvents,
        });
      });
  };
};

/**
 *
 * @returns {Function}
 */
export function resetEventsCollection() {
  return function (dispatch) {
    dispatch({ type: "ALERT_EVENTS_COLLECTION_RESET" });
  };
}

/**
 *
 * @param slug
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export function fetchEvent(slug) {
  return function (dispatch) {
    dispatch({ type: "ALERT_EVENTS_FETCH" });

    let url = "/api/events/" + slug + ".json";

    return axios
      .get(
        AxiosConfig.getEndpointAddress() + url,
        AxiosConfig.getAuthConfigIfAvailable()
      )
      .then((response) => {
        dispatch({
          type: "ALERT_EVENTS_FETCH_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "ALERT_EVENTS_FETCH_REJECTED",
          payload: error,
        });
      });
  };
}
