import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import HamburderDropdown from "../../component/UI/HamburderDropdown";

/**
 *
 * @param jobType
 * @returns {string}
 */
const jobTypeToLabel = (jobType) => {
  switch (jobType) {
    case "Embedding":
      return "Embedding";

    case "SyncOne":
      return "Sync One";

    case "Integration":
    default:
      return "Sync";
  }
};

const TableRow = (props) => {
  /**
   *
   */

  const { id, created, modified, terminate, pid, job_type } = props.worker;

  /**
   *
   * @type {(function(): void)|*}
   */
  const onTerminate = useCallback(
    (e) => {
      props.onTerminate(id);
      return false;
    },
    [id]
  );

  const disabled = props.disabled;

  const menuOptions = useMemo(
    () => [
      {
        label: "Schedule for termination",
        icon: "delete",
        onClick: onTerminate,
      },
    ],
    [onTerminate]
  );

  return (
    <tr>
      <td>{id}</td>
      <td>{pid}</td>
      <td>{jobTypeToLabel(job_type)}</td>
      <td>{moment(created).format("YYYY-MM-DD HH:mm:ss")}</td>
      <td>{moment(modified).format("YYYY-MM-DD HH:mm:ss")}</td>
      <td>
        {terminate ? (
          <i className="material-icons text-danger">radio_button_checked</i>
        ) : (
          <i className="material-icons text-success">radio_button_checked</i>
        )}
      </td>

      <td className="text-right">
        <HamburderDropdown disabled={disabled} options={menuOptions} />
      </td>
    </tr>
  );
};

TableRow.propTypes = {
  worker: PropTypes.shape({
    id: PropTypes.number.isRequired,
    pid: PropTypes.number.isRequired,
    created: PropTypes.string.isRequired,
    modified: PropTypes.string.isRequired,
    terminate: PropTypes.bool.isRequired,
    job_type: PropTypes.string,
  }).isRequired,

  onTerminate: PropTypes.func.isRequired,
};

export default TableRow;
