/**
 * Created by piotr.pozniak@thebeaverhead.com on 20/08/2018.
 */

import React, { useEffect, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import Tos from "../templates/PageTemplate/Tos";
import LoginEmailForm from "./LoginPage/LoginEmailForm";
import { useUserStore } from "../../../hooks/redux/user";
import AxiosConfig from "./../../../AxiosConfig";
import UnauthorizedUserMobileHelp from "./../component/UnauthorizedUserMobileHelp";
import appConfig from "../../../appConfig";

const LoginPage = (props) => {
  const history = useHistory();
  const { user, checkSession } = useUserStore();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");

    if (token && token !== "" && !user.checkSession) {
      AxiosConfig.setAuthToken(token);
      checkSession();
      history.replace("/login");
    }
  }, [window.location.search]);

  const goToRegister = useCallback((e) => {
    e.preventDefault();
    history.push("/register");
  }, []);

  return (
    <div className="main-wrapper">
      <div className="account-page">
        <div className="container">
          <div className="account-logo">
            <Link to="/">
              <img
                src={`/img/branding/${appConfig.branding}/full_length_logo.png`}
                alt={appConfig.displayName}
              />
            </Link>
          </div>

          <div className="account-box">
            <div className="account-wrapper">
              <h3 className="account-title">Login</h3>

              <LoginEmailForm />

              <div className="d-flex flex-column align-items-center">
                Don't have an account?
                <button
                  className={"btn btn-outline-success"}
                  onClick={goToRegister}
                >
                  Create a free account
                </button>
              </div>

              <UnauthorizedUserMobileHelp />
            </div>
          </div>
          <Tos />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
