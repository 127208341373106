/**
 * Created by piotr.pozniak@thebeaverhead.com on 18/06/2021.
 */

import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as integrationActions from "../../actions/integration";
import { deleteIntegration } from "../../actions/integration";

export function useIntegrationStore() {
  const dispatch = useDispatch();
  const _integration = useSelector((store) => store.integration);
  const _integrations = useSelector((store) => store.integrations);

  const fetchIntegration = useCallback(
    async (uuid) => await dispatch(integrationActions.fetchIntegration(uuid)),
    [dispatch]
  );
  const setIntegrationModel = useCallback(
    async (model) =>
      await dispatch(integrationActions.setIntegrationModel(model)),
    [dispatch]
  );

  const updateIntegration = useCallback(
    async (uuid, model) =>
      await dispatch(integrationActions.updateIntegration(uuid, model)),
    [dispatch]
  );

  const syncIntegration = useCallback(
    async (uuid, removeAll) =>
      await dispatch(integrationActions.syncIntegration(uuid, removeAll)),
    [dispatch]
  );

  const checkAccess = useCallback(
    async (uuid) => await dispatch(integrationActions.checkAccess(uuid)),
    [dispatch]
  );

  const deleteIntegration = useCallback(
    async (uuid) => await dispatch(integrationActions.deleteIntegration(uuid)),
    [dispatch]
  );

  return {
    integration: _integration,
    fetchIntegration,
    setIntegrationModel,
    updateIntegration,
    syncIntegration,
    checkAccess,
    deleteIntegration,
  };
}
