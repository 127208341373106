/**
 * Created by piotr.pozniak@thebeaverhead.com on 05/12/2022
 */

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const IntegrationButton = (props) => {
  return (
    <div
      className={classNames("integration-button", {
        selected: props.isSelected,
      })}
      onClick={props.onSelect}
    >
      <div className={"d-flex justify-flex-start align-items-center"}>
        <img
          className="integration-card--icon m-r-10 m-l-10"
          src={`/img/integrations_icons/${props.icon}`}
          alt={props.title}
        />
        <h4 className="blog-title m-0">{props.title}</h4>
      </div>
    </div>
  );
};

IntegrationButton.defaultProps = {};

IntegrationButton.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
};

export default IntegrationButton;
