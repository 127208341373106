/**
 * Created by piotr.pozniak@thebeaverhead.com on 08/11/2021.
 */

import React from "react";
import AxiosConfig from "../../../AxiosConfig";
import PropTypes from "prop-types";
import appConfig from "../../../appConfig";

const setEnforcedStyle = (node) => {
  if (node) {
    node.style.setProperty("display", "block", "important");
    node.style.setProperty("visibility", "visible", "important");
    node.style.setProperty("minHeight", "11px", "important");
    node.style.setProperty("minHeight", "100%", "important");
    node.style.setProperty("opacity", "1", "important");
    node.style.setProperty("color", "#717171", "important");
    node.style.setProperty("font-size", "11px", "important");
  }
};

const setEnforcedStyleImg = (node) => {
  if (node) {
    node.style.setProperty("display", "inline", "important");
    node.style.setProperty("visibility", "visible", "important");
    node.style.setProperty("min-height", "11px", "important");
    node.style.setProperty("min-width", "11px", "important");
    node.style.setProperty("width", "11px", "important");
    node.style.setProperty("opacity", "1", "important");
    node.style.setProperty("margin-right", "5px");
    node.style.setProperty("filter", "grayscale(100%)", "important");
  }
};

const PoweredBy = (props) => {
  const align = { textAlign: props.align };

  return (
    <div ref={setEnforcedStyle} style={align}>
      <a
        href={appConfig.homepage}
        target="newdcetab"
        ref={setEnforcedStyle}
        style={{ textDecoration: "none" }}
        title={`Powered by ${appConfig.displayName}`}
        alt={`Powered by ${appConfig.displayName}`}
      >
        <p ref={setEnforcedStyle}>
          <img
            src={`${AxiosConfig.getEndpointAddress()}/img/branding/${
              appConfig.branding
            }/icon/apple-icon.png`}
            ref={setEnforcedStyleImg}
          />
          Powered by {appConfig.displayName}
        </p>
      </a>
    </div>
  );
};

PoweredBy.defaultProps = {
  align: "left",
};

PoweredBy.propTypes = {
  align: PropTypes.string,
};

export default PoweredBy;
