/**
 * Created by piotr.pozniak@thebeaverhead.com on 02/02/2024
 */

import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as payoutsActions from "../../actions/payouts";
import {
  createPayout,
  deletePayout,
  markVendorPayoutAsPaid,
} from "../../actions/payout";
import { fetchPayouts } from "../../actions/payouts";

export function usePayoutsStore() {
  const dispatch = useDispatch();
  const _payouts = useSelector((store) => store.payouts);

  const fetchPayouts = useCallback(
    async (f, t) => await dispatch(payoutsActions.fetchPayouts(f, t)),
    [dispatch]
  );

  return {
    payouts: _payouts,
    fetchPayouts,
  };
}
