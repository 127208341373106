/**
 * Created by piotr.pozniak@thebeaverhead.com on 13/10/2018.
 */

import React from "react";

import PropTypes from "prop-types";

const MonthSelector = props => {
  /**
   *
   * @param direction
   * @returns {function(...[*]=)}
   */
  const onDateChange = direction => () => {
    props.onChangeDate(direction);
  };

  return (
    <div className="navigation">
      <div className="arrow prv" onClick={onDateChange(-1)}>
        <i className="material-icons">keyboard_arrow_left</i>
      </div>

      <div className="arrow next" onClick={onDateChange(1)}>
        <i className="material-icons">keyboard_arrow_right</i>
      </div>
    </div>
  );
};

MonthSelector.propTypes = {
  onChangeDate: PropTypes.func
};

export default MonthSelector;
