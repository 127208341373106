/**
 * Created by piotr.pozniak@thebeaverhead.com on 15/04/2022
 */

import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as builderActions from "../../actions/builder";

export function useBuilderStore() {
  const dispatch = useDispatch();

  const updateDesignProp = useCallback(
    async (values, path) =>
      await dispatch(builderActions.updateDesignProp(values, path)),
    [dispatch]
  );

  const overwriteDesignProp = useCallback(
    async (values, path) =>
      await dispatch(builderActions.overwriteDesignProp(values, path)),
    [dispatch]
  );

  const overwriteFilterProp = useCallback(
    async (values, path) =>
      await dispatch(builderActions.overwriteFilterProp(values, path)),
    [dispatch]
  );

  return {
    updateDesignProp,
    overwriteDesignProp,
    overwriteFilterProp
  };
}
