/**
 * Created by piotr.pozniak@thebeaverhead.com on 20/10/2022.
 */

import React, { useMemo } from "react";
import { useIntegrationsStore } from "./../../../../hooks/redux/integrations";
import { IntegrationStatus } from "./../../consts/integrations";
import PermissionsAlert from "./../../modals/NewIntegration/PermissionsAlert";
import ExpandableAlert from "./../../component/ExpandableAlert";
import { Link } from "react-router-dom";

const ErrorStatuses = [
  IntegrationStatus.syncError,
  IntegrationStatus.reAuthRequired,
  IntegrationStatus.permissionsRevoked,
];

const SyncIssuesInfo = (props) => {
  const { integrations } = useIntegrationsStore();

  const hasInvalidIntegration = useMemo(() => {
    return integrations.collection.find(
      (i) => ErrorStatuses.includes(i.status) && i.available
    );
  }, [integrations.collection]);

  if (!hasInvalidIntegration) {
    return null;
  }

  const errors = integrations.collection.map((i, idx) => {
    if (!ErrorStatuses.includes(i.status) || !i.available) {
      return null;
    }

    switch (i.status) {
      case IntegrationStatus.permissionsRevoked:
        return <PermissionsAlert key={`si-${idx}`} integration={i} />;

      case IntegrationStatus.syncError:
        return (
          <ExpandableAlert
            key={`si-${idx}`}
            icon={"error"}
            errorLevel={"danger"}
            header={<span>{i.name} sync failed</span>}
            body={<span>{i.last_error_message}</span>}
          />
        );

      case IntegrationStatus.reAuthRequired:
        return (
          <React.Fragment key={`si-${idx}`}>
            <div className="alert alert-warning">
              <i className={"material-icons icon-sm"}>warning</i> {i.name}{" "}
              session expired.{" "}
              <Link to={"/integration/" + i.uuid}>Re-authenticate</Link>
            </div>
          </React.Fragment>
        );
    }
  });

  return errors;
};

export default SyncIssuesInfo;
