import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

const isSupported = navigator.share ? true : false;

const ShareLink = (props) => {
  const { t } = useTranslation();
  const onShare = async (e) => {
    try {
      e.preventDefault();
      await navigator
        .share({
          title: props.title,
          url: props.url,
          // text: props.text,
        })
        .then((d) => {
          if (props.onShareSuccess) {
            props.onShareSuccess();
          }
        })
        .catch((err) => {
          window.open(`${props.url}?dce-fallback-1`, "__DCE_FALLBACK");
          if (props.onShareError) {
            props.onShareError();
          }
        });
    } catch (err) {
      if (props.onShareError) {
        props.onShareError();
      }
      window.open(`${props.url}?dce-fallback-1`, "__DCE_FALLBACK");
    }
    return false;
  };

  if (!isSupported) {
    return null;
  }

  return (
    <a href={props.url} onClick={onShare} className={"dc-share-button"}>
      <div className={classNames("dc-socials-icon", `dc-socials-icon--more`)}>
        <i className="material-icons">more_horiz</i>
      </div>
      <div className={"dc-share-button--label"}>{t("More")}</div>
    </a>
  );
};

ShareLink.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  onShareSuccess: PropTypes.func,
  onShareError: PropTypes.func,
};

export default ShareLink;
