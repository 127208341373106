import axios from "axios";
import AxiosConfig from "../AxiosConfig";

export function fetch(uuid) {
  return function (dispatch) {
    dispatch({ type: "ORGANIZATION_MEMBERS_FETCH" });

    const url = `/api/organizations_users.json?organization_uuid=${uuid}`;

    return axios
      .get(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "ORGANIZATION_MEMBERS_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "ORGANIZATION_MEMBERS_REJECTED",
          payload: error,
        });
      });
  };
}

export function addOrganizationMember(data) {
  return function (dispatch) {
    dispatch({ type: "ORGANIZATION_MEMBERS_ADD" });

    const url = `/api/organizations_users.json`;

    return axios
      .post(url, data, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "ORGANIZATION_MEMBERS_ADD_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "ORGANIZATION_MEMBERS_ADD_REJECTED",
          payload: error,
        });
      });
  };
}

export function editOrganizationMember(uuid, data) {
  return function (dispatch) {
    dispatch({ type: "ORGANIZATION_MEMBERS_EDIT" });

    const url = `/api/organizations_users/${uuid}.json`;

    return axios
      .put(url, data, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "ORGANIZATION_MEMBERS_EDIT_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "ORGANIZATION_MEMBERS_EDIT_REJECTED",
          payload: error,
        });
      });
  };
}

export function deleteOrganizationMember(uuid) {
  return function (dispatch) {
    dispatch({ type: "ORGANIZATION_MEMBERS_DELETE" });

    const url = `/api/organizations_users/${uuid}.json`;

    return axios
      .delete(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "ORGANIZATION_MEMBERS_DELETE_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "ORGANIZATION_MEMBERS_DELETE_REJECTED",
          payload: error,
        });
      });
  };
}

export function reinviteOrganizationMember(uuid) {
  return function (dispatch) {
    dispatch({ type: "ORGANIZATION_MEMBERS_REINVITE", uuid });

    const url = `/api/organizations_users/resend_invitation/${uuid}.json`;

    return axios
      .post(url, {}, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "ORGANIZATION_MEMBERS_REINVITE_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "ORGANIZATION_MEMBERS_REINVITE_REJECTED",
          payload: error,
        });
      });
  };
}

export function resetReinviteOrganizationMember() {
  return function (dispatch) {
    dispatch({ type: "ORGANIZATION_MEMBERS_REINVITE_RESET" });
  };
}

export function checkInvitation(params) {
  return (dispatch) => {
    dispatch({ type: "CHECK_INVITATION" });

    const url = `/api/organizations_users/check_invitation.json?email=${params.email}&invitation_hash=${params.invitationHash}`;

    return axios
      .get(url, AxiosConfig.getConfig())
      .then((response) => {
        dispatch({
          type: "CHECK_INVITATION_FULFILLED",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "CHECK_INVITATION_REJECTED",
          payload: error,
        });
      });
  };
}

export function acceptInvitation(data) {
  return (dispatch) => {
    dispatch({ type: "ACCEPT_INVITATION" });

    const url = `/api/organizations_users/accept_invitation.json`;

    return axios
      .post(url, data, AxiosConfig.getConfig())
      .then((response) => {
        dispatch({
          type: "ACCEPT_INVITATION_FULFILLED",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "ACCEPT_INVITATION_REJECTED",
          payload: error,
        });
      });
  };
}

export function rejectInvitation(data) {
  return (dispatch) => {
    dispatch({ type: "REJECT_INVITATION" });

    const url = `/api/organizations_users/reject_invitation.json`;

    return axios
      .post(url, data, AxiosConfig.getConfig())
      .then((response) => {
        dispatch({
          type: "REJECT_INVITATION_FULFILLED",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "REJECT_INVITATION_REJECTED",
          payload: error,
        });
      });
  };
}
