/**
 * Created by piotr.pozniak@thebeaverhead.com on 20/10/2022
 */

import React, { useContext } from "react";
import PropTypes from "prop-types";
import { IT } from "./../../../../consts";
import IntegrationContext from "./../../../groups/strategies/IntegrationContext";
import ExpandableAlert from "./../../component/ExpandableAlert";

const NoItemsAlert = (props) => {
  const integrationStrategy = useContext(IntegrationContext);

  const { group } = props;

  const errorBody = (
    <React.Fragment>
      <ul>
        <li>
          What about filters such as{" "}
          {integrationStrategy.availableFilters.join(", ")}? Are they limiting
          your display incorrectly?
        </li>
      </ul>
      <span className={"italic"}>
        This message is only for you. It will not display to other people.
      </span>
    </React.Fragment>
  );

  return (
    <div className={"overlapping-alert-container"}>
      <ExpandableAlert
        icon={null}
        errorLevel={"info"}
        header={
          <span>
            {" "}
            No items to display? Please go to the <strong>Setup tab</strong> and
            look at your <strong> Filtering</strong>.
            <br />
          </span>
        }
        body={errorBody}
      />{" "}
    </div>
  );
};

NoItemsAlert.defaultProps = {};

NoItemsAlert.propTypes = {
  group: PropTypes.object,
};

export default NoItemsAlert;
