/**
 * Created by piotr.pozniak@thebeaverhead.com on 05/10/2023
 */

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useDomainsStore } from "../../../../../hooks/redux/domains";

const NoDomainsWarning = ({ widgetTypeName }) => {
  const { domains } = useDomainsStore();

  if (domains.collection.length) {
    return null;
  }

  return (
    <div
      className="alert alert-danger alert-dismissible fade show alert-tab"
      role="alert"
    >
      Assign <Link to={"/domains"}>website</Link> before embedding this{" "}
      {widgetTypeName}.
    </div>
  );
};

NoDomainsWarning.defaultProps = {
  widgetTypeName: "widget",
};

NoDomainsWarning.propTypes = {
  widgetTypeName: PropTypes.string,
};

export default NoDomainsWarning;
