/**
 * Created by piotr.pozniak@thebeaverhead.com on 18/08/2021.
 */

import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useIntegrationStore } from "../../../../hooks/redux/integration";
import { useUserStore } from "../../../../hooks/redux/user";
import LoadingIndicator from "../../../calendar/components/LoadingIndicator";
import { openAuthWindow } from "../../consts/pco";
import ApiError from "../../component/ApiError";
import FormError from "../../component/FormError";
import { IntegrationStatus } from "../../consts/integrations";
import ReAuthButton from "../../component/Integration/ReAuthButton";
import { IT, PLANS } from "../../../../consts";
import Scopes from "./Scopes";
import WelcomeCard from "./WelcomeCard";
import OpenAIForm from "./OpenAIForm";
import classname from "classnames";
import CalendarsSelectionCard from "./GoogleCalendar/CalendarsSelectionCard ";
import appConfig from "../../../../appConfig";
import useQuery from "../../../../hooks/useQuery";
import HelpAnchor from "../../component/HelpAnchor";
import FeatureFlag from "../../component/FeatureFlag";

export const GoogleIntegrationHelp = () => {
  return (
    <FeatureFlag name={"gcalWelcomeInfo"}>
      <p>
        To learn more about the Google Calendar integration, please click on{" "}
        <HelpAnchor
          href={`${appConfig.helpdeskAddress}/en/articles/5669866-display-church-integrations-how-to-set-them-up`}
          target="_blank"
        >
          this address
        </HelpAnchor>
        .
      </p>
    </FeatureFlag>
  );
};

export const GoogleScopes = {
  profile: {
    mandatory: true,
    label: "Public profile",
    description:
      "We will pull public profile information to display your name on the integration card.",
  },
  calendar: {
    mandatory: false,
    label: "Calendar API",
    description: "It will allow us to pull events from your calendars.",
  },
  attachments: {
    mandatory: false,
    label: "Events Attachments (optional)",
    description: (
      <span>
        If you attach an image to an event, we will pull and use it as an event
        cover image. To grant access to your Events attachments, share them with
        the email address: <strong>{appConfig.serviceAccountEmail}</strong>
      </span>
    ),
  },
  places: {
    mandatory: false,
    label: "Places (optional)",
    description:
      "If you set an event location pointing to a place, we will pull and use it for filtering and displaying place details.",
    allowedPlans: [PLANS.starter, PLANS.medium, PLANS.highest],
  },
};

const GoogleIntegrationForm = (props) => {
  const { integration, updateIntegration, syncIntegration } =
    useIntegrationStore();
  const { update, updateError } = integration;
  const [showRedirectUrl, setShowRedirectUrl] = useState(false);

  const integrationModel = useMemo(
    () =>
      integration.model && integration.model.uuid ? integration.model : null,
    [integration]
  );

  const [state, setState] = useState({
    nameError: null,

    showPrivateEventsInfo: integrationModel
      ? integrationModel.sync_private * 1
      : false,
  });

  const onCalendarUpdate = (id, value) => {
    setCalendar((prevCalendars) =>
      prevCalendars.map((calendar) =>
        calendar.id === id ? { ...calendar, sync: value } : calendar
      )
    );
  };

  const scopes = Object.keys(GoogleScopes).map((i) => {
    const existingScope = integrationModel?.integration_scopes.find(
      (s) => s.name === i
    );
    let available = 0;

    if (integrationModel && !existingScope) {
      available = 0;
    } else if (integrationModel && existingScope) {
      available = existingScope.available;
    } else if (!integrationModel) {
      available = 1;
    }

    return {
      name: i,
      available,
    };
  });
  /*integrationModel
    ? integrationModel.integration_scopes
    : Object.keys(GoogleScopes).map((i) => ({ name: i, available: 1 }));
*/
  const [calendar, setCalendar] = useState(null);

  const query = useQuery();
  const selectCalendars = query.get("select-calendars");

  useLayoutEffect(() => {
    const section = document.querySelector("#dc-google-calendar-form");
    if (section && parseInt(selectCalendars)) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  }, [selectCalendars, integrationModel]);

  useEffect(() => {
    if (
      integrationModel &&
      integrationModel.settings &&
      integrationModel.settings.calendars
    ) {
      setCalendar(integrationModel.settings.calendars);
    }
  }, [integrationModel]);

  const isNew = !integrationModel ? true : false;

  useEffect(() => {
    if (
      integration.updateSuccess &&
      integrationModel.uuid &&
      integrationModel.status !== IntegrationStatus.reAuthRequired
    ) {
      syncIntegration(integrationModel.uuid);
    }
  }, [integration.updateSuccess]);

  useEffect(() => {
    if (
      integration.updateSuccess &&
      integrationModel.status === IntegrationStatus.reAuthRequired
    ) {
      openAuthWindow(integrationModel.auth_url);
      setShowRedirectUrl(true);
    }
  }, [integration.updateSuccess]);

  /**
   *
   * @param e
   */
  const onSubmit = async (e) => {
    e.preventDefault();

    const name = "Google Calendar Integration"; //document.querySelector("#integration_name").value;
    let nameError = null;

    const available =
      integrationModel && integrationModel.uuid
        ? integrationModel.available
        : 1;
    /*
    if (!name || !name.length) {
      nameError =
        "Please enter a memorable integration name (can be anything).";
    }*/
    const integration_scopes = scopes.map((i) => {
      const scopeCheckbox = document.getElementById(`google-scope-${i.name}`);
      return {
        uuid: i.uuid,
        name: i.name,
        available: scopeCheckbox && scopeCheckbox.checked ? 1 : 0,
      };
    });

    const data = {
      //...integrationModel,
      type: "google",
      name: name,
      available: available,
      username: isNew ? undefined : integrationModel.username,
      integration_scopes,
      //sync_private: integrationModel.sync_private,
      settings: {
        calendars: calendar,
      },
    };
    setState({
      ...state,
      nameError,
    });

    await updateIntegration(isNew ? null : integrationModel.uuid, data);
    /*
    if (!nameError) {
    }*/
  };

  const onReauth = (e) => {};

  const loadingIndicator = update ? <LoadingIndicator /> : null;
  const isLoading = update;

  const welcomeInfo = 1 ? (
    <div className="howto-card card-box">{GoogleIntegrationHelp()}</div>
  ) : null;

  const authorize = isNew ? (
    <img
      style={{ width: "250px" }}
      className={"btn"}
      onClick={onSubmit}
      disabled={isLoading}
      src={"/img/btn_google_signin_light_normal_web@2x.png"}
    />
  ) : !showRedirectUrl ? (
    <img
      style={{ width: "250px" }}
      className={"btn"}
      onClick={onSubmit}
      disabled={isLoading}
      src={"/img/btn_google_signin_light_normal_web@2x.png"}
    />
  ) : (
    <span>
      You were not redirected to Google?{" "}
      <a href={integrationModel.auth_url}>Click here to finish the setup.</a>
    </span>
  );

  const openAIForm = !isNew ? <OpenAIForm /> : null;

  const calendarSyncingIndicator = useMemo(
    () =>
      integration.sync || !(integration.model && integration.model.settings) ? (
        <div className={"col-xs-12 col-md-12"}>
          Reading your Google calendars…
          <LoadingIndicator />
        </div>
      ) : null,
    [integration.sync, integration.model]
  );

  const calendarsForm =
    !isNew && integrationModel?.settings?.calendars ? (
      <CalendarsSelectionCard
        calendars={
          integrationModel &&
          integrationModel.settings &&
          integrationModel.settings.calendars
            ? integrationModel.settings.calendars
            : []
        }
        handleCalendarCheckboxToggle={onCalendarUpdate}
        error={[state.nameError]}
        loadingIndicator={loadingIndicator}
        calendarSyncingIndicator={calendarSyncingIndicator}
        onSubmit={onSubmit}
      />
    ) : null;

  return (
    <React.Fragment>
      <div className={"col-xs-12 col-md-12"}>
        <WelcomeCard
          integrationName={"Google Calendar"}
          imageSrc={"/img/integrations_howto/howto_gcal.png"}
          helpArticleUrl={`${appConfig.helpdeskAddress}/en/articles/5669866-display-church-integrations-how-to-set-them-up`}
          videoId={"c916234f26444ad2afbf1a71de074561"}
        >
          <p>
            To synchronize with Google Calendar, sign in to Google with your
            account and grant {appConfig.displayName} access to the following
            services:
          </p>
          <ul>
            <li>
              <strong>Calendar API</strong> - it will allow us to pull events
              from your calendars.
            </li>
            <li>
              <strong>Events Attachments (optional)</strong> - if you attach an
              image to an event, we will pull and use it as an event cover
              image. To grant access to your Events attachments, share them with
              the email address:{" "}
              <strong>{appConfig.serviceAccountEmail}</strong>
            </li>
            <li>
              <strong>Places (optional)</strong> - If you set an event location
              pointing to a place, we will pull and use it for filtering and
              displaying place details.
            </li>
            <li>
              <strong>Public profile</strong> - we will pull public profile
              information to display your name on the integration card.
            </li>
          </ul>
        </WelcomeCard>
      </div>

      <div
        className={classname("col-xs-12 col-md-12", {
          "col-lg-6": !isNew && integrationModel?.settings?.calendars,
        })}
      >
        <div className={"card pt-sm-4 pl-sm-4 pr-sm-4"}>
          <form className={"card-body"}>
            <Scopes
              scopes={scopes}
              definitions={GoogleScopes}
              idPrefix={"google-scope-"}
            />
            <div className={"col-xs-12 col-md-12"}>
              <div className="m-t-20 text-center">
                <div className="form-group">
                  {/*{newSyncAlert}*/}
                  {authorize}
                </div>
              </div>
            </div>
            <div className={"col-xs-12"}>
              <ApiError
                error={updateError}
                defaultErrorMessage={"Could not add Api User. Please try again"}
              />

              <FormError errors={[state.nameError]} />
            </div>
            {loadingIndicator}
          </form>
        </div>
      </div>
      <div
        className={classname("col-xs-12", {
          "col-lg-6": !isNew && integrationModel?.settings?.calendars,
        })}
        id="dc-google-calendar-form"
      >
        {calendarsForm}
      </div>

      {openAIForm}
    </React.Fragment>
  );
};

export default GoogleIntegrationForm;
