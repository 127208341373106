import React from "react";
import PropTypes from "prop-types";

const Footer = ({ onRestore, onSubmit, disableSubmit }) => {
  const restoreButton = !disableSubmit ? (
    <button className="btn btn-secondary" onClick={onRestore}>
      Restore
    </button>
  ) : (
    true
  );
  return (
    <div className="d-flex gap-xs">
      {restoreButton}
      <button
        className="btn btn-primary"
        onClick={onSubmit}
        disabled={disableSubmit}
      >
        Save Changes
      </button>
    </div>
  );
};

Footer.propTypes = {
  onRestore: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default Footer;
