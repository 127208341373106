/**
 * Created by piotr.pozniak@thebeaverhead.com on 19/10/2018.
 */

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { Link, useHistory } from "react-router-dom";
import ApiError from "../component/ApiError";
import { updateJquery } from "../tools";
import { clearErrors } from "../../../actions/main";
import Tos from "../templates/PageTemplate/Tos";
import { store } from "../store";
import { useUserStore } from "../../../hooks/redux/user";
import appConfig from "../../../appConfig";

const PasswordResetPage = (props) => {
  const email = props.match.params.email;
  const token = props.match.params.token;

  const history = useHistory();

  const [state, setState] = useState({
    password: "",
  });

  const { resetPassword } = useUserStore();

  useEffect(() => {
    updateJquery();

    return props.clearErrors();
  }, []);

  /**
   *
   */
  const onLoginClick = async (e) => {
    e.preventDefault();

    await resetPassword(email, token, state.password);

    if (store.getState().user.resetPasswordSuccess) {
      props.clearErrors();
      history.push("/dashboard");
    }
    return false;
  };

  /**
   *
   * @param e
   */
  const onChangePassword = (e) => {
    setState({ ...state, password: e.target.value });
  };

  const isLoading = props.user.resetPassword;

  return (
    <div className="main-wrapper">
      <div className="account-page">
        <div className="container">
          <div className="account-logo">
            <Link to="/">
              <img
                src={`/img/branding/${appConfig.branding}/full_length_logo.png`}
                alt={appConfig.displayName}
              />
            </Link>
          </div>
          <div className="account-box">
            <div className="account-wrapper">
              <h3 className="account-title">Change Password</h3>

              <form action="/">
                <div className="form-group form-focus">
                  <label className="focus-label">Email</label>
                  <input
                    className="form-control floating"
                    type="email"
                    value={email}
                    disabled={true}
                  />
                </div>

                <div className="form-group form-focus">
                  <label className="focus-label">New Password</label>
                  <input
                    className="form-control floating"
                    type="password"
                    value={state.password}
                    autoFocus={true}
                    onChange={onChangePassword}
                    disabled={isLoading}
                    autoComplete="off"
                  />
                </div>

                <div className="form-group m-b-0 text-center">
                  <button
                    className="btn btn-primary btn-block account-btn"
                    type="submit"
                    onClick={onLoginClick}
                    disabled={isLoading}
                  >
                    Change Password
                  </button>
                </div>
              </form>
              <ApiError
                error={props.user.resetPasswordError}
                defaultErrorMessage={
                  "Could not reset password. Please try again"
                }
              />
            </div>
          </div>
          <Tos />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (store) => ({
  main: store.main,
  user: store.user,
});

const mapDispatchToProps = (dispatch) => ({
  clearErrors: () => dispatch(clearErrors()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordResetPage);
