/**
 * Created by piotr.pozniak@thebeaverhead.com on 19/02/2024
 */

import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import classNames from "classnames";

const HamburgerItem = ({ icon, label, href, onClick, disabled }) => {
  /**
   *
   * @type {(function(*): void)|*}
   * @private
   */
  const _onClick = useCallback(
    (e) => {
      if (onClick) {
        e.preventDefault();
        // e.stopPropagation();

        onClick(e);
      }
    },
    [onClick]
  );

  return (
    <Link
      className={classNames("dropdown-item d-flex", {
        "pointer-events-none": disabled,
        "text-muted": disabled,
        "cursor-wait": disabled,
      })}
      to={href || "#"}
      title="Delete"
      onClick={_onClick}
      disabled={disabled}
    >
      <i className="material-icons icon-sm m-r-5" aria-hidden={true}>
        {icon}
      </i>{" "}
      {label}
    </Link>
  );
};

HamburgerItem.defaultProps = {
  disabled: false,
};

HamburgerItem.propTypes = {
  icon: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  href: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default HamburgerItem;
