/**
 * Created by piotr.pozniak@thebeaverhead.com on 05/10/2023
 */

import React from "react";
import PropTypes from "prop-types";
import ClipboardCopyInput from "../../../component/ClipboardCopyInput";
import NoDomainsWarning from "./NoDomainsWarning";
import {
  getInstallDivTag,
  getInstallIframeTag,
  installScripTag,
} from "./helpers";

const QuickEmbed = ({ widgetTypeName, widgetType, uuid }) => {
  return (
    <form action="#">
      <NoDomainsWarning widgetTypeName={widgetTypeName} />

      <div className="col-xs-12">
        <p>
          <em>
            This option is not ideal if you are using multiple {widgetTypeName}s
            per website page. If you wish to try the Quick setup anyway,
            copy/paste the following code where you would like the{" "}
            {widgetTypeName} to appear on your website.
          </em>
        </p>

        <ClipboardCopyInput
          id={"embeddable_script_quick"}
          value={installScripTag + getInstallDivTag(widgetType, uuid)}
          placeholder={`${widgetTypeName} embeddable script`}
        />
        <p className="text-center">
          <br />
          OR
          <br />
        </p>

        <p>
          <em>
            <strong className={"text-danger"}>EXPERIMENTAL</strong> In case your
            website theme is using an aggressive stylesheet and the{" "}
            {widgetTypeName}
            does not display nicely on your website, copy/paste the following
            code which will embed the {widgetTypeName} in the iframe on your
            website:
          </em>
        </p>
        <ClipboardCopyInput
          id={"embeddable_div_iframe_quick"}
          value={installScripTag + getInstallIframeTag(widgetType, uuid)}
          placeholder={`${widgetTypeName} embeddable div with iframe`}
        />
      </div>
    </form>
  );
};

QuickEmbed.defaultProps = {
  widgetTypeName: "widget",
};

QuickEmbed.propTypes = {
  widgetTypeName: PropTypes.string,
  widgetType: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
};

export default QuickEmbed;
