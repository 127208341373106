/**
 * Created by piotr.pozniak@thebeaverhead.com on 24/02/2023
 */

import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import Events from "../../../calendar/components/EventsList/Events";
import { useAlertStore } from "../../../../hooks/redux/alert";
import classnames from "classnames";

const EventsPreview = ({ isLoading }) => {
  const { alertEvents } = useAlertStore();
  const [showList, setShowList] = useState(true);

  const toggleList = useCallback(() => {
    setShowList(!showList);
  }, [showList]);

  const eventsContainer = !isLoading ? (
    <Events
      embedded={false}
      events={alertEvents.collection}
      settings={{}}
      selectedEvent={/*props.selectedEvent*/ null}
      calendarSlug={null}
      ccbAddress={null}
      loading={isLoading}
      hasMore={false}
      calendarID={null}
      showWatermark={false}
    />
  ) : null;

  return (
    <div
      className={classnames("card-box alert--events-preview", {
        expanded: showList,
      })}
    >
      <div className={"alert--events-preview_container"}>
        <div
          className={
            "alert--events-preview_toolbar d-flex justify-content-between"
          }
          onClick={toggleList}
        >
          <h4 className={"card-title"}>Events matching filter criteria</h4>
          <h4>
            <i className={"material-icons"}>
              {showList ? "keyboard_arrow_down" : "keyboard_arrow_up"}
            </i>
          </h4>
        </div>

        <div className={" alert--events-preview_events-list"}>
          {eventsContainer}
        </div>
      </div>
    </div>
  );
};

EventsPreview.defaultProps = {};

EventsPreview.propTypes = {
  isLoading: PropTypes.bool,
};

export default EventsPreview;
