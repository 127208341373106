/**
 * Created by piotr.pozniak@thebeaverhead.com on 23/12/2020.
 */

import React, { useMemo } from "react";
import PropTypes from "prop-types";
import LocalTz from "../LocalTz";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../../../helpers/date";

const legacyWeekdayFormatToIntl = (format) => {
  switch (format) {
    case "dd":
      return "narrow";
    case "ddd":
      return "short";
    default:
      return "long";
  }
};
const CardDateFormatter = (props) => {
  const { t } = useTranslation();

  const formattedDate = useMemo(() => {
    const fromDate = new Date(props.startTime * 1000); /*.utc()*/
    const toDate = new Date(props.endTime * 1000); /*.utc()*/
    const showTime = !props.allDayEvent;

    let eventDate = "";

    const weekDayFormatter = props.showWeekDay
      ? { weekday: legacyWeekdayFormatToIntl(props.weekDayFormatter) }
      : {};

    if (props.selectedDateTimeFormat === "compact") {
      eventDate =
        formatDate(fromDate, {
          ...weekDayFormatter,
          month: "short",
          day: "2-digit",
          year: "numeric",
        }) +
        (showTime
          ? ` ${t("from")} ` +
            formatDate(fromDate, { hour: "numeric", minute: "numeric" })
          : "");
      return props.allDayEvent ? `${t("All day")} (${eventDate})` : eventDate;
    } else if (props.selectedDateTimeFormat === "short") {
      if (props.allDayEvent) {
        return t("All day");
      } else {
        eventDate =
          formatDate(fromDate, { hour: "numeric", minute: "numeric" }) +
          " - " +
          formatDate(toDate, { hour: "numeric", minute: "numeric" });
      }
      return eventDate;
    } else {
      eventDate =
        formatDate(fromDate, {
          ...weekDayFormatter,
          month: "long",
          day: "2-digit",
          year: "numeric",
        }) +
        (showTime
          ? ` ${t("from")} ` +
            formatDate(fromDate, { hour: "numeric", minute: "numeric" }) +
            " - " +
            formatDate(toDate, { hour: "numeric", minute: "numeric" })
          : "");
      return props.allDayEvent ? `${t("All day")} (${eventDate})` : eventDate;
    }
  }, [
    props.startTime,
    props.endTime,
    props.allDayEvent,
    props.selectedDateTimeFormat,
    props.weekDayFormatter,
    props.showWeekDay,
  ]);

  const tz = props.allDayEvent ? null : <LocalTz />;
  return (
    <p className={"card-view--card-content-date"}>
      {formattedDate} {tz}
    </p>
  );
};

CardDateFormatter.defaultProps = {
  showWeekDay: false,
  weekDayFormatter: "",
  allDayEvent: false,
  selectedDateTimeFormat: "regular",
};

CardDateFormatter.propTypes = {
  startTime: PropTypes.number.isRequired,
  endTime: PropTypes.number.isRequired,
  showWeekDay: PropTypes.bool,
  weekDayFormatter: PropTypes.string,
  allDayEvent: PropTypes.bool,
  selectedDateTimeFormat: PropTypes.string,
};

export default CardDateFormatter;
