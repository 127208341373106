/**
 * Created by piotr.pozniak@thebeaverhead.com on 06/04/2022
 */

import React, { useContext } from "react";
import PropTypes from "prop-types";
import Row from "./Row";
import LoadingIndicator from "./LoadingIndicator";
import classnames from "classnames";
import { GT, PT } from "../../../../consts";
import { WT } from "../../../admin/consts/widget";

const TwoColumns = (props) => {
  const { items } = props;

  const rows = items.collection.map((i, idx) => {
    return (
      <Row
        item={i}
        key={`tcl-${idx}`}
        onSelectedEvent={props.onSelectedEvent}
      />
    );
  });

  const content =
    items.fetch || !props.initialized ? <LoadingIndicator /> : rows;

  return (
    <div className={`dce--two-columns dce--${GT.twoColumn}`}>{content}</div>
  );
};

TwoColumns.defaultProps = {};

TwoColumns.propTypes = {
  onSelectedEvent: PropTypes.func,
};

export default TwoColumns;
