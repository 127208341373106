import axios from "axios";
import AxiosConfig from "../AxiosConfig";

/**
 *
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export function fetchUsers(
  page = 1,
  ipp = 50,
  name = "",
  email = "",
  sort,
  direction
) {
  return function (dispatch) {
    const queryData = {
      page,
      ipp,
      name,
      email,
      order: sort,
      order_direction: direction,
    };
    dispatch({ type: "USERS_FETCH", payload: queryData });

    const url = "/api/users.json?" + AxiosConfig.objectToURLQuery(queryData);

    return axios
      .get(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "USERS_FETCH_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "USERS_FETCH_REJECTED",
          payload: error,
        });
      });
  };
}

/**
 *
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export function deleteUser(uuid) {
  return function (dispatch) {
    dispatch({ type: "USER_DELETE" });

    const url = "/api/users/" + uuid + ".json";

    return axios
      .delete(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "USER_DELETE_FULFILLED",
          payload: { uuid },
        });
      })
      .catch(function (error) {
        dispatch({
          type: "USER_DELETE_REJECTED",
          payload: error,
        });
      });
  };
}

/**
 *
 * @param uuid
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export function activateAccount(uuid) {
  return function (dispatch) {
    dispatch({ type: "USER_ACTIVATE" });

    const url = "/api/users/activate_account.json";

    return axios
      .put(
        url,
        {
          uuid,
        },
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "USER_ACTIVATE_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "USER_ACTIVATE_REJECTED",
          payload: error,
        });
      });
  };
}
