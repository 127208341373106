/**
 * Created by piotr.pozniak@thebeaverhead.com on 02/09/2022
 */

import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { getEmbeddableSource } from "../../../helpers/launcher";
import BrandingModal from "./BrandingModal";
import appConfig from "../../../appConfig";
import FeatureFlag from "./FeatureFlag";

const ShowBrandingToggle = (props) => {
  const [showBeforeAfterModal, setShowBeforeAfterModal] = useState(false);

  const { price } = props;

  /**
   *
   * @param e
   */
  const onShowBeforeAfterModal = useCallback(
    (e) => {
      setShowBeforeAfterModal(!showBeforeAfterModal);
    },
    [showBeforeAfterModal]
  );

  const beforeAfterModal = showBeforeAfterModal ? (
    <BrandingModal onCloseModal={onShowBeforeAfterModal} />
  ) : null;

  const regularPriceStriked = props.regularPrice ? (
    <span>
      <strike>${props.regularPrice.toFixed(2)}</strike>&nbsp;
    </span>
  ) : null;

  const { isYearly } = props;
  return (
    <div className="branding-toggle-container">
      <div>
        <div className="material-switch ">
          <input
            id={`${props.idPrefix}-is_branded-${props.plan.uuid}`}
            type="checkbox"
            value={1}
            checked={!props.isBranded}
            onChange={props.onChangeBranded}
          />
          <label
            htmlFor={`${props.idPrefix}-is_branded-${props.plan.uuid}`}
            className="badge-primary"
          />
        </div>
      </div>

      <span className={"plan-branding-label"}>
        <span>
          Hide "Powered by {appConfig.shortDisplayName}" Branding&nbsp;(
          {regularPriceStriked}${isYearly ? `${price}/year` : `${price}/month`}){" "}
        </span>

        <span className={"cursor-pointer"} onClick={onShowBeforeAfterModal}>
          <i className={"material-icons text-primary"}>visibility</i>
        </span>
      </span>

      {beforeAfterModal}
    </div>
  );
};

ShowBrandingToggle.defaultProps = {
  idPrefix: "selected-plan",
};

ShowBrandingToggle.propTypes = {
  onChangeBranded: PropTypes.func.isRequired,
  isYearly: PropTypes.bool,
  isBranded: PropTypes.bool,
  plan: PropTypes.shape({ uuid: PropTypes.string }),
  idPrefix: PropTypes.string,
  price: PropTypes.number,
  regularPrice: PropTypes.number,
};

export default ShowBrandingToggle;
