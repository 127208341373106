export const BooleanOptions = [
  {
    id: "yes",
    platform_metadata_id: true,
    value: "true",
    label: "Yes",
    name: "Yes",
    kind: "free",
  },
  {
    id: "no",
    platform_metadata_id: false,
    value: "false",
    label: "No",
    name: "No",
    kind: "free",
  },
];
