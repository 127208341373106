import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

const TableRow = (props) => {
  const { date, description, quote, link } = props;

  return (
    <tr>
      <td>{moment(date * 1000).format("YYYY-MM-DD")}</td>
      <td>{description}</td>
      <td>${(quote / 100).toFixed(2)}</td>
      <td>
        <a href={link} target="_blank">
          <i className="material-icons">receipt</i>
        </a>
      </td>
    </tr>
  );
};

TableRow.propTypes = {
  date: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  quote: PropTypes.number.isRequired,
  link: PropTypes.string.isRequired,
};

export default TableRow;
