/**
 * Created by piotr.pozniak@thebeaverhead.com on 23/07/2021.
 */
import React, { useEffect, useState } from "react";
import ModalTemplate from "./ModalTemplate";
import { useHistory } from "react-router-dom";
import CCBIntegrationForm from "./NewIntegration/CCBIntegrationForm";
import PCOIntegrationForm from "./NewIntegration/PCOIntegrationForm";
import LoadingIndicator from "../../calendar/components/LoadingIndicator";
import { useIntegrationStore } from "../../../hooks/redux/integration";
import { useIntegrationsStore } from "../../../hooks/redux/integrations";
import GoogleIntegrationForm from "./NewIntegration/GoogleIntegrationForm";
import BreezeIntegrationForm from "./NewIntegration/BreezeIntegrationForm";
import { IntegrationStatus } from "../consts/integrations";
import OpenAIForm from "./NewIntegration/OpenAIForm";
import useQuery from "../../../hooks/useQuery";

const integrationTypesMapping = {
  ccb: CCBIntegrationForm,
  pco: PCOIntegrationForm,
  google: GoogleIntegrationForm,
  breeze: BreezeIntegrationForm,
};

const EditIntegrationModal = (props) => {
  const matchParams = props.match.params;

  let query = useQuery();
  const { history } = useHistory();

  const { integrations } = useIntegrationsStore();
  const { integration, setIntegrationModel, syncIntegration } =
    useIntegrationStore();

  const isSyncIntegration = query.get("sync");

  useEffect(() => {
    return () => {
      setIntegrationModel(null);
    };
  }, []);

  useEffect(() => {
    if (parseInt(isSyncIntegration)) {
      syncIntegration(matchParams.id);
    }
  }, [isSyncIntegration]);

  useEffect(() => {
    if (
      integrations.fetchSuccess &&
      !(integration.model && integration.model.settings)
    ) {
      const integration = integrations.collection.find(
        (i) => i.uuid == matchParams.id
      );
      setIntegrationModel(integration);
    }
  }, [integrations.fetchSuccess, integrations]);

  useEffect(() => {
    if (integration.updateSuccess) {
      if (integration.model.status === IntegrationStatus.reAuthRequired) {
        // it should redirect but if not then keep the modal open
      } else {
        setIntegrationModel(null);
        onClose();
      }
    }
  }, [integration.updateSuccess]);

  /**
   *
   * @param e
   */
  const onClose = (e) => {
    props.history.push("/integrations");
  };

  const IntegrationForm = integration.model
    ? integrationTypesMapping[integration.model.type]
    : null;

  const loading =
    integration.fetch || !integration.model ? (
      <div className={"col-xs-12 col-md-12"}>
        <LoadingIndicator />
      </div>
    ) : null;

  const form = integration.model ? <IntegrationForm /> : null;

  return (
    <ModalTemplate
      id={"integrationModal"}
      header={"Update integration"}
      size={"lg"}
      onClose={onClose}
    >
      <div className={"integration-modal"}>
        <div className={"row "}>
          {loading}
          {form}
        </div>
      </div>
    </ModalTemplate>
  );
};

export default EditIntegrationModal;
