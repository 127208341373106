/**
 * Created by piotr.pozniak@thebeaverhead.com on 23/12/2020.
 */

import React from "react";
import PropTypes from "prop-types";

const CardLocationFormatter = (props) => {
  const { event } = props;

  return event.display_location ? (
    <React.Fragment>
      <p>
        <strong>{event.display_location}</strong>
        <br />
        {event.location.line_1 ? (
          <span>
            {event.location.line_1}
            <br />
          </span>
        ) : null}
        {event.location.line_2 ? (
          <span>
            {event.location.line_2}
            <br />
          </span>
        ) : null}
      </p>
    </React.Fragment>
  ) : null;
};

CardLocationFormatter.propTypes = {
  event: PropTypes.shape({
    display_name: PropTypes.string,
    location: PropTypes.shape({
      line_1: PropTypes.string,
      line_2: PropTypes.string,
    }),
  }),
};

export default CardLocationFormatter;
