import axios from "axios";
import AxiosConfig from "../AxiosConfig";

/**
 *
 * @returns {function(*): Promise<AxiosResponse<any>>}
 */
export function fetchAffiliate() {
  return function (dispatch) {
    dispatch({ type: "AFFILIATE_FETCH" });

    const url = "/api4/affiliates.json";

    return axios
      .get(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "AFFILIATE_FETCH_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "AFFILIATE_FETCH_REJECTED",
          payload: error,
        });
      });
  };
}

/**
 *
 * @returns {function(*): Promise<AxiosResponse<any>>}
 */
export function createAffiliate() {
  return function (dispatch) {
    dispatch({ type: "AFFILIATE_CREATE" });

    const url = `/api4/affiliates.json`;

    return axios
      .post(url, {}, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "AFFILIATE_CREATE_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "AFFILIATE_CREATE_REJECTED",
          payload: error,
        });
      });
  };
}

/**
 *
 * @param affiliateId
 * @returns {function(*): Promise<void>}
 */
export function updateAffiliate(affiliateId) {
  return function (dispatch) {
    dispatch({ type: "AFFILIATE_UPDATE" });

    const url = `/api4/affiliates/${affiliateId}.json`;

    return axios
      .put(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "AFFILIATE_UPDATE_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "AFFILIATE_UPDATE_REJECTED",
          payload: error,
        });
      });
  };
}

/**
 *
 * @param affiliateId
 * @returns {function(*): Promise<void>}
 */
export function fetchMagicLink(affiliateId) {
  return function (dispatch) {
    dispatch({ type: "MAGIC_LINK_FETCH" });

    const url = `/api4/affiliates/magic_link/${affiliateId}.json`;

    return axios
      .get(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "MAGIC_LINK_FETCH_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "MAGIC_LINK_FETCH_REJECTED",
          payload: error,
        });
      });
  };
}

/**
 *
 * @param affiliateId
 * @returns {function(*): Promise<void>}
 */
export function resetMagicLink() {
  return function (dispatch) {
    dispatch({ type: "MAGIC_LINK_RESET" });
  };
}

/**
 *
 * @param affiliateId
 * @param linkId
 * @param token
 * @returns {function(*): Promise<void>}
 */
export function updateLinkToken(affiliateId, linkId, token) {
  return function (dispatch) {
    dispatch({ type: "AFFILIATE_LINK_UPDATE" });

    const url = `/api4/affiliates/update-link/${affiliateId}.json`;

    const data = {
      link_id: linkId,
      token,
    };

    return axios
      .put(url, AxiosConfig.getAuthConfig(), data)
      .then((response) => {
        dispatch({
          type: "AFFILIATE_LINK_UPDATE_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "AFFILIATE_LINK_UPDATE_REJECTED",
          payload: error,
        });
      });
  };
}
