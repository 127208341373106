/**
 * Created by piotr.pozniak@thebeaverhead.com on 11/04/2022
 */

import React, { useContext } from "react";
import PropTypes from "prop-types";
import PreloadImage from "../PreloadImage";
import classNames from "classnames";
import WidgetSettingsContext from "../../../../contexts/WidgetSettingsContext";
const EffectFlip = "dce--outlined-card_effect-flip";

const OutlinedCard = (props) => {
  const { item } = props;
  const widgetSettingsContext = useContext(WidgetSettingsContext);

  const showPopup = Number.parseInt(widgetSettingsContext["gPopupShow"]) === 1;
  const hoverEffect = widgetSettingsContext["gOHoverEffect"];

  const isFlipEffect = hoverEffect === EffectFlip;

  /**
   *
   * @param e
   */
  const onCardClick = (e) => {
    e.preventDefault();

    if (props.onSelectedEvent) {
      props.onSelectedEvent(item);
    }
  };

  const bgImage = item.cover_image ? (
    <div className={"dce--outlined-card-image"}>
      <PreloadImage src={item.cover_image} />
    </div>
  ) : null;

  const descriptionElement = item.description.trim().length ? (
    <div className={"dce--outlined-card_container-description"}>
      <p
        className="line-clamp "
        dangerouslySetInnerHTML={{
          __html: item.description,
        }}
      ></p>
      <p className="dce--outlined-card_container-read-more">Read more</p>
    </div>
  ) : null;

  const reverse = isFlipEffect ? (
    <div className={"dce--outlined-card_container-reverse"}>
      <div className={"blend"} />
      <div>
        {descriptionElement}
        <div className={"dce--outlined-filter dce--outlined-filter_azure"} />
      </div>
    </div>
  ) : null;

  return (
    <div
      className={classNames("dce--outlined-card", hoverEffect, {
        "cursor-pointer": showPopup,
      })}
      onMouseUp={onCardClick}
    >
      <div className={"dce--outlined-card_container"}>
        <div className={"dce--outlined-card_container-front"}>
          <div className={"blend"} />
          <div>
            <h2 className={"dce--outlined-header"}>{item.name}</h2>
            <div
              className={"dce--outlined-filter dce--outlined-filter_azure"}
            />
          </div>
        </div>
        {reverse}
        {bgImage}
      </div>
    </div>
  );
};

OutlinedCard.defaultProps = {};

OutlinedCard.propTypes = {
  onSelectedEvent: PropTypes.func,
};

export default OutlinedCard;
