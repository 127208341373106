/**
 * Created by piotr.pozniak@thebeaverhead.com on 15/02/2023
 */

import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as organizationMembersActions from "../../actions/organizationMembers";

export function useOrganizationMembersStore() {
  const dispatch = useDispatch();
  const _organizationMembers = useSelector(
    (store) => store.organizationMembers
  );

  const fetch = useCallback(
    async (uuid) => await dispatch(organizationMembersActions.fetch(uuid)),
    [dispatch]
  );

  const addOrganizationMember = useCallback(
    async (data) =>
      await dispatch(organizationMembersActions.addOrganizationMember(data)),
    [dispatch]
  );

  const deleteOrganizationMember = useCallback(
    async (uuid) =>
      await dispatch(organizationMembersActions.deleteOrganizationMember(uuid)),
    [dispatch]
  );

  const editOrganizationMember = useCallback(
    async (uuid, data) =>
      await dispatch(
        organizationMembersActions.editOrganizationMember(uuid, data)
      ),
    [dispatch]
  );

  const reinviteOrganizationMember = useCallback(
    async (uuid) =>
      await dispatch(
        organizationMembersActions.reinviteOrganizationMember(uuid)
      ),
    [dispatch]
  );

  const resetReinviteState = useCallback(
    async () =>
      await dispatch(
        organizationMembersActions.resetReinviteOrganizationMember()
      ),
    [dispatch]
  );

  const checkInvitation = useCallback(
    async (params) =>
      await dispatch(
        organizationMembersActions.checkInvitation(params)
      ),
    [dispatch]
  );

  const acceptInvitation = useCallback(
    async (data) =>
      await dispatch(
        organizationMembersActions.acceptInvitation(data)
      ),
    [dispatch]
  );

  const rejectInvitation = useCallback(
    async (data) =>
      await dispatch(
        organizationMembersActions.rejectInvitation(data)
      ),
    [dispatch]
  );

  return {
    organizationMembers: _organizationMembers,
    fetch,
    addOrganizationMember,
    deleteOrganizationMember,
    reinviteOrganizationMember,
    editOrganizationMember,
    resetReinviteState,
    checkInvitation,
    acceptInvitation,
    rejectInvitation
  };
}
