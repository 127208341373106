/**
 *
 * @param id
 * @returns {(function(): void)|*}
 */
const copyTextToClipboard = (id) => {
  const input = document.querySelector("#" + id);

  input.readOnly = false;
  input.contentEditable = true;
  input.focus();
  input.select();

  const range = document.createRange();
  range.selectNodeContents(input);

  const s = window.getSelection();
  s.removeAllRanges();
  s.addRange(range);

  input.setSelectionRange(0, 999999);

  let successful = false;
  try {
    successful = document.execCommand("copy");
  } catch (err) {
    return false;
  }

  s.removeAllRanges();
  s.addRange(range);
  input.blur();
  input.readOnly = true;
  input.contentEditable = false;

  return successful;
};

export default copyTextToClipboard;
