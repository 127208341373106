/**
 * Created by piotr.pozniak@thebeaverhead.com on 31/12/2019.
 */

import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useCalendarsStore } from "../../../../../hooks/redux/calendars";
import { useCalendarStore } from "../../../../../hooks/redux/calendar";
import { filterAvailableValuesForWidgetType } from "../../../../../builder";
import ExpandableAlert from "../../../component/ExpandableAlert";
import Accordion from "../../../component/WidgetSettings/Acordion";

const CloneSettings = (props) => {
  const { calendars } = useCalendarsStore();
  const { calendar } = useCalendarStore();
  const [selectedCalendar, setSelectedCalendar] = useState(0);
  const [hasDifferentIntegraiton, setHasDifferentIntegraiton] = useState(false);

  /**
   *
   * @param e
   */
  const onClone = (e) => {
    setSelectedCalendar(e.target.value);
    const _calendar = calendars.collection.find(
      (i) => i.uuid == e.target.value
    );

    const integrationType =
      calendar &&
      calendar.model &&
      calendar.model.integration &&
      calendar.model.integration.type;

    const targetIntegrationType =
      _calendar && _calendar.integration && _calendar.integration.type;

    if (_calendar && _calendar.widget_settings.design) {
      const settings = filterAvailableValuesForWidgetType(
        _calendar.widget_settings.design,
        calendar.model.builder.design.values
      );

      props.overwriteDesignProp(settings);
    }

    if (integrationType === targetIntegrationType) {
      const data = {
        show_private_events: _calendar.show_private_events,
        show_events_with_images: _calendar.show_events_with_images,
        group_recurring_events: _calendar.group_recurring_events,
        filter_settings: {
          ...calendar.model.filter_settings,
          ..._calendar.filter_settings,
        },
      };

      props.overwriteFilterProp(data);
      setHasDifferentIntegraiton(false);
    } else {
      setHasDifferentIntegraiton(true);
    }
  };

  /**
   *
   * @param e
   * @returns {boolean}
   */
  const onRestore = (e) => {
    e.preventDefault();
    const _calendar = calendars.collection.find(
      (i) => i.uuid == calendar.model.uuid
    );

    const data = {
      show_private_events: _calendar.show_private_events,
      show_events_with_images: _calendar.show_events_with_images,
      group_recurring_events: _calendar.group_recurring_events,
      filter_settings: {
        ..._calendar.filter_settings,
      },
    };

    props.overwriteDesignProp(_calendar.widget_settings.design);
    props.overwriteFilterProp(data);

    setSelectedCalendar(0);
    document.getElementById("clone_calendar_uuid").selectedIndex = 0;
    setHasDifferentIntegraiton(false);

    return false;
  };

  const calendarsOptions = useMemo(
    () =>
      calendars.collection.map((i, idx) => {
        if (i.uuid == calendar.model.uuid) {
          return null;
        }

        return (
          <option key={i.uuid} value={i.uuid}>
            {i.name}
          </option>
        );
      }),
    [calendars.collection]
  );

  const isRestoreBtnVisible = useMemo(
    () => !(selectedCalendar == 0),
    [selectedCalendar]
  );

  const restoreButton = isRestoreBtnVisible ? (
    <div className="form-group">
      <div className="text-right">
        <button type="submit" className="btn btn-secondary" onClick={onRestore}>
          Restore
        </button>
      </div>
    </div>
  ) : null;

  const differentIntegrationWarning = useMemo(
    () =>
      hasDifferentIntegraiton ? (
        <div className="form-group">
          <div className="alert alert-warning">
            This calendar and your import calendar are from different
            integrations. Only the design settings will be imported.
          </div>
        </div>
      ) : null,
    [hasDifferentIntegraiton]
  );

  return (
    <Accordion title={"Import Calendar Settings"}>
      <form action="#">
        <ExpandableAlert
          errorLevel={"info"}
          header={"How does it work?"}
          body={
            <span>
              This tool allows you to import setup and design settings when the
              current and import calendars are within the same integration.
              Otherwise, only the design settings are imported.
            </span>
          }
        />

        <div className="form-group">
          <div className="col-md-xs">
            <select
              className={"form-control form-control-sm "}
              id="clone_calendar_uuid"
              onChange={onClone}
            >
              <option value={0}>Select calendar</option>
              {calendarsOptions}
            </select>
          </div>
          {differentIntegrationWarning}
        </div>

        {restoreButton}
      </form>
    </Accordion>
  );
};

CloneSettings.propTypes = {
  overwriteDesignProp: PropTypes.func.isRequired,
};

export default CloneSettings;
