/**
 * Created by piotr.pozniak@thebeaverhead.com on 26/02/2024
 */

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const BuilderTabs = ({ children, tabs }) => {
  const _tabs = tabs.map(
    (tab, idx) => {
      return (
        <li key={idx} className="nav-item">
          <a
            className={classNames("nav-link d-flex align-items-center", {
              active: idx === 0,
            })}
            data-toggle="tab"
            href={`#${tab.id}`}
          >
            {tab.icon ? (
              <i className="material-icons icon-sm" aria-hidden={true}>
                {tab.icon}
              </i>
            ) : null}
            {tab.label}
          </a>
        </li>
      );
    },
    [tabs]
  );

  const tabSelector =
    tabs.length > 1 ? (
      <ul className="nav nav-tabs nav-tabs-bottom nav-tabs-builder-sticky">
        {_tabs}
      </ul>
    ) : null;

  return (
    <>
      {tabSelector}
      <div className={"tab-content chat-contents d-flex tab-content-builder"}>
        {children}
      </div>
    </>
  );
};

BuilderTabs.defaultProps = {};

BuilderTabs.propTypes = {};

export default BuilderTabs;
