/**
 * Created by piotr.pozniak@thebeaverhead.com on 19/10/2018.
 */

import React, { useEffect, useState } from "react";
import LoadingIndicator from "../../calendar/components/LoadingIndicator";
import ApiError from "../component/ApiError";

import { useUserStore } from "./../../../hooks/redux/user";
import { useAppStore } from "./../../../hooks/redux/app";
import SettingsPageTemplate from "../templates/SettingsPageTemplate";

const SettingsPage = (props) => {
  const { user, editUser } = useUserStore();
  const { clearErrors } = useAppStore();
  const userModel = user.model || {};

  const [state, setState] = useState({
    first_name: userModel.first_name,
    last_name: userModel.last_name,
    email: userModel.email,
  });

  useEffect(() => {
    return clearErrors;
  }, []);
  /**
   *
   */
  const onSaveChangesClick = (e) => {
    e.preventDefault();

    editUser(userModel.uuid, {
      ...state,
    });
  };

  /**
   *
   * @param field
   * @returns {function(*): void}
   */
  const onChangeValue = (field) => (e) => {
    const data = { ...state };
    data[field] = e.target.value;
    setState(data);
  };

  const loading = user.edit;

  const loadingIndicator = loading ? <LoadingIndicator /> : null;

  return (
    <SettingsPageTemplate
      title="Account Settings"
      sidebarId="account_settings"
      icon={"manage_accounts"}
    >
      <form>
        <div className="card-box">
          <h3 className="card-title">Basic Information</h3>
          <div className="row">
            <div className="col-md-12">
              <div className="profile-img-wrap">
                <img className="inline-block" src="/img/user.jpg" alt="user" />
              </div>
              <div className="profile-basic">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group form-focus">
                      <label className="focus-label">First name</label>
                      <input
                        className="form-control floating"
                        type="text"
                        value={state.first_name}
                        onChange={onChangeValue("first_name")}
                        placeholder={"First name"}
                        disabled={loading}
                        maxLength={32}
                        minLength={2}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group form-focus">
                      <label className="focus-label">Last name</label>
                      <input
                        className="form-control floating"
                        type="text"
                        value={state.last_name}
                        onChange={onChangeValue("last_name")}
                        placeholder={"Last name"}
                        disabled={loading}
                        maxLength={64}
                        minLength={0}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group form-focus">
                      <label className="focus-label">Email</label>
                      <input
                        className="form-control floating"
                        type="text"
                        value={state.email}
                        onChange={onChangeValue("email")}
                        disabled={loading}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-center m-t-20">
                <button
                  className="btn btn-primary btn-lg"
                  type="submit"
                  onClick={onSaveChangesClick}
                  disabled={loading}
                >
                  Save Changes
                </button>
              </div>
              <br />

              <div className="col-md-12">
                <div className="table-responsive">
                  {loadingIndicator}

                  <ApiError
                    error={user.editError}
                    defaultErrorMessage={
                      "Could not update user settings. Please try again"
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      <div className="card-box">
        <h3 className="card-title">Password change</h3>
        <div className="row">
          <div className="col-md-12">
            <a href="/password-reminder">
              Follow this link to reset your password.
            </a>
          </div>
        </div>
      </div>
    </SettingsPageTemplate>
  );
};

export default SettingsPage;
