/**
 * Created by piotr.pozniak@thebeaverhead.com on 16/07/2022.
 */

import { useEffect, useRef } from "react";

/**
 *
 * @param func
 * @param deps
 */
const useDidMountEffect = (selector, timeout = 800) => {
  const didScroll = useRef(false);

  useEffect(() => {
    if (!didScroll.current) {
      didScroll.current = true;

      if (!selector) {
        return;
      }

      setTimeout(() => {
        document.querySelector(selector).scrollIntoView({
          block: "center",
          inline: "center",
          behavior: "smooth",
        });
      }, timeout);
    }
  }, []);
};

export default useDidMountEffect;
