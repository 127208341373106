import React, { useState } from "react";
import { useAppStore } from "../../../hooks/redux/app";

const UnauthorizedUserMobileHelp = (props) => {
  const [state, setState] = useState({
    helpChatOpen: false,
  });

  const { initializeIntercom, shutdownIntercom } = useAppStore();

  const onShowHelpChat = (e) => {
    e.preventDefault();

    if (state.helpChatOpen) {
      shutdownIntercom();
      setState({ ...state, helpChatOpen: false });
      return false;
    }

    initializeIntercom({}, {}, false);

    window.Intercom("onShow", () => {
      setState({ ...state, helpChatOpen: true });
    });

    setTimeout(() => window.Intercom("show"), 500);

    return false;
  };

  return (
    <div className="text-center d-lg-none">
      <p />
      <a href="/show-help" onClick={onShowHelpChat}>
        {state.helpChatOpen ? "Hide Chat" : "Need help?"}
      </a>
    </div>
  );
};

export default UnauthorizedUserMobileHelp;
