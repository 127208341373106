import axios from "axios";
import AxiosConfig from "../AxiosConfig";

/**
 *
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export function fetchFonts(needle = '') {
    return function(dispatch) {
    dispatch({ type: "FONTS_FETCH" });

    const url = "/api/fonts/search/" + needle + ".json";

    return axios
      .get(
        url,
        AxiosConfig.getAuthConfig()
      )
      .then(response => {

        dispatch({
          type: "FONTS_FETCH_FULFILLED",
          payload: response.data
        });
      })
      .catch(function(error) {

        dispatch({
          type: "FONTS_FETCH_REJECTED",
          payload: error
        });
      });
  };
}
