import axios from "axios";
import AxiosConfig from "../AxiosConfig";

/**
 *
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export function fetchPayouts(from, to) {
  return function (dispatch) {
    dispatch({ type: "PAYOUTS_FETCH" });

    const url =
      "/api/payouts.json?" + AxiosConfig.objectToURLQuery({ from, to });

    return axios
      .get(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "PAYOUTS_FETCH_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "PAYOUTS_FETCH_REJECTED",
          payload: error,
        });
      });
  };
}
