/**
 * Created by piotr.pozniak@thebeaverhead.com on 25/02/2019.
 */

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { Link, useHistory } from "react-router-dom";
import PageTemplate from "./../templates/PageTemplate";
import TableRow from "./UsersLicensesPage/TableRow";
import LoadingIndicator from "../../calendar/components/LoadingIndicator";
import Pagination from "../component/Pagination";
import {
  createLicense,
  fetchUsersWithLicenses,
} from "../../../actions/licenses";
import CreateModal from "./LicensesPage/CreateModal";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import { clearErrors } from "../../../actions/main";

export const OrganizationsLicensesPage = (props) => {
  const licenses = props.usersLicenses;
  const pagination = props.usersLicenses.pagination;
  const loading = licenses.fetch || licenses.delete;

  const history = useHistory();

  const [state, setState] = useState({
    createModalKey: null,
    editModalKey: new Date() * 1,
    license: { uuid: null },
  });

  useEffect(() => {
    const page = props.match.params.page;
    props.fetchUsersLicenses(page ? page * 1 : 1);

    return () => {
      window.$("#create_license").modal("hide");
    };
  }, []);

  useEffect(() => {
    if (props.allLicenses.createSuccess) {
      props.fetchUsersLicenses(pagination.page);
    }
  }, [props.allLicenses.createSuccess]);

  useEffect(() => {
    if (state.createModalKey) {
      window.$("#create_license").modal("show");
    } else {
      props.clearErrors();
    }
  }, [state.createModalKey]);

  useDidMountEffect(() => {
    props.fetchUsersLicenses(props.match.params.page);
  }, [props.match.params.page]);

  /**
   *
   */
  const onShowCreateLicenseModal = (show) => () => {
    if (loading) {
      return false;
    }

    if (!show) {
      window.$("#create_license").modal("hide");
    }
    setState({
      ...state,
      createModalKey: show ? new Date() * 1 : null,
    });

    //
  };

  /**
   *
   * @param data
   */
  const onCreateLicenses = (data) => {
    props.createLicense(data);
  };

  const licensesList = licenses.collection.map((item, idx) => {
    return <TableRow disabled={loading} key={idx} organization={item} />;
  });

  const loadingIndicator = loading ? <LoadingIndicator /> : null;

  const licensesTable = licenses.collection.length ? (
    <table className="table table-striped custom-table">
      <thead>
        <tr>
          <th>User</th>
          <th>License</th>
          <th>Plan</th>
          <th>Plan Expiries</th>
          {/*          <th className="text-right">Action</th>*/}
        </tr>
      </thead>
      <tbody>{licensesList}</tbody>
    </table>
  ) : null;

  /**
   *
   * @param page
   * @returns {*}
   */
  const onPaginationPageChange = (page) => {
    history.push("/users-licenses/" + page);
    window.scrollTo({ top: 0 });
  };

  /**
   *
   * @param page
   * @returns {string}
   */
  const onPaginationGetPageUrl = (page) => "/users-licenses/" + page;

  const createModal = state.createModalKey ? (
    <CreateModal
      key={"cl-" + state.createModalKey}
      onClose={onShowCreateLicenseModal(false)}
      onSubmit={onCreateLicenses}
      licenses={props.allLicenses}
    />
  ) : null;

  return (
    <PageTemplate
      sidebarId="licenses"
      title={"Licenses per organization"}
      icon={"key"}
      headerControls={[
        <Link to={"/licenses"} className={"btn btn-light"}>
          <small>View all licenses</small>
        </Link>,
        <a
          href="#"
          className="btn btn-primary btn-rounded pull-right d-flex"
          onClick={onShowCreateLicenseModal(true)}
        >
          <i className="material-icons">add</i> Add Licenses
        </a>,
      ]}
    >
      <div className="row">
        <div className="col-md-12">
          <div className="table-responsive">
            {licensesTable}
            <Pagination
              itemsPerPage={pagination.perPage}
              currentPage={pagination.page}
              total={pagination.count || 0}
              getPageUrl={onPaginationGetPageUrl}
              onPageChange={onPaginationPageChange}
            />
            {loadingIndicator}
          </div>
        </div>
      </div>

      {createModal}
    </PageTemplate>
  );
};

const mapStateToProps = (store) => ({
  usersLicenses: store.usersLicenses,
  allLicenses: store.licenses,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUsersLicenses: async (page, ipp) =>
    await dispatch(fetchUsersWithLicenses(page, ipp)),
  createLicense: async (data) => await dispatch(createLicense(data)),
  clearErrors: () => dispatch(clearErrors()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationsLicensesPage);
