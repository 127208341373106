/**
 * Created by piotr.pozniak@thebeaverhead.com on 16/01/2019.
 */

import React from "react";
import PropTypes from "prop-types";

const formatterShort = Intl.DateTimeFormat(
  window.navigator.language || "en-US",
  {
    weekday: "narrow",
  }
);

const formatterRegular = Intl.DateTimeFormat(
  window.navigator.language || "en-US",
  {
    weekday: "short",
  }
);

const arbitraryDate = new Date(2019, 0, 6); // 2019-01-06 is Sunday

const CalendarHeader = ({ isCompact, firstDayOfWeek }) => {
  /**
  /**
   *
   * @returns {XML}
   */
  const formatter = isCompact ? formatterShort : formatterRegular;

  return (
    <thead>
      <tr>
        {[...Array(7).keys()].map((i) => {
          const dayIdx = (i + firstDayOfWeek * 1) % 7;
          const date = new Date(arbitraryDate);
          date.setDate(date.getDate() + dayIdx);

          return (
            <th key={"mcwh-" + i}>
              <p>{formatter.format(date)}</p>
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

CalendarHeader.propTypes = {
  isCompact: PropTypes.bool,
  firstDayOfWeek: PropTypes.number,
};

export default CalendarHeader;
export { CalendarHeader };
