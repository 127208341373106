import { useState, useCallback } from 'react';
import SharePopup from '../WidgetsComponents/SharePopup';

const useSharePopup = (props) => {
    const [showPopup, setShowPopup] = useState(false);

    const onShare = useCallback(
      (e) => {
        e.preventDefault();
        setShowPopup(true);
        return false;
      },
      [showPopup]
    );
  
    const onClose = useCallback(
      (e) => {
        e.preventDefault();
        setShowPopup(false);
        return false;
      },
      [showPopup]
    );
  
    const sharePopup = showPopup ? (
      <SharePopup
        url={props.url}
        title={props.title}
        text={props.text}
        onClose={onClose}
      />
    ) : null;

  return {
    showPopup,
    onShare,
    onClose,
    sharePopup
  };
};

export default useSharePopup;
