/**
 * Created by piotr.pozniak@thebeaverhead.com on 06/00/2021.
 */
import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

const Th = (props) => {
  let orderIcon = null;

  const onClick = (e) => {
    e.preventDefault();

    props.onOrder(props.orderField, props.currentDirection);
  };

  if (props.currentDirection) {
    orderIcon = (
      <i className={"material-icons"}>
        {props.currentDirection === "asc" ? "arrow_drop_up" : "arrow_drop_down"}
      </i>
    );
  }

  return (
    <th
      className={classnames(props.className, {
        clickable: props.orderField,
      })}
      onClick={onClick}
    >
      <span className={"sortable"}>
        {props.children} {orderIcon}
      </span>
    </th>
  );
};

Th.propTypes = {
  orderField: PropTypes.string,
  currentDirection: PropTypes.string,
  className: PropTypes.string,
  onOrder: PropTypes.func,
};
export default Th;
