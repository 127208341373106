/**
 * Created by piotr.pozniak@thebeaverhead.com on 23/05/2019.
 */

import React from "react";

import PropTypes from "prop-types";
import classnames from "classnames";

const SuccessAlert = (props) => {
  const { children } = props;

  if (!children) {
    return null;
  }

  return (
    <div
      className={classnames(
        "alert alert-dismissible fade show",
        props.alertKind,
        props.className
      )}
      role="alert"
    >
      {children}
    </div>
  );
};

SuccessAlert.defaultProps = {
  alertKind: "alert-success ",
};

SuccessAlert.propTypes = {
  alertKind: PropTypes.string,
  className: PropTypes.string,
};

export default SuccessAlert;
