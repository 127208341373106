/**
 * Created by piotr.pozniak@thebeaverhead.com on 12/04/2022
 */

import React, { useContext } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import PreloadImage from "../PreloadImage";
import ButtonCta from "../Mosaic/ButtonCta";
import { formatCTAUrl } from "../../tools";
import WidgetSettingsContext from "../../../../contexts/WidgetSettingsContext";

const EffectFlip = "dce--mosaic_effect-flip";

const Tile = (props) => {
  const { item, wide, tall } = props;
  const widgetSettingsContext = useContext(WidgetSettingsContext);

  const showImage = Number.parseInt(widgetSettingsContext["gSImageMain"]) === 1;
  const hoverEffect = widgetSettingsContext["gMHoverEffect"];

  const showDescription =
    Number.parseInt(widgetSettingsContext["gSDescriptionCard"]) === 1;

  const showGroupName =
    Number.parseInt(widgetSettingsContext["gMShowName"]) === 1;

  const showPopup = Number.parseInt(widgetSettingsContext["gPopupShow"]) === 1;

  const showPrimaryCta =
    Number.parseInt(widgetSettingsContext["gMShowCta1st"]) === 1;
  const primaryCtaAction = widgetSettingsContext["gMCTAAction1st"];
  const primaryCtaLink = item.ctas[primaryCtaAction] || null;
  const isPrimaryCTADefaultLabel =
    Number.parseInt(widgetSettingsContext["gM1stDefaultCTALabel"]) === 1;
  const primaryCTACustomLabel = widgetSettingsContext["gM1stCTALabel"];
  const primaryCTASubject = widgetSettingsContext["gMCTASubject1st"];
  const primaryCTAMessage = widgetSettingsContext["gMCTAMessage1st"];

  const showSecondaryCta =
    Number.parseInt(widgetSettingsContext["gMShowCta2nd"]) === 1;
  const secondaryCtaAction = widgetSettingsContext["gMCTAAction2nd"];
  const secondaryCtaLink = item.ctas[secondaryCtaAction] || null;
  const isSecondaryCTADefaultLabel =
    Number.parseInt(widgetSettingsContext["gM2ndDefaultCTALabel"]) === 1;
  const secondaryCTACustomLabel = widgetSettingsContext["gM2ndCTALabel"];
  const secondaryCTASubject = widgetSettingsContext["gMCTASubject2nd"];
  const secondaryCTAMessage = widgetSettingsContext["gMCTAMessage2nd"];
  const openLinkInNewTab =
    Number.parseInt(widgetSettingsContext["openLinksNewTab"]) === 1;

  const isFlipEffect = EffectFlip === hoverEffect;

  /**
   *
   * @param e
   */
  const onCardClick = (e) => {
    e.preventDefault();

    if (props.onSelectedEvent) {
      props.onSelectedEvent(item);
    }
  };

  const descriptionElement =
    showDescription && item.description.trim().length ? (
      <p
        className="line-clamp"
        dangerouslySetInnerHTML={{ __html: item.description }}
      ></p>
    ) : null;

  const groupName = showGroupName ? <h2>{item.name}</h2> : null;

  const bgImage =
    showImage && item.cover_image ? (
      <PreloadImage
        src={item.cover_image}
        className={"dce--mosaic-tile-image"}
      />
    ) : null;

  const primaryLink =
    showPrimaryCta && primaryCtaLink
      ? formatCTAUrl(
          primaryCtaAction,
          primaryCtaLink,
          primaryCTASubject,
          primaryCTAMessage
        )
      : null;

  const secondaryLink =
    showSecondaryCta && secondaryCtaLink
      ? formatCTAUrl(
          secondaryCtaAction,
          secondaryCtaLink,
          secondaryCTASubject,
          secondaryCTAMessage
        )
      : null;

  const primaryCta =
    showPrimaryCta && primaryCtaLink ? (
      <ButtonCta
        url={primaryLink}
        label={
          !isPrimaryCTADefaultLabel
            ? primaryCTACustomLabel
            : primaryCtaLink.label
        }
        className={"dce--mosaic-tile-btn--primary-cta"}
        target={openLinkInNewTab ? "_blank" : "_self"}
      />
    ) : null;

  const secondaryCta =
    showSecondaryCta && secondaryCtaLink ? (
      <ButtonCta
        url={secondaryLink}
        label={
          !isSecondaryCTADefaultLabel
            ? secondaryCTACustomLabel
            : secondaryCtaLink.label
        }
        className={"dce--mosaic-tile-btn--secondary-cta"}
        target={openLinkInNewTab ? "_blank" : "_self"}
      />
    ) : null;

  const tileReverse = isFlipEffect ? (
    <div className={"dce--mosaic-tile_container-reverse"}>
      <div className={"dce--mosaic-tile-header"}>
        {groupName}
        {descriptionElement}
        <div className={"dce--mosaic-tile_btn-container"}>
          {primaryCta}
          {secondaryCta}
        </div>
      </div>
    </div>
  ) : null;

  return (
    <div
      className={classnames("dce--mosaic-tile", hoverEffect, {
        "dce--mosaic-tile_wide": wide,
        "dce--mosaic-tile_tall": tall,
        "dce-cursor-pointer": showPopup,
      })}
      onMouseUp={onCardClick}
    >
      <div className={"dce--mosaic-tile_container"}>
        <div className={"dce--mosaic-tile_container-front"}>
          {bgImage}

          <div className={"dce--mosaic-tile-header"}>
            {groupName}
            {!isFlipEffect && descriptionElement}
            <div className={"dce--mosaic-tile_btn-container"}>
              {!isFlipEffect && primaryCta}
              {!isFlipEffect && secondaryCta}
            </div>
          </div>
        </div>
        {tileReverse}
      </div>
    </div>
  );
};

Tile.defaultProps = {};

Tile.propTypes = {
  onSelectedEvent: PropTypes.func,
};

export default Tile;
