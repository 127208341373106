/**
 * Created by piotr.pozniak@thebeaverhead.com on 11/04/2023
 */

import React, { useEffect } from "react";
import PropTypes from "prop-types";

const Dropdown = (props) => {
  useEffect(() => {
    // if user clicks outside of dropdown, call props.onClose callback
    const handleClickOutside = (e) => {
      if (props.onClose && (!e.target.closest(".filters"))) {
        props.onClose();
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return <div className={"filters__dropdown"}>{props.children}</div>;
};

Dropdown.defaultProps = {};

Dropdown.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default Dropdown;
