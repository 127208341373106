/**
 * Created by piotr.pozniak@thebeaverhead.com on 05/04/2022.
 */


import axios from "axios";
import AxiosConfig from "../AxiosConfig";

/**
 *
 * @returns {function(*): Promise<AxiosResponse<any>>}
 */
export function fetchGroups() {

  return function(dispatch) {

    dispatch({type: "GROUPS_FETCH"});

    const queryParams = [];

    const url = "/api/groups.json";

    return axios.get(
      url,
      AxiosConfig.getAuthConfig()
    )
      .then((response) => {
        dispatch({
          type: "GROUPS_FETCH_FULFILLED",
          payload: response.data
        });

      })
      .catch(function(error) {

        dispatch({
          type: "GROUPS_FETCH_REJECTED",
          payload: error,
        })
      });
  }
}
