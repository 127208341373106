/**
 * Created by piotr.pozniak@thebeaverhead.com on 26/02/2024
 */

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const BuilderTabContent = ({ children, show, id }) => {
  return (
    <div
      className={classNames("content-full tab-pane", { "active show": show })}
      id={id}
    >
      {children}
    </div>
  );
};

BuilderTabContent.defaultProps = {
  show: false,
};

BuilderTabContent.propTypes = {
  show: PropTypes.bool,
  id: PropTypes.string.isRequired,
};

export default BuilderTabContent;
