/**
 * Created by piotr.pozniak@thebeaverhead.com on 06/08/2021.
 */
import { PCOIntegrationHelp } from "../modals/NewIntegration/PCOIntegrationForm";
import { CCBIntegrationHelp } from "../modals/NewIntegration/CCBIntegrationForm";
import { GoogleIntegrationHelp } from "../modals/NewIntegration/GoogleIntegrationForm";
import {
  BreezeBeforeSyncTooltip,
  BreezeIntegrationHelp,
} from "../modals/NewIntegration/BreezeIntegrationForm";
import appConfig from "../../../appConfig";

export const IntegrationStatus = {
  notSynced: -1,
  synced: 0,
  syncInProgress: 1,
  syncError: 2,
  reAuthRequired: 3,
  permissionsRevoked: 4,
};

export const AvailableIntegrations = [
  {
    title: "Church Community Builder™",
    type: "ccb",
    available: true,
    premium: true,
    description: `The ${appConfig.displayName} - Church Community Builder™ (CCB) integration automatically keeps your ministry updated with your public and/or private events. You can create as many beautiful calendars as you wish, choosing from a variety of views.`,
    help: CCBIntegrationHelp(),
    beforeSyncAlert: null,
    icon: "ccb.png",
  },
  {
    title: "BreezeChMS™",
    type: "breeze",
    available: true,
    premium: true,
    beta: true,
    description:
      "Breeze is a powerful, yet intuitive church management software used by more than 9,000 churches. It's designed to help you save time, reduce frustration, and accomplish your church’s goals.",
    help: BreezeIntegrationHelp(),
    customPostDescription: BreezeBeforeSyncTooltip(),
    beforeSyncAlert: `Did you wait 15 minutes? Due to the way Breeze caches updates, it is recommended to wait for 15 minutes (fifteen minutes) BEFORE doing your ${appConfig.shortDisplayName} sync. In that way, all your changes will be available for syncing.`,
    icon: "breeze.png",
  },
  {
    title: "Planning Center™",
    type: "pco",
    available: true,
    premium: true,
    beta: true,
    description: `The ${appConfig.displayName} - Planning Center™ (PCO) integration automatically creates beautiful, customized calendar views filtered by tags (groups coming soon). It integrates with your registration forms as well.`,
    help: PCOIntegrationHelp(),
    beforeSyncAlert: null,
    icon: "pco.png",
  },
  {
    title: "Google Calendar™",
    type: "google",
    available: true,
    premium: false,
    description: `The ${appConfig.displayName} - Google Calendar integration automatically displays your meetings and events in a variety of views on unlimited calendars.`,
    help: GoogleIntegrationHelp(),
    beforeSyncAlert: null,
    icon: "gcal.png",
  },
].filter((integration) => appConfig.integrations.includes(integration.type));

/**
 *
 * @param status
 * @returns {string}
 */
export const getIntegrationStatusLabel = (status) => {
  switch (status * 1) {
    case -1:
      return "Not synced yet";

    case 0:
      return "Synced";

    case 1:
      return "Sync in progress...";

    case 2:
      return "Error";

    case 3:
      return "Re-authorisation required";
  }

  return "Unknown";
};
