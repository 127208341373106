/**
 * Created by piotr.pozniak@thebeaverhead.com on 20/09/2019.
 */

import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import IntegrationContext from "../../../../strategies/IntegrationContext";

const Selector = (props) => {
  const [showMenu, setShowMenu] = useState(false);

  // const selectorId = "selector-" + props.header.toLowerCase().replace(" ", "");

  const selectorRef = useRef();

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideSelector);

    return () => {
      document.removeEventListener("click", handleClickOutsideSelector);
    };
  });

  /**
   *
   * @param location
   */
  const onChange = (filter) => () => {
    setShowMenu(false);
    props.onChange(filter);
  };

  const integrationContext = useContext(IntegrationContext);

  /**
   *
   */
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleClickOutsideSelector = (e) => {
    e.stopPropagation();

    if (!selectorRef.current.contains(e.target) && showMenu) {
      setShowMenu(false);
    }
  };

  const { options } = props;

  let allSelected = !props.currentSelection.id ? (
    <i className={"material-icons"}>done</i>
  ) : null;
  const selectOptions = options.length
    ? options
        .filter((i) => {
          const fieldName = i.label || integrationContext.filterRenderName(i);
          return fieldName && fieldName.length;
        })
        .map((i, idx) => {
          let tick = null;

          if (!props.currentSelection || i.id == props.currentSelection.id) {
            tick = <i className={"material-icons"}>done</i>;
          }
          const fieldName = i.label || integrationContext.filterRenderName(i);

          return (
            <p
              key={"fl-" + idx}
              onClick={onChange(i)}
              className={classnames({ active: tick ? true : false })}
            >
              {tick} {fieldName}
            </p>
          );
        })
    : null;

  return options ? (
    <div
      className="dce-groups--filters--selector"
      /*id={selectorId}*/
      ref={selectorRef}
    >
      <div className="dce-groups--filters--options">
        <p
          className={classnames("dce-groups--filters--options_selected", {
            active: showMenu,
          })}
          onClick={toggleMenu}
        >
          {props.header}
        </p>

        <div
          className={
            "dce-groups--filters--options_dropdown-container " +
            (showMenu ? "expanded" : "")
          }
        >
          <div className="dce-groups--filters--options_dropdown ">
            <p
              onClick={onChange(props.allOption)}
              className={classnames({ active: !props.currentSelection.id })}
            >
              {allSelected}Any
            </p>
            {selectOptions}
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

Selector.propTypes = {
  header: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  currentSelection: PropTypes.object,
  allOption: PropTypes.shape({
    kind: PropTypes.string.isRequired,
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
  }),
};

export default Selector;
