/**
 * Created by piotr.pozniak@thebeaverhead.com on 22/05/2019.
 */

import React from "react";

import classnames from "classnames";
import { formatError } from "../../../helpers/errors";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const APIErrorsLinks = {
  "%billing%": <Link to={"/billing"}>Manage your subscription</Link>,
};

const ApiError = (props) => {
  const error = props.error;

  if (!error) {
    return null;
  }

  const errors = formatError(error, props.defaultErrorMessage);

  let formattedErrors = (
    <ul>
      {errors.map((i, idx) => {
        // find %.*/% and replace with link component from APIErrorsLinks
        let formattedError = i;
        Object.keys(APIErrorsLinks).forEach((key) => {
          // skip if key not found
          if (i.indexOf(key) === -1) {
            return;
          }
          formattedError = formattedError
            .split(key)
            .filter((j) => j)
            .flatMap((item) => {
              return [item, APIErrorsLinks[key]];
            });
        });

        return <li key={idx}>{formattedError}.</li>;
      })}
    </ul>
  );

  return (
    <div
      className={classnames(
        "alert alert-dismissible fade show",
        props.className
      )}
      role="alert"
    >
      <strong>
        {props.heading ? props.heading : "The following error occurred:"}
      </strong>{" "}
      {formattedErrors}
      {/*<button
          type="button"
          className="close"
          data-dismiss="alert"
          aria-label="Close"
        />*/}
    </div>
  );
};

ApiError.propTypes = {
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  heading: PropTypes.string,
  defaultErrorMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  className: PropTypes.string,
};

ApiError.defaultProps = {
  className: "alert-danger",
};

export default ApiError;
