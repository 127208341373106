/**
 * Created by piotr.pozniak@thebeaverhead.com on 25/02/2019.
 */

import React, { useEffect, useState, useRef } from "react";
import PageTemplate from "./../templates/PageTemplate";
import OrganizationSubscription from "./PlanPage/OrganizationSubscription";
import BillingHistory from "./PlanPage/History/BillingHistory";
import SuccessAlert from "../component/SuccessAlert";
import { updateJquery } from "../tools";
import { useUserStore } from "../../../hooks/redux/user";
import { PLANS } from "../../../consts";
import LoadingIndicator from "./../../calendar/components/LoadingIndicator";
import useCloseModals from "../../../hooks/useCloseModals";
import { useSubscriptionPlansStore } from "../../../hooks/redux/subscriptionPlans";
import { useSubscriptionStore } from "../../../hooks/redux/subscription";
import { useAppStore } from "../../../hooks/redux/app";
import { useCurrentOrganizationStore } from "../../../hooks/redux/currentOrganization";
import PlansSelector from "./PlanPage/PlansSelector";
import { hasSubscription, hasTrialFinished } from "../helpers/organization";
import { Link } from "react-router-dom";
import SettingsPageTemplate from "../templates/SettingsPageTemplate";
const PlansPage = (props) => {
  const [state, setState] = useState({
    showProPlans: true,
    showPlanSelection: false,
    subscription: null,
    showUpdateBillingDetails: false,
  });

  const [showBillingHistory, setShowBillingHistory] = useState(false);

  const { user, checkSession, resendActivationToken } = useUserStore();
  const { currentOrganization, fetchBillingHistory } =
    useCurrentOrganizationStore();
  const { fetchPlans, subscriptionPlans } = useSubscriptionPlansStore();
  const { getCredits } = useSubscriptionStore();
  const { clearErrors } = useAppStore();

  const currentOrganizationModel = currentOrganization.model;

  // TODO
  const checkingPaymentTimeout = useRef(null);

  useCloseModals((e) => {}, []);

  // rewardful
  useEffect(() => {
    const rewardful = window.Rewardful || null;
    if (rewardful) {
      rewardful.ready(() => {
        console.log("rewardful ready");
        fetchPlans();
      });
    }
  }, []);

  // modal - TBD delete it
  useEffect(() => {
    clearErrors();
    //hideModal("#edit_plan");

    updateJquery();

    return () => {
      clearErrors();

      window.$("#edit_plan").modal("hide");
    };
  }, []);

  // check payment
  useEffect(() => {
    if (currentOrganizationModel) {
      getCredits();
    }
  }, []);

  useEffect(() => {
    const checkPaymentProceeded = () => {
      //console.log("call checkPaymentProceeded");

      if (currentOrganizationModel.pending_payment * 1) {
        //console.log("checkPaymentProceeded set timeout");

        if (checkingPaymentTimeout.current) {
          clearTimeout(checkingPaymentTimeout.current);
        }
        checkingPaymentTimeout.current = setTimeout(() => {
          checkSession();
          checkPaymentProceeded();
        }, 5 * 1000);
      } else {
        //console.log("checkPaymentProceeded clear timeout");
        if (checkingPaymentTimeout.current) {
          clearTimeout(checkingPaymentTimeout.current);
        }
        checkingPaymentTimeout.current = null;
      }
    };

    if (
      currentOrganizationModel &&
      currentOrganizationModel.pending_payment * 1
    ) {
      //console.log("componentDidMount call check payment proceeded");
      checkPaymentProceeded();
    }

    return () => {
      clearTimeout(checkingPaymentTimeout.current);
    };
  }, [currentOrganizationModel]);

  useEffect(() => {
    if (showBillingHistory) {
      if (!user.billingFetch) {
        fetchBillingHistory();
      }
    }
  }, [showBillingHistory]);

  useEffect(() => {
    if (state.showCancelPlan) {
      window.$("#cancel_plan").modal("show");
    }
  }, [state.showCancelPlan]);

  /**
   *
   * @param e
   * @returns {boolean}
   */
  const onResendActivationLink = (e) => {
    e.preventDefault();

    resendActivationToken();
    return false;
  };

  /**
   *
   */
  const onShowBillingHistory = () => {
    setShowBillingHistory(true);
  };

  /**
   *
   */
  const onUpdatePlan = () => {
    setState({ ...state, showPlanSelection: true });
  };

  const userModel = user.model;

  const isAccountVerified = userModel && userModel.is_account_verified;

  if (!isAccountVerified) {
    return (
      <SettingsPageTemplate sidebarId="billing">
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="row">
              <div className="col-lg-6">
                <div className="card-box">
                  <div className="card-title">
                    <h4>Activate your account</h4>
                  </div>
                  <p>
                    We permit only verified accounts to subscribe and create
                    calendars.
                  </p>
                  <p>
                    Please check your mailbox for the email we sent you after
                    you registered.
                  </p>
                  <p>
                    If you did not receive an email from us, please check your
                    SPAM folder. If no luck in there, please try to{" "}
                    <a href="#" onClick={onResendActivationLink}>
                      resend the activation link
                    </a>
                    .
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SettingsPageTemplate>
    );
  }

  const subscription = currentOrganization.subscription;
  const hasPlan = hasSubscription(currentOrganization);
  const isTrial = hasPlan && hasPlan.is_trial * 1 ? true : false;
  const isTrialFinished = hasTrialFinished(currentOrganization);

  const cancelTrialButton = isTrial ? (
    <div className={"row"}>
      <div className={"col-xs-12 col-sm-12 text-center"}>
        <small>
          <Link to={"/billing/cancel-subscription"}>Cancel Trial</Link>
        </small>
      </div>
    </div>
  ) : null;

  const showYourSubscriptionContent =
    hasPlan &&
    isTrialFinished &&
    !state.showPlanSelection &&
    subscription.code !== PLANS.free;

  const title = showYourSubscriptionContent
    ? "Your subscription"
    : "Select Plan";

  const billingHistory = showBillingHistory ? (
    currentOrganization.billing && !currentOrganization.billingFetch ? (
      <BillingHistory
        billing={currentOrganization.billing}
        isLoading={currentOrganization.billingFetch}
      />
    ) : (
      <LoadingIndicator />
    )
  ) : null;

  const content = showYourSubscriptionContent ? (
    <React.Fragment>
      <SuccessAlert alertKind={"alert-info"}>
        {currentOrganizationModel &&
        currentOrganizationModel.pending_payment * 1
          ? "Your subscription payment is being processed. Your plan will become active shortly."
          : null}
      </SuccessAlert>

      <OrganizationSubscription
        onUpdatePlan={onUpdatePlan}
        onShowBillingHistory={onShowBillingHistory}
      />

      {billingHistory}
    </React.Fragment>
  ) : (
    <React.Fragment>
      <SuccessAlert alertKind={"alert-info"}>
        {currentOrganizationModel.pending_payment * 1
          ? "Your subscription payment is being processed. Your plan will become active shortly."
          : null}
      </SuccessAlert>

      <PlansSelector />
      <div>{cancelTrialButton}</div>
    </React.Fragment>
  );

  return (
    <SettingsPageTemplate
      sidebarId="billing"
      title={title}
      icon={"card_membership"}
    >
      {content}
    </SettingsPageTemplate>
  );
};

export default PlansPage;
