import React from "react";
import PropTypes from "prop-types";
import { CT } from "../../../../consts";

const CalendarTypeName = (props) => {
  let icon = "";
  let fullName = "";
  switch (props.type) {
    case CT.monthly:
      icon = "date_range";
      fullName = "Full Calendar";
      break;
    case CT.eventList:
      icon = "calendar_view_day";
      fullName = "Events List";
      break;
    case CT.weekly:
      icon = "view_week";
      fullName = "Weekly view";
      break;
    case CT.card:
      icon = "view_module";
      fullName = "Cards (Grid)";
      break;
    case CT.cardList:
      icon = "reorder";
      fullName = "Cards (List)";
      break;

    case CT.cardSlider:
      icon = "view_array";
      fullName = "Cards (Slider)";
      break;
    case CT.detailedList:
      icon = "clear_all";
      fullName = "Detailed list";
      break;
    case CT.bubble:
      icon = "more_horiz";
      fullName = "Tile view";
      break;
  }

  return (
    <span className={"calendars--row--calendar-type"}>
      <i className="material-icons">{icon}</i> {fullName}
    </span>
  );
};

CalendarTypeName.propTypes = {
  type: PropTypes.string.isRequired,
};

export default CalendarTypeName;
