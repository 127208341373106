import React from "react";

const AddMoreButton = ({ onClick }) => {
  return (
    <button className="btn btn-sm btn-outline-success m-b-10" onClick={onClick}>
      Add more members
    </button>
  );
};

export default AddMoreButton;
