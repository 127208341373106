/**
 * Created by piotr.pozniak@thebeaverhead.com on 27/11/2023
 */

import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { useSubscriptionStore } from "../../../../hooks/redux/subscription";
import { useSubscriptionPlansStore } from "../../../../hooks/redux/subscriptionPlans";
import { useCurrentOrganizationStore } from "../../../../hooks/redux/currentOrganization";
import { hasSubscription } from "../../helpers/organization";
import Time from "../../../../helpers/time";
import SubscriptionHeading from "./SubscriptionHeading";
import ShowBrandingToggle from "../../component/ShowBrandingToggle";
import {
  UNBRANDED_MONTHLY_PRICE,
  UNBRANDED_YEARLY_PRICE,
} from "../../../../consts";
import PoweredBy from "../../../calendar/components/PoweredBy";
import AffiliateCoupon from "./AffiliateCoupon";
import TotalDue from "./TotalDue";
import CouponForm from "./CouponForm";
import { Elements, StripeProvider } from "react-stripe-elements";
import BillingForm from "./BillingForm";
import FeatureFlag from "../../component/FeatureFlag";
import appConfig from "../../../../appConfig";

const dateFormat = Intl.DateTimeFormat(window.navigator.language || "en-US", {
  year: "numeric",
  month: "short",
  day: "numeric",
});

const Form = (props) => {
  const [appliedCoupon, setAppliedCoupon] = useState(null);
  const { subscription } = useSubscriptionStore();
  const { currentOrganization } = useCurrentOrganizationStore();

  const currentPlan = hasSubscription(currentOrganization);
  const { selectedPlan, onTogglePlanBranded, isBranded, isYearly, disabled } =
    props;

  const {
    subscriptionPlans: { coupon: affiliateCoupon },
  } = useSubscriptionPlansStore();

  const [discountCoupon, setDiscountCoupon] = useState(affiliateCoupon);
  const [useDiscountCoupon, setUseDiscountCoupon] = useState(
    affiliateCoupon ? true : false
  );

  const currentPlanCredits = currentOrganization.credits
    ? -1 * currentOrganization.credits.realBalance
    : 0;

  const chargingInstallFee =
    currentOrganization.model.installation_fee_paid * 1 <= 0 ? (
      <li>
        Your card will be charged{" "}
        <strong>
          ${appConfig.installationFee} for the one-time licensing fee
        </strong>
        .
      </li>
    ) : null;

  const subscriptionCost = Number.parseFloat(
    isYearly ? selectedPlan.price_annual : selectedPlan.price
  );

  const regularPriceWithCreditsApplied =
    currentPlanCredits > 0
      ? (subscriptionCost - currentPlanCredits).toFixed(2)
      : subscriptionCost;

  const subtotalWithInstallationFee =
    Number.parseFloat(regularPriceWithCreditsApplied) +
    (chargingInstallFee ? appConfig.installationFee : 0);

  const totalDue = Number.parseFloat(
    (subtotalWithInstallationFee <= 0
      ? 0
      : subtotalWithInstallationFee
    ).toFixed(2)
  );

  const userCreditsFormatted = Number.parseFloat(currentPlanCredits).toFixed(2);

  const proratedInfo =
    currentPlanCredits > 0
      ? ` because of your prorated discount of $${userCreditsFormatted}.`
      : ".";

  let chargeInfo =
    regularPriceWithCreditsApplied > 0 ? (
      <li>
        Your card will be charged{" "}
        <strong>${regularPriceWithCreditsApplied}</strong> today{proratedInfo}
      </li>
    ) : (
      <li>
        Your card <strong>will not be charged</strong>
        {proratedInfo}
      </li>
    );

  const nextBillingQuoteIfDiscounted =
    currentPlanCredits > 0 ? (
      <li>
        Your card will be charged <strong>${subscriptionCost}</strong> for your
        next billing cycle.
      </li>
    ) : null;

  const daysLeft =
    !currentPlan || currentPlan.is_terminated * 1
      ? 0
      : Math.round(
          currentPlanCredits / (subscriptionCost / (isYearly ? 365 : 30))
        );

  let validUntil = new Time().add(daysLeft, Time.Unit.DAY); //moment().add(daysLeft, "day");

  if (regularPriceWithCreditsApplied > 0) {
    validUntil = new Time().add(1, isYearly ? Time.Unit.YEAR : Time.Unit.MONTH); //moment().add(1, props.isYearly ? "year" : "month");
  }

  if (appliedCoupon && appliedCoupon.duration) {
    validUntil = new Time().add(appliedCoupon.duration, Time.Unit.SECOND); //moment().add(appliedCoupon.duration, "second");
  }

  const discountSummary =
    currentPlanCredits > 0 ? (
      <p className={"prorated-credits-summary"}>
        Total Prorated Discount: {userCreditsFormatted}
      </p>
    ) : null;

  /*
   * @type {(function(*): void)|*}
   */
  const onUseAffiliateDiscount = useCallback(
    (useDiscount) => {
      setUseDiscountCoupon(useDiscount);

      if (useDiscount) {
        setAppliedCoupon(null);
      }
    },
    [useDiscountCoupon]
  );

  /**
   *
   * @param e
   */
  const onChangeBranded = useCallback(
    (e) => {
      onTogglePlanBranded();
    },
    [props.selectedPlan]
  );

  /**
   *
   * @type {(function(*): void)|*}
   */
  const setCoupon = useCallback(
    (coupon) => {
      setAppliedCoupon(coupon);
    },
    [appliedCoupon]
  );

  const regularCouponForm = !useDiscountCoupon ? (
    <CouponForm
      subscriptionPlanUUID={selectedPlan.uuid}
      onSetCoupon={setCoupon}
      appliedCoupon={appliedCoupon}
      regularPrice={totalDue}
    />
  ) : null;

  return (
    <React.Fragment>
      <div className={"card"}>
        <div className={"card-header bg-white"}>
          <h3 className="card-title  m-b-0">Subscription details</h3>
        </div>
        <div className={"card-body"}>
          <form>
            <div className="row">
              <div className="col-sm-12">
                <SubscriptionHeading
                  currentPlan={currentPlan}
                  selectedPlan={selectedPlan}
                  isBranded={isBranded}
                  isYearly={isYearly}
                />

                <ul className={"user-subscription-summary"}>
                  {chargeInfo}
                  {nextBillingQuoteIfDiscounted}
                  <FeatureFlag name={"brandedSubscriptions"}>
                    {chargingInstallFee}
                  </FeatureFlag>
                  <li>
                    Your plan will be{" "}
                    <strong>valid until {dateFormat.format(validUntil)}</strong>{" "}
                    and automatically renewed on that date.
                  </li>
                  <FeatureFlag name={"brandedSubscriptions"}>
                    <li className={"d-flex align-items-start flex-column"}>
                      <ShowBrandingToggle
                        onChangeBranded={onChangeBranded}
                        isBranded={isBranded}
                        plan={selectedPlan}
                        idPrefix={"billing-plan"}
                        isYearly={isYearly}
                        price={
                          isYearly
                            ? UNBRANDED_YEARLY_PRICE
                            : UNBRANDED_MONTHLY_PRICE
                        }
                      />
                      <PoweredBy />
                    </li>
                  </FeatureFlag>
                </ul>

                <hr />

                <div className={"lg-8"} style={{ maxWidth: "600px" }}>
                  {discountSummary}

                  <TotalDue
                    chargeInstallationFee={chargingInstallFee ? true : false}
                    totalDue={totalDue}
                    appliedCoupon={appliedCoupon}
                    affiliateDiscount={
                      useDiscountCoupon ? discountCoupon : null
                    }
                  />

                  <AffiliateCoupon
                    coupon={discountCoupon}
                    regularPrice={totalDue}
                    nextBillingPrice={subscriptionCost}
                    onUseDiscount={onUseAffiliateDiscount}
                  />
                  {regularCouponForm}
                </div>
                <hr />
              </div>
            </div>
          </form>
          <StripeProvider apiKey={process.env.STRIPE_PK_KEY}>
            <Elements>
              <BillingForm
                disabled={disabled}
                submitButtonLabel={"Subscribe"}
                planUUID={selectedPlan.uuid}
                isYearly={props.isYearly}
                couponCode={appliedCoupon ? appliedCoupon.code : null}
                isBranded={isBranded}
                referralCoupon={useDiscountCoupon ? discountCoupon : null}
                selectedPlan={selectedPlan}
              />
            </Elements>
          </StripeProvider>
        </div>
      </div>
    </React.Fragment>
  );
};

Form.defaultProps = {};

Form.propTypes = {
  selectedPlan: PropTypes.object.isRequired,
  onTogglePlanBranded: PropTypes.func.isRequired,
  isBranded: PropTypes.bool.isRequired,
  isYearly: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

export default Form;
