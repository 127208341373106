/**
 * Created by piotr.pozniak@thebeaverhead.com on 12/07/2022
 */

import React, { useCallback } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const ButtonCta = (props) => {
  const onClick = useCallback((e) => {
    e.stopPropagation();
  }, []);

  return (
    <a
      className={classNames("col--right_btn", props.className)}
      onMouseUp={onClick}
      href={props.url}
      target={`_blank-${props.url}`}
    >
      {props.label}
    </a>
  );
};

ButtonCta.defaultProps = {};

ButtonCta.propTypes = {
  url: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
};

export default ButtonCta;
