/**
 * Created by piotr.pozniak@thebeaverhead.com on 14/06/2019.
 */

import React, { useCallback, useMemo } from "react";

import PropTypes from "prop-types";
import {
  CALENDAR_TEMPLATES_PLANS,
  INTEGRATION_NAMES,
  IT,
  PLANS,
} from "../../../../../consts";
import CrownIcon from "../../../component/CrownIcon";
import { Link } from "react-router-dom";
import { useCurrentOrganizationStore } from "../../../../../hooks/redux/currentOrganization";
import { hasSubscription } from "../../../helpers/organization";
import Accordion from "../../../component/WidgetSettings/Acordion";
import { ucFirst } from "../../../../../helpers/string";

const MandatoryInformation = (props) => {
  /**
   *
   * @param fieldName
   * @param callback
   * @returns {function(*=): void}
   */
  const onFormFieldChange = (fieldName) => (e) => {
    props.onUpdateField(fieldName, e.target.value);
  };

  const { integrations } = props;
  const { currentOrganization } = useCurrentOrganizationStore();
  const subscription = hasSubscription(currentOrganization);

  const integrationsOptions = integrations.length
    ? integrations
        .filter((i) => Number.parseInt(i.available))
        .map((item) => {
          const isPremium = item.type !== IT.google;
          return (
            <option key={"integration-" + item.uuid} value={item.uuid}>
              {item.name} {isPremium && " (PREMIUM)"}
            </option>
          );
        })
    : null;

  const isCalendarTypeAllowedForPlan = useMemo(() => {
    if (!props.calendarModel.template) {
      return true;
    }

    return CALENDAR_TEMPLATES_PLANS[props.calendarModel.template].indexOf(
      subscription.code || PLANS.free
    ) > -1
      ? true
      : false;
  }, [props.calendarModel.template]);

  const showPremiumIntegrationWarning = useMemo(() => {
    return (
      !isCalendarTypeAllowedForPlan ||
      (!subscription &&
        props.calendarModel.integration_uuid &&
        integrations.length &&
        integrations.findIndex(
          (i) =>
            i.uuid === props.calendarModel.integration_uuid &&
            i.type !== IT.google
        ) >= 0)
    );
  }, [isCalendarTypeAllowedForPlan, props.calendarModel]);

  /*const premiumIntegrationWarning = showPremiumIntegrationWarning ? (
    <div className={"alert-transparent d-flex m-t-20"}>
      <CrownIcon className={"m-r-5"} />{" "}
      <span>
        <Link to={"/billing"}>Upgrade to Publish</Link> this calendar. You can
        still explore it!
      </span>
    </div>
  ) : null;
*/
  const onFormSubmit = useCallback((e) => {
    e.preventDefault();
  }, []);

  const subtitle =
    props.showSummary &&
    props.calendarModel &&
    props.calendarModel.integration_uuid
      ? `${ucFirst(props.calendarModel.name)} calendar using ${
          INTEGRATION_NAMES[props.calendarModel.integration.type]
        }.`
      : null;

  return (
    <Accordion
      title={"Name & Integration"}
      subtitle={subtitle}
      keepExpanded={props.expanded}
    >
      <form action="#" onSubmit={onFormSubmit}>
        <div className="form-group">
          <div className="col-xs-12">
            <label htmlFor={"calendar_name"} className={""}>
              Widget Name
            </label>
            <input
              type="text"
              className={
                "form-control form-control-sm " +
                (props.formErrors.nameError ? "is-invalid" : "")
              }
              placeholder="Calendar Name"
              id="calendar_name"
              onChange={onFormFieldChange("name")}
              value={props.calendarModel.name || ""}
              disabled={props.disabled}
            />
          </div>
        </div>
        <div className="form-group">
          <div className="col-md-xs">
            <label htmlFor={"calendar_integration_uuid"} className={""}>
              Integration
            </label>
            <select
              key={new Date().valueOf()}
              className={
                "form-control form-control-sm " +
                (props.formErrors.integrationError ? "is-invalid" : "")
              }
              value={props.calendarModel.integration_uuid}
              id="calendar_integration_uuid"
              onChange={onFormFieldChange("integration_uuid")}
              disabled={props.disabled}
            >
              <option value={0}>Select Integration...</option>
              {integrationsOptions}
            </select>
            {/*{premiumIntegrationWarning}*/}
          </div>
        </div>
      </form>
    </Accordion>
  );
};

MandatoryInformation.defaultProps = {
  expanded: false,
  showSummary: true,
};

MandatoryInformation.propTypes = {
  calendarModel: PropTypes.object.isRequired,
  onUpdateField: PropTypes.func.isRequired,
  integrations: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  expanded: PropTypes.bool,
  showSummary: PropTypes.bool,
};

export default MandatoryInformation;
