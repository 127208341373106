/**
 * Created by piotr.pozniak@thebeaverhead.com on 20/09/2019.
 */

import React from "react";
import PropTypes from "prop-types";

const SelectorGroup = props => {

  if (!props.show) {
    return null;
  }
  if (!props.children) {
    return <p />;
  }

  return <div className="calendar-filters">{props.children}</div>;
};

SelectorGroup.propTypes = {
  show: PropTypes.bool
};

export default SelectorGroup;
