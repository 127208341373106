/**
 * Created by piotr.pozniak@thebeaverhead.com on 11/04/2023.
 */

import React, { useCallback, useMemo, useContext } from "react";
import PropTypes from "prop-types";
import Dropdown from "./Dropdown";
import classNames from "classnames";
import { ConjunctionContext, FiltersContext } from "./contexts";

const Conjunction = ({ value, labels, onChange, toggleHovered }) => {
  const context = useContext(ConjunctionContext);
  const { filters } = useContext(FiltersContext);

  const [isEditMode, setIsEditMode] = React.useState(false);

  const selectedConjunction = useMemo(
    () => labels.find((label) => label.field === value),
    [value]
  );

  /**
   *
   * @type {function(): void}
   */
  const toggleEditMode = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsEditMode(!isEditMode);
    },
    [isEditMode, filters]
  );

  /**
   *
   * @type {function(*): function(*): void}
   */
  const onSelectConjunction = useCallback(
    (val) => (e) => {
      onChange(val);
      setIsEditMode(false);
    },
    [value, isEditMode]
  );

  /**
   *
   * @type {(function(): void)|*}
   */
  const onDropdownClose = useCallback(() => {
    setIsEditMode(false);
  }, [filters]);

  const options = useMemo(
    () =>
      labels.map((label) => {
        const selected = label.field === value;
        return (
          <div
            onClick={onSelectConjunction(label.field)}
            className={classNames("filters__dropdown__option", {
              "filters__dropdown__option-active": label.field === value,
            })}
            key={label.field}
          >
            {label.label}
            {selected ? <span>&#x2713;</span> : null}
          </div>
        );
      }),
    [value, filters]
  );

  const dropdown = isEditMode ? (
    <Dropdown onClose={onDropdownClose}>{options}</Dropdown>
  ) : null;

  return (
    <div
      className={classNames("filters__conjunction", {
        filters__conjunction_active: isEditMode || context.hovered,
      })}
      onClick={toggleEditMode}
      onMouseOver={toggleHovered}
      onMouseOut={toggleHovered}
    >
      {selectedConjunction.label}
      {dropdown}
    </div>
  );
};

Conjunction.defaultProps = {
  labels: [
    { field: "and", label: "and" },
    { field: "or", label: "or" },
  ],
};

Conjunction.propTypes = {
  value: PropTypes.oneOf(["and", "or"]).isRequired,
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  toggleHovered: PropTypes.func.isRequired,
};
export default Conjunction;
