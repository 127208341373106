/**
 * Created by piotr.pozniak@thebeaverhead.com on 11/12/2023
 */

import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const dateFormatter = new Intl.DateTimeFormat(
  window.navigator.language || "en-us",
  {
    month: "short",
    day: "numeric",
  }
);

const Bubble = (props) => {
  const { event } = props;
  /**
   *
   */
  const onCardClick = useCallback(
    (e) => {
      e.preventDefault();

      if (props.onSelectedEvent) {
        props.onSelectedEvent(event);
      }
    },
    [props.onSelectedEvent]
  );

  const eventName = props.showEventName ? (
    <div className={"bubble-view--bubble_event-name"}>{event.name}</div>
  ) : null;

  const recurringIcon =
    props.showRecurringIcon && event.recurrence_description ? (
      <i className={"material-icons"}>repeat</i>
    ) : null;

  const eventDate = props.showEventDate ? (
    <div className={"bubble-view--bubble_event-date"}>
      {recurringIcon}
      {dateFormatter.format(new Date(event.start_time * 1000))}
    </div>
  ) : null;

  const bubbleImage = event.cover_image ? (
    <img src={event.cover_image} alt={event.name} />
  ) : null;

  return (
    <div
      className={classNames(
        "bubble-view--bubble",
        `bubble-view--bubble_size-${props.size}`

        /*"card-view--card_hover-effect--" + props.hoverEffect,
        {
         "card-view--card_cursor_pointer": props.onSelectedEvent
           ? true
           : false,
        }*/
      )}
      onMouseUp={onCardClick}
    >
      <div
        className={classNames(
          "bubble-view--bubble-image",
          `bubble-view--bubble-image_${props.style}`,
          {
            "bubble-view--bubble-image--border": props.showImageBorder,
          }
        )}
      >
        {bubbleImage}
      </div>
      <div className={"bubble-view--bubble-details"}>
        {eventName}
        {eventDate}
      </div>
    </div>
  );
};

Bubble.defaultProps = {
  style: "rounded",
  size: "small",
};

Bubble.propTypes = {
  event: PropTypes.object.isRequired,
  onSelectedEvent: PropTypes.func,
  showEventName: PropTypes.bool,
  showEventDate: PropTypes.bool,
  showRecurringIcon: PropTypes.bool,
  style: PropTypes.oneOf(["square", "rounded", "circle"]),
  size: PropTypes.oneOf(["tiny", "small", "medium", "large"]),
  showImageBorder: PropTypes.bool,
};

export default Bubble;
