/**
 * Created by piotr.pozniak@thebeaverhead.com on 02/08/2024
 */

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useHistory, useParams } from "react-router-dom";

const Tab = (props) => {
  const history = useHistory();
  const hash = history.location.hash;

  const isActive =
    hash === `#${props.id}` || (hash.length === 0 && props.isDefault);
  return (
    <div
      className={classNames("tab-pane show", { active: isActive })}
      id={props.id}
    >
      {props.children}
    </div>
  );
};

Tab.defaultProps = {};

Tab.propTypes = {
  isDefault: PropTypes.bool,
  id: PropTypes.string,
};

export default Tab;
