import React from "react";

const FilterLabelCTA = () => {
  return (
    <span>
      You may filter groups on this widget using one of the available filters.{" "}
      <a
        href={
          "https://help.display.church/en/articles/6380138-setup-groups-filtering-filter-selection-pco"
        }
        target={"_blank_pco_groups_filters"}
      >
        Follow this link to learn more
      </a>
      .
    </span>
  );
};

export default FilterLabelCTA;
