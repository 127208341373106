/**
 * Created by piotr.pozniak@thebeaverhead.com on 15/02/2023
 */

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import PropTypes from "prop-types";
import { useAlertStore } from "../../../hooks/redux/alert";
import { useAppStore } from "../../../hooks/redux/app";
import { WT } from "../../admin/consts/widget";
import IntegrationContext from "../../alerts/strategies/IntegrationContext";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import { filtersToQueryParam } from "../../../helpers/filters";
import { AT, CT, PT } from "../../../consts";
import moment from "moment/moment";
import { getAllOptionValues } from "../../../builder";
import classnames from "classnames";
import Banner from "./Banner/Banner";
import { useAlertEventsStore } from "../../../hooks/redux/alertEvents";
import WidgetSettingsContext from "../../../contexts/WidgetSettingsContext";

const TemplateToComponentMapping = {
  banner: Banner,
};

const Alert = (props) => {
  const integrationContext = useContext(IntegrationContext);

  const { alert, hideAlert } = useAlertStore();
  const { events, fetchAlertEvents, resetEventsCollection } =
    useAlertEventsStore();

  const { app } = useAppStore();

  const widgetSettings = getAllOptionValues(alert.model);

  const [initialized, setInitialized] = useState(false);

  const initialShow = useMemo(() => {
    return props.embedded ? true : alert.show;
  }, []);

  const widgetId = `dce_${WT.alerts}__${alert.model.uuid}`;

  useEffect(() => {
    if (alert.model.slug) {
      setInitialized(true);

      window.__DCE[WT.alerts].init = false;
    }
  }, [alert.model.slug]);

  // this effect is used show the alert again after it was hidden, for builder purpose only, must not run if embedded
  useEffect(() => {
    if (alert.show && !props.embedded) {
      setInitialized(true);
    }
  }, [alert.show, props.embedded]);

  useEffect(() => {
    _fetchEvents();

    return () => {
      resetEventsCollection();
    };
  }, []);

  useDidMountEffect(() => {
    if (alert.model.template) {
      _fetchEvents();
    }
  }, [
    alert.model.filter_settings,
    alert.model.show_private_events,
    alert.model.show_events_with_images,
  ]);

  useEffect(() => {
    /* if (initialShow) {
      if (initialized && !alert.show) {
        setTimeout(() => setInitialized(false), 500);
      }
    }*/
    // leave dependencies to alert.show only, if you add "initialized" then it will change
    // initialized to false during the first load as alert.show is false by default for embedded
  }, [alert.show]);

  const _fetchEvents = async () => {
    if (events.fetch) {
      return false;
    }

    return await fetchAlertEvents(
      alert.model.uuid,
      moment().unix(), //format("YYYY-MM-DD HH:mm:ss"),
      null,
      10,
      1,
      null
    );
  };

  /**
   * @type {(function(): void)|*}
   */
  const onClose = useCallback(() => {
    hideAlert();
  }, []);

  const WidgetComponent = useMemo(
    () =>
      initialized ? TemplateToComponentMapping[alert.model.template] : null,
    [initialized]
  );

  const widget = WidgetComponent ? (
    <WidgetComponent
      embedded={props.embedded}
      widgetUUID={alert.model.uuid}
      widgetDOMId={widgetId}
      onClose={onClose}
      previewType={app.previewType}
    />
  ) : null;

  const content =
    app.previewType === PT.mobile ? (
      <div className={"mobile-preview--smartphone"}>{widget}</div>
    ) : (
      widget
    );

  if (!events.collection.length) {
    return null;
  }

  return (
    <WidgetSettingsContext.Provider value={widgetSettings}>
      {content}
    </WidgetSettingsContext.Provider>
  );
};

Alert.defaultProps = {};

Alert.propTypes = {
  widgetUUID: PropTypes.string.isRequired,
  embedded: PropTypes.bool,
};

export default Alert;
