/**
 * Created by piotr.pozniak@thebeaverhead.com on 24/08/2022
 */

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { updateJquery } from "../tools";

const CrownIcon = (props) => {
  useEffect(() => {
    updateJquery();
  }, []);

  const tooltipProps = props.tooltipMessage
    ? {
        "data-toggle": "tooltip",
        "data-placement": "top",
        "data-custom-class": "tooltip-alert",
        title: props.tooltipMessage,
      }
    : {};
  return (
    <Link to={"/billing"} className={"visible"}>
      <img
        src={"/img/crown.png"}
        className={classNames("crown", props.className)}
        {...tooltipProps}
      />
    </Link>
  );
};

CrownIcon.defaultProps = {
  className: "",
};

CrownIcon.propTypes = {
  className: PropTypes.string,
  tooltipMessage: PropTypes.string,
};

export default CrownIcon;
