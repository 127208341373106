import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import classnames from "classnames";
const TaskItem = props => {

  const label =
    props.completed || !props.linkUrl ? (
      props.children
    ) : (
      <Link to={props.linkUrl}>{props.children}</Link>
    );

  return (
    <li className={classnames("task", { completed: props.completed })}>
      <div className="task-container">
        <span className="task-action-btn task-check">
          <span className="action-circle large complete-btn">
            <i className="material-icons">check</i>
          </span>
        </span>
        <span className="task-label">{label}</span>
      </div>
    </li>
  );
};

TaskItem.propTypes = {
  completed: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  linkUrl: PropTypes.string
};

export default TaskItem;
