/**
 * Created by piotr.pozniak@thebeaverhead.com on 19/12/2023
 */

import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Hr from "../../../component/Hr";
import RadioInput from "../../../component/WidgetSettings/RadioInput";
import { useBuilderStore } from "../../../../../hooks/redux/builder";
import { useCalendarStore } from "../../../../../hooks/redux/calendar";
import { CT, CT_NAMES } from "../../../../../consts";

const defaultTemplate = CT.bubble;
const AllowedFeaturedTemplates = [
  CT.bubble,
  CT.card,
  CT.cardList,
  CT.cardSlider,
];

const FeaturedTemplatesEventsLimit = {
  [CT.bubble]: 12,
  [CT.card]: 3,
  [CT.cardList]: 1,
  [CT.cardSlider]: 3,
};

const FeaturedHighlights = (props) => {
  const { updateDesignProp } = useBuilderStore();
  const { calendar, setCalendar } = useCalendarStore();
  const [previousHighlightsSettings, setPreviousHighlightsSettings] = useState(
    {}
  );

  useEffect(() => {
    setPreviousHighlightsSettings({
      [calendar.model.widget_settings.featured_template]:
        { ...calendar.model.widget_settings.highlights } || {},
    });
  }, []);

  const hasFeatureHighlights = Boolean(
    calendar.model.widget_settings.has_feature_highlights
  );

  const highlightTemplate =
    calendar.model.widget_settings.featured_template || CT.bubble;

  /**
   *
   * @type {function(*): function(*)}
   */
  const onHighlightsConfigChange = useCallback(
    (field) => (e) => {
      const isBoolean =
        typeof calendar.model.widget_settings.has_feature_highlights ===
        "boolean";

      if (isBoolean) {
        return props.onUpdateField(field, !hasFeatureHighlights);
      }

      updateDesignProp(
        [{ field, value: !(hasFeatureHighlights * 1) ? 1 : 0 }],
        null
      );
    },
    [hasFeatureHighlights]
  );

  /**
   *
   * @type {(function(*): void)|*}
   */
  const onEventsLimitChange = useCallback(
    (e) => {
      updateDesignProp(
        [
          {
            field: "featured_events_limit",
            value: Number.parseInt(e.target.value),
          },
        ],
        null
      );
    },
    [calendar.model.widget_settings]
  );

  /**
   *
   * @type {(function(*): void)|*}
   */
  const onTemplateChange = useCallback(
    (e) => {
      const previousSettings = previousHighlightsSettings[e.target.value] || {};

      // store current settings for the template, so we can restore them later,
      // when user switches back to this template.
      const settingsCache = { ...previousHighlightsSettings };
      settingsCache[calendar.model.widget_settings.featured_template] = {
        ...calendar.model.widget_settings.highlights,
      };

      setPreviousHighlightsSettings(settingsCache);

      // setting up new template and it's default events limit
      calendar.model.widget_settings.featured_template = e.target.value;
      calendar.model.widget_settings.featured_events_limit =
        FeaturedTemplatesEventsLimit[e.target.value];

      calendar.model.widget_settings.highlights = previousSettings;
      calendar.model.has_featured_events = true;

      setCalendar(calendar.model);
    },
    [calendar.model.widget_settings, previousHighlightsSettings]
  );

  const templateDropdown = hasFeatureHighlights ? (
    <div>
      <label className={"option-label"}>
        Featured events highlight template
      </label>
      <select
        className={"form-control"}
        onChange={onTemplateChange}
        value={highlightTemplate}
      >
        {AllowedFeaturedTemplates.map((template) => {
          return (
            <option key={`${template}_featured_template`} value={template}>
              {CT_NAMES[template]}
            </option>
          );
        })}
      </select>
    </div>
  ) : null;

  const numberOfEventsToShow =
    calendar.model.widget_settings.featured_events_limit ||
    FeaturedTemplatesEventsLimit[
      calendar.model.widget_settings?.featured_template || defaultTemplate
    ];

  const featuredEventsLimit = hasFeatureHighlights ? (
    <div className={"design-element"}>
      <label className={"option-label"}>Featured events limit</label>
      <input
        className={"form-control"}
        id={"featured_events_limit"}
        type={"number"}
        min={0}
        value={numberOfEventsToShow}
        onChange={onEventsLimitChange}
      />
    </div>
  ) : null;

  return (
    <React.Fragment>
      <Hr
        title={
          <>
            Featured highlights{" "}
            <span className={"badge badge-success"}>NEW</span>
            <span className={"badge badge-primary"}>BETA</span>
          </>
        }
      />
      <RadioInput
        fieldName={"has_feature_highlights"}
        label={"Show featured events highlights"}
        onChange={onHighlightsConfigChange}
        checked={hasFeatureHighlights}
      />
      {featuredEventsLimit}
      {templateDropdown}
    </React.Fragment>
  );
};

FeaturedHighlights.defaultProps = {};

FeaturedHighlights.propTypes = {};

export default FeaturedHighlights;
