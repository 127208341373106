/**
 * Created by piotr.pozniak@thebeaverhead.com on 22/08/2022
 */

import React, { useState } from "react";
import PropTypes from "prop-types";

const CCBPrivateEventsSync = (props) => {
  const { integrationModel, isLoading } = props;

  const [showPrivateEventsInfo, setShowPrivateEventsInfo] = useState(
    integrationModel && integrationModel.sync_private * 1 ? true : false
  );

  /**
   *
   * @param e
   */
  const onCheck = (e) => {
    setShowPrivateEventsInfo(e.target.checked);
  };

  const syncPrivateEventsInfo = showPrivateEventsInfo ? (
    <div className={"alert alert-info"}>
      Checking this option will create a sync process to pull all private events
      from all calendars for all groups. This requires the{" "}
      <strong>individual_calendar_listing</strong> CCB API endpoint to be turned
      on.
    </div>
  ) : null;

  return (
    <div className="col-xs-12 col-md-12">
      <div className="checkbox">
        <label>
          <input
            className={""}
            id="integration_sync_private"
            type="checkbox"
            defaultChecked={
              integrationModel ? integrationModel.sync_private : null
            }
            onClick={onCheck}
            disabled={isLoading}
          />{" "}
          Pull private events from all calendars
        </label>
        {syncPrivateEventsInfo}
      </div>
      {/*<div className={"alert alert-warning fade show"}>
        <div>Syncing private events is a premium feature.</div>
      </div>*/}
    </div>
  );
};

CCBPrivateEventsSync.defaultProps = {};

CCBPrivateEventsSync.propTypes = {
  integrationModel: PropTypes.object,
  isLoading: PropTypes.bool,
  callback: PropTypes.func,
};

export default CCBPrivateEventsSync;
