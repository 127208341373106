/**
 * Created by piotr.pozniak@thebeaverhead.com on 26/02/2024
 */

import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { getAllOptionValues } from "../../../../../builder";
import FeatureSearch from "../../Builder/FeatureSearch";
import useBuilderOptionsHook from "../../../hooks/useBuilderOptionsHook";
import WidgetSettingsContext from "../../../../../contexts/WidgetSettingsContext";
import { updateJquery } from "../../../tools";
import { useAppStore } from "../../../../../hooks/redux/app";

const BuilderOptionsTab = ({ widget, settingsName }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const { app } = useAppStore();

  useEffect(() => {
    updateJquery();
  }, [searchQuery]);

  /**
   *
   * @type {(function(*): void)|*}
   */
  const onChangeSearchQuery = useCallback(
    (e) => {
      setSearchQuery(e.target.value.toLowerCase());
    },
    [searchQuery]
  );

  /**
   *
   * @type {(function(): void)|*}
   */
  const clearSearchQuery = useCallback(() => {
    setSearchQuery("");
  }, [searchQuery]);

  const { tabs } = useBuilderOptionsHook(
    widget,
    settingsName,
    searchQuery,
    app.widgetSettingsUpdateTimestamp
  );

  const widgetSettings = getAllOptionValues(widget.model, settingsName);
  const noResults = !tabs.length ? (
    <span className={"design-search-result text-secondary"}>No results</span>
  ) : null;
  return (
    <WidgetSettingsContext.Provider value={widgetSettings}>
      <FeatureSearch
        value={searchQuery}
        onChange={onChangeSearchQuery}
        onClear={clearSearchQuery}
      />
      {tabs}
      {noResults}
    </WidgetSettingsContext.Provider>
  );
};

BuilderOptionsTab.defaultProps = {
  settingsName: "design",
};

BuilderOptionsTab.propTypes = {
  settingsName: PropTypes.string,
};

export default BuilderOptionsTab;
