/**
 * Created by piotr.pozniak@thebeaverhead.com on 24/10/2018.
 */

import axios from "axios";
import AxiosConfig from "../AxiosConfig";

/**
 *
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export function setCalendar(calendarModel) {
  return async (dispatch) => {
    dispatch({
      type: "CALENDAR_SET",
      payload: calendarModel,
    });
  };
}

/**
 *
 * @param email
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export function updateCalendar(uuid = null, data) {
  return async (dispatch) => {
    dispatch({ type: "CALENDAR_UPDATE", payload: { calendarUUID: uuid } });

    const url = "/api/calendars" + (uuid ? "/" + uuid : "") + ".json";

    const action = uuid ? axios.put : axios.post;

    return await action(url, data, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "CALENDAR_UPDATE_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (response) {
        dispatch({
          type: "CALENDAR_UPDATE_REJECTED",
          payload: response,
        });
      });
  };
}

/**
 *
 * @param email
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export function deleteCalendar(uuid) {
  return async (dispatch) => {
    dispatch({ type: "CALENDAR_DELETE", payload: { calendarUUID: uuid } });

    const url = "/api/calendars/" + uuid + ".json";

    return await axios
      .delete(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "CALENDAR_DELETE_FULFILLED",
          payload: { uuid },
        });
      })
      .catch(function (error) {
        dispatch({
          type: "CALENDAR_DELETE_REJECTED",
          payload: error,
        });
      });
  };
}

/**
 *
 * @param uuid
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export function fetchCalendar(uuid) {
  return function (dispatch) {
    dispatch({ type: "CALENDAR_FETCH" });

    const url = "/api/calendars/" + uuid + ".json";

    return axios
      .get(
        AxiosConfig.getEndpointAddress() + url,
        AxiosConfig.getAuthToken()
          ? AxiosConfig.getAuthConfig()
          : AxiosConfig.getConfig()
      )
      .then((response) => {
        dispatch({
          type: "CALENDAR_FETCH_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        console.log(error.message);
        if (
          error.message &&
          typeof error.message === "string" &&
          error.message.match(/Network Error/)
        ) {
          dispatch({
            type: "CORS_ERROR",
            payload: error,
          });
        } else {
          dispatch({
            type: "CALENDAR_FETCH_REJECTED",
            payload: error,
            unpublished:
              typeof error === "object" &&
              error.response &&
              error.response.status === 403,
          });
        }
      });
  };
}

/**
 *
 * @param calendar
 * @returns {{template, group_recurring_events: (number), featured_events_limit: (number|*), calendar_filters_uuids: (*|*[]), available, name, widget_settings: string, show_private_events: (number), integration_uuid: Validator<NonNullable<string>>}}
 */
export const getCalendarCurrentData = (calendar) => {
  return {
    integration_uuid: calendar.integration.uuid,
    available: calendar.available,
    name: calendar.name,
    show_private_events: calendar.show_private_events ? 1 : 0,
    template: calendar.template,
    featured_events_limit: calendar.featured_events_limit,
    group_recurring_events: calendar.group_recurring_events ? 1 : 0,
    filter_settings: { ...calendar.filter_settings },
    widget_settings: { ...calendar.widget_settings },
    has_featured_events: calendar.has_featured_events,
    has_group_events_included: calendar.has_group_events_included,
  };
};
