/**
 * Created by piotr.pozniak@thebeaverhead.com on 22/02/2019.
 */

import axios from "axios";
import AxiosConfig from "../AxiosConfig";

/**
 *
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export function fetchLicenses(page = 1, ipp = 50) {
  return function (dispatch) {
    dispatch({ type: "LICENSES_FETCH", payload: { page, ipp } });

    const url =
      "/api/licenses.json?" + AxiosConfig.objectToURLQuery({ page, ipp });

    return axios
      .get(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "LICENSES_FETCH_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "LICENSES_FETCH_REJECTED",
          payload: error,
        });
      });
  };
}

/**
 *
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export function fetchUsersWithLicenses(page = 1, ipp = 50) {
  return function (dispatch) {
    dispatch({ type: "USERS_LICENSES_FETCH", payload: { page, ipp } });

    const url =
      "/api/licenses/organizations.json?" +
      AxiosConfig.objectToURLQuery({ page, ipp });

    return axios
      .get(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "USERS_LICENSES_FETCH_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "USERS_LICENSES_FETCH_REJECTED",
          payload: error,
        });
      });
  };
}

/**
 *
 * @param data
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export function updateLicense(uuid, data) {
  return function (dispatch) {
    dispatch({ type: "LICENSE_UPDATE" });

    const url = "/api/licenses/" + uuid + ".json";

    return axios
      .put(url, data, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "LICENSE_UPDATE_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "LICENSE_UPDATE_REJECTED",
          payload: error,
        });
      });
  };
}

/**
 *
 * @param data
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export function createLicense(data) {
  return function (dispatch) {
    dispatch({ type: "LICENSE_CREATE" });

    const url = "/api/licenses.json";

    return axios
      .post(url, { licenses: data }, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "LICENSE_CREATE_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "LICENSE_CREATE_REJECTED",
          payload: error,
        });
      });
  };
}

/**
 *
 * @param uuid
 * @param data
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export function deleteLicense(uuid) {
  return function (dispatch) {
    dispatch({ type: "LICENSE_DELETE" });

    const url = "/api/licenses/" + uuid + ".json";

    return axios
      .delete(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "LICENSE_DELETE_FULFILLED",
          payload: { uuid },
        });
      })
      .catch(function (error) {
        dispatch({
          type: "LICENSE_DELETE_REJECTED",
          payload: error,
        });
      });
  };
}
