/**
 * Created by piotr.pozniak@thebeaverhead.com on 08/05/2023
 */

import React, { useContext, useMemo } from "react";
import PropTypes from "prop-types";
import IntegrationContext from "../../strategies/IntegrationContext";
import WidgetSettingsContext from "../../../../contexts/WidgetSettingsContext";

const WidgetFilterLabels = (props) => {
  const widgetSettings = useContext(WidgetSettingsContext);
  const integrationStrategy = useContext(IntegrationContext);
  const { calendar } = props;

  const showFilterLabels = widgetSettings["filterNamesHeader"];

  const filtersHeader = useMemo(() => {
    return showFilterLabels &&
      calendar.model.filter_settings &&
      calendar.model.filter_settings.filters
      ? calendar.model.filter_settings.filters.groups
          .map((group) =>
            group.rules
              .filter(
                (rule) =>
                  rule.operator.value == "is" &&
                  integrationStrategy.canUserFilter[rule.field.value]
              )
              .map((rule) => rule.label)
          )
          .flat()
          .join(", ")
      : null;
  }, [calendar.model.filter_settings]);

  if (!showFilterLabels || filtersHeader === "") {
    return null;
  }

  return <h4 className="campuses-groups-header">{filtersHeader}</h4>;
};

WidgetFilterLabels.defaultProps = {};

WidgetFilterLabels.propTypes = {};

export default WidgetFilterLabels;
