import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as appTagActions from "../../actions/appTags";

export function useAppTagStore() {
  const dispatch = useDispatch();
  const _collection = useSelector((store) => store.collection);

  const fetchAppTags = useCallback(
    async () => await dispatch(appTagActions.fetchAppTags()),
    [dispatch]
  );

  const updateAppTag = useCallback(
    async (type, tag, isDelete) =>
      await dispatch(appTagActions.updateAppTag(type, tag, isDelete)),
    [dispatch]
  );

  return {
    collection: _collection,
    fetchAppTag: fetchAppTags,
    updateAppTag,
  };
}
