import React from "react";
import FormError from "../../../component/FormError";
import PropTypes from "prop-types";
import GoogleCalendarsForm from "./GoogleCalendarsForm";
import useQuery from "../../../../../hooks/useQuery";

const CalendarsSelectionCard = (props) => {
  const {
    handleCalendarCheckboxToggle,
    calendars,
    error,
    loadingIndicator,
    calendarSyncingIndicator,
    onSubmit,
  } = props;
  const query = useQuery();

  const selectCalendars = query.get("select-calendars");

  return (
    <div className="card pt-sm-4 pl-sm-4 pr-sm-4">
      <GoogleCalendarsForm
        calendars={calendars}
        idPrefix={"google-calendars-"}
        handleCalendarCheckboxToggle={handleCalendarCheckboxToggle}
        calendarSyncingIndicator={calendarSyncingIndicator}
        onSubmit={onSubmit}
        calendarsSelectionRequired={selectCalendars}
      />
      <div className="col-xs-12">
        <FormError errors={error} />
      </div>
      {loadingIndicator}
    </div>
  );
};

CalendarsSelectionCard.defaultProps = {
  calendars: [],
};

CalendarsSelectionCard.propTypes = {
  handleCalendarCheckboxToggle: PropTypes.func,
  loadingIndicator: PropTypes.func,
  onSubmit: PropTypes.func,
  calendars: PropTypes.array,
  error: PropTypes.array,
};

export default CalendarsSelectionCard;
