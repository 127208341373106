/**
 * Created by piotr.pozniak@thebeaverhead.com on 19/12/2022
 */

import React, { useContext, useMemo } from "react";
import PropTypes from "prop-types";
import WidgetSettingsContext from "../../../../contexts/WidgetSettingsContext";
import { useTranslation } from "react-i18next";

/**
 * Creates a Google Maps URL for the event location
 * @param {string|null} displayLocation
 * @param {object} location
 * @returns {string}
 */
export const generateGoogleLinkFromLocation = (displayLocation, location) => {
  const locationChunks = [];
  let locationUrl = "https://www.google.com/maps/search/?api=1&query=";
  let hasSearchableLocation = (location && location.name) || displayLocation;

  if (!hasSearchableLocation) {
    return null;
  }

  if (location.name) {
    locationChunks.push(displayLocation);
  }

  if (location.city) {
    locationChunks.push(location.city);
  }

  if (location.line_1) {
    locationChunks.push(location.line_1);
  }

  if (
    (location.street_address && !location.line_1) ||
    location.line_1 != location.street_address
  ) {
    locationChunks.push(location.street_address);
  }

  if (location.line_2) {
    locationChunks.push(location.line_2);
  }

  if (hasSearchableLocation) {
    locationUrl += locationChunks.join(", ");
  } else {
    return null;
  }

  return locationUrl;
};

const EventDetailsLocation = (props) => {
  const calendarSettingsContext = useContext(WidgetSettingsContext);
  const { t } = useTranslation();

  const showLocation =
    Number.parseInt(calendarSettingsContext.eventDetailsShowLocation) === 1;

  const locationAsLinkValue =
    Number.parseInt(calendarSettingsContext.eventDetailsLinkLocation) === 1;

  const { location, displayLocation } = props;

  const linkLocation = showLocation && locationAsLinkValue;

  const hasLocationAddress =
    location &&
    (displayLocation != null ||
      location.line_1 != null ||
      location.line_2 != null ||
      location.street_address);

  const locationBlock = useMemo(
    () =>
      showLocation && hasLocationAddress ? (
        <React.Fragment>
          <div className="col-icon">
            <i className="material-icons">room</i>
          </div>

          <div>
            <h3 className="">{t("Event location")}</h3>
            <div className={"dc-address-link"}>
              {displayLocation ? (
                <h4>{displayLocation}</h4>
              ) : location.name ? (
                <h4>{location.name}</h4>
              ) : null}
              <p className="p i">
                {location.line_1 ? (
                  <span>
                    {location.line_1}
                    <br />
                  </span>
                ) : null}
                {location.street_address &&
                (!location.line_1 ||
                  location.line_1 != location.street_address) ? (
                  <span>
                    {location.street_address}
                    <br />
                  </span>
                ) : null}

                {location.line_2 ? (
                  <span>
                    {location.line_2}
                    <br />
                  </span>
                ) : null}
              </p>
            </div>
          </div>
        </React.Fragment>
      ) : null,
    []
  );

  const locationUrl = generateGoogleLinkFromLocation(displayLocation, location);

  // extract url from the displayLocation string, if it does not contain a url it is null
  const displayLocationUrl = useMemo(() => {
    if (displayLocation) {
      const urlRegex = /(https?:\/\/[^\s]+)/g;
      const url = displayLocation.match(urlRegex);
      return url ? url[0] : null;
    }
    return null;
  }, [displayLocation]);

  // if displayLocation contains a url, use that instead of the locationUrl
  const locationUrlToUse = useMemo(() => {
    return displayLocationUrl ? displayLocationUrl : locationUrl;
  }, [displayLocationUrl, locationUrl]);

  return showLocation && linkLocation && locationUrl ? (
    <a
      className={"col-content col-content--left"}
      href={locationUrlToUse}
      target={"_blank"}
    >
      {locationBlock}
    </a>
  ) : hasLocationAddress ? (
    <div className="col-content col-content--left">{locationBlock}</div>
  ) : null;
};

EventDetailsLocation.defaultProps = {};

EventDetailsLocation.propTypes = {
  location: PropTypes.object.isRequired,
  displayLocation: PropTypes.string,
};

export default EventDetailsLocation;
