/**
 * Created by piotr.pozniak@thebeaverhead.com on 19/05/2024
 */

import React, { useCallback } from "react";
import PropTypes from "prop-types";

const NumberInput = (props) => {
  const onChange = useCallback((event) => {
    props.updateDesignProp([
      {
        field: props.name,
        value: event.target.value,
      },
    ]);
  }, []);

  return (
    <React.Fragment>
      <input
        type="number"
        className="form-control"
        disabled={props.disabled}
        value={!props.disabled ? props.customInputValue : ""}
        onChange={onChange}
      />
      {props.postLabel || null}
    </React.Fragment>
  );
};

NumberInput.defaultProps = {};

NumberInput.propTypes = {
  disabled: PropTypes.bool,
};

export default NumberInput;
