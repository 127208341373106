/**
 * Created by piotr.pozniak@thebeaverhead.com on 01/12/2021.
 */

import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

const EventBadge = (props) => {
  return (
    <div
      className={classnames(
        `dce--event-badge dce--event-badge-${props.badgeType}`,
      )}
    >
      <i className={"material-icons"}>{props.icon}</i>
      <span>{props.label}</span>
    </div>
  );
};

EventBadge.defaultProps = {
  icon: "autorenew",
  label: "Recurring",
};

EventBadge.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string,
  badgeType: PropTypes.string,
};

export default EventBadge;
