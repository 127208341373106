/**
 * Created by piotr.pozniak@thebeaverhead.com on 14/06/2019.
 */

import React, { useContext, useCallback } from "react";
import PropTypes from "prop-types";
import IntegrationContext from "../../../../calendar/strategies/IntegrationContext";
import { isCTAllowed } from "../../../../../builder";
import RadioInput from "../../../component/WidgetSettings/RadioInput";
import { IT, PLANS } from "../../../../../consts";
import ExpandableAlert from "../../../component/ExpandableAlert";
import EventsFiltering from "./EventsFiltering";
import { useCurrentOrganizationStore } from "../../../../../hooks/redux/currentOrganization";
import { hasSubscription } from "../../../helpers/organization";
import Accordion from "../../../component/WidgetSettings/Acordion";
import appConfig from "../../../../../appConfig";

const FiltersSummaryMapping = {
  hasPrivateEvents: "private events",
  hasHiddenEvents: "all events including hidden",
  publicEvents: "public events only",
  hasGroupEvents: "group-specific events",
  hasImages: "events with images only",
  hasMergeRecurringEvents: "merges recurring events",
};

/**
 *
 * @param settings
 * @param filters
 * @returns {string}
 */
const getSummary = (settings, filters) => {
  const summary = Object.keys(settings)
    .map((key) => {
      if (!settings[key] || !FiltersSummaryMapping[key]) {
        return null;
      }

      return FiltersSummaryMapping[key];
    })
    .filter((i) => i);

  let filtersSummary = "";
  if (filters && filters.groups.length) {
    const rule = filters.groups[0].rules[0];

    filtersSummary =
      ", filtered by " +
      rule.field.label +
      " " +
      rule.operator.label +
      " " +
      rule.label;

    if (filters.groups.length > 1 || filters.groups[0].rules.length > 1) {
      let filtersCount = -1;
      for (const gIdx in filters.groups) {
        const group = filters.groups[gIdx];
        filtersCount += group.rules.length;
      }
      filtersSummary += " and " + filtersCount + " more";
    }
  }

  return (
    <span className={"d-flex"}>
      <i className={"material-icons text-info p-0 m-0"}>info</i>
      <span className={"m-1"}>
        {"Shows " +
          summary.join(" and ") +
          filtersSummary.toLocaleLowerCase() +
          "."}
      </span>
    </span>
  );
};

const FiltersSection = (props) => {
  const integrationStrategy = useContext(IntegrationContext);

  const { calendarModel } = props;

  const { currentOrganization } = useCurrentOrganizationStore();
  const subscription = hasSubscription(currentOrganization);

  const isPrivateEventsAllowed =
    calendarModel &&
    calendarModel.integration &&
    (calendarModel.integration.type !== IT.ccb ||
      (calendarModel.integration.type === IT.ccb &&
        subscription &&
        [PLANS.medium, PLANS.highest].indexOf(subscription.code) >= 0))
      ? true
      : false;

  /**
   *
   * @type {(function(*): void)|*}
   */
  const onUpdateFilters = useCallback(
    (filters) => {
      props.onUpdateFilterSettings("filters", filters);
    },
    [calendarModel, props.onUpdateFilterSettings]
  );

  /**
   *
   * @type {function(*): function(*): void}
   */
  const onFilteringCheckboxChange = useCallback(
    (field) => (e) => {
      const isBoolean = typeof calendarModel[field] === "boolean";
      if (isBoolean) {
        return props.onUpdateField(field, !calendarModel[field]);
      }
      props.onUpdateField(field, !(props.calendarModel[field] * 1) ? 1 : 0);
    },
    [props.calendarModel]
  );

  const showInfoAlert = integrationStrategy.filterLabelCTA ? (
    <ExpandableAlert
      errorLevel={"info"}
      header={"How to use filters below?"}
      body={integrationStrategy.filterLabelCTA}
    />
  ) : null;

  const mergeRecurringEvents =
    calendarModel.integration &&
    isCTAllowed(props.calendarModel.integration.type, [
      IT.ccb,
      IT.pco,
      IT.google,
      IT.breeze,
    ]) ? (
      <RadioInput
        fieldName={"group_recurring_events"}
        label={"Merge recurring events"}
        onChange={onFilteringCheckboxChange}
        checked={calendarModel.group_recurring_events * 1}
      />
    ) : null;

  const privateEventsError =
    calendarModel.show_private_events * 1 &&
    !props.integrationModel.sync_private
      ? integrationStrategy.privateEventsSettingsWarning(
          calendarModel.integration.uuid
        )
      : null;

  const privateEventsWarning =
    calendarModel.show_private_events * 1 ? (
      <ExpandableAlert
        icon={null}
        header={"Recommended helpful tip:"}
        body={
          <span>
            We recommend using the events filtering option available below.
            Publishing your calendar without events filtering will reveal{" "}
            <strong>all</strong> the events!
          </span>
        }
        errorLevel={"info"}
      ></ExpandableAlert>
    ) : null;

  const privateEventsControl =
    calendarModel.uuid && integrationStrategy.privateEventsControl ? (
      <React.Fragment>
        <RadioInput
          fieldName={"show_private_events"}
          label={integrationStrategy.privateEventsControl.toggleButtonLabel}
          onChange={onFilteringCheckboxChange}
          checked={calendarModel.show_private_events * 1}
          tooltip={
            integrationStrategy.privateEventsControl.privateEventsTooltip
          }
          isPremium={!isPrivateEventsAllowed}
        />
        {privateEventsError}
        {privateEventsWarning}
      </React.Fragment>
    ) : null;

  const groupSpecificFiltering =
    calendarModel.uuid && calendarModel.integration.type === IT.pco ? (
      <React.Fragment>
        <RadioInput
          fieldName={"has_group_events_included"}
          label={"Include group-specific events"}
          onChange={onFilteringCheckboxChange}
          checked={calendarModel.has_group_events_included * 1}
          isPremium={!isPrivateEventsAllowed}
        />
        {/*{privateEventsError}
        {privateEventsWarning}*/}
      </React.Fragment>
    ) : null;

  const widgetFilters = (
    <EventsFiltering
      widgetModel={calendarModel}
      integrationModel={props.integrationModel}
      onUpdateFilters={onUpdateFilters}
    />
  );

  const subtitle = getSummary(
    {
      hasPrivateEvents:
        calendarModel.integration &&
        calendarModel.integration.type !== IT.pco &&
        calendarModel.show_private_events,
      hasHiddenEvents:
        calendarModel.integration &&
        calendarModel.integration.type === IT.pco &&
        calendarModel.show_private_events,
      publicEvents: !calendarModel.show_private_events,
      hasGroupEvents: calendarModel.has_group_events_included,
      hasImages: calendarModel.show_events_with_images,
      hasMergeRecurringEvents: calendarModel.group_recurring_events,
    },
    calendarModel.filter_settings ? calendarModel.filter_settings.filters : null
  );

  return (
    <Accordion
      title={"Events Filtering"}
      subtitle={subtitle}
      defaultExpanded={true}
    >
      <form action="#">
        {privateEventsControl}
        {groupSpecificFiltering}

        <RadioInput
          fieldName={"show_events_with_images"}
          label={"Show events with images only"}
          onChange={onFilteringCheckboxChange}
          checked={calendarModel.show_events_with_images * 1}
          tooltip={{
            url: `${appConfig.helpdeskAddress}/en/articles/4901525-setup-events-show-events-with-images-only`,
            message: "Show events with images only",
          }}
        />

        {mergeRecurringEvents}
      </form>

      <div
      /*allowedPlans={allowedPlans}
        user={props.user}
        CTAMessage={
          "You can limit events by selecting specific filters. Upgrade your plan to unlock this functionality. "
        }*/
      >
        <p>&nbsp;</p>

        {showInfoAlert}
        {widgetFilters}
      </div>
    </Accordion>
  );
};

FiltersSection.propTypes = {
  calendarModel: PropTypes.object.isRequired,
  integrationModel: PropTypes.object.isRequired,
  onUpdateField: PropTypes.func.isRequired,
  onUpdateFilterSettings: PropTypes.func.isRequired,
};

export default FiltersSection;
