/**
 * Created by piotr.pozniak@thebeaverhead.com on 15/04/2022.
 */

import {PLANS} from "../../../consts";

/**
 * Created by piotr.pozniak@thebeaverhead.com on 03/06/2021.
 */


class GroupsIntegrationStrategy {

  /**
   * list names of event_metadata.kind values used for events filtering. E.g. group.
   * @type {[]}
   */
  availableFilters = [];

  /**
   * settings per available filter. properties:
   * - label - used to display name of the filter
   * @type {{}}
   */
  filtersSettings = {};


  /**
   * an jsx object rendered to inform what filters in calendar settings do.
   * @type {null}
   */
  filterLabelCTA = "Hey Lisa! We need a copy for this section 🤭";


  /**
   *
   * @type {[]}
   */
  featuredAllowedPlans = [];

  /**
   * processes returned events_metadata data into AsyncSelector collection grouped
   * by events_metadata.kind.
   * @param data
   * @returns {}
   * @example return data:
   * {
   *   "groups": [
   *     {
   *       label: "display label",
   *       value: eg. id
   *       uuid: same as value,
   *     }
   *   ]
   * }
   *
   */
  filterRawDataToStateObject = data => {

    console.warn("CalendarIntegrationStrategy::filterRawDataToStateObject not inherited");

    return {};
  }


  /**
   * processes returned events_metadata data into AsyncSelector collection
   * @param data
   * @returns
   * @example return data:
   * [
   *     {
   *       label: "display label",
   *       value: eg. id
   *       uuid: same as value,
   *     }
   *  ]
   *
   *
   */
  filterRawToDisplayObject = data => {

    console.warn("CalendarIntegrationStrategy::filterRawToDisplayObject not inherited");

    return {};
  }


  /**
   * renders a display name from events_metadata object
   * @param filter
   */
  filterRenderName = filter => {
    console.warn("CalendarIntegrationStrategy::filterRenderName not inherited");
  }

}

export default GroupsIntegrationStrategy;
