import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import HamburgerDropdown from "../../component/UI/HamburderDropdown";

const CurrencyFormatter = Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const TableRow = (props) => {
  /**
   *
   */
  const onUpdate = useCallback((e, callback) => {
    if (e.preventDefault) {
      e.preventDefault();
    }
    e.preventDefault();

    callback(props.coupon);

    return false;
  }, []);

  const { uuid, start_date, code, end_date, duration, price } = props.coupon;

  const disabled = props.disabled;

  const menuOptions = useMemo(
    () => [
      {
        label: "Edit",
        icon: "edit",
        onClick: (e) => onUpdate(e, props.onEdit),
      },
      {
        label: "Delete",
        icon: "delete",
        onClick: (e) => onUpdate(e, props.onDelete),
      },
    ],
    []
  );

  return (
    <tr>
      <td>{moment(start_date * 1000).format("YYYY-MM-DD")}</td>
      <td>{moment(end_date * 1000).format("YYYY-MM-DD")}</td>
      <td>{duration / 24 / 60 / 60}</td>
      <td>{code}</td>
      <td>{CurrencyFormatter.format(price / 100)}</td>

      <td className="text-right">
        <HamburgerDropdown disabled={disabled} options={menuOptions} />
      </td>
    </tr>
  );
};

TableRow.propTypes = {
  coupon: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    start_date: PropTypes.number.isRequired,
    end_date: PropTypes.number.isRequired,
    duration: PropTypes.number.isRequired,
  }).isRequired,

  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default TableRow;
