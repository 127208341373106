import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import ActionButton from "./ActionButton";
import { OrganizationMemberStatus } from "../../consts/crm";
import { useOrganizationMembersStore } from "../../../../hooks/redux/organizationMembers";

const Actions = ({ member, onRemove, onResendInvitation, onCancelRemove }) => {
  const { organizationMembers, resetReinviteState } =
    useOrganizationMembersStore();

  const markedForDeletion = useMemo(
    () => member.invite_status === OrganizationMemberStatus.delete,
    [member.invite_status]
  );

  useEffect(() => {
    if (
      organizationMembers.resendInvitationError ||
      organizationMembers.resendInvitationSuccess
    ) {
      setTimeout(() => {
        resetReinviteState();
      }, 1000);
    }
  }, [
    organizationMembers.resendInvitationError,
    organizationMembers.resendInvitationSuccess,
  ]);

  const removeBtn = (
    <ActionButton
      onClick={!markedForDeletion ? onRemove : onCancelRemove}
      label={!markedForDeletion ? "Remove" : "Undo"}
      icon={!markedForDeletion ? "delete" : "undo"}
      className={!markedForDeletion ? "btn-link text-danger" : ""}
    />
  );

  const loader = useMemo(
    () =>
      organizationMembers.resendInvitation &&
      organizationMembers.resendInvitationUUID === member.uuid ? (
        <p className="d-flex align-items-center justify-content-center m-0 ml-1">
          Resending invitation...
        </p>
      ) : null,
    [
      organizationMembers.resendInvitation,
      organizationMembers.resendInvitationUUID,
    ]
  );

  const resendInvitationStatus = useMemo(() => {
    if (organizationMembers.resendInvitationUUID !== member.uuid) {
      return null;
    }

    let message = "";
    if (organizationMembers.resendInvitationSuccess) {
      message = (
        <p className="d-flex align-items-center justify-content-center m-0 ml-1 text-success">
          Invitation resent successfully.
        </p>
      );
    } else if (organizationMembers.resendInvitationError) {
      message = (
        <p className="d-flex align-items-center justify-content-center m-0 ml-1 text-danger">
          Failed to resend invitation.
        </p>
      );
    }

    return message;
  }, [
    organizationMembers.resendInvitationError,
    organizationMembers.resendInvitationSuccess,
    organizationMembers.resendInvitationUUID,
    member.uuid,
  ]);

  const resendInvitationBtn = resendInvitationStatus ? (
    resendInvitationStatus
  ) : member.invite_status === OrganizationMemberStatus.pending ? (
    <ActionButton
      onClick={onResendInvitation}
      label={"Resend invitation"}
      className={"btn-link"}
      icon={"reply"}
      disabled={organizationMembers.resendInvitation}
      loading={loader}
    />
  ) : null;

  return (
    <div className="d-flex">
      {removeBtn}
      {resendInvitationBtn}
    </div>
  );
};

Actions.propTypes = {
  onResendInvitation: PropTypes.func.isRequired,
  onCancelRemove: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default Actions;
