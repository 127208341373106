/**
 * Created by piotr.pozniak@thebeaverhead.com on 05/04/2022
 */

import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as widgetActions from "../../actions/widget";
import * as widgetItemsActions from "../../actions/widgetItems";
import { fetchWidgetItem } from "../../actions/widgetItems";

export function useWidgetStore() {
  const dispatch = useDispatch();
  const _widget = useSelector((store) => store.widget);
  const _widgetItems = useSelector((store) => store.widgetItems);

  const fetchWidget = useCallback(
    async (widgetType, uuid) =>
      await dispatch(widgetActions.fetchWidget(widgetType, uuid)),
    [dispatch]
  );

  const deleteWidget = useCallback(
    async (uuid) => await dispatch(widgetActions.deleteWidget(uuid)),
    [dispatch]
  );

  const updateWidget = useCallback(
    async (uuid, data) =>
      await dispatch(widgetActions.updateWidget(uuid, data)),
    [dispatch]
  );

  const setWidget = useCallback(
    async (model) => await dispatch(widgetActions.setWidget(model)),
    [dispatch]
  );

  const fetchItems = useCallback(
    async (widgetType, uuid, queryParams, postData, preservePreviousItems) =>
      await dispatch(
        widgetItemsActions.fetchItems(
          widgetType,
          uuid,
          queryParams,
          postData,
          preservePreviousItems
        )
      ),
    [dispatch]
  );

  const fetchItem = useCallback(
    async (widgetType, slug) =>
      await dispatch(widgetItemsActions.fetchWidgetItem(slug, widgetType)),
    [dispatch]
  );

  return {
    widget: _widget,
    widgetItems: _widgetItems,
    deleteWidget,
    updateWidget,
    fetchWidget,
    setWidget,
    fetchItems,
    fetchItem,
  };
}
