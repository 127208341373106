import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { PLAN_NAMES } from "../../../../consts";
import classnames from "classnames";
import { store } from "../../store";
import { useAutologinStore } from "../../../../hooks/redux/autologin";
import { useUserStore } from "../../../../hooks/redux/user";
import { useHistory } from "react-router-dom";
import { durationToString } from "../../tools";
import HamburgerDropdown from "../../component/UI/HamburderDropdown";

const IntegrationTypeToIcon = ({ type }) => {
  return (
    <img
      src={`/img/integrations_icons/${type}.png`}
      width={"16"}
      className={"img-responsive m-r-5"}
    />
  );
};

const QueueJobToStatus = ({ job }) => {
  const { fetched, failed, completed } = job;

  let className = "text-secondary";

  if (failed) {
    className = "text-danger";
  }

  if (completed) {
    className = "text-success";
  }

  if (fetched && !failed && !completed) {
    className = "text-primary pulse";
  }

  return (
    <i className={classnames("material-icons", className)}>
      radio_button_checked
    </i>
  );
};

const TableRow = (props) => {
  /**
   *
   */

  const { id, created, data, disabled } = props.job;

  const { autologinUser } = useAutologinStore();
  const { user } = useUserStore();
  const history = useHistory();

  /*/!**
   *
   * @type {(function(): Promise<void>)|*}
   *!/
  const onAutoLogin = useCallback(async () => {
    await autologinUser(data.email, user);

    if (store.getState().autologin.loginSuccess) {
      history.push("/dashboard");
    }
  }, []);

  const menuOptions = useMemo(() => {
    return [
      {
        label: "Auto login",
        icon: "login",
        onClick: onAutoLogin,
      },
    ];
  }, [onAutoLogin]);
*/
  return (
    <tr>
      <td>
        <IntegrationTypeToIcon type={data.integration_type} />
        {PLAN_NAMES[data.plan]}
      </td>
      <td>{id}</td>
      <td>{moment(created).format("YYYY-MM-DD HH:mm:ss")}</td>
      <td>{data.email}</td>
      <td align={"center"}>
        <QueueJobToStatus job={props.job} />
      </td>

      <td className="text-right">
        {/* <HamburgerDropdown disabled={disabled} options={menuOptions} />*/}
      </td>
    </tr>
  );
};

TableRow.propTypes = {
  job: PropTypes.shape({
    id: PropTypes.number.isRequired,
    created: PropTypes.string.isRequired,
    fetched: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
  }).isRequired,
};

export default TableRow;
