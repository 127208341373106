/**
 * Created by piotr.pozniak@thebeaverhead.com on 11/04/2023
 */

import React, { useCallback, useContext } from "react";
import PropTypes from "prop-types";
import RuleDropdown from "./RuleDropdown";
import { AvailableRulesOptionsContext, FiltersContext } from "./contexts";
import classNames from "classnames";

const AddButton = (props) => {
  const [isEditMode, setIsEditMode] = React.useState(false);
  const availableRulesOptions = useContext(AvailableRulesOptionsContext);
  const { filters, updateGroup, setNewRule } = useContext(FiltersContext);

  /**
   *
   * @type {(function(): void)|*}
   */
  const onToggleNewRule = useCallback(
    (e) => {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }
      // console.log("onToggleNewRule");
      setIsEditMode(!isEditMode);
    },
    [isEditMode]
  );

  /**
   *
   * @type {(function(*): void)|*}
   */
  const onAddNewRule = useCallback(
    (rule) => {
      const group = filters.groups[props.groupId] || {
        conjunction: "and",
        rules: [],
      };

      // it needs to be altered, operator may contain metadata like input
      const newRule = {
        field: rule.field,
        operator: {
          label: rule.operator.label,
          value: rule.operator.value,
        },
        value: rule.value,
        label: rule.label,
      };

      const updatedGroup = {
        ...group,
        rules: [...group.rules, newRule],
      };

      updateGroup(props.groupId, updatedGroup);
      setNewRule({ groupId: props.groupId, id: updatedGroup.rules.length - 1 });
      setIsEditMode(false);
    },
    [filters]
  );

  const newRule = isEditMode ? (
    <RuleDropdown
      onClose={onToggleNewRule}
      availableRules={availableRulesOptions}
      onSelectedNewRule={onAddNewRule}
    />
  ) : null;

  return (
    <div
      className={classNames("filter-group__add", props.className)}
      onClick={onToggleNewRule}
    >
      {props.children}
      {newRule}
    </div>
  );
};

AddButton.defaultProps = {};

AddButton.propTypes = {
  className: PropTypes.string,
  groupId: PropTypes.number,
};

export default AddButton;
