import React from "react";
import PropTypes from "prop-types";

const VimeoPreview = ({ videoId }) => (
  <div>
    <iframe
      src={`https://player.vimeo.com/video/${videoId}`}
      width="560"
      height="315"
      frameBorder="0"
      allow="autoplay; fullscreen"
      allowFullScreen
      title="Vimeo Video Preview"
    ></iframe>
  </div>
);

VimeoPreview.propTypes = {
    videoId: PropTypes.string,
};

export default VimeoPreview;
