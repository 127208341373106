/**
 * Created by piotr.pozniak@thebeaverhead.com on 24/10/2018.
 */

import React, { useContext } from "react";

import ColorSelector from "./ColorSelector";
import PropTypes from "prop-types";
import DesignTooltip from "./DesignTooltip";
import { BuilderInputSetupPropTypes } from "./BuilderOptionPropTypes";
import WidgetSettingsContext from "../../../../../contexts/WidgetSettingsContext";

const ColorSetup = (props) => {
  const { name, defaultValue, dependency, settingsName } = props;

  const nameOption = name + "Option";
  const inputName = `${settingsName}-color-setup-${name}`;

  const widgetSettingsContext = useContext(WidgetSettingsContext);

  const inputValue = widgetSettingsContext[name] || "default";
  const optionValue = widgetSettingsContext[nameOption] || "default";

  if (!props.show) {
    return null;
  }

  /**
   *
   * @param name
   * @returns {function(...[*]=)}
   */
  const onColorChange = (name) => (color) => {
    const { r, g, b, a } = color.rgb;
    let normalizedA = ~~(a * 255);

    const hex =
      "#" +
      (r < 10 ? "0" : "") +
      r.toString(16) +
      (g < 10 ? "0" : "") +
      g.toString(16) +
      (b < 10 ? "0" : "") +
      b.toString(16) +
      (a < 0.05 ? "0" : "") +
      normalizedA.toString(16);

    props.updateDesignProp([{ field: name, value: hex }]);
  };

  /**
   *
   * @param nameOption
   * @param name
   * @param defaultValue
   * @param kind
   * @returns {function(...[*]=)}
   */
  const onOptionChange = (nameOption, name, defaultValue, kind) => (event) => {
    props.updateDesignProp([
      { field: nameOption, value: kind },
      { field: name, value: defaultValue },
    ]);
  };

  /**
   *
   * @param name
   * @returns {function(*): *}
   */
  const onCustomValueChange = (name) => (event) =>
    props.updateDesignProp([
      {
        field: name,
        value: event.target.value,
      },
    ]);

  const { showInherit, showCustomValue, showTransparentOption } =
    props.control.options;

  const inherit = showInherit ? (
    <div className="radio">
      <label>
        <input
          type="radio"
          name={inputName}
          checked={optionValue === "inherit"}
          onChange={onOptionChange(nameOption, name, "inherit", "inherit")}
        />{" "}
        Inherit from website
      </label>
    </div>
  ) : null;

  const transparent = showTransparentOption ? (
    <div className="radio">
      <label>
        <input
          type="radio"
          name={inputName}
          checked={optionValue === "transparent"}
          onChange={onOptionChange(
            nameOption,
            name,
            "transparent",
            "transparent"
          )}
        />{" "}
        Transparent
      </label>
    </div>
  ) : null;

  const propertyValueIsCustom = optionValue === "custom";

  let isDependencyMeet = true;
  const flashMessages = [];

  if (dependency) {
    const dependencyArray = Array.isArray(dependency)
      ? dependency
      : [dependency];

    dependencyArray.map((i) => {
      const depValue = i.value; //) ? i.value : i.defaultValue;
      const dependencyMeet =
        widgetSettingsContext[i.field] === undefined ||
        widgetSettingsContext[i.field] === i.value;

      isDependencyMeet &= dependencyMeet;

      if (!dependencyMeet) {
        flashMessages.push(i.message);
      }
    });

    //isPropertyDependencyMet = !isPropertyDependencyMet;
  }

  const showFlashMessage = propertyValueIsCustom && !isDependencyMeet;

  const isCustomValue = optionValue === "customValue";

  const isCustomValueChecked = optionValue === "customValue";

  const customValue = showCustomValue && (
    <div className="radio">
      <label>
        <input
          type="radio"
          name={inputName}
          checked={isCustomValueChecked}
          onChange={onOptionChange(
            nameOption,
            name,
            defaultValue,
            "customValue"
          )}
        />{" "}
        Custom color value
      </label>
      <input
        disabled={!isCustomValue}
        value={!isCustomValue ? "" : inputValue}
        onChange={onCustomValueChange(name)}
        type="text"
        className="form-control"
        placeholder={"CSS value"}
      />
    </div>
  );

  return (
    <div className={"design-element"}>
      <label className="option-label">
        <span className={"option-title"}>{props.title}</span>
        <DesignTooltip help={props.tooltip} />
      </label>

      {showFlashMessage && (
        <p>
          <i className="text-warning">{flashMessages.join(" ")}</i>
        </p>
      )}

      <div className="form-group color-picker-row">
        <div className="col-xs-6">
          <div className="radio">
            <label>
              <input
                type="radio"
                name={inputName}
                checked={optionValue === "default"}
                onChange={onOptionChange(
                  nameOption,
                  name,
                  defaultValue,
                  "default"
                )}
              />{" "}
              Default
            </label>
          </div>

          {inherit}

          <div className="radio">
            <label>
              <input
                type="radio"
                name={inputName}
                checked={optionValue === "custom"}
                onChange={onOptionChange(
                  nameOption,
                  name,
                  defaultValue,
                  "custom"
                )}
              />{" "}
              Custom {showCustomValue && "color picker"}
            </label>
          </div>

          {transparent}
          {customValue}
        </div>
        <div className="col-xs-6">
          <ColorSelector
            id={inputName}
            disabled={optionValue !== "custom"}
            color={inputValue}
            onColorChagnge={onColorChange(name)}
          />
        </div>
      </div>
    </div>
  );
};

ColorSetup.propTypes = {
  ...BuilderInputSetupPropTypes,

  control: PropTypes.shape({
    kind: PropTypes.oneOf(["color"]).isRequired,
    options: PropTypes.shape({
      showInherit: PropTypes.bool,
      showCustomValue: PropTypes.bool,
      showTransparentOption: PropTypes.bool,
    }),
  }).isRequired,
};

ColorSetup.defaultProps = {
  control: {
    kind: "color",
    options: {
      showTransparentOption: false,
    },
  },
};

export default ColorSetup;
