/**
 * Created by piotr.pozniak@thebeaverhead.com on 18/12/2023.
 */

import { useDispatch, useSelector } from "react-redux";

export function useFeaturedEventsStore() {
  const _featuredEvents = useSelector((store) => store.featuredEvents);

  return {
    featuredEvents: _featuredEvents,
    // fetchFeaturedEvents,
  };
}
