/**
 * Created by piotr.pozniak@thebeaverhead.com on 18/05/2024
 */

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const NavButton = ({ icon, className, direction, onDateChange }) => {
  return (
    <div
      className={classNames("arrow", className)}
      onClick={onDateChange(direction)}
    >
      <i className="material-icons">{icon}</i>
    </div>
  );
};

NavButton.defaultProps = {};

NavButton.propTypes = {};

export default NavButton;
