/**
 * Created by piotr.pozniak@thebeaverhead.com on 26/07/2021.
 */

import React, { useEffect, useState } from "react";
import { CCBIntegrationAddress as address, IT } from "../../../../consts";
import ApiError from "../../component/ApiError";
import FormError from "../../component/FormError";
import LoadingIndicator from "../../../calendar/components/LoadingIndicator";
import { useIntegrationStore } from "../../../../hooks/redux/integration";
import { useUserStore } from "../../../../hooks/redux/user";
import Scopes from "./Scopes";
import CCBPrivateEventsSync from "./CCBPrivateEventsSync";
import WelcomeCard from "./WelcomeCard";
import OpenAIForm from "./OpenAIForm";
import classname from "classnames";

export const CCBIntegrationHelp = () => {
  return (
    <p>
      To learn more about the CCB API integration, please click on{" "}
      <a
        href={
          "https://help.display.church/en/articles/5669866-dce-integrations-how-to-set-them-up"
        }
        target="_blank"
      >
        this address
      </a>
      .
    </p>
  );
};

const CCBScopes = {
  mandatory: {
    mandatory: true,
    label: "Mandatory",
    description: (
      <span>
        This scope is used to identify either Calendars or Groups data across
        your Church. Required API services: <i>campus_list</i>,{" "}
        <i>campus_profile</i>.
      </span>
    ),
  },
  calendar: {
    mandatory: false,
    label: "Calendar",
    description: (
      <span>
        This scope will pull your all events from your public, groups and
        individuals calendars. Required API services: <i>group_profiles</i>,{" "}
        <i>event_profiles</i>, <i>public_calendar_listing</i>.
      </span>
    ),
    additionalComponent: CCBPrivateEventsSync,
  },
  groups: {
    mandatory: false,
    label: "Groups",
    description: (
      <span>
        This scope will pull basic information about your groups. Required API
        services: <i>group_profiles</i>, <i>group_type_list</i>,{" "}
        <i>group_type_detail</i>, <i>area_list</i>, <i>area_detail</i>.
      </span>
    ),
  },

  rsvp: {
    mandatory: false,
    label: "RSVP",
    description: (
      <span>
        This scope is used when a person RSVP to an event. Required API
        services: <i>add_individual_to_event</i>,{" "}
        <i>individual_id_from_login_password</i>
      </span>
    ),
  },
  forms: {
    mandatory: false,
    label: "Forms",
    description: (
      <span>
        We will pull your Forms, so you can embed your signup forms. Required
        API services: <i>form_detail</i>, <i>form_list</i>
      </span>
    ),
  },
};

const CCBIntegrationForm = (props) => {
  const { integration, updateIntegration, syncIntegration } =
    useIntegrationStore();
  const { user } = useUserStore();

  const integrationModel =
    integration.model && integration.model.uuid ? integration.model : null;

  const scopes = Object.keys(CCBScopes).map((i) => {
    const existingScope = integrationModel?.integration_scopes.find(
      (s) => s.name === i
    );
    let available = 0;

    if (integrationModel && !existingScope) {
      available = 0;
    } else if (integrationModel && existingScope) {
      available = existingScope.available;
    } else if (!integrationModel) {
      available = 1;
    }

    return {
      name: i,
      available,
    };
  });

  const [state, setState] = useState({
    //nameError: null,
    usernameError: null,
    passwordError: null,
    addressError: null,
  });

  useEffect(() => {
    if (integration.updateSuccess && integrationModel.uuid) {
      syncIntegration(integrationModel.uuid);
    }
  }, [integration.updateSuccess]);

  /*componentWillReceiveProps(nextProps) {

    if (this.props.integration.uuid != nextProps.integration.uuid) {
      this.setState({
        showPrivateEventsInfo: nextProps.integration.sync_private * 1,
      });
    }
  }
*/
  /**
   *
   */
  const onSubmit = async (e) => {
    e.preventDefault();

    const username = document.querySelector("#integration_username").value;
    const name = "CCB Integration"; //document.querySelector("#integration_name").value;
    const password = document.querySelector("#integration_password").value;

    const syncPrivateCheckbox = document.querySelector(
      "#integration_sync_private"
    );
    const syncPrivate =
      syncPrivateCheckbox && syncPrivateCheckbox.checked ? 1 : 0;
    const addressSubdomain = document.querySelector(
      "#integration_address"
    ).value;

    const available =
      integrationModel && integrationModel.uuid
        ? integrationModel.available
        : 1;

    let usernameError = null;
    //let nameError = null;
    let passwordError = null;
    let addressError = null;

    /*if (!name || !name.length) {
      nameError =
        "Please enter a memorable integration name (can be anything).";
    }
    */
    if (!username || !username.length) {
      usernameError = "Please enter API Userame. ";
    }

    if (!password || !password.length) {
      passwordError = "Please enter API User Password. ";
    }

    if (!addressSubdomain || !addressSubdomain.length) {
      addressError = "Please enter API Endpoint Address Subdomain. ";
    }

    const integration_scopes = scopes.map((i) => {
      const scopeCheckbox = document.getElementById(`ccb-scope-${i.name}`);
      return {
        uuid: i.uuid,
        name: i.name,
        available: scopeCheckbox && scopeCheckbox.checked ? 1 : 0,
      };
    });

    setState({
      ...state,
      usernameError,
      //nameError,
      passwordError,
      addressError,
    });

    if (!usernameError && !passwordError && !addressError /*&& !nameError*/) {
      const data = {
        ...integrationModel,
        username,
        name,
        password,
        address: address.scheme + addressSubdomain + address.host,
        available,
        sync_private: syncPrivate,
        type: "ccb",
        integration_scopes,
      };

      await updateIntegration(integrationModel?.uuid || null, data);
    }

    return false;
  };

  const { update, updateError } = integration;

  const loadingIndicator = update ? <LoadingIndicator /> : null;

  const isLoading = update;
  const isNew = !integrationModel ? true : false;

  if (!isNew) {
    address.subdomain = integrationModel.address.split("://")[1].split(".")[0];
  }

  const apiAccessCheckWarning =
    isNew && update ? (
      <div className={"alert alert-info"}>
        Please be patient. We are checking your CCB API configuration - it can
        take up to 30 seconds.
      </div>
    ) : null;

  const newSyncAlert = isNew ? (
    <div className={"alert alert-warning"}>
      Sync process may take between 5 to 10 minutes to finish. Once you create a
      new integration, sync process will start automatically.
    </div>
  ) : null;

  const submitButtonLabel = isNew ? "Start Integration" : "Update";
  const openAIForm = !isNew ? <OpenAIForm /> : null;
  return (
    <React.Fragment>
      <div className={"col-xs-12 col-md-12"}>
        <WelcomeCard
          integrationName={"CCB API"}
          helpArticleUrl={
            "https://help.display.church/en/articles/5669866-dce-integrations-how-to-set-them-up"
          }
          videoId={"d27dbf69ff254b6f84b5c44fb2ed9085"}
          imageSrc={"/img/integrations_howto/howto_ccb.png"}
        >
          <p>
            To synchronize with Church Community Builder, log in to the CCB API
            by providing access credentials in the form below. We will use these
            credentials to pull your public calendar entries from your CCB
            account. You will also be able to manually pull the CCB data.
          </p>
        </WelcomeCard>
      </div>
      <div className={classname("col-xs-12", { "col-lg-6": !isNew })}>
        <div className={"card pt-sm-4 pl-sm-4 pr-sm-4"}>
          <form className={"card-body"}>
            {/*<div className="form-group col-xs-12 col-md-6">
            <label>
              Memorable name <span className="text-danger">*</span>
            </label>

            <input
              id="integration_name"
              className={
                "form-control " + (state.nameError ? "is-invalid" : "")
              }
              type="text"
              placeholder="Memorable name"
              defaultValue={isNew ? "CCB Integration" : integrationModel.name}
              disabled={isLoading}
            />
          </div>*/}

            <div className="form-group col-xs-12 col-md-6">
              <h4>Integration settings</h4>

              <label>
                API Username <span className="text-danger">*</span>
              </label>

              <input
                id="integration_username"
                className={
                  "form-control " + (state.usernameError ? "is-invalid" : "")
                }
                type="text"
                placeholder="API Username"
                defaultValue={isNew ? "" : integrationModel.username}
                disabled={!isNew || isLoading}
              />
            </div>

            <div className="form-group col-xs-12 col-md-6">
              <label>
                API Password<span className="text-danger">*</span>
              </label>

              <input
                className={
                  "form-control " + (state.passwordError ? "is-invalid" : "")
                }
                id="integration_password"
                type="password"
                placeholder="API User Password"
                defaultValue={isNew ? "" : integrationModel.password}
                disabled={isLoading}
              />
            </div>

            <div className="form-group col-xs-12 col-md-12">
              <label>
                API Server Address <span className="text-danger">*</span>
              </label>

              <div className="integration-address-container">
                <span className="integration-address-static">
                  {address.scheme}
                </span>
                <input
                  className={
                    "form-control integration-address-input " +
                    (state.addressError ? "is-invalid" : "")
                  }
                  id="integration_address"
                  type="text"
                  placeholder="Subdomain"
                  defaultValue={isNew ? "" : address.subdomain}
                  disabled={!isNew || isLoading}
                />
                <span className="integration-address-static">
                  {address.host}
                </span>
              </div>
            </div>

            <Scopes
              definitions={CCBScopes}
              scopes={scopes}
              idPrefix={"ccb-scope-"}
              integrationModel={integrationModel}
              isLoading={isLoading}
            />

            <div className={"col-xs-12 col-md-12"}>
              <div className="m-t-20 text-center">
                <div className="form-group">
                  {newSyncAlert}
                  <button
                    className="btn btn-primary btn-lg"
                    onClick={onSubmit}
                    disabled={isLoading}
                  >
                    {submitButtonLabel}
                  </button>
                </div>
              </div>
            </div>
            <div className={"col-xs-12"}>
              {apiAccessCheckWarning}

              <ApiError
                error={updateError}
                defaultErrorMessage={"Could not add Api User. Please try again"}
              />
              {loadingIndicator}

              <FormError
                errors={[
                  /*state.nameError,*/
                  state.usernameError,
                  state.passwordError,
                  state.addressError,
                ]}
              />
            </div>
          </form>
        </div>
      </div>
      {openAIForm}
    </React.Fragment>
  );
};

export default CCBIntegrationForm;
