/**
 * Created by piotr.pozniak@thebeaverhead.com on 06/01/2019.
 */

import React, { useRef, useState, useEffect, useMemo } from "react";

import moment from "moment";
import PropTypes from "prop-types";
import classnames from "classnames";
import EventsCounter from "./EventsCounter";
import DetailedEventsCounter from "./DetailedEventsCounter";
import { formatEventTime } from "./EventNameWithTime";
import { PT } from "../../../../consts";
import { useAppStore } from "../../../../hooks/redux/app";
import classNames from "classnames";

const CalendarDay = (props) => {
  const [state, setState] = useState({
    selectedDay: moment(),
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const height = containerRef.clientHeight;
    const width = containerRef.clientWidth;

    setState({ ...state, width, height });
  }, []);

  /**
   *
   * @param e
   * @returns {boolean}
   */
  const onClickMore = (event) => (e) => {
    e.preventDefault();
    e.stopPropagation();

    props.onEventClick(event);

    return false;
  };

  const containerRef = useRef("");

  const {
    calendar,
    events,
    day,
    showRecurring,
    showFeatured,
    isEventsLimited,
    limitedNumberOfEvents,
  } = props;
  const { app } = useAppStore();

  const isMobilePreview = app.previewType === PT.mobile;

  let groupedDayEvents = null;

  const dayEvents = events.collection
    .filter((e) => {
      return day.isBetween(
        moment(e.start_time * 1000) /*.utc()*/,
        moment(e.end_time * 1000) /*.utc()*/,
        "day",
        "[]"
      );
    })
    .sort((a, b) => {
      if (a.allDay) {
        return -1;
      }
      return 0;
    });

  if (dayEvents.length && !props.showPills) {
    groupedDayEvents =
      dayEvents.length > 5 ? (
        <span className="event-dot">
          <i data-et={dayEvents[0].name} title={dayEvents[0].name} />
          <b>+ {dayEvents.length - 1} more</b>
        </span>
      ) : (
        <span className="event-dot">
          {dayEvents.map((evnt) => {
            const eventTitle = formatEventTime(evnt);
            return (
              <i
                key={"ded-" + evnt.id}
                data-et={eventTitle}
                title={eventTitle}
              />
            );
          })}
        </span>
      );
  }

  const dayEventsCounter = props.showEventsCountTooltip ? (
    <EventsCounter eventsCount={dayEvents.length} />
  ) : null;

  const dayEventsCounterDetailed = props.showFullNamesTooltip ? (
    <DetailedEventsCounter
      events={dayEvents}
      isLeft={[5, 6].indexOf(day.format("d") * 1) >= 0}
      parentContainerWidth={state.width}
      parentContainerHeight={state.height}
      useEventAccent={props.useEventAccent}
    />
  ) : null;

  const label = (
    <span className="day-label" id={"mc-day-label-" + day.format("D")}>
      {day.format("D")}
    </span>
  );

  const classes = classnames("day", {
    "current-day": moment() /*.utcOffset(0, true)*/
      .isSame(day, "date"),
    selected: props.selectedDay.isSame(day, "day"),
    "has-pills": props.showPills,
  });

  let eventsListPills = null;

  const daysEventPills = useMemo(
    () =>
      isEventsLimited ? dayEvents.slice(0, limitedNumberOfEvents) : dayEvents,
    [dayEvents, isEventsLimited, limitedNumberOfEvents]
  );

  const eventPillClasses = classNames({
    "event-pill": true,
    "mobile-preview-event-pill": isMobilePreview,
  });

  if (props.showPills && daysEventPills.length) {
    eventsListPills =
      dayEvents.length > limitedNumberOfEvents ? (
        <React.Fragment>
          <span className={eventPillClasses}>
            {daysEventPills.map((i) => {
              const inlineStyles = {};

              if (props.useEventAccent && i.accentColor) {
                inlineStyles.backgroundColor = i.accentColor;
                inlineStyles.color = i.fontColor;
              }

              const recurringBadge =
                showRecurring && i.recurrence_description ? (
                  <i className={"material-icons dce--event-badge-recurring"}>
                    autorenew
                  </i>
                ) : null;
              const featuredBadge =
                showFeatured && i.is_featured ? (
                  <i className={"material-icons dce--event-badge-featured"}>
                    star
                  </i>
                ) : null;

              const eventTitle = formatEventTime(i);

              return (
                <span
                  key={i.id}
                  data-et={eventTitle}
                  title={eventTitle}
                  onClick={onClickMore(i)}
                  style={inlineStyles}
                >
                  {featuredBadge}
                  {recurringBadge}
                  {eventTitle}
                </span>
              );
            })}
            {dayEvents.length - limitedNumberOfEvents > 0 &&
              isEventsLimited && (
                <b
                  className={classnames({
                    extendable: props.pillOpensEventDetails,
                  })}
                >
                  +&nbsp;{dayEvents.length - limitedNumberOfEvents} More
                </b>
              )}
          </span>
        </React.Fragment>
      ) : (
        <span className="event-pill">
          {dayEvents.map((i) => {
            const inlineStyles = {};

            if (props.useEventAccent && i.accentColor) {
              inlineStyles.backgroundColor = i.accentColor;
            }

            const eventTitle = formatEventTime(i);
            return (
              <span
                key={i.id}
                data-et={eventTitle}
                title={eventTitle}
                onClick={onClickMore(i)}
                style={inlineStyles}
              >
                {eventTitle}
              </span>
            );
          })}
        </span>
      );
  }
  return (
    <td className={classes} onClick={props.onSelectDay(day)} ref={containerRef}>
      {label}
      {groupedDayEvents}

      {dayEventsCounter}
      {eventsListPills}
      {dayEventsCounterDetailed}
    </td>
  );
};

CalendarDay.propTypes = {
  day: PropTypes.object.isRequired,
  events: PropTypes.object,
  selectedDay: PropTypes.object,
  onSelectDay: PropTypes.func.isRequired,
  onEventClick: PropTypes.func,
  showPills: PropTypes.bool,
  isCompact: PropTypes.bool,
  showEventsCountTooltip: PropTypes.bool,
  showFullNamesTooltip: PropTypes.bool,
  pillOpensEventDetails: PropTypes.bool,
  useEventAccent: PropTypes.bool,
  showRecurring: PropTypes.bool,
  showFeatured: PropTypes.bool,
};

export default CalendarDay;
