/**
 * Created by piotr.pozniak@thebeaverhead.com on 12/03/2024
 */

import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import ExternalLink from "./ExternalLink";
import appConfig from "../../../../../appConfig";
import InternalLink from "./internalLink";
import { useAppStore } from "../../../../../hooks/redux/app";
import { useUserStore } from "../../../../../hooks/redux/user";
import { useCurrentOrganizationStore } from "../../../../../hooks/redux/currentOrganization";
import { Link } from "react-router-dom";
import FeatureFlag from "../../../component/FeatureFlag";

const ExternalLinks = (props) => {
  const { app, toggleAdminNavigation, shutdownIntercom, initializeIntercom } =
    useAppStore();
  const { user } = useUserStore();
  const { currentOrganization } = useCurrentOrganizationStore();

  const [state, setState] = useState({
    helpChatOpen: false,
  });

  /**
   *
   * @param e
   * @returns {boolean}
   */
  const toggleChat = useCallback(
    (e) => {
      e.preventDefault();

      if (state.helpChatOpen) {
        shutdownIntercom();
        setState({ ...state, helpChatOpen: false });
        return false;
      }

      initializeIntercom(user, currentOrganization, false);

      if (window.Intercom) {
        window.Intercom("onShow", () => {
          setState({ ...state, helpChatOpen: true });
        });

        setTimeout(() => window.Intercom("show"), 500);
      }

      return false;
    },
    [state]
  );

  /**
   *
   * @type {(function(*): void)|*}
   */
  const onToggleAdminNavClick = useCallback(
    (e) => {
      e.preventDefault();
      toggleAdminNavigation(!app.adminNavOpen);
    },
    [app.adminNavOpen]
  );

  return (
    <div className={"nav-user-menu--dropdown--external-links"}>
      {user?.model?.role === "admins" ? (
        <InternalLink
          onClick={onToggleAdminNavClick}
          icon={"admin_panel_settings"}
          label={"Toggle admin menu"}
          className={"text-warning"}
        />
      ) : null}
      <InternalLink
        onClick={toggleChat}
        icon={"contact_support"}
        label={"Toggle help Chat"}
        className={"text-info"}
      />
      <ExternalLink
        url={`https://help.${appConfig.domain}/en/`}
        icon={"help"}
        label={"Help articles"}
      />
      {appConfig.branding === "dc" ? (
        <ExternalLink
          url={`https://feedback.${appConfig.domain}`}
          icon={"post_add"}
          label={"Feature request"}
        />
      ) : null}
      {appConfig.facebookGroup ? (
        <ExternalLink
          url={appConfig.facebookGroup}
          icon={"facebook"}
          label={"Facebook group"}
        />
      ) : null}
      <FeatureFlag name={"showAffiliates"}>
        <Link to={`/affiliate#share-earn`} alt={"Affiliates"}>
          <i className={"material-icons icon-sm"}>handshake</i>
          Affiliates
        </Link>
      </FeatureFlag>
    </div>
  );
};

ExternalLinks.defaultProps = {};

ExternalLinks.propTypes = {};

export default ExternalLinks;
