/**
 * Created by piotr.pozniak@thebeaverhead.com on 22/02/2024
 */

import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { useUserStore } from "../../../../../hooks/redux/user";
import { useCurrentOrganizationStore } from "../../../../../hooks/redux/currentOrganization";
import { useAppStore } from "../../../../../hooks/redux/app";
import UserName from "./UserName";
import Dropdown from "./Dropdown";

const UserMenu = (props) => {
  const [state, setState] = useState({
    helpChatOpen: false,
  });

  const [showDropdownOptions, setShowDropdownOptions] = useState(false);

  const { user } = useUserStore();
  const { currentOrganization } = useCurrentOrganizationStore();
  const { app, toggleAdminNavigation, initializeIntercom, shutdownIntercom } =
    useAppStore();

  /**
   *
   * @param e
   * @returns {boolean}
   */
  const onHelpChatClick = (e) => {
    e.preventDefault();

    if (state.helpChatOpen) {
      shutdownIntercom();
      setState({ ...state, helpChatOpen: false });
      return false;
    }

    initializeIntercom(user, currentOrganization, false);
    window.Intercom("onShow", () => {
      setState({ ...state, helpChatOpen: true });
    });

    setTimeout(() => window.Intercom("show"), 500);

    return false;
  };

  /**
   *
   * @type {(function(*): void)|*}
   */
  const onToggleAdminNavClick = useCallback(
    (e) => {
      toggleAdminNavigation(!app.adminNavOpen);
    },
    [app.adminNavOpen]
  );

  /**
   *
   * @param e
   */
  const onDropdownClick = (e) => {
    e.preventDefault();
    setShowDropdownOptions(!showDropdownOptions);
  };

  const onCloseClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setShowDropdownOptions(false);
    return false;
  };

  return (
    <div className="nav-user-menu pull-right">
      <UserName onClick={onDropdownClick} />

      <Dropdown show={showDropdownOptions} onClose={onCloseClick} />
    </div>
  );
};

UserMenu.defaultProps = {};

UserMenu.propTypes = {};

export default UserMenu;
