/**
 * Created by piotr.pozniak@thebeaverhead.com on 13/04/2022.
 */

import React from "react";

const LoadingIndicator = () => {
  return (
    <div className="loader">
      <div className="progress">
        <div className="indeterminate"></div>
      </div>
    </div>
  );
};

export default LoadingIndicator;
