/**
 * Created by piotr.pozniak@thebeaverhead.com on 17/08/2022
 */

import React from "react";
import PropTypes from "prop-types";
import { ucFirst } from "../../../../helpers/string";
import { Link } from "react-router-dom";
import ExpandableAlert from "../../component/ExpandableAlert";

const PermissionsAlert = (props) => {
  const scopesWithIssue = props.integration.integration_scopes.filter(
    (i) => i.available && !i.has_permissions
  );

  const scopesList = scopesWithIssue.map((i) => (
    <strong
      key={`${props.integration.uuid}-se-${i.name}`}
      className={"alert-permission-scope"}
    >
      {ucFirst(i.name)}
    </strong>
  ));

  return (
    <ExpandableAlert
      errorLevel={"warning"}
      header={
        <span>
          Your {props.integration.name} sync is paused due to permissions issue
          in scope
          {scopesList.length > 1 ? "s" : ""} {scopesList}.
        </span>
      }
      body={
        <React.Fragment>
          <span>{props.integration.last_error_message}</span>
          <br />
          <span>
            Ensure you have granted all necessary permissions or update{" "}
            <Link to={`/integration/${props.integration.uuid}`}>
              integration scopes
            </Link>
            .
          </span>
        </React.Fragment>
      }
      icon={"warning"}
    />
  );
};

PermissionsAlert.defaultProps = {};

PermissionsAlert.propTypes = {
  integration: PropTypes.object,
};

export default PermissionsAlert;
