/**
 * Created by piotr.pozniak@thebeaverhead.com on 23/10/2018.
 */

import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useContext,
} from "react";
import PropTypes from "prop-types";
import { WT } from "../../consts/widget";
import { useHistory } from "react-router-dom";
import PageTemplate from "./../PageTemplate";
import WidgetPreview from "../../component/WidgetSettings/WidgetPreview";
import SetupTab from "./SetupTab";
import DesignTab from "../../component/WidgetSettings/DesignTab";
import WidgetPreviewHeader from "../../component/WidgetSettings/WidgetPreviewHeader";
import WidgetName from "../../component/WidgetSettings/WidgetName";
import LoadingIndicator from "../../../calendar/components/LoadingIndicator";
import IntegrationIssueNotification from "../../component/IntegrationIssueNotification";
import { useRouteMatch } from "react-router-dom";

import { store } from "../../store";
import { updateJquery } from "../../tools";
import { useBuilderStore } from "../../../../hooks/redux/builder";
import { useUserStore } from "../../../../hooks/redux/user";
import { useAppStore } from "../../../../hooks/redux/app";
import { useDomainsStore } from "../../../../hooks/redux/domains";
import { useIntegrationsStore } from "../../../../hooks/redux/integrations";
// import NoEventsAlert from "./CalendarPage/NoEventsAlert";
import { useWidgetStore } from "../../../../hooks/redux/widget";
import { useWidgetsStore } from "../../../../hooks/redux/widgets";
import WidgetContext from "../../contexts/WidgetContext";
import IntegrationContext from "../../../../contexts/IntegrationContext";
import InstallTab from "./InstallTab";
import NoEventsAlert from "../../pages/CalendarPage/NoEventsAlert";
import useSetupChangesTrackerHook from "../../hooks/useSetupChangesTrackerHook";
import widgetSettingsContext from "../../../../contexts/WidgetSettingsContext";

window.__DCE = window.__DCE || {};

const PageTabs = [
  {
    label: "Setup",
    id: "setup_tab",
    additionalClassName: "active show",
  },
  {
    label: "Design",
    id: "design_tab",
    additionalClassName: "",
  },
  {
    label: "Install",
    id: "install_tab",
    additionalClassName: "",
  },
];

const WidgetPageTemplate = (props) => {
  const match = useRouteMatch();
  const widgetContext = useContext(WidgetContext);

  const {
    widgetFeaturesCheck,
    getTheLowestPlanMeetingWidgetCriteria,
    widgetSettingsValidator,
    getShareableUrl,
    integrationStrategySelector,
    widgetDisplayName,
    indexPageUrl,
  } = widgetContext;
  const uuid = match.params.uuid;

  const { widgetType, widgetTypeIcon } = props;
  const { app } = useAppStore();

  const { user } = useUserStore();
  const { widget, setWidget, updateWidget, fetchWidget, deleteWidget } =
    useWidgetStore();
  const { widgetItems } = useWidgetStore();
  const { widgets } = useWidgetsStore();
  const { clearErrors } = useAppStore();
  const { domains } = useDomainsStore();
  const { integrations } = useIntegrationsStore();

  const { overwriteDesignProp, overwriteFilterProp } = useBuilderStore();

  const history = useHistory();
  const [state, setState] = useState({
    widgetModel: null,
    errors: {},
  });

  const { trackChanges, setOriginValues, hasChanges } =
    useSetupChangesTrackerHook();

  const [savePointEventsSettings, setSavePointEventsSettings] = useState(null);

  const isWidgetInitialized = useMemo(() => {
    return !!(
      widget.model &&
      widget.model.slug &&
      widget.model.integration &&
      state.widgetModel &&
      state.widgetModel.integration_uuid
    );
  }, [integrations.fetchSuccess, widget.model, state.widgetModel]);

  const integrationStrategy = useMemo(
    () =>
      integrationStrategySelector(
        isWidgetInitialized ? widget.model.integration.type : null,
        widget.model
      ),
    [isWidgetInitialized, widget.model]
  );

  useEffect(() => {
    (async () => {
      await fetchWidget(widgetType, match.params.uuid);
    })();

    return () => {
      setWidget(null);
    };
  }, []);

  useEffect(() => {
    if (widget.model) {
      setState({
        ...state,
        widgetModel: {
          ...widget.model,
          integration_uuid: widget.model.integration.uuid,
        },
      });
      setOriginValues({ ...widget.model });
    }
  }, [widget.model]);

  useEffect(() => {
    if (widget.fetchSuccess) {
      if (widget.model && !widget.model.integration) {
        //history.push("/dashboard");
      }
    }

    if (widgets.fetchSuccess) {
      setTimeout(updateJquery, 200);
    }
  }, [widget.fetchSuccess, widgetItems.fetchSuccess]);

  const lowestPlanRecommended = useMemo(() => {
    return state.widgetModel && state.widgetModel.integration
      ? getTheLowestPlanMeetingWidgetCriteria(state.widgetModel, user, widgets)
      : null;
  }, [state.widgetModel, user, widgets]);

  useEffect(() => {
    if (widget.updateSuccess) {
      setSavePointEventsSettings(
        Object.assign({}, widget.model.widget_settings)
      );

      setTimeout(clearErrors, 4500);
      setOriginValues(widget.model);
    }
  }, [widget.updateSuccess]);

  /**
   * track changes if design settings were updated
   */
  useEffect(() => {
    if (isWidgetInitialized && app.widgetSettingsUpdateTimestamp) {
      trackChanges("widget_settings", widget.model.widget_settings);
    }
  }, [app.widgetSettingsUpdateTimestamp, isWidgetInitialized]);

  useEffect(() => {
    if (isWidgetInitialized) {
      setSavePointEventsSettings(
        Object.assign({}, widget.model.widget_settings)
      );
    }
  }, [isWidgetInitialized]);

  /**
   *
   * @param fieldName
   * @param value
   */
  const onUpdateField = (fieldName, value) => {
    const updatedModel = {
      ...state.widgetModel,
    };

    trackChanges(fieldName, value);
    updatedModel[fieldName] = value;

    setState({
      widgetModel: updatedModel,
      errors: widgetSettingsValidator(updatedModel),
    });
  };

  /**
   * @param {string} fieldName
   * @param {string|array|object} value
   * @type {(function(*, *))|*}
   */
  const onUpdateFilterSettings = useCallback(
    (field, value) => {
      const updatedModel = {
        ...state.widgetModel,
      };
      updatedModel.filter_settings = {
        ...(updatedModel.filter_settings || {}),
      };
      updatedModel.filter_settings[field] = value;
      trackChanges("filter_settings", updatedModel.filter_settings);

      setState({
        widgetModel: updatedModel,
        errors: widgetSettingsValidator(updatedModel),
      });
    },
    [state.widgetModel]
  );

  /**
   *
   * @param fieldName
   * @param value
   */
  const onUpdateStatus = useCallback(
    (value) => {
      const updatedModel = {
        ...state.widgetModel,
      };

      updatedModel.available = value;

      const isConfigValid = validateSettings(updatedModel);
      const hasValidSettings = widgetSettingsValidator(updatedModel);

      setState({ ...state, errors: hasValidSettings });

      if (!isConfigValid || Object.keys(hasValidSettings).length) {
        return false;
      }

      setState({
        ...state,
        widgetModel: updatedModel,
      });

      _onSettingsSubmit(updatedModel);
    },
    [state.widgetModel]
  );

  /**
   * @param {string} fieldName
   * @param {string|array|object} value
   * @type {(function(*, *))|*}
   */
  const onUpdateWidgetSettings = useCallback(
    (field, value) => {
      const updatedModel = {
        ...state.widgetModel,
      };
      updatedModel.widget_settings = {
        ...(updatedModel.widget_settings || {}),
      };

      trackChanges(field, value);

      updatedModel.widget_settings[field] = value;

      setState({
        widgetModel: updatedModel,
        errors: widgetSettingsValidator(updatedModel),
      });
    },
    [state.widgetModel]
  );

  /**
   *
   */
  const onSettingsSubmit = async () => {
    const data = { ...state.widgetModel };
    const hasValidSettings = widgetSettingsValidator(data);

    setState({ ...state, errors: hasValidSettings });

    if (Object.keys(hasValidSettings).length) {
      return false;
    }
    _onSettingsSubmit(data);
  };

  /**
   *
   */
  const _onSettingsSubmit = async (data) => {
    // check if integration has changed, if so, remove widget filters;
    if (widget.model.integration.uuid !== data.integration_uuid) {
      data.filter_settings = null;
    }

    data.widget_settings = { ...widget.model.widget_settings };
    data.widget_settings.sorting = state.widgetModel.widget_settings.sorting;
    delete data.builder;
    const isConfigValid = validateSettings(data);

    if (!isConfigValid) {
      return;
    }

    await updateWidget(uuid, data);
  };

  /**
   *
   * @param data
   * @returns {boolean}
   */
  const validateSettings = (data) => {
    const issues = widgetFeaturesCheck(data, user, widgets);

    if (issues.length) {
      setState({
        ...state,
        errors: issues,
      });
      return false;
    }

    return true;
  };

  /**
   *
   */
  const onDeleteClick = async (e) => {
    e.preventDefault();

    if (widget.fetch || widget.delete) {
      return false;
    }

    await deleteWidget(uuid);

    if (store.getState().widget.deleteSuccess) {
      history.push(indexPageUrl);
    }

    return false;
  };

  /**
   *
   */
  const onSettingsRestore = () => {
    if (
      window.confirm(
        "This will remove all the changes you have done since the last SAVE. Are you sure?"
      )
    ) {
      overwriteDesignProp(savePointEventsSettings.design);
    }
  };

  const loading =
    widget.update ||
    widget.fetch ||
    widget.delete ||
    !widget.model ||
    !widget.model.template ||
    !isWidgetInitialized
      ? true
      : false;

  const settingsTabsOptions = isWidgetInitialized ? PageTabs : [];

  const settingsTabs = settingsTabsOptions.map((item, idx) => {
    return (
      <li key={"settings-tab-" + idx} className="nav-item">
        <a
          className={"nav-link " + item.additionalClassName}
          href={"#" + item.id}
          data-toggle="tab"
        >
          {item.label}
        </a>
      </li>
    );
  });

  const designTab = isWidgetInitialized ? (
    <DesignTab
      disabled={loading}
      onSubmit={onSettingsSubmit}
      onRestore={onSettingsRestore}
      widget={widget}
      hasSetupChanged={hasChanges}
    />
  ) : null;

  const setupTab = isWidgetInitialized ? (
    <SetupTab
      integrations={integrations.collection}
      disabled={loading}
      onSubmit={onSettingsSubmit}
      isNew={false}
      overwriteDesignProp={overwriteDesignProp}
      overwriteFilterProp={overwriteFilterProp}
      user={user}
      widgetModel={state.widgetModel || {}}
      errors={state.errors}
      onUpdateField={onUpdateField}
      onUpdateFilterSettings={onUpdateFilterSettings}
      onUpdateWidgetSettings={onUpdateWidgetSettings}
      hasSetupChanged={hasChanges}
      additionalComponents={widgetSettingsContext.setupTabComponents}
    />
  ) : null;

  const installTab = isWidgetInitialized ? (
    <InstallTab
      widget={widget}
      disabled={loading}
      onSubmit={onSettingsSubmit}
      onUpdateStatus={onUpdateStatus}
      isNew={false}
      hasDomains={domains.collection.length ? true : false}
      widgetModel={state.widgetModel || {}}
      errors={state.errors}
      onUpdateField={onUpdateField}
      recommendedPlan={lowestPlanRecommended}
      widgetTypeName={widgetDisplayName}
      shareableUrl={getShareableUrl(widget.model.slug)}
    />
  ) : null;

  const widgetSidebar = (
    <div
      className="col-lg-3 message-view chat-profile-view chat-sidebar"
      id="chat_sidebar"
    >
      <div className="chat-window video-window">
        <div className="fixed-header">
          <ul className="nav nav-tabs nav-tabs-bottom">{settingsTabs}</ul>
        </div>

        <div className="tab-content chat-contents">
          {setupTab}

          {designTab}

          {installTab}
        </div>
      </div>
    </div>
  );

  const widgetName = isWidgetInitialized ? widget.model.name : "Loading...";

  const integrationIssuesInfo = isWidgetInitialized ? (
    <IntegrationIssueNotification
      integration_uuid={widget.model.integration.uuid}
    />
  ) : null;

  const noEventsToDisplayInfo =
    !integrationIssuesInfo &&
    isWidgetInitialized &&
    widgetItems.fetchSuccess &&
    !widgetItems.collection.length ? (
      <NoEventsAlert widget={widget} />
    ) : null;

  const widgetPreview = isWidgetInitialized ? (
    <WidgetPreview
      key={widget.model.integration.type || new Date()}
      uuid={uuid}
      widget={widget}
      widgetKind={"calendars"}
    >
      {props.children}
    </WidgetPreview>
  ) : (
    <LoadingIndicator />
  );

  return (
    <PageTemplate
      title={
        isWidgetInitialized ? (
          <WidgetName
            widgetName={widgetName}
            widget={widget.model}
            isLoading={loading ? true : false}
            widgetFeaturesCheck={widgetFeaturesCheck}
          />
        ) : (
          widgetName
        )
      }
      sidebarId={widgetType}
      icon={widgetTypeIcon}
      className={`page-wrapper-widget`}
      headerControls={[
        <WidgetPreviewHeader key={"cphc"} onDelete={onDeleteClick} />,
      ]}
    >
      <IntegrationContext.Provider value={integrationStrategy}>
        <div>
          <div className="chat-main-row">
            <div className="chat-main-wrapper">
              <div className="col-lg-9 message-view task-view">
                <div className="chat-window">{widgetPreview}</div>
              </div>

              {widgetSidebar}
            </div>
          </div>
        </div>

        <div className="sidebar-overlay" data-reff="#chat_sidebar" />
      </IntegrationContext.Provider>
    </PageTemplate>
  );
};

WidgetPageTemplate.propTypes = {
  widgetType: PropTypes.oneOf(Object.values(WT)).isRequired,
  widgetTypeIcon: PropTypes.string.isRequired,
  // integrationContextProvider
  // no events alert component NoEventsAlert
};

export default WidgetPageTemplate;
