/**
 * Created by piotr.pozniak@thebeaverhead.com on 02/02/2024
 */

import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as payoutActions from "../../actions/payout";
import {
  createPayout,
  deletePayout,
  markVendorPayoutAsPaid,
} from "../../actions/payout";

export function usePayoutStore() {
  const dispatch = useDispatch();
  const _payout = useSelector((store) => store.payout);

  const fetchPayoutData = useCallback(
    async () => await dispatch(payoutActions.fetchPayoutData()),
    [dispatch]
  );

  const createPayout = useCallback(
    async (data) => await dispatch(payoutActions.createPayout(data)),
    [dispatch]
  );

  const markVendorPayoutAsPaid = useCallback(
    async (id, data) =>
      await dispatch(payoutActions.markVendorPayoutAsPaid(id, data)),
    [dispatch]
  );

  const fetchPayout = useCallback(
    async (id) => await dispatch(payoutActions.fetchPayout(id)),
    [dispatch]
  );

  const deletePayout = useCallback(
    async (id) => await dispatch(payoutActions.deletePayout(id)),
    [dispatch]
  );

  return {
    payout: _payout,
    fetchPayout,
    fetchPayoutData,
    createPayout,
    deletePayout,
    markVendorPayoutAsPaid,
  };
}
