/**
 * Created by piotr.pozniak@thebeaverhead.com on 05/04/2022.
 */

import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as alertsActions from "../../actions/alerts";

export function useAlertsStore() {
  const dispatch = useDispatch();
  const _alerts = useSelector((store) => store.alerts);

  const fetchAlerts = useCallback(
    async () => await dispatch(alertsActions.fetchAlerts()),
    [dispatch]
  );

  return {
    alerts: _alerts,
    fetchAlerts,
  };
}
