/**
 * Created by piotr.pozniak@thebeaverhead.com on 22/02/2019.
 */

import axios from 'axios';
import AxiosConfig from '../AxiosConfig';


/**
 *
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export function fetchImports(page = 1, limit = 5) {
  return function(dispatch) {

    dispatch({type: "IMPORTS_FETCH", payload: {page, limit}});

    const url = '/api/imports.json?' + AxiosConfig.objectToURLQuery({page, limit});

    return axios.get(
      url,
      AxiosConfig.getAuthConfig()
    )
      .then((response) => {

        dispatch({
          type: "IMPORTS_FETCH_FULFILLED",
          payload: response.data
        });

      })
      .catch(function(error) {

        dispatch({
          type: "IMPORTS_FETCH_REJECTED",
          payload: error,
        });

      });
  }
}
