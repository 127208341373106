/**
 * Created by piotr.pozniak@thebeaverhead.com on 13/12/2018.
 */

import React from "react";

import PropTypes from "prop-types";
import Accordion from "./Acordion";

export const InstallExpandableTab = (props) => {
  /**
   *
   * @param e
   */
  const onTabClick = (e) => {
    e.preventDefault();
    return false;
  };

  const title = (
    <div className={"d-flex align-items-center"}>
      <i className={"material-icons"} aria-hidden="true">
        {props.icon}
      </i>
      {props.title}
    </div>
  );

  return (
    <Accordion title={title} subtitle={props.subheader}>
      {props.children}
    </Accordion>
  );
};

InstallExpandableTab.defaultProps = {
  show: true,
  subheader: null,
};

InstallExpandableTab.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  show: PropTypes.bool,
  subheader: PropTypes.object,
};

export default InstallExpandableTab;
