/**
 * Created by piotr.pozniak@thebeaverhead.com on 12/03/2024
 */

import React from "react";
import PropTypes from "prop-types";
import appConfig from "../../../../../appConfig";

const ExternalLink = ({ url, icon, label }) => {
  return (
    <a href={url} target={`_blank_${label}`}>
      <i className={"material-icons icon-sm"} aria-hidden={true}>
        {icon}
      </i>
      {label}
    </a>
  );
};

ExternalLink.defaultProps = {};

ExternalLink.propTypes = {
  url: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default ExternalLink;
