/**
 * Created by piotr.pozniak@thebeaverhead.com on 20/03/2023
 */

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import PropTypes from "prop-types";
import { useDomainsStore } from "../../../../../hooks/redux/domains";
import { useBuilderStore } from "../../../../../hooks/redux/builder";
import { getAllOptionValues } from "../../../../../builder";
import { useAlertStore } from "../../../../../hooks/redux/alert";
import { useDebounce } from "../../../../../hooks/useDebounce";
import DesignTooltip from "../../../component/Builder/Input/DesignTooltip";
import Accordion from "../../../component/WidgetSettings/Acordion";
import appConfig from "../../../../../appConfig";

const ShowOnOptions = [
  {
    label: "All pages",
    value: "*",
  },
  {
    label: "Home page",
    value: "/",
  },
  {
    label: "Specific page",
    value: "custom",
  },
];

const PageSetup = (props) => {
  const { domains } = useDomainsStore();
  const { alert } = useAlertStore();

  const widgetSettings = getAllOptionValues(alert.model, "behavior");

  const pageToDisplay = widgetSettings["dcAPageToDisplay"] || null;
  const pageToShowOnOption = widgetSettings["dcAPageToShowOnOption"] || null;
  const { updateDesignProp } = useBuilderStore();
  const [pageToShowOn, setPageToShowOn] = useState(
    widgetSettings["dcAPageToShowOn"]
  );
  const debouncedPageToShowOn = useDebounce(pageToShowOn, 1000);

  const onPageToShowOnChange = useCallback(
    (e) => {
      setPageToShowOn(e.target.value);
    },
    [pageToShowOn]
  );

  useEffect(() => {
    const optionValues = [
      {
        field: "dcAPageToShowOn",
        value: debouncedPageToShowOn,
      },
    ];

    updateDesignProp(optionValues, "behavior");
  }, [debouncedPageToShowOn]);

  /**
   *
   * @type {(function(*, *): void)|*}
   */
  const onUpdateSettings = useCallback(
    (key) => (e) => {
      const optionValues = [
        {
          field: key,
          value: e.target.value,
        },
      ];

      updateDesignProp(optionValues, "behavior");

      /**
       * const onUpdateDesignProp = (option) => (value) => {
       *     let updatedValue = value;
       *
       *     if (option.control.options.updateValue) {
       *       updatedValue = option.control.options.updateValue(updatedValue);
       *     }
       *     updateDesignProp(updatedValue);
       *     setTimeout(window.updateJquery, 200);
       *   };
       */
    },
    [widgetSettings]
  );

  const domainSelectOptions = useMemo(
    () =>
      domains.collection.map((domain, idx) => (
        <option value={domain.domain} key={`${domain.domain}-${idx}`}>
          {domain.domain}
        </option>
      )),
    [domains.collection]
  );

  const showOnSelectOptions = useMemo(
    () =>
      ShowOnOptions.map((option, idx) => (
        <option value={option.value} key={`${option.value}-${idx}`}>
          {option.label}
        </option>
      )),
    []
  );

  const specificPageInput = useMemo(() => {
    if (pageToShowOnOption === "custom") {
      return (
        <div className="col-md-xs">
          <label className={"option-label"} htmlFor="alert_integration_uuid">
            <span className={"option-title"}>Page URL</span>
          </label>
          <input
            type="text"
            className={"form-control form-control-sm "}
            value={pageToShowOn}
            onChange={onPageToShowOnChange}
            disabled={props.disabled}
          />
        </div>
      );
    }
  }, [pageToDisplay, pageToShowOn, pageToShowOnOption]);

  const displayPreview = useMemo(() => {
    if (pageToShowOnOption === "*") {
      return `on every page at ${pageToDisplay}`;
    } else if (pageToShowOnOption === "/") {
      return `on the main page at ${pageToDisplay}`;
    } else {
      return `at ${pageToDisplay}/${pageToShowOn}`;
    }
  }, [pageToDisplay, pageToShowOnOption, pageToShowOn]);

  const summary = useMemo(() => {
    return pageToDisplay && pageToDisplay.length > 1 ? (
      <div className={"col-md-xs"}>
        <p className={"text-break-word m-0"}>
          Your Event Alert will be displayed{" "}
          <strong className={"font-weight-bold"}>{displayPreview}</strong>.
        </p>
      </div>
    ) : null;
  }, [pageToDisplay, displayPreview]);

  return (
    <Accordion title={"Display on"} subtitle={summary} defaultExpanded={true}>
      <form action="#">
        <div className="form-group">
          <div className="col-md-xs design-element">
            <label className={"option-label"} htmlFor="alert_integration_uuid">
              <span className={"option-title"}>Website</span>
              <DesignTooltip
                help={{
                  url: `${appConfig.helpdeskAddress}/en/articles/7191669-event-alerts-banner`,
                  message: "On which website the Event Alert should display.",
                }}
              />
            </label>
            <select
              className={"form-control form-control-sm "}
              value={pageToDisplay}
              id="alert_integration_uuid"
              disabled={props.disabled}
              onChange={onUpdateSettings("dcAPageToDisplay")}
            >
              <option value={0}>Select website...</option>
              {domainSelectOptions}
            </select>
          </div>
          <div className="col-md-xs design-element">
            <label className={"option-label"} htmlFor="alert_integration_uuid">
              <span className={"option-title"}>Show on</span>
              <DesignTooltip
                help={{
                  url: `${appConfig.helpdeskAddress}/en/articles/7191669-event-alerts-banner`,
                  message:
                    "On which website page(s) the Event Alert should display.",
                }}
              />
            </label>
            <select
              className={"form-control form-control-sm "}
              defaultValue={pageToShowOnOption}
              id="alert_integration_uuid"
              disabled={props.disabled}
              onChange={onUpdateSettings("dcAPageToShowOnOption")}
            >
              {showOnSelectOptions}
            </select>
          </div>
          {specificPageInput}
        </div>
      </form>
    </Accordion>
  );
};

PageSetup.defaultProps = {};

PageSetup.propTypes = {};

export default PageSetup;
