/**
 * Created by piotr.pozniak@thebeaverhead.com on 05/04/2022.
 */

import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as groupsActions from "../../actions/groups";

export function useGroupsStore() {
  const dispatch = useDispatch();
  const _groups = useSelector((store) => store.groups);

  const fetchGroups = useCallback(
    async () => await dispatch(groupsActions.fetchGroups()),
    [dispatch]
  );

  return {
    groups: _groups,
    fetchGroups,

  };
}
