/**
 * Created by piotr.pozniak@thebeaverhead.com on 05/04/2022
 */

import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as groupActions from "../../actions/group";

export function useGroupStore() {
  const dispatch = useDispatch();
  const _group = useSelector((store) => store.group);
  const _groupItems = useSelector((store) => store.groupItems);

  const fetchGroup = useCallback(
    async (uuid) => await dispatch(groupActions.fetchGroup(uuid)),
    [dispatch]
  );

  const fetchItems = useCallback(
    async (uuid, limit, page, filters, preservePreviousItems) =>
      await dispatch(
        groupActions.fetchItems(uuid, limit, page, filters, preservePreviousItems)
      ),
    [dispatch]
  );

  const fetchItem = useCallback(
    async (uuid, itemUUID) =>
      await dispatch(
        groupActions.fetchItem(uuid, itemUUID)
      ),
    [dispatch]
  );

  const deleteGroup = useCallback(
    async (uuid) => await dispatch(groupActions.deleteGroup(uuid)),
    [dispatch]
  );

  const updateGroup = useCallback(
    async (uuid, data) => await dispatch(groupActions.updateGroup(uuid, data)),
    [dispatch]
  );

  const setGroup = useCallback(
    async (model) => await dispatch(groupActions.setGroup(model)),
    [dispatch]
  );

  return {
    group: _group,
    groupItems: _groupItems,
    deleteGroup,
    updateGroup,
    fetchGroup,
    setGroup,
    fetchItems,
    fetchItem
  };
}
