import React from "react";

const InvitationResponseOptions = (props) => {
  const { organization, onAccept, onReject } = props;
  return (
    <div>
      <div className={"text-center m-b-30"}>
        <h4>
          You've been invited to <b>{organization.name}</b>. Click on the button
          below to join.
        </h4>
        <button
          className={"btn btn-primary d-block mx-auto account-btn mt-4 m-b-15"}
          onClick={onAccept}
        >
          Accept
        </button>
      </div>

      <div className={"text-center m-b-15"}>
        <h5 className="font-weight-normal">
          If you received this invitation by mistake, let us know by clicking
          the link below:
        </h5>
        <p
          className={"btn btn-none border-0 bg-transparent text-danger"}
          onClick={onReject}
        >
          Reject invitation
        </p>
      </div>
    </div>
  );
};

export default InvitationResponseOptions;
