/**
 * Created by piotr.pozniak@thebeaverhead.com on 13/05/2024
 */

import React, { useCallback } from "react";
import PropTypes from "prop-types";
import Accordion from "../../../component/WidgetSettings/Acordion";
import ExpandableAlert from "../../../component/ExpandableAlert";

const SEOSettings = ({ widgetModel, disabled, onFieldChange }) => {
  /**
   *
   * @type {function(*): function(*): void}
   */
  const onFormFieldChange = useCallback(
    (fieldName) => (e) => {
      onFieldChange("seo", {
        ...widgetModel.widget_settings.seo,
        [fieldName]: e.target.value,
      });
    },
    [widgetModel]
  );

  const fields = widgetModel?.widget_settings?.seo || {};

  return (
    <Accordion title={"Public title and description (SEO)"}>
      <ExpandableAlert
        errorLevel={"info"}
        header={"How does it work?"}
        body={
          <span>
            🛠️ Customize your page's appearance when shared on social media by
            setting the Title, Description, and Preview Image. These details
            help make your content stand out and attract more visitors. Simply
            fill in the fields, and watch your page shine across platforms!
          </span>
        }
      />
      <form action="#">
        <div className="form-group">
          <div className="col-xs-12">
            <label htmlFor={"title"} className={""}>
              Title
            </label>
            <input
              type="text"
              className={
                "form-control form-control-sm "
                // (props.formErrors.nameError ? "is-invalid" : "")
              }
              placeholder="Title"
              id="title"
              onChange={onFormFieldChange("title")}
              value={fields.title || ""}
              disabled={disabled}
            />
          </div>
        </div>

        <div className="form-group">
          <div className="col-xs-12">
            <label htmlFor={"description"} className={""}>
              Description
            </label>
            <textarea
              rows={5}
              className={
                "form-control form-control-sm "
                // (props.formErrors.nameError ? "is-invalid" : "")
              }
              placeholder="Description"
              id="description"
              onChange={onFormFieldChange("description")}
              value={fields.description || ""}
              disabled={disabled}
            />
          </div>
        </div>
        <div className="form-group">
          <div className="col-xs-12">
            <label htmlFor={"alternate"} className={""}>
              Alternate URL
            </label>
            <input
              type="text"
              className={
                "form-control form-control-sm "
                // (props.formErrors.nameError ? "is-invalid" : "")
              }
              placeholder="Alternate URL"
              id="alternate"
              onChange={onFormFieldChange("alternate")}
              value={fields.alternate || ""}
              disabled={disabled}
            />
          </div>
        </div>
      </form>
    </Accordion>
  );
};

SEOSettings.defaultProps = {};

SEOSettings.propTypes = {
  widgetModel: PropTypes.object,
  disabled: PropTypes.bool,
  onFieldChange: PropTypes.func.isRequired,
};

export default SEOSettings;
