/**
 * Created by piotr.pozniak@thebeaverhead.com on 30/06/2022
 */

import React, { memo } from "react";
import PropTypes from "prop-types";
import { formatAddressLines } from "../../tools";

const Address = (props) => {
  const { address } = props;

  const name = address.name ? (
    <div className={"dce--groups-card--address-header"}>{address.name}</div>
  ) : null;

  const addressLines = formatAddressLines(address);

  const addressLine = addressLines.length
    ? addressLines.map((i, idx) => {
        return (
          <div
            key={`ads-ln-${props.groupSlug}-${idx}`}
            className={"dce--groups-card--address-line"}
          >
            {i}
          </div>
        );
      })
    : null;

  return (
    <div className={"dce--groups-card--address"}>
      <div>{name}</div>
      <div>{addressLine}</div>
    </div>
  );
};

Address.defaultProps = {};

Address.propTypes = {
  address: PropTypes.shape({
    name: PropTypes.string,
    city: PropTypes.string,
    street_address: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    formatted_address: PropTypes.string,
  }),
  groupSlug: PropTypes.string,
};

export default memo(Address);
