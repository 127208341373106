/**
 * Created by piotr.pozniak@thebeaverhead.com on 14/10/2018.
 */

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const LoadingIndicator = (props) => {
  return (
    <div className={classNames("loader", props.className)}>
      <div className="progress">
        <div className="indeterminate"></div>
      </div>
    </div>
  );
};

LoadingIndicator.propTypes = {
  className: PropTypes.string
}

export default LoadingIndicator;
