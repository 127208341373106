/**
 * Created by piotr.pozniak@thebeaverhead.com on 13/04/2023
 */

import React, { useCallback, useContext, useMemo } from "react";
import PropTypes from "prop-types";
import Dropdown from "./Dropdown";
import { AvailableRulesOptionsContext, FiltersContext } from "./contexts";

const RuleEditDropdown = (props) => {
  const availableRulesOptions = useContext(AvailableRulesOptionsContext);
  const { filters, updateGroup } = useContext(FiltersContext);
  const currentRuleSettings = availableRulesOptions.find(
    (rule) => rule.field.value === props.rule.field.value
  );

  const currentOperator = useMemo(
    () =>
      currentRuleSettings.operators.find(
        (operator) => operator.value === props.rule.operator.value
      ),
    [props.rule]
  );
  const { value, label } = filters.groups[props.groupId].rules[props.id];
  const onSelectOperator = useCallback(
    (operator) => (e) => {
      const updatedRule = {
        ...props.rule,
        value,
        label,
        operator: {
          value: operator.value,
          label: operator.label,
        },
      };

      const group = { ...filters.groups[props.groupId] };
      group.rules[props.id] = updatedRule;

      updateGroup(props.groupId, group);
    },
    [props.rule]
  );

  const input = (
    <div className={"filters__edit-rule__option-input"}>
      <currentOperator.input.component
        {...currentOperator.input.props}
        groupId={props.groupId}
        filterId={props.id}
      />
    </div>
  );

  const operators = useMemo(
    () =>
      currentRuleSettings.operators.map((setting) => {
        const checked = setting.value === props.rule.operator.value;
        const optionInput = checked ? input : null;

        return (
          <div
            key={`${setting.value}`}
            className={"filters__dropdown__option filters__edit-rule__option"}
          >
            <label>
              <input
                type={"radio"}
                name={"operator"}
                value={setting.value}
                checked={checked}
                onChange={onSelectOperator(setting)}
              />
              {setting.label}
            </label>
            {optionInput}
          </div>
        );
      }),
    [props.rule]
  );

  return (
    <Dropdown onClose={props.onClose}>
      {operators}
      <div className={"filters__edit-rule__submit"} onClick={props.onClose}>
        {props.submitLabel}
      </div>
    </Dropdown>
  );
};

RuleEditDropdown.defaultProps = {
  submitLabel: "Done",
};

RuleEditDropdown.propTypes = {
  rule: PropTypes.object.isRequired,
  groupId: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  submitLabel: PropTypes.string,
};

export default RuleEditDropdown;
