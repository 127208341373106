/**
 * Created by piotr.pozniak@thebeaverhead.com on 22/02/2019.
 */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import LoadingIndicator from "../../../calendar/components/LoadingIndicator";
import ApiError from "../../component/ApiError";
import FormError from "../../component/FormError";
import {PLANS} from "../../../../consts";

const Plans = Object.values(PLANS);
const EditModal = (props) => {
  const [errors, setErrors] = useState({
    nameError: null,
    codeError: null,
    descriptionError: null,
    priceError: null,
    priceAnnualError: null,
  });

  const [state, setState] = useState({ descKey:'sadf' });

  useEffect(() => {
    if (props.subscriptionPlans.updateSuccess) {
      window.$("#add_plan").modal("hide");
      onClose();
    }
  }, [props.subscriptionPlans.updateSuccess]);


  /**
   *
   */
  const onSubmit = (e) => {
    e.preventDefault();

    const plan = props.subscriptionPlan;

    const name = document.querySelector("#plan_name").value;
    const code = document.querySelector("#plan_code").value;
    const description = document.querySelector("#plan_description").value;
    const features = document.querySelector("#plan_features").value;
    const price = document.querySelector("#plan_price").value * 1;
    const price_annual = document.querySelector("#plan_price_annual").value * 1;
    const domains_limit =
      document.querySelector("#plan_domains_limit").value * 1;
    const integrations_limit =
      document.querySelector("#plan_integrations_limit").value * 1;
    const available = document.querySelector("#plan_available").checked ? 1 : 0;
    const is_branded = document.querySelector("#plan_is_branded").checked ? 1 : 0;

    let nameError = null,
      codeError = null,
      descriptionError = null,
      featuresError = null;

    if (!name || !name.length) {
      nameError = "Please enter Plan name. ";
    }

    if (
      !code ||
      !code.length ||
      Plans.indexOf(code) < 0
    ) {
      codeError = "Please enter valid CODE of Subscription Plan. ";
    }

    if (!description || !description.length) {
      descriptionError = "Please enter Plan Description. ";
    }
    if (!features || !features.length) {
      featuresError = "Please enter Plan Description. ";
    }

    setErrors({
      nameError,
      codeError,
      descriptionError,
      featuresError,
    });

    if (!nameError && !descriptionError && !codeError && !featuresError) {
      const data = {
        ...props.subscriptionPlan,
        name,
        code,
        description,
        features,
        price,
        price_annual,
        available,
        domains_limit,
        integrations_limit,
        is_branded
      };

      props.onEdit(data);
    }

    return false;
  };

  /**
   *
   */
  const onClose = () => {
    setTimeout(props.onClose, 350);
  };

  const plan = props.subscriptionPlan;
  const isNew = plan.uuid ? false : true;

  const header = isNew ? "Add Subscription Plan" : "Edit Subscription Plan";

  const submitButtonLabel = isNew ? "Create" : "Update";

  const { update, updateError } = props.subscriptionPlans;

  const loadingIndicator = update ? <LoadingIndicator /> : null;

  return (
    <div id="add_plan" className="modal custom-modal fade" role="dialog">
      <div className="modal-dialog">
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          onClick={onClose}
        >
          &times;
        </button>
        <div className="modal-content modal-md">
          <div className="modal-header">
            <h4 className="modal-title">{header}</h4>
          </div>
          <div className="modal-body">
            <form>
              <div className="form-group">
                <label>
                  Name <span className="text-danger">*</span>
                </label>

                <input
                  id="plan_name"
                  className={
                    "form-control " + (errors.nameError ? "is-invalid" : "")
                  }
                  type="text"
                  placeholder="Subscription Plan Name"
                  defaultValue={plan.name}
                />
              </div>

              <div className="form-group">
                <label>
                  CODE <span className="text-danger">*</span>
                </label>

                <input
                  id="plan_code"
                  className={
                    "form-control " + (errors.codeError ? "is-invalid" : "")
                  }
                  type="text"
                  placeholder="CODE"
                  defaultValue={plan.code}
                  disabled={!isNew}
                />
                <small>
                  This is a hardcoded value, use one of: {Plans.join(', ')}.
                </small>
              </div>

              <div className="form-group">
                <label>
                  Description<span className="text-danger">*</span>
                </label>

                <textarea
                  key={"pd-" + state.descKey}
                  className={
                    "form-control " +
                    (errors.descriptionError ? "is-invalid" : "")
                  }
                  id="plan_description"
                  placeholder="Subscription plan description"
                  defaultValue={plan.description}
                />
              </div>

              <div className="form-group">
                <label>
                  Features<span className="text-danger">*</span>
                </label>

                <textarea
                  key={"pd-" + state.descKey}
                  className={
                    "form-control " +
                    (errors.descriptionError ? "is-invalid" : "")
                  }
                  id="plan_features"
                  placeholder="Subscription plan features"
                  defaultValue={plan.features}
                />
                <small>List of features, semi-colon separated.</small>
              </div>

              <div className="form-group">
                <label>
                  <div className="checkbox-inline">
                    <input
                      key={"pib-" + state.descKey}
                      id="plan_is_branded"
                      type="checkbox"
                      defaultChecked={plan.is_branded * 1 ? true : false}
                    />{" "}
                    Is branded
                  </div>
                </label>
              </div>

              <div className="form-group">
                <label>
                  Price (per month)<span className="text-danger">*</span>
                </label>

                <input
                  className={
                    "form-control " + (state.price ? "is-invalid" : "")
                  }
                  id="plan_price"
                  type="number"
                  placeholder="Monthly price"
                  defaultValue={plan.price}
                  disabled={!isNew}
                />
              </div>

              <div className="form-group">
                <label>
                  Price (per annum)<span className="text-danger">*</span>
                </label>

                <input
                  className={
                    "form-control " + (state.price ? "is-invalid" : "")
                  }
                  id="plan_price_annual"
                  type="number"
                  placeholder="Price per year"
                  defaultValue={plan.price_annual}
                  disabled={!isNew}
                />
              </div>

              <div className="form-group">
                <label>
                  Websites limit<span className="text-danger">*</span>
                </label>

                <input
                  className={
                    "form-control " + (state.domains_limit ? "is-invalid" : "")
                  }
                  id="plan_domains_limit"
                  type="number"
                  min={1}
                  max={100}
                  placeholder="Number of domains allowed within the plan"
                  defaultValue={plan.domains_limit}
                />
              </div>
              <div className="form-group">
                <label>
                  Integrations limit<span className="text-danger">*</span>
                </label>

                <input
                  className={
                    "form-control " + (state.domains_limit ? "is-invalid" : "")
                  }
                  id="plan_integrations_limit"
                  type="number"
                  min={1}
                  max={100}
                  placeholder="Number of Integrations allowed within the plan"
                  defaultValue={plan.integrations_limit}
                />
              </div>

              <div className="form-group">
                <label>
                  <div className="checkbox-inline">
                    <input
                      key={"pd-" + state.descKey}
                      className={errors.endDateError ? "is-invalid" : ""}
                      id="plan_available"
                      type="checkbox"
                      defaultChecked={plan.available * 1 ? true : false}
                    />{" "}
                    Available
                  </div>
                </label>
              </div>


              <div className="m-t-20 text-center">
                <div className="form-group">
                  <button className="btn btn-primary btn-lg" onClick={onSubmit}>
                    {submitButtonLabel}
                  </button>
                </div>
              </div>

              <ApiError
                error={updateError}
                defaultErrorMessage={
                  "Could not add subscription plan. Please try again"
                }
              />
              {loadingIndicator}
              <FormError
                errors={[
                  errors.nameError,
                  errors.descriptionError,
                  errors.codeError,
                  errors.priceAnnualError,
                  errors.priceError,
                  errors.featuresError,
                ]}
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

EditModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  subscriptionPlans: PropTypes.object.isRequired,
  subscriptionPlan: PropTypes.shape({
    uuid: PropTypes.string,
  }).isRequired,
};

export default EditModal;
