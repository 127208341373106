import AxiosConfig from "../AxiosConfig";

/**
 *
 * @param orgSettings
 * @returns {*|null|string}
 */
export const getUploadResourceUrl = (resourceUrl) => {
  if (resourceUrl) {
    if (resourceUrl instanceof File) {
      return URL.createObjectURL(resourceUrl);
    }

    if (resourceUrl.startsWith("http")) {
      return resourceUrl;
    }
    return AxiosConfig.getEndpointAddress() + "/" + resourceUrl;
  }

  return null;
};
