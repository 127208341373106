/**
 * Created by piotr.pozniak@thebeaverhead.com on 23/07/2021.
 */
import React, { useEffect, useState } from "react";
import ModalTemplate from "./ModalTemplate";
import { useHistory } from "react-router-dom";
import CCBIntegrationForm from "./NewIntegration/CCBIntegrationForm";
import PCOIntegrationForm from "./NewIntegration/PCOIntegrationForm";
import { useIntegrationStore } from "../../../hooks/redux/integration";
import { useIntegrationsStore } from "../../../hooks/redux/integrations";
import { useUserStore } from "../../../hooks/redux/user";
import { IntegrationStatus } from "../consts/integrations";
import GoogleIntegrationForm from "./NewIntegration/GoogleIntegrationForm";
import BreezeIntegrationForm from "./NewIntegration/BreezeIntegrationForm";

const integrationTypesMapping = {
  ccb: CCBIntegrationForm,
  pco: PCOIntegrationForm,
  google: GoogleIntegrationForm,
  breeze: BreezeIntegrationForm,
};
const NewIntegrationModal = (props) => {
  const matchParams = props.match.params;

  const { history } = useHistory();
  const { user } = useUserStore();

  const { integration, setIntegrationModel, syncIntegration } =
    useIntegrationStore();

  const { integrations } = useIntegrationsStore();

  useEffect(() => {
    return () => {
      setIntegrationModel(null);
    };
  }, []);

  useEffect(() => {
    if (integration.updateSuccess) {
      if (integration.model.status === IntegrationStatus.reAuthRequired) {
        // it should redirect but if not then keep the modal open
      } else {
        if (
          !integration.model.last_sync &&
          integration.model.status === IntegrationStatus.notSynced
        ) {
          syncIntegration(integration.model.uuid);
        }
        onClose();
      }
    }
  }, [integration.updateSuccess]);

  /**
   *
   * @param e
   */
  const onClose = (e) => {
    //  if (integrations.collection.length > 0) {
    props.history.go(-1);
    /*}
    props.history.go(matchParams.type ? -3 : -2);*/
  };

  const integrationType = matchParams.type || null;

  const IntegrationForm = integrationTypesMapping[integrationType];

  const form = integrationType ? <IntegrationForm /> : null;

  const content = integrationType ? form : null;

  const subheader = integrationType ? (
    ""
  ) : (
    <p>Select the platform you want to integrate with.</p>
  );

  return (
    <ModalTemplate
      id={"integrationModal"}
      header={"New Integration"}
      size={"lg"}
      onClose={onClose}
    >
      <div className={"integration-modal"}>
        {subheader}
        <div className={"row"}>{content}</div>
      </div>
    </ModalTemplate>
  );
};

export default NewIntegrationModal;
