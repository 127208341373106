/**
 * Created by piotr.pozniak@thebeaverhead.com on 22/11/2023.
 */

import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as organizationActions from "../../actions/organization";

export function useOrganizationStore() {
  const dispatch = useDispatch();
  const _organization = useSelector((store) => store.organization);

  const fetchOrganization = useCallback(
    async (uuid) => await dispatch(organizationActions.fetchOrganization(uuid)),
    [dispatch]
  );
  const editOrganization = useCallback(
    async (uuid, data, isAdminRequest) =>
      await dispatch(
        organizationActions.editOrganization(uuid, data, isAdminRequest)
      ),
    [dispatch]
  );

  const editOrganizationSubscription = useCallback(
    async (uuid, expiry) =>
      await dispatch(
        organizationActions.editOrganizationSubscription(uuid, expiry)
      ),
    [dispatch]
  );

  return {
    organization: _organization,
    fetchOrganization,
    editOrganization,
    editOrganizationSubscription,
  };
}
