/**
 * Created by piotr.pozniak@thebeaverhead.com on 24/09/2021.
 */

import React, { useCallback, useEffect, useState } from "react";
import LoadingIndicator from "../../../calendar/components/LoadingIndicator";
import SuccessAlert from "./../../component/SuccessAlert";
import { useUserStore } from "../../../../hooks/redux/user";
import { Link } from "react-router-dom";
import { useAppStore } from "../../../../hooks/redux/app";
import DismissableMessage from "../WidgetPageTemplate/InstallTab/DismissableMessage";
import FeatureFlag from "../../component/FeatureFlag";

const BecomeAffiliateInfo = (props) => {
  const { dismissedAlerts, dismissAlert } = useAppStore();

  const onDismiss = useCallback((e) => {
    e.preventDefault();
    dismissAlert("becomeAffiliate");
    return false;
  }, []);

  return (
    <FeatureFlag name={"showAffiliates"}>
      <DismissableMessage
        message={
          <Link to={"/affiliate#share-earn"}>
            🎁 Earn 30% for each referral. Join our affiliate program!
          </Link>
        }
        id={"becomeAffiliate"}
      />
    </FeatureFlag>
  );
};

export default BecomeAffiliateInfo;
