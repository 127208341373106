import { useState } from "react";

const useSetupChangesTrackerHook = () => {
  const [origin, setOrigin] = useState({}); // origin object to compare changes with [initialState
  const [changes, setChanges] = useState({});

  const hasChanges = Object.keys(changes).length > 0;

  /**
   * Track changes for a field.
   * @param field
   * @param value
   */
  const trackChanges = (field, value) => {
    // set changes if the value is different from the origin

    // for values that are objects
    if (typeof value === "object") {
      const stringValue = JSON.stringify(value);
      const stringOrigin = JSON.stringify(origin[field]);

      if (stringValue === stringOrigin) {
        setChanges((prevChanges) => {
          const newChanges = { ...prevChanges };
          delete newChanges[field];
          return newChanges;
        });
      } else {
        setChanges((prevChanges) => ({
          ...prevChanges,
          [field]: value,
        }));
      }
    } else {
      // compare new value with the origin value, if it's the same, remove it from the changes
      if (origin[field] === value) {
        setChanges((prevChanges) => {
          const newChanges = { ...prevChanges };
          delete newChanges[field];
          return newChanges;
        });
      } else {
        setChanges((prevChanges) => ({
          ...prevChanges,
          [field]: value,
        }));
      }
    }
  };

  /**
   *
   * @param values
   */
  const setOriginValues = (values) => {
    setChanges({});

    setOrigin(JSON.parse(JSON.stringify(values)));
  };

  return {
    changes,
    trackChanges,
    hasChanges,
    setOriginValues,
  };
};

export default useSetupChangesTrackerHook;
