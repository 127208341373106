/**
 * Created by piotr.pozniak@thebeaverhead.com on 12/02/2024
 */

import React, { useCallback } from "react";
import PropTypes from "prop-types";
import Role from "./Role";
import Email from "./Email";
import Status from "./Status";
import Actions from "./Actions";
import { OrganizationMemberStatus } from "../../consts/crm";

const TableRow = (props) => {
  const { member } = props;

  const onSetRole = useCallback(
    (role) => {
      props.updateMemberData(member.uuid, "role", role);
    },
    [member]
  );

  const onSetEmail = useCallback(
    (email) => {
      const label = member.user ? "email" : "invite_email";
      props.updateMemberData(member.uuid, label, email);
    },
    [member]
  );

  /**
   *
   * @type {(function(*): void)|*}
   */
  const onRemove = useCallback(
    (e) => {
      props.onRemove(member);
    },
    [member]
  );

  const onCancelRemove = useCallback(
    (e) => {
      props.onCancelRemove(member);
    },
    [member]
  );

  /**
   *
   * @type {(function(*): void)|*}
   */
  const onResendInvitation = useCallback(
    (e) => {
      props.onResendInvitation(member);
    },
    [member]
  );

  return (
    <tr>
      <td>{member.user ? member.user.name : "--"}</td>
      <td>
        <Email
          editable={
            !member.user &&
            (member.status === OrganizationMemberStatus.newMember ||
              member.invite_status === OrganizationMemberStatus.newMember)
          }
          setEmail={onSetEmail}
          disabled={props.disabled || member.user ? true : false}
          email={member.user ? member.user.email : member.invite_email}
        />
      </td>
      <td>
        <Role
          role={member.role}
          setRole={onSetRole}
          disabled={props.disabled}
        />
      </td>
      <td>
        <Status status={member.invite_status} />
      </td>
      <td>
        <Actions
          member={member}
          onRemove={onRemove}
          onResendInvitation={onResendInvitation}
          onCancelRemove={onCancelRemove}
        />
      </td>
    </tr>
  );
};

TableRow.defaultProps = {};

TableRow.propTypes = {
  member: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
  onResendInvitation: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  updateMemberData: PropTypes.func.isRequired,
  onCancelRemove: PropTypes.func.isRequired,
};

export default TableRow;
