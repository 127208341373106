/**
 * Created by piotr.pozniak@thebeaverhead.com on 18/06/2021.
 */

import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as domainsActions from "../../actions/domains";
import * as domainActions from "../../actions/domain";

export function useDomainsStore() {
  const dispatch = useDispatch();
  const _domains = useSelector((store) => store.domains);

  const fetchDomains = useCallback(
    async (page, ipp) => await dispatch(domainsActions.fetchDomains(page, ipp)),
    [dispatch]
  );

  const updateDomain = useCallback(
    async (uuid, data) => await dispatch(domainActions.updateDomain(uuid, data)),
    [dispatch]
  );

  const deleteDomain = useCallback(
    async (uuid) => await dispatch(domainActions.deleteDomain(uuid)),
    [dispatch]
  );

  return {
    domains: _domains,
    fetchDomains,
    updateDomain,
    deleteDomain,
  };
}
