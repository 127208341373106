/**
 * Created by piotr.pozniak@thebeaverhead.com  on 06/04/2022
 */

import React, { useContext } from "react";
import PropTypes from "prop-types";
import ButtonCta from "./ButtonCta";
import Address from "./Address";
import MeetingDayTime from "./MeetingDayTime";
import Primitives from "../primitives";
import PrimitiveBadges from "../PrimitiveBadges/PrimitiveBadges";
import Badge from "../PrimitiveBadges/Badge";
import { formatCTAUrl } from "../../tools";
import WidgetSettingsContext from "../../../../contexts/WidgetSettingsContext";

const RightColumn = (props) => {
  const { item } = props;
  const widgetSettingsContext = useContext(WidgetSettingsContext);

  const showPrimaryCta =
    Number.parseInt(widgetSettingsContext["gtCShowCta1st"]) === 1;
  const primaryCtaAction = widgetSettingsContext["gtCCTAAction1st"];
  const primaryCtaLink = item.ctas[primaryCtaAction] || null;
  const isPrimaryCTADefaultLabel =
    Number.parseInt(widgetSettingsContext["gtC1stDefaultCTALabel"]) === 1;
  const primaryCTACustomLabel = widgetSettingsContext["gtC1stCTALabel"];
  const primaryCTASubject = widgetSettingsContext["gCTASubject1st"];
  const primaryCTAMessage = widgetSettingsContext["gCTAMessage1st"];

  const showSecondaryCta =
    Number.parseInt(widgetSettingsContext["gtCShowCta2nd"]) === 1;
  const secondaryCtaAction = widgetSettingsContext["gtCCTAAction2nd"];
  const secondaryCtaLink = item.ctas[secondaryCtaAction] || null;
  const isSecondaryCTADefaultLabel =
    Number.parseInt(widgetSettingsContext["gtC2ndDefaultCTALabel"]) === 1;
  const secondaryCTACustomLabel = widgetSettingsContext["gtC2ndCTALabel"];
  const secondaryCTASubject = widgetSettingsContext["gCTASubject2nd"];
  const secondaryCTAMessage = widgetSettingsContext["gCTAMessage2nd"];

  const showDescription =
    Number.parseInt(widgetSettingsContext["gSDescriptionCard"]) === 1;

  const showGroupName =
    Number.parseInt(widgetSettingsContext["gcgShowName"]) === 1;
  const showAddress =
    Number.parseInt(widgetSettingsContext["gcgShowAddress"]) === 1;
  const showMeeting =
    Number.parseInt(widgetSettingsContext["gcgShowMeetingTime"]) === 1;
  const openLinkInNewTab =
    Number.parseInt(widgetSettingsContext["openLinksNewTab"]) === 1;

  const description = showDescription ? (
    <p dangerouslySetInnerHTML={{ __html: item.description }}></p>
  ) : null;

  let primaryLink =
    showPrimaryCta && primaryCtaLink
      ? formatCTAUrl(
          primaryCtaAction,
          primaryCtaLink,
          primaryCTASubject,
          primaryCTAMessage
        )
      : null;

  let secondaryLink =
    showSecondaryCta && secondaryCtaLink
      ? formatCTAUrl(
          secondaryCtaAction,
          secondaryCtaLink,
          secondaryCTASubject,
          secondaryCTAMessage
        )
      : null;

  const primaryCta =
    showPrimaryCta && primaryCtaLink ? (
      <ButtonCta
        url={primaryLink}
        label={
          !isPrimaryCTADefaultLabel
            ? primaryCTACustomLabel
            : primaryCtaLink.label
        }
        className={"col--right_btn--primary-cta"}
        target={openLinkInNewTab ? "_blank" : "_self"}
      />
    ) : null;

  const secondaryCta =
    showSecondaryCta && secondaryCtaLink ? (
      <ButtonCta
        url={secondaryLink}
        label={
          !isSecondaryCTADefaultLabel
            ? secondaryCTACustomLabel
            : secondaryCtaLink.label
        }
        className={"col--right_btn--secondary-cta"}
        target={openLinkInNewTab ? "_blank" : "_self"}
      />
    ) : null;

  const address =
    showAddress && item.address ? <Address address={item.address} /> : null;

  const meeting =
    showMeeting && item.meeting ? (
      <MeetingDayTime
        meetingTime={item.meeting.meeting_time}
        meetingDay={item.meeting.meeting_day}
      />
    ) : null;

  const badgesToShow = Primitives.filter(
    (i) =>
      Number.parseInt(
        widgetSettingsContext[`gBCShow${i.visibilitySetting}`]
      ) === 1
  );

  const badges = badgesToShow.length ? (
    <PrimitiveBadges>
      {badgesToShow.map((i, idx) => {
        const value = item.primitives[i.visibilitySetting];

        if (!value) {
          return null;
        }

        return (
          <Badge
            key={`lbl-${item.slug}-${i.visibilitySetting}`}
            className={"dce--groups-primitives-badges-badge-list"}
            name={value}
            label={i.label}
          />
        );
      })}
    </PrimitiveBadges>
  ) : null;

  const groupName = showGroupName ? (
    <h2>
      {item.name}
      {address}
      {meeting}
    </h2>
  ) : null;
  return (
    <div className={"col--right"}>
      {groupName}

      {description}
      {badges}
      <div className={"col--right_btn-container"}>
        {primaryCta}
        {secondaryCta}
      </div>
    </div>
  );
};

RightColumn.defaultProps = {};

RightColumn.propTypes = {
  item: PropTypes.object.isRequired,
};

export default RightColumn;
