/**
 * Created by piotr.pozniak@thebeaverhead.com on 15/05/2024
 */

import React from "react";
import PropTypes from "prop-types";
import appConfig from "../../../appConfig";

const HelpAnchor = (props) => {
  const _href = appConfig.branding === "dc" ? props.href : null;
  const Component = appConfig.branding === "dc" ? "a" : "div";

  return (
    <Component {...props} href={_href}>
      {props.children}
    </Component>
  );
};

HelpAnchor.defaultProps = {};

HelpAnchor.propTypes = {};

export default HelpAnchor;
