/**
 * Created by piotr.pozniak@thebeaverhead.com on 16/02/2024.
 */

import React from "react";

import { Link } from "react-router-dom";
import classnames from "classnames";
import PropTypes from "prop-types";

const SideMenuGroup = (props) => {
  return (
    <React.Fragment>
      <li className="menu-title">{props.label}</li>
      {props.children}
    </React.Fragment>
  );
};

SideMenuGroup.propTypes = {
  label: PropTypes.string,
};

SideMenuGroup.defaultProps = {};

export default SideMenuGroup;
