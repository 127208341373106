import axios from "axios";
import AxiosConfig from "../AxiosConfig";

/**
 *
 * @returns {function(*): Promise<AxiosResponse<any>>}
 */
export function fetchAppTags() {
  return function (dispatch) {
    dispatch({ type: "APP_TAGS_FETCH" });

    const url = "/api4/tags.json";

    return axios
      .get(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "APP_TAGS_FETCH_FULFILLED",
          payload: response.data.tags,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "APP_TAGS_FETCH_REJECTED",
          payload: error,
        });
      });
  };
}

export function updateAppTag(type, tag, isDelete = false) {
  return function (dispatch) {
    dispatch({ type: "APP_TAGS_UPDATE" });

    const url = `/api4/tags/${tag}.json`;

    return axios
      .put(url, { type: type, delete: isDelete }, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "APP_TAGS_UPDATE_FULFILLED",
          payload: response.data,
          tagType: type,
          tag,
          isDelete,
        });
        return response.data;
      })
      .catch(function (error) {
        console.error("Error updating tag", error);
        dispatch({
          type: "APP_TAGS_UPDATE_REJECTED",
          error,
        });
        throw error;
      });
  };
}
