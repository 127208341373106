/**
 * Created by piotr.pozniak@thebeaverhead.com on 02/08/2024
 */

import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import LinksTable from "./LinksTable";
import TabsView from "../../component/TabsView/TabsView";
import AffiliatePanelButton from "./AffiliatePanelButton";
import Tab from "../../component/TabsView/Tab";
import AffiliatePromoCard from "./AffiliatePromoCard";
import { useHistory } from "react-router-dom";
import AffiliateTools from "./AffiliateTools";

const defaultTabId = "share-earn";
const Tabs = [
  {
    label: "Share & Earn",
    id: defaultTabId,
    component: <AffiliateTools />,
  },
  {
    label: "Details",
    id: "details",
    component: <AffiliatePromoCard />,
  },
];

const AffiliatePanel = (props) => {
  const tabPanels = useMemo(() => {
    return Tabs.map((tab, idx) => {
      return (
        <Tab id={tab.id} key={`tab-${idx}`} isDefault={tab.id === defaultTabId}>
          {tab.component}
        </Tab>
      );
    });
  }, [useHistory().location.hash]);

  return (
    <div className={"row"}>
      <div className={"col"}>
        <div className={"card"}>
          <TabsView tabs={Tabs} defaultTab={"share-earn"}>
            {tabPanels}
          </TabsView>
        </div>
      </div>
    </div>
  );
};

AffiliatePanel.defaultProps = {};

AffiliatePanel.propTypes = {};

export default AffiliatePanel;
