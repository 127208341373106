/**
 * Created by piotr.pozniak@thebeaverhead.com on 13/12/2018.
 */

import React, { useCallback } from "react";
import PropTypes from "prop-types";

export const TabOption = (props) => {
  const { header, options } = props;

  /**
   *
   * @param e
   */
  const onTabClick = useCallback((e) => {
    e.preventDefault();
    return false;
  }, []);

  const searchElement =
    header && options.length > 0 ? (
      <li className="submenu">
        <a className="design-tab-menu" href="#" onClick={onTabClick}>
          <span>{header}</span>
          <span className="menu-arrow" />
        </a>
        <ul className="list-unstyled" style={{ display: "none" }}>
          {options}
        </ul>
      </li>
    ) : null;

  return searchElement;
};

TabOption.defaultProps = {
  options: [],
  header: null,
};

TabOption.propTypes = {
  header: PropTypes.object,
  options: PropTypes.array,
};

export default React.memo(TabOption);
