/**
 * Created by piotr.pozniak@thebeaverhead.com on 21/08/2024
 */

import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useAffiliatesStore } from "../../../../hooks/redux/affiliates";
import { useAppStore } from "../../../../hooks/redux/app";
import { useUserStore } from "../../../../hooks/redux/user";
import { useCurrentOrganizationStore } from "../../../../hooks/redux/currentOrganization";

const FetchAffiliatesError = (props) => {
  const { affiliates } = useAffiliatesStore();
  const { initializeIntercom } = useAppStore();
  const { user } = useUserStore();
  const { currentOrganization } = useCurrentOrganizationStore();

  const contactSupport = useCallback((e) => {
    e.preventDefault();

    initializeIntercom(user, currentOrganization, false);

    if (window.Intercom) {
      window.Intercom("onShow", () => {});

      setTimeout(() => window.Intercom("show"), 500);
    }

    return false;
  }, []);

  if (!affiliates.fetchError || affiliates.fetch) {
    return null;
  }

  return (
    <div className={"row"}>
      <div className={"col-xs-12 offset-xs-0 col-xl-8 offset-xl-2"}>
        <div className={"alert alert-danger"}>
          <p>
            <strong>Something went wrong</strong>
            <br />
            There was a problem pulling your affiliate account information.
          </p>
          <p>Ensure you don't have any ad-blockers turned on. </p>
          <p>
            If the problem persists, please{" "}
            <a href="#" onClick={contactSupport}>
              contact support
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

FetchAffiliatesError.defaultProps = {};

FetchAffiliatesError.propTypes = {};

export default FetchAffiliatesError;
