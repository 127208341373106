/**
 * Created by piotr.pozniak@thebeaverhead.com on 14/05/2024
 */
import { useCallback, useState } from "react";

const useFormHook = (initialValues) => {
  const [form, setFormValue] = useState(initialValues);

  const onInputChange = useCallback(
    (field) => (e) => {
      const data = { ...form };
      data[field] = e.target.value;

      setFormValue(data);
    },
    [form]
  );

  /**
   *
   * @type {function(*): function(*): void}
   */
  const onInputFileChange = useCallback(
    (field) => (file) => {
      const data = { ...form };
      data[field] = file;

      setFormValue(data);
    },
    [form]
  );

  /**
   *
   * @type {function(*): function(*): void}
   */
  const onValueChange = useCallback(
    (field) => (value) => {
      const data = { ...form };
      data[field] = value;

      setFormValue(data);
    },
    [form]
  );

  return { form, onInputChange, onValueChange, onInputFileChange };
};

export default useFormHook;
