/**
 * Created by piotr.pozniak@thebeaverhead.com on 18/06/2021.
 */

import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as integrationsActions from "../../actions/integrations";

export function useIntegrationsStore() {
  const dispatch = useDispatch();
  const _integrations = useSelector((store) => store.integrations);

  const fetchIntegrations = useCallback(
    async (page, ipp) => await dispatch(integrationsActions.fetchIntegrations(page, ipp)),
    [dispatch]
  );

  return {
    integrations: _integrations,
    fetchIntegrations,

  };
}
