/**
 * Created by piotr.pozniak@thebeaverhead.com on 13/10/2018.
 */

import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import moment from "moment";
import { store, persistor } from "./apps/admin/store";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
import RouterClass from "./apps/admin/router";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { getTranslationLanguage } from "./helpers/translations";
import * as Sentry from "@sentry/react";
import appConfig from "./appConfig";
import AxiosConfig from "./AxiosConfig";
import axios from "axios";

require("./sass/calendar.scss");
require("./sass/groups.scss");
require("./sass/alerts.scss");
require("./sass/admin.scss");
require("./sass/signups.scss");

require("react-notifications-component/dist/theme.css");

console.log("Hello Jason!");
const webBrowserLanguage = getTranslationLanguage();
moment.locale(webBrowserLanguage);

const translationResources = process.env.APP_TRANSLATIONS;

for (const key in process.env.WIDGET_TRANSLATIONS) {
  for (const lng in translationResources) {
    // merge objects
    if (
      translationResources[lng] &&
      process.env.WIDGET_TRANSLATIONS[key][lng]
    ) {
      const merged = Object.assign(
        {},
        translationResources[lng].translation,
        process.env.WIDGET_TRANSLATIONS[key][lng].translation
      );
      translationResources[lng].translation = merged;
    }
  }
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: translationResources,
    lng: webBrowserLanguage, // if you're using a language detector, do not define the lng option
    fallbackLng: "en",

    // interpolation: {
    //   escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    // },
  });

function launchApp(swObject) {
  const root = createRoot(document.getElementById("app"));

  root.render(
    <BrowserRouter>
      <Provider store={store}>
        {/*<ConnectedRouter history={history}>*/}
        <PersistGate loading={null} persistor={persistor}>
          <RouterClass swObject={swObject} />
        </PersistGate>
        {/*</ConnectedRouter>*/}
      </Provider>
    </BrowserRouter>
  );
}

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  window.__DCE__DEV__ = true;
} else {
  window.__DCE__DEV__ = false;

  Sentry.init({
    dsn: appConfig.sentryDsn,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "localhost",
      "localhost:81",
      AxiosConfig.getEndpointAddress(),
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log("Error response", error.response);
        Sentry.captureException(error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        Sentry.captureException(error.message);
      }
      Sentry.captureException(error);
      return Promise.reject(error);
    }
  );
}

const timeoutEnforcement = (criteria, callback, timeout) => {
  if (criteria()) {
    callback();
  } else {
    setTimeout(() => timeoutEnforcement(criteria, callback, timeout), timeout);
  }
};

timeoutEnforcement(
  () => (window.$ && typeof window.$ === "function" ? true : false),
  launchApp,
  100
);
