/**
 * Created by piotr.pozniak@thebeaverhead.com on 18/06/2021.
 */

import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as userActions from "../../actions/user";

export function useAutologinStore() {
  const dispatch = useDispatch();
  const _autologin = useSelector((store) => store.autologin);


  const autologinUser = useCallback(
    async (email, user) => await dispatch(userActions.autologin(email, user)),
    [dispatch]
  );

  const autologout = useCallback(
    async (token) => await dispatch(userActions.autologout(token)),
    [dispatch]
  );
  return {
    autologin: _autologin,
    autologinUser,
    autologout,

  };
}
