/**
 * Created by piotr.pozniak@thebeaverhead.com on 07/06/2022
 */

import React, { useMemo } from "react";
import PropTypes from "prop-types";
import SideMenuItem from "../PageTemplate/SideMenuItem";
import SideMenuSection from "./SideMenuSection";

const MenuOptions = [
  {
    label: "Account",
    id: "account",
    options: [
      {
        label: "Profile",
        icon: "manage_accounts",
        id: "account_settings",
        url: "/settings",
      },
      {
        label: "Affiliates",
        icon: "handshake",
        id: "affiliates",
        url: "/affiliate#share-earn",
      },
    ],
  },
  {
    label: "Organization",
    options: [
      {
        label: "Settings",
        icon: "settings",
        id: "org-settings",
        url: "/organization-settings",
      },
      {
        label: "Branding",
        icon: "fingerprint",
        id: "branding",
        url: "/branding",
        beta: true,
      },
      {
        label: "Members",
        icon: "people",
        id: "members",
        url: "/members",
        beta: true,
      },
      {
        label: "Billing",
        icon: "card_membership",
        id: "billing",
        url: "/billing",
      },
    ],
  },
];

MenuOptions[1].options.push();
const SidebarMenu = (props) => {
  const menu = useMemo(() => {
    if (MenuOptions.length === 0) {
      return null;
    }

    return MenuOptions.map((section, idx) => {
      return (
        <SideMenuSection key={`sms-${idx}`} label={section.label}>
          {section.options.map((i, jdx) => {
            return (
              <SideMenuItem
                key={`owmo-${idx}-${jdx}`}
                currentSidebarId={props.sidebarId}
                {...i}
              />
            );
          })}
        </SideMenuSection>
      );
    });
  }, [props.sidebarId]);

  return (
    <div key="orgsettingsmenu" id="sidebar-menu" className="sidebar-menu">
      <ul>
        <SideMenuItem
          key={"backtohome"}
          currentSidebarId={"backtohome"}
          label={"Back to home"}
          icon={"arrow_back"}
          url={"/dashboard"}
        />
        {menu}
      </ul>
    </div>
  );
};

SidebarMenu.defaultProps = {};

SidebarMenu.propTypes = {
  sidebarId: PropTypes.string,
};

export default SidebarMenu;
