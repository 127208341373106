/**
 * Created by piotr.pozniak@thebeaverhead.com on 07/02/2023.
 */

import axios from "axios";
import AxiosConfig from "../AxiosConfig";

/**
 *
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export function fetchQueueWorkers() {
  return function (dispatch) {
    dispatch({ type: "QUEUE_WORKERS_FETCH" });

    const url = "/api/queue-processes.json";

    return axios
      .get(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "QUEUE_WORKERS_FETCH_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "QUEUE_WORKERS_FETCH_REJECTED",
          payload: error,
        });
      });
  };
}

export const runWorker = (jobType = "Embedding") => {
  return async (dispatch) => {
    dispatch({ type: "QUEUE_WORKER_RUN" });

    const url = `/api/queue-processes/runworker/${jobType}.json`;

    return axios
      .post(url, {}, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "QUEUE_WORKER_RUN_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "QUEUE_WORKER_RUN_REJECTED",
          payload: error,
        });
      });
  };
};

export const terminateWorker = (id) => {
  return async (dispatch) => {
    dispatch({ type: "QUEUE_WORKER_TERMINATE" });

    const url = `/api/queue-processes/terminate/${id}.json`;

    return await axios
      .delete(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "QUEUE_WORKER_TERMINATE_FULFILLED",
          payload: response.data,
          queueId: id,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "QUEUE_WORKER_TERMINATE_REJECTED",
          payload: error,
        });
      });
  };
};
