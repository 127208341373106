/**
 * Created by piotr.pozniak@thebeaverhead.com on 23/11/2022
 */

import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import AxiosConfig from "./../../../AxiosConfig";
import axios from "axios";

const useAuthHook = (props) => {
  const [authError, setAuthError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const onGoogleSignUp = useCallback(
    // flow can be either "login" or "register"
    (flow = "login") =>
      (e) => {
        e.preventDefault();
        setIsLoading(true);
        setAuthError(null);

        axios
          .get(
            AxiosConfig.getEndpointAddress() +
              `/api/users/auth_url_google/${flow}.json`
          )
          .then((response) => {
            setIsLoading(false);
            window.open(response.data.authUrl, "_self");
          })
          .catch((error) => {
            console.log(error);
            setIsLoading(false);
            setAuthError(error.message);
          });
      },
    [authError, isLoading]
  );

  return {
    onGoogleSignUp,
    authError,
    isLoading,
  };
};

export default useAuthHook;
