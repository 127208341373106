/**
 * Created by piotr.pozniak@thebeaverhead.com on 04/04/2024
 */

import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";

const ShareButton = () => {
  const [showPopup, setShowPopup] = useState(false);

  /**
   *
   * @type {function(*): boolean}
   */
  const onShow = useCallback(
    (e) => {
      e.preventDefault();
      setShowPopup(!showPopup);
      return false;
    },
    [showPopup]
  );

  /**
   *
   * @type {function(*): boolean}
   */
  const onClose = useCallback(
    (e) => {
      e.preventDefault();
      setShowPopup(false);
      return false;
    },
    [showPopup]
  );

  return { showPopup, onShow };
};

ShareButton.defaultProps = {};

ShareButton.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
};

export default ShareButton;
