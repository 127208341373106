/**
 * Created by piotr.pozniak@thebeaverhead.com on 12/03/2024
 */

import React from "react";
import PropTypes from "prop-types";
import appConfig from "../../../../../appConfig";

const ExternalLink = ({ onClick, icon, label, className }) => {
  return (
    <a href={"#"} onClick={onClick} className={className}>
      <i className={"material-icons icon-sm"} aria-hidden={true}>
        {icon}
      </i>
      {label}
    </a>
  );
};

ExternalLink.defaultProps = {
  className: "",
};

ExternalLink.propTypes = {
  onClick: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default ExternalLink;
