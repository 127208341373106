/**
 * Created by piotr.pozniak@thebeaverhead.com on 12/03/2021.
 */

import React, { useContext } from "react";
import PropTypes from "prop-types";
import WidgetSettingsContext from "../../../contexts/WidgetSettingsContext";

const CheckPropertyDependency = (props) => {
  const {
    dependsOnName,
    flashMessage,
    dependsOnValue,
    dependsOnDefaultValue,
    show,
  } = props;

  const widgetSettingsContext = useContext(WidgetSettingsContext);

  if (!show || !dependsOnName) {
    return null;
  }

  const isPropertyDependencyMet =
    (widgetSettingsContext[dependsOnName] || dependsOnDefaultValue) ===
    dependsOnValue;

  if (isPropertyDependencyMet) {
    return null;
  }

  return (
    <p>
      <i className="text-danger">{flashMessage}</i>
    </p>
  );
};

CheckPropertyDependency.defaultProps = {
  show: true,
};

CheckPropertyDependency.propTypes = {
  settings: PropTypes.object.isRequired,
  dependsOnName: PropTypes.string,
  dependsOnValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dependsOnDefaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  flashMessage: PropTypes.string,
  show: PropTypes.bool,
};

export default CheckPropertyDependency;
