/**
 * Created by piotr.pozniak@thebeaverhead.com on 19/10/2018.
 */

import React, { useEffect } from "react";

import PageTemplate from "./../templates/PageTemplate";
import Imports from "./DashboardPage/Imports";
import WelcomeScreen from "./DashboardPage/WelcomeScreen";
import { useImportsStore } from "../../../hooks/redux/imports";
import { useCalendarsStore } from "../../../hooks/redux/calendars";
import { useUserStore } from "../../../hooks/redux/user";
import { useIntegrationsStore } from "../../../hooks/redux/integrations";
import { useDomainsStore } from "../../../hooks/redux/domains";
import Filters from "../component/Filters/Filters";

const DashboardPage = (props) => {
  const { fetchImports, imports } = useImportsStore();
  const { calendars } = useCalendarsStore();
  const { integrations } = useIntegrationsStore();
  const { domains } = useDomainsStore();
  const { user } = useUserStore();

  const userModel = user.model || {};

  return (
    <PageTemplate sidebarId="dashboard" title={"Dashboard"} icon={"home"}>
      <WelcomeScreen
        isActivated={userModel.is_account_verified}
        hasSync={integrations.collection.length ? true : false}
        hasCalendar={calendars.collection.length ? true : false}
        hasWebsite={domains.collection.length ? true : false}
      />

      <Imports
        imports={imports}
        integrations={integrations.collection}
        fetchImports={fetchImports}
      />
    </PageTemplate>
  );
};

export default DashboardPage;
