/**
 * Created by piotr.pozniak@thebeaverhead.com on 22/02/2024
 */

import React, { createRef } from "react";
import PropTypes from "prop-types";
import DropdownTos from "./DropdownTos";
import DropdownHeader from "./DropdownHeader";
import OrgSelector from "./OrgSelector";
import ExternalLinks from "./ExternalLinks";
import DropdownFooter from "./DropdownFooter";
import { useClickOutsideDomHook } from "../../../hooks/useClickOutsideDomHook";

const Dropdown = (props) => {
  const dropdownRef = createRef();
  useClickOutsideDomHook({ ref: dropdownRef, callback: props.onClose });

  if (!props.show) {
    return;
  }

  return (
    <div className="nav-user-menu--dropdown" ref={dropdownRef}>
      <DropdownHeader onClose={props.onClose} />

      <OrgSelector onClose={props.onClose} />
      <ExternalLinks />

      <DropdownFooter />
      <DropdownTos />
    </div>
  );
};

Dropdown.defaultProps = {};

Dropdown.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
};

export default Dropdown;
