/**
 * Created by piotr.pozniak@thebeaverhead.com on 16/02/2024.
 */

import React from "react";

import { Link } from "react-router-dom";
import classnames from "classnames";
import PropTypes from "prop-types";

const SideMenuGroup = (props) => {
  return (
    <li className="submenu">
      <a href="#" className={"subdrop"} id={props.id}>
        <span className={"d-inline-flex align-items-center "}>
          <i className={"material-icons"}>{props.icon}</i> {props.label}
        </span>
        <span className="menu-arrow" />
      </a>

      <ul className="list-unstyled">{props.children}</ul>
    </li>
  );
};

SideMenuGroup.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
};

SideMenuGroup.defaultProps = {};

export default SideMenuGroup;
