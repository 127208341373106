/**
 * Created by piotr.pozniak@thebeaverhead.com on 18/07/2022
 */

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const Badge = (props) => {
  const value = props.name;

  if (!value) {
    return null;
  }

  const validValues = {
    Yes: true,
    No: true,
    Open: true,
    Closed: true,
  };

  const label =
    Number.parseInt(value) || validValues[value]
      ? `${props.label}: ${value}`
      : value;

  return (
    <div
      className={classNames(
        "dce--groups-primitives-badges-badge",
        props.className
      )}
    >
      {label}
    </div>
  );
};

Badge.defaultProps = {};

Badge.propTypes = {
  className: PropTypes.string,
  field: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
};

export default Badge;
