/**
 * Created by piotr.pozniak@thebeaverhead.com on 22/09/2022.
 */

import React from "react";
import { PLANS } from "../../../../consts";
import { Link } from "react-router-dom";
import { useCurrentOrganizationStore } from "../../../../hooks/redux/currentOrganization";
import { hasSubscription } from "../../helpers/organization";
import appConfig from "../../../../appConfig";

const FailedPayment1Info = (props) => {
  const { currentOrganization } = useCurrentOrganizationStore();

  if (!currentOrganization.model) {
    return null;
  }
  const currentOrganizationModel = currentOrganization.model;
  const organizationSubscription = hasSubscription(currentOrganization);

  const failedPayment1 = Number.parseInt(
    currentOrganizationModel.sent_timestamp_renewal_failed_period_1
  );

  const failedPayment2 = Number.parseInt(
    currentOrganizationModel.sent_timestamp_renewal_failed_period_2
  );

  const isOnFreePlan =
    !organizationSubscription || organizationSubscription.code === PLANS.free;
  if (!failedPayment1 || (failedPayment1 && failedPayment2) || isOnFreePlan) {
    return null;
  }

  return (
    <React.Fragment>
      <div className="alert alert-warning">
        <p>
          It looks like your payment failed. Your {appConfig.shortDisplayName}
          's account will revert to the FREE PLAN in
          <b> 7</b> days if you decide not to do anything.{" "}
          <Link to={"/billing"}>Update billing.</Link>
        </p>
      </div>{" "}
    </React.Fragment>
  );
};

export default FailedPayment1Info;
