/**
 * Created by piotr.pozniak@thebeaverhead.com on 24/10/2018.
 */

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { updateStylesheet } from "../../../../builder";
import WidgetPreviewAlert from "./WidgetPreviewAlert";
import classNames from "classnames";
import { useAppStore } from "../../../../hooks/redux/app";

const WidgetPreview = (props) => {
  const { app } = useAppStore();

  useEffect(() => {
    if (props.widget.model.widget_settings) {
      updateStylesheet(props.widget.model);

      if (props.widget.model.widget_settings.has_feature_highlights) {
        updateStylesheet(props.widget.model, "highlights");
      }
    }
  }, [app.widgetSettingsUpdateTimestamp]);

  const widgetType = props.widget.model.widget_type;
  const widgetLetterCode = widgetType.charAt(0);

  const previewControlsAlert = app.isWidgetPreviewMode ? (
    <WidgetPreviewAlert
      title={props.widget.model.name}
      widgetType={widgetType}
    />
  ) : null;

  return (
    <div
      className={`chat-contents ${widgetType}-background ${widgetLetterCode}${props.uuid}`}
    >
      <div className="chat-content-wrap">
        <div className="chat-wrap-inner">
          <div className="chat-box">
            <div className="chats">
              <div
                className={classNames(
                  `${widgetType}-preview-container`,
                  `widget-preview-container--${app.previewType}`,
                  {
                    "widget-preview-container": app.isWidgetPreviewMode,
                  }
                )}
              >
                {previewControlsAlert}
                {props.children}
                <div className="clearfix" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

WidgetPreview.propTypes = {
  uuid: PropTypes.string.isRequired,
  widget: PropTypes.object,
};
export default WidgetPreview;
