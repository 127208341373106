/**
 * Created by piotr.pozniak@thebeaverhead.com on 15/07/2022
 */

import React from "react";
import PropTypes from "prop-types";
import { formatDate } from "../../../../helpers/date";

export const dividerDateFormatter = new Intl.DateTimeFormat("en-US", {
  month: "long",
  year: "numeric",
});

const Divider = (props) => {
  const date = props.date
    ? formatDate(props.date, {
        month: "long",
        year: "numeric",
      })
    : null;

  return (
    <div className={"dce-detailed-list--divider"}>
      <div className={"dce-detailed-list--divider-label"}>{date} </div>
      {/*<div className={"dce-detailed-list--divider-line"}/>*/}
    </div>
  );
};

Divider.defaultProps = {};

Divider.propTypes = {
  date: PropTypes.instanceOf(Date),
};

export default Divider;
