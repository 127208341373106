/**
 * Created by piotr.pozniak@thebeaverhead.com on 24/09/2021.
 */

import React, { useEffect, useState } from "react";
import LoadingIndicator from "../../../calendar/components/LoadingIndicator";
import SuccessAlert from "./../../component/SuccessAlert";
import { useUserStore } from "../../../../hooks/redux/user";

const ResendActivationInfo = (props) => {
  const { user, resendActivationToken } = useUserStore();

  const [state, setState] = useState({
    showResendActivationSuccess: false,
    showResendActivationError: false,
  });

  useEffect(() => {
    if (user.resendActivationSuccess) {
      setState({ ...state, showResendActivationSuccess: true });
    }

    if (user.resendActivationError) {
      setState({ ...state, showResendActivationError: true });
    }
  }, [user.resendActivationSuccess, user.resendActivationError]);

  if (!user.model) {
    return null;
  }

  /**
   *
   */
  const onResendActivationLink = (e) => {
    e.preventDefault();
    resendActivationToken();

    return false;
  };
  const userModel = user.model;

  const activateAccountCTA =
    !userModel.is_account_verified &&
    !user.resendActivation &&
    !state.showResendActivationSuccess &&
    !state.showResendActivationError ? (
      <div className="alert alert-warning">
        Activate your account to start free trial.{" "}
        <a href="#" onClick={onResendActivationLink}>
          Resend activation link to {userModel.email}.
        </a>
      </div>
    ) : null;

  const activateAccountCTALoading =
    !userModel.is_account_verified && user.resendActivation ? (
      <div className="alert alert-warning">
        <LoadingIndicator />
      </div>
    ) : null;

  const activateAccountCTAError = state.showResendActivationError ? (
    <div className="alert alert-warning">
      Resending activation link failed.{" "}
      <a href="#" onClick={onResendActivationLink}>
        Please try again.
      </a>
    </div>
  ) : null;

  return (
    <React.Fragment>
      {activateAccountCTA}
      {activateAccountCTALoading}

      <SuccessAlert>
        {state.showResendActivationSuccess
          ? "An activation link has been sent. Please check your mailbox."
          : null}
      </SuccessAlert>

      {activateAccountCTAError}
    </React.Fragment>
  );
};

export default ResendActivationInfo;
