import React, { useEffect } from "react";
import PropTypes from "prop-types";
import ImportTableRow from "./ImportTableRow";
import LoadingIndicator from "../../../calendar/components/LoadingIndicator";
import Pagination from "../../component/Pagination";
import { Link } from "react-router-dom";

const Imports = (props) => {
  /**
   *
   */
  useEffect(() => {
    props.fetchImports(1);
  }, []);

  const { imports } = props;

  const importsTable = imports.collection.length ? (
    <table className="table table-striped custom-table">
      <thead>
        <tr>
          <th>Date</th>
          <th>Integration</th>
          <th>Duration</th>
          <th>No. of pulled events</th>
        </tr>
      </thead>
      <tbody>
        {imports.collection.map((i, idx) => {
          return (
            <ImportTableRow
              import={i}
              key={"kir-" + idx}
              integrations={props.integrations}
            />
          );
        })}
      </tbody>
    </table>
  ) : (
    <p>
      No data.{" "}
      <Link to="/integrations">Go to integrations</Link>, and
      synchronize the calendar events data.
    </p>
  );

  const importsPagination =
    imports.collection.length && imports.pagination.pageCount > 1 ? (
      <Pagination
        total={imports.pagination.count}
        currentPage={imports.pagination.page}
        itemsPerPage={imports.ipp}
        onPageChange={props.fetchImports}
        getPageUrl={() => ""}
      />
    ) : null;

  const totalImportsInfo = imports.total
    ? "(" + imports.total + " in total)"
    : null;

  const importsLoading = imports.fetch ? <LoadingIndicator /> : null;

  return (
    <div className="row">
      <div className="col-lg-6">
        <div className="card-box">
          <div className="card-title">
            Recent integration syncs {totalImportsInfo}
          </div>
          {importsTable}
          {importsPagination}
          {importsLoading}
        </div>
      </div>
    </div>
  );
};

Imports.propTypes = {
  imports: PropTypes.object.isRequired,
  integrations: PropTypes.array.isRequired,
  fetchImports: PropTypes.func.isRequired,
};

export default Imports;
