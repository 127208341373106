/**
 * Created by piotr.pozniak@thebeaverhead.com on 15/12/2020.
 */

import React, { useContext, useMemo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import InpicDate from "./InpicDate";
import CardDateFormatter from "./CardDateFormatter";
import CardLocationFormatter from "./CardLocationFormatter";
import EventBadges from "../EventBadges";
import EventCTA from "../EventsList/EventCTA";
import CardEventHeader from "./CardEventHeader";
import WidgetSettingsContext from "../../../../contexts/WidgetSettingsContext";

const CardEvent = (props) => {
  const {
    event,
    settings,
    showCardCta,
    selectedDateTimeFormat,
    showImageContainer,
  } = props;
  const widgetSettings = useContext(WidgetSettingsContext);

  /**
   *
   * @param e
   */
  const onCardClick = (e) => {
    e.preventDefault();

    if (props.triggerCTAOnCardClick) {
      const primaryCtaAction = widgetSettings[`cPopupCTAAction1st`];
      const openLinkInNewTab = widgetSettings[`openLinksNewTab`];
      const primaryCtaLink = event.ctas[primaryCtaAction] || null;

      // open primaryCtaLink.url link in new tab if openLinkInNewTab is true
      if (openLinkInNewTab) {
        window.open(primaryCtaLink.url, "_blank");
        return;
      } else {
        // open primaryCtaLink.url link in same tab
        window.location.href = primaryCtaLink.url;
      }

      return;
    }

    if (props.onSelectedEvent) {
      props.onSelectedEvent(props.event);
    }
  };

  const banner =
    props.showImage && event.cover_image ? (
      <img src={event.cover_image} />
    ) : null;

  const recurringBadges =
    (props.showRecurringBadge && event.recurrence_description) ||
    props.showFeaturedBadge ? (
      <EventBadges
        showRecurringBadge={
          props.showRecurringBadge && event.recurrence_description
            ? true
            : false
        }
        showFeaturedBadge={props.showFeaturedBadge && event.is_featured}
        location={props.badgesLocation}
      />
    ) : null;

  const descriptionElement = props.showDescription ? (
    <p
      className="line-clamp"
      dangerouslySetInnerHTML={{ __html: event.description }}
    ></p>
  ) : null;

  const summaryElement = props.showSummary ? (
    <p
      className="line-clamp"
      dangerouslySetInnerHTML={{ __html: event.summary }}
    ></p>
  ) : null;

  const cardCTAElement = showCardCta ? (
    <EventCTA
      event={event}
      settingsPrefix={"cCard"}
      className={classNames("dce--card-details-item dce--card--cta")}
      buttonClassName={classNames("dce--event-card--cta-btn", {
        "dce--event-card--cta-btn-auto-width": !props.cCardCtaButtonFullWidth,
      })}
    />
  ) : null;

  const title = props.showTitle ? <h2>{event.name}</h2> : null;

  const date = props.showDate ? (
    <CardDateFormatter
      startTime={event.start_time}
      endTime={event.end_time}
      showWeekDay={props.showWeekDay}
      weekDayFormatter={props.weekDayFormatter}
      allDayEvent={event.allDay}
      selectedDateTimeFormat={selectedDateTimeFormat}
    />
  ) : null;

  const location = props.showLocation ? (
    <CardLocationFormatter event={event} />
  ) : null;

  const inlineStyle = {};

  if (props.useEventAccent && event.accentColor) {
    inlineStyle.backgroundColor = event.accentColor;
  }

  const showBadgesInImage =
    (props.showRecurringBadge || props.showFeaturedBadge) &&
    ["image-top", "image-bottom"].indexOf(props.badgesLocation) >= 0;

  const cardContent =
    title ||
    date ||
    location ||
    descriptionElement ||
    summaryElement ||
    cardCTAElement ||
    (recurringBadges && !showBadgesInImage) ? (
      <div
        className={classNames("card-view--card-content", {
          "dc-flex-1": props.badgesLocation === "title-bottom",
        })}
      >
        {recurringBadges && !showBadgesInImage ? recurringBadges : null}
        <div className={"card-view--card-content-body"}>
          {title}
          {date}
          {location}
          {descriptionElement}
          {summaryElement}
          {cardCTAElement}
        </div>
      </div>
    ) : (
      false
    );

  const cardHeader = showImageContainer ? (
    <CardEventHeader
      showBadgesInImage={showBadgesInImage}
      recurringBadges={recurringBadges}
      inlineStyle={inlineStyle}
      banner={banner}
      event={event}
      inpicDatePlacement={props.inpicDatePlacement}
    />
  ) : null;

  return (
    <div
      className={classNames(
        "card-view--card",
        "dc-flex-1",
        `card-view--card_hover-effect--${props.hoverEffect}`,
        {
          "card-view--card_cursor_pointer":
            props.triggerCTAOnCardClick || props.onSelectedEvent ? true : false,
          "dc-flex-col":
            props.badgesLocation === "title-bottom" && !props.showAsList,
        }
      )}
      onMouseUp={onCardClick}
    >
      {cardHeader}
      {cardContent}
      <div className={"card_hover-effect--accent-simple"} style={inlineStyle} />
      <div
        className={classNames({
          "card-view--card_permanent-accent": props.showAccentOnCard,
        })}
        style={inlineStyle}
      />
      <div
        className={"card_hover-effect--accent-expandable"}
        style={inlineStyle}
      />
    </div>
  );
};

CardEvent.defaultProps = {
  hoverEffect: "zoom",
  showWeekDay: false,
  weekDayFormatter: "",
  showImageContainer: true,
};

CardEvent.propTypes = {
  event: PropTypes.object.isRequired,
  onSelectedEvent: PropTypes.func,
  inpicDatePlacement: PropTypes.string,
  showDate: PropTypes.bool,
  showTitle: PropTypes.bool,
  showLocation: PropTypes.bool,
  showDescription: PropTypes.bool,
  showSummary: PropTypes.bool,
  hoverEffect: PropTypes.string,
  showWeekDay: PropTypes.bool,
  weekDayFormatter: PropTypes.string,
  useEventAccent: PropTypes.bool,
  showImage: PropTypes.bool,
  showRecurringBadge: PropTypes.bool,
  showFeaturedBadge: PropTypes.bool,
  badgesLocation: PropTypes.string,
  showCardCta: PropTypes.bool,
  showAsList: PropTypes.bool,
  showImageContainer: PropTypes.bool,
  cardCtaButtonWidth: PropTypes.bool,
  triggerCTAOnCardClick: PropTypes.bool,
  showAccentOnCard: PropTypes.bool,
};
export default CardEvent;
