/**
 * Created by piotr.pozniak@thebeaverhead.com on 28/07/2022
 */

import React, { useCallback } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const PublishDropdown = (props) => {
  const { available, disabled } = props;

  /**
   *
   * @type {(function(*): void)|*}
   */
  const onClick = useCallback(
    (e) => {
      e.preventDefault();
      props.onChangeStatus();
    },
    [available, props.onChangeStatus]
  );

  const icon = props.customIcon || (
    <i
      className={classNames("material-icons ", {
        "text-success": available,
        "text-secondary": !available,
      })}
    >
      adjust
    </i>
  );
  return (
    <div className="dropdown action-label">
      <a
        className="btn btn-white btn-sm btn-rounded dropdown-toggle"
        href={!disabled ? "#" : null}
        data-toggle={!disabled ? "dropdown" : ""}
        aria-expanded="false"
      >
        {icon}
        {available ? props.activeSummaryLabel : props.inactiveSummaryLabel}
      </a>

      <div className="dropdown-menu dropdown-menu-right ">
        <a
          href="#"
          className="dropdown-item d-flex text-success align-items-center"
          onClick={onClick}
        >
          <i className="material-icons text-success icon-sm">adjust</i>{" "}
          {props.activeLabel}
        </a>
        <a
          href="#"
          className="dropdown-item d-flex align-items-center"
          onClick={onClick}
        >
          <i className="material-icons text-secondary icon-sm">adjust</i>{" "}
          {props.inactiveLabel}
        </a>
      </div>
    </div>
  );
};

PublishDropdown.defaultProps = {
  activeLabel: "Published",
  inactiveLabel: "Unpublished",
  activeSummaryLabel: "Published",
  inactiveSummaryLabel: "Unpublished",
};

PublishDropdown.propTypes = {
  available: PropTypes.bool,
  disabled: PropTypes.bool,
  onChangeStatus: PropTypes.func.isRequired,
  customIcon: PropTypes.object,
  activeLabel: PropTypes.string,
  inactiveLabel: PropTypes.string,
  activeSummaryLabel: PropTypes.string,
  inactiveSummaryLabel: PropTypes.string,
};

export default PublishDropdown;
