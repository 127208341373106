/**
 * Created by piotr.pozniak@thebeaverhead.com on 05/10/2023
 */

import React, { useCallback } from "react";
import PropTypes from "prop-types";
import InstallExpandableTab from "../../../component/WidgetSettings/InstallExpandalbleTab";
import AdvancedEmbed from "./AdvancedEmbed";
import QuickEmbed from "./QuickEmbed";
import WixEmbed from "./WixEmbed";
import { useAppStore } from "../../../../../hooks/redux/app";

const dismissableMessage =
  "Sometimes, new embeds do not show up in on builder pages until published. We suggest using a Test Page or Private Mode to set things up. Once things are set as you want them, you can update the actual page or make your private page public.";

const GenericWidgetEmbed = ({
  widgetType,
  widgetTypeName,
  slug,
  uuid,
  onChangeStatus,
  isAvailable,
}) => {
  const { dismissedAlerts } = useAppStore();
  const dismissableMessageId = `embed_warning-${widgetType}`;
  const hasEmbedInfo =
    !dismissedAlerts.collection.includes(dismissableMessageId);

  /**
   *
   * @type {(function(*): void)|*}
   */
  const onChange = useCallback(
    (e) => {
      e.preventDefault();
      onChangeStatus("available")(e);
    },
    [onChangeStatus]
  );

  const unpublishedWarning = (
    <div
      className={
        "alert alert-warning fade show d-flex align-items-center flex-column p-2"
      }
    >
      This widget is unpublished. Publish your widget first.
      <button className=" btn btn-primary btn-sm m-2" onClick={onChange}>
        Publish
      </button>
    </div>
  );

  const advancedSetupTab = isAvailable ? (
    <AdvancedEmbed
      uuid={uuid}
      widgetType={widgetType}
      slug={slug}
      widgetTypeName={widgetTypeName}
      dismissableMessage={dismissableMessage}
      dismissableMessageId={dismissableMessageId}
    />
  ) : (
    unpublishedWarning
  );
  const quickSetupTab = isAvailable ? (
    <QuickEmbed
      uuid={uuid}
      widgetType={widgetType}
      slug={slug}
      widgetTypeName={widgetTypeName}
    />
  ) : (
    unpublishedWarning
  );

  const wixSetupTab = isAvailable ? (
    <WixEmbed
      uuid={uuid}
      widgetType={widgetType}
      widgetTypeName={widgetTypeName}
    />
  ) : (
    unpublishedWarning
  );

  return (
    <React.Fragment>
      <InstallExpandableTab
        title="Embed on website"
        icon="code"
        key={"it-as"}
        subheader={
          hasEmbedInfo && (
            <span className={"d-flex"}>
              <i className={"material-icons text-info p-0 m-0"}>info</i>
              <span className={"m-1"}>
                Read instructions before installing&nbsp;👇
              </span>
            </span>
          )
        }
      >
        {advancedSetupTab}
      </InstallExpandableTab>
      <InstallExpandableTab title="Embed on Wix" icon="schedule" key={"it-wix"}>
        {wixSetupTab}
      </InstallExpandableTab>
      <InstallExpandableTab
        title="Quick website embed"
        icon="science"
        key={"it-qs"}
        subheader={
          <span className={"d-flex"}>
            <i className={"material-icons text-warning p-0 m-0"}>warning</i>
            <span className={"m-1"}>Not recommended</span>
          </span>
        }
      >
        {quickSetupTab}
      </InstallExpandableTab>
    </React.Fragment>
  );
};

GenericWidgetEmbed.defaultProps = {};

GenericWidgetEmbed.propTypes = {
  uuid: PropTypes.string.isRequired,
  widgetType: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  isAvailable: PropTypes.bool.isRequired,
  onChangeStatus: PropTypes.func.isRequired,
};

export default GenericWidgetEmbed;
