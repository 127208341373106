/**
 * Created by piotr.pozniak@thebeaverhead.com  on 06/04/2022
 */

import React, { useContext } from "react";
import PropTypes from "prop-types";
import LeftColumn from "./LeftColumn";
import RightColumn from "./RightColumn";
import classNames from "classnames";
import WidgetSettingsContext from "../../../../contexts/WidgetSettingsContext";

const Row = (props) => {
  const widgetSettingsContext = useContext(WidgetSettingsContext);

  const showPopup = Number.parseInt(widgetSettingsContext["gPopupShow"]) === 1;

  /**
   *
   * @param e
   */
  const onCardClick = (e) => {
    e.preventDefault();

    if (props.onSelectedEvent) {
      props.onSelectedEvent(props.item);
    }
  };

  return (
    <div
      className={classNames("dce--two-columns__row", {
        "cursor-pointer": showPopup,
      })}
      onMouseUp={onCardClick}
    >
      <LeftColumn item={props.item} />
      <RightColumn item={props.item} />
    </div>
  );
};

Row.defaultProps = {};

Row.propTypes = {
  item: PropTypes.object.isRequired,
  showLeader: PropTypes.bool,
  showContactUs: PropTypes.bool,
  showDescription: PropTypes.bool,
  onSelectedEvent: PropTypes.func,
};

export default Row;
