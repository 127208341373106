/**
 * Created by piotr.pozniak@thebeaverhead.com on 15/07/2022
 */

import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const Location = (props) => {
  const { location, display_location } = props.event;
  const { t } = useTranslation();

  const isVirtualEvent = 0;

  const locationAddress = display_location ? (
    <React.Fragment>
      {location.line_1 ? <span>{location.line_1} </span> : null}
      {location.line_2 ? <span>{location.line_2}</span> : null}
    </React.Fragment>
  ) : null;

  const locationName = isVirtualEvent ? (
    <span className={"dce-detailed-list--row-content-details-location-label"}>
      <i className={"material-icons"} aria-hidden={true}>
        wifi
      </i>{" "}
      {t("Virtual Event")}
    </span>
  ) : (
    <span className={"dce-detailed-list--row-content-details-location-label"}>
      {display_location}
    </span>
  );

  return (
    <div>
      {locationName} {locationAddress}
    </div>
  );
};

Location.defaultProps = {};

Location.propTypes = {};

export default Location;
