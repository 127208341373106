/**
 * Created by piotr.pozniak@thebeaverhead.com on 28/09/2023
 */

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import ApiError from "../ApiError";
import LoadingIndicator from "../../../calendar/components/LoadingIndicator";
import { updateJquery } from "../../tools";
import NotificationPopup from "../NotificationPopup";
import { formatError } from "../../../../helpers/errors";
import useNotificationToast from "../../hooks/useNotificationToastHook";
import { useHistory } from "react-router-dom";
import { useWidgetsStore } from "../../../../hooks/redux/widgets";
import { useWidgetStore } from "../../../../hooks/redux/widget";
import TableRow from "./TableRow";
import { getWidgetCurrentData } from "../../../../actions/widget";
import { useCurrentOrganizationStore } from "../../../../hooks/redux/currentOrganization";

const WidgetsIndexTable = (props) => {
  const history = useHistory();
  const { newWidgetUrl, widgetType, featuresCheck, nameComponent } = props;

  const { addNotification } = useNotificationToast();
  const { currentOrganization } = useCurrentOrganizationStore();
  const { widget, updateWidget, setWidget, deleteWidget } = useWidgetStore();
  const { widgets, fetchWidgets } = useWidgetsStore();

  useEffect(() => {
    fetchWidgets(widgetType);
  }, []);

  useEffect(() => {
    /**
     * If no groups available, navigate to new group page.
     * Handle back button so user does not enter an infinite loop.
     */
    if (widgets.fetchSuccess && !widgets.collection.length) {
      if (history.action === "POP" && props) {
        history.go(-1);
      } else {
        history.push(newWidgetUrl);
      }
    }
    updateJquery();
  }, [widgets.fetchSuccess]);

  useEffect(() => {
    if (widget.updateError) {
      addNotification({
        content: (props) => (
          <NotificationPopup {...props}>
            <p className="notification-title">The following error occurred:</p>
            {formatError(widget.updateError).join("")}
          </NotificationPopup>
        ),
      });
    }
  }, [widget.updateError]);

  const loading = widgets.fetch || widgets.delete || widgets.sync;

  const loadingIndicator = loading ? <LoadingIndicator /> : null;

  const onChangeStatus = async (widget) => {
    const widgetData = getWidgetCurrentData({ ...widget });
    widgetData.available = widget.available ? 0 : 1;

    const issues = featuresCheck(widgetData, currentOrganization);
    if (issues.length) {
      addNotification({
        content: (props) => (
          <NotificationPopup {...props}>
            <p className="notification-title">
              <i className={"material-icons"}>warning</i> You cannot publish
              this widget widget because:
            </p>
            <ul className={"notification-message pl-4"}>
              {issues.map((i, idx) => (
                <li key={`per-${idx}`}>{i}</li>
              ))}
            </ul>
          </NotificationPopup>
        ),
      });

      return false;
    }
    await updateWidget(widget.uuid, widgetData);
    setWidget(null);
  };

  /**
   *
   * @param widget
   * TODO
   */
  const onClone = async (widget) => {
    const widgetData = getWidgetCurrentData({ ...widget });
    widgetData.uuid = null;
    widgetData.available = 0;
    widgetData.name = "Copy of " + widgetData.name;

    await updateWidget(null, widgetData);
    await setWidget(null);
  };

  /**
   *
   */
  const onDelete = (widget) => {
    if (
      window.confirm(
        "Are you sure you want to delete widget " +
          widget.name +
          "? Changes are irreversible!"
      )
    ) {
      deleteWidget(widget.uuid);
    }
  };

  const widgetRows = widgets.collection.map((item, idx) => {
    return (
      <TableRow
        disabled={loading}
        key={idx}
        widget={item}
        onChangeStatus={onChangeStatus}
        onDelete={onDelete}
        widgetEditUUID={widgets.updateWidgetUUID}
        onClone={onClone}
        nameComponent={props.nameComponent}
        shareableUrl={props.shareableUrl}
      />
    );
  });

  const widgetsData = widgets.collection.length ? (
    <table className="table table-striped custom-table">
      <thead>
        <tr>
          <th width={"20px"}></th>
          <th width="30%">Name</th>
          <th width="15%">Integration</th>
          <th width="20%">Template</th>
          <th width="10%">Status</th>
          <th width="10%">Last edited</th>
          <th className="text-right">Action</th>
        </tr>
      </thead>
      <tbody>{widgetRows}</tbody>
    </table>
  ) : (
    loadingIndicator
  );

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="table-responsive min-height-50">
          {widgetsData}

          <ApiError
            error={widgets.deleteError}
            defaultErrorMessage={
              "Could not delete the domain. Please try again"
            }
          />
        </div>
      </div>
    </div>
  );
};

WidgetsIndexTable.defaultProps = {};

WidgetsIndexTable.propTypes = {
  widgetType: PropTypes.string.isRequired,
  featuresCheck: PropTypes.func.isRequired,
  nameComponent: PropTypes.func.isRequired,
  shareableUrl: PropTypes.func.isRequired,
  newWidgetUrl: PropTypes.string.isRequired,
};

export default WidgetsIndexTable;
