/**
 *
 * @param job
 * @returns {number}
 */
export const syncDurationInSeconds = (job) => {
  let durationInSeconds = 0;

  // convert job.fetched to unix timestamp considering timezone offset
  let fetched = new Date(job.fetched);

  if (job.completed) {
    // calculate duration from created to completed using Date objects
    const completed = new Date(job.completed);
    durationInSeconds = completed - fetched;
  }

  if (job.failed) {
    // calculate duration from created to failed using Date objects
    const failed = new Date(job.failed);
    durationInSeconds = failed - fetched;
  }

  if (job.fetched && !job.failed && !job.completed) {
    // calculate duration from created to now using Date objects
    const now = new Date();
    const utcOffset = now.getTimezoneOffset() * 60 * 1000;
    let _fetched = new Date(fetched.getTime());

    durationInSeconds = now - _fetched;
  }

  return durationInSeconds / 1000;
};
