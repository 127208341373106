/**
 * Created by piotr.pozniak@thebeaverhead.com on 27/11/2023
 */

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import ModalTemplate from "./ModalTemplate";
import ApiError from "../component/ApiError";
import { useAppStore } from "../../../hooks/redux/app";
import { useSubscriptionStore } from "../../../hooks/redux/subscription";
import Form from "./CancelSubscription/Form";
import { useUserStore } from "../../../hooks/redux/user";

const CancelSubscriptionModal = (props) => {
  const { clearErrors } = useAppStore();
  const { checkSession } = useUserStore();
  const { subscription } = useSubscriptionStore();

  const onClose = async () => {
    await clearErrors();
    props.history.push("/billing/");
  };

  useEffect(() => {
    if (subscription.deleteSuccess) {
      checkSession();

      onClose();
    }
  }, [subscription.deleteSuccess]);

  return (
    <ModalTemplate
      id={"cancel-subscription-modal"}
      header={"Cancel subscription"}
      size={"lg"}
      onClose={onClose}
    >
      <div className={""}>
        <div className="modal-dialog">
          <div className="modal-content modal-lg">
            <div className="modal-header"></div>
            <div className="modal-body">
              <Form />
            </div>
          </div>
        </div>
      </div>
    </ModalTemplate>
  );
};

CancelSubscriptionModal.defaultProps = {};

CancelSubscriptionModal.propTypes = {};

export default CancelSubscriptionModal;
