/**
 * Created by piotr.pozniak@thebeaverhead.com on 04/04/2024
 */

import React from "react";
import PropTypes from "prop-types";
import ShareButton from "./ShareButton";

const ShareButtonX = ({ url, title, text }) => {
  const xShareUrl = `https://twitter.com/intent/tweet?url=${url}&text=${title}`;

  return <ShareButton url={xShareUrl} title={title} text={text} type={"x"} />;
};

ShareButtonX.defaultProps = {};

ShareButtonX.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
};

export default ShareButtonX;
