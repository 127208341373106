/**
 * Created by piotr.pozniak@thebeaverhead.com on 14/06/2019.
 */

import React, { useContext, useCallback } from "react";
import PropTypes from "prop-types";
import IntegrationContext from "../../../../../contexts/IntegrationContext";
import { isCTAllowed } from "../../../../../builder";
import RadioInput from "../../../component/WidgetSettings/RadioInput";
import { useFiltersStore } from "../../../../../hooks/redux/filters";
import { IT, PLANS } from "../../../../../consts";
import ExpandableAlert from "../../../component/ExpandableAlert";
import ItemsFiltering from "./ItemsFiltering";
import { useCurrentOrganizationStore } from "../../../../../hooks/redux/currentOrganization";
import { hasSubscription } from "../../../helpers/organization";
import Accordion from "../../../component/WidgetSettings/Acordion";

let allowedPlans = [];

const permissionsTooltipProps = {
  "data-toggle": "tooltip",
  "data-placement": "bottom",
  "data-trigger": "hover",
  title:
    "You do not have the permission for this filter selection. Upgrade Now.",
};

const FiltersSection = (props) => {
  const { currentOrganization } = useCurrentOrganizationStore();

  const integrationStrategy = useContext(IntegrationContext);

  const { filters } = useFiltersStore();
  const { widgetModel } = props;

  const subscription = hasSubscription(currentOrganization);

  const isPrivateEventsAllowed =
    widgetModel &&
    widgetModel.integration &&
    (widgetModel.integration.type !== IT.ccb ||
      (widgetModel.integration.type === IT.ccb &&
        subscription &&
        [PLANS.medium, PLANS.highest].indexOf(subscription.code) >= 0))
      ? true
      : false;

  /**
   *
   * @type {(function(*): void)|*}
   */
  const onUpdateFilters = useCallback(
    (filters) => {
      props.onUpdateFilterSettings("filters", filters);
    },
    [widgetModel, props.onUpdateFilterSettings]
  );

  /**
   *
   * @type {function(*): function(*): void}
   */
  const onFilteringCheckboxChange = useCallback(
    (field) => (e) => {
      props.onUpdateField(field, !(props.widgetModel[field] * 1) ? 1 : 0);
    },
    [props.widgetModel]
  );

  const showInfoAlert = integrationStrategy.filterLabelCTA ? (
    <ExpandableAlert
      errorLevel={"info"}
      header={"How to use filters below?"}
      body={integrationStrategy.filterLabelCTA}
    />
  ) : null;

  const mergeRecurringEvents =
    widgetModel.integration &&
    isCTAllowed(props.widgetModel.integration.type, [
      IT.ccb,
      IT.pco,
      IT.google,
      IT.breeze,
    ]) ? (
      <RadioInput
        fieldName={"group_recurring_events"}
        label={"Merge recurring events"}
        onChange={onFilteringCheckboxChange}
        checked={widgetModel.group_recurring_events * 1}
      />
    ) : null;

  const privateEventsError =
    widgetModel.show_private_events * 1 && !props.integrationModel.sync_private
      ? integrationStrategy.privateEventsSettingsWarning(
          widgetModel.integration.uuid
        )
      : null;

  const privateEventsWarning =
    widgetModel.show_private_events * 1 ? (
      <ExpandableAlert
        icon={null}
        header={"Recommended helpful tip:"}
        body={
          <span>
            We recommend using the events filtering option available below.
            Publishing your calendar without events filtering will reveal{" "}
            <strong>all</strong> the events!
          </span>
        }
        errorLevel={"info"}
      ></ExpandableAlert>
    ) : null;

  const privateEventsControl =
    widgetModel.uuid && integrationStrategy.privateEventsControl ? (
      <React.Fragment>
        <RadioInput
          fieldName={"show_private_events"}
          label={integrationStrategy.privateEventsControl.toggleButtonLabel}
          onChange={onFilteringCheckboxChange}
          checked={widgetModel.show_private_events * 1}
          tooltip={
            integrationStrategy.privateEventsControl.privateEventsTooltip
          }
          isPremium={!isPrivateEventsAllowed}
        />
        {privateEventsError}
        {privateEventsWarning}
      </React.Fragment>
    ) : null;

  const groupSpecificFiltering =
    widgetModel.uuid && widgetModel.integration.type === IT.pco ? (
      <React.Fragment>
        <RadioInput
          fieldName={"has_group_events_included"}
          label={"Include group-specific events"}
          onChange={onFilteringCheckboxChange}
          checked={widgetModel.has_group_events_included * 1}
          isPremium={!isPrivateEventsAllowed}
        />
        {/*{privateEventsError}
        {privateEventsWarning}*/}
      </React.Fragment>
    ) : null;

  const widgetFilters = (
    <ItemsFiltering
      widgetModel={widgetModel}
      integrationModel={props.integrationModel}
      onUpdateFilters={onUpdateFilters}
    />
  );

  return (
    <Accordion title={"Filtering"} keepExpanded={true}>
      <div
      /*allowedPlans={allowedPlans}
        user={props.user}
        CTAMessage={
          "You can limit events by selecting specific filters. Upgrade your plan to unlock this functionality. "
        }*/
      >
        {showInfoAlert}
        {widgetFilters}
      </div>
    </Accordion>
  );
};

FiltersSection.propTypes = {
  widgetModel: PropTypes.object.isRequired,
  integrationModel: PropTypes.object.isRequired,
  onUpdateField: PropTypes.func.isRequired,
  onUpdateFilterSettings: PropTypes.func.isRequired,
};

export default FiltersSection;
