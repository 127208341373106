/**
 * Created by piotr.pozniak@thebeaverhead.com on 31/07/2024
 */

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const ProgressCircle = ({ size }) => {
  return (
    <div className="app-loader">
      <progress
        className={classNames(
          "pure-material-progress-circular",
          `size-${size}`
        )}
      />
    </div>
  );
};

ProgressCircle.defaultProps = {
  size: "md",
};

ProgressCircle.propTypes = {
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
};

export default ProgressCircle;
