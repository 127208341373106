/**
 * Created by piotr.pozniak@thebeaverhead.com on 01/08/2024
 */

import React from "react";
import PropTypes from "prop-types";
import AttachIntegrationTemplate from "../../templates/AttachIntegrationTemplate";
import { WT } from "../../consts/widget";
import { signupSettingsValidator } from "../../../signups/tools";
import { IT, PLANS, ST } from "../../../../consts";

const AttachSignupIntegrationPage = (props) => {
  return (
    <AttachIntegrationTemplate
      title={"Sign-up Forms"}
      sidebarId={WT.signups}
      icon={"edit_note"}
      widgetDisplayName={"Sign-up Forms"}
      widgetType={WT.signups}
      widgetSettingsValidator={signupSettingsValidator}
      allowedIntegrations={[IT.pco, IT.ccb]}
    />
  );
};

AttachSignupIntegrationPage.defaultProps = {};

AttachSignupIntegrationPage.propTypes = {};

export default AttachSignupIntegrationPage;
