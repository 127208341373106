/**
 * Created by piotr.pozniak@thebeaverhead.com on 21/08/2021.
 */
import CalendarIntegrationStrategy from "./CalendarIntegrationStrategy";
import PrivateEventsSyncWarning from "./GoogleFilteringStrategy/PrivateEventsSyncWarning";
import { PLANS } from "../../../consts";
import FilterLabelCTA from "./GoogleFilteringStrategy/FilterLabelCTA";
import FeaturedFilterLabelCTA from "./BreezeFilteringStrategy/FeaturedFilterLabelCTA";

class GoogleStrategy extends CalendarIntegrationStrategy {
  availableFilters = ["calendar", "place", "tag"];

  filtersSettings = {
    calendar: {
      label: "Calendars",
      tooltipFeatured: {
        url: "https://help.display.church/en/articles/5815427-setup-design-featured-events",
        message: "Feature events for these calendars",
      },
    },
    place: {
      label: "Locations",
      tooltipFeatured: {
        url: "https://help.display.church/en/articles/5815427-setup-design-featured-events",
        message: "Feature events for these locations",
      },
    },
  };

  filterLabelCTA = FilterLabelCTA();
  featuredFilterLabelCTA = FeaturedFilterLabelCTA();

  filtersAllowedPlans = {
    calendar: [PLANS.free, PLANS.starter, PLANS.medium, PLANS.highest],
    place: [PLANS.starter, PLANS.medium, PLANS.highest],
    tag: [PLANS.free, PLANS.starter, PLANS.medium, PLANS.highest],
  };
  privateEventsSettingsWarning = PrivateEventsSyncWarning;

  featuredAllowedPlans = [PLANS.medium, PLANS.highest];

  /**
   *
   * @param data
   * @returns {{}}
   */
  filterRawDataToStateObject = (data) => {
    const filterObjects = this.availableFilters.reduce(
      (i, j) => ({ ...i, [j]: [] }),
      {}
    );
    data.map((filter) => {
      filter.value = filter.platform_metadata_id;
      filter.uuid = filter.platform_metadata_id;
      filter.label = this.filterRenderName(filter);

      filterObjects[filter.kind].push(filter);
    });

    return filterObjects;
  };

  /**
   *
   * @param data
   * @returns {*}
   */
  filterRawToDisplayObject = (data) => {
    return data.map((filter) => {
      filter.value = filter.platform_metadata_id;
      filter.uuid = filter.platform_metadata_id;
      filter.label = this.filterRenderName(filter);
      return filter;
    });
  };

  /**
   *
   * @param data
   * @param showInactive
   * @returns {*}
   */
  filterRenderName = (filter) => {
    switch (filter.kind) {
      case "calendar":
        return filter.data.summary + (filter.data.hidden ? " (hidden)" : "");
      case "place":
        return filter.data.displayName;

      case "tag":
        return filter.data.name;

      default:
        return filter.id;
    }
  };

  _filters = [
    {
      field: {
        value: "calendar",
        label: "Calendar",
      },
      allowedPlans: [PLANS.free, PLANS.starter, PLANS.medium, PLANS.highest],
    },
    {
      field: {
        value: "place",
        label: "Location",
      },
      allowedPlans: [PLANS.starter, PLANS.medium, PLANS.highest],
    },
    {
      field: {
        value: "tag",
        label: "Tag",
      },
      allowedPlans: [PLANS.free, PLANS.starter, PLANS.medium, PLANS.highest],
    },
  ];

  canUserFilter = {
    calendar: true,
    place: true,
    tag: true,
  };
}

export default GoogleStrategy;
