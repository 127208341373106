import { useEffect, useRef } from "react";

const useCloseModals = (callback) => {
  const onUnmount = useRef(null);

  onUnmount.current = callback;

  useEffect(() => {
    return () => {
      const backdrop = document.querySelector(".modal-backdrop");

      if (backdrop) {
        backdrop.remove();
      }
      document.getElementsByTagName("body")[0].className = "";
      onUnmount.current && onUnmount.current();
    };
  }, []);
};

export default useCloseModals;
