/**
 * Created by piotr.pozniak@thebeaverhead.com on 07/02/2023.
 */

import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as queueWorkersActions from "../../actions/queueWorkers";

export function useQueueWorkersStore() {
  const dispatch = useDispatch();
  const _queueWorkers = useSelector((store) => store.queueWorkers);

  const fetchQueueWorkers = useCallback(
    async () => await dispatch(queueWorkersActions.fetchQueueWorkers()),
    [dispatch]
  );

  const runWorker = useCallback(
    async (jobType) => await dispatch(queueWorkersActions.runWorker(jobType)),
    [dispatch]
  );

  const terminateWorker = useCallback(
    async (id) => await dispatch(queueWorkersActions.terminateWorker(id)),
    [dispatch]
  );

  return {
    queueWorkers: _queueWorkers,
    fetchQueueWorkers,
    runWorker,
    terminateWorker,
  };
}
