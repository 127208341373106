/**
 * Created by piotr.pozniak@thebeaverhead.com on 04/10/2023.
 */

import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { filterAvailableValuesForWidgetType } from "../../../../../builder";
import ExpandableAlert from "../../../component/ExpandableAlert";
import { useWidgetsStore } from "../../../../../hooks/redux/widgets";
import { useWidgetStore } from "../../../../../hooks/redux/widget";
import Accordion from "../../../component/WidgetSettings/Acordion";

const CloneSettings = (props) => {
  const { widgets } = useWidgetsStore();
  const { widget } = useWidgetStore();
  const [selectedWidget, setSelectedWidget] = useState(0);

  /**
   *
   * @param e
   */
  const onClone = (e) => {
    setSelectedWidget(e.target.value);
    const _widget = widgets.collection.find((i) => i.uuid == e.target.value);

    const integrationType =
      widget &&
      widget.model &&
      widget.model.integration &&
      widget.model.integration.type;

    const targetIntegrationType =
      _widget && _widget.integration && _widget.integration.type;

    if (_widget && _widget.widget_settings.design) {
      const settings = filterAvailableValuesForWidgetType(
        _widget.widget_settings.design,
        widget.model.builder.design.values
      );
      props.overwriteDesignProp(settings);
    }

    if (integrationType === targetIntegrationType) {
      const data = {
        filter_settings: {
          ...widget.model.filter_settings,
          ..._widget.filter_settings,
        },
      };
      props.overwriteFilterProp(data);
    }
  };

  /**
   *
   * @param e
   * @returns {boolean}
   */
  const onRestore = (e) => {
    e.preventDefault();
    const _widget = widgets.collection.find((i) => i.uuid == widget.model.uuid);

    const data = {
      filter_settings: {
        ..._widget.filter_settings,
      },
    };

    props.overwriteDesignProp(_widget.widget_settings.design);
    props.overwriteFilterProp(data);

    setSelectedWidget(0);
    document.getElementById("clone_widget_uuid").selectedIndex = 0;

    return false;
  };

  const widgetsOptions = useMemo(
    () =>
      widgets.collection.map((i, idx) => {
        if (i.uuid == widget.model.uuid) {
          return null;
        }

        return (
          <option key={i.uuid} value={i.uuid}>
            {i.name}
          </option>
        );
      }),
    [widgets.collection]
  );

  const isRestoreBtnVisible = useMemo(
    () => !(selectedWidget == 0),
    [selectedWidget]
  );

  const restoreButton = isRestoreBtnVisible ? (
    <div className="form-group">
      <div className="text-right">
        <button type="submit" className="btn btn-secondary" onClick={onRestore}>
          Restore
        </button>
      </div>
    </div>
  ) : null;

  return (
    <Accordion title={"Import Settings"}>
      <form action="#">
        <ExpandableAlert
          errorLevel={"info"}
          header={"How does it work?"}
          body={
            <span>
              This tool allows you to import <strong>all applicable</strong>{" "}
              settings from a selected widget and apply them to this widget. It
              will also change the behaviour settings.
            </span>
          }
        />

        <div className="form-group">
          <div className="col-md-xs">
            <select
              className={"form-control form-control-sm "}
              id="clone_widget_uuid"
              onChange={onClone}
            >
              <option value={0}>Select widget</option>
              {widgetsOptions}
            </select>
          </div>
        </div>
        {restoreButton}
      </form>
    </Accordion>
  );
};

CloneSettings.propTypes = {
  overwriteDesignProp: PropTypes.func.isRequired,
};

export default CloneSettings;
