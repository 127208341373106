/**
 * Created by piotr.pozniak@thebeaverhead.com on 05/04/2022
 */

import React from "react";
import PropTypes from "prop-types";
import { GT } from "../../../../consts";

const GroupTypeName = (props) => {
  let icon = "";
  let fullName = "";
  switch (props.type) {
    case GT.card:
      icon = "view_module";
      fullName = "Cards (grid)";
      break;

    case GT.twoColumn:
      icon = "pause";
      fullName = "Simple list";
      break;

    case GT.outlined:
      icon = "grid_on";
      fullName = "Outlined";
      break;
    case GT.mosaic:
      icon = "view_quilt";
      fullName = "Mosaic";
      break;
  }

  return (
    <span className={"groups--row--group-type"}>
      <i className="material-icons">{icon}</i> {fullName}
    </span>
  );
};

GroupTypeName.propTypes = {
  type: PropTypes.string.isRequired,
};

export default GroupTypeName;
