/**
 * Created by piotr.pozniak@thebeaverhead.com on 12/10/2021.
 */

import React from "react";
import PropTypes from "prop-types";

const GenericApiUser = (props) => {

  const {username} = props.integrationModel;

  return username;

};

GenericApiUser.propTypes = {
  integrationModel: PropTypes.shape({
    uuid: PropTypes.string,
    status: PropTypes.number,
    state: PropTypes.string,

  })
}

export default GenericApiUser;
