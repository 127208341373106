/**
 * Created by piotr.pozniak@thebeaverhead.com on 12/10/2021.
 */

import React from 'react';
import PropTypes from "prop-types";
import GenericApiUser from "./IntegrationUser/GenericApiUser";
import CCBApiUser from "./IntegrationUser/CCBApiUser";
import BreezeApiUser from "./IntegrationUser/BreezeApiUser";

const IntegrationToUser = {
  "ccb": CCBApiUser,
  "pco": GenericApiUser,
  "google": GenericApiUser,
  "breeze": BreezeApiUser,
}

const IntegrationUser = props => {

  const Button = IntegrationToUser[props.integrationModel.type] || GenericApiUser;

  return <Button integrationModel={props.integrationModel}/>;
}


IntegrationUser.propTypes = {
  integrationModel: PropTypes.shape({
    uuid: PropTypes.string,
    status: PropTypes.number,
    state: PropTypes.string,

  })
}

export default IntegrationUser;
