/**
 * Created by piotr.pozniak@thebeaverhead.com on 21/08/2018.
 */

import React from "react";

import { Link } from "react-router-dom";
import classnames from "classnames";
import PropTypes from "prop-types";

const SideMenuItem = (props) => {
  const beta = props.beta ? (
    <div
      className={"badge badge-primary design-tooltip"}
      data-toggle="tooltip"
      data-placement="top"
      title={"Experimental"}
    >
      BETA
    </div>
  ) : null;

  const icon = props.iconSvg ? (
    <img src={`/img/nav_icons/${props.iconSvg}`} />
  ) : (
    <i className={"material-icons"} aria-hidden="true">
      {props.icon}
    </i>
  );

  return (
    <li
      key={`sidebar-nav-${props.id}`}
      className={classnames("list-unstyled", props.className, {
        active: props.id == props.currentSidebarId || props.active,
        disabled: props.disabled,
      })}
    >
      <Link to={props.url} className={"d-inline-flex align-items-center w-100"}>
        <span className={"d-inline-flex align-items-center w-100"}>
          {icon} {props.label}
        </span>
        {beta}
      </Link>
    </li>
  );
};

SideMenuItem.propTypes = {
  id: PropTypes.string,
  currentSidebarId: PropTypes.string,
  icon: PropTypes.string,
  iconSvg: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  beta: PropTypes.bool,
  url: PropTypes.string,
};

SideMenuItem.defaultProps = {
  disabled: false,
};

export default SideMenuItem;
