/**
 * Created by piotr.pozniak@thebeaverhead.com on 01/08/2024.
 */
import PageTemplate from "../PageTemplate";
import BreadcrumbBar from "../../component/BreadcrumbBar";
import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import SidebarTab from "../../component/WidgetSettings/SidebarTab";
import MandatoryInformationForm from "../NewWidgetPageTemplate/MandatoryInformationForm";
import SuccessAlert from "../../component/SuccessAlert";
import ApiError from "../../component/ApiError";
import { useWidgetStore } from "../../../../hooks/redux/widget";
import { alertSettingsValidator, updateJquery } from "../../tools";
import { useIntegrationsStore } from "../../../../hooks/redux/integrations";
import { useHistory, useParams } from "react-router-dom";
import { store } from "../../store";
import { CircularProgress } from "@mui/material";

const AttachIntegrationTemplate = (props) => {
  const { widget, updateWidget, setWidget, fetchWidget } = useWidgetStore();

  const { integrations } = useIntegrationsStore();
  const history = useHistory();
  const params = useParams();

  const [state, setState] = useState({
    widgetModel: {},
    errors: {},
    initialized: false,
  });
  const loading = widget.update || widget.fetch || widget.delete;

  useEffect(() => {
    if (!state.initialized) {
      setState({ ...state, initialized: true });
    }
  }, []);

  useEffect(() => {
    if (state.initialized) {
      fetchWidget(props.widgetType, params.uuid);
    }
  }, [state.initialized]);

  useEffect(() => {
    if (widget.fetchSuccess) {
      setState({
        ...state,
        widgetModel: {
          ...widget.model,
        },
      });
    }
  }, [widget.fetchSuccess]);

  useEffect(() => {
    if (widget.fetchSuccess) {
      setTimeout(updateJquery, 200);
    }
  }, [widget.fetchSuccess, widget.fetchSuccess]);

  useEffect(() => {
    return () => setWidget(null);
  }, []);

  /**
   *
   * @param fieldName
   * @param value
   */
  const onUpdateField = useCallback(
    (fieldName, value) => {
      const data = {
        ...state,
      };

      data.widgetModel[fieldName] = value;

      data.errors = props.widgetSettingsValidator(data.widgetModel);

      setState(data);
    },
    [state]
  );

  const onSubmit = useCallback(async () => {
    const data = { ...state.widgetModel };

    await updateWidget(state.widgetModel.uuid, data);

    const storeState = store.getState();

    if (storeState.widget.updateSuccess) {
      await setWidget(null);

      history.push(`/${props.widgetType}/${state.widgetModel.uuid}`);
    }
  }, [state]);

  const footer = !loading ? (
    <div>
      <SuccessAlert>
        {widget.updateSuccess ? "Your changes have been saved." : null}
      </SuccessAlert>
      <ApiError
        error={widget.updateError}
        defaultErrorMessage={"Could not save your settings. Please try again"}
      />
      <div className="d-flex justify-content-end gap-xs">
        {loading ? (
          <div />
        ) : (
          <button
            disabled={loading}
            type="submit"
            className="btn btn-primary"
            onClick={onSubmit}
          >
            Update
          </button>
        )}
      </div>
    </div>
  ) : null;

  const setupTab = (
    <SidebarTab id="setup_tab" show={true} footer={footer}>
      <MandatoryInformationForm
        widgetModel={state.widgetModel || {}}
        widgetType={props.widgetType}
        onUpdateField={onUpdateField}
        disabled={loading}
        formErrors={state.errors}
        integrations={integrations.collection}
        allowedIntegrations={props.allowedIntegrations}
        showSummary={false}
        expanded={true}
      />
    </SidebarTab>
  );

  return (
    <PageTemplate title={null} sidebarId={props.sidebarId}>
      <div className="page-wrapper page-wrapper-with-breadcrumbs">
        <div className="content container-fluid">
          <BreadcrumbBar
            title={props.title}
            icon={props.icon}
            sidebarId={props.sidebarId}
          />
        </div>
        <div className="chat-main-row margin-small">
          <div className="chat-main-wrapper">
            <div className="col-xs-12 message-view task-view">
              <div className="chat-window">
                <div className="chat-contents calendar-background">
                  <div className="chat-content-wrap">
                    <div className="chat-wrap-inner">
                      <div className="chat-box">
                        <div className="chats">
                          <div className="calendar-preview-container">
                            <div className="content container-fluid">
                              <div className="row">
                                <div className="col-sm-12">
                                  <h4 className="page-title">&nbsp;</h4>
                                </div>
                              </div>
                              <div className="card-box">
                                <h2>
                                  Attach integration to your{" "}
                                  {props.widgetDisplayName} widget
                                </h2>
                                <p>
                                  Your {props.widgetDisplayName} does not have
                                  an integration attached because you
                                  disconnected the integration.
                                </p>

                                <p>
                                  <strong>Option 1:</strong> Reconnect with your
                                  previous integration via the Integrations page
                                  using the same account details. Then,
                                  re-attach this {props.widgetDisplayName} to
                                  that integration. If you use the same account
                                  details, all your previous{" "}
                                  {props.widgetDisplayName} filters and design
                                  settings will be maintained.
                                </p>
                                <p>
                                  <strong>Option 2:</strong> Reconnect with your
                                  previous integration via the Integrations page
                                  using different account details OR select a
                                  different integration. Then, re-attach this
                                  {props.widgetDisplayName} to that integration.
                                  In either case, all your previous{" "}
                                  {props.widgetDisplayName} filters and design
                                  settings will be removed.
                                </p>
                                <div className="row"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-lg-3 message-view chat-profile-view chat-sidebar"
              id="chat_sidebar"
            >
              <div className="chat-window video-window">
                <div className="fixed-header">
                  <ul className="nav nav-tabs nav-tabs-bottom">
                    {props.settingsTabs}
                  </ul>
                </div>
                <div className="tab-content chat-contents">{setupTab}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="sidebar-overlay" data-reff="#chat_sidebar" />
    </PageTemplate>
  );
};

AttachIntegrationTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  sidebarId: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  widgetDisplayName: PropTypes.string.isRequired,
  widgetType: PropTypes.string.isRequired,
  widgetSettingsValidator: PropTypes.func.isRequired,
};

export default AttachIntegrationTemplate;
