/**
 * Created by piotr.pozniak@thebeaverhead.com on 18/11/2022
 */

import React from "react";
import PropTypes from "prop-types";
import TableRow from "./TableRow";

const InvoicesTab = (props) => {
  const paymentRows = props.invoices.map((item, idx) => {
    return (
      <TableRow
        key={item.id}
        date={item.created}
        description={item.number}
        quote={item.total}
        link={item.invoice_pdf}
      />
    );
  });

  return (
    <div className={"tab-pane show active"} id={"invoices"}>
      <div className="table-responsive">
        <table className="table table-striped custom-table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Item</th>
              <th>Amount</th>
              <th>Receipt</th>
            </tr>
          </thead>
          <tbody>{paymentRows}</tbody>
        </table>
      </div>
    </div>
  );
};

InvoicesTab.defaultProps = {};

InvoicesTab.propTypes = {
  invoices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      date: PropTypes.number.isRequired,
      invoice_pdf: PropTypes.string.isRequired,
      number: PropTypes.string.isRequired,
      total: PropTypes.number.isRequired,
    })
  ),
};

export default InvoicesTab;
