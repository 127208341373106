/**
 * Created by piotr.pozniak@thebeaverhead.com on 26/07/2021.
 */

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useIntegrationStore } from "../../../../hooks/redux/integration";
import { useUserStore } from "../../../../hooks/redux/user";
import LoadingIndicator from "../../../calendar/components/LoadingIndicator";
import { openAuthWindow } from "../../consts/pco";
import ApiError from "../../component/ApiError";
import FormError from "../../component/FormError";
import { IntegrationStatus } from "../../consts/integrations";
import { PCOIntegrationAddress as address, IT } from "../../../../consts";
import Scopes from "./Scopes";
import WelcomeCard from "./WelcomeCard";
import OpenAIForm from "./OpenAIForm";
import classname from "classnames";
import appConfig from "../../../../appConfig";

export const PCOIntegrationHelp = () => {
  return (
    <p>
      To learn more about the Planning Center integration, please click on{" "}
      <a
        href={
          "https://help.display.church/en/articles/5669866-dce-integrations-how-to-set-them-up"
        }
        target="_blank"
      >
        this address
      </a>
      .
    </p>
  );
};

const PCOScopes = {
  people: {
    mandatory: true,
    label: "People",
    description:
      "We will pull account information to display your name on the integration card.",
    defaultOn: true,
  },
  calendar: {
    mandatory: false,
    label: "Calendar",
    description: "We will pull your events from PCO Calendar service.",
    defaultOn: true,
  },
  groups: {
    mandatory: false,
    label: "Groups",
    description: "We will pull your groups so you can filter events by groups.",
    defaultOn: false,
  },
  signups: {
    mandatory: false,
    label: "Registrations",
    description:
      "We will pull your public registration/signups from your Church Center, so you can embed your signup forms.",
    defaultOn: false,
  },
};

const PCOIntegrationForm = (props) => {
  const { integration, updateIntegration } = useIntegrationStore();
  const { update, updateError } = integration;
  const [showRedirectUrl, setShowRedirectUrl] = useState(false);
  const [isSignupsScopeEnabled, setIsSignupsScopeEnabled] = useState(false);

  const integrationModel =
    integration.model && integration.model.uuid ? integration.model : null;
  const [state, setState] = useState({
    nameError: null,

    showPrivateEventsInfo: integrationModel
      ? integrationModel.sync_private * 1
      : false,
  });

  const scopes = integrationModel
    ? integrationModel.integration_scopes
    : Object.keys(PCOScopes).map((i) => ({
        name: i,
        available: PCOScopes[i].defaultOn,
      }));

  const isNew = !integrationModel ? true : false;

  /**
   *
   */
  useEffect(() => {
    if (integrationModel) {
      const scope = integrationModel.integration_scopes.find(
        (i) => i.name === "signups"
      );

      if (scope && scope.available) {
        setIsSignupsScopeEnabled(true);
      }
    }
  }, [integrationModel]);

  /**
   *
   */
  useEffect(() => {
    if (
      integration.updateSuccess &&
      integrationModel.status === IntegrationStatus.reAuthRequired
    ) {
      openAuthWindow(integrationModel.auth_url);
      setShowRedirectUrl(true);
    }
  }, [integration.updateSuccess]);

  /**
   *
   * @type {(function(*, *): void)|*}
   */
  const onScopesUpdate = useCallback(
    (name, checked) => {
      if (name === "signups") {
        setIsSignupsScopeEnabled(checked);
      }
    },
    [isSignupsScopeEnabled]
  );

  /**
   *
   * @param e
   */
  const onSubmit = async (e) => {
    e.preventDefault();

    const name = "Planning Center Integration"; //document.querySelector("#integration_name").value;
    let nameError = null;
    let addressError = null;
    let addressUrl = null;
    const addressSubdomain = document.querySelector(
      "#integration_address"
    ).value;

    const available =
      integrationModel && integrationModel.uuid
        ? integrationModel.available
        : 1;

    if (
      isSignupsScopeEnabled &&
      (!addressSubdomain || !addressSubdomain.length)
    ) {
      addressError = "Please enter Church Center address. ";
    } else {
      addressUrl = address.scheme + addressSubdomain + address.host;
    }

    const integration_scopes = Object.keys(PCOScopes).map((scopeName) => {
      const scope = scopes.find((i) => i.name === scopeName);
      return {
        uuid: scope ? scope.uuid : undefined,
        name: scopeName,
        available: document.getElementById(`pco-scope-${scopeName}`).checked
          ? 1
          : 0,
      };
    });

    const data = {
      //...integrationModel,
      type: "pco",
      name: name,
      available: available,
      //sync_private: integrationModel.sync_private,
      integration_scopes,
      username: !isNew ? integrationModel.username : undefined,
      address: addressUrl,
    };

    setState({
      ...state,
      nameError,
      addressError,
    });

    if (!addressError) {
      await updateIntegration(isNew ? null : integrationModel.uuid, data);
    }
  };

  const onReauth = (e) => {};

  const loadingIndicator = update ? <LoadingIndicator /> : null;

  const isLoading = update;

  const authorize = isNew ? (
    <button
      className="btn btn-primary btn-lg"
      onClick={onSubmit}
      disabled={isLoading}
    >
      Integrate with PCO
    </button>
  ) : !showRedirectUrl ? (
    <button
      className="btn btn-primary btn-lg"
      onClick={onSubmit}
      disabled={isLoading}
    >
      Re-authenticate
    </button>
  ) : (
    <span>
      You were not redirected to PCO?{" "}
      <a href={integrationModel.auth_url}>Click here to finish the setup.</a>
    </span>
  );

  const openAIForm = !isNew ? <OpenAIForm /> : null;

  const CCAddressMandatory = isSignupsScopeEnabled ? (
    <span className="text-danger">*</span>
  ) : null;

  if (isSignupsScopeEnabled && integrationModel && integrationModel.address) {
    address.subdomain = integrationModel.address.split("://")[1].split(".")[0];
  }

  return (
    <React.Fragment>
      <div className={"col-xs-12 col-sm-12"}>
        <WelcomeCard
          helpArticleUrl={
            "https://help.display.church/en/articles/5669866-dce-integrations-how-to-set-them-up"
          }
          videoId={"c7f07218239849b9ae08402bbf4496d7"}
          integrationName={"Planning Center"}
          imageSrc={"/img/integrations_howto/howto_pco.png"}
        >
          <p>
            To synchronize with Planning Center, sign in to PCO with your
            account and grant {appConfig.displayName} access to the following
            services:
          </p>
          <ul>
            <li>
              <strong>People</strong> - we will pull account information to
              display your name on the integration card.
            </li>
            <li>
              <strong>Calendar</strong> - we will pull your events from PCO
              Calendar service.
            </li>
            <li>
              <strong>Groups (optional)</strong> - we will pull your groups so
              you can filter events by groups.
            </li>
            <li>
              <strong>Registrations (optional)</strong> - we will pull your
              public registration/signups from your Church Center, so you can
              embed your signup forms.
            </li>
          </ul>
        </WelcomeCard>
      </div>
      <div className={classname("col-xs-12", { "col-lg-6": !isNew })}>
        <div className={"card pt-sm-4 pl-sm-4 pr-sm-4"}>
          <form className={"card-body"}>
            <div className={"row"}>
              <div className="form-group col-xs-12 col-md-12">
                <h4>Integration settings</h4>
                <label>Church Center address {CCAddressMandatory}</label>

                <div className="integration-address-container">
                  <span className="integration-address-static">
                    {address.scheme}
                  </span>
                  <input
                    className={
                      "form-control integration-address-input " +
                      (state.addressError ? "is-invalid" : "")
                    }
                    id="integration_address"
                    type="text"
                    placeholder="church"
                    defaultValue={isNew ? "" : address.subdomain}
                    disabled={isLoading}
                  />
                  <span className="integration-address-static">
                    {address.host}
                  </span>
                </div>
              </div>

              <Scopes
                scopes={scopes}
                definitions={PCOScopes}
                idPrefix={"pco-scope-"}
                onScopeUpdate={onScopesUpdate}
              />
              <div className={"col-12"}>
                <FormError
                  errors={[
                    /*state.nameError,*/
                    state.addressError,
                  ]}
                />
              </div>
              <div className={"col-xs-12 col-md-12"}>
                <div className="m-t-20 text-center">
                  <div className="form-group d-inline-flex justify-content-center flex-column">
                    {/*{newSyncAlert}*/}
                    {authorize}
                  </div>
                </div>
              </div>
              <div className={"col-12"}>
                <ApiError
                  error={updateError}
                  defaultErrorMessage={
                    "Could not add Api User. Please try again"
                  }
                />

                <FormError errors={[state.nameError]} />
              </div>

              {loadingIndicator}
            </div>
          </form>
        </div>
      </div>
      {openAIForm}
    </React.Fragment>
  );
};

export default PCOIntegrationForm;
