/**
 *
 * @param field
 * @param value
 * @returns {function(*): *}
 */
export function updateDesignProp(values, path = "design") {
  return function (dispatch) {
    return dispatch({
      type: "WIDGET_DESIGN_PROP_UPDATE",
      payload: values,
      path,
    });
  };
}
/**
 *
 * @param field
 * @param value
 * @returns {function(*): *}
 */
export function overwriteDesignProp(values, path = "design") {
  return function (dispatch) {
    return dispatch({
      type: "WIDGET_DESIGN_PROP_OVERWRITE",
      payload: values,
      path,
    });
  };
}

export function overwriteFilterProp(values) {
  return function (dispatch) {
    return dispatch({
      type: "WIDGET_FILTER_PROP_OVERWRITE",
      payload: values,
    });
  };
}
