/**
 * Created by piotr.pozniak@thebeaverhead.com on 05/07/2022
 */

import React, { useContext } from "react";
import PropTypes from "prop-types";
import Row from "./Row";

import { Primitives as GroupPrimitives } from "../primitives";
import WidgetSettingsContext from "../../../../contexts/WidgetSettingsContext";

const Primitives = (props) => {
  const { group } = props;
  const widgetSettingsContext = useContext(WidgetSettingsContext);

  const primitives = GroupPrimitives.filter(
    (i) =>
      Number.parseInt(
        widgetSettingsContext[`gPopupShow${i.visibilitySetting}`]
      ) === 1
  )
    .map((i, idx) => {
      const val = group.primitives[i.visibilitySetting];

      if (!val) {
        return null;
      }

      return (
        <Row title={i.label} key={`gmp-${group.slug}-${idx}`}>
          <p className={"p"}>{val}</p>
        </Row>
      );
    })
    .filter((i) => i);

  return primitives.length ? (
    <div className={"dce-groups-modal--primitives-wrapper"}>{primitives}</div>
  ) : null;
};

Primitives.defaultProps = {};

Primitives.propTypes = {
  group: PropTypes.object,
};

export default Primitives;
