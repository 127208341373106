import React, { useState, useEffect, useMemo, useCallback } from "react";
import classNames from "classnames";
import { Link, useHistory } from "react-router-dom";
import { IT } from "../../../consts";
import appConfig from "../../../appConfig";
import Tos from "../templates/PageTemplate/Tos";
import { useIntegrationStore } from "../../../hooks/redux/integration";
import LoadingIndicator from "./../../calendar/components/LoadingIndicator";
import { useIntegrationsStore } from "../../../hooks/redux/integrations";
import CalendarOption from "../modals/NewIntegration/GoogleCalendar/CalendarOption";

const SelectCalendarsPage = (props) => {
  const history = useHistory();
  const matchParams = props.match.params;
  const [selectedCalendars, setSelectedCalendars] = useState([]);

  const {
    integration,
    setIntegrationModel,
    syncIntegration,
    updateIntegration,
  } = useIntegrationStore();

  const { integrations } = useIntegrationsStore();

  useEffect(() => {
    if (matchParams.uuid) {
      syncIntegration(matchParams.uuid);
    }
  }, [matchParams.uuid]);

  useEffect(() => {
    if (
      integrations.fetchSuccess &&
      !(integration.model && integration.model.settings)
    ) {
      const integration = integrations.collection.find(
        (i) => i.uuid == matchParams.uuid
      );
      setIntegrationModel(integration);
    }
  }, [integrations.fetchSuccess, integrations]);

  const integrationModel = useMemo(
    () =>
      integration.model && integration.model.uuid ? integration.model : null,
    [integration]
  );

  useEffect(() => {
    if (
      integrationModel &&
      integrationModel.settings &&
      integrationModel.settings.calendars
    ) {
      setSelectedCalendars(integrationModel.settings.calendars);
    }
  }, [integrationModel]);

  useEffect(() => {
    if (
      integration.updateSuccess &&
      integration.model.uuid === matchParams.uuid
    ) {
      syncIntegration(matchParams.uuid);
      history.push(`/integrations`);
    }
  }, [integration.updateSuccess]);

  const calendarSyncingIndicator = useMemo(
    () =>
      integrations.sync ? (
        <div className={"col-xs-12 col-md-12"}>
          Reading your Google calendars...
          <LoadingIndicator />
        </div>
      ) : null,
    [integrations.sync, integration.model]
  );

  const onCalendarUpdate = (id, value) => {
    setSelectedCalendars((prevCalendars) =>
      prevCalendars.map((calendar) =>
        calendar.id === id ? { ...calendar, sync: value } : calendar
      )
    );
  };

  const calendars = useMemo(
    () =>
      integrationModel &&
      integrationModel.settings &&
      integrationModel.settings.calendars
        ? integrationModel.settings.calendars
        : null,
    [integrationModel]
  );

  const calendarsOptions = calendarSyncingIndicator ? (
    calendarSyncingIndicator
  ) : calendars && calendars.length ? (
    calendars.map((calendar) => {
      return (
        <CalendarOption
          key={`${"google-calendars-"}-${calendar.id}`}
          idPrefix={"google-calendars-"}
          name={calendar.id}
          available={calendar && calendar.sync ? true : false}
          label={calendar.label}
          handleCalendarCheckboxToggle={onCalendarUpdate}
        />
      );
    })
  ) : (
    <p className="text-dark fs-16">
      We detected that your Google account has no calendars created. Visit{" "}
      <a
        className="text-primary cursor-pointer fs-16"
        href="https://calendar.google.com"
        target="_blank"
      >
        https://calendar.google.com
      </a>{" "}
      as {integration && integration.model && integration.model.username}. It
      will create default calendars for you. Come back then here and refresh the
      page.
    </p>
  );

  const onSyncCalendars = async (e) => {
    e.preventDefault();

    const data = {
      ...integrationModel,
      settings: {
        calendars: selectedCalendars,
      },
    };

    await updateIntegration(integrationModel.uuid, data);
  };

  const isAnySyncSelected = useMemo(
    () => selectedCalendars.some((e) => e.sync === true),
    [selectedCalendars]
  );

  const title = useMemo(() => {
    if (integrations.sync) {
      return "Reading your calendars...";
    }

    return calendars?.length
      ? `Select which calendars you'd like to sync with ${appConfig.displayName}.`
      : "We didn't find any Google Calenders";
  }, [integrations, calendars]);

  const onCheckYourCalender = useCallback(() => {
    window.location.replace("https://calendar.google.com");
  }, []);

  const button = calendars?.length ? (
    <button
      className={classNames(
        "btn btn-primary d-block mx-auto account-btn m-b-15",
        { "pointer-events-none": !isAnySyncSelected }
      )}
      type="submit"
      onClick={onSyncCalendars}
      disabled={!isAnySyncSelected}
    >
      Sync selected calendars
    </button>
  ) : (
    <button
      className="btn btn-primary d-block mx-auto account-btn m-b-15"
      onClick={onCheckYourCalender}
    >
      Check your Google Calender
    </button>
  );

  const content = (
    <React.Fragment>
      <div className={"mx-3"}>{calendarsOptions}</div>
      <div className="form-group text-center mt-4">
        {button}
        <Link to={"/integrations"} className={"text-primary"}>
          Skip for now
        </Link>
      </div>
    </React.Fragment>
  );

  return (
    <div className="main-wrapper">
      <div className="account-page">
        <div className="container row mx-auto">
          <div className="account-logo col-12">
            <Link to="/">
              <img
                src={`/img/branding/${appConfig.branding}/full_length_logo.png`}
                alt={appConfig.displayName}
              />
            </Link>
          </div>
          <div className="account-box col-12 col-lg-6">
            <div className="account-wrapper">
              <h3 className="account-title">{title}</h3>
              {content}
            </div>
          </div>
          <div className="col-12">
            <Tos />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectCalendarsPage;
