import axios from "axios";
import AxiosConfig from "../AxiosConfig";

/**
 *
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export function fetchPayoutData() {
  return function (dispatch) {
    dispatch({ type: "PAYOUT_INITIAL_DATA_FETCH" });

    const url = "/api/payouts/get_data_for_payout.json";

    return axios
      .get(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "PAYOUT_INITIAL_DATA_FETCH_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "PAYOUT_INITIAL_DATA_FETCH_REJECTED",
          payload: error,
        });
      });
  };
}

/**
 *
 * @param data
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export function createPayout(data) {
  return function (dispatch) {
    dispatch({ type: "PAYOUT_CREATE" });

    const url = "/api/payouts.json";

    return axios
      .post(url, data, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "PAYOUT_CREATE_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "PAYOUT_CREATE_REJECTED",
          payload: error,
        });
      });
  };
}

/**
 *
 * @param id
 * @param data
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export function markVendorPayoutAsPaid(id, data) {
  return function (dispatch) {
    dispatch({ type: "VENDOR_PAYOUT_PAID" });

    const url = "/api/payouts/" + id + ".json";

    return axios
      .put(url, data, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "VENDOR_PAYOUT_PAID_FULFILLED",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "VENDOR_PAYOUT_PAID_REJECTED",
          payload: error,
        });
      });
  };
}

/**
 *
 * @param id
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export function fetchPayout(id) {
  return function (dispatch) {
    dispatch({ type: "PAYOUT_FETCH" });

    const url = "/api/payouts/" + id + ".json";

    return axios
      .get(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "PAYOUT_FETCH_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "PAYOUT_FETCH_REJECTED",
          payload: error,
        });
      });
  };
}

/**
 *
 * @param id
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export function deletePayout(id) {
  return async function (dispatch) {
    dispatch({ type: "PAYOUT_DELETE" });

    const url = "/api/payouts/delete_recent/" + id + ".json";

    return await axios
      .delete(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "PAYOUT_DELETE_FULFILLED",
          payload: { id: id },
        });
      })
      .catch(function (error) {
        dispatch({
          type: "PAYOUT_DELETE_REJECTED",
          payload: error,
        });
      });
  };
}
