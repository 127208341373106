/**
 * Created by piotr.pozniak@thebeaverhead.com on 23/11/2023
 */

import React from "react";
import PropTypes from "prop-types";
import BrandLogo from "./BrandLogo";

const BasicDetailsForm = (props) => {
  const organization = props.organization;

  /**
   *
   * @param field
   * @returns {Function}
   */
  const onSetValue = (field) => (e) => {
    e.preventDefault();

    const data = { ...organization };
    data[field] = e.target.value;

    props.onSetValue(data);
  };

  const disabled = props.disabled;

  return (
    <div>
      <h3 className="page-title">Basic Information</h3>
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className={"col-md-2"}>
              <BrandLogo
                settings={props.organizationSettings}
                organization={organization}
              />
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label className="focus-label" htmlFor={"name"}>
                  Name
                </label>
                <input
                  id={"name"}
                  className={"form-control"}
                  type="text"
                  value={organization.name}
                  disabled={disabled}
                  onChange={onSetValue("name")}
                  maxLength={64}
                  minLength={2}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

BasicDetailsForm.defaultProps = {};

BasicDetailsForm.propTypes = {
  organization: PropTypes.object.isRequired,
  onSetValue: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  organizationSettings: PropTypes.object,
};

export default BasicDetailsForm;
