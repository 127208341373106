/**
 * Created by piotr.pozniak@thebeaverhead.com on 12/02/2024
 */

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const ActionButton = (props) => {
  const icon = props.icon ? (
    <span className={"decoration-none mt-1"}>
      <i className="material-icons icon-sm " aria-hidden="true">
        {props.icon}
      </i>
    </span>
  ) : null;

  const button = props.loading ? (
    props.loading
  ) : (
    <button
      className={classNames(
        "d-flex btn btn-sm align-items-center",
        `${props.className}`
      )}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {icon} {props.label}
    </button>
  );

  return <> {button} </>;
};

ActionButton.defaultProps = {
  loading: null,
  disabled: false,
};

ActionButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  loading: PropTypes.element,
  disabled: PropTypes.bool,
};

export default ActionButton;
