/**
 * Created by piotr.pozniak@thebeaverhead.com on 23/12/2020.
 */

import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { formatDate } from "../../../../helpers/date";

const InpicDate = (props) => {
  const referenceDate = new Date(props.startDate * 1000);
  //const tzOffset = referenceDate.getTimezoneOffset() * 60000;
  const startDate = new Date(props.startDate * 1000 /* + tzOffset*/);
  const endDate = new Date(props.endDate * 1000 /* + tzOffset*/);

  const isSameDay = startDate.isSameDay(endDate);
  const endDay = !isSameDay ? (
    <div className={"card-event--inpic-date-container-end-date"}>
      <span className={"card-event--inpic-date_month"}>
        {formatDate(endDate, { month: "short" })}
      </span>
      <span className={"card-event--inpic-date_day"}>
        {formatDate(endDate, { day: "numeric" })}
      </span>
    </div>
  ) : null;

  return (
    <div
      className={classnames(
        "card-event--inpic-date",
        "card-event--inpic-date_" + props.location
      )}
    >
      <div className={"card-event--inpic-date-container"}>
        <span className={"card-event--inpic-date_month"}>
          {formatDate(startDate, { month: "short" })}
        </span>
        <span className={"card-event--inpic-date_day"}>
          {formatDate(startDate, { day: "numeric" })}
        </span>
      </div>
      {endDay}
    </div>
  );
};

InpicDate.defaultProps = {
  location: "bottom-right",
};

InpicDate.propTypes = {
  startDate: PropTypes.number.isRequired,
  endDate: PropTypes.number.isRequired,
  location: PropTypes.string,
};

export default InpicDate;
