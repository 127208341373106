import React from "react";
import PropTypes from "prop-types";
import InpicDate from "./InpicDate";

const CardEventHeader = (props) => {
  const {
    showBadgesInImage,
    recurringBadges,
    inlineStyle,
    banner,
    event,
    inpicDatePlacement,
  } = props;

  return (
    <div className={"card-view--card-header"}>
      {showBadgesInImage ? recurringBadges : null}
      <div className={"card-view--card-header-image"} style={inlineStyle}>
        {banner}
      </div>
      <InpicDate
        startDate={event.start_time}
        endDate={event.end_time}
        location={inpicDatePlacement}
      />
    </div>
  );
};

CardEventHeader.defaultProps = {};

CardEventHeader.propTypes = {
  inpicDatePlacement: PropTypes.string,
  event: PropTypes.object,
  showBadgesInImage: PropTypes.bool,
  banner: PropTypes.element,
  inlineStyle: PropTypes.object.isRequired,
  recurringBadges: PropTypes.bool,
};

export default CardEventHeader;
