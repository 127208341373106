import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useCurrentOrganizationStore } from "../../../hooks/redux/currentOrganization";

const SubscriptionAccess = (props) => {
  const { currentOrganization } = useCurrentOrganizationStore();
  const subscription = currentOrganization.subscription;

  const isPlanAllowed =
    subscription && props.allowedPlans.includes(subscription.code);

  return props.allowedPlans.length && (!subscription || !isPlanAllowed)
    ? subscription && (
        <React.Fragment>
          {props.children}
          <div className={"alert alert-warning fade show"}>
            <div>
              {props.CTAMessage}
              <p className={"text-center"}>
                <Link className=" mt-3" to={"/billing"}>
                  Upgrade
                </Link>
              </p>
            </div>
          </div>
        </React.Fragment>
      )
    : props.children;
};

SubscriptionAccess.defaultProps = {
  allowedPlans: [],
};

SubscriptionAccess.propTypes = {
  allowedPlans: PropTypes.array.isRequired,
  CTAMessage: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default SubscriptionAccess;
