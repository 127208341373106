/**
 * Created by piotr.pozniak@thebeaverhead.com on 19/01/2022
 */

import React from "react";
import PropTypes from "prop-types";
import DetailsRow from "./DetailsRow";
import { useTranslation } from "react-i18next";

const scheduleFormatter = Intl.DateTimeFormat(navigator.language, {
  hour: "numeric",
  minute: "numeric",
  hour12: true,
});

const headerFormatter = Intl.DateTimeFormat(navigator.language, {
  dateStyle: "full",
});

const EventSchedule = (props) => {
  const { t } = useTranslation();
  if (!props.schedule || !props.schedule.length) {
    return null;
  }

  const eventsGroupedByDays = [];

  props.schedule.map((curr) => {
    const day = new Date(curr.starts_at).getDate();

    if (!eventsGroupedByDays[day]) {
      eventsGroupedByDays[day] = [];
    }
    eventsGroupedByDays[day].push(curr);
  });

  const eventsSchedule = eventsGroupedByDays.map((daySchedule, idx) => {
    const firstDay = new Date(daySchedule[0].starts_at);

    const schedule = daySchedule.map((i, jdx) => {
      return (
        <li
          className="dce-schedule-event-time"
          key={`es-${props.eventId}-${idx}${jdx}`}
        >
          <p>
            <em className="dce-schedule-time">
              {scheduleFormatter.format(new Date(i.starts_at))}
            </em>
            <span className="dce-schedule-description">
              <b>{i.name}</b>
            </span>
          </p>
        </li>
      );
    });

    return (
      <div
        className="event-row--details-row__schedule"
        key={`es-${props.eventId}-${idx}`}
      >
        <ul className="event-row--details-row__schedule dce-schedule-ul">
          <li className="dce-schedule-header">
            {headerFormatter.format(firstDay)}
          </li>
          {schedule}
        </ul>
      </div>
    );
  });

  return (
    <DetailsRow title={t("Event schedule")} icon="event_available">
      {eventsSchedule}
    </DetailsRow>
  );
};

EventSchedule.defaultProps = {};

EventSchedule.propTypes = {
  schedule: PropTypes.array,
  eventId: PropTypes.string,
};

export default EventSchedule;
