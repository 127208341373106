/**
 * Created by piotr.pozniak@thebeaverhead.com on 12/07/2022
 */

import React, { memo } from "react";
import PropTypes from "prop-types";
import { formatAddressLines } from "../../tools";

const Address = (props) => {
  const { address } = props;

  const addressLines = formatAddressLines(address);

  const addressLine = addressLines.length
    ? addressLines.map((i, idx) => {
        return i;
      }).join(', ')
    : null;

  const name = address.name ? (
    <div className={"dce--two-columns--address-header"}>
      {address.name}
      {addressLines.length ? ", " : ""}
    </div>
  ) : null;

  return (
    <div className={"dce--two-columns--address"}>
      <div>{name}</div>
      <div className={"dce--two-columns--address-lines"}>{addressLine}</div>
    </div>
  );
};

Address.defaultProps = {};

Address.propTypes = {
  address: PropTypes.shape({
    name: PropTypes.string,
    city: PropTypes.string,
    street_address: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    formatted_address: PropTypes.string,
  }),
};

export default memo(Address);
