/**
 * Created by piotr.pozniak@thebeaverhead.com on 29/10/2018.
 */

import React, { useState, useEffect } from "react";

import { SketchPicker } from "react-color";
import PropTypes from "prop-types";

const ColorSelector = (props) => {
  const id = "color-picker-" + props.id;

  const [state, setState] = useState({
    showColorPicker: false,
  });

  useEffect(() => {
    document.addEventListener("click", handleClickOutsidePicker);

    return () => {
      document.removeEventListener("click", handleClickOutsidePicker);
    };
  });

  /**
   *
   * @param event
   */
  const handleClickOutsidePicker = (event) => {
    const pickerContainer = document.querySelector("#" + id);

    if (!pickerContainer.contains(event.target) && state.showColorPicker) {
      setState({ showColorPicker: false });
    }
  };

  /**
   *
   */
  const onShowPicker = () => {
    setState({ showColorPicker: true });
  };

  const colorPicker =
    state.showColorPicker && !props.disabled ? (
      <SketchPicker
        onChange={props.onColorChagnge}
        color={props.color}
        enableAlpha={true}
      />
    ) : null;

  return (
    <div className="color-picker-container" id={id}>
      <div
        className={"color-picker-preview " + (props.disabled ? "disabled" : "")}
        onClick={onShowPicker}
      >
        <div
          className="color-picker-preview-color"
          style={{ background: props.color }}
        />
      </div>

      <div className="color-picker-popover">{colorPicker}</div>
    </div>
  );
};

ColorSelector.propTypes = {
  onColorChagnge: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  color: PropTypes.string,
  disabled: PropTypes.bool,
};

export default ColorSelector;
