/**
 * Created by piotr.pozniak@thebeaverhead.com on 19/05/2024
 */

import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";

const valueToRange = (value) => {
  if (!value) {
    return [null, null];
  }

  if (value.indexOf(":") === -1) {
    return [null, null];
  }

  return value.split(":");
};

const DateRangeInput = (props) => {
  const [range, setRange] = useState(
    props.disabled ? [null, null] : valueToRange(props.customInputValue)
  );

  useEffect(() => {}, []);

  /**
   *
   * @type {function(*): function(*): void}
   */
  const onChange = useCallback(
    (index) => (event) => {
      const newRange = [...range];
      newRange[index] = event.target.value;
      setRange(newRange);

      props.updateDesignProp([
        {
          field: props.name,
          value: `${newRange[0]}:${newRange[1]}`,
        },
      ]);
    },
    [props.updateDesignProp, range]
  );

  return (
    <React.Fragment>
      <input
        type="date"
        step={1}
        className="form-control form-control-sm"
        disabled={props.disabled}
        value={!props.disabled ? range[0] : ""}
        onChange={onChange(0)}
      />{" "}
      -{" "}
      <input
        type="date"
        step={1}
        className="form-control form-control-sm"
        disabled={props.disabled}
        value={!props.disabled ? range[1] : ""}
        onChange={onChange(1)}
      />
    </React.Fragment>
  );
};

DateRangeInput.defaultProps = {};

DateRangeInput.propTypes = {};

export default DateRangeInput;
