import React, { useEffect, useState } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import classnames from "classnames";
import WeekDayEvents from "./WeekDayEvents";
import { formatDate, isSameDay } from "../../../../helpers/date";

const WeekDay = (props) => {
  const { calendar, day, events, isSelected, showEventDetails } = props;
  const onClickDay = () => {
    if (!showEventDetails) {
      props.onSelectDay(day);
    }
  };

  const dayEvents = events.collection.filter((e) => {
    return (
      isSameDay(day, new Date(e.start_time * 1000)) ||
      isSameDay(day, new Date(e.end_time * 1000)) ||
      (day > new Date(e.start_time * 1000) && day < new Date(e.end_time * 1000))
    );
  });

  const isCurrentDay = isSameDay(new Date(), day);

  const hasEventsIndication =
    dayEvents.length &&
    !showEventDetails &&
    props.showEventPresenseIndication ? (
      <div className={"weekly-cal--day-events-indicator-container"}>
        <div className={"weekly-cal--day-events-indicator"}></div>
      </div>
    ) : null;

  const eventDetails =
    showEventDetails && dayEvents.length ? (
      <WeekDayEvents
        events={dayEvents}
        day={day}
        onSelectedEvent={props.onSelectedEvent}
        useEventAccent={props.useEventAccent}
        isPills={props.eventsLocation === "pills"}
        isStack={props.eventsLocation === "stack"}
        showRecurring={props.showRecurring}
        showFeatured={props.showFeatured}
      />
    ) : null;

  const isNewMonth = day.getDate() === 1;
  const newMonth = isNewMonth ? (
    <span className={"new-month-label"}>
      {formatDate(day, { month: "short" })}
    </span>
  ) : null;

  return (
    <div className={"weekly-cal--day-wrapper"}>
      <div
        className={classnames("weekly-cal--day-container", {
          today: isCurrentDay,
          selected: isSelected,
          "new-month": isNewMonth,
        })}
        onClick={onClickDay}
      >
        {newMonth}
        <div className={"weekly-cal--day-header"}>
          {formatDate(day, { weekday: "short" })}
        </div>
        <div className={"weekly-cal--day-content"}>
          <div className={"weekly-cal--day-name"}>
            {formatDate(day, { day: "numeric" })}
          </div>
          {hasEventsIndication}
        </div>
      </div>
      {eventDetails}
    </div>
  );
};
WeekDay.propTypes = {
  calendar: PropTypes.object,
  events: PropTypes.object,
  day: PropTypes.object,
  isSelected: PropTypes.bool,
  showEventDetails: PropTypes.bool,
  eventsLocation: PropTypes.oneOf(["events_list", "pills", "stack"]),
  useEventAccent: PropTypes.bool,
  showEventPresenseIndication: PropTypes.bool,
  onSelectDay: PropTypes.func.isRequired,
  onSelectedEvent: PropTypes.func.isRequired,
  showRecurring: PropTypes.bool,
  showFeatured: PropTypes.bool,
};

export default WeekDay;
