/**
 * Created by piotr.pozniak@thebeaverhead.com on 06/04/2022
 */

import React from "react";
import PropTypes from "prop-types";

const LoadingIndicator = (props) => {
  const style = {
    gridTemplateColumns: props.cols,
  };

  return (
    <div className={"dce--outlined-loader-container"} style={style}>
      <div className={"dce--outlined-cards-card-loader"}></div>
      <div className={"dce--outlined-cards-card-loader"}></div>
      <div className={"dce--outlined-cards-card-loader"}></div>
    </div>
  );
};

LoadingIndicator.defaultProps = {
  cols: "1fr 1fr 1fr",
};

LoadingIndicator.propTypes = {
  cols: PropTypes.string,
};

export default LoadingIndicator;
