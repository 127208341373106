/**
 * Created by piotr.pozniak@thebeaverhead.com on 27/11/2023
 */

import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { CancelReasons, StepPropTypes } from "./consts";

const CancelStep2 = (props) => {
  const [error, setError] = useState(null);

  /**
   *
   * @type {(function(*): void)|*}
   */
  const onCheckError = useCallback(
    (e) => {
      if (Number.parseInt(props.values.reason) === 7) {
        if (props.values.comment.length <= 3) {
          setError("Please, tell us why you are leaving.");
          return;
        }
        setError(null);
      }
    },
    [error, props.values]
  );

  /**
   *
   * @type {(function(*): void)|*}
   */
  const onChange = useCallback(
    (e) => {
      onCheckError(e);
      props.onChange("comment", false)(e);
    },
    [props.onChange, props.values, error]
  );

  const showCommentMandatoryError = error ? (
    <span className={"text-danger"}>Please, tell us why you are leaving.</span>
  ) : null;

  return (
    <React.Fragment>
      <div className={"card-header bg-white"}>
        <div className={"card-title m-b-0"}>
          You have not canceled your account yet... select an option below.
        </div>
      </div>

      <div className={"card-body"}>
        <form>
          <div className="form-group">
            <label>What's the single biggest reason for you canceling?</label>

            <div className="radio">
              <label>
                <input
                  name="leave-reason"
                  type="radio"
                  value={1}
                  checked={true}
                  disabled={true}
                />{" "}
                {
                  CancelReasons.find((i) => i.value == props.values.reason)
                    .label
                }
              </label>
            </div>
          </div>

          <p>
            We are sorry to see you go. You're welcome back at any time. Any
            final comments for us?
          </p>
          <textarea
            className="form-control"
            rows="3"
            value={props.values.comment}
            onChange={onChange}
            autoFocus={true}
            onBlur={onCheckError}
          ></textarea>
          {showCommentMandatoryError}

          <div className="m-t-20 text-center">
            <div className="form-group"></div>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

CancelStep2.defaultProps = {};

CancelStep2.propTypes = StepPropTypes;

export default CancelStep2;
