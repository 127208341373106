/**
 * Created by piotr.pozniak@thebeaverhead.com on 05/04/2022.
 */

import React, { useCallback } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useAppStore } from "../../../../hooks/redux/app";

const WidgetPreviewHeader = (props) => {
  const { toggleWidgetPreview } = useAppStore();

  const onTurnOnPreviewMode = useCallback(() => {
    toggleWidgetPreview(true);
  }, []);

  const calendarDropdownMenu = (
    <div className={"calendar-nav-options"}>
      <button
        className={"btn btn-light d-none d-sm-flex"}
        onClick={onTurnOnPreviewMode}
      >
        <i className="material-icons">preview</i>&nbsp; Preview
      </button>

      <li className="nav-item dropdown dropdown-action">
        <a
          href=""
          className="btn nav-link dropdown-toggle text-secondary p-2"
          data-toggle="dropdown"
          aria-expanded="false"
        >
          <i className="material-icons">settings</i>
        </a>

        <div className="dropdown-menu dropdown-menu-right">
          <a
            className="dropdown-item text-danger d-flex"
            href="#"
            onClick={props.onDelete}
          >
            <i className={"material-icons icon-sm"}>delete</i>&nbsp; Delete
            widget
          </a>
        </div>
      </li>
    </div>
  );

  return (
    <div className="navbar">
      <ul className="nav custom-menu">
        <li className="nav-item">
          <a
            href="#chat_sidebar"
            className="nav-link task-chat d-xs-flex  d-lg-none"
          >
            <i className="material-icons" aria-hidden="true">
              tune
            </i>
          </a>
        </li>

        {calendarDropdownMenu}
      </ul>
    </div>
  );
};

WidgetPreviewHeader.defaultProps = {};

WidgetPreviewHeader.propTypes = {
  lastModified: PropTypes.number,
  onDelete: PropTypes.func.isRequired,
};

export default WidgetPreviewHeader;
