/**
 * Created by piotr.pozniak@thebeaverhead.com on 22/11/2023.
 */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useAppStore } from "../../../hooks/redux/app";
import ModalTemplate from "./ModalTemplate";
import Form from "./ManageMembers/Form";
import { useOrganizationMembersStore } from "../../../hooks/redux/organizationMembers";
import { useCurrentOrganizationStore } from "../../../hooks/redux/currentOrganization";
import { OrganizationMemberStatus } from "../consts/crm";
import { isValidEmail } from "../../../helpers/calendar";
import { isEqualArrays } from "../../../helpers/general";

const ManageMembersModal = (props) => {
  const { clearErrors } = useAppStore();
  const { t } = useTranslation();

  const {
    organizationMembers,
    fetch,
    addOrganizationMember,
    deleteOrganizationMember,
    editOrganizationMember,
  } = useOrganizationMembersStore();
  const { currentOrganization } = useCurrentOrganizationStore();

  const [members, setMembers] = useState(organizationMembers.collection);

  const header = `${t("Organization")} Members`;

  useEffect(() => {
    fetch(currentOrganization.model.uuid);
  }, []);

  useEffect(() => {
    setMembers(organizationMembers.collection);
  }, [organizationMembers.fetchSuccess]);

  /**
   *
   * @type {(function(): Promise<void>)|*}
   */
  const onClose = useCallback(async () => {
    await clearErrors();
    props.history.push("/dashboard");
  }, []);

  /**
   *
   * @type {(function(): void)|*}
   */
  const onRestore = useCallback(() => {
    setMembers(organizationMembers.collection);
  }, [organizationMembers.collection]);

  /**
   * Handles add/update staff action.
   *
   * @param domain
   */
  const onSubmit = useCallback(async () => {
    // Prepare promises for all actions
    // Add users
    const addPromises = members
      .filter((e) => e.invite_status === OrganizationMemberStatus.newMember)
      .map(async (member) => {
        const data = {
          organization_uuid: currentOrganization.model.uuid,
          invite_email: member.invite_email,
          role: member.role.toLowerCase(),
        };
        await addOrganizationMember(data);
      });

    // Delete users
    const deletePromises = members
      .filter((e) => e.invite_status === OrganizationMemberStatus.delete)
      .map(async (member) => {
        await deleteOrganizationMember(member.uuid);
      });

    // Edit users
    const editPromises = members
      .filter((e) => e.invite_status !== OrganizationMemberStatus.newMember)
      .filter((member) => {
        const correspondingData = organizationMembers.collection.find(
          (item) => item.uuid === member.uuid
        );
        return correspondingData && correspondingData.role !== member.role;
      })
      .map(async (e) => {
        await editOrganizationMember(e.uuid, { role: e.role.toLowerCase() });
      });

    // Execute all promises concurrently
    await Promise.all([...addPromises, ...deletePromises, ...editPromises]);

    // Check if any of the actions were successful and trigger fetch
    if (
      addPromises.length > 0 ||
      deletePromises.length > 0 ||
      editPromises.length > 0
    ) {
      fetch(currentOrganization.model.uuid);
    }
  }, [members, currentOrganization.model.uuid, organizationMembers.collection]);

  const invalidEmailExist = useMemo(
    () =>
      members
        .filter((e) => e.invite_status === OrganizationMemberStatus.newMember)
        .some((e) => !isValidEmail(e.invite_email)),
    [members]
  );

  const isDataChanged = useMemo(
    () => !isEqualArrays(members, organizationMembers.collection),
    [members]
  );

  const form = (
    <Form
      onSubmit={onSubmit}
      disabled={false}
      members={members}
      onRestore={onRestore}
      onChange={setMembers}
      disableSubmit={
        invalidEmailExist ||
        !isDataChanged ||
        organizationMembers.add ||
        organizationMembers.edit ||
        organizationMembers.delete
      }
    />
  );

  const formClasses = classNames("card-body", {
    "opacity-50":
      organizationMembers.add ||
      organizationMembers.edit ||
      organizationMembers.delete,
  });

  return (
    <ModalTemplate
      id={"manage-members-modal"}
      header={header}
      size={"lg"}
      onClose={onClose}
    >
      <div className={"card"}>
        <div className={"card-header bg-white"}>
          <h3 className="card-title  m-b-0">
            Manage people with whom like to collaborate
          </h3>
        </div>
        <div className={formClasses}>{form}</div>
      </div>
    </ModalTemplate>
  );
};

ManageMembersModal.propTypes = {};

ManageMembersModal.defaultProps = {};

export default ManageMembersModal;
