/**
 * Created by piotr.pozniak@thebeaverhead.com on 28/09/2023.
 */

export const getShareableUrl = (slug) => {
  return window.location.origin + "/s/" + slug;
};

export const newSignupUrl = "/signup/new";

export const indexPageUrl = "/signups";

export const signupSettingsValidator = (data) => {
  const errors = {};

  if (!data.name || !data.name.length) {
    errors.nameError = "Please enter a Widget Name. ";
  }

  if (!data.integration_uuid || data.integration_uuid.length <= 1) {
    errors.integrationError = "Please choose an integration. ";
  }

  return errors;
};

export const formatAddressLines = (address) => {
  const city = address.city || "";
  const state = address.state || "";
  const zip = address.zip || "";

  return [
    address.street_address,
    address.formatted_address,
    (city + state + zip).length
      ? `${city}${city.length ? "," : ""} ${state} ${zip}`
      : null,
  ].filter((i) => (i ? true : false));
};

/**
 * @description This is a helper function that will format the address lines for given address.
 * If ctaAction is contactLeader, it will append given
 * subject line and message to mailto url.
 * @param ctaAction
 * @param ctaLink
 * @param ctaSubject
 * @param ctaMessage
 * @returns {*|string}
 */
export const formatCTAUrl = (ctaAction, ctaLink, ctaSubject, ctaMessage) => {
  if (ctaAction === "contactLeader") {
    // replace message new lines with %0D%0A
    const message = ctaMessage.replaceAll("\n", "%0D%0A");

    // append subject using ctaSubject and body using ctaMessage
    return `${ctaLink.url}?subject=${ctaSubject}&body=${message}`;
  }
  return ctaLink.url;
};
