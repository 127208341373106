/**
 * Created by piotr.pozniak@thebeaverhead.com on 27/11/2023
 */

import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import ApiError from "../../component/ApiError";
import FormError from "../../component/FormError";
import { StepPropTypes } from "./consts";
import { useUserStore } from "../../../../hooks/redux/user";

const CancelStep3 = (props) => {
  const [error, setError] = useState();
  const { user } = useUserStore();

  const onCheckError = useCallback(
    (e) => {
      if (user.model?.is_google_account && e.target.value !== "I CONFIRM") {
        setError("Confirmation is required to terminate subscription.");
      } else if (e.target.value.length < 6) {
        setError("Password must be at least 6 characters long.");
      } else {
        setError(null);
      }
    },
    [error, props.values]
  );

  /**
   *
   * @type {(function(*): void)|*}
   */
  const onChange = useCallback(
    (e) => {
      if (error) {
        onCheckError(e);
      }
      props.onChange("password", false)(e);
    },
    [props.onChange, props.values]
  );

  const confirmationForm = user.model?.is_google_account ? (
    <div className="form-group">
      <label>
        Confirmation <span className="text-danger">*</span>
      </label>

      <span className={"d-block"}>
        Type <span className={" text-danger"}>I CONFIRM</span> to confirm
        termination.
      </span>
      <input
        id="confirmation"
        className={"form-control " + (error ? "is-invalid" : "")}
        type="text"
        placeholder="Type 'I CONFIRM' to confirm termination"
        value={props.values.password}
        onChange={onChange}
        onBlur={onCheckError}
        disabled={props.disabled}
      />
    </div>
  ) : (
    <div className="form-group">
      <label>
        Password <span className="text-danger">*</span>
      </label>

      <input
        id="password"
        className={"form-control " + (error ? "is-invalid" : "")}
        type="password"
        placeholder="User Password"
        value={props.values.password}
        onChange={onChange}
        onBlur={onCheckError}
        disabled={props.disabled}
      />
    </div>
  );

  return (
    <React.Fragment>
      <div className={"card-header bg-white"}>
        <div className={"card-title m-b-0"}>
          In order to terminate your subscription, please enter your account
          password. Once it's verified, your subscription will be terminated.
        </div>
      </div>

      <div className={"card-body"}>
        <form>
          {confirmationForm}

          <div className="m-t-20 text-center">
            <div className="form-group"></div>
          </div>

          <FormError errors={[error]} />
        </form>
      </div>
    </React.Fragment>
  );
};

CancelStep3.defaultProps = {};

CancelStep3.propTypes = StepPropTypes;

export default CancelStep3;
