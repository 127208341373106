/**
 * Created by piotr.pozniak@thebeaverhead.com on 21/03/2024
 */
import { useEffect } from "react";

export const useClickOutsideDomHook = (props) => {
  const { ref, callback } = props;

  useEffect(() => {
    const handleClick = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        callback(e);
      }
    };
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [ref]);
};
