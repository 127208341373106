/**
 * Created by piotr.pozniak@thebeaverhead.com on 22/05/2019.
 */

import React from "react";
import PropTypes from "prop-types";

const FormError = (props) => {
  const errors = props.errors;

  if (!errors || !errors.length || !errors.join("").length) {
    return null;
  }

  const formattedErrors = errors
    .filter((i) => i)
    .map((i, idx) => {
      return <li key={"fe-" + idx}>{i}</li>;
    });

  return (
    <div
      className="alert alert-danger alert-dismissible fade show text-left"
      role="alert"
    >
      <strong>Please fix the following:</strong> <ul>{formattedErrors}</ul>

    </div>
  );
};

FormError.propTypes = {
  errors: PropTypes.array,
};

export default FormError;
