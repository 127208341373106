import { store } from "../apps/admin/store";

/**
 * Triggers a Google Tag Manager event using the dataLayer.
 * @param eventName
 * @param data
 */
const triggerTagEvent = (eventName, data = {}) => {
  const user = store.getState().user;
  const currentOrganization = store.getState().currentOrganization;
  const email = user.model ? user.model.email : null;

  const currentOrgId = currentOrganization.model
    ? currentOrganization.model.uuid
    : null;

  const layerData = {
    event: eventName,
    userID: currentOrgId,
    email,
    ...data,
  };

  if (window.dataLayer) {
    window.dataLayer.push(layerData);
  }
};

export default triggerTagEvent;
