/**
 * Created by piotr.pozniak@thebeaverhead.com on 15/05/2023
 */

import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
  calendarFeaturesCheck,
  getTheLowestPlanMeetingCalendarCriteria,
} from "../../tools";
import { PLAN_NAMES, PLANS } from "../../../../consts";
import { useCalendarsStore } from "../../../../hooks/redux/calendars";
import { useUserStore } from "../../../../hooks/redux/user";
import FeatureFlag from "../../component/FeatureFlag";

const SubscriptionHeading = (props) => {
  const { user } = useUserStore();
  const { calendars } = useCalendarsStore();

  const lowestPlan = useMemo(() => {
    const lowestPlans = calendars.collection
      .filter((i) => i.integration)
      .map((calendar) => {
        return getTheLowestPlanMeetingCalendarCriteria(calendar, calendars);
      });

    const plansKeys = Object.values(PLANS);

    const lowestPlan =
      lowestPlans &&
      lowestPlans.length > 0 &&
      lowestPlans.reduce((a, b) => {
        return plansKeys.indexOf(a) > plansKeys.indexOf(b) ? a : b;
      });

    return PLAN_NAMES[lowestPlan];
  }, [props.selectedPlan, calendars.collection]);

  const downgradableWidgetsPerPlan = useMemo(() => {
    return calendars.collection
      .filter((i) => i.integration)
      .map((calendar) => {
        const issues = calendarFeaturesCheck(
          calendar,
          null,
          calendars,
          props.selectedPlan
        );

        if (!issues.length) {
          return null;
        }

        return { calendar, issues };
      })
      .filter((i) => i);
  }, [props.selectedPlan, calendars.collection]);

  const hasDowngradeSuggestion = downgradableWidgetsPerPlan.length > 0;

  const actionName =
    props.currentPlan && props.currentPlan.code !== PLANS.free
      ? "switching"
      : "subscribing";

  const planTooLowAlert = hasDowngradeSuggestion ? (
    <div className={"alert alert-info text-left"}>
      <span>
        Choosing this plan will make the following widgets disabled as they use
        premium features not available in this plan:{" "}
        <ul>
          {downgradableWidgetsPerPlan.map((i) => (
            <li>{i.calendar.name}</li>
          ))}
        </ul>
        We recommend upgrading to <strong>{lowestPlan}</strong> plan.
      </span>
    </div>
  ) : null;

  return (
    <React.Fragment>
      <p>
        You are {actionName} to the{" "}
        <strong>
          {props.selectedPlan.name} {props.isYearly ? "annual" : "monthly"}{" "}
          <FeatureFlag name={"brandedSubscriptions"}>
            {props.isBranded ? "branded" : ""}
          </FeatureFlag>{" "}
          plan
        </strong>
        . {!hasDowngradeSuggestion ? "The summary is as follows:" : null}
      </p>
      {planTooLowAlert}
      {hasDowngradeSuggestion ? <p>The summary is as follows:</p> : null}
    </React.Fragment>
  );
};

SubscriptionHeading.defaultProps = {};

SubscriptionHeading.propTypes = {};

export default SubscriptionHeading;
