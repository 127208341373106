/**
 * Created by piotr.pozniak@thebeaverhead.com on 24/08/2022
 */

import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import AxiosConfig from "../../../../AxiosConfig";
import classNames from "classnames";

const CouponForm = (props) => {
  const [showCouponForm, setShowCouponForm] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [isCouponInvalid, setIsCouponInvalid] = useState(false);
  const { isLoading, appliedCoupon } = props;

  /**
   *
   * @type {(function(*): void)|*}
   */
  const setCodeValue = useCallback(
    (e) => {
      setCouponCode(e.target.value);
    },
    [couponCode]
  );

  /**
   *
   * @type {(function(*): void)|*}
   */
  const toggleCoupon = useCallback(
    (e) => {
      e.preventDefault();
      setShowCouponForm(true);
    },
    [showCouponForm]
  );

  /**
   *
   * @type {(function(*): void)|*}
   */
  const onCancelCoupon = useCallback(
    (e) => {
      setCouponCode("");
      props.onSetCoupon(null);
    },
    [appliedCoupon]
  );

  /**
   *
   * @type {(function(*))|*}
   */
  const onApplyCoupon = useCallback(
    async (e) => {
      setIsCouponInvalid(false);
      e.preventDefault();

      try {
        const response = await axios.get(
          AxiosConfig.getEndpointAddress() +
            `/api/coupons/validate/${couponCode}.json?subscription_plan_uuid=${props.subscriptionPlanUUID}`,
          AxiosConfig.getAuthConfig()
        );

        props.onSetCoupon(response.data.coupon);
      } catch (e) {
        let error = "This Coupon is not valid";
        const data = e.response.data;
        if (data) {
          if (data.error && data.error.length > 0 && data.error[0].messages && data.error[0].messages.length > 0) {
            error = data.error[0].messages[0];
          }
        }
        setIsCouponInvalid(error);
      }

    },
    [couponCode, isCouponInvalid, props.appliedCoupon]
  );

  const applyButton = !appliedCoupon ? (
    <button
      className={"btn btn-secondary"}
      onClick={onApplyCoupon}
      type={"button"}
      disabled={couponCode.length <= 3}
    >
      Apply
    </button>
  ) : null;

  const cancelCouponButton = appliedCoupon ? (
    <button
      className={"btn btn-default d-flex align-items-center"}
      onClick={onCancelCoupon}
      type={"button"}
    >
      <i className={"material-icons"}>close</i>
    </button>
  ) : null;

  const appliedCouponSummary = appliedCoupon ? (
    <p className={"text-secondary"}>
      You will be charged $
      {Number.parseFloat(appliedCoupon.price / 100).toFixed(2)} for{" "}
      {Number.parseInt(appliedCoupon.duration / 60 / 60 / 24)} days then $
      {Number.parseFloat(props.regularPrice).toFixed(2)} after that.
    </p>
  ) : null;

  const couponForm = showCouponForm ? (
    <div className="form-group ">
      <div
        className={classNames("input-group", {
          "is-valid": appliedCoupon ? true : false,
        })}
      >
        <input
          id="coupon"
          className={classNames("form-control input-coupon", {
            "is-invalid": isCouponInvalid ? true : false,
          })}
          type="text"
          value={couponCode}
          onChange={setCodeValue}
          placeholder="Enter Coupon Code"
          disabled={isLoading || appliedCoupon}
        />
        {cancelCouponButton}
        {applyButton}
      </div>
      {isCouponInvalid ? (
        <p className={"text-danger text-center"}>{isCouponInvalid}</p>
      ) : null}
      {appliedCouponSummary}
    </div>
  ) : (
    <a href={"#"} onClick={toggleCoupon}>
      Have a Coupon Code?
    </a>
  );

  return <div>{couponForm}</div>;
};

CouponForm.defaultProps = {};

CouponForm.propTypes = {
  subscriptionPlanUUID: PropTypes.string.isRequired,
  appliedCoupon: PropTypes.object,
  onSetCoupon: PropTypes.func.isRequired,
  regularPrice: PropTypes.string,
};

export default CouponForm;
