/**
 * Created by piotr.pozniak@thebeaverhead.com on 02/08/2024
 */

import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link, useHistory } from "react-router-dom";

const TabsView = ({ tabs, onTabChange, children, defaultTab }) => {
  const _hash = useHistory().location.hash;
  const hash = _hash && _hash.length > 0 ? _hash : `#${defaultTab}`;

  const tabsList = useMemo(
    () =>
      tabs.map((i, idx) => (
        <li key={`id-${idx}`} className={"nav-item"}>
          <Link
            to={`#${i.id}`}
            className={classNames("nav-link", {
              active: hash === `#${i.id}`,
            })}
          >
            {i.label}
          </Link>
        </li>
      )),
    [tabs, hash]
  );

  return (
    <div>
      <ul className={"nav nav-tabs nav-tabs-bottom "}>{tabsList}</ul>
      <div className={"tab-content"}>{children}</div>
    </div>
  );
};

TabsView.defaultProps = {};

TabsView.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string })),
  onTabChange: PropTypes.func,
  defaultTab: PropTypes.string,
};

export default TabsView;
