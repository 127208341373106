/**
 * Created by piotr.pozniak@thebeaverhead.com on 19/06/2023
 */

import React, { useMemo } from "react";
import PropTypes from "prop-types";
import LocalTz from "../LocalTz";
import { formatDate } from "../../../../helpers/date";
import { useTranslation } from "react-i18next";

const WeekDayEventTime = ({ event, day }) => {
  const { t } = useTranslation();

  const startDate = useMemo(
    () => new Date(event.start_time * 1000),
    [event.start_time]
  );
  const endDate = useMemo(
    () => new Date(event.end_time * 1000),
    [event.end_time]
  );

  const isLastDays = !startDate.isSameDay(endDate);

  const isSpanningAcrossDays = !startDate.isSameDay(day);

  const eventAcrossDaysDateStart = isSpanningAcrossDays
    ? "(" + formatDate(startDate, { month: "long", day: "numeric" }) + ")"
    : null;

  const formattedTime = useMemo(() => {
    if (event.allDay) {
      return t("All day");
    } else {
      const formattedEndDate = isLastDays
        ? "(" + formatDate(endDate, { month: "long", day: "numeric" }) + ")"
        : "";

      return (
        <React.Fragment>
          {eventAcrossDaysDateStart}
          {" " +
            formatDate(startDate, {
              hour: "numeric",
              minute: "2-digit",
            }).toLowerCase()}{" "}
          - {formattedEndDate}
          {" " +
            formatDate(endDate, {
              hour: "numeric",
              minute: "2-digit",
            }).toLowerCase()}{" "}
          <LocalTz />
        </React.Fragment>
      );
    }
  }, [event.allDay, eventAcrossDaysDateStart, endDate, startDate]);

  return formattedTime;
};

WeekDayEventTime.defaultProps = {};

WeekDayEventTime.propTypes = {
  event: PropTypes.shape({
    allDay: PropTypes.bool,
    end_time: PropTypes.number,
    start_time: PropTypes.number,
  }).isRequired,

  day: PropTypes.instanceOf(Date).isRequired, // Assuming 'day' is a Date object
};

export default WeekDayEventTime;
