/**
 * Created by piotr.pozniak@thebeaverhead.com on 12/06/2021.
 */

import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as fontsActions from "../../actions/fonts";

export function useFontsStore() {
  const dispatch = useDispatch();
  const _fonts = useSelector((store) => store.fonts);

  const fetchFonts = useCallback(
    async (needle) => await dispatch(fontsActions.fetchFonts(needle)),
    [dispatch]
  );

  return {
    fonts: _fonts,
    fetchFonts,
  };
}
