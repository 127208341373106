import React, { Component, useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { useFilterField } from "../Filters/useFilterField";
import Select from "react-select";

const FilterSelect = (props) => {
  const [state, setState] = useState({ defaultOptions: [] });

  const { rule, updateFilter } = useFilterField(
    props.groupId,
    props.filterId,
    props.collection[0]
  );

  const onChange = useCallback(
    (value) => {
      const updatedFilterValue = {
        value: value.value,
        label: value.label,
        uuid: value.uuid,
      };
      updateFilter(updatedFilterValue);
    },
    [updateFilter]
  );

  const noOptionsMessage = useCallback(() => props.noOptionsMessage, []);

  return (
    <Select
      autoFocus={true}
      autoExpand={true}
      isMulti={false}
      options={props.collection}
      onChange={onChange}
      isSearchable={true}
      isClearable={false}
      placeholder={props.placeholder}
      noOptionsMessage={noOptionsMessage}
      value={rule}
      styles={{
        menu: (base) => ({
          ...base,
          width: "fit-content",
          minWidth: "100%",
        }),
      }}
    />
  );
};

FilterSelect.defaultProps = {
  isMulti: true,
};

FilterSelect.propTypes = {
  placeholder: PropTypes.string.isRequired,
  collection: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  noOptionsMessage: PropTypes.string,
  rawDataToDisplayObject: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
};
export default FilterSelect;
