/**
 * Created by piotr.pozniak@thebeaverhead.com on 05/10/2022
 */

import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import FeatureFlag from "../../component/FeatureFlag";

const WelcomeCard = (props) => {
  const [showVideo, setShowVideo] = useState(false);

  /**
   *
   * @type {(function(*): void)|*}
   */
  const onToggleVideo = useCallback(
    (e) => {
      e.preventDefault();
      setShowVideo(!showVideo);
    },
    [showVideo]
  );

  const videoContainer = showVideo ? (
    <div>
      <div className={"tutorial-video-wrapper"}>
        <button type="button" className="close" onClick={onToggleVideo}>
          <i className={"material-icons"}>close</i>
        </button>
        <div>
          <iframe
            src={`https://www.loom.com/embed/${props.videoId}`}
            webkitallowfullscreen
            mozallowfullscreen
            allowFullScreen
            frameBorder={0}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
          ></iframe>
        </div>
      </div>
    </div>
  ) : null;

  const watchVideoBtn = !showVideo ? (
    <a
      href={"#"}
      target="_blank"
      className={"d-flex align-items-center m-l-15"}
      onClick={onToggleVideo}
    >
      <strong className={"d-flex align-items-center"}>
        Watch Video <i className={"material-icons"}>videocam</i>
      </strong>
    </a>
  ) : null;

  return (
    <div className="howto-card card-box">
      <div className={"row"}>
        <div className={"col-xs-12 col-md-8"}>
          {props.children}
          <FeatureFlag name={"newIntegrationWelcomeCard"}>
            <p>To learn more about the {props.integrationName} integration:</p>
            <div className={"d-flex align-items-center"}>
              <strong>
                <a
                  href={props.helpArticleUrl}
                  target="_blank"
                  className={"d-flex align-items-center m-r-15"}
                >
                  Read Article &#8594;
                </a>
              </strong>
              {watchVideoBtn}
            </div>
          </FeatureFlag>
        </div>
        <div className={"col-xs-12 col-md-4"}>
          <img
            src={props.imageSrc}
            width={"100%"}
            alt={props.integrationName}
          />
        </div>
      </div>
      {videoContainer}
    </div>
  );
};

WelcomeCard.defaultProps = {};

WelcomeCard.propTypes = {
  videoId: PropTypes.string.isRequired,
  helpArticleUrl: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
  integrationName: PropTypes.string.isRequired,
};

export default WelcomeCard;
