/**
 * Created by piotr.pozniak@thebeaverhead.com on 18/07/2023
 */

import React from "react";
import PropTypes from "prop-types";
import QueueJobsPageTemplate from "./QueueJobsPageTemplate";
import TableSyncRow from "./QueueJobsPage/TableSyncRow";

const getCurrentPageUrl = (page) => "/webhook-jobs/" + page;

const QueuedSyncJobs = (props) => {
  return (
    <QueueJobsPageTemplate
      title={"Webhook jobs"}
      jobType={"SyncOne"}
      getCurrentPageUrl={getCurrentPageUrl}
      tableRowComponent={TableSyncRow}
      tableHeader={
        <thead>
          <tr>
            <th width={"5%"} />
            <th width={"5%"}>Id</th>
            <th width={"12%"}>Created</th>
            <th>Account</th>
            <th width={"5%"} align={"center"}>
              Status
            </th>
            <th width={"20%"}>Duration</th>
            <th className="text-right">Action</th>
          </tr>
        </thead>
      }
    />
  );
};

QueuedSyncJobs.defaultProps = {};

QueuedSyncJobs.propTypes = {};

export default QueuedSyncJobs;
