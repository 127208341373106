/**
 * Created by piotr.pozniak@thebeaverhead.com on 05/07/2022
 */

import React from 'react';
import PropTypes from 'prop-types';
import Row from "./Row";

const Description = props => {

  const {description} = props;
  return (
    <Row icon={"reorder"} title={"Description"}>
      <p
        className={"p"}
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </Row>
  )
}

Description.defaultProps = {};

Description.propTypes = {
  description: PropTypes.string
}

export default Description;
