/**
 * Created by piotr.pozniak@thebeaverhead.com on 02/08/2024
 */

import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { useAffiliatesStore } from "../../../../hooks/redux/affiliates";

const AffiliatePanelButton = (props) => {
  const { affiliates, fetchMagicLink, resetMagicLink } = useAffiliatesStore();

  useEffect(() => {
    if (affiliates.fetchMagicLinkSuccess) {
      window.open(
        affiliates.magicLink.sso.url,
        `_affiliate-${affiliates.model.id}`
      );
      resetMagicLink();
    }
  }, [affiliates.fetchMagicLinkSuccess]);

  const openAffiliatePortal = useCallback(
    (e) => {
      fetchMagicLink(affiliates.model.id);
    },
    [affiliates]
  );

  return (
    <button className={"btn btn-md btn-primary "} onClick={openAffiliatePortal}>
      <span className={" d-flex gap-xs "}>
        Open Affiliate Dashboard
        <i className={"material-icons"}>open_in_new</i>
      </span>
    </button>
  );
};

AffiliatePanelButton.defaultProps = {};

AffiliatePanelButton.propTypes = {};

export default AffiliatePanelButton;
