/**
 * Created by piotr.pozniak@thebeaverhead.com on 06/07/2021.
 */

import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

const EventImage = (props) => {
  return (
    <div
      className={classnames(
        "event-row--image-container",
        "aspect-" + props.aspectRatio
      )}
    >
      <img
        className="event-row--image"
        src={props.src}
        onLoad={props.onLoad}
      />
    </div>
  );
};

EventImage.propTypes = {
  src: PropTypes.string.isRequired,
  onLoad: PropTypes.func,
  aspectRatio: PropTypes.string,
};
export default EventImage;
