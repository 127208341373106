/**
 * Created by piotr.pozniak@thebeaverhead.com on 19/06/2023
 */

import React from "react";
import PropTypes from "prop-types";

const timeFormatter = new Intl.DateTimeFormat(
  window.navigator.language || "en-US",
  {
    hour: "numeric",
    minute: "2-digit",
  }
);
/**
 *
 * @param event
 * @returns {`${string|string} ${string}`}
 */
export const formatEventTime = (event) => {
  const formattedTime = event.allDay
    ? ""
    : timeFormatter.format(event.start_time * 1000);

  return `${formattedTime} ${event.name}`;
};

const EventNameWithTime = (props) => {
  return formatEventTime(props.event);
};

EventNameWithTime.defaultProps = {};

EventNameWithTime.propTypes = {};

export default EventNameWithTime;
