/**
 * Created by piotr.pozniak@thebeaverhead.com on 04/10/2023.
 */

import axios from "axios";
import AxiosConfig from "../AxiosConfig";

/**
 *
 * @param actionType
 * @param widgetType
 * @param widgetUUID
 * @param _queryParams e.g. {page: 1, limit: 10 }
 * @param _postData
 * @param preservePreviousItems
 * @returns {function(*): Promise<AxiosResponse<any>>}
 * @private
 */
export const _fetchItems = (
  actionType,
  widgetType,
  widgetUUID,
  _queryParams,
  _postData,
  preservePreviousItems = false
) => {
  return async (dispatch) => {
    dispatch({ type: actionType });

    const postData = {
      widget_uuid: widgetUUID,
      ..._postData,
    };

    const queryParams = {
      uuid: widgetUUID,
      widget_type: widgetType,
      ..._queryParams,
    };

    const queryString = AxiosConfig.objectToURLQuery(queryParams);

    const url = "/api4/widgets/items.json?" + queryString;

    return await axios
      .post(
        AxiosConfig.getEndpointAddress() + url,
        postData,
        AxiosConfig.getAuthConfigIfAvailable()
      )
      .then((response) => {
        dispatch({
          type: `${actionType}_FULFILLED`,
          payload: response.data,
          preservePreviousItems,
        });
      })
      .catch(function (error) {
        dispatch({
          type: `${actionType}_REJECTED`,
          payload: error,
          preservePreviousItems,
        });
      });
  };
};

/**
 *
 * @param widgetType
 * @param widgetUUID
 * @param params
 * @param preservePreviousItems
 * @returns {function(*): Promise<AxiosResponse<*>>}
 */
export const fetchItems = (
  widgetType,
  widgetUUID,
  params,
  preservePreviousItems = false
) => {
  return _fetchItems(
    "WIDGET_ITEMS_FETCH",
    widgetType,
    widgetUUID,
    params,
    preservePreviousItems,
    0
  );
};

/**
 *
 * @returns {Function}
 */
export function resetWidgetItemsCollection() {
  return function (dispatch) {
    dispatch({ type: "WIDGET_ITEMS_COLLECTION_RESET" });
  };
}

/**
 *
 * @param slug
 * @param widgetType
 * @returns {function(*): Promise<AxiosResponse<any>>}
 */
export function fetchWidgetItem(slug, widgetType) {
  return function (dispatch) {
    dispatch({ type: "WIDGET_ITEMS_FETCH" });

    let url = `"/api4/widgets/items/${slug}.json?widge_type=${widgetType}"`;

    return axios
      .get(
        AxiosConfig.getEndpointAddress() + url,
        AxiosConfig.getAuthConfigIfAvailable()
      )
      .then((response) => {
        dispatch({
          type: "WIDGET_ITEMS_FETCH_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "WIDGET_ITEMS_FETCH_REJECTED",
          payload: error,
        });
      });
  };
}
