import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { Link, useParams } from "react-router-dom";
import PageTemplate from "./../templates/PageTemplate";
import TableRow from "./PayoutsPage/TableRow";
import LoadingIndicator from "../../calendar/components/LoadingIndicator";
import classnames from "classnames";
import EditModal from "./PayoutsPage/EditModal";
import ApiError from "../component/ApiError";
import { formatter } from "../tools";
import { usePayoutStore } from "../../../hooks/redux/payout";
import { usePayoutsStore } from "../../../hooks/redux/payouts";
import useDidMountEffect from "../../../hooks/useDidMountEffect";

const PayoutsPage = (props) => {
  const [state, setState] = useState({
    editModalKey: new Date() * 1,
    expandedRows: [],
  });

  const params = useParams();
  const year = Number(params.year || new Date().getFullYear());

  const {
    payout,
    fetchPayoutData,
    createPayout,
    markVendorPayoutAsPaid,
    fetchPayout,
    deletePayout,
  } = usePayoutStore();

  const { fetchPayouts, payouts } = usePayoutsStore();

  useEffect(() => {
    _fetchPayouts();

    return () => window.$("#payouts_modal").modal("hide");
  }, []);

  useDidMountEffect(() => {
    _fetchPayouts();
  }, [year]);

  useDidMountEffect(() => {
    if (payout.createSuccess) {
      _fetchPayouts();
      // TODO: close modal so it's not possible to update created payout.
      // otherwise data will be corrupted
      window.$("#payouts_modal").modal("hide");
    }
  }, [payout.createSuccess]);

  /**
   *
   */
  const _fetchPayouts = () => {
    if (!payouts.fetch) {
      const from = `${year}-01`;
      const to = `${year}-12`;
      fetchPayouts(from, to);
    }
  };

  /**
   *
   * @param payout
   */
  const payoutView = (payout) => {
    window.$("#payouts_modal").modal("show");
    payout.uuid ? fetchPayout(payout.uuid) : fetchPayoutData();
  };

  /**
   *
   * @param payout
   */
  const onDeletePayout = (payout) => {
    deletePayout(payout.uuid);
  };

  /**
   *
   * @param payout
   */
  const onExpandRow = (payout) => {
    const payoutIndex = state.expandedRows.indexOf(payout.uuid);
    if (payoutIndex >= 0) {
      state.expandedRows.splice(payoutIndex, 1);
    } else {
      state.expandedRows.push(payout.uuid);
    }

    setState({ expandedRows: state.expandedRows });
  };

  /**
   *
   * @param payout
   * @param data
   */
  const onUpdatePayout = async (payoutData) => {
    // prepare data: remove payments that were added automatically (not is_added_along_with_payment),
    // update expense uuid if it's recurring- exenses will be removed entirely, when creating a new expense
    // that is a recurring one (already exist) backend requires uuid to be recurring expense uuid
    const expenses = payoutData.payout_expenses
      .filter((i) => {
        return i.is_added_along_with_payout;
      })
      .map((i) => {
        if (i.recurring_expense) {
          const data = { ...i };
          data.uuid = i.recurring_expense.uuid;

          return data;
        }
        return i;
      });

    const updateData = {
      removed_recurring_expenses: [],
      payout_expenses: expenses,
    };

    await deletePayout(payout.model.uuid);
    // create a new payout with updated data
    createPayout(updateData);
  };

  /**
   *
   * @returns {XML}
   */
  const allYearlyPayouts = payouts.collection.length - 1;
  const payoutsList = payouts.collection.map((row, idx) => (
    <TableRow
      key={idx}
      payout={row}
      onView={payoutView}
      onDelete={onDeletePayout}
      canDelete={idx == allYearlyPayouts}
      showDetails={state.expandedRows.indexOf(row.uuid) >= 0}
      onRowClick={onExpandRow}
    />
  ));

  const loadingIndicator =
    payouts.fetch || payout.fetch || payouts.delete ? (
      <LoadingIndicator />
    ) : null;

  let payoutsSummary = null;

  if (!loadingIndicator && payouts.collection.length) {
    let totalExpenses = 0,
      totalIncome = 0,
      vendorAExpenses = 0,
      vendorAPayouts = 0,
      vendorBExpenses = 0,
      vendorBPayouts = 0;

    payouts.collection.map((i) => {
      totalExpenses += i.vendor_a_expenses_total + i.vendor_b_expenses_total;
      totalIncome += i.income;
      vendorAExpenses += i.vendor_a_expenses_total;
      vendorAPayouts += i.vendor_a_payout;
      vendorBExpenses += i.vendor_b_expenses_total;
      vendorBPayouts += i.vendor_b_payout;
    });

    payoutsSummary = (
      <tfoot>
        <tr>
          <th>{year}</th>
          <th>{formatter.format(totalIncome)}</th>
          <th>{formatter.format(totalExpenses)}</th>
          <th />
          <th />
          <th>{formatter.format(vendorAExpenses)}</th>
          <th>{formatter.format(vendorAPayouts)}</th>
          <th>{formatter.format(vendorBExpenses)}</th>
          <th>{formatter.format(vendorBPayouts)}</th>
          <th />
        </tr>
      </tfoot>
    );
  }

  const payoutsData = (
    <table className="table table-striped custom-table">
      <thead>
        <tr>
          <th>Month</th>
          <th>Revenue</th>
          <th>Expenses</th>
          <th>Balance</th>
          <th>Revenue Carried Over</th>
          <th>Expenses Fluid Ministry</th>
          <th>Payout Fluid Ministry</th>
          <th>Expenses TBH</th>
          <th>Payout TBH</th>

          <th className="text-right">Actions</th>
        </tr>
      </thead>
      <tbody>{payoutsList}</tbody>
      {payoutsSummary}
    </table>
  );

  const currentYear = new Date().getFullYear();
  const paginationYears = [];
  const paginationStart = 2018;
  for (let i = paginationStart; i <= Number(currentYear); i++) {
    paginationYears.push(i);
  }

  const firstPage = paginationYears.slice().shift();
  const lastPage = paginationYears.slice().pop();
  const yearIsInPaginationRange = year >= firstPage && year <= lastPage;

  const disablePrev =
    paginationYears.length === 0 ||
    year <= firstPage ||
    !yearIsInPaginationRange;

  const prevPage = disablePrev ? "#" : `/accounts/${Number(year) - 1}`;

  const disableNext =
    paginationYears.length === 0 ||
    year >= lastPage ||
    !yearIsInPaginationRange;

  const nextPage = disableNext ? "#" : `/accounts/${Number(year) + 1}`;

  const payoutsPagination = (
    <ul className="pagination">
      <li
        className={classnames("paginate_button page-item previous", {
          disabled: disablePrev,
        })}
      >
        <Link to={prevPage} tabIndex="0" className="page-link">
          &laquo;
        </Link>
      </li>
      {paginationYears.map((_year) => (
        <li
          className={classnames("paginate_button page-item", {
            active: _year === year,
          })}
          key={_year}
        >
          <Link className={"page-link"} to={`/accounts/${_year}`}>
            {_year}
          </Link>
        </li>
      ))}
      <li
        className={classnames("paginate_button page-item next", {
          disabled: disableNext,
        })}
      >
        <Link to={nextPage} tabIndex="0" className="page-link">
          &raquo;
        </Link>
      </li>
    </ul>
  );

  const payoutDate = "";
  // props.payout.initialData
  // ? moment(
  //     props.payout.initialData.year + " " + props.payout.initialData.month,
  //     "YYYY MM"
  //   )
  //   .format("MMM YYYY")
  // : "";

  const createNewPayoutButtonDisabled = payout.fetch || payout.fetchError;
  const createNewPayoutButton = (
    <button
      type="button"
      className="btn btn-primary btn-rounded pull-right d-flex"
      disabled={createNewPayoutButtonDisabled}
      onClick={payoutView}
    >
      <i className="material-icons">add</i>
      Create {payoutDate} Payout
    </button>
  );

  return (
    <PageTemplate
      sidebarId="accounts"
      title={`Accounts ${year}`}
      icon={"payments"}
      headerControls={[createNewPayoutButton]}
    >
      <div className="row">
        <div className="col-md-12">
          <div className="table-responsive">
            {payoutsData}
            {payoutsPagination}
            {loadingIndicator}
          </div>
        </div>
      </div>

      <ApiError
        error={payouts.fetchError}
        defaultErrorMessage="Couldn't fetch data. Please try again"
      />

      <div className="row">
        <div className="col-sm-4">
          <small>Table colors explanation:</small>
          <table className="table table-striped custom-table">
            <tbody>
              <tr className={"table-warning"}>
                <td>One of vendors was not paid</td>
              </tr>
              <tr className={"table-danger"}>
                <td>Both vendors were not paid</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <EditModal
        key={"em-" + state.editModalKey}
        onMarkVendorPayoutAsPaid={markVendorPayoutAsPaid}
        onApply={createPayout}
        onUpdate={onUpdatePayout}
      />
      {/*
        <ViewPayoutModal
          key={"em-"}
        />*/}
    </PageTemplate>
  );
};

export default PayoutsPage;
