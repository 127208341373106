import { getWebBrowserCountryCode } from "./general";

/**
 * Tells if the given dates are the same day.
 * @param startDate
 * @param endDate
 * @returns {boolean}
 */
export const isSameDay = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);

  return (
    start.getDate() === end.getDate() &&
    start.getMonth() === end.getMonth() &&
    start.getFullYear() === end.getFullYear()
  );
};

Date.prototype.isSameDay = function (compare) {
  const compareDate = new Date(compare);

  return (
    this.getDate() === compareDate.getDate() &&
    this.getMonth() === compareDate.getMonth() &&
    this.getFullYear() === compareDate.getFullYear()
  );
};

Date.prototype.getWeek = function () {
  // Create a copy of this date object
  var target = new Date(this.valueOf());

  // ISO week date weeks start on Monday, so correct the day number
  var dayNr = (this.getDay() + 6) % 7;

  // ISO 8601 states that week 1 is the week with the first Thursday of that year
  // Set the target date to the Thursday in the target week
  target.setDate(target.getDate() - dayNr + 3);

  // Store the millisecond value of the target date
  var firstThursday = target.valueOf();

  // Set the target to the first Thursday of the year
  // First, set the target to January 1st
  target.setMonth(0, 1);

  // Not a Thursday? Correct the date to the next Thursday
  if (target.getDay() !== 4) {
    target.setMonth(0, 1 + ((4 - target.getDay() + 7) % 7));
  }

  // The week number is the number of weeks between the first Thursday of the year
  // and the Thursday in the target week (604800000 = 7 * 24 * 3600 * 1000)
  return 1 + Math.ceil((firstThursday - target) / 604800000);
};

/**
 * Tells if the given dates are the same week.
 * @type {function(*): boolean|boolean}
 */
Date.prototype.isSameWeek = function isSameWeek(compareDate) {
  if (!this.getTime)
    throw new Error("isBetween() was called on a non Date object");

  if (this.getWeek() === compareDate.getWeek()) {
    if (this.getFullYear() === compareDate.getFullYear()) {
      return true;
    }
  }
  return false;
};

/**
 * Formats a date according to the given locale and formatting options.
 * @param {Date} date - The date to format.
 * @param {Object} options - An object specifying the formatting options (e.g., year, month, weekday).
 * @returns {string} - A localized string representing the formatted date.
 */
export const formatDate = (date, options) => {
  const uses12HourFormat = new Intl.DateTimeFormat(
    window.navigator.language || "en-US",
    { hour: "numeric" }
  ).resolvedOptions().hour12;

  const hour12Option = uses12HourFormat ? true : false;

  options = options || {};

  options.hour12 = hour12Option;

  return new Intl.DateTimeFormat(
    window.navigator.language || "en",
    options
  ).format(date);
};

Date.prototype.isBetween = isBetween;
function isBetween(minDate, maxDate) {
  if (!this.getTime)
    throw new Error("isBetween() was called on a non Date object");
  return !minDate
    ? true
    : this.getTime() >= minDate.getTime() && !maxDate
    ? true
    : this.getTime() <= maxDate.getTime();
}

/**
 * Adds or removes week from the current date.
 * @param weeks
 * @param isAdd
 * @returns {Date}
 */
Date.prototype.addOrSubtractWeeks = function (weeks, isAdd) {
  const operator = isAdd ? 1 : -1;
  return new Date(
    this.getTime() + operator * (weeks * 7 * 24 * 60 * 60 * 1000)
  );
};

/**
 * Adds the given number of weeks to the date.
 * @param weeks
 * @returns {Date}
 */
Date.prototype.addWeeks = function (weeks) {
  return this.addOrSubtractWeeks(weeks, true);
};

/**
 * Subtracts the given number of weeks to the date.
 * @param weeks
 * @returns {Date}
 */
Date.prototype.subtractWeeks = function (weeks) {
  return this.addOrSubtractWeeks(weeks, false);
};

/**
 * Returns the number of weeks between the current date and the given date.
 * @param date
 * @returns {number}
 */
Date.prototype.diffWeeks = function (date) {
  const diffTime = this.getTime() - date.getTime();
  return Math.round(diffTime / (1000 * 3600 * 24 * 7));
};

// NOTE: ADD YOUR CODE ABOVE THIS COMMENT!!
// the code below is too long to be scrolled every time, so just add
// your code above.
/**
 *
 * returns the index of the first day of the week.
 * 0 - Sunday,
 * 1 - Monday
 */
const _firstDayOfWeek = () => {
  const mapping = {
    AE: 6,
    AF: 6,
    AG: 0,
    AS: 0,
    AU: 0,
    BD: 0,
    BH: 6,
    BR: 0,
    BS: 0,
    BT: 0,
    BW: 0,
    BZ: 0,
    CA: 0,
    CN: 0,
    CO: 0,
    DJ: 6,
    DM: 0,
    DO: 0,
    DZ: 6,
    EG: 6,
    ET: 0,
    "GB-alt-variant": 0,
    GT: 0,
    GU: 0,
    HK: 0,
    HN: 0,
    ID: 0,
    IL: 0,
    IN: 0,
    IQ: 6,
    IR: 6,
    JM: 0,
    JO: 6,
    JP: 0,
    KE: 0,
    KH: 0,
    KR: 0,
    KW: 6,
    LA: 0,
    LY: 6,
    MH: 0,
    MM: 0,
    MO: 0,
    MT: 0,
    MV: 5,
    MX: 0,
    MZ: 0,
    NI: 0,
    NP: 0,
    OM: 6,
    PA: 0,
    PE: 0,
    PH: 0,
    PK: 0,
    PR: 0,
    PT: 0,
    PY: 0,
    QA: 6,
    SA: 0,
    SD: 6,
    SG: 0,
    SV: 0,
    SY: 6,
    TH: 0,
    TT: 0,
    TW: 0,
    UM: 0,
    US: 0,
    VE: 0,
    VI: 0,
    WS: 0,
    YE: 0,
    ZA: 0,
    ZW: 0,
  };

  const countryCode = (getWebBrowserCountryCode() || "US").toUpperCase();
  const firstDayIndex =
    mapping[countryCode] !== undefined ? mapping[countryCode] : 1;

  return firstDayIndex;
};

export const firstDayOfWeekIndex = _firstDayOfWeek();

/**
 * Returns the first day of the week for the given date.
 * @param dateObject
 * @returns {Date}
 */
export const firstDayOfWeek = (dateObject) => {
  const dayOfWeek = dateObject.getDay(),
    firstDayOfWeek = new Date(dateObject),
    diff =
      dayOfWeek >= firstDayOfWeekIndex
        ? dayOfWeek - firstDayOfWeekIndex
        : 6 - dayOfWeek;

  firstDayOfWeek.setDate(dateObject.getDate() - diff);
  firstDayOfWeek.setHours(0, 0, 0, 0);

  return firstDayOfWeek;
};

window.firstDayOfWeek = firstDayOfWeek;
/**
 * Returns the start of the week for the given date.
 * The start of the week is considered to be Monday.
 *
 * @param {Date} date - The date for which to get the start of the week.
 * @returns {Date} - A new Date object representing the start of the week.
 */
function getStartOfWeek(date) {
  const result = new Date(date);
  const day = result.getDay();
  const diff = result.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is Sunday
  result.setDate(diff);
  return result;
}

/**
 * Returns the end of the week for the given date.
 * The end of the week is considered to be Sunday.
 *
 * @param {Date} date - The date for which to get the end of the week.
 * @returns {Date} - A new Date object representing the end of the week.
 */
export function getEndOfWeek(date) {
  const startOfWeek = getStartOfWeek(date);
  const result = new Date(startOfWeek);
  result.setDate(result.getDate() + 6);
  return result;
}

/**
 * Returns the start of the month for the given date.
 *
 * @param {Date} date - The date for which to get the start of the month.
 * @returns {Date} - A new Date object representing the start of the month.
 */
function getStartOfMonth(date) {
  return new Date(date.getFullYear(), date.getMonth(), 1);
}

/**
 * Returns the end of the month for the given date.
 *
 * @param {Date} date - The date for which to get the end of the month.
 * @returns {Date} - A new Date object representing the end of the month.
 */
export function getEndOfMonth(date) {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
}

/**
 * Get allowed date range based on the given limits
 * @param {Time} startDate - The start date from which the end-user wants to pull the events.
 * @param {Time} endDate - The end date to which the end-user wants to pull the events.
 * @param {Time} limitStartDate - The start date that is set as a limit in the widget settings.
 * @param {Time} limitEndDate - The end date that is set as a limit in the widget settings.
 * @returns {[Time, Time] | [null, null]}
 */
export function getAllowedDateRange(
  startDate,
  endDate,
  limitStartDate,
  limitEndDate
) {
  // type: TIME
  if (endDate.isBefore(limitStartDate) || startDate.isAfter(limitEndDate)) {
    return [null, null];
  }

  const adjustedStartDate = startDate.isBefore(limitStartDate)
    ? limitStartDate
    : startDate;
  const adjustedEndDate = endDate.isAfter(limitEndDate)
    ? limitEndDate
    : endDate;

  return [adjustedStartDate, adjustedEndDate];

  // // type: DATE
  // if (endDate < limitStartDate || startDate > limitEndDate) {
  //   return [null, null];
  // }

  // const adjustedStartDate =
  //   startDate < limitStartDate ? limitStartDate : startDate;
  // const adjustedEndDate = endDate > limitEndDate ? limitEndDate : endDate;

  // return [adjustedStartDate, adjustedEndDate];
}

/**
 * Calculates the final start and end dates for an events date range based on provided dates and limits.
 *
 * @param {Date} startDate - The start date of the range.
 * @param {Date} endDate - The end date of the range.
 * @param {Date} limitStartDate - The minimum limit for the start date.
 * @param {Date} limitEndDate - The maximum limit for the end date.
 * @param {string} [period="week"] - The period type ("week" or "month") to adjust the date range.
 * @returns {Date[]} - An array containing the final start and end dates.
 */
export function getEventsDateRange(
  startDate,
  endDate,
  limitStartDate,
  limitEndDate,
  period = "week"
) {
  const limitStart = new Date(limitStartDate);
  const limitEnd = new Date(limitEndDate);

  let finalStartDate;
  let finalEndDate;

  if ((!startDate && !endDate) || !startDate) {
    // Both adjusted dates are null
    finalStartDate = limitStart;
    finalEndDate =
      period === "week" ? getEndOfWeek(limitStart) : getEndOfMonth(limitStart);
  } else if (!endDate) {
    // Adjusted end date is null
    const start = new Date(startDate);
    finalEndDate =
      period === "week" ? getEndOfWeek(start) : getEndOfMonth(start);

    if (period === "week") {
      finalStartDate = getStartOfWeek(finalEndDate);
      finalStartDate = new Date(Math.max(finalStartDate, limitStart));
    } else {
      finalStartDate = getStartOfMonth(finalEndDate);
      finalStartDate = new Date(Math.max(finalStartDate, limitStart));
    }
  } else {
    // Both adjusted dates are provided
    finalStartDate = new Date(startDate);
    finalEndDate = new Date(endDate);

    finalStartDate = new Date(Math.max(finalStartDate, limitStart));
    finalEndDate = new Date(Math.min(finalEndDate, limitEnd));
  }

  return [finalStartDate, finalEndDate];
}
