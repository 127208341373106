import React from "react";

export const ConjunctionContext = React.createContext({ hovered: false });
export const AvailableRulesOptionsContext = React.createContext(null);
export const FiltersContext = React.createContext({
  filters: { conjunction: "and", groups: [] },
  updateGroup: () => {},
  updateConjunction: () => {},
  updateMainConjunction: () => {},
});
