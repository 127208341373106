/**
 * Created by piotr.pozniak@thebeaverhead.com on 05/04/2022.
 */

import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as widgetsActions from "../../actions/widgets";

export function useWidgetsStore() {
  const dispatch = useDispatch();
  const _widgets = useSelector((store) => store.widgets);

  const fetchWidgets = useCallback(
    async (widgetType) =>
      await dispatch(widgetsActions.fetchWidgets(widgetType)),
    [dispatch]
  );

  return {
    widgets: _widgets,
    fetchWidgets,
  };
}
