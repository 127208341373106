/**
 * Created by piotr.pozniak@thebeaverhead.com on 05/12/2022.
 */

import React, { useState, useEffect, useMemo, useCallback } from "react";

import { Link, useHistory } from "react-router-dom";
import Tos from "../templates/PageTemplate/Tos";
import UnauthorizedUserMobileHelp from "./../component/UnauthorizedUserMobileHelp";
import { AvailableIntegrations } from "../consts/integrations";
import IntegrationButton from "./SelectIntegrationPage/IntegrationButton";
import AxiosConfig from "./../../../AxiosConfig";
import { useUserStore } from "../../../hooks/redux/user";
import LoadingIndicator from "./../../calendar/components/LoadingIndicator";
import appConfig from "../../../appConfig";

const SelectIntegrationPage = (props) => {
  const history = useHistory();
  const [integrationType, setIntegrationType] = useState(null);
  const { user, checkSession } = useUserStore();
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    const token = params.get("token");

    if (token && token !== "" && !user.checkSession) {
      AxiosConfig.setAuthToken(token);
      checkSession();
    } else {
      setShowForm(true);
    }
    history.replace("/select-integration");
  }, []);

  useEffect(() => {
    if (user.checkSessionSuccess) {
      setShowForm(true);
    }
  }, [user.checkSessionSuccess]);

  const integrations = useMemo(() => {
    const _integrations = AvailableIntegrations.map((i) => ({
      title: i.title,
      icon: i.icon,
      type: i.type,
    }));

    const googleIntegrationIdx = _integrations.findIndex(
      (i) => i.type === "google"
    );
    const googleIntegration = _integrations[googleIntegrationIdx];
    delete _integrations[googleIntegrationIdx];
    _integrations.unshift(googleIntegration);

    return _integrations;
  }, []);

  /**
   *
   * @type {function(*): function(*): void}
   */
  const onSelectIntegration = useCallback(
    (type) => (e) => {
      setIntegrationType(type);
    },
    [integrationType]
  );

  const integrationOptions = integrations.map((i) => {
    return (
      <IntegrationButton
        key={i.type}
        title={i.title}
        icon={i.icon}
        isSelected={integrationType === i.type}
        onSelect={onSelectIntegration(i.type)}
      />
    );
  });

  /**
   *
   * @param e
   */
  const onNext = (e) => {
    e.preventDefault();

    history.push(`/integration/new/${integrationType}`);
  };

  const title = showForm
    ? "Which integration do you want to setup first?"
    : "Loading...";

  const content = showForm ? (
    <React.Fragment>
      <div className={"select-integration"}>{integrationOptions}</div>
      <div className="form-group text-center">
        <button
          className="btn btn-primary btn-block account-btn m-b-15"
          type="submit"
          onClick={onNext}
          disabled={!integrationType}
        >
          Next
        </button>
        <Link to={"/dashboard"} className={"text-primary"}>
          Skip for now
        </Link>
      </div>
    </React.Fragment>
  ) : (
    <div className="form-group text-center">
      <LoadingIndicator />
    </div>
  );
  return (
    <div className="main-wrapper">
      <div className="account-page">
        <div className="container">
          <div className="account-logo">
            <Link to="/">
              <img
                src={`/img/branding/${appConfig.branding}/full_length_logo.png`}
                alt={appConfig.displayName}
              />
            </Link>
          </div>
          <div className="account-box">
            <div className="account-wrapper">
              <h3 className="account-title">{title}</h3>
              {content}
            </div>
            <UnauthorizedUserMobileHelp />
          </div>

          <Tos />
        </div>
      </div>
    </div>
  );
};

export default SelectIntegrationPage;
