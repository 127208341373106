/**
 * Created by piotr.pozniak@thebeaverhead.com on 19/07/2023
 */

import React, { useCallback, useContext, useRef } from "react";
import PropTypes from "prop-types";
import { useEventsStore } from "../../../../hooks/redux/events";
import classnames from "classnames";
import Suggestions from "./Suggestions";
import WidgetSettingsContext from "../../../../contexts/WidgetSettingsContext";

const SearchInput = (props) => {
  const widgetSettings = useContext(WidgetSettingsContext);
  const { events } = useEventsStore();
  const showSearch = widgetSettings["aiEnabled"];
  const showSuggestions = widgetSettings["aiShowSuggestions"];
  const inputRef = useRef();

  const placeholder = widgetSettings["aiPlaceholder"];
  const suggestions = widgetSettings["aiSuggestions"].split(";");

  const onKeyUp = useCallback((e) => {
    // on enter button
    if (e.keyCode === 13) {
      if (e.target.value.length) {
        props.searchEvents(e.target.value);
      } else {
        props.fetchEvents();
      }
    }
    //    console.log(e.target.value);
  }, []);

  /**
   *
   * @type {(function(*): void)|*}
   */
  const onSuggestion = useCallback(
    (value) => {
      props.searchEvents(value);
      inputRef.current.value = value;
    },
    [inputRef.current]
  );

  if (!showSearch) {
    return null;
  }

  const suggestionsComponent = showSuggestions ? (
    <Suggestions suggestions={suggestions} onSuggestionClick={onSuggestion} />
  ) : null;

  return (
    <div className={classnames(props.className, "dc-ai-search")}>
      <div
        className={classnames("dc-ai-search--container", {
          searching: events.fetch,
        })}
      >
        <input
          type={"text"}
          ref={inputRef}
          onKeyUp={onKeyUp}
          className={"dc-ai-search--container__input"}
          placeholder={placeholder}
        />
        <span className={"material-icons"}>search</span>
        <span className={"dc-ai-search--container__blur"} />
        <span className={"dc-ai-search--container__process"} />
      </div>
      {suggestionsComponent}
    </div>
  );
};

SearchInput.defaultProps = {
  className: "",
};

SearchInput.propTypes = {
  fetchEvents: PropTypes.func.isRequired,
  searchEvents: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default SearchInput;
