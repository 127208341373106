/**
 * Created by piotr.pozniak@thebeaverhead.com on 15/12/2020.
 */

import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import CardEvent from "./CardEvent";
import RowModal from "../EventsList/RowModal";
import InfiniteScrollWrapper from "../InfiniteScrollWrapper";
import LoadingIndicator from "../LoadingIndicator";
import { CT, PT } from "../../../../consts";
import PoweredBy from "../PoweredBy";
import CalendarHeader from "../CalendarHeader";
import NoEvents from "../NoEvents";
import { openEventPage } from "../../../../helpers/calendar";
import SearchInput from "../AiSearch/SearchInput";
import useAiSearch from "../../../../hooks/useAiSearch";
import WidgetSettingsContext from "../../../../contexts/WidgetSettingsContext";
import SharePopup from "../../../../WidgetsComponents/SharePopup";

const CardView = (props) => {
  const [state, setState] = useState({
    selectedEvent: null,
  });

  const { calendar, events, topMatches } = props;

  const { hasAIEnabled } = useAiSearch(calendar.model.integration);
  const widgetSettings = useContext(WidgetSettingsContext);
  const loading =
    calendar.rsvp ||
    calendar.fetch ||
    calendar.delete ||
    calendar.update ||
    events.fetch;

  const showAsList = calendar.model && calendar.model.template == "card_list";

  const inpicDatePlacement = widgetSettings["cwDateLocation"];
  const showDate = Number.parseInt(widgetSettings["ccShowDate"]) === 1;
  const selectedDateTimeFormat = widgetSettings["ccShowTimeOptions"];
  const cardsPerRow =
    (widgetSettings["cwColsCount"] ? widgetSettings["cwColsCount"] : "").split(
      "1fr"
    ).length - 1;
  const showTitle = Number.parseInt(widgetSettings["ccShowTitle"]) === 1;
  const showLocation = Number.parseInt(widgetSettings["ccShowLocation"]) === 1;
  const showDescription =
    Number.parseInt(widgetSettings["ccShowDescription"]) === 1;
  const showSummary = Number.parseInt(widgetSettings["ccShowSummary"]) === 1;
  const hoverEffect = widgetSettings["cwHoverEffect"];
  const showWeekDayInList =
    Number.parseInt(widgetSettings["eventDetailsShowDayOfWeek"]) === 1;
  const weekDayFormatter = widgetSettings["eventsShowDayWeekOption"];
  const showDetailsAsModal =
    Number.parseInt(widgetSettings["generalOpenDetailsModal"]) === 1;
  const openChurchCenter =
    Number.parseInt(widgetSettings["generalGoToChurchCenter"]) === 1;

  const openLinkInNewTab =
    Number.parseInt(widgetSettings["openLinksNewTab"]) === 1;

  const useEventAccent =
    Number.parseInt(widgetSettings["generalUseEventAccent"]) === 1;
  const showImage =
    Number.parseInt(widgetSettings["eventDetailsShowImage"]) == 1;

  const hasInfiniteScroll =
    Number.parseInt(widgetSettings["infiniteScroll"]) === 1;

  const recurringShowBadge =
    Number.parseInt(widgetSettings["recurringShowBadge"]) === 1;
  const featuredShowBadge =
    Number.parseInt(widgetSettings["featuredShowBadge"]) === 1;

  const badgesLocation = widgetSettings["recurringBadgeLocation"];

  const showCardCta = Number.parseInt(widgetSettings["cCardShowCta1st"]) === 1;
  const cCardCtaButtonFullWidth =
    Number.parseInt(widgetSettings["cCardCtaButtonFullWidth"]) === 1;

  const bestResultsLabel = widgetSettings["aiBestMatchesLabel"];
  const bestRemainingResultsLabel = widgetSettings["aiRemainingResultsLabel"];
  const showImageContainer =
    Number.parseInt(widgetSettings["eventDetailsShowImageContainer"]) === 1;

  const triggerCTAOnCardClick =
    Number.parseInt(widgetSettings["cCardActionOnClick"]) === 1;

  const showAccentColorOnCard =
    Number.parseInt(widgetSettings["cwShowAccentBar"]) === 1;

  const watermark =
    calendar.model && calendar.model.is_watermarked ? (
      <PoweredBy align={hasInfiniteScroll ? "right" : "left"} />
    ) : null;

  /**
   *
   * @param event
   */
  const onSelectedEvent = (event) => {
    if (openChurchCenter) {
      openEventPage(event, openLinkInNewTab);
      return;
    }
    if (showDetailsAsModal) {
      setState({ ...state, selectedEvent: event });
    }
  };

  /**
   *
   * @param rowId
   */
  const onCloseOpenedEventsDetails = (rowId) => {
    setState({ ...state, selectedEvent: null });

    if (props.onClosedEventRowModal) {
      props.onClosedEventRowModal();
    }
  };

  const calendarID = "dce_calendar__" + calendar.model.uuid;

  const cardCreator = (i, idx) => {
    return (
      <CardEvent
        key={i.slug}
        event={i}
        onSelectedEvent={onSelectedEvent}
        inpicDatePlacement={inpicDatePlacement}
        showDate={showDate}
        showTitle={showTitle}
        showLocation={showLocation}
        showDescription={showDescription}
        showSummary={showSummary}
        hoverEffect={hoverEffect}
        embedded={props.embedded}
        showWeekDay={showWeekDayInList}
        weekDayFormatter={weekDayFormatter}
        useEventAccent={useEventAccent}
        showImage={showImage}
        showRecurringBadge={recurringShowBadge}
        showFeaturedBadge={featuredShowBadge}
        badgesLocation={badgesLocation}
        showCardCta={showCardCta}
        selectedDateTimeFormat={selectedDateTimeFormat}
        showAsList={showAsList}
        showImageContainer={showImageContainer}
        cCardCtaButtonFullWidth={cCardCtaButtonFullWidth}
        triggerCTAOnCardClick={triggerCTAOnCardClick}
        showAccentOnCard={showAccentColorOnCard}
      />
    );
  };

  const cardEvents = events.collection.map(cardCreator);

  const topMatchingEvents = events.topMatchesCollection
    ? events.topMatchesCollection.slice(0, cardsPerRow).map(cardCreator)
    : [];

  const modalEvent = state.selectedEvent ? (
    <RowModal
      event={state.selectedEvent}
      showDetails={true}
      calendarSlug={calendar.model.slug}
      embedded={props.embedded}
      ccbAddress={calendar.model.integration.address}
      onRSVPEvent={props.onRSVPEvent}
      loading={props.loading}
      error={props.error}
      closeOpenedEventsDetails={onCloseOpenedEventsDetails}
      expandedRowSlug={state.selectedEvent.slug}
      expandAsAccordion={false}
      expandAsModal={true}
      calendarID={calendarID}
      showWatermark={calendar.model && calendar.model.is_watermarked}
    />
  ) : null;
  const loadingIndicator = loading ? <LoadingIndicator /> : null;

  const noEvents = !loading && !events.collection.length ? <NoEvents /> : null;

  const search =
    hasAIEnabled && widgetSettings["aiEnabled"] ? (
      <SearchInput
        searchEvents={props.searchEvents}
        fetchEvents={props.fetchEvents}
      />
    ) : null;

  const topMatchesContent = topMatchingEvents.length ? (
    <React.Fragment>
      <div className={"card-view--top-matches-container"}>
        <h3 className={"card-view--top-matches-container_header"}>
          {bestResultsLabel}
        </h3>
      </div>
      <div
        className={classnames("card-view", {
          "card-view-horizontal": showAsList,
        })}
      >
        {topMatchingEvents}
      </div>
    </React.Fragment>
  ) : null;

  //console.log('has more', events.collection.length, events.totalRows, events.collection.length != events.totalRows)
  return (
    <div className={classnames("calendar-card-view", `dce--${CT.card}`)}>
      <CalendarHeader
        disabled={events.fetch}
        calendar={calendar}
        currentDate={props.selectedDate}
        onChangeDate={props.onSelectedDateChange}
        events={events.collection}
        locations={events.locations}
        onUpcomingNavigationChange={props.onUpcomingNavigationChange}
        selectedFilters={props.selectedFilters}
        onChangeFilter={props.onChangeFilter}
      />

      {search}
      {noEvents}

      {topMatchesContent}
      {topMatchesContent ? (
        <div className={"card-view--top-matches-container"}>
          <h3 className={"card-view--top-matches-container_header"}>
            {bestRemainingResultsLabel}
          </h3>
        </div>
      ) : null}
      <InfiniteScrollWrapper
        className={classnames("card-view", {
          "card-view-horizontal": showAsList,
        })}
        onLoadMore={props.onLoadMore}
        hasMore={events.collection.length != events.totalRows}
        loading={loading}
        embedded={props.embedded}
      >
        {cardEvents}
      </InfiniteScrollWrapper>
      {watermark}

      {loadingIndicator}

      {modalEvent}
    </div>
  );
};

CardView.propTypes = {
  calendar: PropTypes.object,
  events: PropTypes.object,
  onSelectedDateChange: PropTypes.func.isRequired,
  onUpcomingNavigationChange: PropTypes.func.isRequired,
  selectedDate: PropTypes.object,
  initialized: PropTypes.bool,
  embedded: PropTypes.bool,
  previewType: PropTypes.oneOf(Object.values(PT)),
  onLoadMore: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  selectedFilters: PropTypes.object.isRequired,
};

export default CardView;
