/**
 * Created by enes.kahrovic@thebeaverhead.com on 24/10/2018.
 */

import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDomainsStore } from "../../../hooks/redux/domains";
import LoadingIndicator from "../../calendar/components/LoadingIndicator";
import ApiError from "../component/ApiError";
import { updateJquery } from "../tools";
import TableRow from "./DomainsPage/TableRow";
import PageTemplate from "./../templates/PageTemplate";
import { useCurrentOrganizationStore } from "../../../hooks/redux/currentOrganization";
import { hasSubscription } from "../helpers/organization";

const DomainsPage = (props) => {
  const history = useHistory();

  const { domains, fetchDomains, deleteDomain, updateDomain } =
    useDomainsStore();
  const { currentOrganization } = useCurrentOrganizationStore();

  const subscription = hasSubscription(currentOrganization);

  useEffect(() => {
    fetchDomains();
  }, []);

  useEffect(() => {
    if (domains.fetchSuccess) {
      updateJquery();
    }
  }, [domains.fetchSuccess]);

  /**
   * Handles the delete domain action.
   *
   * @param domain
   */
  const onDelete = (domain) => {
    if (
      window.confirm(
        "Are you sure you want to delete the website " + domain.domain + "?"
      )
    ) {
      deleteDomain(domain.uuid);
    }
  };

  /**
   * Handle the domain status update action.
   *
   * @param domain
   */
  const onChangeStatus = (domain) => {
    domain.available = domain.available ? 0 : 1;

    updateDomain(domain.uuid, domain);
  };

  /**
   * Handles the add domain action.
   */
  const onAddNewDomain = () => {
    const domainsLimit =
      subscription && subscription.domains_limit
        ? subscription.domains_limit
        : 0;

    if (
      domains.collection.filter((i) => Number(i.available)).length >=
      domainsLimit
    ) {
      return alert(
        "You have reached the domains limit for your subscription (which is " +
          domainsLimit +
          ").\nIf you need to embed the calendar on more websites, please upgrade your plan."
      );
    }

    history.push("/domains/new/");
  };

  const loading = domains.fetch || domains.delete || domains.sync;

  const domainsLimit =
    subscription && subscription.domains_limit ? subscription.domains_limit : 0;

  const domainsList = domains.collection.map((item, idx) => {
    return (
      <TableRow
        disabled={loading}
        key={idx}
        domain={item}
        onDelete={onDelete}
        onChangeStatus={onChangeStatus}
      />
    );
  });

  const loadingIndicator = loading ? <LoadingIndicator /> : null;

  const welcomePanel = !domains.fetch ? (
    <div className="content container-fluid">
      <div className="row">
        <div className="col-sm-12">
          <h4 className="page-title">&nbsp;</h4>
        </div>
      </div>

      <div className="row">
        <div className="col-md-8">
          <div className="blog-view">
            <article className="blog blog-single-post">
              <h3 className="blog-title">Grant Access to your Websites</h3>
              <div className="blog-content">
                <p>
                  Point to websites which you would like to allow to display
                  your calendar and/or group views.
                  <br />
                  Only websites listed on this page will be allowed to embed the
                  calendar widget and/or group widget.
                </p>
                <p>
                  You do not need to configure any website to share the
                  calendars' and/or groups' URL.
                </p>
                <p>
                  Your subscription limit: <strong>{domainsLimit}</strong>{" "}
                  public domains
                </p>
              </div>
            </article>
          </div>
        </div>
        <div className="col-md-4 ">
          {/*<img style={{ width: "100%" }} src="/img/automatic_syncing.png"  className="visible-md"/>*/}
        </div>
      </div>
    </div>
  ) : null;

  const domainsData = domains.collection.length ? (
    <table className="table table-striped custom-table">
      <thead>
        <tr>
          <td></td>
          <th width="70%">Domain</th>
          <th width="20%">Status</th>
          <th className="text-right">Action</th>
        </tr>
      </thead>
      <tbody>{domainsList}</tbody>
    </table>
  ) : (
    welcomePanel
  );

  return (
    <PageTemplate
      sidebarId="domains"
      title={"Your Websites"}
      icon={"language"}
      headerControls={[
        <a
          className="btn btn-primary btn-rounded pull-right text-white d-flex"
          onClick={!loading ? () => onAddNewDomain() : null}
        >
          <i className="material-icons">add</i> Add Website
        </a>,
      ]}
    >
      <div className="row">
        <div className="col-md-12">
          <div className="table-responsive">
            {domainsData}

            {loadingIndicator}

            <ApiError
              error={domains.deleteError}
              defaultErrorMessage={
                "Could not delete the domain. Please try again"
              }
            />
            <ApiError error={domains.updateError} />
          </div>
        </div>
      </div>

      <small>
        For testing and development purposes,
        <br />
        these domains are always allowed to embed the calendars:
        <ul>
          <li>localhost</li>
          <li>localhost:8080</li>
          <li>localhost:48081</li>
        </ul>
      </small>
    </PageTemplate>
  );
};

export default DomainsPage;
