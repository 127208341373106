/**
 * Created by piotr.pozniak@thebeaverhead.com on 09/06/2021.
 */

import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as calendarActions from "../../actions/calendar";
import { setCalendar } from "../../actions/calendar";

export function useCalendarStore() {
  const dispatch = useDispatch();
  const _calendar = useSelector((store) => store.calendar);

  const fetchCalendar = useCallback(
    async (uuid) => await dispatch(calendarActions.fetchCalendar(uuid)),
    [dispatch]
  );

  const deleteCalendar = useCallback(
    async (uuid) => await dispatch(calendarActions.deleteCalendar(uuid)),
    [dispatch]
  );

  const updateCalendar = useCallback(
    async (uuid, data) =>
      await dispatch(calendarActions.updateCalendar(uuid, data)),
    [dispatch]
  );

  const setCalendar = useCallback(
    async (model) => await dispatch(calendarActions.setCalendar(model)),
    [dispatch]
  );

  return {
    calendar: _calendar,
    deleteCalendar,
    updateCalendar,
    fetchCalendar,
    setCalendar,
  };
}
