/**
 * Created by piotr.pozniak@thebeaverhead.com on 03/06/2021.
 */
import FilterLabelCTA from "./BreezeFilteringStrategy/FilterLabelCTA";
import CalendarIntegrationStrategy from "./CalendarIntegrationStrategy";
import PrivateEventsSyncWarning from "./BreezeFilteringStrategy/PrivateEventsSyncWarning";
import { PLANS } from "../../../consts";
import FeaturedFilterLabelCTA from "./BreezeFilteringStrategy/FeaturedFilterLabelCTA";

class BreezeStrategy extends CalendarIntegrationStrategy {
  availableFilters = ["calendar", "tag", "location"];

  filtersSettings = {
    calendar: {
      label: "Calendars",
      tooltip: null,
      tooltipFeatured: {
        url: "https://help.display.church/en/articles/5815427-setup-design-featured-events",
        message: "Feature events for these calendars",
      },
    },
    tag: {
      label: "Tags",
      tooltip: null,
      tooltipFeatured: {
        url: "https://help.display.church/en/articles/5815427-setup-design-featured-events",
        message: "Feature events for these tags",
      },
    },
    location: {
      label: "Locations",
      tooltip: null,
      tooltipFeatured: {
        url: "https://help.display.church/en/articles/5815427-setup-design-featured-events",
        message: "Feature events for these locations",
      },
    },
  };

  filterLabelCTA = FilterLabelCTA();
  featuredFilterLabelCTA = FeaturedFilterLabelCTA();

  filtersAllowedPlans = {
    calendar: [PLANS.free, PLANS.starter, PLANS.medium, PLANS.highest],
    tag: [PLANS.free, PLANS.starter, PLANS.medium, PLANS.highest],
    location: [PLANS.free, PLANS.starter, PLANS.medium, PLANS.highest],
  };

  featuredAllowedPlans = [PLANS.medium, PLANS.highest];
  privateEventsSettingsWarning = PrivateEventsSyncWarning;

  /**
   *
   * @param data
   * @returns {{}}
   */
  filterRawDataToStateObject = (data) => {
    const filterObjects = this.availableFilters.reduce(
      (i, j) => ({ ...i, [j]: [] }),
      {}
    );

    data.map((filter) => {
      filter.value = filter.platform_metadata_id;
      filter.uuid = filter.platform_metadata_id;
      filter.label = this.filterRenderName(filter);
      filterObjects[filter.kind].push(filter);
    });

    return filterObjects;
  };

  /**
   *
   * @param data
   * @returns {*}
   */
  filterRawToDisplayObject = (data, showId) => {
    return data.map((filter) => {
      filter.value = filter.platform_metadata_id;
      filter.uuid = filter.platform_metadata_id;
      filter.label = this.filterRenderName(
        { ...filter.data, ...filter },
        showId
      );
      return filter;
    });
  };

  /**
   *
   * @param data
   * @param showInactive
   * @returns {*}
   */
  filterRenderName = (filter, showId = false) => {
    let name = filter.data.name;

    if (showId) {
      name +=
        (filter.platform_metadata_id
          ? " [" + filter.platform_metadata_id + "]"
          : "") +
        (filter.data.inactive && filter.data.inactive === "true"
          ? " (inactive)"
          : "");
    }

    return name;
  };

  _filters = [
    {
      field: {
        value: "calendar",
        label: "Calendars",
      },
      allowedPlans: [PLANS.free, PLANS.starter, PLANS.medium, PLANS.highest],
    },
    {
      field: {
        value: "tag",
        label: "Tags",
      },
      allowedPlans: [PLANS.free, PLANS.starter, PLANS.medium, PLANS.highest],
    },
    {
      field: {
        value: "location",
        label: "Locations",
      },
      allowedPlans: [PLANS.free, PLANS.starter, PLANS.medium, PLANS.highest],
    },
  ];

  canUserFilter = {
    calendar: true,
    tag: true,
    location: true,
  };
}

export default BreezeStrategy;
