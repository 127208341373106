/**
 * Created by piotr.pozniak@thebeaverhead.com on 28/09/2023.
 */

import axios from "axios";
import AxiosConfig from "../AxiosConfig";

/**
 *
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export function setWidget(widgetModel) {
  return async (dispatch) => {
    dispatch({
      type: "WIDGET_SET",
      payload: widgetModel,
    });
  };
}

/**
 *
 * @param uuid
 * @param data
 * @returns {function(*): Promise<AxiosResponse<any>>}
 */
export function updateWidget(uuid = null, data) {
  return async (dispatch) => {
    dispatch({ type: "WIDGET_UPDATE", payload: { widgetUUID: uuid } });

    const url = "/api4/widgets" + (uuid ? "/" + uuid : "") + ".json";

    const action = uuid ? axios.put : axios.post;

    return await action(url, data, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "WIDGET_UPDATE_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "WIDGET_UPDATE_REJECTED",
          payload: error,
        });
      });
  };
}

/**
 *
 * @param email
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export function deleteWidget(uuid) {
  return async (dispatch) => {
    dispatch({ type: "WIDGET_DELETE", payload: { widgetUUID: uuid } });

    const url = "/api4/widgets/" + uuid + ".json";

    return await axios
      .delete(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "WIDGET_DELETE_FULFILLED",
          payload: { uuid },
        });
      })
      .catch(function (error) {
        dispatch({
          type: "WIDGET_DELETE_REJECTED",
          payload: error,
        });
      });
  };
}

/**
 *
 * @param widgetType
 * @param uuid
 * @returns {function(*): Promise<AxiosResponse<any>>}
 */
export function fetchWidget(widgetType, uuid) {
  return function (dispatch) {
    dispatch({ type: "WIDGET_FETCH" });

    const url = `/api4/widgets/${uuid}.json?widget_type=${widgetType}`;

    return axios
      .get(
        AxiosConfig.getEndpointAddress() + url,
        AxiosConfig.getAuthToken()
          ? AxiosConfig.getAuthConfig()
          : AxiosConfig.getConfig()
      )
      .then((response) => {
        dispatch({
          type: "WIDGET_FETCH_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        console.log(error.message);
        if (
          error.message &&
          typeof error.message === "string" &&
          error.message.match(/Network Error/)
        ) {
          dispatch({
            type: "CORS_ERROR",
            payload: error,
          });
        } else {
          dispatch({
            type: "WIDGET_FETCH_REJECTED",
            payload: error,
            unpublished:
              typeof error === "object" &&
              error.response &&
              error.response.status === 403,
          });
        }
      });
  };
}

/**
 *
 * @param widget
 * @returns {{template, available, name, widget_filters_uuids: (*|*[]), widget_settings, integration_uuid}}
 */
export const getWidgetCurrentData = (widget) => {
  return {
    integration_uuid: widget.integration.uuid,
    available: widget.available,
    name: widget.name,
    widget_type: widget.widget_type,
    template: widget.template,
    widget_settings: { ...widget.widget_settings },
    template_settings: { ...widget.template_settings },
    filter_settings: { ...widget.filter_settings },
  };
};
