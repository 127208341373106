/**
 * Created by piotr.pozniak@thebeaverhead.com on 09/06/2021.
 */

import React, { useContext } from "react";
import DesignTooltip from "./DesignTooltip";
import { isCTAllowed } from "../../../../../helpers/calendar";
import { BuilderInputSetupPropTypes } from "./BuilderOptionPropTypes";
import PropTypes from "prop-types";
import WidgetSettingsContext from "../../../../../contexts/WidgetSettingsContext";
import Input from "./RadioSetupInput/Input";

const RadioSetup = (props) => {
  const { defaultValue, title, name, show, template, settingName } = props;
  const { options } = props.control;

  const widgetSettingsContext = useContext(WidgetSettingsContext);
  if (!show) {
    return null;
  }

  const currentValue = widgetSettingsContext[name + "Option"] || defaultValue;

  const radios = options.radioOptions
    .map((i, idx) => {
      if (!isCTAllowed(template, i.template)) {
        return null;
      }

      const customInputValue =
        widgetSettingsContext[name] || (i.input ? i.input.defaultValue : null);

      const input = i.input ? (
        <Input
          {...i.input}
          disabled={currentValue != i.value}
          updateDesignProp={props.updateDesignProp}
          name={name}
          customInputValue={customInputValue}
        />
      ) : null;

      return (
        <div className="radio" key={`rio-${settingName}-${name}-${idx}`}>
          <label>
            <input
              type="radio"
              name={name}
              checked={
                (widgetSettingsContext[name + "Option"] || defaultValue) ===
                i.value
              }
              onChange={(event) =>
                props.updateDesignProp([
                  { field: name + "Option", value: i.value },
                  {
                    field: name,
                    value: (i.input && i.input.defaultValue) || "",
                  },
                ])
              }
            />{" "}
            {i.title}
          </label>
          {input}
        </div>
      );
    })
    .filter((i) => i);

  if (!radios.length) {
    return null;
  }

  return (
    <div className={"design-element radio"}>
      <label className="option-label">
        <span className={"option-title"}>{title}</span>
        <DesignTooltip help={props.tooltip} />
      </label>
      {radios}
    </div>
  );
};

RadioSetup.propTypes = {
  ...BuilderInputSetupPropTypes,

  control: PropTypes.shape({
    kind: PropTypes.oneOf(["radio"]).isRequired,
    radioOptions: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired,
        template: PropTypes.arrayOf([PropTypes.string]),
        input: PropTypes.shape({
          defaultValue: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
          ]),
          postLabel: PropTypes.string,
        }),
      })
    ),
  }).isRequired,
};

export default RadioSetup;
