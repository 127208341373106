import React, { useContext } from "react";
import parse, { domToReact } from "html-react-parser";
import PropTypes from "prop-types";

import DetailsRow from "./DetailsRow";
import WidgetSettingsContext from "../../../../contexts/WidgetSettingsContext";
import YouTubePreview from "./YoutubePreview";
import VimeoPreview from "./VimeoPreview";
import { useTranslation } from "react-i18next";

const hasYouTubeLink = (str) => /youtube.com|youtu.be/.test(str);
const hasVimeoLink = (str) => /vimeo.com/.test(str);

const renderVideoPreview = (url) => {
  if (hasYouTubeLink(url)) {
    const videoMatch = url.match(
      /(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))([\w-]{11})/
    );
    const videoId = videoMatch?.[1];
    return videoId ? <YouTubePreview videoId={videoId} /> : null;
  } else if (hasVimeoLink(url)) {
    const videoId = url.match(/(?:vimeo\.com\/|video\/)(\d+)/)?.[1];
    return videoId ? <VimeoPreview videoId={videoId} /> : null;
  }
  return null;
};

const getParseOptions = (
  turnVideoLinkIntoPreview,
  openLinksInNewTab,
  underlineLinks
) => ({
  replace: (domNode) => {
    const { type, name, attribs, children, data } = domNode;

    if (turnVideoLinkIntoPreview) {
      if (type === "tag" && name === "a" && attribs?.href) {
        const url = attribs.href;
        const VideoPreviewComponent = renderVideoPreview(url);

        if (!VideoPreviewComponent) {
          const newAttribs = {
            ...attribs,
            target: openLinksInNewTab ? "_blank" : "_self",
            style: underlineLinks
              ? { textDecoration: "underline" }
              : attribs.style,
          };
          return <a {...newAttribs}>{domToReact(children)}</a>;
        }

        return VideoPreviewComponent;
      } else if (type === "text" && data) {
        const VideoPreviewComponent = renderVideoPreview(data);
        return VideoPreviewComponent || null;
      }
    } else {
      if (type === "tag" && name === "a") {
        const newAttribs = {
          ...attribs,
          target: openLinksInNewTab ? "_blank" : "_self",
          style: underlineLinks
            ? { textDecoration: "underline" }
            : attribs.style,
        };
        return <a {...newAttribs}>{domToReact(children)}</a>;
      }
    }
  },
});

const EventDescription = React.memo(({ event }) => {
  const { t } = useTranslation();
  const settingsContext = useContext(WidgetSettingsContext);

  const openLinksInNewTab = Number(settingsContext["openLinksNewTab"]) === 1;
  const underlineLinks = Number(settingsContext["generalUnderlineLinks"]) === 1;
  const turnVideoLinkIntoPreview =
    Number(settingsContext["turnVideoLinkIntoPreview"]) === 1;

  const parsedDescription = event.description
    ? parse(
        event.description,
        getParseOptions(
          turnVideoLinkIntoPreview,
          openLinksInNewTab,
          underlineLinks
        )
      )
    : "";

  return (
    <DetailsRow title={t("Event details")} icon="reorder">
      <div className={"event-row--details__pre"}>{parsedDescription}</div>
    </DetailsRow>
  );
});

EventDescription.propTypes = {
  event: PropTypes.object.isRequired,
};

export default EventDescription;
