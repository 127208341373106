/**
 * Created by piotr.pozniak@thebeaverhead.com on 19/04/2023
 */

import React from "react";
import PropTypes from "prop-types";

const BreezeForm = (props) => {
  return <div></div>;
};

BreezeForm.defaultProps = {};

BreezeForm.propTypes = {};

export default BreezeForm;
