/**
 * Created by piotr.pozniak@thebeaverhead.com on 24/08/2022
 */

import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";

import {
  AffiliateCouponProps,
  applyCouponDiscount,
} from "../../component/Pricing";

const CouponForm = (props) => {
  const [showCouponForm, setShowCouponForm] = useState(
    props.coupon ? true : false
  );
  const { coupon, regularPrice, nextBillingPrice } = props;

  /**
   *
   * @type {(function(*): void)|*}
   */
  const toggleCoupon = useCallback(
    (e) => {
      e.preventDefault();
      setShowCouponForm(true);
      props.onUseDiscount(true);
    },
    [showCouponForm]
  );

  /**
   *
   * @type {(function(*): void)|*}
   */
  const onCancelCoupon = useCallback(
    (e) => {
      setShowCouponForm(false);
      props.onUseDiscount(false);
    },
    [showCouponForm]
  );

  if (!coupon) {
    return null;
  }

  const cancelCouponButton = coupon ? (
    <button
      className={"btn btn-default d-flex align-items-center"}
      onClick={onCancelCoupon}
      type={"button"}
    >
      <i className={"material-icons"}>close</i>
    </button>
  ) : null;

  const nextBillingPriceThen =
    coupon && coupon.duration !== "once"
      ? applyCouponDiscount(coupon, nextBillingPrice)
      : nextBillingPrice;

  const appliedCouponSummary = coupon ? (
    <p className={"text-secondary"}>
      You will be charged ${applyCouponDiscount(coupon, regularPrice)} now, then
      ${nextBillingPriceThen} after that.
    </p>
  ) : null;

  const couponForm = showCouponForm ? (
    <div className="form-group ">
      <div className={"input-group is-valid"}>
        <input
          id="coupon"
          className={"form-control input-coupon bg-success"}
          type="text"
          defaultValue={coupon.name}
          disabled={true}
        />
        {cancelCouponButton}
      </div>

      {appliedCouponSummary}
    </div>
  ) : (
    <div className={"bg-info p-2"}>
      <a href={"#"} onClick={toggleCoupon} className={"text-white"}>
        You qualify for an affiliate deal <strong>{coupon.name}</strong>. Apply
        now.
      </a>
    </div>
  );

  return <div>{couponForm}</div>;
};

CouponForm.defaultProps = {};

CouponForm.propTypes = {
  coupon: AffiliateCouponProps,
  onUseDiscount: PropTypes.func.isRequired,
  regularPrice: PropTypes.number,
  nextBillingPrice: PropTypes.number,
};

export default CouponForm;
