// CssBoxInput.js
import React, { useCallback } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const CssBoxInput = (props) => {
  const { title, value, setValue, placeholder, position, inputClassName } =
    props;

  const onInputValueChange = useCallback(
    (e) => {
      setValue(e.target.value, position);
    },
    [value, setValue, position]
  );

  const label = title ? (
    <label className="option-label d-flex align-items-center justify-content-center">
      <span className={"option-title"}>{title}</span>
    </label>
  ) : null;

  return (
    <div>
      <input
        value={value}
        onChange={onInputValueChange}
        type="number"
        step={"any"}
        className={classNames("form-control w-100", inputClassName)}
        placeholder={placeholder}
      />
      {label}
    </div>
  );
};

CssBoxInput.defaultProps = {
  inputClassName: "",
  placeholder: "",
  position: "",
};

CssBoxInput.propTypes = {
  setValue: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  position: PropTypes.string,
  inputClassName: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default CssBoxInput;
