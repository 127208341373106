/**
 * Created by piotr.pozniak@thebeaverhead.com on 05/04/2022.
 */

import React from "react";

import PropTypes from "prop-types";
import AvailableInPlans from "./../../templates/NewWidgetPageTemplate/AvailableInPlans";
import classNames from "classnames";

const NewAlertPreview = (props) => {
  const { title, description, thumbnailUrl, features, slug, isSelected } =
    props;

  const previewButton = props.previewUrl ? (
    <a href={props.previewUrl} target="_blank">
      <button className={"btn btn-rounded w-md btn-default"}>
        Live preview
      </button>
    </a>
  ) : null;

  const availabilityInfo = props.canSelect ? (
    <span className={"first-letter small"}>
      <AvailableInPlans forPlans={props.forPlans} />
    </span>
  ) : null;

  const isNew = props.isNew ? (
    <div className={"badge-new-calendar"}>
      <span className={"badge badge-success-border "}>New arrival!</span>
    </div>
  ) : null;
  return (
    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <div className="pricing-box new-calendar">
        {/*<h3 className="">{title}</h3>*/}
        <div className="product-inner text-center">
          <img alt={title} src={thumbnailUrl} />

          {previewButton}
        </div>
        <div className={"product-description-container"}>
          {isNew}
          <div className={"product-description"}>
            <p>{description}</p>
            <ul>
              {features.map((i, idx) => {
                return (
                  <li
                    key={"fl-" + slug + "-" + idx}
                    className={"d-flex align-items-center"}
                  >
                    <i className="material-icons icon-lg" aria-hidden="true">
                      done
                    </i>
                    {i}
                  </li>
                );
              })}
            </ul>
          </div>

          <div className={"d-flex flex-column align-items-center w-100"}>
            <a
              href="#chat_sidebar"
              type="button"
              onClick={props.onSelect}
              className={classNames(
                "btn btn-rounded w-md sidenav_btn  d-xs-block d-sm-block d-md-block d-lg-none d-xl-none  task-chat",
                {
                  "btn-success": isSelected,
                  "btn-primary": !isSelected,
                }
              )}
            >
              {isSelected ? "Selected" : "Select"}
            </a>
            <button
              type="button"
              onClick={props.onSelect}
              className={classNames(
                "btn btn-rounded w-md d-none d-xs-none d-sm-none d-md-none d-lg-block d-xl-block",
                {
                  "btn-success": isSelected,
                  "btn-primary": !isSelected,
                }
              )}
            >
              {isSelected ? "Selected" : "Select"}
            </button>

            {availabilityInfo}
          </div>
        </div>
      </div>
    </div>
  );
};

NewAlertPreview.defaultProps = {
  isNew: false,
};

NewAlertPreview.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  thumbnailUrl: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  features: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  defaultCalendarSettings: PropTypes.object,
  forPlans: PropTypes.array,
  isNew: PropTypes.bool,
};

export default NewAlertPreview;
