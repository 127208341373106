/**
 * Created by enes.kahrovic@thebeaverhead.com on 22/10/2018.
 */

import axios from "axios";
import AxiosConfig from "../AxiosConfig";

/**
 *
 * @param page
 * @param ipp
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export function fetchIntegrations(page = 1, ipp = 50) {
  return function(dispatch) {
    dispatch({ type: "INTEGRATIONS_FETCH" });

    const url = "/api/integrations.json?page=" + page + "&ipp=" + ipp;


    return axios
      .get(
        url,
        AxiosConfig.getAuthConfig()
      )
      .then(response => {

        dispatch({
          type: "INTEGRATIONS_FETCH_FULFILLED",
          payload: response.data
        });
      })
      .catch(function(error) {

        dispatch({
          type: "INTEGRATIONS_FETCH_REJECTED",
          payload: error
        });
      });
  };
}
