/**
 * Created by piotr.pozniak@thebeaverhead.com on 17/03/2023.
 */

import React, { useCallback, useEffect } from "react";

import { Link } from "react-router-dom";
import SidebarTab from "../../../component/WidgetSettings/SidebarTab";
import SuccessAlert from "../../../component/SuccessAlert";
import PropTypes from "prop-types";
import ApiError from "../../../component/ApiError";
import { useCalendarsStore } from "../../../../../hooks/redux/calendars";
import { useFiltersStore } from "../../../../../hooks/redux/filters";
import { alertFeaturesCheck } from "../../../tools";
import ClipboardCopyInput from "../../../component/ClipboardCopyInput";
import LoadingIndicator from "../../../../groups/components/LoadingIndicator";
import { PLAN_NAMES } from "../../../../../consts";
import { useCurrentOrganizationStore } from "../../../../../hooks/redux/currentOrganization";
import Accordion from "../../../component/WidgetSettings/Acordion";
import RadioInput from "../../../component/WidgetSettings/RadioInput";
import { widgetAddress } from "../../../templates/WidgetPageTemplate/InstallTab/helpers";

const InstallTab = (props) => {
  const { widgetModel, widget } = props;
  const widgetType = widget.model.widget_type;
  const { calendars } = useCalendarsStore();
  const { filters } = useFiltersStore();
  const { currentOrganization } = useCurrentOrganizationStore();

  const featureCheck =
    widgetModel && widgetModel.integration && !widget.update
      ? alertFeaturesCheck
      : null;

  const publishIssues = featureCheck
    ? featureCheck(
        { ...widgetModel, available: 1 },
        currentOrganization,
        calendars,
        filters
      )
    : [];

  const publishRestrictionWarning =
    !widgetModel.available && publishIssues.length ? (
      <div className={"alert alert-danger mt-3 p-2"}>
        <i className={"text-danger m-r-5 material-icons icon-sm"}>warning</i>
        You cannot publish this {widgetType} widget because:
        <ul className={"pl-3"}>
          {publishIssues.map((i, idx) => (
            <li key={`cv-${idx}`}>{i}</li>
          ))}
        </ul>
        {props.recommendedPlan && (
          <span>
            Click here to upgrade to the minimum required plan{" "}
            <strong>{PLAN_NAMES[props.recommendedPlan]}</strong>.
          </span>
        )}
        <div className={"d-flex justify-content-center"}>
          <Link to={"/billing"}>
            <button className={"btn btn-success btn-sm"}>Upgrade now</button>
          </Link>
        </div>
      </div>
    ) : null;

  /**
   *
   * @type {(function(*): void)|*}
   */
  const onChangeStatus = useCallback(
    (field) => (e) => {
      props.onUpdateStatus(!(widget.model.available * 1) ? 1 : 0);
    },
    [props.onUpdateStatus, widget]
  );

  const widgetTypeName = props.widgetTypeName.toLowerCase();

  const publishCard = (
    <Accordion
      title={"Publish"}
      subtitle={`Your ${widgetTypeName} is ${
        widget.model.available * 1 == 0 ? "is not" : ""
      } published.`}
      keepExpanded={true}
    >
      <RadioInput
        fieldName={"available"}
        label={"Make this widget available to others"}
        onChange={onChangeStatus}
        checked={widgetModel.available}
        disabled={!widgetModel.available}
        tooltip={
          widget.model.available * 1 === 0
            ? {
                message: `In order to share your ${widgetTypeName} and make it accessible by
              others, change it status to published. You can make it here using
              the toggle button.`,
              }
            : null
        }
      />

      {publishRestrictionWarning}
      <SuccessAlert className={"p-2"}>
        {widget.updateSuccess ? "Your changes have been saved." : null}
      </SuccessAlert>
    </Accordion>
  );

  const noDomainsWarning = !props.hasDomains ? (
    <div
      className="alert alert-danger alert-dismissible fade show alert-tab"
      role="alert"
    >
      Assign <Link to={"/domains"}>website</Link> before embedding this{" "}
      {widgetTypeName}.
    </div>
  ) : null;

  const loaderScriptUrl = `${widgetAddress}/alerts${
    window.__DCE__DEV__ ? "" : ".min"
  }.js`;

  const installScripTag = `<script async id="dc-alerts-script" src="${loaderScriptUrl}"></script>`;

  const instructions = widget.model.available ? (
    <Accordion title={"Installation guide"} keepExpanded={true}>
      <form action="#">
        {noDomainsWarning}
        <div className="col-xs-12">
          <p>
            <em>
              To install the {widgetTypeName} on your website, copy the
              following code:
            </em>
          </p>
        </div>
        <div className="col-xs-12">
          <ClipboardCopyInput
            id={"embeddable_script"}
            value={installScripTag}
            placeholder={"Calendar embeddable script"}
          />
        </div>
        <div className="col-xs-12">
          <p className={"mt-2"}>
            <em>
              and paste it into the <code>&lt;head&gt;</code> section of your
              website.
            </em>
          </p>
        </div>
      </form>
    </Accordion>
  ) : null;

  const footer = !props.disabled ? (
    <div>
      <ApiError
        error={widget.updateError}
        defaultErrorMessage={"Could not save your settings. Please try again"}
      />
      {/*<div className="text-right">
        <button
          disabled={props.disabled}
          type="submit"
          className="btn btn-primary"
          onClick={props.onSubmit}
        >
          Update
        </button>
      </div>*/}
    </div>
  ) : (
    <LoadingIndicator />
  );

  return (
    <SidebarTab id="install_tab" show={false} footer={footer}>
      {publishCard}
      {instructions}
    </SidebarTab>
  );
};

InstallTab.defaultProps = {
  widgetTypeName: "Widget", // or Calendar
};

InstallTab.propTypes = {
  // form fields and errors
  widget: PropTypes.object.isRequired,
  widgetModel: PropTypes.object,

  errors: PropTypes.object,

  onSubmit: PropTypes.func.isRequired,
  onUpdateStatus: PropTypes.func.isRequired,
  onUpdateField: PropTypes.func.isRequired,

  isNew: PropTypes.bool,
  hasDomains: PropTypes.bool,

  disabled: PropTypes.bool,

  widgetTypeName: PropTypes.string,
  widgetType: PropTypes.string,
  shareableUrl: PropTypes.string,

  recommendedPlan: PropTypes.string,
};

export default InstallTab;
