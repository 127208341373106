/**
 * Created by piotr.pozniak@thebeaverhead.com on 17/07/2024
 */

import React from "react";
import PropTypes from "prop-types";

const BrandLogo = ({ organization, settings }) => {
  const hasLogoUploaded = settings?.logo || null;

  const imgSrc = hasLogoUploaded || "/img/organization.png";
  const imgClassName = hasLogoUploaded
    ? "branding--brand-logo"
    : "w-100 circle";

  return (
    <img
      className={imgClassName}
      src={imgSrc}
      alt={`${organization.name} logo`}
      title={`${organization.name} logo`}
    />
  );
};

BrandLogo.defaultProps = {};

BrandLogo.propTypes = {
  organization: PropTypes.object,
  settings: PropTypes.object,
};

export default BrandLogo;
