import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ucFirst } from "../../../helpers/string";
import classnames from "classnames";
import PoweredBy from "../../calendar/components/PoweredBy";
import {
  PLANS,
  PT,
  UNBRANDED_MONTHLY_PRICE,
  UNBRANDED_YEARLY_PRICE,
} from "../../../consts";
import ShowBrandingToggle from "./ShowBrandingToggle";
import classNames from "classnames";
import FeatureFlag from "./FeatureFlag";

export const applyCouponDiscount = (coupon, price) => {
  if (!coupon) {
    return price.toFixed(2);
  }

  let discountedPrice = price * 1;
  if (coupon.percent_off) {
    discountedPrice = (price * (100 - coupon.percent_off)) / 100;
  } else if (coupon.amount_off) {
    discountedPrice = price - coupon.amount_off;
  }

  return discountedPrice.toFixed(2);
};
const Pricing = (props) => {
  /**
   *
   * @param e
   */
  const onChangeBranded = (e) => {
    props.onSwitchPlanBranded(props.plan);
  };

  const { coupon, showAnnual } = props;

  /**
   *
   * @type {(function(*): void)|*}
   */
  const onSelectPlan = useCallback(
    (e) => {
      e.preventDefault();
      props.onSelect(props.plan);
    },
    [props.plan, props.onSelect]
  );

  const plan = props.plan;
  const isBranded = Number.parseInt(plan.is_branded) === 1 ? true : false;

  const annualPriceRegular = plan.price_annual * 1;
  const annualPricePerMonthRegular = annualPriceRegular / 12;
  const discountedAnnualPrice = applyCouponDiscount(coupon, annualPriceRegular);
  const regularPrice = applyCouponDiscount(
    coupon,
    (showAnnual ? annualPriceRegular / 12 : plan.price) * 1
  );

  const regularPricePerMonth = showAnnual
    ? annualPricePerMonthRegular.toFixed(2)
    : plan.price;

  const brandingPriceRegular = showAnnual
    ? UNBRANDED_YEARLY_PRICE
    : UNBRANDED_MONTHLY_PRICE;

  const brandingPrice = applyCouponDiscount(coupon, brandingPriceRegular);

  const price = showAnnual ? regularPrice + " /m" : regularPrice;

  const discountedLabelForAnnual = coupon ? (
    <strike>${annualPriceRegular.toFixed(2)}</strike>
  ) : null;
  const priceforLabel = props.showAnnual ? (
    <span>
      Paid Yearly ({discountedLabelForAnnual} ${discountedAnnualPrice} /yr)
    </span>
  ) : (
    "Per Month"
  );

  const domainsLimitFormatted =
    plan.domains_limit * 1 >= 1000
      ? "For any number of websites:"
      : "For " +
        plan.domains_limit +
        " website" +
        (plan.domains_limit * 1 > 1 ? "s" : "") +
        " only";

  const listFeatures = (domainsLimitFormatted + ";" + props.plan.features)
    .split(";")
    .map((feature, index) => {
      const label = ucFirst(feature.trim());

      return (
        <li
          key={"pf-" + props.plan.uuid + "-" + index}
          className={"pricing-feature"}
        >
          <i className="material-icons" aria-hidden="true">
            check
          </i>
          {index == 0 ? <b>{label}</b> : label}
        </li>
      );
    });

  const title = props.isCurrentPlan ? "YOUR PLAN" : props.plan.name;

  const submitButton = !props.isCurrentPlan ? (
    <a
      href="#"
      className={classnames("btn btn-primary btn-rounded w-md", {
        disabled: props.disabled,
      })}
      onClick={onSelectPlan}
      disabled={props.disabled}
    >
      {props.isPendingPayment ? "Activating..." : props.submitLabel}
    </a>
  ) : null;

  const discountedUnbrandedPrice = coupon ? (
    <strike>${brandingPriceRegular.toFixed(2)}</strike>
  ) : null;

  const unbrandedBadge = !isBranded ? (
    <div className={"badge-pill badge-not-branded"}>
      Includes {discountedUnbrandedPrice} ${brandingPrice} to hide branding
    </div>
  ) : null;

  const recommended =
    props.plan.code === PLANS.medium ? (
      <div>
        <h3 className="pricing-recommended">Recommended</h3>
      </div>
    ) : (
      <div>
        <h3 className="pricing-recommended-placeholder">&nbsp;</h3>
      </div>
    );

  const regularPriceHeader = coupon ? (
    <h5>
      Regular price: <strike>${regularPricePerMonth}</strike>
    </h5>
  ) : null;

  return (
    <div className="col-sm-6 col-md-4 col-lg-4">
      {recommended}
      <div className="pricing-box ">
        <h3
          className={classNames("pricing-title", {
            "bg-info": props.isCurrentPlan,
          })}
        >
          {title}
        </h3>
        <FeatureFlag name={"brandedSubscriptions"}>
          {unbrandedBadge}
        </FeatureFlag>
        {regularPriceHeader}
        <h1 className="pricing-rate">
          <sup>$</sup>
          {price}
          <span className="pricing-rate-conditions">{priceforLabel}</span>
        </h1>
        <p dangerouslySetInnerHTML={{ __html: props.plan.description }}></p>
        <hr />

        <FeatureFlag name={"brandedSubscriptions"}>
          <ShowBrandingToggle
            onChangeBranded={onChangeBranded}
            isBranded={isBranded}
            plan={props.plan}
            isYearly={props.showAnnual ? true : false}
            price={brandingPrice}
            regularPrice={coupon ? brandingPriceRegular : null}
          />
          {isBranded ? <PoweredBy align={"center"} /> : null}
          <hr />
        </FeatureFlag>
        <ul>{listFeatures}</ul>

        {submitButton}
      </div>
    </div>
  );
};

export const AffiliateCouponProps = PropTypes.shape({
  id: PropTypes.string,
  amount_off: PropTypes.number,
  percent_off: PropTypes.number,
  name: PropTypes.string,
});

Pricing.defaultProps = {
  submitLabel: "Select",
};

Pricing.propTypes = {
  plan: PropTypes.shape({
    name: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    price_annual: PropTypes.string.isRequired,
    description: PropTypes.string,
    uuid: PropTypes.string.isRequired,
    features: PropTypes.string.isRequired,
  }),
  onSelect: PropTypes.func.isRequired,
  onSwitchPlanBranded: PropTypes.func.isRequired,
  submitLabel: PropTypes.string,
  isCurrentPlan: PropTypes.bool,
  isPendingPayment: PropTypes.bool,
  disabled: PropTypes.bool,
  coupon: AffiliateCouponProps,
};

export default Pricing;
