/**
 * Created by piotr.pozniak@thebeaverhead.com on 06/04/2022.
 */

import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import PreloadImage from "../PreloadImage";
import Address from "./Address";
import { isSameDay } from "../../../../helpers/date";
import WidgetSettingsContext from "../../../../contexts/WidgetSettingsContext";

const dateFormatter = new Intl.DateTimeFormat(
  window.navigator.language || "en-us",
  {
    month: "short",
    day: "numeric",
    year: "numeric",
  }
);

const Card = (props) => {
  const { item } = props;

  const widgetSettingsContext = useContext(WidgetSettingsContext);

  const showImage =
    true || Number.parseInt(widgetSettingsContext["gSImageMain"]) === 1;
  const showAddress =
    Number.parseInt(widgetSettingsContext["gcgShowAddress"]) === 1;

  const showTitle =
    Number.parseInt(widgetSettingsContext["sBCTitleShow"]) === 1;

  const showEventDate =
    Number.parseInt(widgetSettingsContext["sBCEventDateShow"]) === 1;

  const showEventDateAboveTitle =
    Number.parseInt(widgetSettingsContext["sBEventDate"]) === 1;

  const showSummary =
    Number.parseInt(widgetSettingsContext["sBCSummaryShow"]) === 1;

  const actionValue = widgetSettingsContext["sBCAction"];

  const showPopup =
    true || Number.parseInt(widgetSettingsContext["gPopupShow"]) === 1;
  const showPrimaryBadge = true || widgetSettingsContext["gBCShowPrimary"];
  const showSecondaryBadge = true || widgetSettingsContext["gBCShowSecondary"];

  const primaryBadgeField = widgetSettingsContext["gBCShowPrimaryField"];
  const secondaryBadgeField = widgetSettingsContext["gBCShowSecondaryField"];
  const showFeaturedBadge = widgetSettingsContext["sBCFeaturedShow"];
  const featuredLabel = widgetSettingsContext["sBCFeaturedLabel"];
  const isSummaryLinesLimited =
    Number.parseInt(widgetSettingsContext["sCLimitSummary"]) === 1;

  const eventStartDate = item.starts_at
    ? dateFormatter.format(item.starts_at)
    : null;

  const eventEndDate = item.ends_at ? dateFormatter.format(item.ends_at) : null;

  /**
   *
   * @param e
   */
  const onCardClick = (e) => {
    e.preventDefault();
    if (props.onSelectedEvent) {
      //props.onSelectedEvent(item);
    }
  };

  const banner = item.cover_image ? (
    <PreloadImage src={item.cover_image} />
  ) : (
    <div className={"dce--signups-cards-card_header-image__placeholder"}></div>
  );

  const summaryElement =
    showSummary && item.description.trim().length ? (
      <div
        className={classnames("dce--signup-cards-card_content-summary", {
          "line-clamp": isSummaryLinesLimited,
        })}
      >
        <p
          dangerouslySetInnerHTML={{ __html: item.description }}
          className="summary-text"
        ></p>
      </div>
    ) : null;

  const address =
    showAddress && item.address ? (
      <Address address={item.address} slug={item.slug} />
    ) : null;

  const avatar = showImage ? (
    <div className={"dce--signups-cards-card_header-image"}>{banner}</div>
  ) : null;

  const title = showTitle ? <h2>{item.name}</h2> : null;

  const eventDate = showEventDate ? (
    <h2 className="event-date">
      {isSameDay(item.starts_at, item.ends_at)
        ? eventStartDate
        : `${eventStartDate} ${eventEndDate ? "-" : ""} ${eventEndDate || ""}`}
    </h2>
  ) : null;

  /*const primaryBadge = showPrimaryBadge
    ? Primitives.find((i) => i.visibilitySetting === primaryBadgeField)
    : null;
  const secondaryBadge = showSecondaryBadge
    ? Primitives.find((i) => i.visibilitySetting === secondaryBadgeField)
    : null;

  const badges =
    primaryBadge || secondaryBadge ? (
      <PrimitiveBadges>
        {showPrimaryBadge && primaryBadge ? (
          <Badge
            item={item}
            label={primaryBadge.label}
            name={group.primitives[primaryBadgeField]}
            className={"dce--signups-cards-card_badge-primary"}
          />
        ) : null}
        {showSecondaryBadge && secondaryBadge ? (
          <Badge
            group={group}
            label={secondaryBadge.label}
            name={group.primitives[secondaryBadgeField]}
            className={"dce--signups-cards-card_badge-secondary"}
          />
        ) : null}
      </PrimitiveBadges>
    ) : null;
*/
  const featuredBadge =
    showFeaturedBadge && item.isFeatured ? (
      <div className={"dce--signups-cards-card_featured-badge"}>
        <span>{featuredLabel}</span>
      </div>
    ) : null;

  return (
    <a href={item[actionValue]} target="_blank">
      <div
        className={classnames(
          "dce--signups-cards-card",
          "dce--signups-cards-card--style--" + props.style,
          "dce--signups-cards-card--hover-effect--" + props.hoverEffect,
          {
            "dce--signups-cards-card_cursor_pointer": showPopup ? true : false,
          }
        )}
        onMouseUp={onCardClick}
      >
        <div className={"dce--signups-cards-card_cover "}>
          {featuredBadge}
          {avatar}
        </div>
        <div className={"dce--signups-cards-card_content"}>
          {/*{badges}*/}
          <div className={"dce--signups-cards-card_content-body"}>
            {!showEventDateAboveTitle && eventDate}
            {title}
            {showEventDateAboveTitle && eventDate}
            {address}
            {summaryElement}
          </div>
        </div>
        <div className={"signups-cards-card--hover-effect--accent-simple"} />
        <div
          className={"signups-cards-card--hover-effect--accent-expandable"}
        />
      </div>
    </a>
  );
};

Card.defaultProps = {
  hoverEffect: "zoom",
  style: "none",
};

Card.propTypes = {
  onSelectedEvent: PropTypes.func,
  hoverEffect: PropTypes.string,
  style: PropTypes.string,
  item: PropTypes.object.isRequired,
  starts_at: PropTypes.number,
  ends_at: PropTypes.number,
};
export default Card;
