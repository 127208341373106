/**
 * Created by piotr.pozniak@thebeaverhead.com on 23/11/2022.
 */

import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import Tos from "../templates/PageTemplate/Tos";
import { useUserStore } from "../../../hooks/redux/user";
import AxiosConfig from "./../../../AxiosConfig";
import LoadingIndicator from "./../../calendar/components/LoadingIndicator";
import ApiError from "./../component/ApiError";
import classnames from "classnames";
import useAuthHook from "./../hooks/useAuthHook";
import AccountNotFound from "./AccountVerificationPage/AccountNotFound";
import SignUpForm from "./AccountVerificationPage/SignUpForm";
import appConfig from "../../../appConfig";
import { useOrganizationMembersStore } from "../../../hooks/redux/organizationMembers";
import useQuery from "../../../hooks/useQuery";
import { useCurrentOrganizationStore } from "../../../hooks/redux/currentOrganization";
import axios from "axios";

const PLuginNamesMapping = {
  wp: "WordPress",
};
const AccountVerificationPage = (props) => {
  const params = useQuery();

  const [autoDomainSetup, setAutoDomainSetup] = useState(true);

  const { currentOrganization } = useCurrentOrganizationStore();
  const requestData = useMemo(
    () => ({
      callbackUrl: params.get("callback").replace(/&amp;/g, "&"),
      name: params.get("sitename"),
      adminEmail: params.get("adminemail"),
      type: params.get("type"),
      version: params.get("version"),
      url: params.get("url"),
    }),
    []
  );

  const onSubmit = useCallback((e) => {
    e.preventDefault();

    axios
      .post(
        AxiosConfig.getEndpointAddress() + "/api4/plugins/auth",
        {
          app_type: requestData.type,
          app_name: requestData.name,
        },
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        // console.log(response);
        // return;
        window.location =
          requestData.callbackUrl + `&api_key=${response.data.api_key}`;
      })
      .catch((error) => {
        console.log(error);
      });

    // console.log("submit");

    return false;
  }, []);
  return (
    <div className="main-wrapper">
      <div className="account-page">
        <div className="container">
          <div className="account-logo">
            <Link to="/">
              <img
                src={`/img/branding/${appConfig.branding}/full_length_logo.png`}
                alt={appConfig.displayName}
              />
            </Link>
          </div>
          <div className="account-box">
            <div className="account-wrapper">
              <h3 className="account-title">
                Authenticate with {PLuginNamesMapping[requestData.type]}
              </h3>
              <form action="/" onSubmit={onSubmit}>
                <div>
                  <strong>Website info:</strong>
                  <div>
                    site name: {requestData.name}
                    <br />
                    admin email: {requestData.adminEmail}
                    <br />
                    version: {requestData.version}
                    <br />
                    url: {requestData.url}
                    <br />
                    callback url: {requestData.callbackUrl}
                    <br />
                  </div>
                  <div>
                    <string>Account info</string>
                    <div>
                      organization name: {currentOrganization.model.name}
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label>
                    <div className="checkbox-inline">
                      <input
                        id="autoDomainSetup"
                        type="checkbox"
                        checked={autoDomainSetup}
                      />{" "}
                      Add domain to my account
                    </div>
                  </label>
                </div>
                <div>
                  <button type={"submit"} className={"btn btn-primary"}>
                    Link {appConfig.name} with {requestData.name}
                  </button>
                  <button
                    type={"button"}
                    className={"btn btn-link text-warning"}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
          <Tos />
        </div>
      </div>
    </div>
  );
};

export default AccountVerificationPage;
