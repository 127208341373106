/**
 * Created by piotr.pozniak@thebeaverhead.com on 01/06/2022
 */

import React, { useCallback } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useAppStore } from "../../../../hooks/redux/app";
import { PT } from "../../../../consts";

const WidgetPreviewAlert = (props) => {
  const { app, changePreviewType, toggleWidgetPreview } = useAppStore();

  const isMobilePreview = app.previewType === PT.mobile;

  /**
   *
   * @type {(function())|*}
   */
  const onClose = useCallback(() => {
    changePreviewType(PT.auto);
    toggleWidgetPreview(false);
  }, []);

  /**
   *
   * @type {function(*): function(): void}
   */
  const onChangePreviewType = useCallback(
    (type) => (e) => {
      e.preventDefault();
      changePreviewType(type);
    },
    []
  );

  return (
    <div className={`widget-preview-container__bar bar-${props.widgetType}`}>
      <div className={"d-flex"}>
        <h4 className={"mr-2"}>
          <img
            src={`/img/nav_icons/${props.widgetType}.svg`}
            className={"mr-1"}
          />
          {props.title}
        </h4>

        <div className="dropdown action-label">
          <a
            className="btn btn-white btn-sm btn-rounded dropdown-toggle"
            href="#"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            <i
              className={classNames("material-icons", {
                "text-primary": !isMobilePreview,
                "text-success": isMobilePreview,
              })}
            >
              {classNames({
                computer: !isMobilePreview,
                smartphone: isMobilePreview,
              })}
            </i>{" "}
            <span>{isMobilePreview ? "Mobile" : "Desktop"} preview</span>
          </a>
          <div
            className="dropdown-menu dropdown-menu-right"
            x-placement="bottom-end"
          >
            <a
              className="dropdown-item d-flex align-items-center"
              href="#"
              onClick={onChangePreviewType("desktop")}
            >
              <i className="material-icons icon-sm text-primary">computer</i>{" "}
              Desktop preview
            </a>
            <a
              className="dropdown-item d-flex align-items-center"
              href="#"
              onClick={onChangePreviewType("mobile")}
            >
              <i className="material-icons icon-sm text-success">smartphone</i>{" "}
              Mobile preview
            </a>
          </div>
        </div>
      </div>

      <button
        type="button"
        className="close"
        data-dismiss="modal"
        onClick={onClose}
      >
        &times;
      </button>
    </div>
  );
};

WidgetPreviewAlert.defaultProps = {};

WidgetPreviewAlert.propTypes = {
  title: PropTypes.string,
  widgetType: PropTypes.string.isRequired,
};

export default WidgetPreviewAlert;
