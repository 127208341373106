/**
 * Created by piotr.pozniak@thebeaverhead.com on 15/02/2023
 */

import React from "react";
import PropTypes from "prop-types";
import { AT } from "../../../../consts";

const AlertTypes = {
  [AT.banner]: {
    icon: "remove",
    fullName: "Banner",
  },
  [AT.modal]: {
    icon: "pause",
    fullName: "Modal",
  },
  [AT.alert]: {
    icon: "grid_on",
    fullName: "Alert",
  },
  [AT.slider]: {
    icon: "view_quilt",
    fullName: "Slider",
  },
  [AT.takeover]: {
    icon: "view_quilt",
    fullName: "Takeover",
  },
};
const AlertTypeName = (props) => {
  const { icon, fullName } = AlertTypes[props.type];

  return (
    <span className={"groups--row--group-type"}>
      <i className="material-icons">{icon}</i> {fullName}
    </span>
  );
};

AlertTypeName.propTypes = {
  type: PropTypes.string.isRequired,
};

export default AlertTypeName;
