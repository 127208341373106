import React from "react";
import PropTypes from "prop-types";
import {
  OrganizationMemberStatus,
  OrganizationMemberStatusLabels,
  OrganizationMemberStatusMapping,
} from "../../consts/crm";
import classnames from "classnames";

const getStatusBadgeClass = (statusCode) => {
  switch (statusCode) {
    case OrganizationMemberStatus.newMember:
      return "text-primary";

    case OrganizationMemberStatus.accepted:
      return "text-default";

    case OrganizationMemberStatus.rejected:
      return "text-warning";

    case 4:
      return "text-secondary";
    case OrganizationMemberStatus.delete:
      return "text-danger";
    default:
      return "";
  }
};

const Status = ({ status }) => {
  const statusCode = OrganizationMemberStatusMapping[status];
  return (
    <div className="">
      <i
        className={classnames(`fa fa-circle`, getStatusBadgeClass(status))}
        aria-hidden="true"
      ></i>{" "}
      <span>{OrganizationMemberStatusLabels[statusCode]}</span>
    </div>
  );
};
Status.propTypes = {
  status: PropTypes.number.isRequired,
};
export default Status;
