import React, { useMemo } from "react";
import { getDefinitions } from "../../../builder";
import Tab from "../component/Builder/Tab";

/**
 * Utility function to extract text content from a JSX element.
 * This function recursively extracts and concatenates text from
 * the given JSX element and its children.
 * @param {string|React.ReactNode} jsx - The JSX element or string from which to extract text.
 * @returns {string} - The extracted text, converted to lowercase.
 *
 */
const extractTextFromJSX = (jsx) => {
  if (typeof jsx === "string") {
    return jsx.toLowerCase();
  }

  if (React.isValidElement(jsx)) {
    const children = [];
    const extract = (child) => {
      if (typeof child === "string") {
        children.push(child);
      } else if (React.isValidElement(child) && child.props.children) {
        React.Children.forEach(child.props.children, extract);
      }
    };
    extract(jsx);
    return children.join(" ").toLowerCase();
  }
  return "";
};

const useBuilderOptionsHook = (
  widget,
  settingsName,
  searchQuery,
  widgetSettingsUpdateTimestamp
) => {
  const tabs = useMemo(() => {
    let searchResultsCount = 0;
    const lowercasedSearchQuery = searchQuery.toLowerCase();

    return getDefinitions(widget.model, settingsName)
      .map((definitionTab, idx) => {
        const sections = searchQuery.length
          ? definitionTab.groups
              .map((group) => {
                if (
                  lowercasedSearchQuery.length > 0 &&
                  group.label.toLowerCase().search(lowercasedSearchQuery) !== -1
                ) {
                  return group;
                }
              })
              .filter((i) => i)
              .flat()
          : [];

        const rules = definitionTab.groups
          .map((group) => ({
            ...group,
            options: group.options.filter((i) => {
              const titleText = extractTextFromJSX(i.title);

              if (
                // if search query is not empty
                !lowercasedSearchQuery.length ||
                // title of an option matches
                titleText.includes(lowercasedSearchQuery) ||
                // it's a radio and one of its options match
                (i.control.kind === "radio" &&
                  i.control.options.radioOptions
                    .map((radioOption) => radioOption.title)
                    .join("")
                    .toLowerCase()
                    .search(lowercasedSearchQuery) !== -1) ||
                // it's a dropdown and one of its options match
                (i.control.kind === "dropdown" &&
                  i.control.options.dropdownOptions
                    .map((radioOption) => radioOption.label)
                    .join("")
                    .toLowerCase()
                    .search(lowercasedSearchQuery) !== -1)
              ) {
                return ++searchResultsCount;
              }
              return false;
            }),
          }))
          .filter((i) => i.options.length);

        if (!rules.length && !sections.length) {
          return null;
        }

        const definitions = {
          ...definitionTab,
          rules,
          sections,
        };

        const subheader = searchQuery.length ? (
          <span className={"design-search-result"}>
            {searchResultsCount} Design Option
            {searchResultsCount > 1 ? "s" : ""}
          </span>
        ) : null;

        const subSectionHeader = searchQuery.length ? (
          <span className={"design-search-result"}>
            {sections.length} Design Section
            {sections.length > 1 ? "s" : ""}
          </span>
        ) : null;

        return (
          <Tab
            key={`dtg-${settingsName}-${idx}`}
            definition={definitions}
            widget={widget}
            searchQuery={searchQuery}
            subheader={subheader}
            subSectionHeader={subSectionHeader}
            sections={sections}
            settingsName={settingsName}
          />
        );
      })
      .filter((i) => i);
  }, [widget.model, searchQuery, settingsName, widgetSettingsUpdateTimestamp]);

  return { tabs };
};

export default useBuilderOptionsHook;
