/**
 * Created by piotr.pozniak@thebeaverhead.com on 13/04/2023
 */

import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import Dropdown from "./Dropdown";

const RuleDropdown = (props) => {
  /*

   */
  const onSelect = useCallback(
    (rule) => () => {
      const newRule = {
        field: rule.field,
        value: "",
        label: "",
        operator: rule.operators[0],
      };

      props.onSelectedNewRule(newRule);
    },
    [props.onSelectedNewRule]
  );

  const rulesOptions = useMemo(
    () =>
      props.availableRules.map((rule) => {
        // filter out rules that have operators with empty props collection
        const hasOptions = rule.operators.reduce((acc, current) => {
          return current.input.props.collection.length > 0 || acc;
        }, false);

        if (!hasOptions) {
          // uncommenting it will make filter disappear if there are no options available
          // return null;
        }

        return (
          <div
            key={rule.field.value}
            className={"filters__add-group-button__new-rule__option"}
            onClick={onSelect(rule)}
          >
            {rule.field.label}
          </div>
        );
      }),
    [props.availableRules]
  );

  return (
    <Dropdown onClose={props.onClose}>
      <div className={"filters__add-group-button__new-rule"}>
        {rulesOptions}
      </div>
    </Dropdown>
  );
};

RuleDropdown.defaultProps = {};

RuleDropdown.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSelectedNewRule: PropTypes.func.isRequired,
};

export default RuleDropdown;
