/**
 * Created by piotr.pozniak@thebeaverhead.com on 27/11/2023
 */
import PropTypes from "prop-types";

export const CancelReasons = [
  {
    value: 1,
    label: " I don't understand how to use your product",
  },
  { value: 2, label: " It's too expensive" },
  {
    value: 3,
    label: " I found another option or product that I like better",
  },
  { value: 4, label: " I don't use it enough" },
  { value: 5, label: " Some features I need are missing" },
  { value: 6, label: " Bugs or Support Problems" },
  { value: 7, label: " Other reasons" },
];

export const StepPropTypes = {
  values: PropTypes.shape({
    reason: PropTypes.string,
    comment: PropTypes.string,
    password: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
