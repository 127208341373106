/**
 * Created by piotr.pozniak@thebeaverhead.com on 13/10/2018.
 */

import React, { useContext } from "react";
import PropTypes from "prop-types";
import EventDetails from "./EventDetails";
import EventHeader from "./EventHeader";
import WidgetSettingsContext from "../../../../contexts/WidgetSettingsContext";
import { useTranslation } from "react-i18next";

const Row = React.memo((props) => {
  const { t } = useTranslation();
  /**
   *
   */
  const onEventsRowClick = () => {
    props.closeOpenedEventsDetails(
      props.event.slug == props.expandedRowSlug ? "" : props.event.slug
    );
  };

  const event = props.event;
  const calendarSlug = props.calendarSlug;
  const widgetSettings = useContext(WidgetSettingsContext);

  const showWeekDayInList = widgetSettings["eventDetailsShowDayOfWeek"];
  const weekDayFormatter = widgetSettings["eventsShowDayWeekOption"];
  const useEventAccent = Number.parseInt(
    widgetSettings["generalUseEventAccent"]
  )
    ? true
    : false;
  const recurringShowBadge =
    Number.parseInt(widgetSettings["recurringShowBadge"]) === 1;

  const featuredShowBadge =
    Number.parseInt(widgetSettings["featuredShowBadge"]) === 1;

  const isExpanded = props.expandedRowSlug === event.slug;

  const eventDetails = props.expandAsAccordion ? (
    <EventDetails
      event={event}
      calendarSlug={calendarSlug}
      embedded={props.embedded}
      showDetails={props.showDetails}
      ccbAddress={props.ccbAddress}
      onRSVPEvent={props.onRSVPEvent}
      loading={props.loading}
      error={props.error}
      expandedRowSlug={props.expandedRowSlug}
    />
  ) : null;

  const collapsible =
    isExpanded && props.expandAsAccordion ? (
      <div
        className="event-list--details-close"
        title={t("Close")}
        onClick={onEventsRowClick}
      >
        <i className={"material-icons"} aria-hidden={true}>
          keyboard_arrow_up
        </i>
      </div>
    ) : null;

  return (
    <div id={event.platform_event_id + "_" + event.start_time}>
      <EventHeader
        embedded={props.embedded}
        event={event}
        onClick={onEventsRowClick}
        showWeekDay={showWeekDayInList ? true : false}
        weekDayFormatter={weekDayFormatter}
        useEventAccent={useEventAccent}
        showRecurringBadge={recurringShowBadge}
        showFeaturedBadge={featuredShowBadge}
      />
      {eventDetails}

      {collapsible}
    </div>
  );
});

Row.defaultProps = {
  showDetails: false,
};

Row.propTypes = {
  showDetails: PropTypes.bool,
  calendarSlug: PropTypes.string,
  embedded: PropTypes.bool,
  closeOpenedEventsDetails: PropTypes.func,
  expandedRowSlug: PropTypes.string,
  expandAsAccordion: PropTypes.bool,
  expandAsModal: PropTypes.bool,
};
//Row.whyDidYouRender = true
export default Row;
