/**
 * Created by piotr.pozniak@thebeaverhead.com on 14/04/2023
 */

import React, { useContext, useMemo } from "react";
import PropTypes from "prop-types";
import Filters from "../../../component/Filters/Filters";
import IntegrationContext from "../../../../../contexts/IntegrationContext";
import useFiltersHook from "../../../hooks/useFiltersHook";
import { useWidgetStore } from "../../../../../hooks/redux/widget";

const ItemsFiltering = (props) => {
  const integrationStrategy = useContext(IntegrationContext);
  const { widget } = useWidgetStore();
  const { widgetModel } = props;

  const { availableFilters } = useFiltersHook({
    widget,
    integrationStrategy,
  });

  const widgetFilters = widgetModel.filter_settings
    ? widgetModel.filter_settings.filters
    : {};

  return (
    <Filters
      availableFilters={availableFilters}
      filters={widgetFilters}
      onChange={props.onUpdateFilters}
    />
  );
};

ItemsFiltering.defaultProps = {};

ItemsFiltering.propTypes = {
  widgetModel: PropTypes.object,
  integrationModel: PropTypes.object,
};

export default ItemsFiltering;
