/**
 * Created by piotr.pozniak@thebeaverhead.com on 24/11/2022
 */

import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Link, useHistory } from "react-router-dom";
import { store } from "./../../store";
import LoadingIndicator from "../../../calendar/components/LoadingIndicator";
import ApiError from "../../component/ApiError";
import FormError from "../../component/FormError";
import { updateJquery } from "./../../tools";

import UnauthorizedUserMobileHelp from "./../../component/UnauthorizedUserMobileHelp";
import { useUserStore } from "./../../../../hooks/redux/user";
import { useAppStore } from "./../../../../hooks/redux/app";
import { useIntegrationStore } from "./../../../../hooks/redux/integration";
import { useTranslation } from "react-i18next";

const SignUpForm = (props) => {
  const { user, activateAccount } = useUserStore();

  const { t } = useTranslation();

  const [state, setState] = useState({
    firstName: props.oAuthData.firstName,
    lastName: props.oAuthData.lastName,
    email: props.oAuthData.email,
    organizationName: props.oAuthData.organizationName,
    organization_uuid: props.oAuthData.organizationUuid,
    password: "",
    coupon: "",

    firstNameError: null,
    lastNameError: null,
    passwordError: null,
    captchaError: null,

    hasCoupon: false,
  });

  const [syncDrive, setSyncDrive] = useState(false);

  const history = useHistory();
  const { clearErrors } = useAppStore();

  /**
   *
   * @param field
   * @returns {function(...[*]=)}
   */
  const setValue = (field) => (e) => {
    const newState = { ...state };
    newState[field] = e.target.value;
    setState(newState);
  };

  //const passwordRef = useRef();

  useEffect(() => {
    updateJquery();

    //passwordRef.current.focus();
    return clearErrors;
  }, []);

  useEffect(() => {
    if (state.hasCoupon) {
      updateJquery();

      document.getElementById("invite_code").focus();
    }
  }, [state.hasCoupon]);

  /**
   *
   */
  const onRegisterClick = async (e) => {
    e.preventDefault();

    const {
      firstName,
      lastName,
      email,
      coupon,
      organizationName,
      organization_uuid,
    } = state;

    let passwordError = null;
    let firstNameError = null;
    let lastNameError = null;

    if (!firstNameError && !lastNameError && !passwordError) {
      await activateAccount({
        first_name: firstName,
        last_name: lastName,
        email,
        trial_coupon: coupon,
        access_token: props.oAuthData.accessToken,
        organization_name: organizationName,
        organization_uuid,
      });

      if (store.getState().user.registerSuccess) {
        history.push("/select-integration");
      }
    }

    return false;
  };

  const onHaveInvitationCode = (e) => {
    e.preventDefault();
    setState({ ...state, hasCoupon: true });
  };

  const haveACouponLink = !state.hasCoupon ? (
    <div className="text-sm mb-3">
      <Link
        to={"#"}
        className={"text-xs card-link text-primary"}
        style={{ fontSize: "0.9em" }}
        onClick={onHaveInvitationCode}
      >
        Do you have an invite code?
      </Link>
    </div>
  ) : null;

  const isLoading = user.register;

  const loadingIndicator = isLoading ? <LoadingIndicator /> : null;

  const couponInput = state.hasCoupon ? (
    <div className="form-group form-focus">
      <label className="focus-label">Enter your invite code (optional)</label>
      <input
        id={"invite_code"}
        className={"form-control floating "}
        type="text"
        value={state.coupon}
        onChange={setValue("coupon")}
        disabled={isLoading}
      />
    </div>
  ) : null;

  const passwordInputStyle = state.hasCoupon ? {} : { marginBottom: "5px" };

  /**
   *
   * @param e
   */
  const onDriveScopeChange = (e) => {
    setSyncDrive(!syncDrive);
  };

  const submitBtnText = props.submitBtnText
    ? props.submitBtnText
    : "START FREE TRIAL";

  return (
    <form action="/">
      <h5>Your details</h5>
      <div className="form-group form-focus">
        <label className="focus-label">Email</label>
        <input
          className={"form-control floating"}
          type="email"
          defaultValue={props.oAuthData.email}
          disabled={true}
        />
      </div>

      <div className="form-group form-focus">
        <label className="focus-label">First name</label>

        <input
          className={classnames("form-control floating ", {
            "is-invalid": state.firstNameError,
          })}
          type="text"
          value={state.firstName}
          onChange={setValue("firstName")}
          disabled={false}
        />
      </div>
      <div className="form-group form-focus">
        <label className="focus-label">Last name</label>

        <input
          className={classnames("form-control floating ", {
            "is-invalid": state.lastNameError,
          })}
          type="text"
          value={state.lastName}
          autoFocus={true}
          onChange={setValue("lastName")}
          disabled={false}
        />
      </div>

      <div className="form-group form-focus">
        <label className="focus-label">{t("Organization")} name</label>

        <input
          className={classnames("form-control floating ", {
            "is-invalid": state.organizationNameError,
          })}
          type="text"
          value={state.organizationName}
          autoFocus={true}
          onChange={setValue("organizationName")}
          disabled={isLoading || state.organization_uuid}
        />
      </div>

      {/*<div className="form-group form-focus" style={passwordInputStyle}>
        <label className="focus-label">Password</label>
        <input
          ref={passwordRef}
          className={
            "form-control floating " + (state.passwordError ? "is-invalid" : "")
          }
          type="password"
          value={state.password}
          onChange={setValue("password")}
          disabled={isLoading}
        />
      </div>*/}
      {haveACouponLink}

      {couponInput}

      {/* <hr />
      <h5>Your Google Calendar</h5>
      <ul className={"list-unstyled"}>
        <li>
          <input type={"checkbox"} defaultChecked={true} disabled={true} />{" "}
          <span>Sync my Google Calendar automatically</span>
        </li>
        <li>
          <label>
            <input
              type={"checkbox"}
              checked={syncDrive}
              disabled={isLoading}
              onChange={onDriveScopeChange}
            />{" "}
            <span className={"scope-label"}>
              Make my calendars stand out by pulling images attached to events
              from my Google Drive.
            </span>
          </label>
        </li>
      </ul>*/}

      <div className="form-group text-center">
        <button
          className="btn btn-primary btn-block account-btn"
          type="submit"
          onClick={onRegisterClick}
          disabled={isLoading}
        >
          {submitBtnText}
        </button>
      </div>
      <ApiError
        error={user.registerError}
        heading={"Could not create the account:"}
        defaultErrorMessage={"Could not create an account."}
      />

      <FormError
        errors={[
          state.firstNameError,
          state.lastNameError,
          state.emailError,
          state.passwordError,
        ]}
      />

      {loadingIndicator}

      <UnauthorizedUserMobileHelp />
    </form>
  );
};

SignUpForm.defaultProps = {};

SignUpForm.propTypes = {
  oAuthData: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    refreshToken: PropTypes.string,
    accessToken: PropTypes.string,
    expiresIn: PropTypes.number,
    organizationName: PropTypes.string,
    organization_uuid: PropTypes.string,
  }),
};

export default SignUpForm;
