import React, { useCallback, useState } from "react";
import AddMoreButton from "./AddMoreButton";
import { OrganizationMemberStatus, UserRoles } from "../../consts/crm";
import TableRow from "./TableRow";
import PropTypes from "prop-types";
import Footer from "./Fotter";
import { useOrganizationMembersStore } from "../../../../hooks/redux/organizationMembers";

const Form = (props) => {
  const { organizationMembers, reinviteOrganizationMember } =
    useOrganizationMembersStore();

  const { members } = props;

  /**
   *
   * @type {(function(): void)|*}
   */
  const onAddMore = useCallback(() => {
    props.onChange((prevMembers) => [
      ...prevMembers,
      {
        invite_status: 0,
        invite_email: "",
        role: UserRoles.admin,
        uuid: new Date().getTime(),
      },
    ]);
  }, [members]);

  /**
   *
   * @type {(function(*): void)|*}
   */
  const onRemove = useCallback(
    (member) => {
      const memberData = { ...member };
      if (member.invite_status === OrganizationMemberStatus.newMember) {
        return props.onChange((prevMembers) =>
          prevMembers.filter((m) => m.uuid !== member.uuid)
        );
      }
      memberData.invite_status = OrganizationMemberStatus.delete;
      props.onChange((prevMembers) =>
        prevMembers.map((item) =>
          item.uuid === memberData.uuid ? memberData : item
        )
      );
    },
    [members]
  );

  const onCancelRemove = useCallback(
    (member) => {
      const previousData = organizationMembers.collection.find(
        (r) => r.uuid === member.uuid
      );
      if (previousData) {
        member.invite_status = previousData.invite_status;
        props.onChange((prevMembers) =>
          prevMembers.map((item) => (item.uuid === member.uuid ? member : item))
        );
      }
    },
    [members, organizationMembers.collection, props]
  );

  /**
   *
   * @type {(function(*): void)|*}
   */
  const onResendInvitation = useCallback(
    (member) => {
      // Re-invite users
      reinviteOrganizationMember(member.uuid);
    },
    [members]
  );

  const updateMemberData = (uuid, key, value) => {
    let _member = members.find((_member) => _member.uuid === uuid);

    _member = {
      ..._member,
      [key]: value,
    };

    props.onChange((prevMembers) =>
      prevMembers.map((item) => (item.uuid === _member.uuid ? _member : item))
    );
  };

  const rows = members.map((member) => (
    <TableRow
      key={member.uuid}
      member={member}
      disabled={false}
      onRemove={onRemove}
      onResendInvitation={onResendInvitation}
      updateMemberData={updateMemberData}
      onCancelRemove={onCancelRemove}
    />
  ));

  return (
    <div>
      <table className="table table-striped table-responsive custom-table">
        <thead>
          <tr>
            <td width={"15%"}>Name</td>
            <td width={"50%"}>Email</td>
            <td width={"15%"}>Role</td>
            <td width={"15%"}>Status</td>
            <td>Actions</td>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
      <AddMoreButton onClick={onAddMore} />
      <div className={"card-footer d-flex justify-content-end"}>
        <Footer
          onRestore={props.onRestore}
          onSubmit={props.onSubmit}
          disableSubmit={props.disableSubmit}
        />
      </div>
    </div>
  );
};

Form.propTypes = {
  onRestore: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default Form;
