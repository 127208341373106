/**
 * Created by piotr.pozniak@thebeaverhead.com on 18/06/2021.
 */

import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as calendarsActions from "../../actions/calendars";

export function useCalendarsStore() {
  const dispatch = useDispatch();
  const _calendars = useSelector((store) => store.calendars);

  const fetchCalendars = useCallback(
    async () => await dispatch(calendarsActions.fetchCalendars()),
    [dispatch]
  );

  return {
    calendars: _calendars,
    fetchCalendars,

  };
}
