/**
 * Created by piotr.pozniak@thebeaverhead.com on 24/05/2019.
 */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import LoadingIndicator from "../../../calendar/components/LoadingIndicator";
import ApiError from "../../component/ApiError";
import classnames from "classnames";
import { formatter } from "../../tools";
import moment from "moment";
import { connect } from "react-redux";
import CurrentMonthExpenseRow from "./CurrentMonthExpenseRow";
import { PAYOUTS_EXPENSES_KINDS, PAYOUTS_VENDORS } from "../../../../consts";
import { usePayoutStore } from "../../../../hooks/redux/payout";
import { usePayoutsStore } from "../../../../hooks/redux/payouts";

const EditModal = (props) => {
  const [state, setState] = useState({
    showCurrentMonthIncomeDetails: false,
    showCurrentMonthExpenseDetails: false,
    showCurrentMonthLicensesExpenses: false,

    currMonthIncomes: [],
    prevMonthExpenses: [],
    currMonthExpenses: [],

    removedExpenses: [],
    //createSuccess: false,
  });

  const { payout } = usePayoutStore();
  const { payouts } = usePayoutsStore();

  useEffect(() => {
    if (payout.fetchSuccess && (payout.initialData || payout.model)) {
      const emptyExpense = [
        {
          description: "",
          kind: "",
          vendor: "",
          amount: "",
          is_added_along_with_payout: 1,
        },
      ];
      let currMonthExpenses = emptyExpense;
      let prevMonthExpenses = [];

      if (payout.initialData && payout.initialData.recurring_expenses.length) {
        // do not apply recurring expenses that happens "in the future", eg.
        // when making a payout for Sept 2018 but recurring expense is license
        // that was created on Aug 2019- it should not be considered.
        const initialData = payout.initialData;
        const currentExpenseDateStamp = moment(
          initialData.year + "-" + initialData.month + "-01"
        )
          .endOf("month")
          .unix();

        currMonthExpenses = payout.initialData.recurring_expenses.filter(
          (i) =>
            !i.license ||
            moment(i.created * 1000).unix() <= currentExpenseDateStamp
        );

        if (!currMonthExpenses.length) {
          currMonthExpenses = emptyExpense;
        }

        prevMonthExpenses = payout.initialData.previous_expenses;
      } else if (payout.model && payout.model.payout_expenses.length) {
        currMonthExpenses = payout.model.payout_expenses;
        prevMonthExpenses = payout.model.previous_expenses;
      }

      let currMonthIncomes = [];

      if (payout.initialData) {
        currMonthIncomes = payout.initialData.stripe_payouts;
      } else if (payout.model) {
        currMonthIncomes = payout.model.stripe_payouts;
      }

      setState({
        ...state,
        currMonthExpenses,
        currMonthIncomes,
        prevMonthExpenses,
        //createSuccess: !!payout.model,
        showCurrentMonthExpenseDetails: !!payout.initialData,
        removedExpenses: [],
      });
    }
  }, [payout.fetchSuccess]);

  /**
   *
   * @param kindValue
   * @returns {*}
   */
  const getKindLabel = (kindValue) => {
    const kindOption = PAYOUTS_EXPENSES_KINDS.find(
      (i) => i.value === kindValue
    );
    return kindOption ? kindOption.label : kindValue;
  };

  /**
   *
   * @param vendorValue
   * @returns {*}
   */
  const getVendorLabel = (vendorValue) => {
    const vendorOption = PAYOUTS_VENDORS.find((i) => i.value === vendorValue);
    return vendorOption ? vendorOption.label : vendorValue;
  };

  /**
   *
   */
  const toggleCurrentMonthIncomeDetails = () => {
    setState({
      ...state,
      showCurrentMonthIncomeDetails: !state.showCurrentMonthIncomeDetails,
    });
  };

  /**
   *
   */
  const toggleCurrentMonthExpenseDetails = () => {
    setState({
      ...state,
      showCurrentMonthExpenseDetails: !state.showCurrentMonthExpenseDetails,
    });
  };

  /**
   *
   */
  const toggleCurrentMonthLicensesExpenses = () => {
    setState({
      ...state,
      showCurrentMonthLicensesExpenses: !state.showCurrentMonthLicensesExpenses,
    });
  };

  /**
   *
   */
  const addNextExpense = () => {
    setState({
      ...state,
      currMonthExpenses: [
        ...state.currMonthExpenses,
        {
          description: "",
          kind: "",
          vendor: "",
          amount: "",
          is_added_along_with_payout: 1,
        },
      ],
    });
  };

  /**
   *
   * @param index
   * @returns {Function}
   */
  const removeExpense = (index) => () => {
    const removedExpense = state.currMonthExpenses[index];
    const removedExpenses = Object.keys(removedExpense).includes("uuid")
      ? [...state.removedExpenses, state.currMonthExpenses[index]]
      : state.removedExpenses;

    setState({
      ...state,
      removedExpenses,
      currMonthExpenses: [
        ...state.currMonthExpenses.slice(0, index),
        ...state.currMonthExpenses.slice(index + 1),
      ],
    });
  };

  /**
   *
   */
  const applyPayout = () => {
    let expenseWithoutAmount = null;

    state.currMonthExpenses.map((i) => {
      if (i.amount * 1 <= 0) {
        expenseWithoutAmount = i;
      }
    });

    if (
      !expenseWithoutAmount ||
      window.confirm(
        expenseWithoutAmount.description +
          " expense has no value. Are you sure it's OK?"
      )
    ) {
      props.onApply({
        payout_expenses: state.currMonthExpenses,
        removed_recurring_expenses: state.removedExpenses,
      });
    }
  };

  /**
   *
   */
  const updatePayout = () => {
    let expenseWithoutAmount = null;

    state.currMonthExpenses.map((i) => {
      if (i.amount * 1 <= 0) {
        expenseWithoutAmount = i;
      }
    });

    if (
      !expenseWithoutAmount ||
      window.confirm(
        expenseWithoutAmount.description +
          " expense has no value. Are you sure it's OK?"
      )
    ) {
      props.onUpdate({
        payout_expenses: state.currMonthExpenses,
        removed_recurring_expenses: state.removedExpenses,
      });
    }
  };

  /**
   *
   * @param index
   * @param key
   * @returns {Function}
   */
  const onChangePayoutExpense = (index, key) => (event) => {
    setState({
      ...state,
      currMonthExpenses: state.currMonthExpenses.map((x, idx) => {
        if (idx === index) {
          return {
            ...x,
            [key]: event.target.value,
          };
        }
        return x;
      }),
    });
  };

  /**
   *
   */
  const loadingIndicator =
    payout.create || payout.fetch || payout.markVendorPaid ? (
      <LoadingIndicator />
    ) : null;

  const inputDisabled =
    payout.model && payout.model.vendor_a_paid && payout.model.vendor_a_paid;

  const currentMonthIncomeTotal = state.currMonthIncomes
    .map((x) => Number(x.amount))
    .reduce((prevVal, currVal) => prevVal + currVal, 0);

  const currentMonthIncomesClassName = classnames(
    "d-flex justify-content-between",
    {
      "cursor-pointer": currentMonthIncomeTotal > 0,
    }
  );

  const currentMonthIncomes = (
    <React.Fragment>
      <div
        className={currentMonthIncomesClassName}
        onClick={toggleCurrentMonthIncomeDetails}
      >
        <p className="h4 text-primary">Current Month Revenue</p>
        <p className="h4">{formatter.format(currentMonthIncomeTotal)}</p>
      </div>
      {state.showCurrentMonthIncomeDetails && currentMonthIncomeTotal > 0 && (
        <div className="card-box mb-4">
          <table className="table table-striped custom-table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Stripe ID</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {state.currMonthIncomes.map((x) => (
                <tr key={x.stripe_payout_id}>
                  <td>
                    {moment(x.payout_created * 1000).format("YYYY-MM-DD")}
                  </td>
                  <td>
                    <a
                      target="_blank"
                      href={
                        "https://dashboard.stripe.com/payouts/" +
                        x.stripe_payout_id
                      }
                    >
                      {x.stripe_payout_id}
                    </a>
                  </td>
                  <td>{formatter.format(x.amount)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </React.Fragment>
  );

  let prevMonthIncomeTotal = 0;

  if (payout.initialData) {
    prevMonthIncomeTotal = payout.initialData.income_carried_over;
  } else if (payout.model) {
    prevMonthIncomeTotal = payout.model.income_carried_over;
  }

  const prevMonthIncome = prevMonthIncomeTotal > 0 && (
    <div className="d-flex justify-content-between">
      <p className="h4">Previous Month(s) Income</p>
      <p className="h4">{formatter.format(prevMonthIncomeTotal)}</p>
    </div>
  );

  let coverExpenses = true;
  let revenueCarriedOverTotal = currentMonthIncomeTotal + prevMonthIncomeTotal;
  let coveredExpenses = [];

  const prevMonthExpensesTotal = state.prevMonthExpenses
    .map((x) => Number(x.amount))
    .reduce((prevVal, currVal) => prevVal + currVal, 0);
  //+ (payout.model ? payout.model.balance : 0);

  const prevMonthExpenses = (
    <React.Fragment>
      <div className="d-flex justify-content-between">
        <p className="h4">Balance (from previous month)</p>
        <p
          className={classnames("h4", {
            "text-danger": prevMonthExpensesTotal > 0,
          })}
        >
          {formatter.format(
            prevMonthExpensesTotal > 0
              ? -1 * prevMonthExpensesTotal
              : prevMonthExpensesTotal
          )}
        </p>
      </div>
      {prevMonthExpensesTotal > 0 && (
        <div className="card-box mb-4 px-5">
          <table className="table table-borderless">
            <tbody>
              {state.prevMonthExpenses.map((x) => {
                let className = "";
                if (
                  !coverExpenses ||
                  revenueCarriedOverTotal - Number(x.amount) < 0
                ) {
                  className = "table-danger";
                  coverExpenses = false;
                } else {
                  revenueCarriedOverTotal -= Number(x.amount);
                  coveredExpenses.push(x);
                }
                return (
                  <tr key={x.uuid} className={className}>
                    <td>{x.description}</td>
                    <td>{getKindLabel(x.kind)}</td>
                    <td>{getVendorLabel(x.vendor)}</td>
                    <td>
                      <span className="text-danger">
                        {formatter.format(-1 * x.amount)}
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </React.Fragment>
  );

  const uncoveredExpenses =
    payout.initialData && payout.initialData.expenses
      ? payout.initialData.expenses
      : null;

  const autoCreatedExpensesTotal = uncoveredExpenses
    ? uncoveredExpenses.map((i) => Number(i.amount)).reduce((p, n) => p + n, 0)
    : null;

  let currentMonthExpensesTotal =
    state.currMonthExpenses
      .map((i) => Number(i.amount))
      .reduce((p, n) => p + n, 0) + autoCreatedExpensesTotal;

  const disableInput =
    //state.createSuccess ||
    // payout.create ||
    // payout.fetch ||
    payout.model && (payout.model.vendor_a_paid || payout.model.vendor_b_paid)
      ? true
      : false;

  const autoCreatedExpenses = uncoveredExpenses
    ? uncoveredExpenses.map((i, idx) => {
        let className = "";
        if (!coverExpenses || revenueCarriedOverTotal - Number(i.amount) < 0) {
          className = "table-danger";
          coverExpenses = false;
        } else {
          revenueCarriedOverTotal -= Number(i.amount);
          coveredExpenses.push(i);
        }
        return (
          <CurrentMonthExpenseRow
            id={"pe-" + idx}
            key={"pe-" + idx}
            className={className}
            onChangePayoutExpense={() => {}}
            removeExpense={() => {}}
            expense={i}
            removeBtnDisabled={true}
            inputDisabled={true}
          />
        );
      })
    : null;

  const currentMonthExpenses = (
    <React.Fragment>
      <div
        className="d-flex justify-content-between cursor-pointer"
        onClick={toggleCurrentMonthExpenseDetails}
      >
        <p className="h4 text-primary">Current month expenses (total)</p>
        <p className="h4 text-danger">
          {formatter.format(-1 * currentMonthExpensesTotal)}
        </p>
      </div>
      <div
        className={classnames("card-box mb-4", {
          "d-none": !state.showCurrentMonthExpenseDetails,
        })}
      >
        <table className="table table-borderless mt-3">
          <tbody>
            {autoCreatedExpenses}

            {state.currMonthExpenses.map((x, idx) => {
              let className = "";
              if (
                !coverExpenses ||
                revenueCarriedOverTotal - Number(x.amount) < 0
              ) {
                className = "table-danger";
                coverExpenses = false;
              } else {
                revenueCarriedOverTotal -= Number(x.amount);
                coveredExpenses.push(x);
              }

              return (
                <CurrentMonthExpenseRow
                  id={idx}
                  key={"cmer-" + idx}
                  className={className}
                  onChangePayoutExpense={onChangePayoutExpense}
                  removeExpense={removeExpense}
                  expense={x}
                  removeBtnDisabled={disableInput}
                  inputDisabled={disableInput}
                />
              );
            })}
          </tbody>
        </table>

        <div className="text-right">
          <button
            className="btn btn-primary"
            type={"button"}
            onClick={addNextExpense}
            disabled={disableInput}
          >
            Add next
          </button>
        </div>
      </div>
    </React.Fragment>
  );

  let payoutPeriod = "";

  let yearStart = 0;
  let monthStart = 0;

  if (payout.fetchSuccess && payout.model) {
    yearStart = payout.model.year;
    monthStart = payout.model.month;
  } else if (payout.fetchSuccess && payout.initialData) {
    yearStart = payout.initialData.year;
    monthStart = payout.initialData.month;
  }

  payoutPeriod = moment(yearStart + " " + monthStart, "YYYY MM").format(
    "MMM YYYY"
  );

  const modalHeader = payout.fetchSuccess
    ? "Expenses for " + payoutPeriod
    : "Expenses";

  const vendorsList = PAYOUTS_VENDORS.filter((i) => i.value.length).map(
    (i) => i.value
  );

  const vendorsExpenses =
    (payout.initialData || payout.model) &&
    vendorsList.map((vendor) => (
      <div className="d-flex justify-content-between" key={vendor}>
        <p className="h4">Current month expenses {getVendorLabel(vendor)}</p>
        <p className="h4">
          {formatter.format(
            state.currMonthExpenses
              .filter((i) => i.vendor === vendor)
              .map((i) => Number(i.amount))
              .reduce((pVal, cVal) => pVal + cVal, 0) +
              (payout.initialData && payout.initialData.expenses
                ? payout.initialData.expenses
                    .filter((i) => i.vendor === vendor)
                    .map((i) => Number(i.amount))
                    .reduce((pVal, cVal) => pVal + cVal, 0)
                : 0)
          )}
        </p>
      </div>
    ));

  const subtotalRevenue =
    currentMonthIncomeTotal +
    prevMonthIncomeTotal -
    (prevMonthExpensesTotal + currentMonthExpensesTotal);

  const subtotal = (
    <div className="d-flex justify-content-between mt-5">
      <p className="h4">Subtotal revenue</p>
      <p className={classnames("h4", { "text-danger": subtotalRevenue < 0 })}>
        {formatter.format(subtotalRevenue)}
      </p>
    </div>
  );

  const revenueCarriedOver = coveredExpenses.length !==
    state.prevMonthExpenses.length + state.currMonthExpenses.length && (
    <div className="d-flex justify-content-between">
      <p className="h4">Revenue carried over</p>
      <p className="h4">{formatter.format(revenueCarriedOverTotal)}</p>
    </div>
  );

  const vendorsRevenues =
    (payout.initialData || payout.model) &&
    vendorsList.map((vendor) => {
      const vendorRevenue = coveredExpenses
        .filter((i) => i.vendor === vendor)
        .map((i) => Number(i.amount))
        .reduce(
          (pVal, cVal) => pVal + cVal,
          subtotalRevenue > 0 ? subtotalRevenue / 2 : 0
        );

      let vendorPaid = false;

      if (
        vendor === "TBH" &&
        payout.model &&
        Boolean(payout.model.vendor_b_paid)
      ) {
        vendorPaid = true;
      }

      if (
        vendor === "FLI" &&
        payout.model &&
        Boolean(payout.model.vendor_a_paid)
      ) {
        vendorPaid = true;
      }

      const payBtnClass = classnames("btn", {
        "btn-success": vendorPaid,
        "btn-primary": !vendorPaid,
      });

      const payBtnText = vendorPaid ? "PAID" : "Pay";

      const payBtnDisabled =
        vendorPaid ||
        payout.create ||
        //!state.createSuccess ||
        vendorRevenue === 0;

      const vendorPayoutData = {};

      if (vendor === "TBH") {
        vendorPayoutData.vendor_b_paid = 1;
      }

      if (vendor === "FLI") {
        vendorPayoutData.vendor_a_paid = 1;
      }

      const handleVendorPayout =
        !vendorPaid && payout.model
          ? () =>
              props.onMarkVendorPayoutAsPaid(
                payout.model.uuid,
                vendorPayoutData
              )
          : null;

      return (
        <div
          className="d-flex align-items-center justify-content-between mb-2"
          key={vendor + "-revenue"}
        >
          <p className="h4 mb-0">Payout {getVendorLabel(vendor)}</p>
          <div className="d-flex align-items-center">
            <p className="h4 mr-3 mb-0">{formatter.format(vendorRevenue)}</p>
            <button
              className={payBtnClass}
              type="button"
              disabled={payBtnDisabled}
              onClick={handleVendorPayout}
            >
              {payBtnText}
            </button>
          </div>
        </div>
      );
    });

  const applyButton =
    payout.model && payout.model.uuid ? null : (
      <div className="m-t-20 text-center">
        <div className="form-group">
          <button
            className="btn btn-primary btn-lg"
            type={"button"}
            onClick={applyPayout}
            disabled={payout.create}
          >
            Apply
          </button>
        </div>
      </div>
    );

  const updateButton =
    payout.model &&
    payout.model.uuid &&
    !payout.model.vendor_a_paid &&
    !payout.model.vendor_b_paid ? (
      <div className="m-t-20 text-center">
        <div className="form-group">
          <button
            className="btn btn-primary btn-lg"
            type={"button"}
            onClick={updatePayout}
            disabled={payout.create || payouts.delete}
          >
            Update
          </button>
        </div>
      </div>
    ) : null;

  return (
    <div id="payouts_modal" className="modal custom-modal fade" role="dialog">
      <div className="modal-dialog">
        <button type="button" className="close" data-dismiss="modal">
          &times;
        </button>

        <div className="modal-content modal-xl">
          <div className="modal-header">
            <h3>{modalHeader}</h3>
          </div>

          <div className="modal-body pt-4">
            <div className="card-box">
              {payout.fetchSuccess && (
                <React.Fragment>
                  {currentMonthIncomes}

                  {prevMonthIncome}

                  {prevMonthExpenses}

                  {currentMonthExpenses}

                  {vendorsExpenses}

                  {subtotal}

                  {revenueCarriedOver}

                  <hr />

                  {vendorsRevenues}

                  <small className="text-muted">
                    Payout = Vendor expenses + half of the revenue
                  </small>

                  {applyButton}
                  {updateButton}
                </React.Fragment>
              )}

              <ApiError
                error={payout.createError || payout.fetchError}
                defaultErrorMessage={"Could not apply payout. Please try again"}
              />
              {loadingIndicator}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

EditModal.propTypes = {
  payout: PropTypes.object,
  onUpdate: PropTypes.func.isRequired,
  onApply: PropTypes.func.isRequired,
};

export default EditModal;
