/**
 * Created by piotr.pozniak@thebeaverhead.com on 12/07/2022
 */

import React, { memo } from "react";
import PropTypes from "prop-types";

const MeetingDayTime = (props) => {
  const values = [props.meetingDay, props.meetingTime]
    .filter((i) => i)
    .join(", ");

  return (
    <div className={"dce--two-columns--meeting"}>
      <div>{values}</div>
    </div>
  );
};

MeetingDayTime.defaultProps = {};

MeetingDayTime.propTypes = {
  meetingDay: PropTypes.string,
  meetingTime: PropTypes.string,
};

export default memo(MeetingDayTime);
