/**
 * Created by piotr.pozniak@thebeaverhead.com on 16/01/2019.
 */

import React from "react";

import PropTypes from "prop-types";
import classnames from "classnames";

const EventsCounter = (props) => {
  return props.eventsCount ? (
    <div className={classnames("events-counter", { lefter: props.isLeft })}>
      <p>{props.eventsCount}</p>
    </div>
  ) : null;
};

EventsCounter.defaultProps = {
  isLeft: false,
};

EventsCounter.propTypes = {
  eventsCount: PropTypes.number.isRequired,
  isLeft: PropTypes.bool.isRequired,
};

export default EventsCounter;
