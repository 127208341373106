import React, { useCallback } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

/**
 * Renders a Call to Action (CTA) button component.
 *
 * @param {Object} props - The component props.
 * @param {string} props.url - The URL the button should navigate to.
 * @param {string} props.label - The label or text displayed on the button.
 * @param {string} [props.className=""] - Additional CSS class for the component.
 * @param {string} [props.buttonClassName="dce--event-card--cta-btn"] - Additional CSS class for the button.
 * @returns {JSX.Element} The rendered CTA button component.
 */
const CTAButton = (props) => {
  /**
   * Prevents event propagation on click.
   *
   * @param {MouseEvent} e - The mouse event object.
   */
  const onClick = useCallback((e) => {
    e.stopPropagation();
  }, []);

  return (
    <div className={classNames(props.className)}>
      <a
        className={props.buttonClassName}
        href={props.url}
        target={props.target}
        onMouseUp={onClick}
        dangerouslySetInnerHTML={{ __html: props.label }}
      ></a>
    </div>
  );
};

// Default props for the component.
CTAButton.defaultProps = {
  buttonClassName: "dce--event-card--cta-btn",
};

// Prop types for the component.
CTAButton.propTypes = {
  url: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
};

export default CTAButton;
