/**
 * Created by piotr.pozniak@thebeaverhead.com on 13/10/2018.
 */

import React, { useCallback, useContext, useState } from "react";
import Row from "./Row";
import PropTypes from "prop-types";
import LoadingIndicator from "../LoadingIndicator";
import RowModal from "./RowModal";
import InfiniteScrollWrapper from "../InfiniteScrollWrapper";
import NoEvents from "../NoEvents";
import { openEventPage } from "../../../../helpers/calendar";
import WidgetSettingsContext from "../../../../contexts/WidgetSettingsContext";

const Events = (props) => {
  const [expandedRowSlug, setExpandedRowSlug] = useState(props.selectedEvent);

  const widgetSettings = useContext(WidgetSettingsContext);
  const openChurchCenter =
    Number.parseInt(widgetSettings["generalGoToChurchCenter"]) === 1;

  const openLinkInNewTab =
    Number.parseInt(widgetSettings["openLinksNewTab"]) === 1;

  /**
   *
   * @param rowId
   */

  const events = props.events || [];
  const { loading, hasMore, embedded, singleEvent } = props;

  const onCloseOpenedEventsDetails = useCallback(
    (rowId) => {
      if (openChurchCenter) {
        const event = props.events.find((i) => i.slug === rowId);
        openEventPage(event, openLinkInNewTab);

        return;
      }

      setExpandedRowSlug(rowId);

      if (props.onClosedEventRowModal) {
        props.onClosedEventRowModal();
      }
    },
    [
      openChurchCenter,
      props.events,
      openLinkInNewTab,
      props.onClosedEventRowModal,
    ]
  );

  const noEvents = !loading && !events.length ? <NoEvents /> : null;

  const expandDetailsAsAccordionValue =
    widgetSettings["generalExpandDetailAccording"];
  const showDetailsAsModalValue = widgetSettings["generalOpenDetailsModal"];

  const expandDetailsAsAccordion =
    singleEvent || expandDetailsAsAccordionValue ? true : false;

  const showDetailsAsModal =
    !singleEvent && showDetailsAsModalValue ? true : false;

  const rows = events.length
    ? events.map((event, idx) => {
        return (
          <Row
            key={event.integration_event_id + "_" + idx}
            event={event}
            showDetails={props.selectedEvent === event.slug}
            calendarSlug={props.calendarSlug}
            embedded={embedded}
            ccbAddress={props.ccbAddress}
            onRSVPEvent={props.onRSVPEvent}
            loading={props.loading}
            error={props.error}
            closeOpenedEventsDetails={onCloseOpenedEventsDetails}
            expandedRowSlug={expandedRowSlug}
            expandAsAccordion={expandDetailsAsAccordion}
            expandAsModal={showDetailsAsModal}
          />
        );
      })
    : noEvents;

  const modalEvent =
    events &&
    events.length &&
    showDetailsAsModal &&
    expandedRowSlug &&
    expandedRowSlug !== "" ? (
      <RowModal
        event={events.find((i) => i.slug == expandedRowSlug)}
        showDetails={true}
        calendarSlug={props.calendarSlug}
        embedded={embedded}
        ccbAddress={props.ccbAddress}
        onRSVPEvent={props.onRSVPEvent}
        loading={props.loading}
        error={props.error}
        closeOpenedEventsDetails={onCloseOpenedEventsDetails}
        expandedRowSlug={expandedRowSlug}
        expandAsAccordion={expandDetailsAsAccordion}
        expandAsModal={showDetailsAsModal}
        calendarID={props.calendarID}
        showWatermark={props.showWatermark}
      />
    ) : null;

  const loadingIndicator = loading ? <LoadingIndicator /> : null;

  return (
    <div
      className={
        "events-list-container " +
        (!events.length ? "events-list-container--empty" : "")
      }
    >
      {modalEvent}
      <InfiniteScrollWrapper
        onLoadMore={props.onLoadMore}
        hasMore={hasMore}
        loading={loading}
        embedded={embedded}
      >
        {rows}
      </InfiniteScrollWrapper>
      {loadingIndicator}
    </div>
  );
};

Events.propTypes = {
  loading: PropTypes.bool,
  selectedEvent: PropTypes.string,
  calendarSlug: PropTypes.string,
  calendarID: PropTypes.string,
  embedded: PropTypes.bool,
  ccbAddress: PropTypes.string,
  onLoadMore: PropTypes.func,
  hasMore: PropTypes.bool,
  showWatermark: PropTypes.bool,
  /**
   * an optional callback, this is to notify that modal row has been closed.
   * Used to close events list modal in case there is only one event on the list
   * so closing the event row modal should close the events list
   */
  onClosedEventRowModal: PropTypes.func,
};
//Events.whyDidYouRender = true
export default Events;
