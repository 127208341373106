/**
 * Created by piotr.pozniak@thebeaverhead.com on 06/02/2019.
 */

import React, { useState, useEffect, useMemo, useCallback } from "react";
import { connect } from "react-redux";

import { useHistory } from "react-router-dom";
import PageTemplate from "./../templates/PageTemplate";
import TableRow from "./UsersPage/TableRow";
import LoadingIndicator from "../../calendar/components/LoadingIndicator";
import Pagination from "../component/Pagination";
import ApiError from "../component/ApiError";
import SuccessAlert from "../component/SuccessAlert";
import EditModal from "./UsersPage/EditModal";
import { clearErrors } from "../../../actions/main";
import { autologin, editUser, login } from "../../../actions/user";
import {
  activateAccount,
  deleteUser,
  fetchUsers,
} from "../../../actions/users";
import { useAutologinStore } from "../../../hooks/redux/autologin";
import { store } from "../store";
import Th from "../component/Table/Th";
import { useSubscriptionStore } from "../../../hooks/redux/subscription";

const UsersPage = (props) => {
  const queryParams = useMemo(() => {
    return new URLSearchParams(window.location.search);
  }, [window.location.search]);

  const [state, setState] = useState({
    editUser: {
      uuid: null,
    },

    orderField: queryParams.get("sort"),
    orderDirection: queryParams.get("direction"),
  });

  const { terminateSubscriptionAdmin } = useSubscriptionStore();

  const history = useHistory();

  const { autologinUser } = useAutologinStore();

  const refreshUsersList = useCallback(() => {
    const page = props.match.params.page;
    const ipp = props.match.params.ipp;

    props.usersActions.fetch(
      page ? page * 1 : 1,
      50,
      props.users.filters.name,
      props.users.filters.email,
      queryParams.get("sort"),
      queryParams.get("direction")
    );
  }, [props.match.params.page, window.location.search]);

  useEffect(() => {
    /*
    const page = props.match.params.page;
    const ipp = props.match.params.ipp;

    props.usersActions.fetch(
      page ? page * 1 : 1,
      50,
      props.users.filters.name,
      props.users.filters.email
    );
*/

    return () => {
      window.$("#user_edit_modal").modal("hide");
    };
  }, []);

  useEffect(() => {
    refreshUsersList();
  }, [props.match.params.page, window.location.search]);

  /**
   *
   */
  useEffect(() => {
    onPaginationPageChange(1);
  }, [state.orderField, state.orderDirection]);

  useEffect(() => {
    if (props.users.activateSuccess) {
      setState({
        editUser: props.users.collection.find(
          (i) => i.uuid == state.editUser.uuid
        ),
      });
    }
  }, [props.users.activateSuccess]);

  useEffect(() => {
    if (props.users.updateSuccess) {
      window.$("#user_edit_modal").modal("hide");
    }
  }, [props.users.updateSuccess]);

  useEffect(() => {
    if (state.editUser.uuid) {
      window.$("#user_edit_modal").modal("show");
    }
  }, [state.editUser]);

  useEffect(() => {
    if (props.users.terminateSubscriptionSuccess) {
      refreshUsersList();
    }
  }, [props.users.terminateSubscriptionSuccess]);

  /**
   *
   * @param user
   */
  const onUserDelete = (user) => {
    const promptValue =
      window.prompt(`Are you sure you want to delete user ${user.name}?
    Type '${user.name}' to confirm.`);

    if (promptValue === user.name) {
      props.usersActions.deleteUser(user.uuid);
    }
  };

  /**
   *
   * @param user
   */
  const onAutoLogin = async (autologinAsUser) => {
    await autologinUser(autologinAsUser.email, props.user);

    if (store.getState().autologin.loginSuccess) {
      history.push("/dashboard");
    }
  };

  /**
   *
   * @type {(function(*): Promise<*>)|*}
   */
  const onTerminateSubscription = useCallback(async (user) => {
    if (
      window.confirm(
        `Are you sure you want to terminate subscription for user ${user.first_name} ${user.last_name} (${user.email})?`
      )
    ) {
      await terminateSubscriptionAdmin(user.uuid);
    }
  }, []);

  /**
   *
   * @param user
   */
  const onShowEditModal = (user) => {
    setState({
      editUser: user,
    });
  };

  /**
   *
   * @param uuid
   * @param userData
   */
  const onUserEdit = (uuid, userData) => {
    props.userActions.editUser(uuid, userData);
  };

  /**
   *
   * @param page
   * @returns {string}
   */
  const getCurrentPageUrl = (page) => "/users/" + page;

  /**
   *
   * @param page
   * @returns {*}
   */
  const onPaginationPageChange = (page) => {
    const sort = queryParams.get("sort");
    const direction = queryParams.get("direction");

    let url = `/users/${page}`;

    if (state.orderField) {
      url += `?sort=${state.orderField}`;

      if (state.orderDirection) {
        url += `&direction=${state.orderDirection}`;
      }
    }

    history.push(url);
    window.scrollTo({ top: 0 });
  };

  const users = props.users;
  const loading = users.fetch || users.delete || users.terminateSubscription;

  const usersList = users.collection.map((item, idx) => {
    return (
      <TableRow
        disabled={
          loading ||
          (users.terminateSubscription &&
            users.terminateSubscription.uuid == item.uuid)
        }
        key={idx}
        user={item}
        onDelete={onUserDelete}
        onAutoLogin={onAutoLogin}
        onShowEditModal={onShowEditModal}
      />
    );
  });

  const loadingIndicator = loading ? <LoadingIndicator /> : null;

  /**
   *
   * @param e
   */
  const clearFilters = (e) => {
    e.preventDefault();

    props.usersActions.fetch(1, 50, "", "");
  };

  /**
   *
   * @param name
   * @param direction
   */
  const onChangeOrder = (name, direction) => {
    const directionsMapping = {};
    directionsMapping[null] = "asc";
    directionsMapping["asc"] = "desc";
    directionsMapping["desc"] = null;

    setState({
      ...state,
      orderField: name,
      orderDirection:
        state.orderField == name ? directionsMapping[direction] : "asc",
    });
  };

  /**
   *
   * @param e
   */
  const filterUsers = (e) => {
    e.preventDefault();

    const name = document.getElementById("users-filter-name").value;
    const email = document.getElementById("users-filter-email").value;

    props.usersActions.fetch(1, 50, name, email);
  };

  const tableRows = [
    {
      label: "Name",
      orderField: "name",
    },
    {
      label: "Email",
      orderField: "email",
    },
    {
      label: "Verified",
    },
    {
      label: "Join Date",
      orderField: "join_date",
    },
  ];

  const usersData = users.collection.length ? (
    <div>
      <table className="table table-striped custom-table">
        <thead>
          <tr>
            {tableRows.map((i, idx) => {
              return (
                <Th
                  key={"tr-" + idx}
                  onOrder={onChangeOrder}
                  orderField={i.orderField}
                  currentDirection={
                    state.orderField === i.orderField
                      ? state.orderDirection
                      : null
                  }
                >
                  {i.label}
                </Th>
              );
            })}
            <th className="text-right">Action</th>
          </tr>
        </thead>
        <tbody>{usersList}</tbody>
      </table>
    </div>
  ) : null;

  return (
    <PageTemplate sidebarId="users" title={"Users"} icon={"group"}>
      <h5 className="col-sm-12">
        <ApiError
          error={users.deleteError}
          defaultErrorMessage={"Couldn't delete User. Please try again"}
        />
        <ApiError
          error={users.terminateSubscriptionError}
          defaultErrorMessage={
            "Couldn't terminate user's subscription. Please try again"
          }
        />

        <SuccessAlert>
          {users.deleteSuccess ? "User has been deleted successfully." : null}
        </SuccessAlert>
      </h5>
      <div className="row">
        <div className="col-md-12">
          <div className={"card"}>
            <div className={"card-header"}>Filter</div>
            <div className={"card-body"}>
              <div className={"row filter-row"}>
                <div className="col-sm-6 col-md-3">
                  <div className="form-group form-focus">
                    <label className="focus-label">User Name</label>
                    <input
                      id={"users-filter-name"}
                      className="form-control floating"
                      defaultValue={props.users.filters.name}
                      type="text"
                      disabled={loading}
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-3">
                  <div className="form-group form-focus">
                    <label className="focus-label">Email</label>
                    <input
                      id={"users-filter-email"}
                      className="form-control floating"
                      defaultValue={props.users.filters.email}
                      type="text"
                      disabled={loading}
                    />
                  </div>
                </div>
              </div>
              <div className={"row filter-row"}>
                <div className="col-md-6"></div>
                <div className="col-sm-6 col-md-3">
                  <a
                    href="#"
                    className="btn btn-warning btn-block"
                    onClick={clearFilters}
                  >
                    {" "}
                    Clear{" "}
                  </a>
                </div>
                <div className="col-sm-6 col-md-3">
                  <a
                    href="#"
                    className="btn btn-success btn-block"
                    onClick={filterUsers}
                  >
                    {" "}
                    Search{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="table-responsive">
            {usersData}

            <Pagination
              itemsPerPage={users.ipp}
              currentPage={users.page}
              total={users.total}
              getPageUrl={getCurrentPageUrl}
              onPageChange={onPaginationPageChange}
            />
            {loadingIndicator}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-4">
          <small>Table colors explanation:</small>
          <table className="table table-striped custom-table">
            <tbody>
              <tr className={"table-info"}>
                <td>User is on trial</td>
              </tr>
              <tr className={"table-warning"}>
                <td>
                  User has cancelled their subscription but it is still active
                  for the remaining period of time
                </td>
              </tr>

              <tr className={"table-danger"}>
                <td>User has cancelled their subscription</td>
              </tr>
              <tr className={"table-secondary"}>
                <td>
                  User is either on trial or subscription but did not pay for
                  plan, their subscription is still active
                </td>
              </tr>
              <tr className={"table-success"}>
                <td>User is on a paid plan</td>
              </tr>
              <tr className="">
                <td>
                  Users without verified email, terminated trial period or with
                  cancelled subscription due to unpaid plan
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <EditModal
        key={state.editUser.uuid}
        user={state.editUser}
        users={props.users}
        onEdit={onUserEdit}
      />
    </PageTemplate>
  );
};

const mapStateToProps = (store) => ({
  main: store.main,
  autologin: store.autologin,
  user: store.user,
  calendars: store.calendars,
  users: store.users,
});

const mapDispatchToProps = (dispatch) => ({
  mainActions: {
    clearErrors: () => dispatch(clearErrors()),
  },
  userActions: {
    login: (email, password) => dispatch(login(email, password)),
    autologin: async (email, user) => await dispatch(autologin(email, user)),
    editUser: (uuid, data) => {
      dispatch(editUser(uuid, data, true));
    },
  },
  usersActions: {
    fetch: (page, ipp, name, email, sort, direction) => {
      dispatch(fetchUsers(page, ipp, name, email, sort, direction));
    },
    deleteUser: (uuid) => {
      dispatch(deleteUser(uuid));
    },
    activateAccount: (uuid) => {
      dispatch(activateAccount(uuid));
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersPage);
