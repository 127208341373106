/**
 * Created by piotr.pozniak@thebeaverhead.com on 05/08/2024
 */

import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { IntegrationStatus } from "../../consts/integrations";
import QueueJobProgress from "../../pages/QueueJobsPage/QueueJobProgress";
import QueueJobToDuration from "../../pages/QueueJobsPage/QueueJobToDuration";
import { useIntegrationsStore } from "../../../../hooks/redux/integrations";
import { useAppStore } from "../../../../hooks/redux/app";
import LoadingIndicator from "../../component/LoadingIndicator";

const IntegrationCardSyncInProgress = ({ integration }) => {
  const {
    dismissedAlerts: { lastSyncs },
  } = useAppStore();

  const lastSyncTimestamp =
    lastSyncs?.[integration.uuid] ?? integration.modified;
  const syncTime = new Date(lastSyncTimestamp * 1000);

  const hasLastImport = integration?.last_import ? true : false;

  const job = {
    data: {
      last_duration: integration?.last_import?.duration || 0,
    },
    created: syncTime,
    fetched: syncTime,
    completed: null,
    progress: 0,
  };

  const { integrations, fetchIntegrations } = useIntegrationsStore();

  useEffect(() => {
    let timeout = null;
    if (
      integration &&
      integration.status === IntegrationStatus.syncInProgress
    ) {
      const fetchIntegrationsTimeout =
        job.data.last_duration - (Date.now() / 1000 - lastSyncTimestamp) + 20;

      if (fetchIntegrationsTimeout > 0) {
        timeout = setTimeout(() => {
          fetchIntegrations();
        }, fetchIntegrationsTimeout * 1000);
      }
    }

    return () => clearTimeout(timeout);
  }, [integration.status]);

  /**
   *
   * @type {(function(*): void)|*}
   */
  const onRefresh = useCallback((e) => {
    e.preventDefault();
    fetchIntegrations();
  }, []);

  /**
   *
   * @type {(function(): void)|*}
   */
  const onSyncComplete = useCallback(() => {
    fetchIntegrations();
  }, []);

  const refresh = integrations.fetch ? null : (
    <span className={"d-inline-flex align-items-center "}>
      <i className={"material-icons icon-sm"}>refresh</i>
      <a href={"#"} className={"btn-link"} onClick={onRefresh}>
        Refresh
      </a>
    </span>
  );

  return integration &&
    integration.status === IntegrationStatus.syncInProgress ? (
    <div className={"d-flex align-items-center flex-column my-2 blog"}>
      <div className={"w-100 d-flex justify-content-between gap-xs "}>
        <div>
          Sync in progress:{" "}
          {hasLastImport ? (
            <QueueJobToDuration job={job} asPercentage showETA />
          ) : (
            "Initial sync, please wait..."
          )}
        </div>
        {refresh}
      </div>
      <div className={"w-100 flex"}>
        {hasLastImport ? (
          <QueueJobProgress job={job} />
        ) : (
          <LoadingIndicator className={"dce--progress"} />
        )}
      </div>
    </div>
  ) : null;
};

IntegrationCardSyncInProgress.defaultProps = {};

IntegrationCardSyncInProgress.propTypes = {};

export default IntegrationCardSyncInProgress;
