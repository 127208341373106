import React, { Component, useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";

import AxiosConfig from "../../../../AxiosConfig";
import axios from "axios";
import AsyncSelect from "react-select/async";

const page = 1;
const limit = 100;

const AsyncSelection = (props) => {
  const [state, setState] = useState({ defaultOptions: [] });

  useEffect(() => {
    promiseOptions("", (r) => {
      setState({ defaultOptions: r });
    });
  }, []);

  /**
   *
   * @param inputValue
   * @returns {Promise<*>}
   */
  const promiseOptions = (inputValue, callback) => {
    if (
      props.calendar &&
      (!props.calendar.model || !props.calendar.model.integration)
    ) {
      return callback([]);
    }

    const url = props.baseUrl + inputValue;

    return axios
      .get(url, AxiosConfig.getAuthConfig())
      .then((response) =>
        callback(props.rawDataToDisplayObject(response.data, true))
      )
      .catch(function (error) {});
  };

  /**
   *
   * @type {(function(): void)|*}
   */
  const onClear = useCallback(() => {
    props.onChange([]);
  }, [props.onChange]);

  return (
    <AsyncSelect
      autoFocus={true}
      autoExpand={true}
      isMulti={props.isMulti}
      cacheOptions
      defaultOptions={state.defaultOptions}
      onChange={props.onUpdate}
      loadOptions={promiseOptions}
      isSearchable={true}
      isClearable={true}
      value={props.collection}
      placeholder={props.placeholder}
      noOptionsMessage={() => props.noOptionsMessage}
      styles={{
        multiValueLabel: (provided, state) => {
          return {
            ...provided,
            whiteSpace: "normal",
          };
        },
      }}
      onClear={onClear}
    />
  );
};

AsyncSelection.defaultProps = {
  isMulti: true,
};

AsyncSelection.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  baseUrl: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  collection: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  noOptionsMessage: PropTypes.string,
  rawDataToDisplayObject: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
};
export default AsyncSelection;
