/**
 * Created by piotr.pozniak@thebeaverhead.com on 05/01/2024
 */

import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import ModalTemplate from "./ModalTemplate";
import { Elements, StripeProvider } from "react-stripe-elements";
import { useUserStore } from "../../../hooks/redux/user";
import { useSubscriptionStore } from "../../../hooks/redux/subscription";
import LoadingIndicator from "../../calendar/components/LoadingIndicator";
import BillingDetailsForm from "../component/BillingDetailsForm";
import BillingForm from "./Subscribe/BillingForm";
import { useHistory } from "react-router-dom";
import ApiError from "../component/ApiError";
import { useAppStore } from "../../../hooks/redux/app";

const UpdateBillingDetailsModal = (props) => {
  const { clearErrors } = useAppStore();
  const { checkSession } = useUserStore();
  const { subscription, updateBillingDetails } = useSubscriptionStore();
  const { updatePayment, updatePaymentError } = subscription;

  const history = useHistory();

  useEffect(() => {
    return clearErrors;
  }, []);

  /**
   *
   */
  useEffect(() => {
    if (subscription.updatePaymentSuccess) {
      checkSession();
      history.push("/billing");
    }
  }, [subscription.updatePaymentSuccess]);

  /**
   *
   */
  const onSubmit = useCallback((stripe, billingData) => {
    updateBillingDetails(stripe, billingData);
  }, []);

  const loadingIndicator = updatePayment ? <LoadingIndicator /> : null;

  return (
    <ModalTemplate
      id={"billingDetailsModal"}
      header={"Update billing details"}
      size={"lg"}
    >
      <StripeProvider apiKey={process.env.STRIPE_PK_KEY}>
        <Elements>
          <BillingForm
            disabled={updatePayment ? true : false}
            submitButtonLabel={"Update"}
            showCoupon={false}
            defaultErrorMessage={
              "Could not update billing details. Please try again"
            }
            showAutoCharge={true}
            onSubmit={onSubmit}
          />
        </Elements>
      </StripeProvider>
      {loadingIndicator}
      <ApiError error={updatePaymentError} />
    </ModalTemplate>
  );
};

UpdateBillingDetailsModal.defaultProps = {};

UpdateBillingDetailsModal.propTypes = {};

export default UpdateBillingDetailsModal;
