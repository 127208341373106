/**
 * Created by piotr.pozniak@thebeaverhead.com on 04/04/2024
 */

import React, { useContext } from "react";
import PropTypes from "prop-types";
import useShowPopup from "../../../hooks/useShowPopup";
import SharePopup from "../../../WidgetsComponents/SharePopup";
import WidgetSettingsContext from "../../../contexts/WidgetSettingsContext";

const ShareCalendarButton = ({ title, url, text, className }) => {
  const { onShow, showPopup } = useShowPopup({ url, title, text });
  const widgetSettings = useContext(WidgetSettingsContext);

  const showCalendarShare = widgetSettings["calendarShowShare"] === 1;

  const popup = showPopup ? (
    <SharePopup url={url} title={title} text={text} onClose={onShow} />
  ) : null;

  if (!showCalendarShare) {
    return null;
  }

  return (
    <div>
      <div onClick={onShow} className={className}>
        Share <i className={"material-icons icon-sm"}>share</i>
      </div>
      {popup}
    </div>
  );
};

ShareCalendarButton.defaultProps = {
  className: "dc-calendar-share-button",
};

ShareCalendarButton.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
};

export default ShareCalendarButton;
