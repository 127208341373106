import React, { useCallback, useContext, useState } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import DesignTooltip from "./DesignTooltip";
import { BuilderInputSetupPropTypes } from "./BuilderOptionPropTypes";

import CssBoxInput from "./CssBoxInput";
import useDidMountEffect from "../../../../../hooks/useDidMountEffect";
import { CSSUnits } from "../../../../../builder/consts";
import { CSSUnitSelector } from "./CSSUnitSelector";
import WidgetSettingsContext from "../../../../../contexts/WidgetSettingsContext";

const CssDimension = (props) => {
  const {
    title,
    name,
    tooltip,
    control,
    updateDesignProp,
    defaultValue: _defaultValue,
  } = props;

  const widgetSettingsContext = useContext(WidgetSettingsContext);
  const positionValues = widgetSettingsContext[name] + "";
  const matchResult = positionValues.match(/(\d+(\.\d+)?)([a-zA-Z%]+)/);
  const defaultUnit =
    matchResult && matchResult.length >= 2 && CSSUnits.includes(matchResult[2])
      ? matchResult[2]
      : CSSUnits[0];

  const defaultValue =
    matchResult && matchResult.length ? matchResult[1] : _defaultValue;

  const [unit, setUnit] = useState(defaultUnit);
  const [value, setValue] = useState(() => {
    const parsedValue = !isNaN(parseFloat(positionValues))
      ? parseFloat(positionValues)
      : defaultValue;

    return parsedValue;
  });

  /**
   * It must run as an effect as the final value is dependent on both value and dimension.
   */
  useDidMountEffect(() => {
    updateDesignProp([
      { field: name + "Option", value: "custom" },
      {
        field: name,
        value: `${value}${unit}`,
      },
    ]);
  }, [value, unit]);

  /**
   *
   * @type {(function(*): void)|*}
   */
  const handleInputChange = useCallback((value) => {
    setValue(value);
  }, []);

  return (
    <div className={"design-element"}>
      <div className={"d-flex align-items-center justify-content-between"}>
        <label className={classnames("option-label")}>
          <span className={"option-title"}>{title}</span>
          <DesignTooltip help={tooltip} />
        </label>
        <CSSUnitSelector value={unit} setValue={setUnit} />
      </div>
      <CssBoxInput
        key={name}
        value={value}
        setValue={handleInputChange}
        placeholder={control.options.placeholder}
        inputClassName={"text-right"}
      />
    </div>
  );
};

CssDimension.propTypes = {
  ...BuilderInputSetupPropTypes,

  control: PropTypes.shape({
    kind: PropTypes.oneOf(["cssDimension"]).isRequired,
    options: PropTypes.shape({
      placeholder: PropTypes.string,
    }),
  }).isRequired,
};

export default CssDimension;
